import React from "react";
import {InputNumber, List, Select, Form, Tooltip, Cascader, Radio} from "antd";
import { IconButton } from "@material-ui/core";

import { CaretDownOutlined, CaretRightOutlined, ExclamationCircleFilled, SearchOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import TooltipMui from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { translator } from "../../../../translations/translators";

import { CoatingPicker } from "../../CoatingPicker";
import './style.less'

import {profiles} from "../../../../data/profiles";

const { Option } = Select;
const { OptGroup } = Select;

const PartsTooltip = withStyles({
  tooltip: {
    backgroundColor: "#14181a",
    border: "1px solid #a6a6a6",
    marginTop: 10
  },
  arrow: {
    '&::before': {
      backgroundColor: "#14181a",
      border: "1px solid #a6a6a6"
    }
  },
})(TooltipMui);

const ListItem = (props) => {
  const {
    item, index, listLength,

    selectedItemsCount, selectedSheetMetalPartsCount,
    selectedMillingPartsCount, selectedLathingPartsCount,
    selectedAutomaticTubeCuttingPartsCount, selectedManualTubeCuttingPartsCount,
    selectedProfilePartsCount, selectedPartsMapByType,

    addMultiEditField, removeMultiEditField, setMultiEditField,
    currentActiveMultiEditFields,

    selectedParameters, sheetMetalMaterials, selectableSheetMetalMaterials,
    selectableThickness, machiningMaterials, selectableMachiningMaterials,

    handleMultiQuantityChange, handleMultiCertificateChange,
    handleMultiCoatingChange, handleMultiCoatingClear,
    handleMultiSheetMetalMaterialChange, handleMultiSheetMetalMaterialClear,
    handleMultiThicknessChange, handleMultiThicknessClear,
    handleMultiMachiningMaterialChange, handleMultiMachiningMaterialClear,
    handleMultiStandardChange, handleMultiStandardClear
  } = props;

  const getApplicableParts = (type) => {
    switch (type) {
      case "sheetMetalMaterial":
        return <span style={{fontWeight: 600}}>Sheet Metal parts</span>
      case "thickness":
        return <span style={{fontWeight: 600}}>Sheet Metal parts</span>
      case "machiningMaterial":
        return <span style={{fontWeight: 600}}>Machining parts</span>
      case "standard":
        return <span style={{fontWeight: 600}}>Manual Tube and Profile parts</span>
      case "certificate":
        return <span style={{fontWeight: 600}}>All selected parts with material</span>
      case "quantity":
      case "coating":
      default:
        return <span style={{fontWeight: 600}}>All selected parts</span>
    }
  }

  const getType = (type) => {
    switch (type) {
      case "thickness":
        return "sheetMetal";
      case "machiningMaterial":
        return "machined";
      case "sheetMetalMaterial":
        return "sheetMetal";
      case "standard":
        return "tubeProfile";
      default:
        return "sheetMetal";
    }
  }

  const strForSearch = (str) => {
    return str
      ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
      : str;
  };

  return (
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center", height: 55, padding: "6px 0px"}}>
      <div>
        <div style={{display: "flex", alignItems: "center"}}>
          <div style={{marginRight: 10, color: "white", fontSize: 12}}>
            Change
          </div>
          <Form.Item
            style={{marginBottom: 0}}
          >
          <Select
            style={{width: 200}}
            suffixIcon={<CaretDownOutlined />}
            getPopupContainer={trigger => trigger.parentNode}
            virtual={false}
            placeholder={<span style={{color: "#bdbdbd"}}>Select Parameter</span>}
            className={"materialMultiSelect muiInput"}
            value={item.selected}
            onChange={(value) => {
              setMultiEditField({index, prevSelected: item.selected, selected: value});
            }}
            dropdownClassName={"orderItemMaterialSelectDropDown"}
          >
            <OptGroup label="COMMON">
              <Option
                value={"quantity"}
                disabled={
                  selectedItemsCount === 0 ||
                  (currentActiveMultiEditFields["quantity"] && item.selected !== "quantity")
                }
                className={"orderItemMaterialSelectItem multiEditOption"}
              >
                Quantity
              </Option>
              <Option
                value={"certificate"}
                disabled={
                  (selectedSheetMetalPartsCount === 0 &&
                  selectedMillingPartsCount === 0 &&
                  selectedLathingPartsCount === 0 &&
                  selectedManualTubeCuttingPartsCount === 0 &&
                  selectedAutomaticTubeCuttingPartsCount === 0 &&
                  selectedProfilePartsCount === 0) ||
                  (currentActiveMultiEditFields["certificate"] && item.selected !== "certificate")
                }
                className={"orderItemMaterialSelectItem multiEditOption"}
              >
                Material Certificate
              </Option>
              <Option
                value={"coating"}
                disabled={
                  (selectedSheetMetalPartsCount === 0 &&
                  selectedMillingPartsCount === 0 &&
                  selectedLathingPartsCount === 0 &&
                  (selectedAutomaticTubeCuttingPartsCount + selectedManualTubeCuttingPartsCount) === 0 &&
                  selectedProfilePartsCount === 0) ||
                  (currentActiveMultiEditFields["coating"] && item.selected !== "coating")
                }
                className={"orderItemMaterialSelectItem multiEditOption"}
              >
                Surface Finish
              </Option>
            </OptGroup>
            <OptGroup label="SHEET METAL">
              <Option
                value={"sheetMetalMaterial"}
                disabled={
                  selectedSheetMetalPartsCount === 0 ||
                  (currentActiveMultiEditFields["sheetMetalMaterial"] && item.selected !== "sheetMetalMaterial")
                }
                className={"orderItemMaterialSelectItem multiEditOption"}
              >
                Material
              </Option>
              <Option
                value={"thickness"}
                disabled={
                  selectedSheetMetalPartsCount === 0 ||
                  (currentActiveMultiEditFields["thickness"] && item.selected !== "thickness")
                }
                className={"orderItemMaterialSelectItem multiEditOption"}
              >
                Thickness
              </Option>
            </OptGroup>
            <OptGroup label="MACHINING">
              <Option
                value={"machiningMaterial"}
                disabled={
                  (selectedMillingPartsCount === 0 && selectedLathingPartsCount === 0) ||
                  (currentActiveMultiEditFields['machiningMaterial'] && item.selected !== 'machiningMaterial')
                }
                className={"orderItemMaterialSelectItem multiEditOption"}
              >
                Material
              </Option>
            </OptGroup>
            <OptGroup label="TUBE AND PROFILE">
              <Option
                value={"standard"}
                disabled={
                  (selectedManualTubeCuttingPartsCount === 0 && selectedProfilePartsCount === 0) ||
                  (currentActiveMultiEditFields['standard'] && item.selected !== 'standard')
                }
                className={"orderItemMaterialSelectItem multiEditOption"}
              >
                Manual Profile Type
              </Option>
            </OptGroup>
          </Select>
          </Form.Item>
          {item.selected && (
            <div style={{display: "flex", alignItems: "center"}}>
              <div style={{marginLeft: 10, marginRight: 10, color: "white", fontSize: 12}}>
                to
              </div>
              <div>
                {item.selected === "quantity" && (
                  <InputNumber
                    className={"item-number-input muiInput"}
                    type={"number"}
                    style={{width: 160}}
                    placeholder={"Enter quantity"}
                    min={1}
                    max={10000}
                    step={1}
                    onChange={handleMultiQuantityChange}
                  />
                )}
                {item.selected === "certificate" && (
                  <Radio.Group
                    onChange={(e) => handleMultiCertificateChange(e.target.value)}
                    defaultValue={true}
                  >
                    <Radio style={{color: "white", fontSize: 12}} value={true}>Apply</Radio>
                    <Radio style={{color: "white", fontSize: 12}} value={false}>Remove</Radio>
                  </Radio.Group>
                )}
                {item.selected === "coating" && (
                  <CoatingPicker
                    changeColor={handleMultiCoatingChange}
                    hasCoating={selectedParameters.coating.hasCoating}
                    type={selectedParameters.coating?.type}
                    colorRAL={selectedParameters.coating?.data?.colorRAL}
                    colorGlossId={selectedParameters.coating?.data?.colorGlossId}
                    ralRow={selectedParameters.coating?.data?.ralRow}
                    ralHex={selectedParameters.coating?.data?.ralHex}
                    handleColorRemove={handleMultiCoatingClear}
                    isMultiEdit={true}
                  />
                )}
                {item.selected === "sheetMetalMaterial" && (
                  <Select
                    className={"materialMultiSelect muiInput"}
                    dropdownClassName={"orderItemMaterialSelectDropDown"}
                    style={{width: 160}}
                    suffixIcon={<CaretDownOutlined/>}
                    getPopupContainer={trigger => trigger.parentNode}
                    placeholder={"Select material"}
                    virtual={false}
                    showSearch
                    allowClear
                    filterOption={(input, option) => {
                      if (option.value) {
                        return strForSearch(option.children.props.children).includes(strForSearch(input));
                      } else {
                        return false;
                      }
                    }}
                    value={selectedParameters.sheetMetalMaterial?.grade}
                    onChange={handleMultiSheetMetalMaterialChange}
                    onClear={handleMultiSheetMetalMaterialClear}
                  >
                    {sheetMetalMaterials.map(material => {
                      return (
                        <OptGroup
                          key={material.groupName}
                          label={material.groupName}
                        >
                          {material.grades.map(t => {
                            const disabled = !selectableSheetMetalMaterials.has(t.grade)
                            return (
                              <Option
                                key={t.grade}
                                value={t.grade}
                                disabled={disabled}
                                className={"orderItemMaterialSelectItem"}
                              >
                                <span
                                  className={"text12-600-colorless"}
                                  style={disabled ?
                                    {color: "#919191"}
                                    :
                                    {color: "white"}
                                  }
                                >
                                  {translator(t.grade)}
                                </span>
                              </Option>
                            )
                          })}
                        </OptGroup>
                      )
                    })}
                  </Select>
                )}
                {item.selected === "thickness" && (
                  <div>
                    {selectedParameters.sheetMetalMaterial ?
                      (
                        <div style={{display: "flex"}}>
                          <Select
                            className={"thicknessMultiSelect muiInput"}
                            dropdownClassName={"orderItemMaterialSelectDropDown"}
                            style={{width: 160}}
                            suffixIcon={<CaretDownOutlined/>}
                            getPopupContainer={trigger => trigger.parentNode}
                            placeholder={"Select thickness"}
                            virtual={false}
                            onChange={handleMultiThicknessChange}
                            onClear={handleMultiThicknessClear}
                            allowClear
                          >
                            {selectedParameters.sheetMetalMaterial &&
                              (selectedParameters.sheetMetalMaterial.thickness.map(item => {
                                const disabled = !selectableThickness.has(item.id)
                                return (
                                  <Option className={"orderItemMaterialSelectItem"} disabled={disabled} key={item.id} value={item.thickness}>
                                    <span
                                      className={"text12-600-colorless"}
                                      style={disabled ?
                                        {color: "#919191"}
                                        :
                                        {color: "white"}
                                      }
                                    >
                                      {item.thickness} mm
                                    </span>
                                  </Option>
                                )
                              }))}
                          </Select>
                          <div>
                            {selectedParameters.thickness &&
                              <div style={{marginTop: 5, marginLeft: 10}}>
                                <Tooltip
                                  getPopupContainer={trigger => trigger.parentNode}
                                  overlayClassName={"thickness-tooltip"}
                                  color={"rgba(82,82,82,0.9)"}
                                  title={
                                    <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                                      <div className={"text12-500"} style={{ color: "white", textAlign: "center" }}>
                                        Note: Thickness won't be applied to items whose thickness was automatically detected
                                      </div>
                                    </div>
                                  }
                                >
                                  <ExclamationCircleFilled style={{color: "#eed202"}}/>
                                </Tooltip>
                              </div>
                            }
                          </div>
                        </div>
                      )
                      :
                      (
                        <Tooltip
                          getPopupContainer={trigger => trigger.parentNode}
                          overlayClassName={"multi-edit-thickness-tooltip"}
                          color={"rgba(82,82,82,0.9)"}
                          title={
                            <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                              <span
                                className={"text12-500"}
                                style={{ color: "white" }}
                              >
                                Select Sheet Metal material first
                              </span>
                            </div>
                          }
                        >
                          <Select
                            className={"thicknessMultiSelect muiInput"}
                            disabled={true}
                            suffixIcon={<CaretDownOutlined />}
                            style={{width: 160}}
                            placeholder={"Select thickness"}
                            virtual={false}
                          >
                          </Select>
                        </Tooltip>
                      )
                    }
                  </div>
                )}
                {item.selected === "machiningMaterial" && (
                  <Select
                    className={"materialMultiSelect muiInput"}
                    dropdownClassName={"orderItemMaterialSelectDropDown"}
                    style={{width: 160}}
                    suffixIcon={<CaretDownOutlined />}
                    getPopupContainer={trigger => trigger.parentNode}
                    placeholder={"Select material"}
                    virtual={false}
                    showSearch
                    allowClear
                    filterOption={(input, option) => {
                      if (option.value) {
                        return strForSearch(option.children.props.children).includes(strForSearch(input));
                      } else {
                        return false;
                      }
                    }}
                    value={selectedParameters.machiningMaterial?.id}
                    onChange={handleMultiMachiningMaterialChange}
                    onClear={handleMultiMachiningMaterialClear}
                  >
                    {machiningMaterials.map(group => {
                      return (
                        <OptGroup
                          key={group.group}
                          label={group.group}
                        >
                          {group.grades.map(grade => {
                            const disabled = !selectableMachiningMaterials.has(grade.grade)
                            return (
                              <Option
                                key={grade.id}
                                value={grade.id}
                                disabled={disabled}
                                className={"orderItemMaterialSelectItem"}
                              >
                                <span
                                  className={"text12-600-colorless text-white"}
                                  style={disabled ?
                                    {color: "#919191"}
                                    :
                                    {color: "white"}
                                  }
                                >
                                  {translator(grade.grade)}
                                </span>
                              </Option>
                            )
                          })}
                        </OptGroup>
                      )
                    })}
                  </Select>
                )}
                {item.selected === "standard" && (
                  <Cascader
                    options={profiles}
                    onChange={handleMultiStandardChange}
                    onClear={handleMultiStandardClear}
                    className={"text12-500 muiInput"}
                    dropdownClassName={"orderItemMaterialSelectDropDown profileTypeDropDown"}
                    style={{width: 186}}
                    placeholder="Select profile"
                    showSearch={{
                      filter: (inputValue, path) => {return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);},
                      limit: 400
                    }}
                    getPopupContainer={trigger => trigger.parentNode}
                    suffixIcon={(e) => {
                      return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                    }}
                    expandIcon={<CaretRightOutlined/>}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
        {item.selected && (
          <PartsTooltip
            arrow
            placement={"bottom"}
            disableHoverListener={(item.selected === "quantity" || item.selected === "coating" || item.selected === "certificate")}
            title={
              <div className={"partsCountTooltip"}>
                {Object.keys(selectedPartsMapByType[getType(item.selected)] || {}).map((key, index) => {
                  return (
                    <div key={index} style={{paddingTop: 2, paddingBottom: 2}}>
                      <div style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", fontSize: 11}}>- {(selectedPartsMapByType[getType(item.selected)] || {})[key]}</div>
                    </div>
                  )
                })}
              </div>
            }
          >
          <div style={{display: "flex", alignItems: "center", padding: "4px 0px", fontSize: 12, color: "white", marginRight: 10}}>
            <InfoIcon style={{color: "#ff5b2d", fontSize: 12, marginRight: 4}}/>
            <div className={"text12-400"}>
              Applies to {getApplicableParts(item.selected)}
            </div>
          </div>
          </PartsTooltip>
        )}
        <IconButton onClick={()=>{addMultiEditField(index)}} className={"listItemIconButton"} disabled={listLength > 6} style={{marginRight: 5}}>
          <AddOutlinedIcon className={"multiEditAddButton"}/>
        </IconButton>
        <IconButton
          onClick={()=>{removeMultiEditField({id: item.id, selected: item.selected})}}
          className={"listItemIconButton"}
          disabled={listLength === 1 && !item.selected}
        >
          <CloseOutlinedIcon className={"multiEditRemoveButton"}/>
        </IconButton>
      </div>
    </div>
  )
}

export const MultiEditListOfFields = (props) => {
  const {
    multiEditFields,

    selectedItemsCount, selectedSheetMetalPartsCount,
    selectedMillingPartsCount, selectedLathingPartsCount,
    selectedAutomaticTubeCuttingPartsCount, selectedManualTubeCuttingPartsCount,
    selectedProfilePartsCount, selectedPartsMapByType,

    addMultiEditField, removeMultiEditField, setMultiEditField,
    currentActiveMultiEditFields,

    selectedParameters, sheetMetalMaterials, selectableSheetMetalMaterials,
    selectableThickness, machiningMaterials, selectableMachiningMaterials,

    handleMultiQuantityChange, handleMultiCertificateChange,
    handleMultiCoatingChange, handleMultiCoatingClear,
    handleMultiSheetMetalMaterialChange, handleMultiSheetMetalMaterialClear,
    handleMultiThicknessChange, handleMultiThicknessClear,
    handleMultiMachiningMaterialChange, handleMultiMachiningMaterialClear,
    handleMultiStandardChange, handleMultiStandardClear
  } = props;

  return (
    <List>
      {multiEditFields.map((item, index) => {
        return (
          <List.Item className={"editFeatureListItem"} style={{padding: 0, width: "100%", display: "block"}} key={item.id}>
            <ListItem
              item={item}
              index={index}
              listLength={multiEditFields.length}

              selectedItemsCount={selectedItemsCount}
              selectedSheetMetalPartsCount={selectedSheetMetalPartsCount}
              selectedMillingPartsCount={selectedMillingPartsCount}
              selectedLathingPartsCount={selectedLathingPartsCount}
              selectedAutomaticTubeCuttingPartsCount={selectedAutomaticTubeCuttingPartsCount}
              selectedManualTubeCuttingPartsCount={selectedManualTubeCuttingPartsCount}
              selectedProfilePartsCount={selectedProfilePartsCount}
              selectedPartsMapByType={selectedPartsMapByType}

              addMultiEditField={addMultiEditField}
              removeMultiEditField={removeMultiEditField}
              setMultiEditField={setMultiEditField}
              currentActiveMultiEditFields={currentActiveMultiEditFields}

              selectedParameters={selectedParameters}
              sheetMetalMaterials={sheetMetalMaterials}
              selectableSheetMetalMaterials={selectableSheetMetalMaterials}
              selectableThickness={selectableThickness}
              machiningMaterials={machiningMaterials}
              selectableMachiningMaterials={selectableMachiningMaterials}

              handleMultiQuantityChange={handleMultiQuantityChange}
              handleMultiCertificateChange={handleMultiCertificateChange}
              handleMultiCoatingChange={handleMultiCoatingChange}
              handleMultiCoatingClear={handleMultiCoatingClear}

              handleMultiSheetMetalMaterialChange={handleMultiSheetMetalMaterialChange}
              handleMultiSheetMetalMaterialClear={handleMultiSheetMetalMaterialClear}

              handleMultiThicknessChange={handleMultiThicknessChange}
              handleMultiThicknessClear={handleMultiThicknessClear}

              handleMultiMachiningMaterialChange={handleMultiMachiningMaterialChange}
              handleMultiMachiningMaterialClear={handleMultiMachiningMaterialClear}

              handleMultiStandardChange={handleMultiStandardChange}
              handleMultiStandardClear={handleMultiStandardClear}
            />
          </List.Item>
        )
      })}
    </List>
  )
}