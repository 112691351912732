import * as React from 'react';
import {AutoSizer, MultiGrid} from 'react-virtualized';
import "./style.less";
import "../tableStyle.less";
import {Button, Spin} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {EmptyTable} from "../EmptyTable";
import {Trans} from 'react-i18next';
import {useEffect, useState} from "react";

const STYLE = {
  borderRadius: '0 0 5px 0'
};

export const ModalTable = (props) => {
  const [fixedColumnCount, setFixedColumnCount] = useState(1);
  const [fixedRowCount, setFixedRowCount] = useState(1);
  const [scrollToColumn, setScrollToColumn] = useState(0);
  const [scrollToRow, setScrollToRow] = useState(0);

  const {
    columns, rows, allAvailable,
    tabIndex, onClick, groupName,
    updateTable, setUpdateTable,
    activeLoadingTabs, isEditable,
    nestedTable, gradeTabIndex,
    gradeName, referenceHeight
  } = props

  let multiGridRef = null;

  const refreshGrid = () => {
    if(multiGridRef) {
      multiGridRef.forceUpdateGrids()
    }
  }

  useEffect(() => {
    if (updateTable) {
      setUpdateTable(false)
      refreshGrid()
    }
  }, [updateTable])

  useEffect(() => {
    refreshGrid()
  }, [activeLoadingTabs[tabIndex]])

  const getClassName = (rowIndex, columnIndex) => {
    if (rowIndex > rows.length) {
      return "laserSpeedDisabledRow"
    }
    if (rowIndex === 0) {
      return "laserSpeedZeroRow"
    } else {
      if (columnIndex === 0) {
        if(nestedTable) {
          return "laserSpeedZeroRow"
        }
        return "laserSpeedLeftColumn"
      } else {
        let row = rows[rowIndex - 1]
        let name = row[0]
        let value = row[columnIndex]
        if (!value) {
          if (nestedTable || allAvailable[name][columns[columnIndex]]) {
            return "laserSpeedOddRow"
          } else {
            return "laserSpeedDisabledRow"
          }
        } else {
          return "laserSpeedOddRow"
        }
      }
    }
  }

  const _cellRenderer = ({columnIndex, key, rowIndex, style}) => {
    return (
      <div className={getClassName(rowIndex, columnIndex)}
           key={key} style={{
        ...style,
        padding: 5,
        borderTop: rowIndex === 0 ? "1px solid #888888" : "none",
        borderBottom: columnIndex === 0 && nestedTable ? "none" : "1px solid #888888",
        borderRight: '2px solid #001529',
        display: 'inline-block',
        alignItems: 'center',
        right: -2
      }}>
        <div style={{fontSize: 12}} className={"cellText"}>
          {rowIndex === 0 ?
            <div className={"speedTableHeaderCellText"}>{columns[columnIndex]}</div>
            :
            (rows.length < rowIndex ?
              null
              :
              (columnIndex === 0 ?
                <div className={nestedTable ? "nestedSpeedTableCellLeft yesSelect": "speedTableCellLeft yesSelect"}><Trans>{rows[rowIndex - 1][columnIndex]}</Trans></div>
                :
                <div>
                  {nestedTable || allAvailable[rows[rowIndex - 1][0]][columns[columnIndex]] ?
                    <Button
                      type={"primary"}
                      style={{marginRight: 1, marginTop: -2, width: 44, paddingTop: 1, paddingBottom: 1, height: 26}}
                      onClick={() => onClick(
                        {
                          materialGrade: rows[rowIndex - 1][0],
                          materialThickness: columns[columnIndex],
                          materialId: nestedTable ? null : allAvailable[rows[rowIndex - 1][0]][columns[columnIndex]],
                          groupName: groupName,
                          gradeName: gradeName,
                          row: rowIndex - 1,
                          col: columnIndex,
                          tubeData: nestedTable ? rows[rowIndex - 1][columnIndex] : null,
                          tab: tabIndex,
                          gradeTabIndex: gradeTabIndex
                        }
                      )
                      }
                    >
                      {!rows[rowIndex - 1][columnIndex] ?
                        (!isEditable || nestedTable ? null : <PlusOutlined style={{fontSize: 12}}/>)
                        :
                        <span style={{fontSize: 12, fontWeight: 600, height: 12}}>
                          {nestedTable ? rows[rowIndex - 1][columnIndex]?.length : rows[rowIndex - 1][columnIndex]}
                        </span>
                      }
                    </Button>
                    :
                    null}
                </div>
              ))
          }
        </div>
      </div>
    );
  }

  const _getColumnWidth = ({index}) => {
    if(nestedTable) {
      return index === 0 ? 45 : 55
    }
    return index === 0 ? 140 : 55
  };

  const _getColumnCount = (columns, width) =>  {
    const count = Object.keys(columns).length

    if(nestedTable) {
      if(45 + (count-1) * 55 < width) {
        return Math.floor((width-45)/55) + 1
      }
      else {
        return count
      }
    }

    if(140 + (count-1) * 55 < width) {
      return Math.floor((width-140)/55) + 1
    }
    else {
      return count
    }
  }

  if (rows.length === 0) {
    return (
      <React.Fragment>
        <Spin spinning={Boolean(activeLoadingTabs && activeLoadingTabs[tabIndex])}
              indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
        >
          <EmptyTable/>
        </Spin>
      </React.Fragment>
    )
  } else {
    return (
      <div style={{height: referenceHeight || "calc(100vh - 672px)", minHeight: 233}}>
        <Spin spinning={Boolean(activeLoadingTabs && activeLoadingTabs[tabIndex])}
              wrapperClassName={"modalTableSpin"}
              indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
        >
          <AutoSizer>
            {({height, width}) => (
              <MultiGrid
                ref={(grid) => {
                  multiGridRef = grid;
                }}
                fixedColumnCount={fixedColumnCount}
                fixedRowCount={fixedRowCount}
                scrollToColumn={scrollToColumn}
                scrollToRow={scrollToRow}
                cellRenderer={_cellRenderer}
                columnWidth={_getColumnWidth}
                columnCount={_getColumnCount(columns, width)}
                enableFixedColumnScroll
                enableFixedRowScroll
                height={referenceHeight || height}
                rowHeight={33}
                rowCount={rows.length < 6 ? 7 : rows.length+1}
                style={STYLE}
                classNameTopLeftGrid={"topLeftGrid"}
                classNameTopRightGrid={"topRightGrid"}
                classNameBottomLeftGrid={"bottomLeftGrid"}
                classNameBottomRightGrid={"bottomRightGrid"}
                width={width}
                hideTopRightGridScrollbar
                hideBottomLeftGridScrollbar
              />
            )}
        </AutoSizer>
        </Spin>
      </div>
    );
  }
}