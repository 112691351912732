import React from "react";
import "./style.less";
import {Checkbox, Divider, Input} from "antd";


export const ProducerMaterialInput = (props) => {
  const { label, _labelWidth, value, inputWidth, suffix, style, disabled, isDouble=false } = props;
  return (
    <div
      className={`producerDetailEntry ${disabled ? "showDisabled" : ""}`}
      style={{ ...style }}
    >
      <span
        className={isDouble ? "producerMaterialDoubleLabel" : "producerMaterialLabel"}
        style={_labelWidth ? { width: _labelWidth } : {}}
      >
        {label}:
      </span>
      <Input
        style={inputWidth ?{ maxWidth: inputWidth } : { maxWidth: 100 }}
        value={value}
        disabled
      />
      <span
        className={"producerMaterialInputSuffix"}
        style={{ marginLeft: 8 }}
      >
        {suffix}
      </span>
    </div>
  )
}

export const ProducerMaterialDoubleInput = (props) => {
  const { disabled, hasTreatment, price, suffix, labelOne, labelTwo } = props;
  return (
    <div className={disabled && !hasTreatment ? "" : "disabledField"}
         style={{display: "flex", alignItems: "center", margin: "5px 0"}}>
      <div className={!disabled ? (hasTreatment ? "trueDisabledCheckbox" : "falseDisabledCheckbox") : ""}>
        <Checkbox
          style={{ marginRight: 10, marginTop: -1}}
          checked={hasTreatment}
          disabled={true}
        />
      </div>
      <ProducerMaterialInput
        label={<div className={`dividedLabel ${disabled ? "disabledLabel" : ""}`}>
          <span className={"dividedFieldOne"}>{labelOne}</span>
          <Divider type={"vertical"} style={{ height: "24px", margin: "0 8px", borderColor: "#838f94" }} />
          <span className={`dividedFieldTwo`}>{labelTwo}</span>
        </div>}
        isDouble={true}
        value={price}
        disabled={!hasTreatment || disabled}
        suffix={suffix}
      />
    </div>
  )
}

export const ProducerMaterialsCheckbox = (props) => {
  return (
    <div className={"detailEntry"}>
      <div align={"right"}
           style={{whiteSpace: "nowrap", marginRight: 8, color: "white" }}
      >
        <span className={"mainFromLabel"}>{props.label}</span>:
      </div>
      <div className={props.value ? "trueDisabledCheckbox" : "falseDisabledCheckbox"}>
        <Checkbox checked={props.value}
                  disabled
        />
      </div>
      <Divider
        className={"lightDivider"}
        style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
    </div>
  )
}