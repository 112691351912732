import {
  SURFACE_COATING_SET_DATA,
  SURFACE_COATING_IS_FETCHING_DATA,
  SURFACE_COATING_SET_SURFACE_COATING_SERVICE,
  SURFACE_COATING_SET_PAINTING_SERVICE,
  SURFACE_COATING_SET_POWDER_PAINTING_SERVICE,
  SURFACE_COATING_SET_GALVANIZING_SERVICE,
  SURFACE_COATING_SET_PAINTING_DATA,
  SURFACE_COATING_SET_POWDER_PAINTING_DATA,
  SURFACE_COATING_SET_GALVANIZING_DATA,
  SURFACE_COATING_SET_NICKEL_PLATING_SERVICE,
  SURFACE_COATING_SET_NICKEL_PLATING_DATA,
  SURFACE_COATING_SET_ANODIZING_DATA,
  SURFACE_COATING_SET_ANODIZING_SERVICE,
  SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_DATA,
  SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_SERVICE,
  SURFACE_COATING_SET_BLASTING_SERVICE,
  SURFACE_COATING_SET_HEAT_TREATMENT_SERVICE,
  SURFACE_COATING_SET_GLASS_BEAD_BLASTING_DATA,
  SURFACE_COATING_SET_BLASTING_DATA,
  SURFACE_COATING_SET_HEAT_TREATMENT_DATA
} from "../actions/actionTypes";
import initialState from './initialState'

const surfaceCoatingReducer = (state = initialState.surfaceCoating, action) => {
  switch (action.type) {
    case SURFACE_COATING_SET_DATA:
      return {
        ...state,
        surfaceCoatingData: action.payload,
      }

    case SURFACE_COATING_IS_FETCHING_DATA:
      return {
        ...state,
        isFetchingSurfaceCoatingData: action.payload,
      }

    case SURFACE_COATING_SET_SURFACE_COATING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, hasSurfaceCoating: action.payload},
      }

    case SURFACE_COATING_SET_PAINTING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, painting: {...state.surfaceCoatingData.painting, hasPainting: action.payload}},
      }

    case SURFACE_COATING_SET_POWDER_PAINTING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, powderPainting: {...state.surfaceCoatingData.powderPainting, hasPowderPainting: action.payload}},
      }

    case SURFACE_COATING_SET_GALVANIZING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, galvanizing: {...state.surfaceCoatingData.galvanizing, hasGalvanizing: action.payload}},
      }

    case SURFACE_COATING_SET_NICKEL_PLATING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, nickelPlating: {...state.surfaceCoatingData.nickelPlating, hasNickelPlating: action.payload}},
      }

    case SURFACE_COATING_SET_ANODIZING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, anodizing: {...state.surfaceCoatingData.anodizing, hasAnodizing: action.payload}},
      }

    case SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, galvanicZincPlating: {...state.surfaceCoatingData.galvanicZincPlating, hasGalvanicZincPlating: action.payload}},
      }

    case SURFACE_COATING_SET_BLASTING_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, blasting: {...state.surfaceCoatingData.blasting, hasBlasting: action.payload}},
      }

    case SURFACE_COATING_SET_HEAT_TREATMENT_SERVICE:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, heatTreatment: {...state.surfaceCoatingData.heatTreatment, hasHeatTreatment: action.payload}},
      }

    case SURFACE_COATING_SET_PAINTING_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, painting: {...state.surfaceCoatingData.painting, ...action.payload}},
      }

    case SURFACE_COATING_SET_POWDER_PAINTING_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, powderPainting: {...state.surfaceCoatingData.powderPainting, ...action.payload}},
      }

    case SURFACE_COATING_SET_GALVANIZING_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, galvanizing: {...state.surfaceCoatingData.galvanizing, ...action.payload}},
      }

    case SURFACE_COATING_SET_NICKEL_PLATING_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, nickelPlating: {...state.surfaceCoatingData.nickelPlating, ...action.payload}},
      }

    case SURFACE_COATING_SET_ANODIZING_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, anodizing: {...state.surfaceCoatingData.anodizing, ...action.payload}},
      }

    case SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, galvanicZincPlating: {...state.surfaceCoatingData.galvanicZincPlating, ...action.payload}},
      }

    case SURFACE_COATING_SET_BLASTING_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, blasting: {...state.surfaceCoatingData.blasting, ...action.payload}},
      }

    case SURFACE_COATING_SET_HEAT_TREATMENT_DATA:
      return {
        ...state,
        surfaceCoatingData: {...state.surfaceCoatingData, heatTreatment: {...state.surfaceCoatingData.heatTreatment, ...action.payload}},
      }

    default:
      return state
  }
}

export default surfaceCoatingReducer
