import * as THREE from 'three';
import { OrbitControls } from './OrbitControls';

// Three.js extension functions. Webpack doesn't seem to like it if we modify the THREE object directly.
let THREEx = { Math: {} };
/**
 * Returns the angle in radians of the vector (p1,p2). In other words, imagine
 * putting the base of the vector at coordinates (0,0) and finding the angle
 * from vector (1,0) to (p1,p2).
 * @param  {Object} p1 start point of the vector
 * @param  {Object} p2 end point of the vector
 * @return {Number} the angle
 */
THREEx.Math.angle2 = function(p1, p2) {
    let v1 = new THREE.Vector2(p1.x, p1.y);
    let v2 = new THREE.Vector2(p2.x, p2.y);
	v2.sub(v1); // sets v2 to be our chord
	v2.normalize();
	if(v2.y < 0) return -Math.acos(v2.x);
	return Math.acos(v2.x);
};


THREEx.Math.polar = function(point, distance, angle) {
    let result = {};
	result.x = point.x + distance * Math.cos(angle);
	result.y = point.y + distance * Math.sin(angle);
	return result;
};

/**
 * Calculates points for a curve between two points
 * @param startPoint - the starting point of the curve
 * @param endPoint - the ending point of the curve
 * @param bulge - a value indicating how much to curve
 * @param segments - number of segments between the two given points
 */
THREEx.BulgeGeometry = function ( startPoint, endPoint, bulge, segments ) {

	let vertex, i,
		center, p0, p1, angle,
		radius, startAngle,
		thetaAngle;

	startPoint = p0 = startPoint ? new THREE.Vector2(startPoint.x, startPoint.y) : new THREE.Vector2(0,0);
	endPoint = p1 = endPoint ? new THREE.Vector2(endPoint.x, endPoint.y) : new THREE.Vector2(1,0);
	bulge = bulge = bulge || 1;

	angle = 4 * Math.atan(bulge);
	radius = p0.distanceTo(p1) / 2 / Math.sin(angle/2);
	center = THREEx.Math.polar(startPoint, radius, THREEx.Math.angle2(p0,p1) + (Math.PI / 2 - angle/2));

	segments = segments = segments || Math.max( Math.abs(Math.ceil(angle/(Math.PI/18))), 6); // By default want a segment roughly every 10 degrees
	startAngle = THREEx.Math.angle2(center, p0);
	thetaAngle = angle / segments;

    let points = []
    points.push(new THREE.Vector3(p0.x, p0.y, 0));


	for(i = 1; i <= segments - 1; i++) {

		vertex = THREEx.Math.polar(center, Math.abs(radius), startAngle + thetaAngle * i);

        points.push(new THREE.Vector3(vertex.x, vertex.y, 0));

	}
    return points
};
    
/**
 * Viewer class for a dxf object.
 * @param {Object} data - the dxf object
 * @param {Object} parent - the parent element to which we attach the rendering canvas
 * @param {Number} width - width of the rendering canvas in pixels
 * @param {Number} height - height of the rendering canvas in pixels
 * @param {Object[]} error - coordinates of errors if found
 * @param {Object} font - a font loaded with THREE.FontLoader
 ** @param {Object} square - a font loaded with THREE.FontLoader
 * @constructor
 */
export function Viewer(data, parent, width, height, error, font, square = null) {

    createLineTypeShaders(data);

    let scene = new THREE.Scene();

    // Create scene from dxf object (data)
    let i, entity, obj;
    let dims = {
        min: { x: false, y: false, z: false},
        max: { x: false, y: false, z: false}
    };

    for(i = 0; i < data.entities.length; i++) {

        entity = data.entities[i];

        obj = drawEntity(entity, data);

        if (obj) {
            let bbox = new THREE.Box3().setFromObject(obj);
            if (bbox.min.x && ((dims.min.x === false) || (dims.min.x > bbox.min.x))) dims.min.x = bbox.min.x;
            if (bbox.min.y && ((dims.min.y === false) || (dims.min.y > bbox.min.y))) dims.min.y = bbox.min.y;
            if (bbox.min.z && ((dims.min.z === false) || (dims.min.z > bbox.min.z))) dims.min.z = bbox.min.z;
            if (bbox.max.x && ((dims.max.x === false) || (dims.max.x < bbox.max.x))) dims.max.x = bbox.max.x;
            if (bbox.max.y && ((dims.max.y === false) || (dims.max.y < bbox.max.y))) dims.max.y = bbox.max.y;
            if (bbox.max.z && ((dims.max.z === false) || (dims.max.z < bbox.max.z))) dims.max.z = bbox.max.z;
            scene.add(obj);
        }
        obj = null;
    }

    let dimx = dims.max.x-dims.min.x;
    let dimy = dims.max.y-dims.min.y;
    let max = (dimx > dimy ? dimx : dimy)

    if(square !== null){
        obj = drawPlateSquare(square.x, square.y);
        if(obj) {
            scene.add(obj);
        }
    }

    for(i=0; i<error.length; i++) {
        entity = {
            color: 0xff0000,
            type: 'ERROR-CIRCLE',
            startAngle: 0,
            radius: max/37,
            center: {
                x: error[i].x,
                y: error[i].y,
                z: 0
            }
        }
        obj = drawErrorCircle(entity, max);
        if(obj) {
            scene.add(obj);
        }
        obj = null;
    }

    let grid = new THREE.GridHelper( (dims.max.x > dims.max.y ? dims.max.x : dims.max.y)*32, 180, 0xff0000, 0x999999 );
    //(dims.max.x > dims.max.y ? dims.max.x : dims.max.y)*32, 80
    grid.material.opacity = 0.4;
    grid.material.transparent = true;
    grid.geometry.rotateX( Math.PI / 2 );

    scene.add( grid );

    width = width || parent.innerWidth;
    height = height || parent.innerHeight;
    let aspectRatio = width / height;

    let upperRightCorner = { x: dims.max.x, y: dims.max.y };
    let lowerLeftCorner = { x: dims.min.x, y: dims.min.y };

    // Figure out the current viewport extents
    let vp_width = upperRightCorner.x - lowerLeftCorner.x;
    let vp_height = upperRightCorner.y - lowerLeftCorner.y;

    let center = {
        x: vp_width / 2 + lowerLeftCorner.x,
        y: vp_height / 2 + lowerLeftCorner.y
    };
    // Fit all objects into current ThreeDXF viewer
    let extentsAspectRatio = Math.abs(vp_width / vp_height);
    if (aspectRatio > extentsAspectRatio) {
        vp_width = vp_height * aspectRatio;
    } else {
        vp_height = vp_width / aspectRatio;
    }

    let viewPort = {
        bottom: -vp_height / 2,
        left: -vp_width / 2,
        top: vp_height / 2,
        right: vp_width / 2,
        center: {
            x: center.x,
            y: center.y
        }
    };

    let camera = new THREE.OrthographicCamera(viewPort.left, viewPort.right, viewPort.top, viewPort.bottom, 1, 19);
    camera.position.z = 10;
    camera.position.x = viewPort.center.x;
    camera.position.y = viewPort.center.y;
    camera.translateZ(-6);
    let renderer = new THREE.WebGLRenderer();
    renderer.setSize(width, height);
    renderer.setClearColor(0xfffffff, 1);

    parent.appendChild(renderer.domElement);
    parent.style.display = 'block';

    //TODO: Need to make this an option somehow so others can roll their own controls.
    let controls = new OrbitControls(camera, parent);
    controls.target.x = camera.position.x;
    controls.target.y = camera.position.y;
    controls.target.z = 0;
    controls.zoomSpeed = 3;
    controls.dollyOut(1.1)
    //Uncomment this to disable rotation (does not make much sense with 2D drawings).
    //controls.enableRotate = false;

    this.render = function() { renderer.render(scene, camera) };
    controls.addEventListener('change', this.render);
    this.render();
    controls.update();

    // var originalWidth1 = renderer.domElement.width;
    // var originalHeight2 = renderer.domElement.height;

    this.resize = function(width, height) {
        let originalWidth = renderer.domElement.width;
        let originalHeight = renderer.domElement.height;

        let hscale = width / originalWidth;
        let vscale = height / originalHeight;
        camera.top = (vscale * camera.top);
        camera.bottom = (vscale * camera.bottom);
        camera.left = (hscale * camera.left);
        camera.right = (hscale * camera.right);
        camera.updateProjectionMatrix();

        renderer.setSize(width, height);
        renderer.setClearColor(0xfffffff, 1);
        this.render();
    };

    function drawEntity(entity, data) {
        let mesh;

        if(entity.type === 'CIRCLE' || entity.type === 'ARC') {
            mesh = drawArc(entity, data);
        } else if(entity.type === 'LWPOLYLINE' || entity.type === 'LINE' || entity.type === 'POLYLINE') {
            mesh = drawLine(entity, data);
        } else if(entity.type === 'TEXT') {
            mesh = drawText(entity, data);
        } else if(entity.type === 'SOLID') {
            mesh = drawSolid(entity, data);
        } else if(entity.type === 'POINT') {
            drawPoint(entity, data);
        } else if(entity.type === 'INSERT') {
            mesh = drawBlock(entity, data);
        } else if(entity.type === 'SPLINE') {
            mesh = drawSpline(entity, data);
        } else if(entity.type === 'MTEXT') {
            mesh = drawMtext(entity, data);
        } else if(entity.type === 'ELLIPSE') {
            mesh = drawEllipse(entity, data);
        } else if(entity.type === 'DIMENSION') {
            let dimTypeEnum = entity.dimensionType & 7;
            if(dimTypeEnum === 0) {
                mesh = drawDimension(entity, data);
            } else {
                console.log("Unsupported Dimension type: " + dimTypeEnum);
            }
        }
        else {
            console.log("Unsupported Entity Type: " + entity.type);
        }
        return mesh;
    }

    function drawEllipse(entity, data) {
        let color = getColor(entity, data);

        let xrad = Math.sqrt(Math.pow(entity.majorAxisEndPoint.x,2) + Math.pow(entity.majorAxisEndPoint.y,2));
        let yrad = xrad*entity.axisRatio;
        let rotation = Math.atan2(entity.majorAxisEndPoint.y, entity.majorAxisEndPoint.x);

        let curve = new THREE.EllipseCurve(
            entity.center.x,  entity.center.y,
            xrad, yrad,
            entity.startAngle, entity.endAngle,
            false, // Always counterclockwise
            rotation
        );

        let points = curve.getPoints( 50 );
        let geometry = new THREE.BufferGeometry().setFromPoints( points );
        let material = new THREE.LineBasicMaterial( {  linewidth: 1, color : color } );

        // Create the final object to add to the scene
        return new THREE.Line( geometry, material );
    }

    function drawMtext(entity, data) {

        if(!font)
            return undefined

        let color = getColor(entity, data);

        let geometry = new THREE.TextGeometry( entity.text, {
            font: font,
            size: entity.height * (4/5),
            height: 1
        });
        let material = new THREE.MeshBasicMaterial( {color: color} );
        let text = new THREE.Mesh( geometry, material );

        // Measure what we rendered.
        let measure = new THREE.Box3();
        measure.setFromObject( text );

        let textWidth  = measure.max.x - measure.min.x;

        // If the text ends up being wider than the box, it's supposed
        // to be multiline. Doing that in threeJS is overkill.
        // if (textWidth > entity.width) {
        //     console.log("Can't render this multipline MTEXT entity, sorry.", entity);
        //     return undefined;
        // }

        text.position.z = 0;
        switch (entity.attachmentPoint) {
            case 1:
                // Top Left
                text.position.x = entity.position.x;
                text.position.y = entity.position.y - entity.height;
            break;
            case 2:
                // Top Center
                text.position.x = entity.position.x - textWidth/2;
                text.position.y = entity.position.y - entity.height;
            break;
            case 3:
                // Top Right
                text.position.x = entity.position.x - textWidth;
                text.position.y = entity.position.y - entity.height;
            break;

            case 4:
                // Middle Left
                text.position.x = entity.position.x;
                text.position.y = entity.position.y - entity.height/2;
            break;
            case 5:
                // Middle Center
                text.position.x = entity.position.x - textWidth/2;
                text.position.y = entity.position.y - entity.height/2;
            break;
            case 6:
                // Middle Right
                text.position.x = entity.position.x - textWidth;
                text.position.y = entity.position.y - entity.height/2;
            break;

            case 7:
                // Bottom Left
                text.position.x = entity.position.x;
                text.position.y = entity.position.y;
            break;
            case 8:
                // Bottom Center
                text.position.x = entity.position.x - textWidth/2;
                text.position.y = entity.position.y;
            break;
            case 9:
                // Bottom Right
                text.position.x = entity.position.x - textWidth;
                text.position.y = entity.position.y;
            break;

            default:
                return undefined;
        }

        return text;
    }

    function drawSpline(entity, data) {
        let color = getColor(entity, data);

        let segmens = 5000;

        let step = (entity.knotValues[entity.knotValues.length-1] - entity.knotValues[0])/segmens;

        let interpolatedPoints = [];

        for(let i=0;i<=segmens;i++) {
            interpolatedPoints.push(__point__(entity,i*step + entity.knotValues[0]));
        }
        let geometry = new THREE.BufferGeometry().setFromPoints( interpolatedPoints );
        let material = new THREE.LineBasicMaterial( { linewidth: 1, color : color } );

        return new THREE.Line( geometry, material );
    }

    function __point__(entity, t) {
        let number_of_control_points = entity.controlPoints.length;
        let knots = entity.knotValues;
        if(entity.closed !== undefined && entity.closed === true) {
            number_of_control_points += 1;

            knots.push(knots[knots.length - 1]);
        }
        let order = entity.degreeOfSplineCurve + 1

        if (isclose(t,knots[knots.length-1])) {
            t = knots[knots.length - 1];
        }
        let basis = [];
        for(let i=0; i<knots.length-1; i++)
        {
            if(knots[i] <= t && t < knots[i+1]) {
                basis.push(1);
            }
            else {
                basis.push(0);
            }
        }
        for(let k=2;k<=order;k++) {
            for(let i=0;i<(number_of_control_points + order - k);i++) {
                let first_segment_of_Cox_de_Boor_formula = 0;
                if(basis[i] !== 0) {
                    first_segment_of_Cox_de_Boor_formula = ((t - knots[i]) * basis[i]) / (knots[i + k - 1] - knots[i]);
                }
                else {
                    first_segment_of_Cox_de_Boor_formula = 0;
                }
                let second_segment_of_Cox_de_Boor_formula = 0;
                if(basis[i + 1] !== 0) {
                    second_segment_of_Cox_de_Boor_formula = ((knots[i + k] - t) * basis[i + 1]) / (knots[i + k] - knots[i + 1]);
                }
                else {
                    second_segment_of_Cox_de_Boor_formula = 0;
                }

                basis[i] = first_segment_of_Cox_de_Boor_formula + second_segment_of_Cox_de_Boor_formula;
            }
        }

        basis = basis.slice(0,number_of_control_points);

        if(isclose(t,knots[knots.length-1])) {
            basis[basis.length-1] = 1;
        }

        //TODO: Weight

        // if self.weights is not None:
        //     products = [weight * basis_segment for weight, basis_segment in zip(self.weights + [self.weights[0]], basis)]
        //     products_sum = sum(products)
        //     basis = [0] * number_of_control_points if products_sum == 0 else [product / products_sum for product in products]

        let x = 0;
        let y = 0;
        let points = entity.controlPoints;
        for(let i=0; i<number_of_control_points-1; i++)
        {
            x += points[i].x * basis[i];
            y += points[i].y * basis[i];
        }
        if(entity.closed !== undefined && entity.closed === true) {
            x += points[0].x * basis[basis.length - 1];
            y += points[0].y * basis[basis.length - 1];
        }
        else {
            x += points[number_of_control_points-1].x * basis[basis.length - 1];
            y += points[number_of_control_points-1].y * basis[basis.length - 1];
        }

        return new THREE.Vector2(x, y)
    }

    function isclose(a,b) {
        return Math.abs(a-b) <= Math.max(1e-09 * Math.max(Math.abs(a), Math.abs(b)), 0.0);
    }

    function drawLine(entity, data) {
        let color = getColor(entity, data),
            material, lineType, vertex, startPoint, endPoint, bulgeGeometry,
            bulge, i;

        // create geometry
        let points = []
        for(i = 0; i < entity.vertices.length; i++) {

            if(entity.vertices[i].bulge) {
                bulge = entity.vertices[i].bulge;
                startPoint = entity.vertices[i];
                endPoint = i + 1 < entity.vertices.length ? entity.vertices[i + 1] : points[0];

                bulgeGeometry = new THREEx.BulgeGeometry(startPoint, endPoint, bulge);
                points.push.apply(points, bulgeGeometry);
            } else {
                vertex = entity.vertices[i];
                points.push(new THREE.Vector3(vertex.x, vertex.y, 0));
            }

        }
        if(entity.shape) points.push(points[0]);

        let geometry = new THREE.BufferGeometry().setFromPoints(points)

        // set material
        if(entity.lineType) {
            lineType = data.tables.lineType.lineTypes[entity.lineType];
        }

        if(lineType && lineType.pattern && lineType.pattern.length !== 0) {
            material = new THREE.LineDashedMaterial({ color: color, gapSize: 4, dashSize: 4});
        } else {
            material = new THREE.LineBasicMaterial({ linewidth: 1, color: color });
        }

        // if(lineType && lineType.pattern && lineType.pattern.length !== 0) {

        //           geometry.computeLineDistances();

        //           // Ugly hack to add diffuse to this. Maybe copy the uniforms object so we
        //           // don't add diffuse to a material.
        //           lineType.material.uniforms.diffuse = { type: 'c', value: new THREE.Color(color) };

        // 	material = new THREE.ShaderMaterial({
        // 		uniforms: lineType.material.uniforms,
        // 		vertexShader: lineType.material.vertexShader,
        // 		fragmentShader: lineType.material.fragmentShader
        // 	});
        // }else {
        // 	material = new THREE.LineBasicMaterial({ linewidth: 1, color: color });
        // }

        return new THREE.Line(geometry, material);
    }

    function drawPlateSquare(x, y) {
        let material,line;

        let points = []

        points.push(new THREE.Vector3(0.0, 0.0, 0));
        points.push(new THREE.Vector3(x, 0.0, 0));
        points.push(new THREE.Vector3(x, y, 0));
        points.push(new THREE.Vector3(0.0, y, 0));
        points.push(new THREE.Vector3(0.0, 0.0, 0));

        let geometry = new THREE.BufferGeometry().setFromPoints(points)

        material = new THREE.LineDashedMaterial({ color: 0xff0000, dashSize:20, gapSize:5});
        line = new THREE.Line(geometry, material);
        line.computeLineDistances();
        return line;
    }
    
    function drawArc(entity, data) {
        let startAngle, endAngle;
        if (entity.type === 'CIRCLE') {
            startAngle = entity.startAngle || 0;
            endAngle = startAngle + 2 * Math.PI;
        } else {
            startAngle = entity.startAngle;
            endAngle = entity.endAngle;
        }

        let curve = new THREE.ArcCurve(
            0, 0,
            entity.radius,
            startAngle,
            endAngle);

        let points = curve.getPoints( 32 );
        let geometry = new THREE.BufferGeometry().setFromPoints( points );

        let material = new THREE.LineBasicMaterial({ color: getColor(entity, data)});

        let arc = new THREE.Line(geometry, material);

        arc.position.x = entity.center.x;
        arc.position.y = entity.center.y;
        arc.position.z = entity.center.z || 0;

        return arc;
    }

    function drawErrorCircle(entity, dim) {
        let startAngle, endAngle;
        if (entity.type === 'ERROR-CIRCLE') {
            startAngle = entity.startAngle || 0;
            endAngle = startAngle + 2 * Math.PI;
        } else {
            startAngle = entity.startAngle;
            endAngle = entity.endAngle;
        }

        let curve = new THREE.ArcCurve(
            0, 0,
            entity.radius,
            startAngle,
            endAngle);

        let points = curve.getPoints( 32 );
        let geometry = new THREE.BufferGeometry().setFromPoints( points );
        let material = new THREE.LineDashedMaterial({ color: entity.color, dashSize: dim/74, gapSize: dim/185 });

        let arc = new THREE.Line(geometry, material);
        arc.computeLineDistances();
        arc.position.x = entity.center.x;
        arc.position.y = entity.center.y;
        arc.position.z = entity.center.z || 0;

        return arc;
    }

    function drawSolid(entity, data) {
        let material

        let points = []
        points.push(new THREE.Vector3(entity.points[0].x, entity.points[0].y, (entity.points[0].z || 0)));
        points.push(new THREE.Vector3(entity.points[1].x, entity.points[1].y, (entity.points[1].z || 0)));
        points.push(new THREE.Vector3(entity.points[2].x, entity.points[2].y, (entity.points[2].z || 0)));
        points.push(new THREE.Vector3(entity.points[3].x, entity.points[3].y, (entity.points[3].z || 0)));

        // Calculate which direction the points are facing (clockwise or counter-clockwise)
        let vector1 = new THREE.Vector3();
        let vector2 = new THREE.Vector3();
        vector1.subVectors(points[1], points[0]);
        vector2.subVectors(points[2], points[0]);
        vector1.cross(vector2);

        let geometry = new THREE.BufferGeometry().setFromPoints(points)


        // If z < 0 then we must draw these in reverse order
        // if(vector1.z < 0) {
        //     geometry.faces.push(new THREE.Face3(2, 1, 0));
        //     geometry.faces.push(new THREE.Face3(2, 3, 1));
        // } else {
        //     geometry.faces.push(new THREE.Face3(0, 1, 2));
        //     geometry.faces.push(new THREE.Face3(1, 3, 2));
        // }


        material = new THREE.MeshBasicMaterial({ color: getColor(entity, data) });

        return new THREE.Mesh(geometry, material);
        
    }

    function drawText(entity, data) {
        let geometry, material, text;

        if(!font)
            return undefined
        
        geometry = new THREE.TextGeometry(entity.text, { font: font, height: 0, size: entity.textHeight || 12 });

        if (entity.rotation) {
            let zRotation = entity.rotation * Math.PI / 180;
            geometry.rotateZ(zRotation);
        }

        material = new THREE.MeshBasicMaterial({ color: getColor(entity, data) });

        text = new THREE.Mesh(geometry, material);
        text.position.x = entity.startPoint.x;
        text.position.y = entity.startPoint.y;
        text.position.z = entity.startPoint.z || 0;

        return text;
    }

    function drawPoint(entity, data) {
        let geometry, material, point;

        geometry = new THREE.BufferGeometry();

        geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( new THREE.Vector3(entity.position.x, entity.position.y, (entity.position.z || 0)).toArray(), 3 ) );
        // TODO: could be more efficient. PointCloud per layer?

        let numPoints = 1;

        let color = getColor(entity, data);
        let colors = new Float32Array( numPoints*3 );
        colors[0] = color.r;
        colors[1] = color.g;
        colors[2] = color.b;
        geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3))

        material = new THREE.PointsMaterial( { size: 1, vertexColors: THREE.VertexColors} );
        point = new THREE.Points(geometry, material);
        scene.add(point);
    }

    function drawDimension(entity, data) {
        let block = data.blocks[entity.block];

        if (!block || !block.entities) return null;

        let group = new THREE.Object3D();
        // if(entity.anchorPoint) {
        //     group.position.x = entity.anchorPoint.x;
        //     group.position.y = entity.anchorPoint.y;
        //     group.position.z = entity.anchorPoint.z;
        // }

        for(let i = 0; i < block.entities.length; i++) {
            let childEntity = drawEntity(block.entities[i], data, group);
            if(childEntity) group.add(childEntity);
        }

        return group;
    }

    function drawBlock(entity, data) {
        let block = data.blocks[entity.name];
        
        if (!block.entities) return null;

        let group = new THREE.Object3D()
        
        if(entity.xScale) group.scale.x = entity.xScale;
        if(entity.yScale) group.scale.y = entity.yScale;

        if(entity.rotation) {
            group.rotation.z = entity.rotation * Math.PI / 180;
        }

        if(entity.position) {
            group.position.x = entity.position.x;
            group.position.y = entity.position.y;
            group.position.z = entity.position.z || 0;
        }
        
        for(let i = 0; i < block.entities.length; i++) {
            let childEntity = drawEntity(block.entities[i], data, group);
            if(childEntity) group.add(childEntity);
        }

        return group;
    }

    function getColor(entity, data) {
        let color = 0x000000; //default
        if(entity.color) color = entity.color;
        else if(data.tables && data.tables.layer && data.tables.layer.layers[entity.layer])
            color = data.tables.layer.layers[entity.layer].color;
            
        if(color == null || color === 0xffffff) {
            color = 0x000000;
        }
        return color;
    }

    function createLineTypeShaders(data) {
        let ltype, type;
        if(!data.tables || !data.tables.lineType) return;
        let ltypes = data.tables.lineType.lineTypes;

        for(type in ltypes) {
            ltype = ltypes[type];
            if(!ltype.pattern) continue;
            ltype.material = createDashedLineShader(ltype.pattern);
        }
    }

    function createDashedLineShader(pattern) {
        let i,
            dashedLineShader = {},
            totalLength = 0.0;

        for(i = 0; i < pattern.length; i++) {
            totalLength += Math.abs(pattern[i]);
        }

        dashedLineShader.uniforms = THREE.UniformsUtils.merge([

            THREE.UniformsLib[ 'common' ],
            THREE.UniformsLib[ 'fog' ],

            {
                'pattern': { type: 'fv1', value: pattern },
                'patternLength': { type: 'f', value: totalLength }
            }

        ]);

        dashedLineShader.vertexShader = [
            'attribute float lineDistance;',

            'varying float vLineDistance;',

            THREE.ShaderChunk[ 'color_pars_vertex' ],

            'void main() {',

            THREE.ShaderChunk[ 'color_vertex' ],

            'vLineDistance = lineDistance;',

            'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',

            '}'
        ].join('\n');

        dashedLineShader.fragmentShader = [
            'uniform vec3 diffuse;',
            'uniform float opacity;',

            'uniform float pattern[' + pattern.length + '];',
            'uniform float patternLength;',

            'varying float vLineDistance;',

            THREE.ShaderChunk[ 'color_pars_fragment' ],
            THREE.ShaderChunk[ 'fog_pars_fragment' ],

            'void main() {',

            'float pos = mod(vLineDistance, patternLength);',

            'for ( int i = 0; i < ' + pattern.length + '; i++ ) {',
            'pos = pos - abs(pattern[i]);',
            'if( pos < 0.0 ) {',
            'if( pattern[i] > 0.0 ) {',
            'gl_FragColor = vec4(1.0, 0.0, 0.0, opacity );',
            'break;',
            '}',
            'discard;',
            '}',

            '}',

            THREE.ShaderChunk[ 'color_fragment' ],
            THREE.ShaderChunk[ 'fog_fragment' ],

            '}'
        ].join('\n');

        return dashedLineShader;
    }

    // function findExtents(scene) {
    //     for(var child of scene.children) {
    //         var minX, maxX, minY, maxY;
    //         if(child.position) {
    //             minX = Math.min(child.position.x, minX);
    //             minY = Math.min(child.position.y, minY);
    //             maxX = Math.max(child.position.x, maxX);
    //             maxY = Math.max(child.position.y, maxY);
    //         }
    //     }
    //
    //     return { min: { x: minX, y: minY }, max: { x: maxX, y: maxY }};
    // }

}


// Show/Hide helpers from https://plainjs.com/javascript/effects/hide-or-show-an-element-42/
// get the default display style of an element
// function defaultDisplay(tag) {
//     var iframe = document.createElement('iframe');
//     iframe.setAttribute('frameborder', 0);
//     iframe.setAttribute('width', 0);
//     iframe.setAttribute('height', 0);
//     document.documentElement.appendChild(iframe);
//
//     var doc = (iframe.contentWindow || iframe.contentDocument).document;
//
//     // IE support
//     doc.write();
//     doc.close();
//
//     var testEl = doc.createElement(tag);
//     doc.documentElement.appendChild(testEl);
//     var display = (window.getComputedStyle ? getComputedStyle(testEl, null) : testEl.currentStyle).display
//     iframe.parentNode.removeChild(iframe);
//     return display;
// }

// actual show/hide function used by show() and hide() below
// function showHide(el, show) {
//     var value = el.getAttribute('data-olddisplay'),
//     display = el.style.display,
//     computedDisplay = (window.getComputedStyle ? getComputedStyle(el, null) : el.currentStyle).display;
//
//     if (show) {
//         if (!value && display === 'none') el.style.display = '';
//         if (el.style.display === '' && (computedDisplay === 'none')) value = value || defaultDisplay(el.nodeName);
//     } else {
//         if ((display && display !== 'none') || !(computedDisplay === 'none'))
//             el.setAttribute('data-olddisplay', (computedDisplay === 'none') ? display : computedDisplay);
//     }
//     if (!show || el.style.display === 'none' || el.style.display === '')
//         el.style.display = show ? value || '' : 'none';
// }

// helper functions
// function show(el) { showHide(el, true); }
// function hide(el) { showHide(el); }



