import React, {useCallback, useEffect, useState} from "react";
import "./style.less";
import {CompanySidebar} from "../../../../components/company";
import {logoutUser} from "../../../../actions/authActions";
import {Button, Card, Col, Divider, Grid, Input, Layout, Row, Modal, Form, Spin, Upload, Select} from 'antd'
import {useDispatch, useSelector} from "react-redux";

import {ProducerGrid} from "../../../../components/company/Producers/ProducerGrid";
import {debounce} from "lodash";
import { escapeRegExp} from "../../../../utils/utility";
import {EmptyTable} from "../../../../components/EmptyTable";
import history from "../../../../utils/history";
import {CircularProgress} from "@mui/material";
import {message} from "../../../../utils";
import {CaretDownOutlined, EditOutlined, GlobalOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {countries} from "../../../../data/countries";
import {
  fetchProducers,
  toggleBlockProducer,
  createNewProducer,
  updateProducerInfo, updateProducerLogo
} from "../../../../actions/producersActions";
import {API_BASE_URL, S3_BUCKET_URL} from "../../../../constants";
import {resetProducerParams} from "../../../../actions/producerParamsActions";
import backupLogo from "../../../../assets/images/backup_producer_logo.png"
const { useBreakpoint } = Grid;
const { Option } = Select;

const regions = [
  {name: "US", value: "us"},
  {name: "EU-west", value: "eu-west"},
  {name: "EU-east", value: "eu-east"},
  {name: "Asia", value: "asia"},
]

export const ProducersPage = (props) => {
  const screens = useBreakpoint();

  const user = useSelector(state => state.auth.user);
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const allProducers = useSelector(state => state.producers.producers);
  const isFetchingProducers = useSelector(state => state.producers.fetchingProducers);
  const isCreatingProducer = useSelector(state => state.producers.isCreatingProducer);
  const isUpdatingProducerInfo = useSelector(state => state.producers.isUpdatingProducerInfo);
  const isUpdatingProducerLogo = useSelector(state => state.producers.isUpdatingProducerLogo);

  const [updatingStatus, setIsUpdatingStatus] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [srcText, setSrcText] = useState("");
  const [filteredProducersByName, setFilteredProducersByName] = useState([]);
  const [filteredProducersByRegion, setFilteredProducersByRegion] = useState([]);
  const [selectedProducer, setSelectedProducer] = useState(null);
  const [selectedProducerId, setSelectedProducerId] = useState(null);
  const [xlState, setXlState] = useState(screens["xl"]);
  const [addProducerModalVisible, setAddProducerModalVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [editProducerModalVisible, setEditProducerModalVisible] = useState(false);
  const [changedLogo, setChangedLogo] = useState(false);

  const dispatch = useDispatch();
  const [newProducerForm] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [editProducerFormInfo] = Form.useForm();
  const [editProducerFormLogo] = Form.useForm();

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => setImageUrl(reader.result));
    reader.readAsDataURL(img);
  }

  useEffect(() => {
    dispatch(fetchProducers());
  }, [])

  useEffect(() => {
    setFilteredProducersByRegion(allProducers);
    setFilteredProducersByName(allProducers);
    setIsUpdatingStatus(false);
  }, [allProducers])

  useEffect(() => {
    setXlState(screens["xl"]);
  }, [screens["xl"]])

  useEffect(() => {
    setIsSearching(true);
    if (srcText === "") {
      setFilteredProducersByName(filteredProducersByRegion)
    } else {
      const filteredProducers = filteredProducersByRegion.filter(item => (item.name.toLowerCase() + item.email.toLowerCase()).search(escapeRegExp(srcText)) !== -1);
      setFilteredProducersByName(filteredProducers)
    }
    setIsSearching(false);
  }, [filteredProducersByRegion])

  useEffect(() => {
    if (srcText === "") {
      setFilteredProducersByName(filteredProducersByRegion)
    } else {
      const filteredProducers = filteredProducersByRegion.filter(item => (item.name.toLowerCase() + item.email.toLowerCase()).search(escapeRegExp(srcText)) !== -1);
      setFilteredProducersByName(filteredProducers)
    }
    setIsSearching(false)
  }, [srcText])

  const debounceSearchProducers = useCallback(
    debounce(data => filterProducersByName(data), 800),
    []
  );

  const searchProducers = (text) => {
    setIsSearching(true)
    debounceSearchProducers({text})
  }

  const handleSelectCustomer = (producer) => {
    setSelectedProducer(producer)
    setSelectedProducerId(producer.id)
  }

  const filterProducersByRegion = (selectedRegions) => {
    if (selectedRegions.length === 0) {
      setFilteredProducersByRegion(allProducers);
    } else {
      const filteredProducers = allProducers.filter(item => selectedRegions.includes(item.region.toLowerCase()));
      setFilteredProducersByRegion(filteredProducers);
    }
  }

  const filterProducersByName = (data) => {
    setSrcText(data.text.toLowerCase());
  }

  const getProducerLocation = () => {
    return selectedProducer.address + ", " + selectedProducer.city + " " + selectedProducer.postalCode + ", " + selectedProducer.country;
  }

  const handleAddProducer = () => {
    setAddProducerModalVisible(true);
  }

  const handleCancelAddProducer = () => {
    setAddProducerModalVisible(false);
    newProducerForm.resetFields();
    setImageFile(null);
    setImageUrl("");
  }

  const handleEditProducer = () => {
    setEditProducerModalVisible(true);
  }

  const handleCancelEditProducer = () => {
    setEditProducerModalVisible(false);
    editProducerFormInfo.resetFields();
    setImageFile(null);
    setImageUrl("");
  }

  const defaultLogo = () => {
    let arr = backupLogo.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let binaryStr = atob(arr[1]);
    let n = binaryStr.length;
    let u8arr = new Uint8Array(n);

    while(n--){
      u8arr[n] = binaryStr.charCodeAt(n);
    }

    return new File([u8arr], "logo.png", {type:mime});
  }

  const submitAddProducerForm = (values) => {
    let logo = imageFile;
    if(!logo) {
      logo = defaultLogo()
    }
    const data = {
      email: values.producerEmail,
      password: values.producerPassword,
      adminName: values.adminName,
      name: values.producerName,
      country: values.country,
      region: values.region,
      city: values.city,
      address: values.address,
      postalCode: values.postalCode,
      phone: values.producerPhone
    }

    dispatch(createNewProducer({file: logo, data: data})).then(()=>{
      handleCancelAddProducer();
      filterForm.resetFields();
      setFilteredProducersByRegion(allProducers);
      setSrcText("");
      dispatch(fetchProducers());
    }).catch(()=>{})
  }

  const submitEditProducerFormInfo = (values) => {
    const data = {
      name: values.producerName,
      country: values.country,
      region: values.region,
      city: values.city,
      address: values.address,
      postalCode: values.postalCode,
      phone: values.producerPhone
    }
    dispatch(updateProducerInfo({producerId: selectedProducerId, data: data}))
      .then(() => {
        setSelectedProducer({
          ...selectedProducer,
          ...data
        })
      })
      .catch(() => {})
  }

  const submitEditProducerFormLogo = () => {
    let logo = imageFile;
    if(!logo) {
      logo = defaultLogo()
    }
    dispatch(updateProducerLogo({producerId: selectedProducerId, file: logo}))
      .then((logoUrl) => {
        setSelectedProducer({
          ...selectedProducer,
          logoUrl: logoUrl
        })
      })
      .catch(() => {})
  }

  const formatRegion = (region) => {
    switch (region) {
      case "eu-west":
        return "EU West"
      case "eu-east":
        return "EU East"
      case "asia":
        return "Asia"
      case "us":
        return "US"
    }
  }

  const handleToggleBlockProducer = () => {
    setIsUpdatingStatus(true);
    dispatch(toggleBlockProducer({
      producerId: selectedProducerId,
      isBlocked: !selectedProducer.isBlocked
    }))
    setSelectedProducer({...selectedProducer, isBlocked: !selectedProducer.isBlocked})
  }

  const DetailsCardLarge = (props) => {
    return (
      <div className={"producerDetailsCard"} style={{ flexDirection: "column" }}>
        <div className={"producerDetailsLogo"} style={{ marginTop: xlState ? 10 : 0 }}>
          <img src={`${S3_BUCKET_URL}${selectedProducer.logoUrl}`} className={"producerLogo"} width={200}/>
        </div>
        <Row gutter={0} style={{ width: "100%"}}>
          <Col span={24} style={{ display: "flex", justifyContent: "flex-start" }}>
            <div style={{ minWidth: 300, width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <div className={"producerName"} style={{ justifyContent: "center"}}>
                {selectedProducer.name}
              </div>
              <div style={{ maxWidth: 650, marginLeft: 12, marginTop: 16 }}>
                <div className={"producerDetailEntry"}>
                  <span style={{ width: 110, flexShrink: 0 }}>
                    Producer Name:
                  </span>
                  <Input
                    defaultValue={selectedProducer.name}
                    disabled
                  />
                </div>
                <div className={"producerDetailEntry"}>
                  <span style={{ width: 110, flexShrink: 0 }}>
                    Email:
                  </span>
                  <Input
                    defaultValue={selectedProducer.email}
                    disabled
                  />
                </div>
                <div className={"producerDetailEntry"}>
                  <span style={{ width: 110, flexShrink: 0 }}>
                    Phone number:
                  </span>
                  <Input
                    defaultValue={selectedProducer.phone}
                    disabled
                  />
                </div>
                <div className={"producerDetailEntry"}>
                  <span style={{ width: 110, flexShrink: 0 }}>
                    Region:
                  </span>
                  <Input
                    defaultValue={formatRegion(selectedProducer.region)}
                    disabled
                  />
                </div>
                <div className={"producerDetailEntry"}>
                  <span style={{ width: 110, flexShrink: 0 }}>
                    Location:
                  </span>
                  <Input
                    defaultValue={getProducerLocation()}
                    disabled
                  />
                </div>
                <div style={{ display: "flex", width: "100%", alignItems: "center"}}>
                  <div className={"producerDetailEntry"} style={{ paddingRight: 1}}>
                    <span style={{ width: 110, flexShrink: 0 }}>
                      Status:
                    </span>
                    <Input
                      className={!selectedProducer.isBlocked ? "activeStatus" : "disabledStatus"}
                      defaultValue={selectedProducer.isBlocked ? "Disabled" : "Active"}
                      disabled
                    />
                  </div>
                  <div style={{ paddingRight: 13 }}>
                    <Button type={!selectedProducer.isBlocked ? "danger" : "primary"}
                            onClick={handleToggleBlockProducer}
                            size={"middle"}
                            className={"producerParamsButton"}
                            loading={updatingStatus}
                    >
                      <span style={{ width: 80 }}>{!selectedProducer.isBlocked ? "Disable" : "Activate"}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Divider style={{ marginTop: 12, marginBottom: 0}}/>
          <Col span={24}>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between"}}>
              <Button
                style={{ marginTop: 12, marginLeft: 12 }}
                onClick={handleEditProducer}
              >
                <EditOutlined /> Edit Producer Info
              </Button>
              <Button
                type={"primary"}
                style={{ marginTop: 12, marginRight: 12 }}
                onMouseDown={(e) => {
                  if (e.button === 1) {
                    const win = window.open(`/producersManagement/${selectedProducerId}/materials`, "_blank");
                    win.focus();
                  } else if (e.button === 0) {
                    dispatch(resetProducerParams())
                    history.push(`/producersManagement/${selectedProducerId}/materials`)
                  }
                }}
              >
                View Parameters
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const DetailsCardSmall = (props) => {
    return (
      <div className={"producerDetailsCard"} style={{ flexDirection: "row" }}>
        {/*<div className={"producerDetailsLogo"} style={{background: `url(${selectedProducer.logo}) center`}} />*/}
        <div className={"producerDetailsLogo"}>
          <img src={`${S3_BUCKET_URL}${selectedProducer.logoUrl}`}  className={"producerLogo"} width={200}/>
        </div>
        <Row gutter={0} style={{ width: "100%"}}>
          <Col span={24} style={{ display: "flex", justifyContent: "flex-start" }}>
            <div style={{ minWidth: 300, width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <div
                className={"producerName scalableText"}
                style={{ justifyContent: "flex-end", marginLeft: 6, paddingLeft: 6, borderLeft: "1px solid #0b0e0f" }}
              >
                {/*{selectedProducer.name}*/}
                <div style={{ whiteSpace: "nowrap", marginLeft: 6, marginRight: 12 }}>
                  <Button
                    style={{ position: "relative", top: -4, marginRight: 6 }}
                    onClick={handleEditProducer}
                  >
                    <EditOutlined /> Edit Producer Info
                  </Button>
                  <Button
                    type={"primary"}
                    style={{ position: "relative", top: -4 }}
                    onMouseDown={(e) => {
                      if (e.button === 1) {
                        const win = window.open(`/producersManagement/${selectedProducerId}/materials`, "_blank");
                        win.focus();
                      } else if (e.button === 0) {
                        dispatch(resetProducerParams())
                        history.push(`/producersManagement/${selectedProducerId}/materials`)
                      }
                    }}
                  >
                    View Parameters
                  </Button>
                </div>
              </div>
              <div style={{ maxWidth: 650, marginLeft: 6, paddingLeft: 6, borderLeft: "1px solid #0b0e0f" }}>
                <div className={"producerDetailEntry"}>
                  <span style={{ width: 110, flexShrink: 0 }}>
                    Producer Name:
                  </span>
                  <Input
                    defaultValue={selectedProducer.name}
                    disabled
                  />
                </div>
                <div className={"producerDetailEntry"}>
                  <span style={{ width: 110, flexShrink: 0 }}>
                    Email:
                  </span>
                  <Input
                    defaultValue={selectedProducer.email}
                    disabled
                  />
                </div>
                <div className={"producerDetailEntry"}>
                  <span style={{ width: 110, flexShrink: 0 }}>
                    Phone number:
                  </span>
                  <Input
                    defaultValue={selectedProducer.phone}
                    disabled
                  />
                </div>
                <div className={"producerDetailEntry"}>
                  <span style={{ width: 110, flexShrink: 0 }}>
                    Region:
                  </span>
                  <Input
                    defaultValue={formatRegion(selectedProducer.region)}
                    disabled
                  />
                </div>
                <div className={"producerDetailEntry"}>
                  <span style={{ width: 110, flexShrink: 0 }}>
                    Location:
                  </span>
                  <Input
                    defaultValue={getProducerLocation()}
                    disabled
                  />
                </div>
                <div style={{ display: "flex", width: "100%", alignItems: "center"}}>
                  <div className={"producerDetailEntry"} style={{ paddingRight: 1}}>
                    <span style={{ width: 110, flexShrink: 0 }}>
                      Status:
                    </span>
                    <Input
                      className={!selectedProducer.isBlocked ? "activeStatus" : "disabledStatus"}
                      defaultValue={selectedProducer.isBlocked ? "Disabled" : "Active"}
                      disabled
                    />
                  </div>
                  <div style={{ paddingRight: 13 }}>
                    <Button type={!selectedProducer.isBlocked ? "danger" : "primary"}
                            onClick={handleToggleBlockProducer}
                            size={"middle"}
                            className={"producerParamsButton"}
                            loading={updatingStatus}
                    >
                      <span style={{ width: 80 }}>{!selectedProducer.isBlocked ? "Disable" : "Activate"}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{overflowX: "hidden"}}>
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            <Row type="flex">
              <Col span={24}>
                <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                  <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
                    <div className={"pageTitle"}>Producers:</div>
                  </div>
                  <div>
                    <Button type={"primary"}
                            onClick={() => {
                              handleAddProducer()
                            }}
                    >
                      <span style={{ fontWeight: 600 }}>Add Producer</span>
                    </Button>
                  </div>
                </div>
                <Divider style={{marginTop: 6, marginBottom: 11 }}/>
              </Col>
            </Row>
            <Row type={"flex"} gutter={15}>
              <Col xl={{order: 1, span: 16}} xs={{order: 2, span: 24}}>
                <div className={"pageSubtitle"}>
                  Summary
                </div>
                <div className={"summaryCard"}>
                  <Row>
                    <Col span={24}>
                      <Form form={filterForm}>
                      <Card
                        className={"producersCardHeader"}
                        bodyStyle={{height: "100%", width: "100%", padding: "0 12px 0 12px", display: "flex", alignItems: "center"}}
                      >
                        <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between"}}>
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <div style={{fontSize: 13, fontWeight: 500, marginRight: 10, whiteSpace: "nowrap"}}>
                              Search Producers:
                            </div>
                            <div style={{ marginRight: 24 }}>
                              <Form.Item name={"producerSearch"}>
                              <Input
                                allowClear
                                style={{marginTop: 20}}
                                placeholder={"Type to search"}
                                onChange={(e)=>{
                                  searchProducers(e.target.value)
                                }}
                              />
                              </Form.Item>
                            </div>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <div style={{fontSize: 13, fontWeight: 500, marginRight: 10, whiteSpace: "nowrap"}}>
                              Filter by Region:
                            </div>
                            <div>
                              <Form.Item name={"regionFilter"}>
                              <Select
                                mode="multiple"
                                allowClear
                                style={{
                                  minWidth: 150,
                                  marginTop: 20
                                }}
                                placeholder="Select a region"
                                onChange={filterProducersByRegion}
                              >
                                <Option key="us" value={"us"}>US</Option>
                                <Option key="eu-west" value={"eu-west"}>EU-west</Option>
                                <Option key="eu-east" value={"eu-east"}>EU-east</Option>
                                <Option key="asia" value={"asia"}>Asia</Option>
                              </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </Card>
                      </Form>
                    </Col>
                    <Col span={24}>
                      <Card
                        style={{borderRadius: "0 0 5px 5px", width: "100%", border: "none"}}
                        bodyStyle={{width: "100%", padding: "6px 6px 6px 6px"}}
                      >
                        <div style={{height: xlState ? 'calc(100vh - 190px)' : 'calc(100vh - 487px)', overflowY: "auto", overflowX: "hidden", minHeight: 310}}>
                          <ProducerGrid
                            producers={filteredProducersByName}
                            itemCount={filteredProducersByName.length}
                            itemSize={300}
                            selectProducer={handleSelectCustomer}
                            selectedId={selectedProducerId}
                            searching={isSearching}
                            isFetchingProducers={isFetchingProducers}
                            emptyText={"No Producers"}
                          />
                        </div>
                        <Divider style={{marginTop: 5, marginBottom: 5}}/>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl={{order: 2, span: 8}} xs={{order: 1, span: 24}}>
                <div className={"pageSubtitle"}>
                  Producer Details
                </div>
                <div className={"summaryCard"}>
                  <Row>
                    <Col span={24}>
                      <Card
                        style={{borderRadius: 5, width: "100%", border: "none"}}
                        bodyStyle={{width: "100%", padding: "6px 6px 6px 6px"}}
                      >
                        <div style={{height: xlState ? 'calc(100vh - 313px)' : 250, overflowY: "hidden", overflowX: "auto", minHeight: 150}}>
                          {selectedProducerId === null ?
                            (
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
                                <EmptyTable text={"No Producer Selected"}/>
                              </div>
                            )
                          :
                            (
                              xlState ? <DetailsCardLarge /> : <DetailsCardSmall />
                            )
                          }
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Layout.Content>

      <Modal
        visible={addProducerModalVisible}
        title={"Create New Producer"}
        destroyOnClose={true}
        onCancel={handleCancelAddProducer}
        footer={<div>
          <Button onClick={handleCancelAddProducer}>
            Cancel
          </Button>
          <Button
            type={"primary"}
            form={"add-producer"}
            key={"submit"}
            htmlType={"submit"}
            loading={isCreatingProducer}
          >
            Create
          </Button>
        </div>}
        width={980}
        style={{ minWidth: 980 }}
        bodyStyle={{ minWidth: 980 }}
        closable={true}
      >
        <Form
          form={newProducerForm}
          name={"add-producer"}
          onFinish={submitAddProducerForm}
        >
          <Row>
            <Col span={8}>
              <div style={{ height: "100%", display: "flex", flexDirection: "row"}}>
                <div style={{ width: "100%"}}>
                  <div align={"center"}>
                    <div className={"newProducerLogo"}>
                      <Form.Item
                        name="logo"
                        label={"Logo"}
                        valuePropName="logo"
                      >
                        <Upload
                          name={"logo"}
                          listType={"picture-card"}
                          className={"newProducerLogoUpload"}
                          showUploadList={false}
                          accept={".jpg,.jpeg,.png"}
                          beforeUpload={(file) => {
                            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                            if (!isJpgOrPng) {
                              message.show(message.type.error, "Unsupported image format!")
                            }
                            const isLt2M = file.size / 1024 / 1024 < 2;
                            if (!isLt2M) {
                              message.show(message.type.error, "Maximum image size is 2MB!")
                            }
                            if (isJpgOrPng && isLt2M) {
                              setImageFile(file);
                              getBase64(file);
                              return false;
                            }
                            return false;
                          }}
                        >
                          {imageUrl ?
                            <img className={"uploadedImage"} src={imageUrl} alt="avatar"
                                 style={{maxWidth: '100%', maxHeight: '100%'}}/>
                            :
                            <div className={"uploadButton"}>
                              <PlusOutlined/>
                              <div style={{marginTop: 8}}>Upload</div>
                            </div>
                          }
                        </Upload>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <Divider type={"vertical"} style={{ margin: 6, minHeight: "100%" }}/>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ height: "100%", display: "flex", flexDirection: "row"}}>
                <div style={{ width: "100%"}}>
                  <div className={"producerFormItemSection"}>
                    <div style={{ width: "100%"}}>
                      <Form.Item
                        label={"Name"}
                        name={'producerName'}
                        initialValue={""}
                        validateTrigger={"onSubmit"}
                        className={"producerFormItem"}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required!',
                          },
                          {type: 'string', min: 2 ,message: 'Too short!'},
                          {type: 'string', max: 60 ,message: 'Too long!'},
                        ]}
                      >
                        <Input
                          className={"newProducerInput"}
                          style={{ width: "100%"}}
                          placeholder={"Enter the producers name"}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={"producerFormItemSection"}>
                    <div style={{ width: "100%"}}>
                      <Form.Item
                        label={"Email"}
                        name={'producerEmail'}
                        validateTrigger={"onSubmit"}
                        className={"producerFormItem"}
                        rules={[
                          {
                            required: true, message: 'This field is required'
                          },
                          {
                            type: 'email', message: 'Please enter a valid email address'
                          },
                        ]}
                      >
                        <Input
                          className={"newProducerInput"}
                          style={{ width: "100%"}}
                          placeholder={"Enter the producers email"}
                          autoComplete="new-password"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={"producerFormItemSection"}>
                    <div style={{ width: "100%"}}>
                      <Form.Item
                        label={"Password"}
                        name={'producerPassword'}
                        validateTrigger={"onSubmit"}
                        className={"producerFormItem"}
                        rules={[
                          {
                            required: true, message: 'This field is required'
                          },
                          {type: 'string', min: 6 ,message: 'Too short!'},
                          {type: 'string', max: 60 ,message: 'Too long!'}
                        ]}
                      >
                        <Input.Password
                          className={"newProducerInput"}
                          style={{ width: "100%"}}
                          placeholder={"Enter the producers password"}
                          autoComplete="new-password"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={"producerFormItemSection"}>
                    <div style={{ width: "100%"}}>
                      <Form.Item
                        label={"Admin Name"}
                        name={'adminName'}
                        validateTrigger={"onSubmit"}
                        className={"producerFormItem"}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required!',
                          },
                          {type: 'string', min: 2 ,message: 'Too short!'},
                          {type: 'string', max: 60 ,message: 'Too long!'},
                        ]}
                      >
                        <Input
                          className={"newProducerInput"}
                          style={{ width: "100%"}}
                          placeholder={"Enter the admin name"}
                          autoComplete="new-password"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className={"producerFormItemSection"}>
                    <div style={{ width: "100%"}}>
                      <Form.Item
                        label={"Phone"}
                        name={'producerPhone'}
                        validateTrigger={"onSubmit"}
                        className={"producerFormItem"}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required!',
                          },
                          {type: 'string', min: 2 ,message: 'Too short!'},
                          {type: 'string', max: 60 ,message: 'Too long!'},
                          {
                            type: 'string',
                            pattern: new RegExp(/^[+]*[(]?\d{1,4}[)]?[-\s./\d]*$/g),
                            message: 'Please specify a valid phone number'
                          },
                        ]}
                      >
                        <Input
                          className={"newProducerInput"}
                          style={{ width: "100%"}}
                          placeholder={"Enter the producers phone number"}
                          autoComplete="new-password"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <Divider type={"vertical"} style={{ margin: 6, minHeight: "100%" }}/>
              </div>
            </Col>
            <Col span={8}>
              <div className={"producerFormItemSection"}>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    label={"Region"}
                    name={'region'}
                    validateTrigger={"onSubmit"}
                    className={"producerFormItem"}
                    initialValue={null}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                      {type: 'string', min: 2 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                  >
                    <Select
                      showSearch
                      style={{width: '100%'}}
                      prefix={<GlobalOutlined/>}
                      suffixIcon={(e) => {
                        return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                      }}
                      placeholder="Select the producers region"
                      optionFilterProp="children"
                      notFoundContent={<span style={{color: "white"}}>Region not found</span>}
                      filterOption={(input, option) =>
                        option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      autoComplete="new-password"
                    >
                      {regions.map(item => {
                        return (
                          <Select.Option key={item.name} value={item.value} className={"countrySelect"}>
                            {item.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className={"producerFormItemSection"}>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    label={"Country"}
                    name={'country'}
                    rules={[
                      {required: true, message: 'This field is required.'},
                    ]}
                    validateTrigger={'onSubmit'}
                    className={"producerFormItem"}
                  >
                    <Select
                      showSearch
                      style={{width: '100%'}}
                      prefix={<GlobalOutlined/>}
                      suffixIcon={(e) => {
                        return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                      }}
                      placeholder="Select the producers country"
                      optionFilterProp="children"
                      notFoundContent={<span style={{color: "white"}}>Country not found</span>}
                      filterOption={(input, option) =>
                        option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      autoComplete="new-password"
                    >
                      {countries.map(item => {
                        return (
                          <Select.Option key={item.name} value={item.name} className={"countrySelect"}>
                            <img src={item.flag} alt="" style={{position: "relative", top: -2, width: 18}}/> {item.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className={"producerFormItemSection"}>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    label={"City"}
                    name={'city'}
                    validateTrigger={"onSubmit"}
                    className={"producerFormItem"}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                      {type: 'string', min: 2 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                  >
                    <Input
                      className={"newProducerInput"}
                      style={{ width: "100%"}}
                      placeholder={"Enter the producers city"}
                      autoComplete="new-password"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={"producerFormItemSection"}>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    label={"Postal Code"}
                    name={'postalCode'}
                    validateTrigger={"onSubmit"}
                    className={"producerFormItem"}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                      {type: 'string', min: 2 ,message: 'Too short!'},
                      {type: 'string', max: 60 ,message: 'Too long!'},
                    ]}
                  >
                    <Input
                      className={"newProducerInput"}
                      style={{ width: "100%"}}
                      placeholder={"Enter the producers postal code"}
                      autoComplete="new-password"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={"producerFormItemSection"}>
                <div style={{ width: "100%"}}>
                  <Form.Item
                    label={"Street Address"}
                    name={'address'}
                    validateTrigger={"onSubmit"}
                    className={"producerFormItem"}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required!',
                      },
                      {type: 'string', min: 2 ,message: 'Too short!'},
                      {type: 'string', max: 120 ,message: 'Too long!'},
                    ]}
                  >
                    <Input
                      className={"newProducerInput"}
                      style={{ width: "100%"}}
                      placeholder={"Enter the producers street name and number"}
                      autoComplete="new-password"
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>


      <Modal
        visible={editProducerModalVisible}
        title={"Edit Producer Details"}
        destroyOnClose={true}
        onCancel={handleCancelEditProducer}
        footer={null}
        width={980}
        style={{ minWidth: 980 }}
        bodyStyle={{ minWidth: 980 }}
        closable={true}
      >
        {selectedProducer &&
          <Row>
            <Col span={8}>
              <Form
                form={editProducerFormLogo}
                name={"edit-producer-logo"}
                onFinish={submitEditProducerFormLogo}
              >
                <Row>
                  <Col span={24}>
                    <div style={{ height: "100%", display: "flex", flexDirection: "row"}}>
                      <div style={{ width: "100%"}}>
                        <div align={"center"}>
                          <div
                            className={"newProducerLogo"}
                            style={{ flexDirection: "column", paddingTop: 16 }}
                          >
                            <Form.Item
                              name="logo"
                              label={"Logo"}
                              valuePropName="logo"
                            >
                              <Upload
                                name={"logo"}
                                listType={"picture-card"}
                                className={"newProducerLogoUpload"}
                                showUploadList={false}
                                accept={".jpg,.jpeg,.png"}
                                beforeUpload={(file) => {
                                  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                  if (!isJpgOrPng) {
                                    message.show(message.type.error, "Unsupported image format!")
                                  }
                                  const isLt2M = file.size / 1024 / 1024 < 2;
                                  if (!isLt2M) {
                                    message.show(message.type.error, "Maximum image size is 2MB!")
                                  }
                                  if (isJpgOrPng && isLt2M) {
                                    setImageFile(file);
                                    setChangedLogo(true)
                                    getBase64(file);
                                    return false;
                                  }
                                  return false;
                                }}
                              >
                                {selectedProducer.logoUrl && changedLogo &&
                                  <img
                                    className={"uploadedImage"}
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{maxWidth: '100%', maxHeight: '100%'}}
                                  />
                                }
                                {selectedProducer.logoUrl && !changedLogo &&
                                  <img
                                    className={"uploadedImage"}
                                    src={`${S3_BUCKET_URL}${selectedProducer.logoUrl}`}
                                    alt="avatar"
                                    style={{maxWidth: '100%', maxHeight: '100%'}}
                                  />
                                }
                                {!selectedProducer.logoUrl &&
                                  <div className={"uploadButton"}>
                                    <PlusOutlined/>
                                    <div style={{marginTop: 8}}>Upload</div>
                                  </div>
                                }
                              </Upload>
                            </Form.Item>
                            <div style={{
                              display: "flex", justifyContent: "flex-end", width: "100%",
                              position: "relative", top: 35
                            }}>
                              <Button
                                type={"primary"}
                                htmlType={"submit"}
                                disabled={!changedLogo}
                                loading={isUpdatingProducerLogo}
                              >
                                Update Logo
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Divider type={"vertical"} style={{ margin: "6px 6px 0 6px", minHeight: 360 }}/>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span={16}>
              <Form
                form={editProducerFormInfo}
                name={"edit-producer-info"}
                onFinish={submitEditProducerFormInfo}
              >
                <Row>
                  <Col span={12}>
                    <div style={{ height: "100%", display: "flex", flexDirection: "row"}}>
                      <div style={{ width: "100%"}}>
                        <div className={"producerFormItemSection"}>
                          <div style={{ width: "100%"}}>
                            <Form.Item
                              label={"Name"}
                              name={'producerName'}
                              initialValue={selectedProducer.name}
                              validateTrigger={"onSubmit"}
                              className={"producerFormItem"}
                              rules={[
                                {type: 'string', min: 2 ,message: 'Too short!'},
                                {type: 'string', max: 60 ,message: 'Too long!'},
                              ]}
                            >
                              <Input
                                className={"newProducerInput"}
                                style={{ width: "100%"}}
                                placeholder={"Enter the producers name"}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className={"producerFormItemSection"}>
                          <div style={{ width: "100%"}}>
                            <Form.Item
                              label={"Phone"}
                              name={'producerPhone'}
                              validateTrigger={"onSubmit"}
                              initialValue={selectedProducer.phone}
                              className={"producerFormItem"}
                              rules={[
                                {type: 'string', min: 2 ,message: 'Too short!'},
                                {type: 'string', max: 60 ,message: 'Too long!'},
                                {
                                  type: 'string',
                                  pattern: new RegExp(/^[+]*[(]?\d{1,4}[)]?[-\s./\d]*$/g),
                                  message: 'Please specify a valid phone number'
                                },
                              ]}
                            >
                              <Input
                                className={"newProducerInput"}
                                style={{ width: "100%"}}
                                placeholder={"Enter the producers phone number"}
                                autoComplete="new-password"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className={"producerFormItemSection"}>
                          <div style={{ width: "100%"}}>
                            <Form.Item
                              label={"Region"}
                              name={'region'}
                              validateTrigger={"onSubmit"}
                              initialValue={selectedProducer.region}
                              className={"producerFormItem"}
                              rules={[
                                {type: 'string', min: 2 ,message: 'Too short!'},
                                {type: 'string', max: 60 ,message: 'Too long!'},
                              ]}
                            >
                              <Select
                                showSearch
                                style={{width: '100%'}}
                                prefix={<GlobalOutlined/>}
                                suffixIcon={(e) => {
                                  return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                                }}
                                placeholder="Select the producers region"
                                optionFilterProp="children"
                                notFoundContent={<span style={{color: "white"}}>Region not found</span>}
                                filterOption={(input, option) =>
                                  option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                autoComplete="new-password"
                              >
                                {regions.map(item => {
                                  return (
                                    <Select.Option key={item.name} value={item.value} className={"countrySelect"}>
                                      {item.name}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div className={"producerFormItemSection"}>
                          <div style={{ width: "100%"}}>
                            <Form.Item
                              label={"Country"}
                              name={'country'}
                              validateTrigger={'onSubmit'}
                              initialValue={selectedProducer.country}
                              className={"producerFormItem"}
                            >
                              <Select
                                showSearch
                                style={{width: '100%'}}
                                prefix={<GlobalOutlined/>}
                                suffixIcon={(e) => {
                                  return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                                }}
                                placeholder="Select the producers country"
                                optionFilterProp="children"
                                notFoundContent={<span style={{color: "white"}}>Country not found</span>}
                                filterOption={(input, option) =>
                                  option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                autoComplete="new-password"
                              >
                                {countries.map(item => {
                                  return (
                                    <Select.Option key={item.name} value={item.name} className={"countrySelect"}>
                                      <img src={item.flag} alt="" style={{position: "relative", top: -2, width: 18}}/> {item.name}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div className={"producerFormItemSection"}>
                          <div style={{ width: "100%"}}>
                            <Form.Item
                              label={"City"}
                              name={'city'}
                              validateTrigger={"onSubmit"}
                              initialValue={selectedProducer.city}
                              className={"producerFormItem"}
                              rules={[
                                {type: 'string', min: 2 ,message: 'Too short!'},
                                {type: 'string', max: 60 ,message: 'Too long!'},
                              ]}
                            >
                              <Input
                                className={"newProducerInput"}
                                style={{ width: "100%"}}
                                placeholder={"Enter the producers city"}
                                autoComplete="new-password"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <Divider type={"vertical"} style={{ margin: 6, minHeight: "100%" }}/>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                      <div>
                        <div className={"producerFormItemSection"}>
                          <div style={{ width: "100%"}}>
                            <Form.Item
                              label={"Postal Code"}
                              name={'postalCode'}
                              validateTrigger={"onSubmit"}
                              initialValue={selectedProducer.postalCode}
                              className={"producerFormItem"}
                              rules={[
                                {type: 'string', min: 2 ,message: 'Too short!'},
                                {type: 'string', max: 60 ,message: 'Too long!'},
                              ]}
                            >
                              <Input
                                className={"newProducerInput"}
                                style={{ width: "100%"}}
                                placeholder={"Enter the producers postal code"}
                                autoComplete="new-password"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className={"producerFormItemSection"}>
                        <div style={{ width: "100%"}}>
                          <Form.Item
                            label={"Street Address"}
                            name={'address'}
                            validateTrigger={"onSubmit"}
                            initialValue={selectedProducer.address}
                            className={"producerFormItem"}
                            rules={[
                              {type: 'string', min: 2 ,message: 'Too short!'},
                              {type: 'string', max: 120 ,message: 'Too long!'},
                            ]}
                          >
                            <Input
                              className={"newProducerInput"}
                              style={{ width: "100%"}}
                              placeholder={"Enter the producers street name and number"}
                              autoComplete="new-password"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      </div>
                      <div align={"right"}>
                        <Button
                          type={"primary"}
                          htmlType={"submit"}
                          loading={isUpdatingProducerInfo}
                        >
                          Update Info
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        }
      </Modal>


    </Layout>
  )
}