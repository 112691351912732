import React from "react";
import { Trans } from "react-i18next";
import { S3_BUCKET_URL } from "../../constants";
import twoD from "../../assets/icons/2D.svg";
import treeD from "../../assets/icons/3D.svg";
import { getPlaceholderImage, getProfileType } from "../utility";

export const materialInfoSection = (part) => {
  switch (part.partType) {
    case "sheetMetal":
      return (
        <div style={{fontSize: 12, fontWeight: 500}}>
          Material: <span style={{fontWeight: 600}}><Trans>{part.selectedMaterial.grade}</Trans> ({part.selectedMaterial.thickness}mm)</span>
        </div>
      )
    case "milled":
    case "lathed":
      return (
        <div style={{fontSize: 12, fontWeight: 500}}>
          Material: <span style={{fontWeight: 600}}><Trans>{part.selectedMaterial?.grade || part.materialGrade}</Trans></span>
        </div>
      )
    case "profile":
    case "tubeCut":
      return (
        <div style={{width: "100%"}}>
          <table style={{width: "100%"}}>
            <tbody>
            <tr>
              <td style={{width: "calc(100% - 100px)", maxWidth: 150}}>
                <div style={{fontSize: 12, marginLeft: -1, fontWeight: 500, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                  Profile Type: <span style={{fontSize: 11, fontWeight: 600}}>{(part.isTubeCut ? getProfileType(part) : part.standard) || "/"}</span>
                </div>
              </td>
              <td style={{width: 100}}/>
            </tr>
            </tbody>
          </table>
        </div>
      )
    case "buyPart":
    case "bulkMaterial":
      return (
        <div style={{width: "100%"}}>
          <table style={{width: "100%"}}>
            <tbody>
            <tr>
              <td style={{width: "calc(100% - 100px)", maxWidth: 150}}>
                <div style={{fontSize: 12, fontWeight: 500, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                  OEM: <span style={{fontSize: 11, fontWeight: 600}}>{part.standard || "/"}</span>
                </div>
              </td>
              <td style={{width: 100}}/>
            </tr>
            </tbody>
          </table>
        </div>
      )
    case "assembly":
      return (
        <div style={{fontSize: 12, fontWeight: 500}}>
          Number of Unique Parts: <span style={{fontWeight: 600}}>{part.numberOfUniqueParts || 1}</span>
        </div>
      )
    default:
      return <div style={{height: 20}}/>
  }
}

export const imageSection = (part) => {
  return (
    <div className={"archivedItemContainer"}>
      {part.fileType === "dxf" &&
        <div>
          {part.dxfData?.pngFilePath ?
            <div className={"partImgContainer"}>
              <div>
                <img
                  className={"partImgBigger"}
                  src={S3_BUCKET_URL + part.dxfData.pngFilePath}
                  alt={""}
                />
              </div>
            </div>
            :
            <div className={"partImgContainer"}>
              <img
                src={twoD}
                alt={""}
              />
            </div>
          }
        </div>
      }
      {part.fileType === "step" &&
        <div>
          {part.stepData?.filePaths?.pngFilePath ?
            <img
              className={"partImgMid"}
              src={S3_BUCKET_URL + part.stepData.filePaths.pngFilePath}
              alt={""}
            />
            :
            <div className={"partImgContainer"}>
              <img
                src={treeD}
                alt={""}
              />
            </div>
          }
        </div>
      }
      {(part.fileType === "other" || (!part.fileType && part.processingType === "manual")) &&
        <div className={"partImgContainer"}>
          <img
            src={getPlaceholderImage(part.partType)}
            style={{padding: 3}}
            alt={""}
          />
        </div>
      }
    </div>
  )
}

export const getTubeData = ({ item }) => {
  let tokens = item.selectedMaterial.grade.split(" - ")
  const thickness = item.selectedMaterial.thickness
  const x = item.stepData.partData.tubeData.tubeDimensions[0]
  const y = item.stepData.partData.tubeData.tubeDimensions[1]
  const type = item.stepData.partData.tubeData.tubeType
  let dimensions = `${x}x${y}x${thickness}`;
  if (type === 'circle') {
    dimensions = `${x}x${thickness}`;
  }
  return [tokens[0], tokens[1], dimensions]
}