import React, {useState} from "react";
import { Button, Card, Col, Divider, Modal, Row, Tooltip } from "antd";
import {
  FileSearchOutlined
} from "@ant-design/icons";
import {formatDate} from "../../../utils/utility";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import { imageSection, materialInfoSection } from "../../../utils/partCardUtils/sections";


const LeftItem = (props) => {
  const {oldPart, editType} = props;
  return (
    <Card
      className={"bendCard"}
      style={{height: 80, marginTop: 12, marginBottom: 12, backgroundColor: oldPart.assemblyId ? "#354045" : "", maxWidth: 419}}
      bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
    >
      <div style={{display: "flex", width: "100%"}}>
        {imageSection(oldPart)}
        <div style={{width: "100%"}}>
          <div>
            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
              <div
                className={"partInfo"}
                style={{display: "block", maxWidth: oldPart.assemblyId ? "294px" : "320px"}}
              >
                <Tooltip
                  overlayClassName={"delete-tooltip"}
                  color={"rgba(82,82,82,0.9)"}
                  title={<span className={"text12-500"} style={{color: "white"}}>{oldPart.fileName || oldPart.name}</span>}
                >
                  {oldPart.fileName || oldPart.name}
                </Tooltip>
              </div>
              <div>
                <div style={{height: 24}}/>
              </div>
            </div>
            <div>
              {materialInfoSection(oldPart)}
              <div style={{display:"flex", justifyContent: "space-between"}}>
                <div style={{fontSize: 12, fontWeight: 500, marginRight: 8}}>
                  Quantity: <span style={{fontWeight: 600}}>{oldPart.quantity}</span>
                </div>
                {editType === "newQuote" || oldPart.quoteType === 'unquoted' ?
                  <div style={{fontSize: 12, fontWeight: 500}}>
                    1pc: <span style={{fontWeight: 600}}>RFQ</span>
                  </div>
                  :
                  <div style={{fontSize: 12, fontWeight: 500}}>
                    1pc: <span style={{fontWeight: 600}}>{(parseFloat(oldPart.pricePerPiece) || 0).toFixed(2)} €</span>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

const RightItem = (props) => {
  const {newPart, editType} = props;

  const getEditType = (type) => {
    switch (type) {
      case "delete":
        return "Rejected"
      case "newQuote":
        return "Quoted"
      case "priceChange":
        return "Edited"
      default:
        return ""
    }
  }

  return (
    <Card
      className={`bendCard ${editType === "delete" ? "declinedCard" : "changedCard"}`}
      style={{height: 80, marginTop: 12, marginBottom: 12, backgroundColor: newPart.assemblyId ? "#354045" : "", maxWidth: 419}}
      bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
    >
      <div style={{display: "flex", width: "100%"}}>
        {imageSection(newPart)}
        <div style={{width: "100%"}}>
          <div>
            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
              <div
                className={"partInfo"}
                style={{display: "block", maxWidth: newPart.assemblyId ? "245px" : "280px"}}
              >
                <Tooltip
                  overlayClassName={"delete-tooltip"}
                  color={"rgba(82,82,82,0.9)"}
                  title={<span className={"text12-500"} style={{color: "white"}}>{newPart.fileName || newPart.name}</span>}
                >
                  {newPart.fileName || newPart.name}
                </Tooltip>
              </div>
              <div>
                <div style={{height: 24, fontSize: 12, fontWeight: 600}}>
                  {getEditType(editType)}
                </div>
              </div>
            </div>
            <div>
              {materialInfoSection(newPart)}
              <div style={{display:"flex", justifyContent: "space-between"}}>
                <div
                  style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                >
                  Quantity: <span style={{fontWeight: 600}}>{newPart.quantity}</span>
                </div>
                {editType === "delete" ?
                  <div
                    style={{fontSize: 12, fontWeight: 500}}
                  >
                    1pc: <span style={{fontWeight: 600}}>0.00 €</span>
                  </div>
                  :
                  <div
                    style={{fontSize: 12, fontWeight: 500}}
                  >
                    1pc: <span style={{fontWeight: 600, color: editType !== 'delete' ? "#52c41a" : "inherit"}}>{(parseFloat(newPart.pricePerPiece) || 0).toFixed(2)} €</span>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

const OrderPartItem = (props) => {
  const { oldPart, newPart, item } = props;

  return (
    <div style={{display: "flex"}}>
      <LeftItem
        oldPart={oldPart}
        editType={item.editType}
      />
      <div align={"center"} style={{display: "flex"}}>
        <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
      </div>
      <RightItem
        newPart={newPart}
        editType={item.editType}
      />
    </div>
  )
}

const AssemblyOrderItem = (props) => {
  const { oldPart, newPart, item, parts } = props;

  const getEditType = (type) => {
    switch (type) {
      case "delete":
      case "declined":
        return "Rejected"
      case "priceChange":
      case "edited":
        return "Edited"
      default:
        return ""
    }
  }

  return (
    <div style={{display: "flex"}}>
      <div style={{width: "100%"}}>
        <Card
          style={{ maxWidth: 419 }}
          className={"assemblyPartsCard"}
          key={oldPart.id}
        >
          <div
            className={"assemblyPartName"}

          >
            <div
              style={{display: "flex", width: "100%"}}
            >
              {imageSection(newPart)}
              <div style={{width: "100%"}}>
                <div>
                  <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <div className={"partInfo"}
                         style={{ maxWidth: 200 }}
                    >
                      <Tooltip
                        overlayClassName={"delete-tooltip"}
                        color={"rgba(82,82,82,0.9)"}
                        title={<span className={"text12-500"} style={{color: "white"}}>{newPart.fileName || newPart.name}</span>}
                      >
                        {newPart.fileName || newPart.name}
                      </Tooltip>
                    </div>
                    <div>
                      <div style={{height: 24}}/>
                    </div>
                  </div>
                  <div>
                    {materialInfoSection(oldPart)}
                    <div style={{display:"flex", justifyContent: "space-between"}}>
                      <div style={{fontSize: 12, fontWeight: 500, marginRight: 8}}>
                        Number of Assemblies: <span style={{fontWeight: 600}}>{oldPart.quantity}</span>
                      </div>
                      {item.editType === "newQuote" || oldPart.quoteType === 'unquoted' ?
                        <div style={{fontSize: 12, fontWeight: 500}}>
                          1pc: <span style={{fontWeight: 600}}>RFQ</span>
                        </div>
                        :
                        <div style={{fontSize: 12, fontWeight: 500}}>
                          1pc: <span style={{fontWeight: 600}}>{(parseFloat(oldPart.pricePerPiece) || 0).toFixed(2)} €</span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{marginTop: 5}}>
              <Row gutter={[4, 4]}>
                <Col span={12}>
                  <Card style={{background: "#354045"}} bodyStyle={{padding: "2px 0 2px 6px"}}>
                    <div style={{fontSize: 11, fontWeight: 500}}>
                      Assembling Price: <span style={{fontWeight: 600}}>RFQ</span>
                    </div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{background: "#354045"}} bodyStyle={{padding: "2px 0 2px 6px"}}>
                    <div style={{fontSize: 11, fontWeight: 500}}>
                      Welding Price: <span style={{fontWeight: 600}}>RFQ</span>
                    </div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{background: "#354045"}} bodyStyle={{padding: "2px 0 2px 6px"}}>
                    <div style={{fontSize: 11, fontWeight: 500}}>
                      Others: <span style={{fontWeight: 600}}>RFQ</span>
                    </div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{background: "#354045"}} bodyStyle={{padding: "2px 0 2px 6px"}}>
                    <div style={{fontSize: 11, fontWeight: 500}}>
                      Machine Processing Price: <span style={{fontWeight: 600}}>RFQ</span>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          <div className={"assemblyParts"} style={{paddingBottom: 0}}>
            {parts.map((item, index) => {
              const part = item.oldPart || item.oldAssembly
              return (
                part?.assemblyId === oldPart.id ?
                  <div key={part.id} style={{marginTop: -3, marginBottom: -3}}>
                    <LeftItem
                      oldPart={part}
                      editType={item.editType}
                    />
                  </div>
                  :
                  <div key={index}/>
              )
            })}
          </div>
        </Card>
      </div>
      <div align={"center"} style={{display: "flex"}}>
        <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
      </div>
      <div style={{width: "100%"}}>
        <Card
          style={{ maxWidth: 419 }}
          className={"assemblyPartsCard"}
          key={oldPart.id}
        >
          <div
            className={newPart.state === "edited" ? "changedCard assemblyPartName" : "declinedCard assemblyPartName"}
          >
            <div
              style={{display: "flex", width: "100%"}}
            >
              {imageSection(newPart)}
              <div style={{width: "100%"}}>
                <div>
                  <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <div className={"partInfo"}
                         style={{ maxWidth: 200 }}
                    >
                      <Tooltip
                        overlayClassName={"delete-tooltip"}
                        color={"rgba(82,82,82,0.9)"}
                        title={<span className={"text12-500"} style={{color: "white"}}>{newPart.fileName || newPart.name}</span>}
                      >
                        {newPart.fileName || newPart.name}
                      </Tooltip>
                    </div>
                    <div>
                      <div style={{height: 24, fontSize: 12, fontWeight: 600}}>
                        {getEditType(item.editType || newPart.state)}
                      </div>
                    </div>
                  </div>
                  <div>
                    {materialInfoSection(newPart)}
                    <div style={{display:"flex", justifyContent: "space-between"}}>
                      <div
                        style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                      >
                        Number of Assemblies: <span style={{fontWeight: 600}}>{newPart.quantity}</span>
                      </div>
                      {item.editType === "delete" ?
                        <div
                          style={{fontSize: 12, fontWeight: 500}}
                        >
                          1pc: <span style={{fontWeight: 600}}>0.00 €</span>
                        </div>
                        :
                        <div
                          style={{fontSize: 12, fontWeight: 500}}
                        >
                          1pc: <span style={{fontWeight: 600, color: newPart.state !== 'declined' ? "#52c41a" : "inherit"}}>{(parseFloat(newPart.pricePerPiece) || 0).toFixed(2)} €</span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{marginTop: 5}}>
              <Row gutter={[4, 4]}>
                <Col span={12}>
                  <Card style={{background: "#354045"}} bodyStyle={{padding: "2px 0 2px 6px"}}>
                    <div style={{fontSize: 11, fontWeight: 500}}>
                      Assembling Price: <span style={{fontWeight: 600}}>{(parseFloat(newPart.assemblingPrice) || 0).toFixed(2)} €</span>
                    </div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{background: "#354045"}} bodyStyle={{padding: "2px 0 2px 6px"}}>
                    <div style={{fontSize: 11, fontWeight: 500}}>
                      Welding Price: <span style={{fontWeight: 600}}>{(parseFloat(newPart.weldingPrice) || 0).toFixed(2)} €</span>
                    </div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{background: "#354045"}} bodyStyle={{padding: "2px 0 2px 6px"}}>
                    <div style={{fontSize: 11, fontWeight: 500}}>
                      Others: <span style={{fontWeight: 600}}>{(parseFloat(newPart.transportationPrice) || 0).toFixed(2)} €</span>
                    </div>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{background: "#354045"}} bodyStyle={{padding: "2px 0 2px 6px"}}>
                    <div style={{fontSize: 11, fontWeight: 500}}>
                      Machine Processing Price: <span style={{fontWeight: 600}}>{(parseFloat(newPart.packagingPrice) || 0).toFixed(2)} €</span>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
          <div className={"assemblyParts"} style={{paddingBottom: 0}}>
            {parts.map((item, index) => {
              const part = item.newPart || item.newAssembly
              return (
                part?.assemblyId === newPart.id ?
                  <div key={part.id} style={{marginTop: -3, marginBottom: -3}}>
                    <RightItem
                      newPart={part}
                      editType={item.editType}
                    />
                  </div>
                  :
                  <div key={index}/>
              )
            })}
          </div>
      </Card>
      </div>
    </div>
  )
}

export const OrderChanges = (props) => {

  const {order,size} = props

  const [changesVisible, setChangesVisible] = useState(false)

  return (
    <div>
      <Button
        type="primary"
        style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
        onClick={()=>setChangesVisible(true)}
        size={size}
      >
        <FileSearchOutlined style={{fontSize: 14}}/>
        View Changes
      </Button>

      <Modal
        visible={changesVisible}
        centered={true}
        onCancel={()=> setChangesVisible(false)}
        title="Quote Changes"
        footer={null}
        destroyOnClose={true}
        width={950}
      >
        {order.deliveryDate !== order?.["archive"].deliveryDate ?
          <div style={{ color: "white" }}>
            <div style={{fontWeight: 400, fontSize: 13, color: "white"}}>Delivery Date:</div>
            <div style={{display: "flex"}}>
              <div style={{fontWeight: 600, fontSize: 14, color: "#e03838"}}>
                {formatDate(order["archive"].deliveryDate)}
              </div>
              <div align={"center"} style={{display: "flex"}}>
                <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
              </div>
              <div style={{fontWeight: 600, fontSize: 14, color: "#52c41a"}}>
                {formatDate(order.deliveryDate)}
              </div>
            </div>
            <Divider style={{marginTop: 10, marginBottom: 10, borderColor: "#838f94"}}/>
          </div>
          :
          null
        }
        {order?.["archive"].edits.length !== 0 ?
          <div style={{ color: "white" }}>
            <div style={{fontWeight: 400, fontSize: 13, color: "white"}}>Total Price:</div>
            <div style={{display: "flex"}}>
              <div style={{fontWeight: 600, fontSize: 14, color: "#e03838"}}>
                € {(order["archive"].price|| 0).toFixed(2)}
              </div>
              <div align={"center"} style={{display: "flex"}}>
                <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
              </div>
              <div style={{fontWeight: 600, fontSize: 14, color: "#52c41a"}}>
                € {(order.totalPrice || 0).toFixed(2)}
              </div>
            </div>
            <Divider style={{marginTop: 10, marginBottom: 20, borderColor: "#838f94"}}/>
            <div
              className={"quotedItemsList"}
              style={{
                maxHeight: 410,
                padding: "0 6px 0 6px",
                overflowX: "hidden"
              }}
            >
              {order["archive"].edits.map((item, index) => {
                const oldPart = item.oldPart || item.oldAssembly
                const newPart = item.newPart || item.newAssembly
                return (
                  !oldPart.assemblyId ?
                  <div key={newPart.id}>
                    {oldPart.partType === "assembly" ?
                      <AssemblyOrderItem
                        oldPart={oldPart}
                        newPart={newPart}
                        item={item}
                        parts={order["archive"].edits}
                      />
                      :
                      <OrderPartItem
                        oldPart={oldPart}
                        newPart={newPart}
                        item={item}
                      />
                    }
                    <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                  </div>
                  :
                  <div key={index}/>
                )
              })}
              {order["archive"].newParts && order["archive"].newParts.map(item => {
                return (
                  <div key={item.id || item._id}>
                    <Card
                      className={"changedCard bendCard"}
                      style={{height: 80, marginTop: 12, marginBottom: 12}}
                      bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                    >
                      <div style={{display: "flex", width: "100%"}}>
                        {imageSection(item)}
                        <div style={{width: "100%"}}>
                          <div>
                            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                              <div
                                className={"partInfo"}
                                style={{ maxWidth: 280 }}
                              >
                                {item.fileName || item.name}
                              </div>
                              <div>
                                <div style={{height: 24, fontSize: 12, fontWeight: 600}}>
                                  Additionally Added
                                </div>
                              </div>
                            </div>
                            <div>
                              {materialInfoSection(item)}
                              <div style={{display:"flex", justifyContent: "space-between"}}>
                                <div
                                  style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                                >
                                  Quantity: <span style={{fontWeight: 600}}>{item.quantity}</span>
                                </div>
                                <div
                                  style={{fontSize: 12, fontWeight: 500}}
                                >
                                  1pc: <span style={{fontWeight: 600, color: "#52c41a"}}>{(parseFloat(item.pricePerPiece) || 0).toFixed(2)} €</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Divider style={{marginTop: 0, marginBottom: 0}}/>
                  </div>
                )
              })}
            </div>
          </div>
          :
          null
        }
      </Modal>
    </div>
  )
}