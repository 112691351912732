import React, {Fragment, useState, useEffect} from 'react';
import {Layout, Button, Col, Row, Table, Input, Modal, Checkbox, Divider, InputNumber, Spin} from 'antd';

import {logoutUser} from '../../../actions/authActions';
import {
  changeProducerBulk, changeProducerNesting,
  changeProducerOrders,
  fetchProducers, fetchProducer
} from '../../../actions/producersActions';
import { AdminSidebar} from '../../../components/admin';
import {useDispatch, useSelector} from 'react-redux';
import http from "../../../utils/http";
import {API_BASE_URL} from "../../../constants";
import {LoadingOutlined} from "@ant-design/icons";
import {getColumnSearchProps} from "../../../utils/utility";
import "./style.less";
import {EmptyTable} from "../../../components/EmptyTable";

export const DetailEntryText = (props) => {
  return (
    <div className={"detailEntry"}>
      <div align={"right"} className={"inputInfoText"}
           style={{ marginRight: 12, width: 240, whiteSpace: "nowrap" }}
      >
        {props.fieldName}:
      </div>
      <Input defaultValue={props.value}
             disabled
      />
    </div>
  )
}

const DetailEntryCheckbox = (props) => {
  return (
    <div className={"detailEntry"}>
      <div align={"right"} className={"inputInfoText"}
           style={{ marginRight: 12, width: 151, whiteSpace: "nowrap" }}
      >
        {props.fieldName}:
      </div>
      <div className={props.value ? "trueDisabledCheckbox" : "falseDisabledCheckbox"}>
        <Checkbox checked={props.value}
                  disabled
        />
      </div>
    </div>
  )
}

const DetailEntryNumber = (props) => {
  return (
    <div className={"inlineField"}>
      <div align={"right"} className={"inputInfoText"}
           style={{ marginRight: 12, width: 151, whiteSpace: "nowrap" }}
      >
        {props.fieldName}:
      </div>
      <div className={"inputClass totalEarnings"}>
        <InputNumber defaultValue={props.value}
                     disabled
                     formatter={value => `${value}€`}
                     parser={value => value.replace('€', '')}
        />
      </div>
    </div>
  )
}

const DoubleDetailEntryNumber = (props) => {
  return (
    <div className={"inlineDetailEntry"}>
      <div className={"inlineField"}>
        <div align={"right"} className={"inputInfoText"}
             style={{ marginRight: 12, width: 151, whiteSpace: "nowrap" }}
        >
          {props.fieldName_first}:
        </div>
        <div className={"inputClass"}>
          <InputNumber defaultValue={props.value_first}
                       disabled
          />
        </div>
      </div>

      <div align={"right"} className={"inlineField"}>
        <div className={"inputInfoText"}
             style={{ marginRight: 12, whiteSpace: "nowrap" }}
        >
          {props.fieldName_second}:
        </div>
        <div className={"inputClass"}>
          <InputNumber defaultValue={props.value_second}
                       disabled
          />
        </div>
      </div>
    </div>
  )
}
// TODO UNUSED
export const AllProducers = (props) => {
  const [details, setDetails] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isBlockVisible, setIsBlockVisible] = useState(false)
  const [isBlocking, setIsBlocking] = useState(false)
  const [isUnblockVisible, setIsUnblockVisible] = useState(false)
  const [isUnblocking, setIsUnblocking] = useState(false)
  const [actionId, setActionId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteEmail, setDeleteEmail] = useState(null)
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false)

  const [blockedProducers, setBlockedProducers] = useState(new Set());
  const [producerData, setProducerData] = useState(null)

  const producers = useSelector(state => state.producers.producers)
  const fetchingProducers = useSelector(state => state.producers.fetchingProducers)
  const pagination = useSelector(state => state.producers.producersPagination)
  const isCollapsed = useSelector(state => state.util.isCollapsed);

  const dispatch = useDispatch();
  let searchInput = null;

  useEffect(() => {
    initializeData();
  }, [])

  const handleClose = () => {
    setIsVisible(false);
    setDetails(null)
  }

  const initializeData = () => {
    dispatch(fetchProducers({
      logout_user: logoutUser,
      page: pagination.current !== undefined ? pagination.current : 1,
      pagination: pagination,
      email: "",
      name: ""
    }))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    dispatch(fetchProducers({
      logout_user: logoutUser,
      page: pagination.current,
      pagination: pager,
      email: filters.email || "",
      name: filters.name || ""
    }))
  }

  const viewProducerDetails = (producerId) => {
    dispatch(fetchProducer({producerId}))
      .then((prodData) => {
        setProducerData(prodData)
      })
    setIsDetailsModalVisible(true);
  }

  const handleBulkChange = (producerId, value) => {
    const set = blockedProducers
    set.add(producerId)
    setBlockedProducers(new Set(set));
    dispatch(changeProducerBulk({ producerId: producerId, hasBulk: value}))
      .then((prodId) => {
        const set = blockedProducers
        set.delete(prodId)
        setBlockedProducers(new Set(set));
      })
  }

  const handleNestingChange = (producerId, value) => {
    const set = blockedProducers
    set.add(producerId)
    setBlockedProducers(new Set(set));
    dispatch(changeProducerNesting({ producerId: producerId, hasNesting: value}))
      .then((prodId) => {
        const set = blockedProducers
        set.delete(prodId)
        setBlockedProducers(new Set(set));
      })
  }

  const handleOrderChange = (producerId, value) => {
    const set = blockedProducers
    set.add(producerId)
    setBlockedProducers(new Set(set));
    dispatch(changeProducerOrders({ producerId: producerId, hasOrdering: value}))
      .then((prodId) => {
        const set = blockedProducers
        set.delete(prodId)
        setBlockedProducers(new Set(set));
      })
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <AdminSidebar onLogout={() => dispatch(logoutUser({}))} />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
          <Row type={"flex"}>
            <Col span={24}>
              <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                <div className={"pageTitle"}>These are all the registered producers:</div>
              </div>
              <Divider style={{marginTop: 6, marginBottom: 11}}/>
            </Col>
          </Row>
          <Row type={"flex"}>
            <Col span={24}>
              <Fragment>
                <Table
                  columns={[
                    {
                      title: 'Email',
                      key: 'email',
                      className: "darkerColumn",
                      render: row => (
                        <div>
                          <span style={{fontWeight: 500, fontSize: 13}}>{row.email}</span>
                        </div>
                      ),
                      ...getColumnSearchProps('email', searchInput),
                    },
                    {
                      title: 'Domain',
                      key: 'domain',
                      render: row => (
                        <div>
                          <span style={{fontWeight: 500, fontSize: 13}}>{row.domain}</span>
                        </div>
                      ),
                    },
                    {
                      title: 'Name',
                      key: 'name',
                      className: "darkerColumn",
                      render: row => (
                        <div>
                          <span style={{fontWeight: 500, fontSize: 13}}>{row.name}</span>
                        </div>
                      ),
                      ...getColumnSearchProps('name', searchInput),
                    },
                    {
                      title: 'Bulk',
                      key: 'bulk',
                      align: 'center',
                      render: row => (
                        <div>
                          <Checkbox defaultChecked={row.hasBulkFunction}
                                    onChange={(e) => {
                                      handleBulkChange(row.id, e.target.checked)
                                    }}
                                    disabled={blockedProducers.has(row.id)}
                          />
                        </div>
                      )
                    },
                    {
                      title: 'Nesting',
                      key: 'nesting',
                      align: 'center',
                      className: "darkerColumn",
                      render: row => (
                        <div>
                          <Checkbox defaultChecked={row.hasNestingFunction}
                                    onChange={(e) => {
                                      handleNestingChange(row.id, e.target.checked)
                                    }}
                                    disabled={blockedProducers.has(row.id)}
                          />
                        </div>
                      )
                    },
                    {
                      title: 'Order',
                      key: 'order',
                      align: 'center',
                      render: row => (
                        <div>
                          <Checkbox defaultChecked={row.hasOrderFunction}
                                    onChange={(e) => {
                                      handleOrderChange(row.id, e.target.checked)
                                    }}
                                    disabled={blockedProducers.has(row.id)}
                          />
                        </div>
                      )
                    },
                    {
                      title: "Blocked",
                      className: "darkerColumn",
                      align: 'center',
                      key: "isBlocked",
                      render: row => (
                        <div className={row.isBlocked ? "trueDisabledCheckbox" : "falseDisabledCheckbox"}>
                          <Checkbox checked={row.isBlocked}
                                    disabled
                          />
                        </div>
                      )
                    },
                    {
                      title: 'Details',
                      key: 'details',
                      align: 'center',
                      render: row => {
                        return (
                          <Button
                            onClick={() => {
                              viewProducerDetails(row.id)
                            }}
                            type="primary">
                            <span style={{fontWeight: 600, fontSize: 13}}>Details</span>
                          </Button>)
                      },
                    },
                    {
                      title: 'Action',
                      className: "darkerColumn",
                      key: 'action',
                      align: 'center',
                      render: row => {
                        return (
                          <Button
                            onClick={() => {

                            }}
                            type="danger"
                            style={{ width: 88 }}
                          >
                            <span style={{fontWeight: 600, fontSize: 13}}>{row.isBlocked ? "Unblock" : "Block"}</span>
                          </Button>
                        )
                      },
                    }
                  ]}
                  className={"antTable"}
                  dataSource={producers}
                  rowKey="id"
                  loading={{spinning: fetchingProducers, indicator: <LoadingOutlined style={{ fontSize: 50,marginLeft: "-25px" }} spin />}}
                  onChange={handleTableChange}
                  pagination={pagination}
                  size={"small"}
                  scroll={{x: '70%'}}
                  locale={{ emptyText: <EmptyTable text={"No Data"} /> }}
                />
              </Fragment>
            </Col>
          </Row>
        </div>

        <Modal
          onCancel={handleClose}
          title="Details"
          visible={isVisible}
          footer={null}
          centered={true}
        >
          {!details ? null : (
            <Fragment>
              <Row>
                <Col span={14}>
                  Email:
                  <h3>{details.email}</h3>
                  Name:
                  <h3>{details.company.data.name}</h3>
                  Address 1:
                  <h3>{details.company.data.street_address1}</h3>
                  City:
                  <h3>{details.company.data.city}</h3>
                  Postal Code:
                  <h3>{details.company.data.postal_code}</h3>
                  Region:
                  <h3>{details.company.data.region}</h3>
                  Country:
                  <h3>{details.company.data.country}</h3>
                </Col>
                <Col span={10}>
                  Cutting price:
                  <h3>{details.company.data.laserCuttingServiceProvider.data.cutting_price_per_hour}€</h3>
                  Engineering:
                  <h3>{details.company.data.laserCuttingServiceProvider.data.engineering_price_per_hour}€</h3>
                  VAT number:
                  <h3>{details.company.data.vat_number}</h3>
                  ID number:
                  <h3>{details.company.data.id_number}</h3>
                  Contact Name:
                  <h3>{details.company.data.contact_person_first_name} {details.company.data.contact_person_last_name}</h3>
                  Phone number:
                  <h3>{details.company.data.contact_phone}</h3>
                  Field of activity:
                  <h3>{details.company.data.field_of_activity}</h3>
                </Col>
              </Row>
            </Fragment>
          )}
        </Modal>

        <Modal
          title="Block"
          centered={true}
          visible={isBlockVisible}
          onCancel={() => {
            setIsBlockVisible(false)
          }}
          footer={[
            <Button key="cancel" onClick={() => {
              setIsBlockVisible(false)
            }}>Cancel</Button>,
            <Button
              key="submit"
              type="danger"
              loading={isBlocking}
              onClick={ () => {
                setIsBlocking(true)
                http
                  .post(
                    `${API_BASE_URL}/users/${actionId}/block`
                  )
                  .then(() => {
                    setActionId(null)
                    setIsBlocking(false)
                    setIsBlockVisible(false)
                    initializeData();
                  })
              }}>
              Block
            </Button>,
          ]}
        >
          <h3>Are you sure you want to BLOCK this user ? </h3>
        </Modal>

        <Modal
          title="Unblock"
          centered={true}
          visible={isUnblockVisible}
          onCancel={() => {
            setIsUnblockVisible(false)
          }}
          footer={[
            <Button key="cancel" onClick={() => {
              setIsUnblockVisible(false)
            }}>Cancel</Button>,
            <Button
              key="submit"
              type="danger"
              loading={isUnblocking}
              onClick={ () => {
                setIsUnblocking(true)
                http
                  .post(
                    `${API_BASE_URL}/users/${actionId}/unblock`
                  )
                  .then(() => {
                    setActionId(null)
                    setIsUnblocking(false)
                    setIsUnblockVisible(false)
                    initializeData();
                  })
              }}>
              Unblock
            </Button>,
          ]}
        >
          <h3>Are you sure you want to UNBLOCK this user ? </h3>
        </Modal>

        <Modal
          title="Delete"
          centered={true}
          visible={isDeleteVisible}
          onCancel={() => {
            setIsDeleteVisible(false)
          }}
          footer={[
            <Button key="cancel" onClick={() => {
              setIsDeleteVisible(false)
            }}>Cancel</Button>,
            <Button
              key="submit"
              type="danger"
              loading={isDeleting}
              onClick={ () => {
                setIsDeleting(true)
                http
                  .delete(
                    `${API_BASE_URL}/companies/${deleteId}`
                  )
                  .then(() => {
                    setDeleteId(null)
                    setIsDeleting(false)
                    setIsDeleteVisible(false)
                    initializeData();
                  })
              }}>
              Delete
            </Button>,
          ]}
        >
          <h3>Are you sure you want to DELETE {deleteEmail} ? </h3>
        </Modal>

        <Modal
          visible={isDetailsModalVisible}
          title={producerData ? `${producerData.name} Details` : ""}
          onCancel={() => {
            setIsDetailsModalVisible(false)
            setProducerData(null)
          }}
          footer={null}
          width={1100}
          bodyStyle={{
            height: 670,
            padding: 12,
          }}
          centered
        >
          {producerData ?
            <div style={{ height: 200 }}>
              <Row wrap={false}>
                <Col flex={"auto"}>
                  <div className={"infoBox"}>
                    <h2 style={{ margin: 0 }}>General Info</h2>
                    <Divider style={{ margin: "12px 0 12px 0"}}/>
                    <DetailEntryText fieldName={"Company Name"} value={producerData.name}/>
                    <DetailEntryText fieldName={"Domain Name"} value={producerData.domain}/>
                    <DetailEntryText fieldName={"Email"} value={producerData.email}/>
                    <DetailEntryText fieldName={"VAT"} value={producerData.vat}/>
                    <DetailEntryText fieldName={"UID"} value={producerData.uid}/>
                    <DetailEntryText fieldName={"Admin Name"} value={producerData.adminName}/>
                  </div>

                  <div className={"infoBox"}>
                    <h2 style={{ margin: 0 }}>Regional Info</h2>
                    <Divider style={{ margin: "12px 0 12px 0"}}/>
                    <DetailEntryText fieldName={"Country"} value={producerData.country}/>
                    <DetailEntryText fieldName={"Region"} value={producerData.region}/>
                    <DetailEntryText fieldName={"City"} value={producerData.city}/>
                    <DetailEntryText fieldName={"Address"} value={producerData.address}/>
                    <DetailEntryText fieldName={"Postal Code"} value={producerData.postalCode}/>
                  </div>
                </Col>

                <Col flex={"14px"}>
                  <Divider type="vertical" style={{ height: "100%", margin: 0 }} />
                </Col>

                <Col flex={"auto"}>
                  <div className={"infoBox"} style={{ marginLeft: 0, marginRight: 0 }}>
                    <h2 style={{ margin: 0 }}>Contact Info</h2>
                    <Divider style={{ margin: "12px 0 12px 0"}}/>
                    <DetailEntryText fieldName={"Contact Name"} value={producerData.contactName}/>
                    <DetailEntryText fieldName={"Phone"} value={producerData.phone}/>
                    <DetailEntryText fieldName={"Contact Email"} value={producerData.contactEmail}/>
                  </div>
                  <div className={"infoBox"} style={{ marginLeft: 0, marginRight: 0 }}>
                    <h2 style={{ margin: 0 }}>Producer Details</h2>
                    <Divider style={{ margin: "12px 0 12px 0"}}/>
                    <DetailEntryCheckbox fieldName={"Offers Bulk Handling"} value={producerData.hasBulkFunction}/>
                    <DetailEntryCheckbox fieldName={"Has Nesting Function"} value={producerData.hasNestingFunction}/>
                    <DetailEntryCheckbox fieldName={"Has Order Function"} value={producerData.hasOrderFunction}/>
                    <DoubleDetailEntryNumber fieldName_first={"Total Clients"} value_first={producerData.numberOfClients}
                                             fieldName_second={"Total Orders"} value_second={producerData.numberOfOrders}/>
                    <DoubleDetailEntryNumber fieldName_first={"Declined Orders"} value_first={producerData.numberOfDeclinedOrders}
                                             fieldName_second={"Completed Orders"} value_second={producerData.numberOfCompletedOrders}
                    />
                    <DoubleDetailEntryNumber fieldName_first={"Part Analyzed in Bulk"} value_first={producerData.numberOfBulkPartsAnalyzed}
                                             fieldName_second={"Nestings so far"} value_second={producerData.numberOfNestings}
                    />
                    <DoubleDetailEntryNumber fieldName_first={"Client Parts Analyzed"} value_first={producerData.numberOfClientPartsAnalyzed}
                                             fieldName_second={"Drafted Parts"} value_second={producerData.numberOfDraftedParts}
                    />
                    <DetailEntryNumber fieldName={"Total Earnings"} value={producerData.totalEarnings}/>
                  </div>
                </Col>
              </Row>
            </div>
            :
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spin size={"large"} indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}/>
            </div>
          }
        </Modal>
      </Layout.Content>
    </Layout>
  )
}



