import React from "react";
import {Divider, Card, Progress} from "antd";
import {
  CloudUploadOutlined, SettingOutlined
} from "@ant-design/icons";
import "./style.less";

export const OrderUploadProcessProgress = (props) => {

  const {
    toProcess, currentlyUploaded, currentlyProcessed,
  } = props


  return (
    <Card
      className={"uploadingProcessingCard"}
      style={{ marginTop: 10, marginBottom: 0 }}
      bodyStyle={{padding: 8}}
    >
      <div style={{padding: "3px 6px 6px 6px"}}>
        <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
          <div style={{marginLeft: 15, minWidth: 155}}>
            <span><CloudUploadOutlined className={"uploadingProcessIcons"}/></span>
            <span className={"text14-500"} style={{fontWeight: 500, marginLeft: 8}}>Uploading files</span>
          </div>
          <div style={{width: "100%", marginRight: 20, lineHeight: 1.4}}>
            <Progress
              className={"processingProgress"}
              size="small"
              status={toProcess === currentlyUploaded ? "normal" : "active"}
              percent={toProcess === 0 ? 0 : (currentlyUploaded / toProcess) * 100}
              showInfo={false}
            />
          </div>
          <div align={'right'} style={{marginRight: 15, minWidth: 25}}>
            <span className={"text14-500"}>{currentlyUploaded}/{toProcess}</span>
          </div>
        </div>
      </div>
      <Divider
        style={{
          padding: 0,
          marginTop: 4,
          marginBottom: 4,
          borderTop: '1px solid #bdbdbd'
        }}
      />
      <div style={{padding: "6px 6px 3px 6px"}}>
        <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
          <div style={{marginLeft: 15, minWidth: 155}}>
            <span><SettingOutlined className={"uploadingProcessIcons"}/></span>
            <span
              className={"text14-500"}
              style={{fontWeight: 500, marginLeft: 8}}
            >
              Processing files
            </span>
          </div>
          <div style={{width: "100%", marginRight: 20, lineHeight: 1.4}}>
            <Progress
              className={"processingProgress"}
              size="small"
              status={toProcess === currentlyProcessed ? "normal" : "active"}
              percent={toProcess === 0 ? 0 : (currentlyProcessed / toProcess) * 100}
              showInfo={false}
            />
          </div>
          <div align={'right'} style={{marginRight: 15, minWidth: 25}}>
            <span className={"text14-500"}>{currentlyProcessed}/{toProcess}</span>
          </div>
        </div>
      </div>
    </Card>
  )
}