import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, Tabs, Form, Spin, Row, Col, Divider, Modal, InputNumber, Typography} from "antd";

import MaterialTableContainer from "../../MaterialTableContainer";
import {ValueTable} from "../../MaterialTables/ValueTable";

import {LoadingOutlined} from "@ant-design/icons";
import {
  fetchMaterialCertificatePrices,
  fetchMaterialPrices, updateAssemblyMaterialCertificatePrice, updateBaseMaterialCertificatePrice,
  updateMaterialPrice
} from "../../../../../actions/materialsActions"
import {MachiningMaterialList} from "../../MachiningMaterialList";
import {
  fetchMachiningMaterials,
  updateMachiningMaterialPrice
} from "../../../../../actions/machiningActions";
import {
  fetchStandardTubeMaterialPrices,
  fetchTubeMaterialPrices, updateStandardTubeMaterialPrice,
  updateTubeMaterialPrice
} from "../../../../../actions/tubeCuttingActions";
import MaterialTableContainerNested from "../../MaterialTableContainerNested";
import { ModalTable } from "../../MaterialTables/ModalTable";
import {Trans} from "react-i18next";
import {debounce} from "lodash";
import {EmptyTable} from "../../MaterialTables/EmptyTable";
import "./style.less";
import {TableHeader} from "../../MaterialTables/TableHeader";

const { TabPane } = Tabs;

export const MaterialsTab = (props) => {
  const {
    selectedTab, socketConnected, changeMaterialPrice,
    changeBaseMaterialCertificateParams, changeAssemblyMaterialCertificateParams,
    changeMachiningMaterialPrice, changeTubeMaterialPrice,
    changeStandardTubeMaterialPrice
  } = props

  const [shouldUpdatePrices, setShouldUpdatePrices] = useState(false)
  const [shouldUpdateMachiningTable, setShouldUpdateMachiningTable] = useState(false);
  const [shouldUpdateTubePrices, setShouldUpdateTubePrices] = useState(false)
  const [selectedTubeData, setSelectedTubeData] = useState({})
  const [tubeModalVisible, setTubeModalVisible] = useState(false);

  const materialPrices = useSelector(state => state.materials.materialPrices)
  const isFetchingMaterialPrices = useSelector(state => state.materials.isFetchingMaterialPrices)
  const isFetchingMaterialCertPrices = useSelector(state => state.materials.isFetchingMaterialCertPrices)
  const isFetchingMachiningMaterials = useSelector(state => state.machining.isFetchingMachiningMaterials)
  const machiningMaterials = useSelector(state => state.machining.materials)
  const hasMachining = useSelector(state => state.machining.hasMachining)

  const tubeCuttingMaterials = useSelector(state => state.tubeCutting.materials)
  const baseMaterialCertFee = useSelector(state => state.materials.baseMaterialCertFee)
  const assemblyMaterialCertFee = useSelector(state => state.materials.assemblyMaterialCertFee)
  const isFetchingStandardTubeMaterialPrices = useSelector(state => state.tubeCutting.isFetchingStandardTubeMaterialPrices)
  const standardTubeMaterialPrices = useSelector(state => state.tubeCutting.standardTubeMaterialPrices)
  const isFetchingTubeMaterialPrices = useSelector(state => state.tubeCutting.isFetchingTubeMaterialPrices)
  const hasTubeCutting = useSelector(state => state.tubeCutting.hasTubeCutting)

  const certificateForm = React.createRef();
  const dispatch = useDispatch()

  useEffect(()=>{
    if(selectedTab === "7") {
      dispatch(fetchMaterialPrices()).then(()=>{
        setShouldUpdatePrices(true)
      })
      dispatch(fetchTubeMaterialPrices()).then(()=>{
        setShouldUpdateTubePrices(true)
      })
      dispatch(fetchStandardTubeMaterialPrices()).then(()=>{

      })
      dispatch(fetchMachiningMaterials())
      dispatch(fetchMaterialCertificatePrices())
    }
  }, [selectedTab])

  useEffect(() => {
    certificateForm.current.setFieldsValue({
      baseMaterialCertFee: baseMaterialCertFee
    })
  }, [baseMaterialCertFee])

  useEffect(() => {
    console.log(assemblyMaterialCertFee)
    certificateForm.current.setFieldsValue({
      assemblyMaterialCertFee: assemblyMaterialCertFee
    })
  }, [assemblyMaterialCertFee])

  const changePrice = (params) => {
    changeMaterialPrice({materialId: params.materialId, price: params.value})
    dispatch(updateMaterialPrice(params))
    return Promise.resolve(10)
  }

  const changeTubeMaterial = (params) => {
    changeTubeMaterialPrice({grade: params.materialId, price: params.value})
    dispatch(updateTubeMaterialPrice(params))

    dispatch(fetchStandardTubeMaterialPrices({groupName: params.groupName})).then(()=>{})

    return Promise.resolve(10)
  }

    const changeStandardTubeMaterial = (params) => {
    changeStandardTubeMaterialPrice({materialId: params.materialId, price: params.value})
    dispatch(updateStandardTubeMaterialPrice({type: 'circle', ...params}))
    return Promise.resolve(10)
  }

  const changeMachiningMaterial = (params) => {
    changeMachiningMaterialPrice({materialId: params.materialId, price: params.value})
    dispatch(updateMachiningMaterialPrice(params))
    return Promise.resolve(10)
  }

  const handleOpenTubeMaterialModal = (params) => {
    setSelectedTubeData({
      gradeName: params.gradeName,
      materialId: params.materialId,
      groupName: params.groupName,
      x: params.materialThickness,
      y: params.materialGrade,
      tab: params.tab,
      grade: params.gradeTabIndex,
      data: params.tubeData,
      col: params.col,
      row: params.row
    })
    setTubeModalVisible(true);
  }

  const handleCloseTubeMaterialModal = () => {
    setTubeModalVisible(false);
    setSelectedTubeData({});
  }

  const handleStandardTubePriceChange = (data) => {
    const pomData = {
      type: 'square',
      tab: data.data.tab,
      grade: data.data.grade,
      row: data.data.row,
      col: data.data.col,
      elem: data.index,
      value: data.value
    }
    changeStandardTubeMaterialPrice({materialId: data.materialId, price: data.value})
    dispatch(updateStandardTubeMaterialPrice(pomData))
  }

  const debouncedStandardTubePriceChange = useCallback(
    debounce(data => handleStandardTubePriceChange(data), 800),
    []
  );

  const changeStandardTubePrice = (data) => {
    debouncedStandardTubePriceChange(data)
  }

  /*----------- Base Part Certificate -----------*/

  const changeBaseCertificatePriceData = (data) => {
    changeBaseMaterialCertificateParams(data)
    dispatch(updateBaseMaterialCertificatePrice(data["baseMaterialCertFee"]))
  }

  const debouncedBaseCertificatePrice = useCallback(
    debounce(data => changeBaseCertificatePriceData(data), 800),
    []
  );

  const changeBaseCertificatePrice = () => {
    debouncedBaseCertificatePrice({
      "baseMaterialCertFee": certificateForm.current.getFieldValue("baseMaterialCertFee")
    })
  }

  /*----------- Assembly Part Certificate -----------*/

  const changeAssemblyCertificatePriceData = (data) => {
    changeAssemblyMaterialCertificateParams(data)
    dispatch(updateAssemblyMaterialCertificatePrice(data["assemblyMaterialCertFee"]))
  }

  const debouncedAssemblyCertificatePrice = useCallback(
    debounce(data => changeAssemblyCertificatePriceData(data), 800),
    []
  );

  const changeAssemblyCertificatePrice = () => {
    debouncedAssemblyCertificatePrice({
      "assemblyMaterialCertFee": certificateForm.current.getFieldValue("assemblyMaterialCertFee")
    })
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard tabMaterialCardMargin"} bodyStyle={{paddingBottom: 10, paddingTop: 8}}>
        <div style={{height: 'calc(100vh - 106px)', overflowY: materialPrices.length === 0 ? "hidden" : "auto", overflowX: "hidden", paddingRight: 15}}>
          <Row>
            <Col span={24}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2 }}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Material Certificates:</span>
                </div>
                <Form ref={certificateForm}
                      style={{ width: "100%", marginLeft: 12}}
                >
                  <Spin
                    spinning={isFetchingMaterialCertPrices || !socketConnected}
                    style={{zIndex: 1}}
                    indicator={<LoadingOutlined style={{ fontSize: 32, position: "absolute", left: 237, top: 17 }} spin />}
                  >
                    <Card
                      style={{ width: "100%"}}
                      bodyStyle={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 2 }}
                    >
                      <div style={{display: "flex", alignItems: "center", marginLeft: 12}}>
                        <Form.Item
                          label={"Base Part Cost"}
                          name={"baseMaterialCertFee"}
                          style={{marginBottom: 5, marginTop: 5}}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeBaseCertificatePrice}
                            disabled={isFetchingMaterialCertPrices}
                          />
                        </Form.Item>
                        <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>€</Typography.Text>
                      </div>
                      <Divider type={"vertical"} style={{ height: "24px", margin: "0 12px", borderColor: "#838f94" }} />
                      <div style={{display: "flex", alignItems: "center"}}>
                        <Form.Item
                          label={"Assembly Part Cost"}
                          name={"assemblyMaterialCertFee"}
                          style={{marginBottom: 5, marginTop: 5}}
                        >
                          <InputNumber
                            style={{minWidth: 30, marginLeft: 5}}
                            min={0}
                            step={1}
                            formatter={value => value ? value : 0}
                            parser={value => value ? value : 0}
                            onChange={changeAssemblyCertificatePrice}
                            disabled={isFetchingMaterialCertPrices}
                          />
                        </Form.Item>
                        <Typography.Text className={"inputInfoText"} style={{marginLeft: 12}}>€</Typography.Text>
                      </div>
                    </Card>
                  </Spin>
                </Form>
              </div>
            </Col>
            <Col flex={"285px"}>
              <div style={{display: "flex", alignItems: "center", marginBottom: 2}}>
                <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Machining:</span>
                <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
              </div>
              <Spin
                spinning={isFetchingMachiningMaterials || !socketConnected}
                style={{zIndex: 1}}
                indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-12px, -8px)" }} spin />}
              >
                {(machiningMaterials.availableMaterials.length === 0 || !hasMachining) ?
                  <Card
                    bordered={false}
                    style={{borderRadius: 5}}
                    bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: "calc(100vh - 192px)", minHeight: 710}}
                  >
                    {(!hasMachining && !isFetchingMachiningMaterials)  &&
                      <div style={{width: "100%", height: "60%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{fontSize: 16, fontWeight: 600, color: "#aaaaaa", textAlign: "center"}}>
                          You do not have Machining enabled
                        </div>
                      </div>
                    }
                  </Card>
                  :
                  <div>
                    <TableHeader columns={[
                      {title: "Name", width: 130},
                      {title: "Price (€/kg)", width: 140}
                    ]}/>
                    <Card
                      className={"tableCard"}
                      style={{width: 285}}
                      bodyStyle={{height: "calc(100vh - 232px)", maxWidth: 300, minHeight: 670}}
                    >
                      <MachiningMaterialList
                        rows={machiningMaterials.availableMaterials}
                        allAvailable={machiningMaterials.allAvailable}
                        onChange={changeMachiningMaterial}
                        updateTable={shouldUpdateMachiningTable}
                        setUpdateTable={setShouldUpdateMachiningTable}
                        isEditable={true}
                      />
                    </Card>
                  </div>
                }
              </Spin>
            </Col>
            <Divider type="vertical" style={{height: 'calc(100vh - 190px)', margin: "13px 15px 0 15px", borderColor: "#1e1e1e"}}/>
            <Col flex={"auto"}>
              <div style={{display: "flex", alignItems: "center", marginBottom: 2}}>
                <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Sheet Metal:</span>
                <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
              </div>
              <Form layout={'horizontal'}>
                <div className={"newLine"}>
                  <div className={"customColumn"}>
                    <div style={{maxWidth: "100%"}}>
                      <Spin spinning={isFetchingMaterialPrices || !socketConnected}
                            style={{zIndex: 1}}
                            indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
                      >
                        {materialPrices.length === 0 ?
                          <Card
                            bordered={false}
                            bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: 336}}
                            style={{marginBottom: 0, borderRadius: 5}}
                          >

                          </Card>
                          :
                          <Tabs
                            style={{marginLeft: -10, marginBottom: 0}}
                            type="card"
                            className={"laserSpeedTabs"}
                          >
                            {materialPrices.map((item, index) => {
                              return (
                                <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                                  <MaterialTableContainer
                                    tableText={"Material Price (€/kg)"}
                                    xAxisText={"Available Thickness (mm)"}
                                    yAxisText={"Material Grade"}
                                    xAxisStyle={{marginTop: 12, marginBottom: -12}}
                                  >
                                    <ValueTable
                                      columns={item.columns}
                                      rows={item.rows}
                                      allAvailable={item.allAvailable}
                                      tabIndex={index}
                                      onChange={changePrice}
                                      updateTable={shouldUpdatePrices}
                                      setUpdateTable={setShouldUpdatePrices}
                                      leftColumnWidth={140}
                                      referenceHeight={248}
                                      isEditable={true}
                                    />
                                  </MaterialTableContainer>
                                </TabPane>
                              )
                            })}
                          </Tabs>
                         }
                      </Spin>
                    </div>
                  </div>
                </div>
              </Form>
              <div style={{display: "flex", alignItems: "center", marginBottom: 2, marginTop: 4}}>
                <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Tube Cutting:</span>
                <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
              </div>
              <Form layout={'horizontal'}>
                <div className={"newLine"}>
                  <div className={"customColumn"}>
                    <div style={{maxWidth: "100%"}}>
                      <Row gutter={15} style={{width: "100%", marginRight: 0, marginLeft: 0}}>
                        <Col flex="285px" style={{paddingLeft: 0}}>
                          <Spin
                            spinning={isFetchingTubeMaterialPrices || !socketConnected}
                            style={{zIndex: 1}}
                            indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-25%, -50%)" }} spin />}
                          >
                            {(tubeCuttingMaterials.availableMaterials.length === 0 || !hasTubeCutting) ?
                              <Card
                                bordered={false}
                                style={{marginBottom: 0, borderRadius: 5}}
                                bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: "calc(100vh - 562px)", minHeight: 338, width: 285}}
                              >
                                {(!hasTubeCutting && !isFetchingTubeMaterialPrices)  &&
                                  <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div style={{fontSize: 16, fontWeight: 600, color: "#aaaaaa", textAlign: "center"}}>
                                      You do not have Tube Cutting enabled
                                    </div>
                                  </div>
                                }
                              </Card>
                              :
                              <div>
                                <TableHeader columns={[
                                  {title: "Name", width: 180},
                                  {title: "Price (€/kg)", width: 90}
                                ]}/>
                                <Card
                                  className={"tableCard"}
                                  style={{width: 285}}
                                  bodyStyle={{maxWidth: 300, minHeight: 298, height: "calc(100vh - 603px)"}}
                                >
                                  <MachiningMaterialList
                                    rows={tubeCuttingMaterials.availableMaterials}
                                    allAvailable={tubeCuttingMaterials.allAvailable}
                                    onChange={changeTubeMaterial}
                                    updateTable={shouldUpdateTubePrices}
                                    setUpdateTable={setShouldUpdateTubePrices}
                                    isEditable={true}
                                    leftWidth={190}
                                    rightWidth={70}
                                    gradeAlignLeft={true}
                                  />
                                </Card>
                              </div>
                            }
                          </Spin>
                        </Col>
                        <Col flex="auto" style={{paddingRight: 0}}>
                          <Spin
                          spinning={isFetchingStandardTubeMaterialPrices || !socketConnected}
                          style={{zIndex: 1}}
                          wrapperClassName={"largeTubeTable"}
                          indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
                        >
                          {(standardTubeMaterialPrices.length === 0 || !hasTubeCutting) ?
                            <Card
                              bordered={false}
                              style={{marginBottom: 0, borderRadius: 5, width: "100%"}}
                              bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: "calc(100vh - 562px)", minHeight: 338}}
                            >
                              {(!hasTubeCutting && !isFetchingStandardTubeMaterialPrices)  &&
                                <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                  <div style={{fontSize: 18, fontWeight: 600, color: "#aaaaaa", textAlign: "center"}}>
                                    You do not have Tube Cutting enabled
                                  </div>
                                </div>
                              }
                            </Card>
                            :
                            <Tabs
                              style={{marginLeft: -10, marginBottom: 0}}
                              type="card"
                              className={"laserSpeedTabs"}
                            >
                              {standardTubeMaterialPrices.map((group, index) => {
                                return (
                                  <TabPane className={"materialsTabPane tubesTabPane"} tab={group.groupName} key={index}>
                                    <Card
                                      className={'laserSpeedCard'}
                                      bodyStyle={{ padding: "8px 0 0 0", height: "calc(100vh - 593px)", minHeight: 308}}
                                    >
                                      {group.grades.length === 0 ?
                                        <div className={"emptyTableContainer"} style={{margin: "0 12px 10px 12px"}}>
                                          <EmptyTable height={"calc(100% - 12px)"} text={"No General Grade Price Set"}/> {/* TODO */}
                                        </div>
                                        :
                                        <Tabs
                                          style={{marginBottom: 0}}
                                          type="card"
                                          tabPosition="left"
                                          className={"laserSpeedTabs gradeSideTabs"}
                                        >
                                          {group.grades.map((item, subIndex) => {
                                            return (
                                              <TabPane style={{paddingLeft: 0}} tab={item.gradeName} key={subIndex}>
                                                {item.tubeType === 'tube' ?
                                                  <MaterialTableContainerNested
                                                    tableText={`Tube Price (€/kg)`}
                                                    xAxisText={"X dimension (mm)"}
                                                    yAxisText={"Y dimension (mm)"}
                                                  >
                                                    <ModalTable
                                                      columns={item.columns}
                                                      rows={item.rows}
                                                      allAvailable={item.allAvailable}
                                                      tabIndex={index}
                                                      gradeTabIndex={subIndex}
                                                      onClick={handleOpenTubeMaterialModal}
                                                      groupName={group.groupName}
                                                      gradeName={item.gradeName}
                                                      updateTable={false}
                                                      setUpdateTable={()=>{}}
                                                      activeLoadingTabs={{}}
                                                      nestedTable={true}
                                                      isEditable={true}
                                                    />
                                                  </MaterialTableContainerNested>
                                                  :
                                                  <MaterialTableContainerNested
                                                    tableText={`Tube Price (€/kg)`}
                                                    xAxisText={"Diameter (mm)"}
                                                    yAxisText={"Thickness (mm)"}
                                                  >
                                                    <ValueTable
                                                      columns={item.columns}
                                                      rows={item.rows}
                                                      allAvailable={item.allAvailable}
                                                      tabIndex={index}
                                                      gradeTabIndex={subIndex}
                                                      onChange={changeStandardTubeMaterial}
                                                      updateTable={false}
                                                      setUpdateTable={()=>{}}
                                                      nestedTable={true}
                                                      leftColumnWidth={45}
                                                      isEditable={true}
                                                    />
                                                  </MaterialTableContainerNested>
                                                }
                                              </TabPane>
                                            )
                                          })}
                                        </Tabs>
                                      }
                                    </Card>
                                  </TabPane>
                                )
                              })}
                            </Tabs>
                          }
                        </Spin>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Card>
      <Modal
        visible={tubeModalVisible}
        onCancel={handleCloseTubeMaterialModal}
        title={"Tube Prices"}
        footer={<div style={{ height: 20 }}/>}
        centered={true}
        bodyStyle={{ height: 660 }}
        destroyOnClose={true}
        zIndex={1}
        width={550}
      >
        <React.Fragment>
          <div style={{ fontWeight: 500 }}>
            <div style={{color: "white"}}>
              <div>
                <div>Tube Grade: <Trans>{selectedTubeData.gradeName}</Trans></div>
                <div>Tube Dimensions: {`${selectedTubeData.x}x${selectedTubeData.y}`}mm</div>
              </div>
              <div className={"modalDataContainer"}>
                <Form>
                {selectedTubeData?.data?.map((item, index) => (
                  <div className={"checkboxStyle"} key={item.id}>
                    <Card
                      className={"bendCard"}
                      style={{ marginTop: 3, marginBottom: 3}}
                      bodyStyle={{paddingTop: 8, paddingBottom: 8}}
                    >
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>Thickness: {item.thickness} mm</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                          <Form.Item
                            label="Price:"
                            name={`thickness-${index}`}
                            style={{marginBottom: 0, marginTop: 0}}
                            initialValue={item.price}
                          >
                            <InputNumber
                              style={{minWidth: 30, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={(value)=>{changeStandardTubePrice({materialId: item.id, index, value, data: selectedTubeData})}}
                            />
                          </Form.Item>
                          <Typography.Text className={"inputInfoText"} style={{marginLeft: 8}}>€/kg</Typography.Text>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
                </Form>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  )
}