import React from "react";
import {LoadingOutlined} from "@ant-design/icons";
import "./style.less";

export const FetchingOrderIndicator = (props) => {
  return (
    <div className={"fetchingOrderContainer"}>
      <LoadingOutlined style={{ fontSize: 72 }} spin />
      <div style={{fontSize: 27, fontWeight: 500, marginTop: 10}}>{props.text}</div>
    </div>
  )
}