import React, {useEffect, useState} from "react";
import {Button, Input, notification} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import cadSheetMetal from "../assets/icons/placeholderIcons/sheetMetal.svg";
import cadMachined from "../assets/icons/placeholderIcons/machined.svg";
import cadProfile from "../assets/icons/placeholderIcons/profile.svg";
import cadTubeCut from "../assets/icons/placeholderIcons/tubeCut.svg";
import cadOther from "../assets/icons/placeholderIcons/other.svg";
import cadAssembly from "../assets/icons/placeholderIcons/assembly.svg";
import cadLathed from "../assets/icons/placeholderIcons/lathed.svg";
import cadMilled from "../assets/icons/placeholderIcons/milled.svg";
import format from "date-fns/format";

export const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.height = target.offsetHeight + 'px';
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
    //alert("!");
  }, duration);
}

export const slideDown = (target, duration = 500, forceFlexDisplay = false) => {
  target.style.removeProperty('display');
  let display = window.getComputedStyle(target).display;

  if (display === 'none')
    display = 'block';

  if (forceFlexDisplay) {
    target.style.display = "flex";
  } else {
    target.style.display = display;
  }
  let height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout(() => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
}

export const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
}

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export const getColumnSearchProps = (dataIndex, searchInput, name = "") => ({
  filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
    <div style={{padding: 8}}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${name || dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{width: 188, marginBottom: 8, display: 'block', color: "white"}}
      />
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<SearchOutlined/>}
        size="small"
        style={{width: 90, marginRight: 8}}
      >
        <span style={{fontWeight: 600, fontSize: 13}}>Search</span>
      </Button>
      <Button
        onClick={() => {
          clearFilters()
          confirm()
        }}
        size="small"
        style={{width: 90}}
      >
        <span style={{fontWeight: 600, fontSize: 13}}>Reset</span>
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#23d317' : '#da291c'}}/>,
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.select());
    }
  },
})

export const formatDate = date => {
  let datum = new Date(date)
  return (datum.getDate() < 10 ? "0" : "") + datum.getDate().toString() + (datum.getMonth() < 9 ? ".0" : ".") + (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString()
};

export const formatTime = date => {
  let datum = new Date(date)

  return `${String(datum.getHours()).padStart(2, "0")}:${String(datum.getMinutes()).padStart(2, "0")}:${String(datum.getSeconds()).padStart(2, "0")}`
}

export const getPlaceholderImage = (type) => {
  switch (type) {
    case "sheetMetal":
      return cadSheetMetal
    case "machined":
      return cadMachined
    case "profile":
      return cadProfile
    case "tubeCut":
      return cadTubeCut
    case "other":
      return cadOther
    case "assembly":
      return cadAssembly
    case "lathed":
      return cadLathed
    case "milled":
      return cadMilled
    default:
      return cadOther
  }
};

export const getProfileType = (part) => {
  let tokens = part.selectedMaterial.grade.split(" - ")
  const thickness = part.selectedMaterial.thickness
  const x = part.stepData.partData.tubeData.tubeDimensions[0]
  const y = part.stepData.partData.tubeData.tubeDimensions[1]
  const type = part.stepData.partData.tubeData.tubeType
  let dimensions = `${x}x${y}x${thickness}`;
  if (type === 'circle') {
    dimensions = `${x}x${thickness}`;
  }
  return `${tokens[0]} / ${tokens[1]} / ${dimensions}`
}

export const getManualType = (type) => {
  switch (type) {
    case "sheetMetal":
      return "Sheet Metal";
    case "milled":
      return "Milled";
    case "lathed":
      return "Lathed";
    case "tubeCut":
      return "Tube Cut";
    case "profile":
      return "Standard Profile";
    case "assembly":
      return "Assembly";
    case "buyPart":
      return "Buy Part";
    case "bulkMaterial":
      return "Bulk Material";
    case "other":
      return "Other"

    default:
      return "Other"
  }
}

export const getOrderStateName = (state) => {
  switch (state) {
    case "quoted":
      return "Pending"
    case "awaitingQuote":
      return "Pending"
    case "awaitingConfirmation":
      return "Awaiting"
    case "accepted":
      return "Accepted"
    case "inProgress":
      return "In Progress"
    case "declined":
      return "Declined"
    case "completed":
      return "Completed"
    case "internalArchived":
      return "Internal"
    case "draft":
      return "Draft"
    default:
      return "Pending"
  }
}

export const tagTextColor = (type) => {
  switch (type) {
    case "quoted":
      return "black"
    case "awaitingQuote":
      return "black"
    case "awaitingConfirmation":
      return "white"
    case "accepted":
      return "black"
    case "inProgress":
      return "black"
    case "declined":
      return "white"
    case "completed":
      return "white"
    case "internalArchived":
      return "black"
    case "draft":
      return "white"
    default:
      return "white"
  }
}

export const tagColor = (type) => {
  switch (type) {
    case "quoted":
      return "#f0bd27"
    case "awaitingQuote":
      return "#f0bd27"
    case "awaitingConfirmation":
      return "#9966cc"
    case "accepted":
      return "#6bce34"
    case "inProgress":
      return "#7cbbf4"
    case "declined":
      return "#e03838"
    case "completed":
      return "#1890ff"
    case "internalArchived":
      return "#f0bd27"
    case "draft":
      return "#3548AF"
    default:
      return "#f0bd27"
  }
}

export const formatTableDate = (timestamp) => {
  let date;
  try {
    date = format(new Date(timestamp), "dd.MM.yyyy")
  } catch {
    date = format(new Date(null), "dd.MM.yyyy")
  }
  return date
}

export const escapeRegExp = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export const canHaveCoating = (part) => {
  const partType = part.partType;
  return part.coating?.hasCoating && !(partType === "buyPart" || partType === "bulkMaterial" || partType === "assembly" || partType === "other");
}

export const canHaveStandard = (partType) => {
  return partType === "tubeCut" || partType === "profile" || partType === "buyPart" || partType === "bulkMaterial";

}

export const canHaveCertificate = (partType) => {
  return partType === "tubeCut" || partType === "sheetMetal" || partType === "milled" || partType === "lathed" || partType === "profile";
}

export const getTotalSheetMetalSuggestedPrice = (opPrices, quantity) => {
  if (opPrices) {
    return (
      (opPrices.cuttingPricePerPiece * quantity + opPrices.cuttingPreparationPrice) / quantity +
      (opPrices.bendingPricePerPiece * quantity + opPrices.bendingPreparationPrice) / quantity +
      (opPrices.machiningPricePerPiece * quantity + opPrices.machiningPreparationPrice) / quantity +
      opPrices.coatingPricePerPiece + opPrices.materialPricePerPiece + opPrices.materialCertificatePrice / quantity
    )
  }
  return 0
}

export const getTotalMillingSuggestedPrice = (opPrices, quantity) => {
  if (opPrices) {
    return (
      (opPrices.millingPricePerPiece * quantity + opPrices.millingPreparationPrice) / quantity +
      opPrices.coatingPricePerPiece + opPrices.materialPricePerPiece + opPrices.materialCertificatePrice / quantity
    )
  }
  return 0
}

export const getTotalLathingSuggestedPrice = (opPrices, quantity) => {
  if (opPrices) {
    return (
      (opPrices.lathingPricePerPiece * quantity + opPrices.lathingPreparationPrice) / quantity +
      opPrices.coatingPricePerPiece + opPrices.materialPricePerPiece + opPrices.materialCertificatePrice / quantity
    )
  }
  return 0
}

export const getTotalTubeCuttingSuggestedPrice = (opPrices, quantity) => {
  if (opPrices) {
    return (
      (opPrices.tubeCuttingPricePerPiece * quantity + opPrices.tubeCuttingPreparationPrice) / quantity +
      opPrices.coatingPricePerPiece + opPrices.materialPricePerPiece + opPrices.materialCertificatePrice / quantity
    )
  }
  return 0
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const calcAdjustment = (adjustment) => {
  return 1 + (adjustment || 0)
}

export const openNotification = (placement) => {
  notification.info({
    key: "size20warning",
    message: (<div style={{fontWeight: 600}}>File larger than 20 Megabytes.</div>),
    description: (<div style={{fontSize: 14, fontWeight: 600}}>Processing might take up to a few minutes. </div>),
    className: "fileSizeWarningPopup",
    duration: 0,
    placement,
  });
};

export const formatTubeCuttingMachines = (machines) => {
  return machines.length > 0 ? [{
    name: "Tube Cutting Machines",
    id: 123456,
    machines: machines
  }] : []
}

export const getTolerances = () => {
  return ["coarse", "medium", "fine"]
}

export const getToleranceValue = (label, type) => {
  if (type === "machined") {
    switch (label) {
      case null:
        return ">= 0.1mm"
      case "coarse":
        return "0.060 - 0.099mm";
      case "medium":
        return "0.020 - 0.059mm";
      case "fine":
        return "0.001 - 0.019mm";
      default:
        return "0.060 - 0.099mm";
    }
  } else {
    switch (label) {
      case null:
        return ">= 0.5mm"
      case "coarse":
        return "0.100 - 0.490mm";
      case "medium":
        return "0.050 - 0.099mm";
      case "fine":
        return "0.010 - 0.049mm";
      default:
        return "0.100 - 0.490mm";
    }
  }
}

export const getRoughnesses = () => {
  return ["RA32", "RA16", "RA8", "RA4"]
}

export const getRoughnessValue = (label) => {
  switch (label) {
    case null:
      return ">= 3.2μm Ra"
    case "RA32":
      return "3.2μm Ra";
    case "RA16":
      return "1.6μm Ra";
    case "RA8":
      return "0.8μm Ra";
    case "RA4":
      return "0.4μm Ra";
    case "":
      return "";
    default:
      return "3.2μm Ra"
  }
}

export const getApplicableTolerances = ({tolerance, maxTolerance}) => {
  if (!maxTolerance) {
    return false
  }
  if (tolerance === "coarse") {
    return true;
  } else if (tolerance === "medium" && (maxTolerance === "medium" || maxTolerance === "fine")) {
    return true;
  } else return tolerance === "fine" && maxTolerance === "fine";
}

export const getApplicableRoughnesses = ({roughness, maxRoughness}) => {
  if (!maxRoughness) {
    return false
  }
  if (roughness === "RA32") {
    return true;
  } else if (roughness === "RA16" && (maxRoughness === "RA16" || maxRoughness === "RA8" || maxRoughness === "RA4")) {
    return true;
  } else if (roughness === "RA8" && (maxRoughness === "RA8" || maxRoughness === "RA4")) {
    return true;
  } else return roughness === "RA4" && maxRoughness === "RA4";
}

export const hasWeight = ({item}) => {
  if (item.fileType === "step") {
    if (item.partType === 'tubeCut' && item.isTubeCut) {
      return item.stepData?.partData?.tubeData?.tubeLength && item.selectedMaterial?.density
    } else {
      return item.stepData?.partData?.volume && item.selectedMaterial?.density
    }
  }
  if (item.fileType === "dxf") {
    return item.dxfData?.partData?.area && item.selectedMaterial?.thickness && item.selectedMaterial?.density
  }
}

export const getWeight = ({item}) => {
  let weight = 0;
  if (item.fileType === "step") {
    let volume = item.stepData.partData.volume / 1000000000
    if (item.partType === 'tubeCut' && item.isTubeCut) {
      weight = ((item.selectedMaterial.density || 0) * item.stepData.partData.tubeData.tubeLength / 1000).toFixed(3)
    } else {
      weight = ((item.selectedMaterial.density || 0) * volume).toFixed(3)
    }
  }
  if (item.fileType === "dxf") {
    let volume = (item.dxfData.partData.area * item.selectedMaterial.thickness) / 1000000000
    weight = ((item.selectedMaterial.density || 0) * volume).toFixed(3)
  }

  return weight
}

export const getColorName = ({color}) => {
  return color === 'Any Color' ? color : `RAL ${color}`
}

export const getSevenDaysAgoDate = () => {
  let sevenDaysAgo = new Date()
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6)
  return sevenDaysAgo
}

export const getTodaysDate = () => {
  return new Date()
}

export const getCoatingType = (coatingData) => {
  if (coatingData?.hasCoating) {
    switch (coatingData.type) {
      case "coldGalvanizing":
        return "Cold Galvanization";
      case "hotGalvanizing":
        return "Hot-Dip Galvanization";
      case "anodizing":
        return "Anodizing";
      case "nickelPlating":
        return "Chemical Nickel Plating";
      case "galvanicZincPlating":
        return "Galvanic Zinc Plating";
      case "hardening":
        return "Hardening";
      case "caseHardening":
        return "Tempering";
      case "annealing":
        return "Annealing";
      case "glassBeadBlasting":
        return "Glass Bead Blasting";
      case "sandBlasting":
        return "Ceramic Bead Blasting";
      case "painting":
        return coatingData.data.colorGlossId === "1" ? "Painting" : "Powder Painting"
      default:
        return "/";
    }
  } else {
    return "/"
  }
}

export const getVolume = ({boundingBox}) => {
  if (boundingBox) {
    return (boundingBox.x * boundingBox.y * boundingBox.z) / 1000000
  }
  return 0
}

export const getWidths = (schema, objects) => {
  return schema.map(schemaItem => {
    return Math.max(...objects.map(item => {
      if (schemaItem.value(item))
        return schemaItem.value(item).toString().length * 1.2
      return 0
    }), Math.max(7, Math.round(schemaItem.column.length * 1.2)))
  })
}