import React from "react";
import {List} from "antd";
import {ProcessedItemCard, ProcessingItemCard, UploadingItemCard} from "../OrderItemCards";

export const OrderPartsList = (props) => {
  const {
    orderId, items, selectableItemsCount, selectedItems, handleRemoveItem,
    changeSheetMetalMaterial, changePartThickness, changePartQuantity,
    changePartTolerance, changePartRoughness, changeManualPartTolerance,
    changeManualPartRoughness, changePartNote, changePartColor, handleColorRemove,
    showItem, handleChangeItemType, bulkItem, adjustment,
    handleSelectAssembly, partiallySelectedAssemblies,
    handleRemoveEntireAssembly, assemblyProgressMap, changeAssemblyQuantity,
    changeAssemblyWeldingPrice, changeAssemblyAssemblingPrice,
    changeAssemblyPackagingPrice, changeAssemblyTransportationPrice,
    openDocumentsView, onCheck, sheetMetalMaterials, machiningMaterials,
    tubeCuttingMaterials, changeMachiningMaterial, changeTubeCuttingMaterial, changePartStandard,
    changePartNumberOfUniqueParts, isOrderDetails, handleOpenPricesModal, changePrice,
    changeMaterialCertificate
  } = props

  return (
    <List
      style={{ paddingTop: selectableItemsCount > 0 ? 0 : 10 }}
      dataSource={items}
      key={"id"}
      rowKey={(item) => item.fileId || item.id}
      renderItem={item => {
        switch (item.state) {
          case "processed":
            return (
              <ProcessedItemCard
                orderId={orderId}
                item={item}
                sheetMetalMaterials={sheetMetalMaterials}
                machiningMaterials={machiningMaterials}
                tubeCuttingMaterials={tubeCuttingMaterials}
                changeSheetMetalMaterial={changeSheetMetalMaterial}
                changeMachiningMaterial={changeMachiningMaterial}
                changeTubeCuttingMaterial={changeTubeCuttingMaterial}
                changePartThickness={changePartThickness}
                changePartQuantity={changePartQuantity}
                changePartNote={changePartNote}
                changePartColor={changePartColor}
                changePartTolerance={changePartTolerance}
                changePartRoughness={changePartRoughness}
                changeManualPartTolerance={changeManualPartTolerance}
                changeManualPartRoughness={changeManualPartRoughness}
                changePartStandard={changePartStandard}
                changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
                handleColorRemove={handleColorRemove}
                handleChangeItemType={handleChangeItemType}
                handleRemoveItem={handleRemoveItem}
                partiallySelectedAssemblies={partiallySelectedAssemblies}
                handleSelectAssembly={handleSelectAssembly}
                handleRemoveEntireAssembly={handleRemoveEntireAssembly}
                assemblyProgressMap={assemblyProgressMap}
                changeAssemblyQuantity={changeAssemblyQuantity}
                changeAssemblyWeldingPrice={changeAssemblyWeldingPrice}
                changeAssemblyAssemblingPrice={changeAssemblyAssemblingPrice}
                changeAssemblyPackagingPrice={changeAssemblyPackagingPrice}
                changeAssemblyTransportationPrice={changeAssemblyTransportationPrice}
                changeMaterialCertificate={changeMaterialCertificate}
                showItem={showItem}
                selectedItems={selectedItems}
                onCheck={onCheck}
                bulkItem={bulkItem}
                isOrderDetails={isOrderDetails}
                adjustment={adjustment}
                openDocumentsView={openDocumentsView}
                key={item.id || item.fileId}
                handleOpenPricesModal={handleOpenPricesModal}
                changePrice={changePrice}
              />
            )
          case "uploaded":
          case "processing":
            if (item.assemblyId) {
              return (<div key={item.id || item.fileId}/>)
            } else {
              return (
                <ProcessingItemCard item={item} key={item.id || item.fileId}/>
              )
            }
          case "uploading":
            if (item.assemblyId) {
              return (<div key={item.id || item.fileId}/>)
            } else {
              return (
                <UploadingItemCard item={item} key={item.id || item.fileId}/>
              )
            }
          default:
            return (<div key={item.id || item.fileId}/>)
        }
      }}
    />
  );
}