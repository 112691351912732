import React, {useEffect, useState} from "react";
import "./style.less";
import {CompanySidebar} from "../../../../components/company";
import {logoutUser} from "../../../../actions/authActions";
import {useDispatch, useSelector} from "react-redux";
import {Layout, Row, Col, Card, Tabs, Spin, Tooltip} from 'antd'
import {IconButton} from "@mui/material";
import history from "../../../../utils/history";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import {LaserCuttingTab} from "../../../../components/company/ProducerMaterials/MaterialTabs/LaserCuttingTab";
import {EyeFilled, LoadingOutlined} from "@ant-design/icons";
import {MaterialBendingTab} from "../../../../components/company/ProducerMaterials/MaterialTabs/MaterialBendingTab";
import {SimpleMachiningTab} from "../../../../components/company/ProducerMaterials/MaterialTabs/SimpleMachiningTab";
import {SurfaceCoatingTab} from "../../../../components/company/ProducerMaterials/MaterialTabs/SurfaceCoatingTab";
import {MaterialsTab} from "../../../../components/company/ProducerMaterials/MaterialTabs/MaterialsTab";
import {MachiningTab} from "../../../../components/company/ProducerMaterials/MaterialTabs/MachiningTab";
import {TubeCuttingTab} from "../../../../components/company/ProducerMaterials/MaterialTabs/TubeCuttingTab";
import {fetchProducer} from "../../../../actions/producersActions";
import {useParams} from "react-router-dom";

const { TabPane } = Tabs;

export const ProducerMaterialsPage = (props) => {

  const params = useParams();

  const getInitialTab = () => {
    if(history.state?.state?.tab) {
      return history.state.state.tab
    }
    const query = new URLSearchParams(window.location.search);
    const tab = query.get("tab")

    if(tab) {
      switch(tab) {
        case "material":
          return "7"
        case "cutting":
          return "1"
        case "bending":
          return "2"
        case "simple-machining":
          return "3"
        case "machining":
          return "4"
        case "tubes":
          return "5"
        case "coating":
          return "6"
        default:
          return "1"
      }
    }
    return "1"
  }

  const producerId = params.producerId

  const user = useSelector(state => state.auth.user);
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const isFetchingBendingData = useSelector(state => state.producerParams.isFetchingBendingData)
  const isFetchingSimpleMachiningData = useSelector(state => state.producerParams.isFetchingSimpleMachiningData)
  const isFetchingMachiningData = useSelector(state => state.producerParams.isFetchingMachiningData)
  const isFetchingSurfaceCoatingData = useSelector(state => state.producerParams.isFetchingSurfaceCoatingData)
  const isFetchingTubeCuttingData = useSelector(state => state.producerParams.isFetchingTubeCuttingData)
  const isFetchingProducer = useSelector(state => state.producers.fetchingProducerDetails)

  const [selectedTab, setSelectedTab] = useState(getInitialTab());
  const [producerName, setProducerName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducer({producerId: producerId})).then((producer)=>{
      setProducerName(producer.name)
    }).catch(()=>{
      history.push("/producersManagement")
    })
  }, [])

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{overflowX: "hidden"}}>
          <div style={{marginRight: 25, marginBottom: 10, marginLeft: 25, marginTop: 15}}>
            <Row type={"flex"}>
              <Col span={24}>
                <Card className={"producerDetailsHeader"}>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex"}}>
                      <div style={{marginRight: 5, marginTop: 6}}>
                        <IconButton
                          color="primary"
                          aria-label="back to pending quotes"
                          size="small"
                          onClick={()=>{
                            history.goBack()
                          }}
                        >
                          <ArrowBackIosRoundedIcon  fontSize="inherit" />
                        </IconButton>
                      </div>
                      <div
                        className={"orderDetailsTitle"}
                        style={{ marginTop: 4 }}
                      >
                        {isFetchingProducer || !producerName ?
                          <Spin
                            spinning={true}
                            indicator={<LoadingOutlined style={{ fontSize: 22 }} spin />}
                          />
                          :
                          <span>
                            {producerName}
                            <Tooltip
                              placement={"right"}
                              overlayClassName={"producerTooltip"}
                              title={"You are viewing the parameters of this producer. The parameters here are not editable."}
                            >
                              <EyeFilled style={{marginLeft: 8, position: "relative", top: 2, color: "#ff5b2d"}}/>
                            </Tooltip>
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        <div style={{paddingLeft: 15, paddingRight: 15}}>
          <Tabs size={"small"}
                activeKey={selectedTab}
                onChange={(e)=>{setSelectedTab(e)}}
                type="card"
                className={"materialsTabs noSelect"}
          >
            <TabPane className={"materialsTabPane noSelect"} tab="Cutting Machines" key="1">
              <LaserCuttingTab
                selectedTab={selectedTab}
                producerId={producerId}
              />
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Bending" key="2">
              <Spin spinning={isFetchingBendingData}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <MaterialBendingTab
                  selectedTab={selectedTab}
                  producerId={producerId}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Simple Machining" key="3">
              <Spin spinning={isFetchingSimpleMachiningData}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <SimpleMachiningTab
                  selectedTab={selectedTab}
                  producerId={producerId}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Machining" key="4">
              <Spin spinning={isFetchingMachiningData}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <MachiningTab
                  selectedTab={selectedTab}
                  producerId={producerId}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Tube Cutting" key="5">
              <Spin spinning={isFetchingTubeCuttingData}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <TubeCuttingTab
                  selectedTab={selectedTab}
                  producerId={producerId}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Surface Treatments" key="6">
              <Spin spinning={isFetchingSurfaceCoatingData}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <SurfaceCoatingTab
                  selectedTab={selectedTab}
                  producerId={producerId}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Materials" key="7">
              <MaterialsTab
                selectedTab={selectedTab}
                producerId={producerId}
              />
            </TabPane>
          </Tabs>
        </div>
      </Layout.Content>
    </Layout>
  )
}