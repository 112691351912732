export const AUTH_SET_USER = 'AUTH_SET_USER'
export const AUTH_SET_LOGGING_IN = 'AUTH_SET_LOGGING_IN'
export const AUTH_SET_REGISTERING = 'AUTH_SET_REGISTERING'
export const AUTH_SET_REDIRECT = 'AUTH_SET_REDIRECT'
export const AUTH_SET_REGISTERING_COMPANY = 'AUTH_SET_REGISTERING_COMPANY'

export const UTIL_COLLAPSE = 'UTIL_COLLAPSE';
export const UTIL_UPDATE_ACTIVE_SUBMENU_KEYS = 'UTIL_UPDATE_ACTIVE_SUBMENU_KEYS';
export const UTIL_UPDATE_CUSTOMER_ACTIVE_SUBMENU_KEYS = 'UTIL_UPDATE_CUSTOMER_ACTIVE_SUBMENU_KEYS';
export const UTIL_IS_FETCHING_STATS = 'UTIL_IS_FETCHING_STATS';

export const PRODUCERS_PRODUCERS = 'PRODUCERS_PROVIDER'
export const PRODUCERS_SET_IS_CREATING_PRODUCER = 'PRODUCERS_SET_IS_CREATING_PRODUCER'
export const PRODUCERS_FETCHING_PRODUCERS = 'PRODUCERS_FETCHING_PRODUCERS_PROVIDER'
export const PRODUCERS_FETCHING_PRODUCER_DETAILS = 'PRODUCERS_FETCHING_PRODUCER_DETAILS'
export const PRODUCERS_UPDATE_PRODUCER_BLOCKED = 'PRODUCERS_UPDATE_PRODUCER_BLOCKED'
export const PRODUCERS_SET_IS_UPDATING_PRODUCER_INFO = 'PRODUCERS_SET_IS_UPDATING_PRODUCER_INFO'
export const PRODUCERS_SET_IS_UPDATING_PRODUCER_LOGO = 'PRODUCERS_SET_IS_UPDATING_PRODUCER_LOGO'
export const PRODUCERS_SET_PRODUCER_LOGO = 'PRODUCERS_SET_PRODUCER_LOGO'

export const PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINE_DATA = 'PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINE_DATA'
export const PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINES = 'PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINES'
export const PRODUCER_PARAMS_IS_FETCHING_MACHINE_PRIORITIES = 'PRODUCER_PARAMS_IS_FETCHING_MACHINE_PRIORITIES'
export const PRODUCER_PARAMS_SET_CUTTING_MACHINES = 'PRODUCER_PARAMS_SET_CUTTING_MACHINES'
export const PRODUCER_PARAMS_SET_MACHINE_DATA = 'PRODUCER_PARAMS_SET_MACHINE_DATA'
export const PRODUCER_PARAMS_SET_MACHINE_PRIORITIES = 'PRODUCER_PARAMS_SET_MACHINE_PRIORITIES'
export const PRODUCER_PARAMS_IS_FETCHING_MATERIALS = 'PRODUCER_PARAMS_IS_FETCHING_MATERIALS'
export const PRODUCER_PARAMS_SET_MATERIALS = 'PRODUCER_PARAMS_SET_MATERIALS'
export const PRODUCER_PARAMS_IS_FETCHING_MATERIAL_PRICES = 'PRODUCER_PARAMS_IS_FETCHING_MATERIAL_PRICES'
export const PRODUCER_PARAMS_SET_MATERIAL_PRICES = 'PRODUCER_PARAMS_SET_MATERIALS_PRICES'
export const PRODUCER_PARAMS_IS_FETCHING_MACHINING_DATA = 'PRODUCER_PARAMS_IS_FETCHING_MACHINING_DATA'
export const PRODUCER_PARAMS_SET_MACHINING_DATA = 'PRODUCER_PARAMS_SET_MACHINING_DATA'
export const PRODUCER_PARAMS_IS_FETCHING_SURFACE_COATING = 'PRODUCER_PARAMS_IS_FETCHING_SURFACE_COATING'
export const PRODUCER_PARAMS_SET_SURFACE_COATING_DATA = 'PRODUCER_PARAMS_SET_SURFACE_COATING_DATA'
export const PRODUCER_PARAMS_IS_FETCHING_BENDING_DATA = 'PRODUCER_PARAMS_IS_FETCHING_BENDING_DATA'
export const PRODUCER_PARAMS_SET_BENDING_DATA = 'PRODUCER_PARAMS_SET_BENDING_DATA'
export const PRODUCER_PARAMS_IS_FETCHING_SIMPLE_MACHINING_DATA = 'PRODUCER_PARAMS_IS_FETCHING_SIMPLE_MACHINING_DATA'
export const PRODUCER_PARAMS_SET_SIMPLE_MACHINING_DATA = 'PRODUCER_PARAMS_SET_SIMPLE_MACHINING_DATA'
export const PRODUCER_PARAMS_IS_FETCHING_SINGLE_MATERIAL_BENDS = 'PRODUCER_PARAMS_IS_FETCHING_SINGLE_MATERIAL_BENDS'
export const PRODUCER_PARAMS_SET_SINGLE_MATERIAL_BENDS = 'PRODUCER_PARAMS_SET_SINGLE_MATERIAL_BENDS'
export const PRODUCER_PARAMS_RESET_PRODUCER_PARAMS = 'PRODUCER_PARAMS_RESET_PRODUCER_PARAMS'
export const PRODUCER_PARAMS_SET_MILLING_MACHINE_DATA = 'PRODUCER_PARAMS_SET_MILLING_MACHINE_DATA'
export const PRODUCER_PARAMS_IS_FETCHING_MILLING_MACHINE_DATA = 'PRODUCER_PARAMS_IS_FETCHING_MILLING_MACHINE_DATA'
export const PRODUCER_PARAMS_SET_LATHING_MACHINE_DATA = 'PRODUCER_PARAMS_SET_LATHING_MACHINE_DATA'
export const PRODUCER_PARAMS_IS_FETCHING_LATHING_MACHINE_DATA = 'PRODUCER_PARAMS_IS_FETCHING_LATHING_MACHINE_DATA'
export const PRODUCER_PARAMS_IS_FETCHING_MACHINING_MATERIALS = 'PRODUCER_PARAMS_IS_FETCHING_MACHINING_MATERIALS'
export const PRODUCER_PARAMS_SET_MACHINING_MATERIALS = 'PRODUCER_PARAMS_SET_MACHINING_MATERIALS'
export const PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_DATA = 'PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_DATA'
export const PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_MACHINE_DATA = 'PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_MACHINE_DATA'
export const PRODUCER_PARAMS_SET_TUBE_CUTTING_DATA = 'PRODUCER_PARAMS_SET_TUBE_CUTTING_DATA'
export const PRODUCER_PARAMS_SET_TUBE_CUTTING_MACHINE_DATA = 'PRODUCER_PARAMS_SET_TUBE_CUTTING_MACHINE_DATA'
export const PRODUCER_PARAMS_IS_FETCHING_TUBE_MATERIAL_PRICES = 'PRODUCER_PARAMS_IS_FETCHING_TUBE_MATERIAL_PRICES'
export const PRODUCER_PARAMS_SET_TUBE_MATERIAL_PRICES = 'PRODUCER_PARAMS_SET_TUBE_MATERIAL_PRICES'
export const PRODUCER_PARAMS_IS_FETCHING_STANDARD_TUBE_MATERIAL_PRICES = 'PRODUCER_PARAMS_IS_FETCHING_STANDARD_TUBE_MATERIAL_PRICES'
export const PRODUCER_PARAMS_SET_STANDARD_TUBE_MATERIAL_PRICES = 'PRODUCER_PARAMS_SET_STANDARD_TUBE_MATERIAL_PRICES'
export const PRODUCER_PARAMS_IS_FETCHING_MATERIAL_CERTIFICATES = 'PRODUCER_PARAMS_IS_FETCHING_MATERIAL_CERTIFICATES'
export const PRODUCER_PARAMS_SET_MATERIAL_CERTIFICATES = 'PRODUCER_PARAMS_SET_MATERIAL_CERTIFICATES'

export const SUB_ADMINS_IS_ADDING_SUB_ADMIN = 'SUB_ADMINS_IS_ADDING_SUB_ADMIN'
export const SUB_ADMINS_IS_FETCHING_SUB_ADMINS = 'SUB_ADMINS_IS_FETCHING_SUB_ADMINS'
export const SUB_ADMINS_SET_SUB_ADMINS = 'SUB_ADMINS_SET_SUB_ADMINS'
export const SUB_ADMINS_SET_SUB_ADMINS_PAGINATION = 'SUB_ADMINS_SET_SUB_ADMINS_PAGINATION'
export const SUB_ADMINS_IS_FETCHING_SUB_ADMINS_LIMIT = 'SUB_ADMINS_IS_FETCHING_SUB_ADMINS_LIMIT'
export const SUB_ADMINS_SET_CURRENT_SUB_ADMINS = 'SUB_ADMINS_SET_CURRENT_SUB_ADMINS'
export const SUB_ADMINS_IS_EDITING_SUB_ADMINS = 'SUB_ADMINS_IS_EDITING_SUB_ADMINS'
export const SUB_ADMINS_SET_ADMIN_PERMISSIONS = 'SUB_ADMINS_SET_ADMIN_PERMISSIONS'

export const CUSTOMERS_CUSTOMERS = 'CUSTOMERS_CUSTOMERS'
export const CUSTOMERS_CUSTOMERS_PAGINATION = 'CUSTOMERS_CUSTOMERS_PAGINATION'
export const CUSTOMERS_FETCHING_CUSTOMERS = 'CUSTOMERS_FETCHING_CUSTOMERS'
export const CUSTOMER_FETCHING_CUSTOMER_DETAILS = 'CUSTOMER_FETCHING_CUSTOMER_DETAILS'
export const CUSTOMER_SET_IS_REMOVING_CUSTOMER = 'CUSTOMER_SET_IS_REMOVING_CUSTOMER'
export const CUSTOMER_REMOVE_CUSTOMER = 'CUSTOMER_REMOVE_CUSTOMER'
export const CUSTOMER_TOGGLING_BLOCK = 'CUSTOMER_TOGGLING_BLOCK'
export const CUSTOMER_BLOCK_CUSTOMER = 'CUSTOMER_BLOCK_CUSTOMER'
export const CUSTOMER_FETCHING_UNAUTHORIZED_CUSTOMERS = 'CUSTOMER_FETCHING_UNAUTHORIZED_CUSTOMERS'
export const CUSTOMER_SET_UNAUTHORIZED_CUSTOMERS = 'CUSTOMER_SET_UNAUTHORIZED_CUSTOMERS'
export const CUSTOMER_SET_IS_AUTHORIZING_CUSTOMER = 'CUSTOMER_SET_IS_AUTHORIZING_CUSTOMER'

export const CORPORATE_CUSTOMERS_FETCHING_CUSTOMERS = 'CORPORATE_CUSTOMER_FETCHING_CUSTOMERS'
export const CORPORATE_CUSTOMERS_FETCHING_DETAILS = 'CORPORATE_CUSTOMERS_FETCHING_DETAILS'
export const CORPORATE_CUSTOMERS_CUSTOMERS = 'CORPORATE_CUSTOMER_CUSTOMERS'
export const CORPORATE_CUSTOMERS_PAGINATION = 'CORPORATE_CUSTOMERS_PAGINATION'
export const CORPORATE_CUSTOMERS_BLOCK_CUSTOMER = 'CUSTOMER_BLOCK_CUSTOMER'
export const CORPORATE_CUSTOMERS_IS_REMOVING_CUSTOMER = 'CORPORATE_CUSTOMERS_IS_REMOVING_CUSTOMER'
export const CORPORATE_CUSTOMERS_REMOVE_CUSTOMER = 'CORPORATE_CUSTOMERS_REMOVE_CUSTOMER'
export const CORPORATE_CUSTOMERS_TOGGLING_BLOCK = 'CORPORATE_CUSTOMERS_TOGGLING_BLOCK'

export const MATERIALS_SET_MATERIAL_PRICES = 'MATERIALS_SET_MATERIAL_PRICES'
export const MATERIALS_FETCHING_MATERIAL_PRICES = 'MATERIALS_FETCHING_MATERIAL_PRICES'
export const MATERIALS_UPDATE_MATERIAL_PRICE = 'MATERIALS_UPDATE_MATERIAL_PRICE'
export const MATERIALS_FETCHING_MATERIAL_CERTIFICATE_PRICES = 'MATERIALS_FETCHING_MATERIAL_CERTIFICATE_PRICES'
export const MATERIALS_SET_MATERIAL_CERTIFICATE_PRICES = 'MATERIALS_SET_MATERIAL_CERTIFICATE_PRICES'
export const MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE = 'MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE'
export const MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE = 'MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE'

export const BENDING_IS_FETCHING_BENDING_DATA = 'BENDING_IS_FETCHING_BENDING_DATA'
export const BENDING_SET_BENDING_DATA = 'BENDING_SET_BENDING_DATA'
export const BENDING_SET_BENDING_SERVICE = 'BENDING_SET_BENDING_SERVICE'
export const BENDING_SET_BENDING_MAX_LENGTH = 'BENDING_SET_BENDING_MAX_LENGTH'
export const BENDING_SET_BENDING_PREPARATION_PRICE = 'BENDING_SET_BENDING_PREPARATION_PRICE'
export const BENDING_SET_BENDING_AVERAGE_PREPARATION_TIME = 'BENDING_SET_BENDING_AVERAGE_PREPARATION_TIME'
export const BENDING_FETCHING_SINGLE_MATERIAL_BENDS = 'BENDING_FETCHING_SINGLE_MATERIAL_BENDS'
export const BENDING_SET_MATERIAL_BENDING_DATA = 'BENDING_SET_MATERIAL_BENDING_DATA'
export const BENDING_RESET_BENDS_DATA = 'BENDING_RESET_BENDS_DATA'
export const BENDING_IS_ADDING_NEW_BEND = 'BENDING_IS_ADDING_NEW_BEND'
export const BENDING_SET_SINGLE_MATERIAL_BENDS = 'BENDING_SET_SINGLE_MATERIAL_BENDS'
export const BENDING_IS_EDITING_BEND = 'BENDING_IS_EDITING_BEND'
export const BENDING_IS_DELETING_BENDS = 'BENDING_IS_DELETING_BENDS'

export const SIMPLE_MACHINING_SET_DATA = 'SIMPLE_MACHINING_SET_DATA'
export const SIMPLE_MACHINING_IS_FETCHING_DATA = 'SIMPLE_MACHINING_IS_FETCHING_DATA'
export const SIMPLE_MACHINING_SET_SIMPLE_MACHINING_SERVICE = 'SIMPLE_MACHINING_SET_SIMPLE_MACHINING_SERVICE'
export const SIMPLE_MACHINING_SET_DEBURRING_SERVICE = 'SIMPLE_MACHINING_SET_DEBURRING_SERVICE'
export const SIMPLE_MACHINING_SET_CHAMFERING_SERVICE = 'SIMPLE_MACHINING_SET_CHAMFERING_SERVICE'
export const SIMPLE_MACHINING_SET_DRILLING_SERVICE = 'SIMPLE_MACHINING_SET_DRILLING_SERVICE'
export const SIMPLE_MACHINING_SET_DEBURRING_DATA = 'SIMPLE_MACHINING_SET_DEBURRING_DATA'
export const SIMPLE_MACHINING_SET_CHAMFERING_DATA = 'SIMPLE_MACHINING_SET_CHAMFERING_DATA'
export const SIMPLE_MACHINING_SET_DRILLING_DATA = 'SIMPLE_MACHINING_SET_DRILLING_DATA'
export const SIMPLE_MACHINING_SET_PREPARATION_DATA = 'SIMPLE_MACHINING_SET_PREPARATION_DATA'

export const MACHINING_SET_DATA = 'MACHINING_SET_DATA'
export const MACHINING_IS_FETCHING_DATA = 'MACHINING_IS_FETCHING_DATA'
export const MACHINING_IS_FETCHING_SELECTED_MILLING_MACHINE_DATA = 'MACHINING_IS_FETCHING_SELECTED_MILLING_MACHINE_DATA'
export const MACHINING_IS_FETCHING_SELECTED_LATHING_MACHINE_DATA = 'MACHINING_IS_FETCHING_SELECTED_LATHING_MACHINE_DATA'
export const MACHINING_IS_FETCHING_MATERIALS = 'MACHINING_IS_FETCHING_MATERIALS'
export const MACHINING_SET_MATERIALS = 'MACHINING_SET_MATERIALS'
export const MACHINING_SET_MACHINING_SERVICE = 'MACHINING_SET_MACHINING_SERVICE'
export const MACHINING_SET_MILLING_SERVICE = 'MACHINING_SET_MILLING_SERVICE'
export const MACHINING_SET_LATHING_SERVICE = 'MACHINING_SET_LATHING_SERVICE'
export const MACHINING_SET_MILLING_DATA = 'MACHINING_SET_MILLING_DATA'
export const MACHINING_SET_LATHING_DATA  = 'MACHINING_SET_LATHING_DATA'
export const MACHINING_SET_SELECTED_MILLING_MACHINE = 'MACHINING_SET_SELECTED_MILLING_MACHINE'
export const MACHINING_SET_SELECTED_LATHING_MACHINE = 'MACHINING_SET_SELECTED_LATHING_MACHINE'
export const MACHINING_SET_MACHINING_MATERIAL_PRICE = 'MACHINING_SET_MACHINING_MATERIAL_PRICE'
export const MACHINING_SET_IS_CREATING_MILLING_MACHINE = 'MACHINING_SET_IS_CREATING_MILLING_MACHINE'
export const MACHINING_SET_IS_CREATING_LATHING_MACHINE = 'MACHINING_SET_IS_CREATING_LATHING_MACHINE'
export const MACHINING_SET_MILLING_MACHINES = 'MACHINING_SET_MILLING_MACHINES'
export const MACHINING_SET_LATHING_MACHINES = 'MACHINING_SET_LATHING_MACHINES'
export const MACHINING_IS_REMOVING_MILLING_MACHINE = 'MACHINING_IS_REMOVING_MILLING_MACHINE'
export const MACHINING_IS_REMOVING_LATHING_MACHINE = 'MACHINING_IS_REMOVING_LATHING_MACHINE'
export const MACHINING_IS_UPDATING_MILLING_MACHINE = 'MACHINING_IS_UPDATING_MILLING_MACHINE'
export const MACHINING_IS_UPDATING_LATHING_MACHINE = 'MACHINING_IS_UPDATING_LATHING_MACHINE'
export const MACHINING_SET_MILLING_GRADE_SPEED = 'MACHINING_SET_MILLING_GRADE_SPEED'
export const MACHINING_SET_LATHING_GRADE_SPEED = 'MACHINING_SET_LATHING_GRADE_SPEED'
export const MACHINING_SET_MILLING_MACHINE_TOLERANCE = 'MACHINING_SET_MILLING_MACHINE_TOLERANCE'
export const MACHINING_SET_LATHING_MACHINE_TOLERANCE = 'MACHINING_SET_LATHING_MACHINE_TOLERANCE'
export const MACHINING_SET_MILLING_MACHINE_ROUGHNESS = 'MACHINING_SET_MILLING_MACHINE_ROUGHNESS'
export const MACHINING_SET_LATHING_MACHINE_ROUGHNESS = 'MACHINING_SET_LATHING_MACHINE_ROUGHNESS'

export const TUBE_CUTTING_FETCHING_MATERIAL_PRICES = 'TUBE_CUTTING_FETCHING_MATERIAL_PRICES'
export const TUBE_CUTTING_FETCHING_STANDARD_MATERIAL_PRICES = 'TUBE_CUTTING_FETCHING_STANDARD_MATERIAL_PRICES'
export const TUBE_CUTTING_SET_MATERIAL_PRICES = 'TUBE_CUTTING_SET_MATERIAL_PRICES'
export const TUBE_CUTTING_SET_STANDARD_MATERIAL_PRICES = 'TUBE_CUTTING_SET_STANDARD_MATERIAL_PRICES'
export const TUBE_CUTTING_UPDATE_MATERIAL_PRICE = 'TUBE_CUTTING_UPDATE_MATERIAL_PRICE'
export const TUBE_CUTTING_UPDATE_STANDARD_MATERIAL_PRICE = 'TUBE_CUTTING_UPDATE_STANDARD_MATERIAL_PRICE'
export const TUBE_CUTTING_SET_CUTTING_SPEED = 'TUBE_CUTTING_SET_CUTTING_SPEED'
export const TUBE_CUTTING_SET_MACHINE_TOLERANCE = 'TUBE_CUTTING_SET_MACHINE_TOLERANCE'

export const SURFACE_COATING_SET_DATA = 'SURFACE_COATING_SET_DATA'
export const SURFACE_COATING_IS_FETCHING_DATA = 'SURFACE_COATING_IS_FETCHING_DATA'
export const SURFACE_COATING_SET_SURFACE_COATING_SERVICE = 'SURFACE_COATING_SET_SURFACE_COATING_SERVICE'
export const SURFACE_COATING_SET_PAINTING_SERVICE = 'SURFACE_COATING_SET_PAINTING_SERVICE'
export const SURFACE_COATING_SET_POWDER_PAINTING_SERVICE = 'SURFACE_COATING_SET_POWDER_PAINTING_SERVICE'
export const SURFACE_COATING_SET_GALVANIZING_SERVICE = 'SURFACE_COATING_SET_GALVANIZING_SERVICE'
export const SURFACE_COATING_SET_NICKEL_PLATING_SERVICE = 'SURFACE_COATING_SET_NICKEL_PLATING_SERVICE'
export const SURFACE_COATING_SET_ANODIZING_SERVICE = 'SURFACE_COATING_SET_ANODIZING_SERVICE'
export const SURFACE_COATING_SET_HEAT_TREATMENT_SERVICE = "SURFACE_COATING_SET_HEAT_TREATMENT_SERVICE"
export const SURFACE_COATING_SET_BLASTING_SERVICE = "SURFACE_COATING_SET_BLASTING_SERVICE"
export const SURFACE_COATING_SET_PAINTING_DATA = 'SURFACE_COATING_SET_PAINTING_DATA'
export const SURFACE_COATING_SET_POWDER_PAINTING_DATA = 'SURFACE_COATING_SET_POWDER_PAINTING_DATA'
export const SURFACE_COATING_SET_GALVANIZING_DATA = 'SURFACE_COATING_SET_GALVANIZING_DATA'
export const SURFACE_COATING_SET_NICKEL_PLATING_DATA = 'SURFACE_COATING_SET_NICKEL_PLATING_DATA'
export const SURFACE_COATING_SET_ANODIZING_DATA = 'SURFACE_COATING_SET_ANODIZING_DATA'
export const SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_SERVICE = "SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_SERVICE"
export const SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_DATA = "SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_DATA"
export const SURFACE_COATING_SET_BLASTING_DATA = "SURFACE_COATING_SET_BLASTING_DATA"
export const SURFACE_COATING_SET_HEAT_TREATMENT_DATA = "SURFACE_COATING_SET_HEAT_TREATMENT_DATA"


export const CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINES = 'CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINES'
export const CUTTING_MACHINES_SET_CUTTING_MACHINES = 'CUTTING_MACHINES_SET_CUTTING_MACHINES'
export const CUTTING_MACHINES_IS_FETCHING_MACHINE_TYPES = 'CUTTING_MACHINES_IS_FETCHING_MACHINE_TYPES'
export const CUTTING_MACHINES_SET_MACHINE_TYPES = 'CUTTING_MACHINES_SET_MACHINE_TYPES'
export const CUTTING_MACHINES_IS_FETCHING_MACHINE_PRIORITIES = 'CUTTING_MACHINES_IS_FETCHING_MACHINE_PRIORITIES'
export const CUTTING_MACHINES_SET_MACHINE_PRIORITIES = 'CUTTING_MACHINES_SET_MACHINE_PRIORITIES'
export const CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINE_DATA = 'CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINE_DATA'
export const CUTTING_MACHINES_SET_MACHINE_DATA = 'CUTTING_MACHINES_SET_MACHINE_DATA'
export const CUTTING_MACHINES_SET_CUTTING_SPEED = 'CUTTING_MACHINES_SET_CUTTING_SPEED'
export const CUTTING_MACHINES_SET_MACHINE_PARAMETER = 'CUTTING_MACHINES_SET_MACHINE_PARAMETER'
export const CUTTING_MACHINES_IS_UPDATING_MACHINE_PRIORITIES = 'CUTTING_MACHINES_IS_UPDATING_MACHINE_PRIORITIES'
export const CUTTING_MACHINES_IS_CREATING_CUTTING_MACHINE = 'CUTTING_MACHINES_IS_CREATING_CUTTING_MACHINE'
export const CUTTING_MACHINES_IS_EDITING_CUTTING_MACHINE = 'CUTTING_MACHINES_IS_EDITING_CUTTING_MACHINE'
export const CUTTING_MACHINES_IS_DELETING_CUTTING_MACHINE = 'CUTTING_MACHINES_IS_DELETING_CUTTING_MACHINE'
export const CUTTING_MACHINES_SET_TOLERANCE = 'CUTTING_MACHINES_SET_TOLERANCE'

export const TUBE_CUTTING_SET_TUBE_CUTTING_SERVICE = "TUBE_CUTTING_SET_TUBE_CUTTING_SERVICE"
export const TUBE_CUTTING_MACHINES_IS_FETCHING_TUBE_CUTTING_DATA = 'TUBE_CUTTING_MACHINES_IS_FETCHING_TUBE_CUTTING_DATA'
export const TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINES = 'TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINES'
export const TUBE_CUTTING_MACHINES_IS_FETCHING_MACHINE_PROPERTIES = "TUBE_CUTTING_MACHINES_IS_FETCHING_MACHINE_PROPERTIES"
export const TUBE_CUTTING_MACHINES_SET_MACHINE_DATA = 'TUBE_CUTTING_MACHINES_SET_MACHINE_DATA'
export const TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINE_PARAMETER = 'TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINE_PARAMETER'
export const TUBE_CUTTING_MACHINES_IS_CREATING_TUBE_CUTTING_MACHINE = 'TUBE_CUTTING_MACHINES_IS_CREATING_TUBE_CUTTING_MACHINE'
export const TUBE_CUTTING_MACHINES_IS_EDITING_TUBE_CUTTING_MACHINE = 'TUBE_CUTTING_MACHINES_IS_EDITING_TUBE_CUTTING_MACHINE'
export const TUBE_CUTTING_MACHINES_IS_DELETING_TUBE_CUTTING_MACHINE = 'TUBE_CUTTING_MACHINES_IS_DELETING_TUBE_CUTTING_MACHINE'

export const ADMIN_LOGS = 'ADMIN_LOGS'
export const ADMIN_LOGS_PAGINATION = 'ADMIN_LOGS_PAGINATION'
export const ADMIN_FETCHING_LOGS = 'ADMIN_FETCHING_LOGS'

export const CUSTOMER_SETTINGS_SET_EDITING_CUSTOMER_SETTINGS = 'CUSTOMER_SETTINGS_SET_EDITING_CUSTOMER_SETTINGS'
export const CUSTOMER_SETTINGS_SET_RESETTING_PASSWORD = 'CUSTOMER_SETTINGS_SET_RESETTING_PASSWORD'

export const COMPANY_SETTINGS_SET_FETCHING_COMPANY_DETAILS = 'COMPANY_SETTINGS_SET_FETCHING_COMPANY_DETAILS'
export const COMPANY_SETTINGS_SET_COMPANY_DETAILS = 'COMPANY_SETTINGS_SET_COMPANY_DETAILS'
export const COMPANY_SETTINGS_SET_EDITING_COMPANY_SETTINGS = 'COMPANY_SETTINGS_SET_EDITING_COMPANY_SETTINGS'
export const COMPANY_SETTINGS_SET_RESETTING_PASSWORD = 'COMPANY_SETTINGS_SET_RESETTING_PASSWORD'
export const CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER = 'CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER'
export const CUSTOMER_RELATIONS_SET_INTERNAL_RELATIONS = 'CUSTOMER_RELATIONS_SET_INTERNAL_RELATIONS'
export const CUSTOMER_RELATIONS_INTERNAL_PAGINATION = 'CUSTOMER_RELATIONS_INTERNAL_PAGINATION'
export const CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS = 'CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS'

export const ORDER_SET_ORDER = 'ORDER_SET_ORDER'
export const ORDER_RESET_ORDER = 'ORDER_RESET_ORDER'
export const ORDER_SET_DRAFT_STAGE = 'ORDER_SET_DRAFT_STAGE'
export const ORDER_SET_SHIPPING_DATA = 'ORDER_SET_SHIPPING_DATA'
export const ORDER_SET_DELIVERY_DATE = 'ORDER_SET_DELIVERY_DATE'
export const ORDER_SET_DRAFT_NAME = 'ORDER_SET_DRAFT_NAME'
export const ORDER_SET_CHANGED_ADJUSTMENT = 'ORDER_SET_CHANGED_ADJUSTMENT'
export const ORDER_CLEAR_ORDER = 'ORDER_CLEAR_ORDER'
export const ORDER_RESET_TO_PROCESS = 'ORDER_RESET_TO_PROCESS'
export const ORDER_INCREMENT_TO_PROCESS = 'ORDER_INCREMENT_TO_PROCESS'
export const ORDER_INCREMENT_UPLOADED = 'ORDER_INCREMENT_UPLOADED'
export const ORDER_INCREMENT_PROCESSED = 'ORDER_INCREMENT_PROCESSED'

export const ORDER_PARTS_CHANGE_PART_PROCESSING_TYPE = 'ORDER_PARTS_CHANGE_PART_PROCESSING_TYPE'
export const ORDER_PARTS_SET_ASSEMBLY_PARTS_DATA = 'ORDER_PARTS_SET_ASSEMBLY_PARTS_DATA'
export const ORDER_PARTS_SET_ASSEMBLY_DATA = 'ORDER_PARTS_SET_ASSEMBLY_DATA'
export const ORDER_PARTS_DELETE_ENTIRE_ASSEMBLY = 'ORDER_PARTS_DELETE_ENTIRE_ASSEMBLY'
export const ORDER_PARTS_UPDATE_PART_ANALYSED_STATUS = 'ORDER_PARTS_UPDATE_PART_ANALYSED_STATUS'
export const ORDER_PARTS_CHANGE_ASSEMBLY_QUANTITY = 'ORDER_PARTS_CHANGE_ASSEMBLY_QUANTITY'
export const ORDER_PARTS_UPDATE_ASSEMBLY_PRICE = 'ORDER_PARTS_UPDATE_ASSEMBLY_PRICE'
export const ORDER_PARTS_UPDATE_MANY_ASSEMBLIES_PRICES = 'ORDER_PARTS_UPDATE_MANY_ASSEMBLIES_PRICES'
export const ORDER_PARTS_SET_PART_COLOR = 'ORDER_PARTS_SET_PART_COLOR'
export const ORDER_PARTS_REMOVE_PART_COLOR = 'ORDER_PARTS_REMOVE_PART_COLOR'
export const ORDER_PARTS_SET_PART_QUANTITY = 'ORDER_PARTS_SET_PART_QUANTITY'
export const ORDER_PARTS_SET_PART_NOTE = 'ORDER_PARTS_SET_PART_NOTE'
export const ORDER_PARTS_SET_PART_STANDARD = 'ORDER_PARTS_SET_PART_STANDARD'
export const ORDER_PARTS_SET_PART_MATERIAL = 'ORDER_PARTS_SET_PART_MATERIAL'
export const ORDER_PARTS_SET_PART_MACHINING_MATERIAL = 'ORDER_PARTS_SET_PART_MACHINING_MATERIAL'
export const ORDER_PARTS_SET_PART_MATERIAL_CERTIFICATE = 'ORDER_PARTS_SET_PART_MATERIAL_CERTIFICATE'
export const ORDER_PARTS_SET_PART_TUBE_CUTTING_MATERIAL = 'ORDER_PARTS_SET_PART_TUBE_CUTTING_MATERIAL'
export const ORDER_PARTS_SET_PART_THICKNESS = 'ORDER_PARTS_SET_PART_THICKNESS'
export const ORDER_PARTS_SET_ASSEMBLY_WELDING_PRICE = 'ORDER_PARTS_SET_ASSEMBLY_WELDING_PRICE'
export const ORDER_PARTS_SET_ASSEMBLY_ASSEMBLING_PRICE = 'ORDER_PARTS_SET_ASSEMBLY_ASSEMBLING_PRICE'
export const ORDER_PARTS_SET_ASSEMBLY_PACKAGING_PRICE = 'ORDER_PARTS_SET_ASSEMBLY_PACKAGING_PRICE'
export const ORDER_PARTS_SET_ASSEMBLY_TRANSPORTATION_PRICE = 'ORDER_PARTS_SET_ASSEMBLY_TRANSPORTATION_PRICE'
export const ORDER_PARTS_SET_NUMBER_OF_UNIQUE_PARTS = 'ORDER_PARTS_SET_NUMBER_OF_UNIQUE_PARTS'
export const ORDER_PARTS_REMOVE_ITEM = 'ORDER_PARTS_REMOVE_ITEM'
export const ORDER_PARTS_REMOVE_MULTIPLE_ITEMS = 'ORDER_PARTS_REMOVE_MULTIPLE_ITEMS'
export const ORDER_PARTS_APPLY_MULTIPLE_ITEMS = 'ORDER_PARTS_APPLY_MULTIPLE_ITEMS'
export const ORDER_PARTS_SET_PART_TYPE = 'ORDER_PARTS_SET_PART_TYPE'
export const ORDER_PARTS_ADD_NEW_PART = 'ORDER_PARTS_ADD_NEW_PART'
export const ORDER_PARTS_CHANGE_PART_TOLERANCE_DATA = 'ORDER_PARTS_CHANGE_PART_TOLERANCE_DATA'
export const ORDER_PARTS_CHANGE_PART_TOLERANCE = 'ORDER_PARTS_CHANGE_PART_TOLERANCE'
export const ORDER_PARTS_CHANGE_PART_ROUGHNESS_DATA = 'ORDER_PARTS_CHANGE_PART_ROUGHNESS_DATA'
export const ORDER_PARTS_CHANGE_PART_ROUGHNESS = 'ORDER_PARTS_CHANGE_PART_ROUGHNESS'
export const ORDER_PARTS_CHANGE_MANUAL_PART_TOLERANCE = 'ORDER_PARTS_CHANGE_MANUAL_PART_TOLERANCE'
export const ORDER_PARTS_CHANGE_MANUAL_PART_ROUGHNESS = 'ORDER_PARTS_CHANGE_MANUAL_PART_ROUGHNESS'
export const ORDER_PARTS_CHANGE_PART_MATERIAL_CERTIFICATE_DATA = 'ORDER_PARTS_CHANGE_PART_MATERIAL_CERTIFICATE_DATA'
export const ORDER_PARTS_UPDATE_PART = 'ORDER_PARTS_UPDATE_PART'
export const ORDER_PARTS_UPDATE_PART_DATA = 'ORDER_PARTS_UPDATE_PART_DATA'
export const ORDER_PARTS_UPDATE_PART_FILE_PATH = 'ORDER_PARTS_UPDATE_PART_FILE_PATH'
export const ORDER_PARTS_UPDATE_ORDER_PRICE = 'ORDER_PARTS_UPDATE_ORDER_PRICE'
export const ORDER_PARTS_ADD_PART_DRAWING = 'ORDER_PARTS_ADD_PART_DRAWING'
export const ORDER_PARTS_REMOVE_PART_DRAWING = 'ORDER_PARTS_REMOVE_PART_DRAWING'
export const ORDER_PARTS_UPDATE_MANY_PART_DATA = 'ORDER_PARTS_UPDATE_MANY_PART_DATA'
export const ORDER_PARTS_CHANGE_PART_DATA = 'ORDER_PARTS_CHANGE_PART_DATA'
export const ORDER_PARTS_UPDATE_PART_PRICE = 'ORDER_PARTS_UPDATE_PART_PRICE'
export const ORDER_PARTS_UPDATE_MANY_PART_PRICES = 'ORDER_PARTS_UPDATE_MANY_PART_PRICES'

export const ORDERS_SET_PRODUCER_PENDING_ORDERS = 'ORDERS_SET_PRODUCER_PENDING_ORDERS'
export const ORDERS_SET_PRODUCER_PENDING_ORDERS_PAGINATION = 'ORDERS_SET_PRODUCER_PENDING_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_PRODUCER_PENDING_ORDERS = 'ORDERS_SET_IS_FETCHING_PRODUCER_PENDING_ORDERS'
export const ORDERS_SET_PRODUCER_ACCEPTED_ORDERS = 'ORDERS_SET_PRODUCER_ACCEPTED_ORDERS'
export const ORDERS_SET_PRODUCER_ACCEPTED_ORDERS_PAGINATION = 'ORDERS_SET_PRODUCER_ACCEPTED_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_PRODUCER_ACCEPTED_ORDERS = 'ORDERS_SET_IS_FETCHING_PRODUCER_ACCEPTED_ORDERS'
export const ORDERS_SET_PRODUCER_ARCHIVED_ORDERS = 'ORDERS_SET_PRODUCER_ARCHIVED_ORDERS'
export const ORDERS_SET_PRODUCER_ARCHIVED_ORDERS_PAGINATION = 'ORDERS_SET_PRODUCER_ARCHIVED_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_PRODUCER_ARCHIVED_ORDERS = 'ORDERS_SET_IS_FETCHING_PRODUCER_ARCHIVED_ORDERS'
export const ORDERS_SET_IS_FETCHING_PRODUCER_PROCESSED_ORDERS = 'ORDERS_SET_IS_FETCHING_PRODUCER_PROCESSED_ORDERS'
export const ORDERS_SET_PRODUCER_PROCESSED_ORDERS = 'ORDERS_SET_PRODUCER_PROCESSED_ORDERS'
export const ORDERS_SET_PRODUCER_PROCESSED_ORDERS_PAGINATION = 'ORDERS_SET_PRODUCER_PROCESSED_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_RECENT_PRODUCER_CHANGES = 'ORDERS_SET_IS_FETCHING_RECENT_PRODUCER_CHANGES'
export const ORDERS_RECENT_PRODUCER_CHANGES = 'ORDERS_RECENT_PRODUCER_CHANGES'
export const ORDERS_SET_IS_UPDATING_ODOO_ORDER_LINK = 'ORDERS_SET_IS_UPDATING_ODOO_ORDER_LINK'
export const ORDERS_SET_ODOO_ORDER_LINK = 'ORDERS_SET_ODOO_ORDER_LINK'

export const ORDERS_SET_CUSTOMER_PENDING_ORDERS = 'ORDERS_SET_CUSTOMER_PENDING_ORDERS'
export const ORDERS_SET_CUSTOMER_PENDING_ORDERS_PAGINATION = 'ORDERS_SET_CUSTOMER_PENDING_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_CUSTOMER_PENDING_ORDERS = 'ORDERS_SET_IS_FETCHING_CUSTOMER_PENDING_ORDERS'
export const ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS = 'ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS'
export const ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS_PAGINATION = 'ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_CUSTOMER_ACCEPTED_ORDERS = 'ORDERS_SET_IS_FETCHING_CUSTOMER_ACCEPTED_ORDERS'
export const ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS = 'ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS'
export const ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS_PAGINATION = 'ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS_PAGINATION'
export const ORDERS_SET_IS_FETCHING_CUSTOMER_ARCHIVED_ORDERS = 'ORDERS_SET_IS_FETCHING_CUSTOMER_ARCHIVED_ORDERS'
export const ORDERS_SET_IS_FETCHING_CUSTOMER_PROCESSED_ORDERS = 'ORDERS_SET_IS_FETCHING_CUSTOMER_PROCESSED_ORDERS'
export const ORDERS_SET_CUSTOMER_PROCESSED_ORDERS = 'ORDERS_SET_CUSTOMER_PROCESSED_ORDERS'
export const ORDERS_sET_CUSTOMER_PROCESSED_ORDERS_PAGINATION = 'ORDERS_sET_CUSTOMER_PROCESSED_ORDERS_PAGINATION'

export const ORDERS_SET_IS_FETCHING_DRAFT_ORDERS = 'ORDERS_SET_IS_FETCHING_DRAFT_ORDERS'
export const ORDERS_SET_DRAFT_ORDERS = 'ORDERS_SET_DRAFT_ORDERS'
export const ORDERS_SET_IS_REMOVING_DRAFT_ORDER = 'ORDERS_SET_IS_REMOVING_DRAFT_ORDER'
export const ORDERS_REMOVE_DRAFT_ORDER = 'ORDERS_REMOVE_DRAFT_ORDER'
export const ORDERS_SET_IS_CREATING_DRAFT_ORDER = 'ORDERS_SET_IS_CREATING_DRAFT_ORDER'
export const ORDERS_SET_IS_FETCHING_DRAFT_ORDER = 'ORDERS_SET_IS_FETCHING_DRAFT_ORDER'

export const ORDERS_SET_IS_CHANGING_DRAFT_STAGE = 'ORDERS_SET_IS_CHANGING_DRAFT_STAGE'
export const ORDERS_SET_PARTS_TO_ANALYZE = 'ORDERS_SET_PARTS_TO_ANALYZE'
export const ORDERS_SET_IS_CHANGING_DELIVERY_DATE = 'ORDERS_SET_IS_CHANGING_DELIVERY_DATE'
export const ORDERS_SET_IS_FETCHING_PENDING_ORDER = 'ORDERS_SET_IS_FETCHING_PENDING_ORDER'
export const ORDERS_SET_PENDING_ORDER = 'ORDERS_SET_PENDING_ORDER'
export const ORDERS_SET_IS_ADDING_NEW_PART = 'ORDERS_SET_IS_ADDING_NEW_PART'
export const ORDERS_SET_IS_QUOTING_ASSEMBLY_PRODUCTION_PRICE = 'ORDERS_SET_IS_QUOTING_ASSEMBLY_PRODUCTION_PRICE'
export const ORDERS_SET_PENDING_ORDER_PRICE = 'ORDERS_SET_PENDING_ORDER_PRICE'
export const ORDERS_SET_PENDING_ORDER_PARTS = 'ORDERS_SET_PENDING_ORDER_PARTS'
export const ORDERS_SET_IS_FETCHING_PENDING_ORDER_PARTS = 'ORDERS_SET_IS_FETCHING_PENDING_ORDER_PARTS'
export const ORDERS_SET_IS_EDITING_PENDING_ORDER = 'ORDERS_SET_IS_EDITING_PENDING_ORDER'
export const ORDERS_SET_PENDING_ORDER_DELIVERY_DATE = 'ORDERS_SET_PENDING_ORDER_DELIVERY_DATE'
export const ORDERS_SET_PENDING_ORDER_STAGE = 'ORDERS_SET_PENDING_ORDER_STAGE'
export const ORDERS_SET_IS_RESOLVING_PENDING_ORDER = 'ORDERS_SET_IS_RESOLVING_PENDING_ORDER'
export const ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_DASHBOARD_ORDERS = 'ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_DASHBOARD_ORDERS'
export const ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_DASHBOARD_ORDERS = 'ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_DASHBOARD_ORDERS'
export const ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_ORDERS = 'ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_ORDERS'
export const ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_ORDERS = 'ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_ORDERS'
export const ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_PAGINATION = 'ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_PAGINATION'
export const ORDERS_SET_IS_FETCHING_PRODUCER_AWAITING_CONFIRMATION_ORDERS = 'ORDERS_SET_IS_FETCHING_PRODUCER_AWAITING_CONFIRMATION_ORDERS'
export const ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_PAGINATION = 'ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_PAGINATION'
export const ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_ORDERS = 'ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_ORDERS'
export const ORDERS_SET_IS_REVERTING_ORDER = 'ORDERS_SET_IS_REVERTING_ORDER'
export const ORDERS_SET_IS_CHANGING_DRAFT_NAME = 'ORDERS_SET_IS_CHANGING_DRAFT_NAME'
export const ORDERS_SET_IS_FETCHING_AWAITING_CHANGES = 'ORDERS_SET_IS_FETCHING_AWAITING_CHANGES'
export const ORDERS_SET_AWAITING_CHANGES = 'ORDERS_SET_AWAITING_CHANGES'
export const ORDERS_SET_IS_FETCHING_RECENT_CHANGES = 'ORDERS_SET_IS_FETCHING_RECENT_CHANGES'
export const ORDERS_SET_RECENT_CHANGES = 'ORDERS_SET_RECENT_CHANGES'
export const ORDERS_SET_IS_GENERATING_ZIP = 'ORDERS_SET_IS_GENERATING_ZIP'

export const ORDERS_SET_ARCHIVED_BULK_ORDERS = 'ORDERS_SET_ARCHIVED_BULK_ORDERS'
export const ORDERS_SET_FETCHING_ARCHIVED_BULK_ORDERS = 'ORDERS_SET_FETCHING_ARCHIVED_BULK_ORDERS'
export const ORDERS_SET_IS_FETCHING_INTERNAL_DRAFT_ORDER = 'ORDERS_SET_IS_FETCHING_INTERNAL_DRAFT_ORDER'
export const ORDERS_SET_INTERNAL_ARCHIVED_ORDERS = 'ORDERS_SET_INTERNAL_ARCHIVED_ORDERS'
export const ORDERS_SET_IS_ARCHIVING_CALCULATION = 'ORDERS_SET_IS_ARCHIVING_CALCULATION'
export const ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION = 'ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION'
export const ORDERS_SET_ARCHIVED_CALCULATION = 'ORDERS_SET_ARCHIVED_CALCULATION'
export const ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION_CUSTOMERS = 'ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION_CUSTOMERS'
export const ORDERS_SET_ARCHIVED_CALCULATION_CUSTOMERS = 'ORDERS_SET_ARCHIVED_CALCULATION_CUSTOMERS'
export const ORDERS_SET_IS_FETCHING_PRODUCERS_FOR_ORDER = 'ORDERS_SET_IS_FETCHING_PRODUCERS_FOR_ORDER'
export const ORDER_SET_PRODUCERS_FOR_ORDER = 'ORDER_SET_PRODUCERS_FOR_ORDER'

export const ORDERS_SET_PENDING_ORDER_PART_PRODUCED_QUANTITY = 'ORDERS_SET_PENDING_ORDER_PART_PRODUCED_QUANTITY'

export const ORDERS_SET_FILES_TO_UPLOAD = 'ORDERS_SET_FILES_TO_UPLOAD'
