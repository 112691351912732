import {List} from "react-virtualized/dist/commonjs/List";
import {Card, Spin} from "antd";
import {AutoSizer} from "react-virtualized";
import React from "react";
import "./style.less";
import {formatDate} from "../../../utils/utility";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import history from "../../../utils/history";

export const ArchivedBulkOrdersList = (props) => {
  const { items, loading, searching } = props

  if (items.length === 0) {
    return (
      <React.Fragment>
        <Spin
          spinning={searching}
          wrapperClassName={"archivedCalculationsSpinWrapper"}
          indicator={
            <div align={"center"}>
              <LoadingOutlined style={{ fontSize: 50, marginLeft: -20 }} spin />
            </div>
          }
        >
          <div className={"emptyAwaiting"}>
            {
              !loading &&
              <div className={"emptyDraftsText"}>No Stored Calculations</div>
            }
          </div>
        </Spin>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <AutoSizer>
          {({height, width}) => {
            return (
              <Spin
                spinning={searching}
                style={{width}}
                indicator={
                  <div align={"center"}>
                    <LoadingOutlined style={{ fontSize: 50, marginLeft: -20 }} spin />
                  </div>
                }
              >
                <List
                  className={'archiveList'}
                  width={width}
                  height={height}
                  rowCount={items.length}
                  rowHeight={74}
                  overscanRowCount={4}
                  rowRenderer={
                    ({index, key, style}) => {
                      return (
                        <div
                          className={'archiveRow'}
                          key={key}
                          style={style}
                        >
                          <div
                            className={'archiveItem'}
                            key={index}
                          >
                            <Card
                              className={"archivedNestingOrderCard fadeInCard"}
                              bodyStyle={{height: "100%", width: "100%"}}
                              onMouseDown={(e) => {
                                if (e.button === 1) {
                                  const win = window.open(`/calculation/${items[index].id}/details`)
                                  win.focus();
                                } else if (e.button === 0) {
                                  history.push(`/calculation/${items[index].id}/details`)
                                }
                              }}
                            >
                              <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                width: "100%",
                                height: "100%"
                              }}>
                                <div style={{width: "100%"}}>
                                  <table style={{width: "100%"}}>
                                    <tbody>
                                    <tr>
                                      <td style={{width: "calc(100% - 100px)", maxWidth: 200}}>
                                        <div
                                          className={"orderName"}
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            lineHeight: 1.5,
                                            paddingRight: 10,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "100%",
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          {items[index].customName || items[index].name}
                                        </div>
                                      </td>
                                      <td style={{width: 100}}>
                                        <div style={{display: "flex", height: 24, justifyContent: "flex-end"}}>
                                          <div style={{fontSize: 13}}>
                                            <span style={{fontWeight: 500, color: "#bdbdbd"}}>{formatDate(items[index].createdAt)}</span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div style={{width: "100%"}}>
                                  <table style={{width: "100%"}}>
                                    <tbody>
                                    <tr>
                                      <td style={{width: "calc(100% - 160px)", maxWidth: 130}}>
                                        <div
                                          style={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                            paddingRight: 10,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "100%",
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          <span style={{ color: "#bdbdbd"}}>by </span>
                                          {items[index].adminName}
                                        </div>
                                      </td>
                                      <td style={{width: 160}}>
                                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                                          <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between"
                                          }}>
                                            <div style={{whiteSpace: "nowrap"}}>
                                              <span style={{fontSize: 13, color: "#bdbdbd"}}>Total Price: </span>
                                              <span style={{
                                                fontWeight: 600,
                                                fontSize: 13
                                              }}>{items[index].totalPrice.toFixed(2)} €</span>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Card>
                          </div>
                        </div>
                      )
                    }
                  }
                />
              </Spin>
            )
          }}
        </AutoSizer>
      </React.Fragment>
    )
  }
}