import React from "react";
import {Button, List, Modal, Spin, Upload} from "antd";
import {FilePdfOutlined, LoadingOutlined} from "@ant-design/icons";
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@mui/icons-material/Add";

export const OrderDocumentViewModal = (props) => {

  const {
    isVisible, onCancel, documentViewItem,
    handleRemovePartDrawing, addPartDrawing,
    uploadingDrawing,
  } = props;

  return (
    <Modal
      visible={isVisible}
      closable={true}
      destroyOnClose={true}
      maskClosable={false}
      footer={null}
      centered={true}
      width={500}
      onCancel={onCancel}
      title={<div style={{maxWidth: 400, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
        {documentViewItem?.fileName || documentViewItem?.name || "Documents"}
      </div>
      }
    >
      <List
        dataSource={documentViewItem?.documents || []}
        key={"documentId"}
        renderItem={(item)=> {
          return (
            <div className={"documentSpinContainer"} style={{ marginBottom: 10 }}>
              <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <div style={{display: "flex", width: "calc(100% - 20px)"}}>
                  <div>
                    <FilePdfOutlined style={{color: "#1890ff", marginRight: 4}}/>
                  </div>
                  <div
                    className={"addDrawingText ellipsisText"}
                    style={{color: "#1890ff", marginTop: 4, cursor: "default"}}
                  >
                    {item.drawingPDFFileName}
                  </div>
                </div>
                <div style={{marginTop: 0, justifyContent: "flex-end", alignItems: "center"}}>
                  <IconButton
                    size={'small'}
                    aria-label="clear"
                    style={{width: 18, height: 18, marginRight: -3, marginTop: -1}}
                    onClick={()=> {
                      handleRemovePartDrawing({
                        orderPartId: documentViewItem.id,
                        partType: documentViewItem.partType,
                        documentId: item.documentId
                      })
                    }}
                  >
                    <CloseIcon style={{ fontSize: 14, color: '#fc505a'}}/>
                  </IconButton>
                </div>
              </div>
            </div>
          )
        }}
      />
      <Spin
        spinning={uploadingDrawing}
        wrapperClassName={"documentUploadSpinButton"}
        indicator={<LoadingOutlined style={{ fontSize: 32, marginTop: -16, marginLeft: -17 }} spin />}
      >
        <Upload.Dragger
          showUploadList={false}
          accept=".pdf"
          multiple
          beforeUpload={(file) => {
            addPartDrawing({
              orderPartId: documentViewItem.id,
              partType: documentViewItem.partType,
              file: file
            })
            return false
          }}
          style={{width: "100%"}}
          className={"documentDragger"}
        >
          <Button
            className={"addMoreDocumentsButton"}
            style={{width: "100%", height: 120, borderRadius: 5}}
            type="dashed"
            ghost
          >
            <div style={{fontSize: 15, fontWeight: 600}}>Add Additional Documents</div>
            <AddIcon style={{fontSize: 20, marginLeft: -6, minWidth: 14, minHeight: 14, marginTop: 5}}/>
          </Button>
        </Upload.Dragger>
      </Spin>
    </Modal>
  )
}