import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Row, Col, Card, Button, Form, Input, Spin} from 'antd';
import logo from '../../assets/vectors/step2real_logo_black.svg';
import {LinkWrapper, Logo} from './styles';
import {loginUser} from '../../actions/authActions';
import {Link} from '../../components';
import {LoadingOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import history from "../../utils/history";
import "./style.css";
import "./styleLess.less";
import {useWindowSize} from "../../utils/utility";

export const LoginPage = (props) => {
  const redirect = useSelector(state => state.auth.redirect);
  const loggingIn = useSelector(state => state.auth.loggingIn);

  const windowSize = useWindowSize();

  const dispatch = useDispatch();

  const handleClick = (values) => {
    dispatch(loginUser({
      form: {
        email: values.email,
        password: values.password
      },
      redirectTo: redirect}))
  }

  return (
    <React.Fragment>
      <div className={"publicWrapper"}>
        <div className={windowSize.width > 800 ? "tiledBackgroundWide" : "tiledBackgroundNarrow"}>
          <Card className={"publicCard"}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center",  height: 200}}>
              <Logo src={logo} alt={"Step2Real"}/>
            </div>
            <Form
              name={'login'}
              onFinish={handleClick}
            >
              <span className={"loginLabel"}>Email</span>
              <Form.Item
                name={'email'}
                rules={[
                  {required: true, message: 'This field is required'},
                  {type: 'email', message: 'Please enter a valid email address'},
                ]}
                validateTrigger={'onSubmit'}
                className={"formItem"}
              >
                <Input
                  placeholder="Enter your email address"
                  className={"loginInputText"}
                  prefix={<UserOutlined/>}
                  autoComplete={'email'}
                />
              </Form.Item>
              <span className={"loginLabel"}>Password</span>
              <Form.Item
                name={'password'}
                rules={[
                  {required: true, message: 'This field is required'},
                  {type: 'string', min: 6, message: 'Password must be 6 characters or longer'},
                  {type: 'string', max: 60, message: 'Password must be 60 characters or shorter'},
                ]}
                validateTrigger={'onSubmit'}
              >
                <Input.Password
                  className={"loginInputText"}
                  prefix={<LockOutlined/>}
                  placeholder="Enter your password"
                  autoComplete={'current-password'}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className={"blackButton"}
                  style={{width: '100%', marginTop: 5}}
                  loading={loggingIn}
                  htmlType="submit"
                >
                  <span>Sign in</span>
                </Button>
              </Form.Item>
            </Form>
            <Spin
              spinning={false}
              indicator={
                <div align={"center"}>
                  <LoadingOutlined style={{ fontSize: 28, marginLeft: -14 }} spin />
                </div>
              }
            >
              <LinkWrapper>
                <Link to="/register" history={history}>
                  Don't have an account? Sign up!
                </Link>
              </LinkWrapper>
            </Spin>
            <Row>
              <Col span={12}>
                <LinkWrapper style={{marginTop: 20}}>
                  <Link to="/forgot-password" history={history}>
                    Forgot Password?
                  </Link>
                </LinkWrapper>
              </Col>
              <Col span={12}>
                <LinkWrapper style={{marginTop: 20}}>
                  <Link to="/resend-email" history={history}>
                    Resend Verification?
                  </Link>
                </LinkWrapper>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}