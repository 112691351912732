import {
  SUB_ADMINS_IS_ADDING_SUB_ADMIN,
  SUB_ADMINS_IS_EDITING_SUB_ADMINS,
  SUB_ADMINS_IS_FETCHING_SUB_ADMINS,
  SUB_ADMINS_IS_FETCHING_SUB_ADMINS_LIMIT,
  SUB_ADMINS_SET_ADMIN_PERMISSIONS,
  SUB_ADMINS_SET_CURRENT_SUB_ADMINS,
  SUB_ADMINS_SET_SUB_ADMINS,
  SUB_ADMINS_SET_SUB_ADMINS_PAGINATION
} from '../actions/actionTypes'
import initialState from './initialState'

const subAdminReducer = (state = initialState.subAdmins, action) => {
  switch (action.type) {

    case SUB_ADMINS_IS_ADDING_SUB_ADMIN:
      return {
        ...state,
        isAddingSubAdmin: action.payload
      }

    case SUB_ADMINS_IS_FETCHING_SUB_ADMINS:
      return {
        ...state,
        isFetchingSubAdmins: action.payload
      }

    case SUB_ADMINS_SET_SUB_ADMINS:
      return {
        ...state,
        subAdmins: action.payload
      }

    case SUB_ADMINS_SET_SUB_ADMINS_PAGINATION:
      return {
        ...state,
        subAdminsPagination: action.payload
      }

    case SUB_ADMINS_SET_CURRENT_SUB_ADMINS:
      return {
        ...state,
        currentSubAdmins: action.payload
      }

    case SUB_ADMINS_IS_FETCHING_SUB_ADMINS_LIMIT:
      return {
        ...state,
        isFetchingSubAdminsLimit: action.payload
      }

    case SUB_ADMINS_SET_ADMIN_PERMISSIONS:
      return {
        ...state,
        subAdmins: state.subAdmins.map(item => {
          if (item.id === action.payload.subAdminId) {
            return {...item, permissions: action.payload.data}
          }
          return item
        })
      }

    case SUB_ADMINS_IS_EDITING_SUB_ADMINS:
      return {
        ...state,
        isEditingSubAdmin: action.payload
      }

    default:
      return state
  }
}

export default subAdminReducer