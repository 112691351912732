import React, {useState} from 'react'
import { Card, Button, Form, Input} from 'antd'
import logo from '../../assets/vectors/step2real_logo_black.svg'
import { LinkWrapper, CardTitle, Logo } from '../LoginPage/styles'
import { Link } from '../../components'
import {http, message} from "../../utils";
import {API_BASE_URL} from "../../constants";
import {UserOutlined} from "@ant-design/icons";
import {useWindowSize} from "../../utils/utility";
import history from "../../utils/history";

export const ForgotPassword = (props) => {

  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const windowSize = useWindowSize();

  const onFinish = (values) => {
    setIsSendingEmail(true);
    http
      .post(`${API_BASE_URL}/auth/forgot-password-email`, values)
      .then(()=>{
        setIsSendingEmail(false);
        message.show(message.type.success, 'A password reset email has been sent.')
        history.push('/login')
      })
      .catch(error=>{
        setIsSendingEmail(false);
        message.show(message.type.error, http.getErrorMessage(error))
      })
  }

  return(
    <div className={"publicWrapper"}>
      <div className={windowSize.width > 800 ? "tiledBackgroundWide" : "tiledBackgroundNarrow"}>
        <Card className={"publicCard"}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center",  height: 200}}>
            <Logo src={logo} alt={"Step2Real"}/>
          </div>
          <CardTitle className={"resendText"}>Send Password Reset Email</CardTitle>
          <Form
            name={'forgot-password'}
            onFinish={onFinish}
          >
            <span className={"loginLabel"}>Email</span>
            <Form.Item
              name={'email'}
              rules={[
                {required: true, message: 'This field is required'},
                {type: 'email', message: 'Please enter a valid email address'},
              ]}
              validateTrigger={'onSubmit'}
            >
              <Input
                className={"loginInputText"}
                placeholder="Enter your email address"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: '100%', marginTop: 15}}
                loading={isSendingEmail}
                type="primary"
                htmlType="submit"
                className={"blackButton"}
              >
                Send Password Reset Email
              </Button>
            </Form.Item>
          </Form>
          <LinkWrapper>
            <Link to="/login" history={history}>
              Remembered your password? Sign in!
            </Link>
          </LinkWrapper>
        </Card>
      </div>
    </div>
  )
}
