import initialState from './initialState'
import {
  TUBE_CUTTING_MACHINES_IS_CREATING_TUBE_CUTTING_MACHINE,
  TUBE_CUTTING_MACHINES_IS_EDITING_TUBE_CUTTING_MACHINE,
  TUBE_CUTTING_MACHINES_IS_FETCHING_MACHINE_PROPERTIES,
  TUBE_CUTTING_MACHINES_IS_FETCHING_TUBE_CUTTING_DATA,
  TUBE_CUTTING_MACHINES_SET_MACHINE_DATA,
  TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINE_PARAMETER,
  TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINES,
  TUBE_CUTTING_SET_TUBE_CUTTING_SERVICE,
  TUBE_CUTTING_FETCHING_MATERIAL_PRICES,
  TUBE_CUTTING_SET_MATERIAL_PRICES,
  TUBE_CUTTING_UPDATE_MATERIAL_PRICE,
  TUBE_CUTTING_SET_CUTTING_SPEED,
  TUBE_CUTTING_FETCHING_STANDARD_MATERIAL_PRICES,
  TUBE_CUTTING_SET_STANDARD_MATERIAL_PRICES,
  TUBE_CUTTING_UPDATE_STANDARD_MATERIAL_PRICE,
  TUBE_CUTTING_SET_MACHINE_TOLERANCE
} from "../actions/actionTypes";

const tubeCuttingReducer = (state = initialState.tubeCutting, action) => {
  switch (action.type) {
    case TUBE_CUTTING_FETCHING_MATERIAL_PRICES:
      return {
        ...state,
        isFetchingTubeMaterialPrices: action.payload,
      }

    case TUBE_CUTTING_SET_MATERIAL_PRICES:
      return {
        ...state,
        hasTubeCutting: action.payload.hasTubeCutting,
        materials: {
           availableMaterials: action.payload.availableMaterials,
           allAvailable: action.payload.allAvailable
        }
      }

    case TUBE_CUTTING_UPDATE_MATERIAL_PRICE: {
      let tmp = [...state.materials.availableMaterials]
      tmp[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        materials: {...state.materials, availableMaterials: tmp},
      }
    }

    case TUBE_CUTTING_FETCHING_STANDARD_MATERIAL_PRICES:
      return {
        ...state,
        isFetchingStandardTubeMaterialPrices: action.payload,
      }

    case TUBE_CUTTING_SET_STANDARD_MATERIAL_PRICES:
      return {
        ...state,
        hasTubeCutting: action.payload.hasTubeCutting,
        standardTubeMaterialPrices: action.payload.standardTubeMaterialPrices
      }

    case TUBE_CUTTING_UPDATE_STANDARD_MATERIAL_PRICE: {
      let tmp = [...state.standardTubeMaterialPrices]
      if(action.payload.type === 'circle') {
        tmp[action.payload.tab].grades[action.payload.grade].rows[action.payload.row][action.payload.col] = action.payload.value
      } else {
        tmp[action.payload.tab].grades[action.payload.grade].rows[action.payload.row][action.payload.col][action.payload.elem].price = action.payload.value
      }
      return {
        ...state,
        standardTubeMaterialPrices: [...tmp],
      }
    }

    case TUBE_CUTTING_SET_TUBE_CUTTING_SERVICE:
      return {
        ...state,
        hasTubeCutting: action.payload
      }
    case TUBE_CUTTING_MACHINES_IS_FETCHING_TUBE_CUTTING_DATA:
      return {
        ...state,
        isFetchingTubeCuttingData: action.payload
      }

    case TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINES:
      return {
        ...state,
        tubeCuttingMachines: action.payload
      }

    case TUBE_CUTTING_MACHINES_IS_CREATING_TUBE_CUTTING_MACHINE:
      return {
        ...state,
        isCreatingCuttingMachine: action.payload
      }

    case TUBE_CUTTING_MACHINES_IS_FETCHING_MACHINE_PROPERTIES:
      return {
        ...state,
        isFetchingSelectedMachine: action.payload,
      }

    case TUBE_CUTTING_MACHINES_IS_EDITING_TUBE_CUTTING_MACHINE:
      return {
        ...state,
        isEditingMachine: action.payload
      }

    case TUBE_CUTTING_MACHINES_SET_MACHINE_DATA:
      return {
        ...state,
        selectedMachine: action.payload
      }

    case TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINE_PARAMETER:
      return {
        ...state,
        selectedMachine: { ...state.selectedMachine, ...action.payload }
      }

    case TUBE_CUTTING_SET_CUTTING_SPEED:
      let tmp = [...state.selectedMachine.tables]
      tmp[action.payload.tab].rows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        selectedMachine: {...state.selectedMachine, tables: tmp},
      }

    case TUBE_CUTTING_SET_MACHINE_TOLERANCE:
      return {
        ...state,
        selectedMachine: {
          ...state.selectedMachine,
          markup: {
            tolerance: {
              ...state.selectedMachine.markup.tolerance,
              [action.payload.toleranceClass]: action.payload.toleranceMarkup
            }
          }
        }
      }

    default:
      return state
  }
}
export default tubeCuttingReducer
