import React from "react";
import { Button, Card, Checkbox, Divider, Tooltip } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import LazyLoad from "react-lazyload";
import { AssemblyOrderItem } from "../../../AssemblyOrderItem";
import './style.less'

export const AssemblyItemCard = (props) => {
  const {
    orderId, item, selectedItems, onCheck, handleRemoveItem,
    sheetMetalMaterials, machiningMaterials, tubeCuttingMaterials,
    changeSheetMetalMaterial, changePartThickness,
    changePartTolerance, changePartRoughness, changeManualPartTolerance,
    changeManualPartRoughness,
    changePartQuantity, changePartNote, changePartColor, handleColorRemove,
    handleChangeItemType, showItem, changePartStandard, changePartNumberOfUniqueParts,
    bulkItem, changePrice, openDocumentsView, partiallySelectedAssemblies,
    handleSelectAssembly, countAssemblyParts,
    handleRemoveEntireAssembly, expanded, setExpanded, isOrderDetails,
    adjustment, handleOpenPricesModal, assemblyProgressMap,
    changeAssemblyQuantity, changeMachiningMaterial, changeTubeCuttingMaterial,
    changeAssemblyWeldingPrice, changeAssemblyAssemblingPrice,
    changeAssemblyPackagingPrice, changeAssemblyTransportationPrice,
    changeMaterialCertificate
  } = props;

  return (
    <Card className={"assembly-card"}>
      <div className={"assembly-card-head"}>
        <div className={"part-card-head-left-side"}>
          <span
            className={"assembly-card-header-left"}
            onClick={event => {
              event.stopPropagation();
              let checked = !(
                partiallySelectedAssemblies[item.id] &&
                partiallySelectedAssemblies[item.id].selected === partiallySelectedAssemblies[item.id].total
              )
              handleSelectAssembly({assembly: item, checked});
            }}
            style={{paddingTop: 4, paddingBottom: 4, display: "flex"}}
          >
            <Checkbox
              className={"assembly-card-head-checkbox"}
              style={{marginTop: -2}}
              onClick={event => {
                event.stopPropagation();
                let checked = event.target.checked
                handleSelectAssembly({assembly: item, checked});
              }}
              disabled={!item.isAnalysed || countAssemblyParts(item.id) === 0}
              indeterminate={
                partiallySelectedAssemblies[item.id]?.selected > 0 &&
                (partiallySelectedAssemblies[item.id]?.selected < partiallySelectedAssemblies[item.id]?.total)
              }
              checked={
                partiallySelectedAssemblies[item.id] &&
                partiallySelectedAssemblies[item.id]?.selected === partiallySelectedAssemblies[item.id]?.total
              }
            />
          </span>
          <div
            style={{
              overflow: "hidden",
              display: "inline-flex",
              alignItems: "center",
              marginTop: 1,
              cursor: "default"
            }}
          >
            <Tooltip
              title={<span style={{ color: "black", fontSize: 13, fontWeight: 600}}>{item.fileName}</span>}
              placement={"topRight"}
              color={"#d6d6d6"}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 12,
                  lineHeight: 1.5,
                  paddingLeft: 12,
                  paddingRight: 10,
                  color: "black",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                {item.fileName}
              </div>
            </Tooltip>
            <span
              className={"text12-400"}
              style={{
                color: "black",
                lineHeight: 1.5
              }}
            >
              {
                item.stepData?.partData?.boundingBox?.x + "×" +
                item.stepData?.partData?.boundingBox?.y + "×" +
                item.stepData?.partData?.boundingBox?.z + "mm"
              }
            </span>
          </div>

        </div>
        <div className={"part-card-head-right-side"}>
          <div className={"assemblyCardHeaderSection"}>
            <div>
              {bulkItem ?
                <div style={{ display: "inline-block"}}>
                  Parts - Unique: {item.numberOfUniqueParts}
                  <Divider type="vertical" style={{height: "16px", marginInline: 8, borderColor: "#14181a"}}/>
                  Automatic: {item.numberOfAutomaticParts}
                  <Divider type="vertical" style={{height: "16px", marginInline: 8, borderColor: "#14181a"}}/>
                  Manual: {item.numberOfManualParts}
                </div>
                :
                <div style={{ display: "inline-block"}}>
                  Unique Parts: {item.numberOfUniqueParts}
                </div>
              }
            </div>

          </div>
          <Tooltip
            overlayClassName={"delete-tooltip"}
            color={"rgba(82,82,82,0.9)"}
            title={<span className={"text12-500"} style={{color: "white"}}>Delete</span>}
          >
            <Button
              type="circle"
              disabled={!item.isAnalysed} // TODO color and tooltip
              style={{boxShadow: "5px 0 16px 0 rgba(101,101,101,.1)", marginLeft: 15}}
              onClick={event => {
                event.stopPropagation();
                handleRemoveEntireAssembly({orderId: item.orderId, assemblyId: item.id})
              }}
            >
              <DeleteFilled style={{color: item.isAnalysed ? "#ff5b2d" : "#acacac"}}/>
            </Button>
          </Tooltip>
        </div>
      </div>

      <LazyLoad
        resize={true}
        offset={600}
        once
      >
        <div className={"assembly-card-body"}>
          <div style={{ padding: "10px 6px", margin: "0 4px"}}>
            <AssemblyOrderItem
              orderId={orderId}
              assemblyId={item.id}
              item={item}
              expanded={expanded}
              setExpanded={setExpanded}
              sheetMetalMaterials={sheetMetalMaterials}
              machiningMaterials={machiningMaterials}
              tubeCuttingMaterials={tubeCuttingMaterials}
              selectedItems={selectedItems}
              onCheck={onCheck}
              changeSheetMetalMaterial={changeSheetMetalMaterial}
              changeMachiningMaterial={changeMachiningMaterial}
              changeTubeCuttingMaterial={changeTubeCuttingMaterial}
              changePartThickness={changePartThickness}
              changePartQuantity={changePartQuantity}
              changePartNote={changePartNote}
              changePartColor={changePartColor}
              changePartTolerance={changePartTolerance}
              changePartRoughness={changePartRoughness}
              changeManualPartTolerance={changeManualPartTolerance}
              changeManualPartRoughness={changeManualPartRoughness}
              changeMaterialCertificate={changeMaterialCertificate}
              changePartStandard={changePartStandard}
              changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
              handleColorRemove={handleColorRemove}
              showItem={showItem}
              isOrderDetails={isOrderDetails}
              bulkItem={bulkItem}
              changePrice={changePrice}
              adjustment={adjustment}
              openDocumentsView={openDocumentsView}
              handleOpenPricesModal={handleOpenPricesModal}
              handleRemoveItem={handleRemoveItem}
              handleChangeItemType={handleChangeItemType}
              assemblyProgressMap={assemblyProgressMap}
              changeAssemblyQuantity={changeAssemblyQuantity}
              changeAssemblyWeldingPrice={changeAssemblyWeldingPrice}
              changeAssemblyAssemblingPrice={changeAssemblyAssemblingPrice}
              changeAssemblyPackagingPrice={changeAssemblyPackagingPrice}
              changeAssemblyTransportationPrice={changeAssemblyTransportationPrice}
            />
          </div>
        </div>
      </LazyLoad>
    </Card>
  )
}