import React, { createRef, useEffect, useState } from "react";
import {Col, Form, Row} from "antd";
import "./style.less";
import { PartInputArea } from "./PartInputArea";
import { PartImageArea } from "./PartImageArea";
import { PartFeaturesArea } from "./PartFeaturesArea";

export const PartItemBody = (props) => {
  const form = createRef()

  const {
    item, sheetMetalMaterials, machiningMaterials, tubeCuttingMaterials,
    changeSheetMetalMaterial, changePartQuantity, changePartThickness,
    changePartNote, changePartColor, handleColorRemove,
    changePartTolerance, changePartRoughness,
    changeManualPartTolerance, changeManualPartRoughness, showItem,
    isOrderDetails, bulkItem, changePrice, adjustment,
    handleOpenPricesModal, openDocumentsView,
    assemblyCount, ptAutomatic, orderId, changePartStandard,
    changeMachiningMaterial, changeTubeCuttingMaterial, changePartNumberOfUniqueParts, assemblyName,
    changeMaterialCertificate
  } = props

  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(()=>{
    form.current.setFieldsValue({
      quantity: item.quantity
    })
  }, [item.quantity])

  useEffect(()=>{
    form.current.setFieldsValue({
      materialCertificate: item.certNeeded
    })
  }, [item.certNeeded])

  useEffect(()=>{
    if(item.standard !== undefined) {
      form.current.setFieldsValue({
        standard: item.standard
      })
    }
  }, [item.standard])

  useEffect(()=>{
    if(item.numberOfUniqueParts !== undefined){
      form.current.setFieldsValue({
        numberOfUniqueParts: item.numberOfUniqueParts
      })
    }
  }, [item.numberOfUniqueParts])

  useEffect(()=> {
    if(item.selectedMaterial) {
      if (item.partType === "sheetMetal") {
        form.current.setFieldsValue({
          material: item.selectedMaterial.grade
        })
      } else {
        form.current.setFieldsValue({
          material: item.selectedMaterial.isAvailable === false ? item.selectedMaterial.grade : item.selectedMaterial.materialId
        })
      }
    }
  }, [item.selectedMaterial])

  useEffect(()=>{
    if (bulkItem) {
      form.current.setFieldsValue({
        price: (item.pricePerPiece || 0).toFixed(2)
      })
    }
  }, [item.pricePerPiece])

  return (
    <Form
      ref={form}
      name={"form"+item.id}
      style={{height: "100%"}}
    >
      <Row style={{height: "100%"}}>
        <Col flex={"192px"} style={{borderRight: "1px dotted #838f94", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
          <PartImageArea
            item={item}
            showItem={showItem}
            assemblyName={assemblyName}
          />
        </Col>
        <Col flex="1 1 29%" style={{borderRight: window.innerWidth > 920  ? "1px dotted #838f94" : "none"}}>
          <PartInputArea
            item={item}
            orderId={orderId}
            changeSheetMetalMaterial={changeSheetMetalMaterial}
            changeMachiningMaterial={changeMachiningMaterial}
            changeTubeCuttingMaterial={changeTubeCuttingMaterial}
            ptAutomatic={ptAutomatic}
            sheetMetalMaterials={sheetMetalMaterials}
            machiningMaterials={machiningMaterials}
            tubeCuttingMaterials={tubeCuttingMaterials}
            changePartThickness={changePartThickness}
            changePartQuantity={changePartQuantity}
            changePartNote={changePartNote}
            changePartStandard={changePartStandard}
            changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
            changeMaterialCertificate={changeMaterialCertificate}
          />
        </Col>
        <Col flex="1 1 42%">
          <PartFeaturesArea
            item={item}
            changePartColor={changePartColor}
            changePartTolerance={changePartTolerance}
            changePartRoughness={changePartRoughness}
            changeManualPartTolerance={changeManualPartTolerance}
            changeManualPartRoughness={changeManualPartRoughness}
            ptAutomatic={ptAutomatic}
            isOrderDetails={isOrderDetails}
            handleColorRemove={handleColorRemove}
            openDocumentsView={openDocumentsView}
            bulkItem={bulkItem}
            handleOpenPricesModal={handleOpenPricesModal}
            assemblyCount={assemblyCount}
            changePrice={changePrice}
            adjustment={adjustment}
            selectedProfile={selectedProfile}
            orderId={orderId}
            form={form}
          />
        </Col>
      </Row>
    </Form>
  )
};