import React from "react";
import {Card, Divider, Modal} from "antd";
import {Trans} from "react-i18next";
import {
  canHaveCertificate,
  canHaveCoating,
  canHaveStandard,
  getManualType,
  getRoughnessValue,
  getToleranceValue
} from "../../../../../utils/utility";
import {CoatingSection} from "../../../../ItemView/CoatingSection";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";

export const OrderViewOtherModal = (props) => {

  const {
    item,
    viewOtherVisible,
    closeOtherView,
    showMaterialGroup,
    showPrice
  } = props;

  const standardFieldLabel = (itemType) => {
    if (itemType === "tubeCut" || itemType === "profile") {
      return "Standard: "
    } else {
      return "OEM: "
    }
  }

  return (
    <Modal
      visible={viewOtherVisible}
      centered={true}
      onCancel={closeOtherView}
      title={"Part Details"}
      footer={null}
      width={600}
    >
      {item &&
        <div style={{width: "100%"}}>
          <Card
            className={"blackModalCard"}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 5,
              border: "1px solid rgb(215, 215, 215)",
              boxShadow: "rgba(0, 0, 0, 0.07) 0 2px 4px 1px"
            }}
          >
            <div>
              <div style={{fontSize: 20, fontWeight: 600, marginBottom: 10}}>{item.fileName || item.name}</div>
              {item.quoteType === "quoted" ?
                <div>
                  <div className={"orderItemDetailsLabel"}>Part Type:</div>
                  <div className={"orderItemDetailsValue"}>{getManualType(item.partType)}</div>
                </div>
                :
                <div>
                  <div className={"orderItemDetailsLabel"}>Proposed Type:</div>
                  <div className={"orderItemDetailsValue"}>{getManualType(item.partType)}</div>
                </div>
              }

              <Divider className={"lightDivider"} style={{ margin: "8px 0 10px 0" }}/>
              {item.partType === "sheetMetal" &&
              <div>
                {showMaterialGroup &&
                  <div>
                    <div className={"orderItemDetailsLabel"}>Material Group:</div>
                    <div className={"orderItemDetailsValue"}>{item.selectedMaterial?.groupName}</div>
                  </div>
                }
                <div className={"orderItemDetailsLabel"}>Material Grade:</div>
                <div className={"orderItemDetailsValue"}><Trans>{item.selectedMaterial?.grade}</Trans></div>
                <div className={"orderItemDetailsLabel"}>Thickness:</div>
                <div className={"orderItemDetailsValue"}>{item.selectedMaterial?.thickness}mm</div>
              </div>
              }

              {item.certNeeded && canHaveCertificate(item.partType) &&
              <div>
                <div
                  className={"orderItemDetailsLabel"}
                  style={{display: 'flex', alignItems: 'flex-end'}}
                >
                  <TaskOutlinedIcon className={"materialCertificateIcon"} style={{ fontSize: 21 }}/>
                  3.1 Material Certificate Requested
                </div>
                <div className={"orderItemDetailsValue"}/>
              </div>
              }

              {(item.partType === "milled" || item.partType === "lathed")  &&
              <div>
                {showMaterialGroup &&
                  <div>
                    <div className={"orderItemDetailsLabel"}>Material Group:</div>
                    <div className={"orderItemDetailsValue"}>{item.selectedMaterial.groupName}</div>
                  </div>
                }
                <div className={"orderItemDetailsLabel"}>Material Grade:</div>
                <div className={"orderItemDetailsValue"}><Trans>{item.selectedMaterial?.grade || item.materialGrade}</Trans></div>
              </div>
              }
              {canHaveStandard(item.partType) && item.standard &&
              <div>
                <div className={"orderItemDetailsLabel"}>{standardFieldLabel(item.partType)}</div>
                <div className={"orderItemDetailsValue"}>{item.standard}</div>
              </div>
              }
              {item.manualTolerance &&
              <div>
                <div className={"orderItemDetailsLabel"}>Max Tolerance:</div>
                <div className={"orderItemDetailsValue"}>{getToleranceValue(item.manualTolerance,
                  (item.partType === 'milled' || item.partType === 'lathed') ? "machined" : "other")
                }</div>
              </div>
              }
              {item.manualRoughness &&
              <div>
                <div className={"orderItemDetailsLabel"}>Max Roughness:</div>
                <div className={"orderItemDetailsValue"}>{getRoughnessValue(item.manualRoughness)}</div>
              </div>
              }
              <div className={"orderItemDetailsLabel"}>Quantity:</div>
              <div className={"orderItemDetailsValue"}>{item.quantity}</div>

              {canHaveCoating(item) || item.note &&
                <Divider className={"lightDivider"} style={{ margin: "8px 0 10px 0" }}/>
              }

              {canHaveCoating(item) &&
                <CoatingSection item={item}/>
              }
              {item.note &&
                <div>
                  <div className={"orderItemDetailsLabel"}>Note:</div>
                  <div className={"orderItemDetailsValue"}>{item.note}</div>
                </div>
              }
              {showPrice &&
                <div>
                  <Divider className={"lightDivider"} style={{ margin: "8px 0 10px 0" }}/>
                  {item.quoteType === "quoted" &&
                    <div>
                      {item.partType === "assembly" &&
                        <div>
                          <div className={"orderItemDetailsLabel"}>Assembling Price Breakdown 1pc:</div>
                          <div className={"assemblyPriceBreakdownBox"}>
                            <div style={{ marginRight: 10 }}>
                              <div className={"orderItemDetailsLabel"}>Welding:</div>
                              <div className={"orderItemDetailsValue"}>€ {(parseFloat(item.assemblyPrice?.weldingPrice) || 0).toFixed(2)}</div>

                              <div className={"orderItemDetailsLabel"}>Assembling:</div>
                              <div className={"orderItemDetailsValue"}>€ {(parseFloat(item.assemblyPrice?.assemblingPrice) || 0).toFixed(2)}</div>
                            </div>

                            <div>
                              <div className={"orderItemDetailsLabel"}>Machine Processing:</div>
                              <div className={"orderItemDetailsValue"}>€ {(parseFloat(item.assemblyPrice?.packagingPrice) || 0).toFixed(2)}</div>

                              <div className={"orderItemDetailsLabel"}>Others:</div>
                              <div className={"orderItemDetailsValue"}>€ {(parseFloat(item.assemblyPrice?.transportationPrice) || 0).toFixed(2)}</div>
                            </div>
                          </div>
                        </div>
                      }
                      <div style={{display: "flex"}}>
                        <div style={{marginRight: 30}}>
                          <div className={"orderItemDetailsLabel"}>Price 1pc:</div>
                          <div className={"orderItemDetailsValue"}>€ {(parseFloat(item.pricePerPiece) || 0).toFixed(2)}</div>
                        </div>
                        <div>
                          <div className={"orderItemDetailsLabel"}>Total Price:</div>
                          <div className={"orderItemDetailsValue"}>€ {(parseFloat(item.totalPrice) || 0).toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  }
                  {item.quoteType === "unquoted" &&
                    <div>
                      {item.partType === "assembly" &&
                        <div>
                          <div className={"orderItemDetailsLabel"}>Assembling Price Breakdown 1pc:</div>
                          <div className={"orderItemDetailsValue"}>RFQ</div>
                        </div>
                      }

                      <div style={{display: "flex"}}>
                        <div style={{marginRight: 30}}>
                          <div className={"orderItemDetailsLabel"}>Price:</div>
                          <div className={"orderItemDetailsValue"}>RFQ</div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </Card>
        </div>
      }
    </Modal>
  )
}