import initialState from './initialState'
import {
  PRODUCERS_PRODUCERS,
  PRODUCERS_FETCHING_PRODUCERS,
  PRODUCERS_FETCHING_PRODUCER_DETAILS,
  PRODUCERS_UPDATE_PRODUCER_BLOCKED,
  PRODUCERS_SET_IS_CREATING_PRODUCER,
  PRODUCERS_SET_IS_UPDATING_PRODUCER_INFO, PRODUCERS_SET_IS_UPDATING_PRODUCER_LOGO, PRODUCERS_SET_PRODUCER_LOGO
} from "../actions/actionTypes";

const producersReducer = (state = initialState.producers, action) => {
  switch (action.type) {
    case PRODUCERS_PRODUCERS:
      return {
        ...state,
        producers: action.payload,
      }

    case PRODUCERS_FETCHING_PRODUCERS:
      return {
        ...state,
        fetchingProducers: action.payload,
      }

    case PRODUCERS_SET_IS_CREATING_PRODUCER:
      return {
        ...state,
        isCreatingProducer: action.payload,
      }

    case PRODUCERS_FETCHING_PRODUCER_DETAILS:
      return {
        ...state,
        fetchingProducerDetails: action.payload
      }

    case PRODUCERS_UPDATE_PRODUCER_BLOCKED:
      const itemIndex = state.producers.findIndex((item => item.id === action.payload));
      const updatedProducers = [...state.producers];
      updatedProducers[itemIndex].isBlocked = !state.producers[itemIndex].isBlocked
      return {
        ...state,
        producers: [...updatedProducers]
      }

    case PRODUCERS_SET_IS_UPDATING_PRODUCER_INFO:
      return {
        ...state,
        isUpdatingProducerInfo: action.payload
      }

    case PRODUCERS_SET_IS_UPDATING_PRODUCER_LOGO:
      return {
        ...state,
        isUpdatingProducerLogo: action.payload
      }

    case PRODUCERS_SET_PRODUCER_LOGO:
      const updatedProducersLogo = state.producers.map(item => {
        if (item.id === action.payload.producerId) {
          return {
            ...item,
            logo: action.payload.logo
          }
        }
        return item;
      })

      return {
        ...state,
        producers: [...updatedProducersLogo]
      }

    default:
      return state
  }
}

export default producersReducer
