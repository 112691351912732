import {
  MATERIALS_FETCHING_MATERIAL_CERTIFICATE_PRICES,
  MATERIALS_FETCHING_MATERIAL_PRICES, MATERIALS_SET_MATERIAL_CERTIFICATE_PRICES,
  MATERIALS_SET_MATERIAL_PRICES,
  MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE,
  MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE,
  MATERIALS_UPDATE_MATERIAL_PRICE
} from './actionTypes'

import { API_BASE_URL } from '../constants'
import { http, message } from '../utils'
import {forceLogoutUser} from "./authActions";


const fetchMaterialPrices = () => dispatch => {
  dispatch({
    type: MATERIALS_FETCHING_MATERIAL_PRICES,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/params/producer-materials/prices`)
    .then(response => {
      let data = response.data.groupNames.map( name => {
        let item = response.data.prices.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })

      dispatch({
        type: MATERIALS_SET_MATERIAL_PRICES,
        payload: [...data],
      })

      dispatch({
        type: MATERIALS_FETCHING_MATERIAL_PRICES,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MATERIALS_FETCHING_MATERIAL_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}


const updateMaterialPrice = payload => dispatch => {
  dispatch({
    type: MATERIALS_UPDATE_MATERIAL_PRICE,
    payload: payload,
  })
}

const fetchMaterialCertificatePrices = () => dispatch => {
  dispatch({
    type: MATERIALS_FETCHING_MATERIAL_CERTIFICATE_PRICES,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/params/material-cert-fees`)
    .then(response => {
      dispatch({
        type: MATERIALS_SET_MATERIAL_CERTIFICATE_PRICES,
        payload: response.data,
      })

      dispatch({
        type: MATERIALS_FETCHING_MATERIAL_CERTIFICATE_PRICES,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: MATERIALS_FETCHING_MATERIAL_CERTIFICATE_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const updateBaseMaterialCertificatePrice = payload => dispatch => {
  dispatch({
    type: MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE,
    payload: payload,
  })
}

const updateAssemblyMaterialCertificatePrice = payload => dispatch => {
  dispatch({
    type: MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE,
    payload: payload,
  })
}


export {
  fetchMaterialPrices,
  updateMaterialPrice,
  fetchMaterialCertificatePrices,
  updateBaseMaterialCertificatePrice,
  updateAssemblyMaterialCertificatePrice
}
