import React from "react";

import "../tableStyle.less";

export const EmptyTable = (props) => {
  return (
    <div className={"emptyTable"} style={{height: props.height || 248}}>
      <div className={"emptyTableHeader"} />
      <div className={"emptyTableBody"}>
        <div className={"emptyTableText"}>{props.text || "No data available."}</div>
      </div>
    </div>
  )
}