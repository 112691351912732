import React, { useEffect, useState } from "react";
import Sticky from "react-sticky-el";
import { Card, Divider } from "antd";
import { OrderExcelExportButton } from "../../../OrderExcelExportButton";

export const ProducerOrderSticky = (props) => {

  const {
    items, numOfItems, totalPrice, orderName
  } = props;

  return (
    <div style={{marginLeft: 20, marginTop: -15}}>
      <Sticky
        scrollElement="window"
      >
        <div style={{paddingTop: 20}}>
          <Card
            style={{borderRadius: 5, border: '1px solid #c0c0c0'}}
            bodyStyle={{padding: "15px 15px 5px 15px"}}
          >
            <div className={"text14-600"} style={{fontSize: 28, lineHeight: 1.4}}>
              Calculation Summary
            </div>
            <Divider className={"orderDivider"} style={{marginBottom: 10, marginTop: 12}}/>
            <div className={"text12-500"} style={{ color: "#eee", fontSize: 13}}>
              This page is used to test how the parameters you've set up affect the automated price calculation for various parts.
            </div>
            <Divider className={"orderDivider"} style={{marginBottom: 10, marginTop: 12}}/>
            <div
              style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}
            >
              <div>
                <div>
                  <span className={"text14-400"}>Number of Unique Parts</span>
                </div>
                <div>
                  <span className={"text14-400"}>Total Price</span>
                </div>
              </div>

              <div>
                <div className={"text12-600"} style={{lineHeight: 1.8}}>
                  {numOfItems}
                </div>
                <div className={"text12-600"} style={{lineHeight: 1.8}}>
                  {(totalPrice  || 0).toFixed(2)} €
                </div>
              </div>
            </div>
            <Divider className={"orderDivider"} style={{marginBottom: 20, marginTop: 12}}/>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginBottom: 14, alignItems: "center" }}>
              <div className={"text12-500"}>
                Export current parts:
              </div>
              <OrderExcelExportButton
                role={"producerAdmin"}
                name={orderName}
                isEditing={false}
                parts={items || []}
                adjustment={0}
                squared={false}
              />
            </div>
          </Card>
        </div>
      </Sticky>
    </div>
  )
}