import {Button, Card, Tooltip} from "antd";
import {calcAdjustment, canHaveCertificate, getWeight, hasWeight} from "../../../utils/utility";
import {DownloadOutlined, FilePdfOutlined, SearchOutlined} from "@ant-design/icons";
import React from "react";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import "./style.less";
import {imageSection, materialInfoSection} from "../../../utils/partCardUtils/sections";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";

const ArchivedBulkItem = (props) => {
  const {
    part, handleOpenDocsModal, handleShowDownloadFiles,
    showOtherItem, showDetailsItem, userRole,
    handleOpenPricesModal, assemblyCount, adjustment,
    getAssemblyWeight
  } = props;

  return (
    <div style={{display: "flex", width: "100%"}}>
      {imageSection(part)}
      <div style={{width: "100%"}}>
        <div style={{width: "100%"}}>
          <table style={{width: "100%"}}>
            <tbody>
            <tr>
              <td style={{width: "calc(100% - 100px)", maxWidth: 150}}>
                <div
                  className={"partInfo"}
                  style={{display: "block", marginLeft: -1}}
                >
                  {part.fileName || part.name}
                </div>
              </td>
              <td style={{width: 100}}>
                <div style={{display: "flex", height: 24, justifyContent: "flex-end"}}>
                  <div style={{display: "flex", height: 24}}>
                    {part.documents?.length > 0 &&
                    <Tooltip
                      title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>Download Documents</span>}
                      placement={"topRight"}
                      color={"#d6d6d6"}
                    >
                      <Button
                        size={"small"}
                        type={"primary"}
                        className={"exportPdfButton"}
                        onClick={() => {
                          handleOpenDocsModal(part.documents)
                        }}
                      >
                        <div>
                          <FilePdfOutlined style={{fontSize: 12}}/>
                        </div>
                      </Button>
                    </Tooltip>
                    }
                    {(part.processingType === "automatic" && (userRole === "gelsoAdmin" || userRole === "gelsoSubAdmin")) &&
                      <Tooltip
                        title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>View Different Producers Prices</span>}
                        placement={"topRight"}
                        color={"#d6d6d6"}
                      >
                        <Button
                          size={"small"}
                          className={"d6Button"}
                          style={{marginLeft: 5}}
                          onClick={() => {
                            handleOpenPricesModal({...part, assemblyCount: assemblyCount, adjustment: adjustment})
                          }}
                        >
                          <AssignmentOutlinedIcon sx={{fontSize: "14px !important"}}/>
                        </Button>
                      </Tooltip>
                    }
                    <Tooltip title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>Download Files</span>}
                             placement={"topRight"}
                             color={"#d6d6d6"}
                    >
                      <Button
                        size={"small"}
                        type={"primary"}
                        style={{marginLeft: 5}}
                        onClick={() => handleShowDownloadFiles(part)}
                      >
                        <DownloadOutlined style={{fontSize: 13}}/>
                      </Button>
                    </Tooltip>
                    <Tooltip title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>View Part</span>}
                             placement={"topRight"}
                             color={"#d6d6d6"}
                    >
                      <Button
                        size={"small"}
                        type={"primary"}
                        style={{marginLeft: 5}}
                        onClick={() => {
                          if (part.fileType === "other" || (part.processingType === "manual" && !part.fileType)) {
                            showOtherItem({...part, quoteType: "quoted"})
                          } else if (part.partType === "assembly") {
                            let assemblyWeight = getAssemblyWeight(part.id)
                            showDetailsItem({
                              ...part,
                              assemblyWeight: assemblyWeight,
                              quoteType: "quoted"
                            })
                          } else {
                            showDetailsItem({...part, quoteType: "quoted"})
                          }
                        }}
                      >
                        <div>
                          <SearchOutlined style={{fontSize: 13}}/>
                        </div>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "flex-end"}}>
            {part.certNeeded && canHaveCertificate(part.partType) &&
              <Tooltip
                title={<span>3.1 Material Certificate Requested for this part.</span>}
                placement={"topLeft"}
              >
                <TaskOutlinedIcon
                  className={"materialCertificateIcon"}
                  style={{ fontSize: 18, position: "relative", bottom: 2 }}
                />
              </Tooltip>
            }
            {materialInfoSection(part)}
          </div>

          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{fontSize: 12, fontWeight: 500, marginRight: 8}}>
              {part.partType === "assembly" ? "Number of Assemblies" : "Quantity"}: <span style={{fontWeight: 600}}>{part.quantity}</span>
            </div>
            <div style={{fontSize: 12, fontWeight: 500}}>
              1pc: <span style={{fontWeight: 600, color: adjustment === 0 ? "#fff" : "#04cc04"}}>
                  {((parseFloat(part.pricePerPiece) || 0) * calcAdjustment(adjustment)).toFixed(2)} €
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ArchivedBulkOrderPartList = (props) => {

  const {
    parts, handleOpenDocsModal, handleShowDownloadFiles,
    showOtherItem, showDetailsItem, userRole,
    handleOpenPricesModal, adjustment
  } = props;

  const getAssemblyParts = (assemblyId) => {
    return parts.filter(item => item.assemblyId === assemblyId)
  }

  const getAssemblyWeight = (assemblyId) => {
    let assemblyParts = getAssemblyParts(assemblyId)
    let weight = 0;
    for (const assemblyPart of assemblyParts) {
      if (hasWeight({item: assemblyPart})) {
        weight += parseFloat(getWeight({item: assemblyPart})) * (assemblyPart.quantity || 1)
      }
    }
    return weight
  }

  return (
    <div
      className={"quotedItemsList"}
      style={{
        minHeight: 250,
        height: "calc(100vh - 145px)",
        padding: "0 6px 0 6px"
      }}
    >
      {parts.map(part =>
        part.partType === "assembly" ?
          <Card className={"assemblyPartsCard"} key={part.id}>
            <div className={"assemblyPartName"}>
              <ArchivedBulkItem
                part={part}
                handleOpenDocsModal={handleOpenDocsModal}
                handleShowDownloadFiles={handleShowDownloadFiles}
                showOtherItem={showOtherItem}
                showDetailsItem={showDetailsItem}
                getAssemblyWeight={getAssemblyWeight}
                userRole={userRole}
                handleOpenPricesModal={handleOpenPricesModal}
                adjustment={adjustment}
              />
            </div>
            <div className={"assemblyParts"}>
              {getAssemblyParts(part.id).map(assemblyPart => (
                <Card
                  className={"bendCard"}
                  style={{height: 80, backgroundColor: assemblyPart.assemblyId ? "#354045" : ""}}
                  bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                  key={assemblyPart.id}
                >
                  <ArchivedBulkItem
                    part={assemblyPart}
                    handleOpenDocsModal={handleOpenDocsModal}
                    handleShowDownloadFiles={handleShowDownloadFiles}
                    showOtherItem={showOtherItem}
                    showDetailsItem={showDetailsItem}
                    userRole={userRole}
                    assemblyCount={part.quantity}
                    handleOpenPricesModal={handleOpenPricesModal}
                    adjustment={adjustment}
                  />
                </Card>
              ))}
            </div>
          </Card>
          :
          (!part.assemblyId &&
            <Card
              className={"bendCard"}
              style={{height: 80, backgroundColor: part.assemblyId ? "#354045" : ""}}
              bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
              key={part.id}
            >
              <ArchivedBulkItem
                part={part}
                handleOpenDocsModal={handleOpenDocsModal}
                handleShowDownloadFiles={handleShowDownloadFiles}
                showOtherItem={showOtherItem}
                showDetailsItem={showDetailsItem}
                userRole={userRole}
                handleOpenPricesModal={handleOpenPricesModal}
                adjustment={adjustment}
              />
            </Card>
          )
      )}
    </div>
  )
}