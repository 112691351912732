import React, {useEffect, useState} from "react";
import {CompanySidebar} from "../../../components/company";
import {logoutUser} from "../../../actions/authActions";
import {Layout, Row, Col, Divider, Table, Tag, Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {formatTableDate, getColumnSearchProps} from "../../../utils/utility";
import history from "../../../utils/history";
import {LoadingOutlined} from "@ant-design/icons";
import {EmptyTable} from "../../../components/EmptyTable";
import {fetchProducerProcessedOrders} from "../../../actions/ordersActions";

export const ProcessedPage = () => {
  let searchInput = null;
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user)
  const isFetchingProcessedOrders = useSelector(state => state.orders.isFetchingProducerProcessedOrders)
  const processedOrders = useSelector(state => state.orders.producerProcessedOrders)
  const pagination = useSelector(state => state.orders.producerProcessedOrdersPagination)
  const dispatch = useDispatch()

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  const [sortedInfo, setSortedInfo] = useState({})
  const [filteredInfo, setFilteredInfo] = useState({})

  useEffect(() => {
    dispatch(fetchProducerProcessedOrders({
      sortField: "lastStateChangeDate",
      sortDirection: "desc",
      orderName: "",
      customName: "",
      customerName: "",
      state: "",
      page: pagination.current !== undefined ? pagination.current : 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const sortField = sorter.order ? sorter.columnKey : "lastStateChangeDate"
    const sortDirection = sorter.order ? (sorter.order === "ascend" ? "asc" : "desc") : "desc"
    const orderName = filters.orderName ? filters.orderName[0] : ""
    const customName = filters.customName ? filters.customName[0] : ""
    const customerName = filters.customerName ? filters.customerName[0] : ""
    const state = filters.state && filters.state.length === 1 ? filters.state[0] : ""

    setFilteredInfo(filters)
    setSortedInfo(sorter)

    dispatch(fetchProducerProcessedOrders({
      sortField: sortField,
      sortDirection: sortDirection,
      orderName: orderName,
      customName: customName,
      customerName: customerName,
      page: pagination.current,
      pagination: pager,
      state: state
    }))
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{overflowX: "hidden"}}>
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            <Row type="flex">
              <Col span={24}>
                <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                  <div className={"pageTitle"}>Processed quotes:</div>
                </div>
                <Divider style={{marginTop: 6, marginBottom: 11}}/>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={24}>
                <React.Fragment>
                  <Table
                    columns={[
                      {
                        title: 'Quote Code',
                        key: 'orderName',
                        width: "10%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.orderName}
                          </div>
                        ),
                        filteredValue: filteredInfo.orderName || null,
                        ...getColumnSearchProps('orderName', searchInput,"code"),
                      },
                      {
                        title: 'Custom Name',
                        key: 'customName',
                        className: "darkerColumn",
                        ellipsis: {showTitle: false},
                        width: "14%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.customName || "/"}
                          </div>
                        ),
                        filteredValue: filteredInfo.customName || null,
                        ...getColumnSearchProps('customName', searchInput,"orders"),
                      },
                      {
                        title: 'Quote Date',
                        key: 'orderDate',
                        width: "10%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {formatTableDate(row.orderDate)}
                          </div>
                        ),
                        sorter: true,
                        sortOrder: sortedInfo.columnKey === 'orderDate' && sortedInfo.order
                      },
                      {
                        title: 'Customer',
                        key: 'customerName',
                        className: "darkerColumn",
                        width: "17%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.customerName}
                          </div>
                        ),
                        filteredValue: filteredInfo.customerName || null,
                        ...getColumnSearchProps('customerName', searchInput,"customer"),
                      },
                      {
                        title: 'Delivery Date',
                        key: 'deliveryDate',
                        width: "10%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {formatTableDate(row.deliveryDate)}
                          </div>
                        ),
                        sorter: true,
                        sortOrder: sortedInfo.columnKey === 'deliveryDate' && sortedInfo.order
                      },
                      {
                        title: 'Total Price',
                        key: 'totalWithVat',
                        className: "darkerColumn",
                        width: "12%",
                        align: "right",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {(row.totalPrice * (1 + row.vatValue) || row.totalPrice || 0).toFixed(2)}€
                          </div>
                        ),
                      },
                      {
                        title: 'Processed Date',
                        key: 'lastStateChangeDate',
                        width: "12%",
                        align: "right",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {formatTableDate(row.lastStateChangeDate || 0)}
                          </div>
                        ),
                        sorter: true,
                        sortOrder: sortedInfo.columnKey === 'lastStateChangeDate' && sortedInfo.order
                      },
                      {
                        title: 'Status',
                        key: 'state',
                        className: "darkerColumn",
                        width: "120px",
                        align: "center",
                        render: row => (
                          <div>
                            <React.Fragment>
                              {row.state === "accepted" ?
                                <Tag color="#6bce34" style={{fontWeight: 600, color: "black", textAlign: "center", width: 89, marginRight: 0}}>Accepted</Tag>
                                :
                                <Tag color="#e03838" style={{fontWeight: 600, textAlign: "center", width: 89, marginRight: 0}}>Declined</Tag>
                              }
                            </React.Fragment>
                          </div>
                        ),
                        filteredValue: filteredInfo.state || null,
                        filters: [{
                          text: 'Accepted',
                          value: 'accepted',
                        }, {
                          text: 'Declined',
                          value: 'declined',
                        }],
                      },
                      {
                        title: '',
                        key: 'more',
                        width: "90px",
                        align: "right",
                        render: row => (
                          <Button
                            onMouseDown={(e) => {
                              if (e.button === 1) {
                                const win = window.open(`/completed-order?order=${row.id}`, "_blank");
                                win.focus();
                              } else if (e.button === 0) {
                                history.push(`/completed-order?order=${row.id}`)
                              }
                            }}
                            type="primary"
                            style={{
                              fontSize: 13,
                              fontWeight: 600
                            }}
                          >
                            Details
                          </Button>
                        ),
                      },
                    ]}
                    className={"antTable"}
                    dataSource={processedOrders}
                    rowKey="id"
                    pagination={{...pagination, pageSize: pageSize}}
                    loading={{spinning: isFetchingProcessedOrders, indicator: <LoadingOutlined style={{ fontSize: 50,marginLeft: "-25px" }} spin />}}
                    onChange={handleTableChange}
                    size={"small"}
                    scroll={{x: '70%'}}
                    locale={{ emptyText: <EmptyTable text={"No Data"} /> }}
                  />
                </React.Fragment>
              </Col>
            </Row>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}