import * as React from "react";
import "./style.css";
import {Card} from "antd";

const MaterialTableContainer = (props) => {
  return (
    <Card className={'laserSpeedCard'}
          bodyStyle={{ paddingTop: 0, paddingLeft: 10, paddingRight: 5, paddingBottom: 11  }}>
      <div align={"center"} className={"cuttingSpeedText"} style={{ fontSize: 15, fontWeight: 600, textDecoration: "underline", marginTop: 2 }}>
        {props.tableText}
      </div>
      <div className={"materialTableYAxis"}>
        {props.yAxisText}
      </div>
      <div className={"materialTableXAxis"}>
        {props.xAxisText}
      </div>
      <Card className={"tableCardWrapper"}
            style={{ borderRadius: 5, marginLeft: 30, marginRight: 10 }}
            bodyStyle={{ padding: 0 }}>
        {props.children}
      </Card>
    </Card>
  )
}

export default MaterialTableContainer;