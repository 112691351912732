import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Divider, Form, Input, InputNumber, Modal, Select, Space, Typography} from "antd";
import {CaretDownOutlined, DeleteOutlined, EditOutlined, WarningOutlined} from "@ant-design/icons";
import {EmptyTable} from "../../EmptyTable";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {WaterJetIcon} from "../../../assets/icons/waterJetIcon";
import {debounce} from "lodash";
import "./style.less";
import {getRoughnesses, getRoughnessValue, getTolerances, getToleranceValue} from "../../../utils/utility";


const { Option, OptGroup } = Select;

/*
    laser types:
    CO₂ Laser
    Fiber Laser
    Nd:YAG Laser
    CNC Gas
    CNC Plasma
    Water Jet
*/

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: "8px 8px 0 8px",
  margin: `0 0 ${grid}px 0`,
  borderRadius: 3,
  border: "1px solid #14181a",
  boxShadow: "0 2px 4px 1px rgba(0, 0, 0, 0.12)",
  // change background colour if dragging
  background: isDragging ? '#838f94' : '#3f4c52',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#dfecf1' : '#ececec',
  border: "1px solid #14181a",
  borderRadius: 5,
  padding: grid,
  width: 350,
});

const getMachineName = (type, machine) => {
  if (machine) {
    if (type === "milling") {
      return (
        <span>
          {machine.name}
          <span className={"part-dimensions-white"} style={{ marginLeft: 8, color: "#bdbdbd", fontSize: 11 }}>
            {Math.round((machine.dimensions.x + Number.EPSILON) * 10) / 10}×{Math.round((machine.dimensions.y + Number.EPSILON) * 10) / 10}×{Math.round((machine.dimensions.z + Number.EPSILON) * 10) / 10}mm
          </span>
        </span>
      )
    }
    else if  (type === "lathing") {
      return (
        <span>
          {machine.name}
          <span className={"part-dimensions-white"} style={{ marginLeft: 8, color: "#bdbdbd", fontSize: 11 }}>
            {Math.round((machine.dimensions.r + Number.EPSILON) * 10) / 10}×{Math.round((machine.dimensions.h + Number.EPSILON) * 10) / 10}mm
          </span>
        </span>
      )
    }
    else {
      return machine.name
    }
  }
}

export const AddMachineModal = (props) => {

  const {
    modalVisible, handleCloseModal,
    isLoading, handleAddMachine,
    machineTypes, title, type
  } = props;

  return (
    <Modal
      visible={modalVisible}
      title={title || "Add New Machine"}
      destroyOnClose={true}
      onCancel={() => {
        handleCloseModal()
      }}
      width={570}
      bodyStyle={{ paddingBottom: 12 }}
      footer={<div>
        <div align={'right'}>
          <Button
            type={"primary"}
            style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
            form={"addMachine"}
            key={"submit"}
            htmlType={"submit"}
            loading={isLoading}
          >
            Add Machine
          </Button>
        </div>
      </div>}
    >
      <Form
        name={"addMachine"}
        validateTrigger={"onSubmit"}
        onFinish={handleAddMachine}
      >
        {type === "laserCutting" &&
          <div>
            <div style={{ color: "white" }}>Machine Type:</div>
            <Form.Item
              name={"typeId"}
              rules={[{required: true, message: "Select machine type!"}]}
            >
              <Select
                placeholder={"Select machine type"}
                virtual={false}
                suffixIcon={<CaretDownOutlined/>}
              >
                {machineTypes.map(item => (
                  <Option value={item.id} key={item.id} style={{ color: "white" }}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        }
        <div style={{ color: "white" }}>Machine Name:</div>
        <Form.Item
          name={"name"}
          rules={[
            {required: true, message: "Input Required!"},
            {type: "string", min: 3, message: "Name too short!"},
            {type: "string", max: 60, message: "Name too long!"}
          ]}
        >
          <Input className={"machineName"} size={"large"} placeholder={"Enter the machine name"}/>
        </Form.Item>
        {type === "milling" &&
          <div>
            <div style={{ color: "white" }}>Machine Dimensions:</div>

            <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <span style={{ color: "white", marginRight: 6, marginBottom: 20 }}>X:</span>
                <Form.Item
                  name={"x"}
                  rules={[
                    ({getFieldValue}) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject("Input Required!");
                        } else {
                          const y = getFieldValue("y");
                          const z = getFieldValue("z");
                          if (y && z) {
                            if (value > y && value > z) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject("X must be the highest value!");
                            }
                          }
                        }
                      }
                    })
                  ]}
                >
                  <InputNumber
                    min={100}
                    max={20000}
                    placeholder={"Enter the value"}
                    type={"number"}
                    style={{width: "152px"}}
                  />
                </Form.Item>
              </div>

              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <span style={{ color: "white", marginRight: 6, marginBottom: 20 }}>Y:</span>
                <Form.Item
                  name={"y"}
                  rules={[
                    {required: true, message: "Input Required!"},
                  ]}
                >
                  <InputNumber
                    min={100}
                    max={20000}
                    placeholder={"Enter the value"}
                    type={"number"}
                    style={{width: "152px"}}
                  />
                </Form.Item>
              </div>

              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <span style={{ color: "white", marginRight: 6, marginBottom: 20 }}>Z:</span>
                <Form.Item
                  name={"z"}
                  rules={[
                    {required: true, message: "Input Required!"},
                  ]}
                >
                  <InputNumber
                    min={100}
                    max={20000}
                    placeholder={"Enter the value"}
                    type={"number"}
                    style={{width: "152px"}}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        }
        {type === "lathing" &&
          <div>
            <div style={{ color: "white" }}>Machine Dimensions:</div>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <span style={{ color: "white", marginRight: 6, marginBottom: 20 }}>Height (Z):</span>
                <Form.Item
                  style={{ marginRight: 6 }}
                  name={"r"}
                  rules={[
                    {required: true, message: "Input Required!"},
                  ]}
                >
                  <InputNumber
                    min={5}
                    max={1000}
                    placeholder={"Enter the value"}
                    type={"number"}
                    style={{width: "184px"}}
                  />
                </Form.Item>
              </div>

              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <span style={{ color: "white", marginRight: 6, marginBottom: 20 }}>Length (X):</span>
                <Form.Item
                  name={"h"}
                  rules={[
                    {required: true, message: "Input Required!"},
                  ]}
                >
                  <InputNumber
                    min={10}
                    max={20000}
                    placeholder={"Enter the value"}
                    type={"number"}
                    style={{width: "184px"}}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        }
      </Form>
    </Modal>
  )
}

export const EditMachineModal = (props) => {

  const {
    modalVisible, handleCloseModal,
    isLoading, handleEditMachine,
    editMachine, machineTypes,
    type, title
  } = props;

  return (
    <Modal
      visible={modalVisible}
      title={title || "Edit Machine"}
      destroyOnClose={true}
      onCancel={() => {
        handleCloseModal()
      }}
      bodyStyle={{ paddingBottom: 12 }}
      width={570}
      footer={<div>
        <div align={'right'}>
          <Button
            type={"primary"}
            style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
            form={"editMachine"}
            key={"submit"}
            htmlType={"submit"}
            loading={isLoading}
          >
            Save Changes
          </Button>
        </div>
      </div>}
    >
      <Form
        name={"editMachine"}
        validateTrigger={"onSubmit"}
        onFinish={handleEditMachine}
      >
        {type === "laserCutting" &&
          <div>
            <div style={{ color: "white" }}>Machine Type:</div>
            <Form.Item
              name={"typeId"}
              rules={[{required: true, message: "Select machine type!"}]}
              initialValue={editMachine ? editMachine.typeId : null}
            >
              <Select
                placeholder={"Select machine type"}
                virtual={false}
                suffixIcon={<CaretDownOutlined/>}
              >
                {machineTypes.map(item => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        }
        <div>
          <div style={{ color: "white" }}>Machine Name:</div>
          <Form.Item
            name={"name"}
            rules={[{required: true, message: "Input Required!"}]}
            initialValue={editMachine ? editMachine.name : null}
          >
            <Input className={"machineName"} size={"large"} placeholder={"Enter the machine name"}/>
          </Form.Item>
        </div>
        {type === "milling" &&
          <div>
            <div style={{ color: "white" }}>Machine Dimensions:</div>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <span style={{ color: "white", marginRight: 6, marginBottom: 20 }}>X:</span>
                <Form.Item
                  name={"x"}
                  rules={[
                    ({getFieldValue}) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject("Input Required!");
                        } else {
                          const y = getFieldValue("y");
                          const z = getFieldValue("z");
                          if (y && z) {
                            if (value > y && value > z) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject("X must be the highest value!");
                            }
                          }
                        }
                      }
                    })
                  ]}
                  initialValue={editMachine ? editMachine.dimensions.x : null}
                >
                  <InputNumber
                    min={100}
                    max={20000}
                    placeholder={"Enter the value"}
                    type={"number"}
                    style={{width: "152px"}}
                  />
                </Form.Item>
              </div>

              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <span style={{ color: "white", marginRight: 6, marginBottom: 20 }}>Y:</span>
                <Form.Item
                  name={"y"}
                  rules={[
                    {required: true, message: "Input Required!"},
                  ]}
                  initialValue={editMachine ? editMachine.dimensions.y : null}
                >
                  <InputNumber
                    min={100}
                    max={20000}
                    placeholder={"Enter the value"}
                    type={"number"}
                    style={{width: "152px"}}
                  />
                </Form.Item>
              </div>

              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <span style={{ color: "white", marginRight: 6, marginBottom: 20 }}>Z:</span>
                <Form.Item
                  name={"z"}
                  rules={[
                    {required: true, message: "Input Required!"},
                  ]}
                  initialValue={editMachine ? editMachine.dimensions.z : null}
                >
                  <InputNumber
                    min={100}
                    max={20000}
                    placeholder={"Enter the value"}
                    type={"number"}
                    style={{width: "152px"}}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        }
        {type === "lathing" &&
          <div>
            <div style={{ color: "white" }}>Machine Dimensions:</div>

            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <span style={{ color: "white", marginRight: 6, marginBottom: 20 }}>Height (Z):</span>
                <Form.Item
                  style={{ marginRight: 6 }}
                  name={"r"}
                  rules={[
                    {required: true, message: "Input Required!"},
                  ]}
                  initialValue={editMachine ? editMachine.dimensions.r : null}
                >
                  <InputNumber
                    min={5}
                    max={1000}
                    placeholder={"Enter the value"}
                    type={"number"}
                    style={{width: "152px"}}
                  />
                </Form.Item>
              </div>

              <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <span style={{ color: "white", marginRight: 6, marginBottom: 20 }}>Length (X):</span>
                <Form.Item
                  name={"h"}
                  rules={[
                    {required: true, message: "Input Required!"},
                  ]}
                  initialValue={editMachine ? editMachine.dimensions.h : null}
                >
                  <InputNumber
                    min={10}
                    max={20000}
                    placeholder={"Enter the value"}
                    type={"number"}
                    style={{width: "152px"}}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        }
      </Form>
    </Modal>
  )
}

export const RemoveMachineModal = (props) => {

  const {
    modalVisible, handleCloseModal,
    isLoading, handleRemoveMachine,
    machine, type
  } = props;

  return (
    <Modal
      visible={modalVisible}
      closable={false}
      okText={"Remove"}
      cancelText={"Cancel"}
      maskClosable={false}
      width={416}
      bodyStyle={{padding: "26px 26px 13px 26px"}}
      okButtonProps={{type: "danger", style: {fontSize: 13, fontWeight: 500}, loading: isLoading}}
      cancelButtonProps={{style: {fontSize: 13, fontWeight: 500}, disabled: isLoading}}
      onOk={()=>{
        handleRemoveMachine()
      }}
      onCancel={() => {
        handleCloseModal()
      }}
    >
      <div className="ant-modal-confirm-body">
        <WarningOutlined style={{color: "red"}}/>
        <span className="ant-modal-confirm-title" style={{color: "white"}}>Remove Machine</span>
        <div className="ant-modal-confirm-content">
          <span style={{fontSize: 13, fontWeight: 500, color: "white"}}>
            Are you sure you want to remove this machine:
            <br/>"<b>{getMachineName(type, machine)}</b>".
            <br/>This action can't be reverted!
          </span>
        </div>
      </div>
    </Modal>
  )
}

export const MachinePriorityModal = (props) => {

  const {
    modalVisible, handleCloseModal,
    isLoading, handleEditPriorities,
    onDragEnd, machinePriority
  } = props;

  return (
    <Modal
      visible={modalVisible}
      title={"Machine Priority"}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={() => {
        handleCloseModal()
      }}
      footer={<div>
        <div align={'right'}>
          <Button
            type={"primary"}
            style={{marginLeft: 10, fontSize: 13, fontWeight: 500}}
            onClick={()=>{
              handleEditPriorities()
            }}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </div>}
    >
      <div className={"machinePriorityContainer"} align={"center"}>
        <DragDropContext style={{ background: "#2d363a" }} onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided, droppableSnapshot) => (
              <div
                ref={droppableProvided.innerRef}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                {machinePriority.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        style={getItemStyle(
                          draggableSnapshot.isDragging,
                          draggableProvided.draggableProps.style,
                        )}
                      >
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                          <div style={{display: "flex"}}>
                            <WaterJetIcon/>
                            <div
                              style={{fontSize: 12, fontWeight: 500, marginLeft: 10, marginTop: 8, color: "white"}}>{item.name}</div>
                          </div>

                          <div style={{marginTop: 24, marginBottom: 5}}>
                            <div style={{fontSize: 10, fontWeight: 500, color: "#ccc"}}>Priority: {index + 1}</div>
                          </div>
                        </div>

                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {machinePriority.length > 1 &&
        <span style={{position: "relative", right: 35, color: "#aaa", fontSize: 12 }}>
              *Click and drag the machines to rearrange priorities.
            </span>
        }
      </div>
    </Modal>
  )
}

export const MachineTolerancesModal = (props) => {

  const {
    modalVisible, handleCloseModal,
    handleChangeTolerance, isLoading,
    title, machineType, selectedMachineId,
    allowTolerancesChange, markupData,
    handleChangeRoughness
  } = props;

  const FormInputField = (props) => {

    const {
      label, name, type, initialValue
    } = props;

    const debouncedChange = debounce(
      (data) => {
        if(data.type === 'tolerance') {
          handleChangeTolerance({
            machineId: data.machineId,
            machineType: data.machineType,
            toleranceClass: data.className,
            toleranceMarkup: data.markup
          })
        } else {
          handleChangeRoughness({
            machineId: data.machineId,
            machineType: data.machineType,
            roughnessClass: data.className,
            roughnessMarkup: data.markup
          })
        }

      }, 500
    );

    return (
      <Space>
        <Form.Item
          className={"materialFormItem"}
          label={label}
          name={name}
          initialValue={initialValue}
        >
          <InputNumber
            className={allowTolerancesChange ? "toleranceInputField" : "toleranceInputFieldDisabled"}
            style={{ minWidth: 120 }}
            min={0}
            max={type === 'tolerance' ? 100 : 99.9}
            disabled={isLoading || !allowTolerancesChange}
            formatter={value => value ? value : 0}
            parser={value => value ? value : 0}
            onChange={(value) => debouncedChange({
              className: name,
              markup: value / 100,
              type: type,
              machineId: selectedMachineId,
              machineType: machineType
            })}
          />
        </Form.Item>
        <Typography.Text className={"cuttingSpeedText"}>%</Typography.Text>
      </Space>
    )
  }

  return (
    <Modal
      visible={modalVisible}
      title={title || "Edit Machine Tolerance Markup"}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={() => {
        handleCloseModal()
      }}
      footer={null}
      width={"fit-content"}
      className={"tolerancesModal"}
    >
      <Form>
        <div style={{ display: "flex", flexDirection: "row", gap: 24}}>
          <div>
            <div style={{display: "flex", alignItems: "center"}}>
              <span className={"mainFromLabel"} style={{color: "white"}}>Max Tolerance Markup:</span>
              <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
            </div>
            <span className={"processDescription"}>(Tolerance-based percentage price adjustment)</span>
            <Card className={"inputInfoText"} style={{margin: "12px 0px 0 0px"}} bodyStyle={{padding: "8px 16px"}}>
              <div style={{ display: "grid", gridTemplateRows: "repeat(4, 50px)" }}>
                {getTolerances().map((tolerance, index) => {
                  return <FormInputField
                    key={index}
                    label={getToleranceValue(tolerance, (machineType === "milling" || machineType === "lathing") ? "machined" : "other")}
                    type={"tolerance"}
                    name={tolerance}
                    initialValue={(markupData?.tolerance[tolerance] || 0) * 100}
                  />
                })}
              </div>
            </Card>
          </div>
          {(machineType === "milling" || machineType === "lathing") &&
          <div>
            <div style={{display: "flex", alignItems: "center"}}>
              <span className={"mainFromLabel"} style={{color: "white"}}>Surface Roughness Speed Decrease:</span>
              <Divider
                className={"lightDivider"}
                style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
            </div>
            <span className={"processDescription"}>(Surface roughness-based percentage speed decrease)</span>
            <Card className={"inputInfoText"} style={{margin: "12px 0px 0 0px"}} bodyStyle={{padding: "8px 16px"}}>
              <div style={{ display: "grid", gridTemplateRows: "repeat(4, 50px)" }}> {/* Ako se povecava broj tolerancija ili roughnesa, povecaj repeat(4) na repeat(maxLen(tolerancess, rougnesses))*/}
                {getRoughnesses().map((roughness, index) => {
                  return <FormInputField
                    key={index}
                    label={getRoughnessValue(roughness)}
                    type={"roughness"}
                    name={roughness}
                    initialValue={(markupData?.roughness[roughness] || 0) * 100}
                  />
                })}
              </div>
            </Card>
          </div>
          }
        </div>
      </Form>
    </Modal>
  )
}

export const MachineSelect = (props) => {

  const {
    selectedMachine, changeSelectedMachine, 
    machines, isFetchingMachineTypes, 
    handleEditMachine, handleRemoveMachine, 
    handleAddMachineOpen, isFetchingMachinePriorities, 
    handleMachinePriorityOpen, machinePriorities, 
    allowPriorityChange, style, isFetchingMachines,
    type, showButtons, allowAddMachine,
  } = props;

  const [currentValue, setCurrentValue] = useState(selectedMachine)

  useEffect(() => {
    if (machines.length === 0 || machines[0]?.machines.length === 0) {
      setCurrentValue(null)
    } else {
      setCurrentValue(selectedMachine)
    }
  }, [machines, selectedMachine])

  const getPlaceholder = (type) => {
    if (type === "milling") {
      return "Select a Milling Machine"
    }
    else if (type === "lathing") {
      return "Select a Lathing Machine"
    }
    else if (type === "laserCutting") {
      return "Select a Cutting Machine"
    }
    else if (type === "tubeCutting") {
      return "Select a Tube Cutting machine"
    }
  }

  return (
    <div className={"machineSelectRoot"} style={{...style}}>
      <div className={"machineSelectContainer"}>
        <div className={"machineSelectLabel"}>
          Selected Machine:
        </div>
        <Select
          value={currentValue}
          className={"machineSelect"}
          style={{ display: "flex", maxWidth: 350, minWidth: 100, width: "100%", marginBottom: 10 }}
          dropdownStyle={{ boxShadow: "none" }}
          virtual={false}
          disabled={isFetchingMachines}
          optionLabelProp={"label"}
          placeholder={getPlaceholder(type, machines)}
          suffixIcon={<CaretDownOutlined/>}
          onChange={(id) => {
            setCurrentValue(id)
            changeSelectedMachine(id)
          }}
          notFoundContent={<div className={"emptyMachineList"}>
            <EmptyTable text={"No Machines Available"}/>
          </div>}
        >
          {machines.map(item => (
            <OptGroup label={item.name} key={item.id}>
              {item.machines?.map(machine => (
                <Option value={machine.id} key={machine.id} label={getMachineName(type, machine)}
                        style={{paddingRight: 8}}>
                  <div style={{display: "flex", alignItems: 'center', justifyContent: "space-between"}}>
                    <div style={{
                      maxWidth: showButtons ? 160 : 300,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      marginTop: 2,
                      color: "whitesmoke"
                    }}>
                      {getMachineName(type, machine)}
                    </div>
                    {showButtons &&
                    <div style={{display: "flex"}}>
                      <EditOutlined
                        className={"editLaserIcon"}
                        onClick={(e) => {
                          if(!isFetchingMachineTypes) {
                            handleEditMachine(e, machine)
                          }
                          else {
                            e.stopPropagation()
                          }
                        }}
                      />
                      <DeleteOutlined
                        className={"deleteLaserIcon"}
                        onClick={(e) => {
                          handleRemoveMachine(e, machine)
                        }}
                      />
                    </div>
                    }
                  </div>
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </div>
      <div style={{display: "flex", marginBottom: 10}}>
        {allowAddMachine &&
          <Button
            type={"primary"}
            style={{fontSize: 13, fontWeight: 500, marginRight: 10}}
            onClick={() => {
              handleAddMachineOpen()
            }}
            disabled={allowPriorityChange ?
              (isFetchingMachines || isFetchingMachineTypes || isFetchingMachinePriorities)
              :
              (isFetchingMachines || isFetchingMachineTypes)
            }
          >
            Add Machine
          </Button>
        }
        {allowPriorityChange &&
          <div align={'right'}>
            <Button
              type={"primary"}
              style={{fontSize: 13, fontWeight: 500, marginRight: 10}}
              onClick={() => {
                handleMachinePriorityOpen()
              }}
              disabled={isFetchingMachines || isFetchingMachineTypes || isFetchingMachinePriorities || machinePriorities.length === 0}
            >
              Machine Priority
            </Button>
          </div>
        }
      </div>
    </div>
  )
}