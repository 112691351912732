import React, {createRef, useState} from "react";
import { forceCheck } from "react-lazyload";
import "./style.less";
import {Form, Row, Col, Collapse, InputNumber, Input, List, Spin, Upload, Button, Progress} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {S3_BUCKET_URL} from "../../../constants";
import {
  ExclamationCircleFilled,
  FilePdfOutlined, LoadingOutlined,
  MinusOutlined
} from "@ant-design/icons";
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {DrawingIcon2} from "../../../assets/icons/drawing2";
import {calcAdjustment} from "../../../utils/utility";
import {AssemblyPartsList} from "../AssemblyPartsList";
import {removePartDrawing, uploadPartDrawing} from "../../../actions/orderActions";
import AddIcon from "@mui/icons-material/Add";

const { Panel } = Collapse;

export const AssemblyOrderItem = (props) => {
  const {
    orderId, item, sheetMetalMaterials, machiningMaterials, tubeCuttingMaterials,
    changeSheetMetalMaterial, changePartQuantity, changePartThickness,
    changePartNote, changePartColor, changePartStandard,
    changePartTolerance, changePartRoughness, changeManualPartTolerance,
    changeManualPartRoughness,
    changePartNumberOfUniqueParts, handleColorRemove, showItem, isOrderDetails,
    bulkItem, changePrice, adjustment,
    assemblyId, selectedItems, onCheck,
    expanded, setExpanded, openDocumentsView,
    handleOpenPricesModal, handleRemoveItem, handleChangeItemType,
    assemblyProgressMap, changeAssemblyQuantity, changeMachiningMaterial,
    changeTubeCuttingMaterial,
    changeAssemblyWeldingPrice, changeAssemblyAssemblingPrice,
    changeAssemblyPackagingPrice, changeAssemblyTransportationPrice,
    changeMaterialCertificate
  } = props

  const [loaded, setLoaded] = useState(false)
  const [uploadingDrawing, setUploadingDrawing] = useState(false)

  const isProducer = useSelector(state => state.auth.user.role === "producerAdmin")
  const items = bulkItem ? useSelector(state => state.order.parts) : useSelector(state => state.order.parts)
  const dispatch = useDispatch();

  const form = createRef();

  const handleRemovePartDrawing = (documentId) => {
    if(bulkItem) {
      dispatch(removePartDrawing({
        orderId: orderId,
        orderPartId: item.id,
        partType: "assembly",
        documentId: documentId
      }))
    } else {
      dispatch(removePartDrawing({
        orderId: orderId,
        orderPartId: item.id,
        partType: "assembly",
        documentId: documentId
      }))
    }
  }

  const addPartDrawing = (file) => {
    setUploadingDrawing(true)
    if(bulkItem) {
      dispatch(uploadPartDrawing({
        orderId: orderId,
        orderPartId: item.id,
        partType: "assembly",
        file: file
      })).then(()=>{
        setUploadingDrawing(false)
      }).catch(()=>{
        setUploadingDrawing(false)
      })
    } else {
      dispatch(uploadPartDrawing({
        orderId: orderId,
        orderPartId: item.id,
        partType: "assembly",
        file: file
      })).then(()=>{
        setUploadingDrawing(false)
      })
    }
  }

  return (
    <div>
      <Form
        ref={form}
        name={"form_"+item.id}
        style={{height: "100%"}}
      >
        <Row style={{height: "100%"}}>
          <Col flex={"192px"} style={{borderRight: "1px solid #838f94", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
            <Row style={{height: 200}}>
              <Col style={{height: 180}}>
                <div className={item.stepData?.filePaths?.foldedStlFilePath && "container"}>
                  {item.stepData?.filePaths?.pngFilePath ?
                    <img
                      className={"productImage"}
                      style={{width: 180, height: 180, padding: 10, position: "relative", background: "white", zIndex: 3}}
                      src={S3_BUCKET_URL + item.stepData.filePaths.pngFilePath}
                      alt={""}
                      loading={"lazy"}
                      onLoad={()=>{
                        setLoaded(true)
                      }}
                    />
                    :
                    <div className={"productImage"} style={{ width: 180, height: 180 }}/>
                  }
                  {item.stepData?.filePaths?.foldedStlFilePath && (
                    <div>
                      <div className={"overlay"} onClick={() => {
                        showItem(item)
                      }}>
                        <div className={"overlay-text"}>
                          <div className={"overlay-text-3D"}>3D</div>
                          <div className={"overlay-text-view"}>View</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {!loaded &&
              (<div className={"imgLoading"}>
                <div className={"dotsContainer"}>
                  <div className={"dotsFlashing"}/>
                </div>
              </div>)
            }
          </Col>
          <Col flex="1 1 29%" style={{borderRight: window.innerWidth > 921  ? "1px dotted #838f94" : "none"}}>
            <Row gutter={10} style={{marginInline: 5, marginTop: -4}}>
              <Col span={24}>
                <span className={"text12-500-12"}>Number of Assemblies</span>
                <Form.Item
                  name={"quantity"}
                  style={{marginBottom: 5}}
                  initialValue={item.quantity}
                  rules={[
                    {required: true, message: ""}
                  ]}
                  className={"quantity-field"}
                >
                  <InputNumber
                    className={"item-number-input assemblyItemQuantity muiInput"}
                    placeholder={"Enter quantity"}
                    type={"number"}
                    style={{width: '100%'}}
                    min={1}
                    max={10000}
                    formatter={value => value ? value : 1}
                    parser={value => value ? value : 1}
                    step={1}
                    onChange={(q) => changeAssemblyQuantity({orderId: orderId, id: item.id, quantity: q})}
                    disabled={!item.isAnalysed}
                  />
                </Form.Item>
              </Col>
              <Col span={24} >
                <span className={"text12-500-12"}>Note (Optional)</span>
                <Form.Item
                  name={"note"}
                  initialValue={item.note}
                  className={"textAreaInput"}
                  style={{ marginBottom: 5 }}
                >
                  <Input.TextArea
                    rows={3}
                    className={`textAreaInputText text12-500 muiInput assemblyTextArea`}
                    placeholder={"Notes and additional requirements"}
                    maxLength={500}
                    showCount={true}
                    onChange={(e)=> changePartNote({orderId: orderId, id: item.id, partType: "assembly", note: e.target.value})}
                    disabled={!item.isAnalysed}
                  />
                </Form.Item>
              </Col>
              {(item.numberOfManualParts > 0 || !bulkItem) &&
                <Col span={24}>
                  <div className={"assemblyErrorMsg"}>
                    <div>
                      <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 13}}/>
                    </div>
                    <div style={{display:"flex", height: "100%", alignItems: "center", marginTop: "auto", marginBottom: "auto"}}>
                      <div className={"text12-600"} style={{marginLeft: 5, lineHeight: 1.4, fontSize: 11, marginTop: 1}}>
                        {bulkItem ?
                          (
                            <div>
                              {item.numberOfManualParts > 0 && (
                                "Info: Production costs and some parts will be quoted manually."
                              )}
                            </div>
                          )
                          :
                          (
                            <div>
                              {item.numberOfManualParts > 0 ?
                                "Info: Production costs and some parts will be quoted manually."
                                :
                                "Info: Production costs will be quoted manually."
                              }
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </Col>
              }
            </Row>
          </Col>
          <Col flex="1 1 42%">
            <List
              style={{marginLeft: 10, marginTop: -5, minWidth: 300}}
            >
              <List.Item style={{paddingTop: 3, paddingBottom: 4}}>
                <div
                  style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
                >
                  <div>
                    <span>
                      <MinusOutlined style={{color: '#bdbdbd'}}/>
                    </span>
                    <span
                      className={"text14-400"}
                      style={{color: '#bdbdbd', marginLeft: 8}}
                    >
                      Welding
                    </span>
                  </div>
                  <div style={{marginRight: 15}}>
                    {isProducer ? null : (
                      bulkItem ?
                        <div>
                          <Form.Item
                            className={"priceInputField"}
                            name={"weldingPrice"}
                            initialValue={(item.weldingPrice || 0).toFixed(2)}
                          >
                            <InputNumber
                              className={"muiInput"}
                              formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)} €`}
                              parser={value => value ? value.replace('€', '') : 0}
                              min={0}
                              onChange={(p) => changeAssemblyWeldingPrice({orderId: orderId, id: item.id, weldingPrice: p})}
                              size={"small"}
                              style={{width: 80 }}
                              disabled={!item.isAnalysed}
                            />
                          </Form.Item>
                        </div>
                        :
                        <span className={"text12-500"} >RFQ</span>
                      )
                    }
                  </div>
                </div>
              </List.Item> {/*Welding*/}
              <List.Item style={{paddingTop: 3, paddingBottom: 4}}>
                <div
                  style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
                >
                  <div>
                    <span>
                      <MinusOutlined style={{color: '#bdbdbd'}}/>
                    </span>
                    <span
                      className={"text14-400"}
                      style={{color: '#bdbdbd', marginLeft: 8}}
                    >
                      Machine Processing
                    </span>
                  </div>
                  <div style={{marginRight: 15}}>
                    {isProducer ? null : (
                      bulkItem ?
                        <div>
                          <Form.Item
                            className={"priceInputField"}
                            name={"packagingPrice"}
                            initialValue={(item.packagingPrice || 0).toFixed(2)}
                          >
                            <InputNumber
                              className={"muiInput"}
                              formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)} €`}
                              parser={value => value ? value.replace('€', '') : 0}
                              min={0}
                              onChange={(p) => changeAssemblyPackagingPrice({orderId: orderId, id: item.id, packagingPrice: p})}
                              size={"small"}
                              style={{width: 80 }}
                              disabled={!item.isAnalysed}
                            />
                          </Form.Item>
                        </div>
                        :
                        <span className={"text12-500"} >RFQ</span>
                    )
                    }
                  </div>
                </div>
              </List.Item> {/*Machine Processing*/}
              <List.Item style={{paddingTop: 3, paddingBottom: 4}}>
                <div
                  style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
                >
                  <div>
                    <span>
                      <MinusOutlined style={{color: '#bdbdbd'}}/>
                    </span>
                    <span
                      className={"text14-400"}
                      style={{color: '#bdbdbd', marginLeft: 8}}
                    >
                      Assembling
                    </span>
                  </div>
                  <div style={{marginRight: 15}}>
                    {isProducer ? null : (
                      bulkItem ?
                        <div>
                          <Form.Item
                            className={"priceInputField"}
                            name={"assemblingPrice"}
                            initialValue={(item.assemblingPrice || 0).toFixed(2)}
                          >
                            <InputNumber
                              className={"muiInput"}
                              formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)} €`}
                              parser={value => value ? value.replace('€', '') : 0}
                              min={0}
                              onChange={(p) => changeAssemblyAssemblingPrice({orderId: orderId, id: item.id, assemblingPrice: p})}
                              size={"small"}
                              style={{width: 80 }}
                              disabled={!item.isAnalysed}
                            />
                          </Form.Item>
                        </div>
                        :
                        <span className={"text12-500"} >RFQ</span>
                      )
                    }
                  </div>
                </div>
              </List.Item> {/*Assembling*/}
              <List.Item style={{paddingTop: 3, paddingBottom: 4}}>
                <div
                  style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
                >
                  <div>
                    <span>
                      <MinusOutlined style={{color: '#bdbdbd'}}/>
                    </span>
                    <span
                      className={"text14-400"}
                      style={{color: '#bdbdbd', marginLeft: 8}}
                    >
                      Others
                    </span>
                  </div>
                  <div style={{marginRight: 15}}>
                    {isProducer ? null : (
                      bulkItem ?
                        <div>
                          <Form.Item
                            className={"priceInputField"}
                            name={"transportationPrice"}
                            initialValue={(item.transportationPrice || 0).toFixed(2)}
                          >
                            <InputNumber
                              className={"muiInput"}
                              formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)} €`}
                              parser={value => value ? value.replace('€', '') : 0}
                              min={0}
                              onChange={(p) => changeAssemblyTransportationPrice({orderId: orderId, id: item.id, transportationPrice: p})}
                              size={"small"}
                              style={{width: 80 }}
                              disabled={!item.isAnalysed}
                            />
                          </Form.Item>
                        </div>
                        :
                        <span className={"text12-500"}>RFQ</span>
                      )
                    }
                  </div>
                </div>
              </List.Item> {/*Others*/}
              <List.Item style={{paddingBottom: 0}}>
                <div style={{width: '100%'}}>
                  <table style={{width: "100%", height: "100%", marginBottom: 5}}>
                    <tbody style={{height: "100%"}}>
                    <tr style={{height: "100%"}}>
                      <td style={{width: "67%", maxWidth: 150, height: "100%"}}>
                        {!(isOrderDetails || isProducer) &&
                          <div style={{display: "flex", height: '100%'}}>
                            {item.documents?.length > 0 ?
                              <div style={{width: "100%"}}>
                                {item.documents.length > 1 ?
                                  <div style={{display: "flex", width: "100%"}}>
                                    <Spin
                                      spinning={uploadingDrawing}
                                      wrapperClassName={"overlayDocumentUploadSpin"}
                                      indicator={<LoadingOutlined style={{fontSize: 30, marginLeft: -15, marginTop: -15}}
                                                                  spin/>}
                                    >
                                      <Button
                                        className={"addMoreDocumentsButton"}
                                        style={{
                                          width: "100%",
                                          borderRadius: 5,
                                          marginBottom: -10,
                                          paddingRight: 0
                                        }}
                                        type="dashed"
                                        onClick={() => {
                                          openDocumentsView(item)
                                        }}
                                        ghost
                                      >
                                        <div style={{display: "flex", width: "100%"}}>
                                          <div style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            width: "calc(100% - 20px)"
                                          }}>
                                            <div>
                                              <FilePdfOutlined style={{
                                                fontSize: 14,
                                                color: "#1890ff",
                                                marginRight: 4,
                                                marginTop: 4,
                                                marginLeft: -5
                                              }}/>
                                            </div>
                                            <div
                                              className={"addDrawingText ellipsisText"}
                                              style={{color: "#1890ff", marginTop: 5}}
                                            >
                                              {item.documents.length} Drawing Files
                                            </div>
                                          </div>
                                        </div>
                                      </Button>
                                    </Spin>
                                    <div style={{marginLeft: 5}}>
                                      <Spin
                                        spinning={uploadingDrawing}
                                        wrapperClassName={"documentUploadSpin"}
                                        indicator={<LoadingOutlined
                                          style={{fontSize: 24, marginTop: -13, marginLeft: -11}} spin/>}
                                      >
                                        <Upload
                                          showUploadList={false}
                                          accept=".pdf"
                                          multiple
                                          beforeUpload={(file) => {
                                            addPartDrawing(file)
                                            return false
                                          }}
                                        >
                                          <Button
                                            className={"addMoreDocumentsButton"}
                                            style={{width: 40, minWidth: 40, borderRadius: 5}}
                                            type="dashed"
                                            ghost
                                          >
                                            <AddIcon style={{
                                              fontSize: 20,
                                              marginLeft: -6,
                                              minWidth: 14,
                                              minHeight: 14,
                                              marginTop: 5
                                            }}/>
                                          </Button>
                                        </Upload>
                                      </Spin>
                                    </div>
                                  </div>
                                  :
                                  <div style={{display: "flex", width: "100%"}}>
                                    <Spin
                                      spinning={uploadingDrawing}
                                      wrapperClassName={"overlayDocumentUploadSpin"}
                                      indicator={<LoadingOutlined style={{fontSize: 30, marginLeft: -15, marginTop: -15}}
                                                                  spin/>}
                                    >
                                      <div className={"documentSpinContainer"}>
                                        <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                          <div style={{display: "flex", width: "calc(100% - 20px)"}}>
                                            <div>
                                              <FilePdfOutlined style={{color: "#1890ff", marginRight: 4}}/>
                                            </div>
                                            <div
                                              className={"addDrawingText ellipsisText"}
                                              style={{color: "#1890ff", marginTop: 4, cursor: "default"}}
                                            >
                                              {item.documents[0].drawingPDFFileName}
                                            </div>
                                          </div>
                                          <div style={{marginTop: 0, justifyContent: "flex-end", alignItems: "center"}}>
                                            <IconButton
                                              size={'small'}
                                              aria-label="clear"
                                              style={{width: 18, height: 18, marginRight: -3, marginTop: -1}}
                                              onClick={() => {handleRemovePartDrawing(item.documents[0].documentId)}}
                                            >
                                              <CloseIcon style={{fontSize: 14, color: '#fc505a'}}/>
                                            </IconButton>
                                          </div>
                                        </div>
                                      </div>
                                    </Spin>
                                    <div style={{marginLeft: 5}}>
                                      <Spin
                                        spinning={uploadingDrawing}
                                        wrapperClassName={"documentUploadSpin"}
                                        indicator={<LoadingOutlined
                                          style={{fontSize: 24, marginTop: -13, marginLeft: -11}} spin/>}
                                      >
                                        <Upload
                                          showUploadList={false}
                                          accept=".pdf"
                                          multiple
                                          beforeUpload={(file) => {
                                            addPartDrawing(file)
                                            return false
                                          }}
                                        >
                                          <Button
                                            className={"addMoreDocumentsButton"}
                                            style={{width: 40, minWidth: 40, borderRadius: 5}}
                                            type="dashed"
                                            ghost
                                          >
                                            <AddIcon style={{
                                              fontSize: 20,
                                              marginLeft: -6,
                                              minWidth: 14,
                                              minHeight: 14,
                                              marginTop: 5
                                            }}/>
                                          </Button>
                                        </Upload>
                                      </Spin>
                                    </div>
                                  </div>
                                }
                              </div>
                              :
                              <Spin
                                spinning={uploadingDrawing}
                                wrapperClassName={"documentUploadSpin"}
                                indicator={<LoadingOutlined style={{fontSize: 30, marginLeft: -67, marginTop: -15}}
                                                            spin/>}
                              >
                                <Upload
                                  showUploadList={false}
                                  accept=".pdf"
                                  multiple
                                  beforeUpload={(file) => {
                                    addPartDrawing(file)
                                    return false
                                  }}
                                >
                                  <Button
                                    className={"drawingsButton"}
                                    icon={<DrawingIcon2 className={"drawingIcon"}/>}
                                  >
                                    <span className={"addDrawingText"}>
                                      Add part drawings
                                    </span>
                                  </Button>
                                </Upload>
                              </Spin>
                            }
                          </div>
                        }
                      </td>
                      <td style={{width: "33%", paddingLeft: 10}}>
                        {bulkItem ? (
                          <div align={"right"}>
                            <div style={{minWidth: 180, display: "flex", justifyContent: "flex-end"}}>
                              <div style={{marginRight: 15, display: 'flex', justifyContent: "space-between", textAlign: 'right'}}>
                                <div style={{ marginRight: 30 }}>
                                  <div className={"priceText"}>
                                    1 pc
                                  </div>
                                  {item.isAnalysed ?
                                    <div className={"priceText"} style={{paddingTop: 6, whiteSpace: "nowrap"}}>
                                      {(item.pricePerPiece || 0).toFixed(2)} €
                                    </div>
                                    :
                                    <div className={"dotsFlashing2Container"}>
                                      <div className={"dotsFlashing2"}/>
                                    </div>
                                  }
                                </div>

                                <div>
                                  <div className={"priceText"}>
                                    Total
                                  </div>
                                  {item.isAnalysed ?
                                    <div className={"priceText"} style={{paddingTop: 6, whiteSpace: "nowrap"}}>
                                      {(item.totalPrice || 0).toFixed(2)} €
                                    </div>
                                    :
                                    <div className={"dotsFlashing2Container"}>
                                      <div className={"dotsFlashing2"}/>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div style={{minWidth: 180, display: "flex", justifyContent: "flex-end"}}>
                            <div style={{marginRight: 15, display: 'flex', justifyContent: "space-between", textAlign: 'right'}}>
                              <div style={{marginRight: 30}}>
                                <div className={"priceText"}>1 pc</div>
                                {item.isAnalysed ?
                                  <div className={"priceText"} style={{paddingTop: 6, whiteSpace: "nowrap"}}>
                                    {item.pricePerPiece === 0 ?
                                      <span>RFQ</span>
                                      :
                                      <span>{`${((item.pricePerPiece || 0) * calcAdjustment(adjustment)).toFixed(2)} € + RFQ`}</span>
                                    }
                                  </div>
                                  :
                                  <div className={"dotsFlashing2Container"}>
                                    <div className={"dotsFlashing2"}/>
                                  </div>
                                }
                              </div>
                              <div>
                                <div className={"priceText"}>Total</div>
                                {item.isAnalysed ?
                                  <div className={"priceText"} style={{paddingTop: 6, whiteSpace: "nowrap"}}>
                                    {item.totalPrice === 0 ?
                                      <span>RFQ</span>
                                      :
                                      <span>{`${((item.totalPrice || 0) * calcAdjustment(adjustment)).toFixed(2)} € + RFQ`}</span>
                                    }
                                  </div>
                                  :
                                  <div className={"dotsFlashing2Container"}>
                                    <div className={"dotsFlashing2"}/>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div style={{borderTop: "1px dotted white", marginTop: 10, paddingTop: 5, display: "flex", height: 40, alignItems: "center"}}>
                    <Button
                      className={"d6ButtonDark"}
                      size={"middle"}
                      onClick={() => {
                        setExpanded(prevState => prevState === 1 ? 0 : 1)
                        forceCheck()
                      }}
                    >
                      {item.isAnalysed ?
                        <span style={{ fontWeight: 600 }}>{expanded === 0 ? "Show Assembly Parts" : "Hide Assembly Parts"}</span>
                        :
                        <span style={{ fontWeight: 600 }}>{expanded === 0 ? "Show Assembly Progress" : "Hide Assembly Progress"}</span>
                      }
                    </Button>
                  </div>
                </div>
              </List.Item>
            </List>
          </Col>
        </Row>
      </Form>
      <Row style={{marginBottom: 10}}>
        <Col span={24}>
          <div className={"assemblyPartsContainer"}>
            <Collapse collapsible={"disabled"} className={"assemblyCollapse"} id={assemblyId} activeKey={expanded}>
              <Panel
                header={null}
                showArrow={false}
                key={1}
                style={{ backgroundColor: "#4d5c64"}}
              >
                {item.isAnalysed ?
                  <div>
                    <AssemblyPartsList
                      orderId={orderId}
                      assemblyId={assemblyId}
                      assemblyName={item.fileName}
                      assemblyItems={items}
                      selectedItems={selectedItems}
                      handleRemoveItem={handleRemoveItem}
                      sheetMetalMaterials={sheetMetalMaterials}
                      machiningMaterials={machiningMaterials}
                      tubeCuttingMaterials={tubeCuttingMaterials}
                      onCheck={onCheck}
                      changeSheetMetalMaterial={changeSheetMetalMaterial}
                      changeMachiningMaterial={changeMachiningMaterial}
                      changeTubeCuttingMaterial={changeTubeCuttingMaterial}
                      changePartThickness={changePartThickness}
                      changePartQuantity={changePartQuantity}
                      changePartNote={changePartNote}
                      changePartColor={changePartColor}
                      changePartTolerance={changePartTolerance}
                      changePartRoughness={changePartRoughness}
                      changeManualPartTolerance={changeManualPartTolerance}
                      changeManualPartRoughness={changeManualPartRoughness}
                      changeMaterialCertificate={changeMaterialCertificate}
                      changePartStandard={changePartStandard}
                      changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
                      changePrice={changePrice}
                      handleColorRemove={handleColorRemove}
                      showItem={showItem}
                      handleChangeItemType={handleChangeItemType}
                      bulkItem={bulkItem}
                      adjustment={adjustment}
                      openDocumentsView={openDocumentsView}
                      handleOpenPricesModal={handleOpenPricesModal}
                      assemblyCount={item.quantity}
                    />
                  </div>
                  :
                  <div style={{ marginTop: -8, marginBottom: 1 }}>
                    <Progress
                      className={"processingProgress"}
                      percent={assemblyProgressMap[assemblyId] ? Math.ceil((assemblyProgressMap[assemblyId] / item.numberOfUniqueParts) * 100) : 0}
                      status={"active"}
                    />
                  </div>
                }
              </Panel>
            </Collapse>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            className={"d6ButtonDark"}
            onClick={() => {
              setExpanded(prevState => prevState === 1 ? 0 : 1)
              forceCheck()
            }}
          >
            {item.isAnalysed ?
                <span style={{ fontWeight: 600 }}>{expanded === 0 ? "Show Assembly Parts" : "Hide Assembly Parts"}</span>
              :
                <span style={{ fontWeight: 600 }}>{expanded === 0 ? "Show Assembly Progress" : "Hide Assembly Progress"}</span>
            }
          </Button>
        </Col>
      </Row>
    </div>
  )
}