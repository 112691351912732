import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";
import {
  TUBE_CUTTING_FETCHING_MATERIAL_PRICES,
  TUBE_CUTTING_FETCHING_STANDARD_MATERIAL_PRICES,
  TUBE_CUTTING_SET_STANDARD_MATERIAL_PRICES,
  TUBE_CUTTING_UPDATE_STANDARD_MATERIAL_PRICE,
  TUBE_CUTTING_MACHINES_IS_CREATING_TUBE_CUTTING_MACHINE,
  TUBE_CUTTING_MACHINES_IS_DELETING_TUBE_CUTTING_MACHINE,
  TUBE_CUTTING_MACHINES_IS_EDITING_TUBE_CUTTING_MACHINE,
  TUBE_CUTTING_MACHINES_IS_FETCHING_MACHINE_PROPERTIES,
  TUBE_CUTTING_MACHINES_IS_FETCHING_TUBE_CUTTING_DATA,
  TUBE_CUTTING_MACHINES_SET_MACHINE_DATA,
  TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINE_PARAMETER,
  TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINES,
  TUBE_CUTTING_SET_CUTTING_SPEED,
  TUBE_CUTTING_SET_MACHINE_TOLERANCE,
  TUBE_CUTTING_SET_MATERIAL_PRICES,
  TUBE_CUTTING_SET_TUBE_CUTTING_SERVICE,
  TUBE_CUTTING_UPDATE_MATERIAL_PRICE
} from "./actionTypes";
import {formatTubeCuttingMachines} from "../utils/utility";

const setTubeCuttingService = (payload) => dispatch => {
  dispatch({
    type: TUBE_CUTTING_SET_TUBE_CUTTING_SERVICE,
    payload: payload.value
  })

  return http
    .put(`${API_BASE_URL}/tube-cutting`, {hasTubeCutting: payload.value})
    .then(() => {

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const fetchTubeCuttingData = () => dispatch => {
  dispatch({
    type: TUBE_CUTTING_MACHINES_IS_FETCHING_TUBE_CUTTING_DATA,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/tube-cutting`)
    .then(response => {
      const machines = formatTubeCuttingMachines(response.data.machines)

      dispatch({
        type: TUBE_CUTTING_SET_TUBE_CUTTING_SERVICE,
        payload: response.data.hasTubeCutting
      })

      dispatch({
        type: TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINES,
        payload: machines
      })

      dispatch({
        type: TUBE_CUTTING_MACHINES_IS_FETCHING_TUBE_CUTTING_DATA,
        payload: false
      })

      return machines
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: TUBE_CUTTING_MACHINES_IS_FETCHING_TUBE_CUTTING_DATA,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}
const fetchTubeCuttingMachineParameters = payload => dispatch => {
  dispatch({
    type: TUBE_CUTTING_MACHINES_IS_FETCHING_MACHINE_PROPERTIES,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/tube-cutting/${payload.id}/properties`)
    .then(response => {
      let data = response.data.groupNames.map( name => {
        let item = response.data.tables.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })

      let machine = {...response.data, tables: data}

      dispatch({
        type: TUBE_CUTTING_MACHINES_SET_MACHINE_DATA,
        payload: machine
      })

      dispatch({
        type: TUBE_CUTTING_MACHINES_IS_FETCHING_MACHINE_PROPERTIES,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: TUBE_CUTTING_MACHINES_IS_FETCHING_MACHINE_PROPERTIES,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const createTubeCuttingMachine = payload => dispatch => {

  dispatch({
    type: TUBE_CUTTING_MACHINES_IS_CREATING_TUBE_CUTTING_MACHINE,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/tube-cutting/create`, payload)
    .then(response => {

      dispatch({
        type: TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINES,
        payload: formatTubeCuttingMachines(response.data)
      })

      dispatch({
        type: TUBE_CUTTING_MACHINES_IS_CREATING_TUBE_CUTTING_MACHINE,
        payload: false
      })

      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: TUBE_CUTTING_MACHINES_IS_CREATING_TUBE_CUTTING_MACHINE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const editTubeCuttingMachine = payload => dispatch => {
  dispatch({
    type: TUBE_CUTTING_MACHINES_IS_EDITING_TUBE_CUTTING_MACHINE,
    payload: true
  })

  return http
    .put(`${API_BASE_URL}/tube-cutting/${payload.id}`, payload.data)
    .then(response => {

      dispatch({
        type: TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINES,
        payload: formatTubeCuttingMachines(response.data)
      })

      dispatch({
        type: TUBE_CUTTING_MACHINES_IS_EDITING_TUBE_CUTTING_MACHINE,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: TUBE_CUTTING_MACHINES_IS_EDITING_TUBE_CUTTING_MACHINE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const deleteTubeCuttingMachine = payload => dispatch => {
  dispatch({
    type: TUBE_CUTTING_MACHINES_IS_DELETING_TUBE_CUTTING_MACHINE,
    payload: true
  })
  return http
    .delete(`${API_BASE_URL}/tube-cutting/${payload.id}`)
    .then(response => {
      const machines = formatTubeCuttingMachines(response.data)
      dispatch({
        type: TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINES,
        payload: machines
      })

      dispatch({
        type: TUBE_CUTTING_MACHINES_IS_DELETING_TUBE_CUTTING_MACHINE,
        payload: false
      })

      return machines
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: TUBE_CUTTING_MACHINES_IS_DELETING_TUBE_CUTTING_MACHINE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })

}

const setTubeCuttingMachineParameter = (payload) => dispatch => {
  dispatch({
    type: TUBE_CUTTING_MACHINES_SET_TUBE_CUTTING_MACHINE_PARAMETER,
    payload: payload
  })
}

const setEmptyTubeCuttingMachineData = () => dispatch => {
  dispatch({
    type: TUBE_CUTTING_MACHINES_SET_MACHINE_DATA,
    payload: {}
  })
}

const updateTubeCuttingMachineSpeed = payload => dispatch => {
  dispatch({
    type: TUBE_CUTTING_SET_CUTTING_SPEED,
    payload: payload
  })
}

const fetchTubeMaterialPrices = () => dispatch => {
  dispatch({
    type: TUBE_CUTTING_FETCHING_MATERIAL_PRICES,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/tube-cutting/materials`)
    .then(response => {
      dispatch({
        type: TUBE_CUTTING_SET_MATERIAL_PRICES,
        payload: {
          hasTubeCutting: response.data.hasTubeCutting,
          availableMaterials: response.data.rows,
          allAvailable: response.data.allAvailable
        }
      })

      dispatch({
        type: TUBE_CUTTING_FETCHING_MATERIAL_PRICES,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: TUBE_CUTTING_FETCHING_MATERIAL_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}


const fetchStandardTubeMaterialPrices = () => dispatch => {
  dispatch({
    type: TUBE_CUTTING_FETCHING_STANDARD_MATERIAL_PRICES,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/tube-cutting/standard-tube-materials`)
    .then(response => {
      dispatch({
        type: TUBE_CUTTING_SET_STANDARD_MATERIAL_PRICES,
        payload: {
          hasTubeCutting: response.data.hasTubeCutting,
          standardTubeMaterialPrices: response.data.table
        }
      })

      dispatch({
        type: TUBE_CUTTING_FETCHING_STANDARD_MATERIAL_PRICES,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: TUBE_CUTTING_FETCHING_STANDARD_MATERIAL_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}


const updateTubeMaterialPrice = payload => dispatch => {
  dispatch({
    type: TUBE_CUTTING_UPDATE_MATERIAL_PRICE,
    payload: payload,
  })
}

const updateStandardTubeMaterialPrice = payload => dispatch => {
  dispatch({
    type: TUBE_CUTTING_UPDATE_STANDARD_MATERIAL_PRICE,
    payload: payload,
  })
}

const setTubeCuttingMachineTolerance = payload => dispatch => {
  dispatch({
    type: TUBE_CUTTING_SET_MACHINE_TOLERANCE,
    payload: payload
  })
}

export {
  setTubeCuttingService,
  fetchTubeCuttingData,
  fetchTubeCuttingMachineParameters,
  createTubeCuttingMachine,
  deleteTubeCuttingMachine,
  editTubeCuttingMachine,
  setTubeCuttingMachineParameter,
  setEmptyTubeCuttingMachineData,
  fetchTubeMaterialPrices,
  updateTubeMaterialPrice,
  fetchStandardTubeMaterialPrices,
  updateStandardTubeMaterialPrice,
  updateTubeCuttingMachineSpeed,
  setTubeCuttingMachineTolerance
}