import React, {useEffect, useState} from "react";

import {
  Button, Divider,
  Modal, Select,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

import "./style.less";
import {
  Assembly, BulkAndBuy,
  MillingAndLathing,
  Other, SheetMetal,
  StandardProfileAndTubeCutting
} from "./ManualQuotePartTypes";

const { Option } = Select;

const FirstStep = (props) => {
  return (
    <div>
      <div style={{marginBottom: 5, fontWeight: 500, color: "white"}}>Select the appropriate part type:</div>
      <Select
        defaultValue={props.manualQuoteType}
        onChange={value => props.setManualQuoteType(value)}
        style={{width: 200}}
        suffixIcon={<CaretDownOutlined/>}
        dropdownClassName={"orderItemMaterialSelectDropDown"}
      >
        <Option className={"orderItemMaterialSelectItem"} value={"sheetMetal"}>Sheet Metal</Option>
        <Option className={"orderItemMaterialSelectItem"} value={"milled"}>Milling Part</Option>
        <Option className={"orderItemMaterialSelectItem"} value={"lathed"}>Lathing Part</Option>
        <Option className={"orderItemMaterialSelectItem"} value={"tubeCut"}>Tube Cutting</Option>
        <Option className={"orderItemMaterialSelectItem"} value={"profile"}>Standard Profile</Option>
        <Option className={"orderItemMaterialSelectItem"} value={"assembly"}>Assembly</Option>
        <Option className={"orderItemMaterialSelectItem"} value={"buyPart"}>Buy Parts</Option>
        <Option className={"orderItemMaterialSelectItem"} value={"bulkMaterial"}>Bulk Material</Option>
        <Option className={"orderItemMaterialSelectItem"} value={"other"}>Other</Option>
      </Select>
      <Divider className={"lightDivider"} style={{ margin: "12px 0" }}/>
      <div align={"right"}>
        <Button
          type={"primary"}
          onClick={()=>props.setManualQuoteStep(2)}
          style={{fontWeight: 500, fontSize: 13}}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export const OrderDetailsManualQuote = (props) => {

  const {
    isManualQuoteModalVisible,
    item, handleClose, addNewPart,
    materials, isAddingNewPart, assemblyData
  } = props

  const [manualQuoteStep, setManualQuoteStep] = useState(1)
  const [manualQuoteType, setManualQuoteType] = useState(item ? item?.partType : "sheetMetal")
  const [fileData, setFileData] = useState(null)

  const handleModalClose = () => {
    handleClose()
  }

  useEffect(() => {
    setManualQuoteType(item ? item?.partType : "sheetMetal" )
  }, [item])

  useEffect(() => {
    if(isManualQuoteModalVisible) {
      setManualQuoteStep(1)
      setManualQuoteType(item ? item?.partType : "sheetMetal")
    }
  }, [isManualQuoteModalVisible])

  const step = () => {
    switch(manualQuoteStep) {

      case 1:
        return <FirstStep
          setManualQuoteType={setManualQuoteType}
          manualQuoteType={manualQuoteType}
          setManualQuoteStep={setManualQuoteStep}
        />;
      case 2: return itemType()
      case 3: return <div />;

      default: return <div>Error</div>
    }
  }

  const itemType = () => {
    switch(manualQuoteType) {

      case "sheetMetal":
        return <SheetMetal
          item={item}
          addNewPart={addNewPart}
          materials={materials}
          fileData={fileData}
          setManualQuoteStep={setManualQuoteStep}
          setFileData={setFileData}
          isAddingNewPart={isAddingNewPart}
          assemblyData={assemblyData}
          partType={manualQuoteType}
        />

      case "tubeCut":
      case "profile":
        return <StandardProfileAndTubeCutting
          item={item}
          addNewPart={addNewPart}
          materials={materials}
          setManualQuoteStep={setManualQuoteStep}
          isAddingNewPart={isAddingNewPart}
          assemblyData={assemblyData}
          partType={manualQuoteType}
        />;

      case "milled":
      case "lathed":
        return <MillingAndLathing
          item={item}
          addNewPart={addNewPart}
          materials={materials}
          setManualQuoteStep={setManualQuoteStep}
          isAddingNewPart={isAddingNewPart}
          assemblyData={assemblyData}
          partType={manualQuoteType}
        />

      case "buyPart":
      case "bulkMaterial":
        return <BulkAndBuy
          item={item}
          addNewPart={addNewPart}
          setManualQuoteStep={setManualQuoteStep}
          isAddingNewPart={isAddingNewPart}
          assemblyData={assemblyData}
          partType={manualQuoteType}
        />

      case "assembly":
        return <Assembly
          item={item}
          addNewPart={addNewPart}
          setManualQuoteStep={setManualQuoteStep}
          isAddingNewPart={isAddingNewPart}
          assemblyData={assemblyData}
          partType={manualQuoteType}
        />;

      case "other":
        return <Other
          item={item}
          addNewPart={addNewPart}
          setManualQuoteStep={setManualQuoteStep}
          isAddingNewPart={isAddingNewPart}
          assemblyData={assemblyData}
          partType={manualQuoteType}
        />;

      default: return <div>Error</div>
    }
  }

  const widthBasedOnType = (type) => {
    switch(type) {
      case "sheetMetal":
        return 750
      case "tubeCut":
      case "profile":
      case "milled":
      case "lathed":
      case "assembly":
        return 640
      case "buyPart":
      case "bulkMaterial":
      case "other":
        return 456
    }
  }

  const getWidth = () => {
    switch(manualQuoteStep) {
      case 1:   return 400;
      case 2:   return widthBasedOnType(manualQuoteType);
      case 3:   return 400;
      default:  return 400;
    }
  }

  const getModalTitle = () => {
    if (manualQuoteStep === 1) {
      return "Manual Quote"
    } else {
      switch(manualQuoteType) {
        case "sheetMetal":
          return "Manual Quote | Sheet Metal Part"
        case "tubeCut":
          return "Manual Quote | Tube Cutting Part"
        case "profile":
          return "Manual Quote | Standard Profile Part"
        case "milled":
          return "Manual Quote | Milling Part"
        case "lathed":
          return "Manual Quote | Lathing Part"
        case "buyPart":
          return "Manual Quote | Buy Part"
        case "bulkMaterial":
          return "Manual Quote | Bulk Material Part"
        case "assembly":
          return "Manual Quote | Assembly Part"
        case "other":
          return "Manual Quote | Other"
        default:
          return "Manual Quote"
      }
    }
  }

  return (
    <div>
      <Modal
        className={"manualPartModal"}
        bodyStyle={{ padding: "16px" }}
        visible={isManualQuoteModalVisible}
        style={{ top: 200 }}
        onCancel={handleModalClose}
        title={getModalTitle()}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        width={getWidth()}
      >
        { step() }
      </Modal>
    </div>

  )
}