import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getToleranceValue, useWindowSize} from "../../../../../utils/utility";
import {
  fetchProducerTubeCuttingData,
  fetchProducerTubeCuttingMachineProperties,
} from "../../../../../actions/producerParamsActions";
import {Button, Card, Col, Divider, Input, Row, Spin, Switch, Tabs} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {lineBreakLimit} from "../../../../../constants";
import {MachineSelect, MachineTolerancesModal} from "../../../MachineSelect";
import {ProducerMaterialInput} from "../../NonEditableFields";
import MaterialTableContainer from "../../../CompanyMaterials/MaterialTableContainer";
import {ValueTable} from "../../../CompanyMaterials/MaterialTables/ValueTable";

const {TabPane} = Tabs;

export const TubeCuttingTab = (props) => {

  const {selectedTab, producerId} = props;

  const tubeCuttingData = useSelector(state => state.producerParams.tubeCuttingData)
  const isFetchingTubeCuttingData = useSelector(state => state.producerParams.isFetchingTubeCuttingData)
  const isFetchingSelectedTubeCuttingMachine = useSelector(state => state.producerParams.isFetchingSelectedTubeCuttingMachine)
  const machineData = useSelector(state => state.producerParams.selectedTubeCuttingMachine)
  const alreadyFetchedTubeCuttingService = useSelector(state => state.producerParams.alreadyFetchedTubeCuttingService)

  const [fetchingAll, setFetchingAll] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [shouldUpdateSpeeds, setShouldUpdateSpeeds] = useState(false)
  const [machineTolerancesModalVisible, setMachineTolerancesModalVisible] = useState(false)

  const [rowFlex, setRowFlex] = useState(false);

  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width >= 1153 && windowSize.width <= 1426) {
      setRowFlex(true)
    } else {
      setRowFlex(false);
    }
  }, [windowSize.width])

  const fetchData = () => {
    setFetchingAll(true)
    dispatch(fetchProducerTubeCuttingData({producerId, alreadyFetchedTubeCuttingService})).then((machines) => {
      if (machines.length > 0) {
        if (selectedMachine) {
          let doesExist = machines.find(m => {
            return m?.machines.find(item => item.id === selectedMachine)
          })
          if (doesExist) {
            dispatch(fetchProducerTubeCuttingMachineProperties({producerId, machineId: selectedMachine})).then(() => {
              setShouldUpdateSpeeds(true)
              setFetchingAll(false)
            })
          } else {
            setSelectedMachine(machines[0].machines[0].id)
            dispatch(fetchProducerTubeCuttingMachineProperties({producerId, machineId: machines[0].machines[0].id}))
            setFetchingAll(false)
          }
          setFetchingAll(false)
        } else {
          setSelectedMachine(machines[0].machines[0].id)
          dispatch(fetchProducerTubeCuttingMachineProperties({producerId, machineId: machines[0].machines[0].id}))
          setFetchingAll(false)
        }
      } else {
        setFetchingAll(false)
      }
    })
  }

  useEffect(() => {
    if (selectedTab === "5") {
      fetchData()
    }
  }, [selectedTab])

  const changeSelectedMachine = (id) => {
    setSelectedMachine(id)
    dispatch(fetchProducerTubeCuttingMachineProperties({producerId, machineId: id})).then(() => {
      setShouldUpdateSpeeds(true)
    })
  }

  const handleMachineTolerancesOpen = () => {
    setMachineTolerancesModalVisible(true)
  }

  const handleMachineTolerancesClose = () => {
    setMachineTolerancesModalVisible(false)
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0, paddingBottom: 15}}>
        <div style={{height: 'calc(100vh - 172px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          <Row>
            <Col xl={14} span={24}>
              <div className={"switchContainer"}>
                <div>
                  <span style={{marginRight: 30, marginBottom: 6}}>Producer offers Machining service?</span>
                  <span>No</span>
                  <Switch
                    className={"producersUneditableSwitch"}
                    checked={tubeCuttingData?.hasTubeCutting}
                    style={{marginLeft: 6, marginRight: 6}}
                    disabled={true}
                  />
                  <span>Yes</span>
                </div>
              </div>
            </Col>
          </Row>
          <Divider style={{width: "75%", minWidth: "0%", margin: "14px 0 12px 0"}}/>
          {tubeCuttingData?.hasTubeCutting &&
            <Spin spinning={fetchingAll || isFetchingSelectedTubeCuttingMachine || isFetchingTubeCuttingData}
                  indicator={<LoadingOutlined style={{fontSize: 72}} spin/>}
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: machineData?.id ? "41px" : "36%",
                    left: "-10px",
                  }}
            >
              {machineData && Object.keys(machineData).length > 0 &&
              <div>
                <div className={windowSize.width > lineBreakLimit ? "inLine" : "newLIne"}>
                  <div className={"customColumn2"} style={{width: "100%", maxWidth: 1605}}>
                    <div
                      style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'flex-end'}}>
                      <MachineSelect
                        type={"tubeCutting"}
                        isFetchingMachines={isFetchingTubeCuttingData}
                        selectedMachine={selectedMachine}
                        changeSelectedMachine={changeSelectedMachine}
                        machines={tubeCuttingData.tubeCuttingMachines}
                        isFetchingMachineTypes={false}
                        handleEditMachine={() => {}}
                        handleRemoveMachine={() => {}}
                        handleAddMachineOpen={() => {}}
                        isFetchingMachinePriorities={false}
                        handleMachinePriorityOpen={() => {}}
                        machinePriorities={[]}
                        allowPriorityChange={false}
                        allowAddMachine={false}
                        hasTolerances={selectedMachine || false}
                        isFetchingMachineTolerances={isFetchingSelectedTubeCuttingMachine}
                        handleMachineTolerancesOpen={handleMachineTolerancesOpen}
                        showButtons={false}
                      />
                    </div>
                  </div>
                </div>
                <Divider style={{width: "75%", minWidth: "0%", margin: "1px 0 16px 0"}}/>
                <div className={windowSize.width > lineBreakLimit ? "inLine" : "newLIne"}>
                  <div className={"customColumn3"}>
                    <div className={"column3CardContainer"}>
                      <Card style={{marginBottom: 16}} bodyStyle={{padding: "8px 16px"}}>
                        <ProducerMaterialInput
                          style={{marginBottom: 12}}
                          label={"Maximum tube length"}
                          _labelWidth={150}
                          value={machineData.maxTubeLength}
                          suffix={"mm"}
                        />
                        <ProducerMaterialInput
                          label={"Maximum tube width"}
                          _labelWidth={150}
                          value={machineData.maxTubeWeight}
                          suffix={"kg/m"}
                        />
                      </Card>
                    </div>
                    <Tabs style={{marginLeft: -10, marginBottom: 20}}
                          type="card"
                          className={"laserSpeedTabs"}
                    >
                      {machineData.tables.map((item, index) => (
                        <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                          <MaterialTableContainer tableText={"Cutting speed by Tube Grade and Tube Thickness (m/min)"}
                                                  xAxisText={"Available Thickness (mm)"}
                                                  yAxisText={"Tube Grade"}
                                                  xAxisStyle={{marginTop: 12, marginBottom: -12}}
                          >
                            <ValueTable
                              columns={item.columns}
                              rows={item.rows}
                              allAvailable={item.allAvailable}
                              tabIndex={index}
                              onChange={() => {}}
                              updateTable={shouldUpdateSpeeds}
                              setUpdateTable={setShouldUpdateSpeeds}
                              activeLoadingTabs={{}}
                              leftColumnWidth={200}
                              referenceHeight={248}
                              isEditable={false}
                            />
                          </MaterialTableContainer>
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                  <div className={`${rowFlex ? "customColumn5" : "customColumn4"}`}>
                    <Card bodyStyle={{padding: "16px"}}>
                      <div style={{
                        display: "flex",
                        flexDirection: rowFlex ? "row" : "column",
                        height: "100%",
                        width: "100%",
                        alignItems: "flex-start"
                      }}>
                        <div style={{width: "100%", maxWidth: 430}}>
                          <div className={"producerDetailEntry"}>
                            <span className={"producerMaterialLabel"}>
                              Cutting price:
                            </span>
                            <Input
                              style={{maxWidth: 100}}
                              value={machineData.cuttingPrice}
                              disabled
                            />
                            <span style={{marginLeft: 12}}>€/h</span>
                          </div>

                          <Divider className={"laserDivider"} style={{marginBottom: 18}}/>

                          <div className={"producerDetailEntry"}>
                            <span className={"producerMaterialLabel"}>
                              Preparation price:
                            </span>
                            <Input
                              style={{maxWidth: 100}}
                              value={machineData.engineerPricePerHour}
                              disabled
                            />
                            <span style={{marginLeft: 12}}>€/h</span>
                          </div>
                          <div align={'right'}>
                            <div className={"producerDetailEntry"} style={{justifyContent: "flex-end"}}>
                              <span className={"producerMaterialLabel"}>
                                Average preparation time:
                              </span>
                              <Input
                                style={{maxWidth: 100}}
                                value={machineData.averagePreparationTime}
                                disabled
                              />
                              <span style={{marginLeft: 12}}>min</span>
                            </div>
                          </div>
                          {!rowFlex && <Divider className={"laserDivider"} style={{marginTop: 15, marginBottom: 15}}/>}
                        </div>
                        {rowFlex && <Divider type={"vertical"} style={{height: "180px", margin: "0 10px"}}/>}
                        <div>
                          <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                            <div className={"producerDetailEntry"}>
                              <span className={"producerMaterialLabel"} style={{width: 150}}>
                                Average piercing time:
                              </span>
                              <div style={{
                                display: "flex",
                                flexDirection: "column",
                                height: 74,
                                justifyContent: "space-between",
                                paddingTop: 4
                              }}>
                                <div>
                                  <Input
                                    style={{maxWidth: 70}}
                                    value={machineData.pierceTimeLow}
                                    disabled
                                  />
                                  <span style={{marginLeft: 12}}>sec</span>
                                  <span style={{marginLeft: 17}}>for thickness</span>
                                  <span style={{marginLeft: 14}}>≤</span>
                                </div>
                                <div>
                                  <Input
                                    style={{maxWidth: 70}}
                                    value={machineData.pierceTimeHigh}
                                    disabled
                                  />
                                  <span style={{marginLeft: 12}}>sec</span>
                                  <span style={{marginLeft: 17}}>for thickness</span>
                                  <span style={{marginLeft: 14}}>></span>
                                </div>
                              </div>
                              <div style={{marginTop: 2}}>
                                <Input
                                  style={{maxWidth: 70, marginLeft: 14}}
                                  value={machineData.pierceTimeThreshold}
                                  disabled
                                />
                                <span style={{marginLeft: 12}}>mm</span>
                              </div>
                            </div>
                          </div>
                          <Divider className={"laserDivider"} style={{marginBottom: 18}}/>
                          <div style={{ width: "100%", minWidth: 0}}>
                            <div className={"producerDetailEntry"}>
                              <ProducerMaterialInput
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  paddingRight: 0
                                }}
                                inputWidth={200}
                                label={"Maximum Tolerance"}
                                value={getToleranceValue(machineData.maxTolerance, "other")}
                                suffix={""}
                              />
                            </div>
                            <div style={{ marginTop: 8, display: "flex", width: "100%", justifyContent: "center"}}>
                              <Button
                                className={"toleranceButton d6ButtonDark"}
                                style={{fontSize: 13}}
                                onClick={() => handleMachineTolerancesOpen()}
                                disabled={false}
                              >
                                <span style={{fontWeight: 600}}>View Tolerance Markup</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
              }
            </Spin>
          }
        </div>
      </Card>

      <MachineTolerancesModal
        machineType={"tubeCutting"}
        title={"Machine Tolerance Markup"}
        modalVisible={machineTolerancesModalVisible}
        handleCloseModal={handleMachineTolerancesClose}
        isLoading={isFetchingSelectedTubeCuttingMachine}
        markupData={machineData?.markup}
        allowTolerancesChange={false}
      />

    </React.Fragment>
  )
}