import React from "react";
import {Divider as MiDivider} from "@material-ui/core";
import {Button, Tooltip} from "antd";

export const BulkOrderHeader = (props) => {

  const {
    largeThreshold, allItemsCount,
    openClearOrder, checkIfPriceIsZero,
    setExistsZeroPrice, setIsArchiveModalVisible,
    isProducer
  } = props;

  return (
    <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
      <div className={"pageTitle"}>
        Internal Calculation
      </div>
      <div style={{marginRight: 20, display: 'flex'}}>
        <MiDivider
          orientation="vertical"
          flexItem
          className={"vertMiDiv"}
          style={{display: largeThreshold ? 'none' : 'block',}}
        />
        <div style={{marginLeft: 20}}>
          <Button
            className={"clearOrderButton"}
            disabled={allItemsCount === 0}
            onClick={() => {
              openClearOrder()
            }}
          >
            <span style={{fontSize: 14, fontWeight: 600}}>
              Clear Calculation
            </span>
          </Button>
        </div>
        {isProducer ?
          <div>

          </div>
          :
          <div style={{marginLeft: 20}}>
            {allItemsCount < 1 ? (
              <Tooltip
                overlayClassName={"adminTooltip"}
                placement={'bottomRight'}
                overlayStyle={{width: 300}}
                getPopupContainer={trigger => trigger.parentNode}
                title={
                  <span
                    className={"text12-500"}
                    style={{color: "white", fontSize: 13}}
                  >
                    Internal Calculation has no parts. Upload your parts to add them.
                  </span>
                }
              >
                <Button type={"primary"}
                        disabled={true}
                >
                  <span style={{fontSize: 14, fontWeight: 600}}>Store This Calculation</span>
                </Button>
              </Tooltip>
            ) : (
              <Button type={"primary"}
                      onClick={() => {
                        setExistsZeroPrice(checkIfPriceIsZero())
                        setIsArchiveModalVisible(true)
                      }}
              >
                <span style={{fontSize: 14, fontWeight: 600}}>Store This Calculation</span>
              </Button>
            )}
          </div>
        }
      </div>
    </div>
  )
}