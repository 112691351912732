import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from "react";

export function Icon2D(props) {
    return (
        <SvgIcon {...props}>
          <g stroke="currentColor" fill="none">
            <path d="M5.093 8.157a1.774 1.774 0 113.547 0c0 1.423-1.555 2.542-1.985 2.919-1.238 1.087-1.562 2.613-1.562 2.613H8.64v-.896M11.717 13.689h-.46V6.383h.46a3.653 3.653 0 010 7.306z"/>
            <path d="M14.753 19.147H5.11a4.395 4.395 0 01-4.396-4.396V5.11A4.396 4.396 0 015.11.714h9.643a4.395 4.395 0 014.394 4.396v9.641a4.394 4.394 0 01-4.394 4.396z"/>
          </g>
        </SvgIcon>
    );
}