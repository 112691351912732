import React, { useState } from "react";
import {Button, Card, Spin} from "antd";
import "./style.less";
import {CheckOutlined, CloseOutlined, LoadingOutlined} from "@ant-design/icons";

export const PendingCustomerCard = (props) => {
  const {
    item, itemCount, count,
    onCustomerAuth
  } = props;

  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className={'customerItem'}>
      {count < itemCount &&
      <Spin
        spinning={isLoading}
        indicator={
          <div align={"center"}>
            <LoadingOutlined style={{ fontSize: 32, marginTop: -8 }} spin />
          </div>
        }
      >
        <Card
          className={"pendingCustomerCard fadeInCustomerCard"}
          bodyStyle={{height: "100%", width: "100%", padding: 6}}
          onClick={() => {}}
        >
          <div style={{display: "flex"}}>
            <div
              className={"pendingNameIcon"}
              style={{backgroundColor: item.role === "regularCustomer" ? "#1c84e0" : "#9966cc"}}>
              {item.name.charAt(0).toUpperCase()}
            </div>
            <table style={{width: "100%"}}>
              <tbody>
              <tr>
                <td style={{width: "100%", maxWidth: 50}}>
                  <div
                    className={"customerGridText customerName"}
                    style={{
                      fontSize: 13,
                      fontWeight: 600,
                      lineHeight: "13px",
                      width: "100%"
                    }}
                  >
                    {item.name}
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{width: "100%", maxWidth: 50}}>
                  <div
                    className={"customerGridText"}
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: "14px",
                      width: "100%"
                    }}
                  >
                    {item.email}
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{width: "100%", maxWidth: 50}}>
                  <div
                    className={"customerGridText"}
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: "14px",
                      width: "100%"
                    }}
                  >
                    <span style={{color: "#bbb  ", fontWeight: 600}}>{item.role === "regularCustomer" ? "Private Customer" : "Corporate Customer"}</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              height: "45px",
            }}>
              <Button
                size={"small"}
                onClick={() => {
                  setIsLoading(true)
                  onCustomerAuth({
                    customerId: item.id,
                    isAuthorized: true
                  })
                }}
              >
                <CheckOutlined style={{color: "#6bce34"}}/>
              </Button>
              <Button
                type="danger"
                size={"small"}
                style={{marginLeft: 2}}
                onClick={() => {
                  setIsLoading(true)
                  onCustomerAuth({
                    customerId: item.id,
                    isAuthorized: false
                  })
                }}
                >
                <CloseOutlined />
              </Button>
            </div>
          </div>
        </Card>
      </Spin>
      }
    </div>
  )
}