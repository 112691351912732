import React from "react";
import {
  canHaveCoating,
  formatDate, getCoatingType, getColorName,
  getManualType, getRoughnessValue, getToleranceValue, getTotalLathingSuggestedPrice,
  getTotalMillingSuggestedPrice,
  getTotalSheetMetalSuggestedPrice, getTotalTubeCuttingSuggestedPrice, getWidths
} from "../../../utils/utility";
import writeXlsxFile from 'write-excel-file'
import {FileExcelOutlined} from "@ant-design/icons";
import {Button} from "antd";
import "./style.css";
import {translator} from "../../../translations/translators";
import {useSelector} from "react-redux";
import {getTubeData} from "../../../utils/partCardUtils/sections";

export const OrderExcelExportButton = (props) => {
  const { isEditing, parts, name, role, adjustment, collapsed, squared } = props;

  const producersForOrder = useSelector(state => state.orders.producersForOrder)

  const getState = (partState) => {
    if (partState === "declined")
      return "Rejected";
    else if (partState === "edited" || partState === "processed")
      return "Processed"
  }

  const exportWithWEF = () => {
    const fileName = name + "_" + formatDate(Date.now());
    const headerStyle = {
      backgroundColor: "#dee7e5",
      borderColor: "#000000",
      borderStyle: "thin",
      fontWeight: 600,
      fontSize: 9,
      color: "#000000",
      align: "left"
    }

    const prepPartData = (parts) => {
      let tmpParts = []
      let assemblies = {}
      let assemblyQuantities = {}
      let assemblyPrices = {}
      for(let part of parts) {
        if(!(part.fileName || part.name)) {
          continue
        }
        if(part.assemblyId) {
          let price = 0;
          if(part.partType === "sheetMetal") {
            price = getTotalSheetMetalSuggestedPrice(part.operationPrices, part.quantity) * part.quantity
          } else if(part.partType === "milled") {
            price = getTotalMillingSuggestedPrice(part.operationPrices, part.quantity) * part.quantity
          } else if(part.partType === "lathed") {
            price = getTotalLathingSuggestedPrice(part.operationPrices, part.quantity) * part.quantity
          } else if(part.partType === "tubeCut") {
            price = getTotalTubeCuttingSuggestedPrice(part.operationPrices, part.quantity) * part.quantity
          }
          if(assemblies[part.assemblyId]) {
            assemblies[part.assemblyId].push(part)
            assemblyPrices[part.assemblyId] += price
          } else {
            assemblies[part.assemblyId] = [part]
            assemblyPrices[part.assemblyId] = price
          }
        } else {
          tmpParts.push(part)
        }
      }
      let preppedParts = []
      for(let part of tmpParts) {
        preppedParts.push(part)
        if(part.partType === "assembly") {
          assemblyQuantities[part.id] = part.quantity
          preppedParts.push(...(assemblies[part.id] || []))
        }
      }
      return { preppedParts, assemblyQuantities, assemblyPrices }
    }

    const { preppedParts, assemblyQuantities, assemblyPrices } = prepPartData(parts)

    if (role === "regularCustomer" || role === "corporateCustomer") {
      const objects = preppedParts.map(item => {
        let name = (item.fileName || item.name)
        let profileType = "/"
        if(item.partType === "profile" || item.partType === "tubeCut") {
          if(item.isTubeCut) {
            let tubeData = getTubeData({item})
            profileType = `${tubeData[0]} / ${tubeData[1]} / ${tubeData[2]}`
          } else {
            profileType = item.standard || "/"
          }
        }

        let area = "/";
        let weight = "/";
        if(item.fileType === "step") {
          area = item.stepData?.partData?.area ? (item.stepData.partData.area / 1000000).toFixed(3) : "/"
          if(item.stepData?.partData?.volume) {
            let volume = item.stepData.partData.volume / 1000000000
            if(item.partType === 'tubeCut' && item.isTubeCut) {
              weight = ((item.selectedMaterial?.density || 0) * item.stepData.partData.tubeData.tubeLength / 1000).toFixed(3) || "/"
            } else {
              weight = ((item.selectedMaterial?.density || 0) * volume).toFixed(3) || "/"
            }
          } else {
            weight = "/"
          }
        }
        if(item.fileType === "dxf") {
          area = item.dxfData?.partData?.area ? (item.dxfData.partData.area / 1000000).toFixed(3) : "/"
          if(item.dxfData?.partData?.area) {
            let volume = (item.dxfData.partData.area * item.selectedMaterial?.thickness) / 1000000000
            weight = ((item.selectedMaterial?.density || 0) * volume).toFixed(3) || "/"
          } else {
            weight = "/"
          }
        }

        let tolerance = (item.processingType === 'automatic' ? item.selectedTolerance?.tolerance : item.manualTolerance) || null
        let roughness = (item.processingType === 'automatic' ? item.selectedRoughness?.roughness : item.manualRoughness) || null

        return {
          fileName: (item.assemblyId ? "-    " : "") + name.substring(0, name.lastIndexOf(".")),
          fileType: item.fileType ? (item.fileType).charAt(0).toUpperCase() + (item.fileType).slice(1) : "Manual",
          partType: getManualType(item.partType),
          profileType: profileType,
          oem: (item.partType === "bulkMaterial" || item.partType === "buyPart") ? item.standard : "/",
          material: translator(item.selectedMaterial?.grade) || "/",
          matCertNeeded: item.certNeeded ? "Yes" : "No",
          thickness: item.selectedMaterial?.thickness || "/",
          area: area,
          weight: weight,
          hasCoating: item.coating?.hasCoating ? "Yes" : "No",
          coatingType: getCoatingType(item.coating),
          coatingColor: (item.coating?.hasCoating && item.coating.type === "painting") ? getColorName({color: item.coating?.data?.colorRAL}) : "/",
          tolerance: tolerance ? getToleranceValue(tolerance, (item.partType === 'milled' || item.partType === 'lathed') ? "machined" : "other") : "/",
          roughness: roughness ? getRoughnessValue(roughness) : "/",
          quantity: item.quantity,
          pricePerPiece: item.pricePerPiece.toFixed(3),
          totalPrice: (item.pricePerPiece * item.quantity).toFixed(3),
          note: item.note || "/"
        }
      })

      const schema = [
        {
          column: "Part name:",
          value: part => part.fileName || part.name,
          align: "left",
        },
        {
          column: "File type:",
          value: part => part.fileType,
          align: "left",
        },
        {
          column: "Part type:",
          value: part => part.partType,
          align: "left",
        },
        {
          column: "Profile type:",
          value: part => part.profileType,
          align: "left",
        },
        {
          column: "OEM:",
          value: part => part.oem,
          align: "left",
        },
        {
          column: "MAT:",
          value: part => part.material,
          align: "left",
        },
        {
          column: "MTR/MTC 3.1:",
          value: part => part.matCertNeeded,
          align: "left",
        },
        {
          column: "QTY:",
          value: part => part.quantity,
          align: "right",
        },
        {
          column: "Area (m²):",
          value: part => part.area,
          align: "left",
        },
        {
          column: "Weight (kg):",
          value: part => part.weight,
          align: "left",
        },
        {
          column: "THK (mm):",
          value: part => part.thickness,
          align: "right",
        },
        {
          column: "Has Surface Finish:",
          value: part => part.hasCoating,
          align: "left",
        },
        {
          column: "Surface Finish Type:",
          value: part => part.coatingType,
          align: "left",
        },
        {
          column: "Surface Finish color:",
          value: part => part.coatingColor,
          align: "left",
        },
        {
          column: "Max Tolerance:",
          value: part => part.tolerance,
          align: "right",
        },
        {
          column: "Max Roughness:",
          value: part => part.roughness,
          align: "right",
        },
        {
          column: "PPU (€):",
          value: part => part.pricePerPiece,
          align: "right",
        },
        {
          column: "TC (€):",
          value: part => part.totalPrice,
          align: "right",
        },
        {
          column: "Note:",
          value: part => part.note,
          align: "left",
        },
      ]
      const widths = getWidths(schema, objects)
      const schemaWithWidth = schema.map((item, index) => {
        return {
          ...item,
          width: widths[index]
        }
      })

      writeXlsxFile(objects, {
        schema: schemaWithWidth,
        fileName: fileName + ".xlsx",
        headerStyle: headerStyle,
        fontSize: 8
      }).then(()=>{})

    }
    else if (role === "gelsoAdmin" || role === "gelsoSubAdmin" || role === "producerAdmin") {
      const objects = preppedParts.map(item => {
        let area = "/";
        let volume = "/";
        let weight = "/";
        if(item.fileType === "step") {
          area = item.stepData?.partData?.area ? (item.stepData.partData.area / 1000000).toFixed(3) : "/"
          if(item.stepData?.partData?.volume) {
            volume = item.stepData.partData.volume / 1000000000
            if(item.partType === 'tubeCut' && item.isTubeCut) {
              weight = ((item.selectedMaterial?.density || 0) * item.stepData.partData.tubeData.tubeLength / 1000).toFixed(3) || "/"
            } else {
              weight = ((item.selectedMaterial?.density || 0) * volume).toFixed(3) || "/"
            }
            volume = (volume * 1000).toFixed(3)
          } else {
            volume = "/"
            weight = "/"
          }
        }
        if(item.fileType === "dxf") {
          area = item.dxfData?.partData?.area ? (item.dxfData.partData.area / 1000000).toFixed(3) : "/"
          if(item.dxfData?.partData?.area) {
            volume = (item.dxfData.partData.area * item.selectedMaterial?.thickness) / 1000000000
            weight = ((item.selectedMaterial?.density || 0) * volume).toFixed(3) || "/"
            volume = (volume * 1000).toFixed(3)
          } else {
            volume = "/"
            weight = "/"
          }
        }

        let thickness = item.selectedMaterial?.thickness || "/"
        let numberOfBends;
        let numOfSlopedEdges;
        let numOfMachinedHoles;

        let bendingPricePerPiece;
        let machiningPricePerPiece;
        let cuttingPricePerPiece;
        let suggestedPricePerPiece = 0;
        let millingPricePerPiece;
        let lathingPricePerPiece;
        let tubeCuttingPricePerPiece;

        let assemblingPricePerPiece;
        let weldingPricePerPiece;
        let packagingPricePerPiece;
        let transportationPricePerPiece;

        const quantity = item.quantity * (assemblyQuantities[item.assemblyId] || 1)
        const materialPrice = (item.operationPrices?.materialPricePerPiece) || 0
        let coatingPricePerPiece = canHaveCoating(item) ? ((item.operationPrices?.coatingPricePerPiece) || 0).toFixed(3) : "/"
        if(item.partType === "sheetMetal") {
          numberOfBends = item.stepData?.partData?.numberOfBends
          numOfSlopedEdges = item.stepData?.partData?.numOfSlopedEdges
          numOfMachinedHoles =  item.stepData?.partData?.numOfMachinedHoles

          bendingPricePerPiece = ((item.operationPrices.bendingPricePerPiece * quantity + item.operationPrices?.bendingPreparationPrice) / quantity || 0).toFixed(3)
          machiningPricePerPiece = ((item.operationPrices.machiningPricePerPiece * quantity + item.operationPrices?.machiningPreparationPrice) / quantity || 0).toFixed(3)
          cuttingPricePerPiece = ((item.operationPrices?.cuttingPricePerPiece * quantity + item.operationPrices?.cuttingPreparationPrice) / quantity || 0).toFixed(3)
          suggestedPricePerPiece = getTotalSheetMetalSuggestedPrice(item.operationPrices, quantity) || 0
        } else {
          numberOfBends = "/"
          numOfSlopedEdges = "/"
          numOfMachinedHoles =  "/"

          bendingPricePerPiece = "/"
          machiningPricePerPiece = "/"
          cuttingPricePerPiece = "/"
        }

        if(item.partType === "milled") {
          millingPricePerPiece = ((item.operationPrices?.millingPricePerPiece * quantity + item.operationPrices?.millingPreparationPrice) / quantity || 0).toFixed(3)
          suggestedPricePerPiece = getTotalMillingSuggestedPrice(item?.operationPrices, quantity) || 0
          thickness = "/"
        } else {
          millingPricePerPiece = "/"
        }

        if(item.partType === "lathed") {
          lathingPricePerPiece = ((item.operationPrices?.lathingPricePerPiece * quantity + item.operationPrices?.lathingPreparationPrice) / quantity || 0).toFixed(3)
          suggestedPricePerPiece = getTotalLathingSuggestedPrice(item?.operationPrices, quantity) || 0
          thickness = "/"
        } else {
          lathingPricePerPiece = "/"
        }

        if(item.partType === 'tubeCut') {
          tubeCuttingPricePerPiece = ((item.operationPrices?.tubeCuttingPricePerPiece * quantity + item.operationPrices?.tubeCuttingPreparationPrice) / quantity || 0).toFixed(3)
          suggestedPricePerPiece = getTotalTubeCuttingSuggestedPrice(item?.operationPrices, quantity) || 0
          thickness = "/"
        } else {
          tubeCuttingPricePerPiece = "/"
        }

        if (item.partType === "assembly") {
          assemblingPricePerPiece = (item.assemblyPrice?.assemblingPrice || item.assemblingPrice || 0).toFixed(3)
          weldingPricePerPiece = (item.assemblyPrice?.weldingPrice || item.weldingPrice || 0).toFixed(3)
          packagingPricePerPiece = (item.assemblyPrice?.packagingPrice || item.packagingPrice || 0).toFixed(3)
          transportationPricePerPiece = (item.assemblyPrice?.transportationPrice || item.transportationPrice || 0).toFixed(3)
          if (item.processingType !== "manual") {
            if (Object.keys(assemblyPrices).length > 0) {
              suggestedPricePerPiece = assemblyPrices[item.id] + item.productionPrice
            } else {
              suggestedPricePerPiece = "/" // This happens when the assembly is empty
            }
          }
        } else {
          assemblingPricePerPiece = "/"
          weldingPricePerPiece = "/"
          packagingPricePerPiece = "/"
          transportationPricePerPiece = "/"
        }

        let name = (item.fileName || item.name)

        let profileType = "/"
        if(item.partType === "profile" || item.partType === "tubeCut") {
          if(item.isTubeCut) {
            let tubeData = getTubeData({item})
            profileType = `${tubeData[0]} / ${tubeData[1]} / ${tubeData[2]}`
          } else {
            profileType = item.standard || "/"
          }
        }

        let tolerance = (item.processingType === 'automatic' ? item.selectedTolerance?.tolerance : item.manualTolerance) || null
        let roughness = (item.processingType === 'automatic' ? item.selectedRoughness?.roughness : item.manualRoughness) || null

        return {
          fileName: (item.assemblyId ? "-    " : "") + name.substring(0, name.lastIndexOf(".")),
          producedBy: producersForOrder[item.selectedMaterial?.producerId]?.name || "Gelso",
          fileType: item.fileType ? (item.fileType).charAt(0).toUpperCase() + (item.fileType).slice(1) : "Manual",
          partType: getManualType(item.partType),
          processing: (item.processingType?.charAt(0).toUpperCase() || "") + (item.processingType?.slice(1) || "/"),
          profileType: profileType,
          oem: (item.partType === "bulkMaterial" || item.partType === "buyPart") ? item.standard : "/",
          material: translator(item.selectedMaterial?.grade) || "/",
          materialPrice: item.selectedMaterial?.grade ? materialPrice.toFixed(3) : "/",
          matCertNeeded: item.certNeeded ? "Yes" : "No",
          thickness: thickness,
          area: area,
          volume: volume,
          weight: weight,
          quantity: item.quantity,
          tolerance: tolerance ? getToleranceValue(tolerance, (item.partType === 'milled' || item.partType === 'lathed') ? "machined" : "other") : "/",
          roughness: roughness ? getRoughnessValue(roughness) : "/",
          numberOfBends: numberOfBends,
          bendingPricePerPiece: bendingPricePerPiece,
          hasCoating: item.coating?.hasCoating ? "Yes" : "No",
          coatingType: getCoatingType(item.coating),
          coatingColor: (item.coating?.hasCoating && item.coating.type === "painting") ? getColorName({color: item.coating?.data?.colorRAL}) : "/",
          coatingPricePerPiece: coatingPricePerPiece,
          assemblingPricePerPiece: assemblingPricePerPiece,
          weldingPricePerPiece: weldingPricePerPiece,
          packagingPricePerPiece: packagingPricePerPiece,
          transportationPricePerPiece: transportationPricePerPiece,
          numOfSlopedEdges: numOfSlopedEdges,
          numOfMachinedHoles: numOfMachinedHoles,
          machiningPricePerPiece: machiningPricePerPiece,
          cuttingPricePerPiece: cuttingPricePerPiece,
          millingPricePerPiece: millingPricePerPiece,
          lathingPricePerPiece: lathingPricePerPiece,
          tubeCuttingPricePerPiece: tubeCuttingPricePerPiece,
          suggestedPricePerPiece: isNaN(suggestedPricePerPiece) ? "/" : suggestedPricePerPiece.toFixed(3),
          adjustedPricePerPiece: (isNaN(suggestedPricePerPiece) || item.partType === "assembly") ? "/" : (suggestedPricePerPiece * (1 + adjustment)).toFixed(3),
          pricePerPiece: item.state !== "declined" ? item.pricePerPiece.toFixed(3) : (0).toFixed(3),
          totalPrice: item.state !== "declined" ? (item.pricePerPiece * item.quantity).toFixed(3) : (0).toFixed(3),
          isRejected: getState(item.state),
          note: item.note || "/"
        }
      })

      let schema = [
        {
          column: "Part name:",
          value: part => (part.fileName || part.name),
          align: "left"
        },
        {
          column: "Produced by:",
          value: part => part.producedBy,
          align: "left",
        },
        {
          column: "File type:",
          value: part => part.fileType,
          align: "left",
        },
        {
          column: "Part type:",
          value: part => part.partType,
          align: "left",
        },
        {
          column: "Processing:",
          value: part => part.processing,
          align: "left",
        },
        {
          column: "Profile type:",
          value: part => part.profileType,
          align: "left",
        },
        {
          column: "OEM:",
          value: part => part.oem,
          align: "left",
        },
        {
          column: "MAT:",
          value: part => part.material,
          align: "left",
        },
        {
          column: "MTR/MTC 3.1:",
          value: part => part.matCertNeeded,
          align: "left",
        },
        {
          column: "THK (mm):",
          value: part => part.thickness,
          align: "left",
        },
        {
          column: "QTY:",
          value: part => part.quantity,
          align: "left",
        },
        {
          column: "Area (m²):",
          value: part => part.area,
          align: "left",
        },
        {
          column: "Volume (dm³):",
          value: part => part.volume,
          align: "left",
        },
        {
          column: "Weight (kg):",
          value: part => part.weight,
          align: "left",
        },
        {
          column: "Bends:",
          value: part => part.numberOfBends,
          align: "left",
        },
        {
          column: "Has Surface Finish:",
          value: part => part.hasCoating,
          align: "left",
        },
        {
          column: "Surface Finish Type:",
          value: part => part.coatingType,
          align: "left",
        },
        {
          column: "Surface Finish color:",
          value: part => part.coatingColor,
          align: "left",
        },
        {
          column: "Sloped edges:",
          value: part => part.numOfSlopedEdges,
          align: "left",
        },
        {
          column: "Machined holes:",
          value: part => part.numOfMachinedHoles,
          align: "left",
        },
        {
          column: "Max Tolerance:",
          value: part => part.tolerance,
          align: "right",
        },
        {
          column: "Max Roughness:",
          value: part => part.roughness,
          align: "right",
        },
        {
          column: "MAT (€):",
          value: part => part.materialPrice,
          align: "right",
        },
        {
          column: "Cutting (€):",
          value: part => part.cuttingPricePerPiece,
          align: "right",
        },
        {
          column: "Milling (€):",
          value: part => part.millingPricePerPiece,
          align: "right",
        },
        {
          column: "Lathing (€):",
          value: part => part.lathingPricePerPiece,
          align: "right",
        },
        {
          column: "Tube Cutting (€):",
          value: part => part.tubeCuttingPricePerPiece,
          align: "right",
        },
        {
          column: "Bending (€):",
          value: part => part.bendingPricePerPiece,
          align: "right",
        },
        {
          column: "Simple Machining (€):",
          value: part => part.machiningPricePerPiece,
          align: "right",
        },
        {
          column: "Surface Finish (€):",
          value: part => part.coatingPricePerPiece,
          align: "right",
        },
        {
          column: "Welding (€):",
          value: part => part.weldingPricePerPiece,
          align: "right",
        },
        {
          column: "Machine Processing (€):",
          value: part => part.packagingPricePerPiece,
          align: "right",
        },
        {
          column: "Assembling (€):",
          value: part => part.assemblingPricePerPiece,
          align: "right",
        },
        {
          column: "Others (€):",
          value: part => part.transportationPricePerPiece,
          align: "right",
        },
        {
          column: "Suggested PPU (€):",
          value: part => part.suggestedPricePerPiece,
          align: "right",
        },
        {
          column: "Selected PPU (€):",
          value: part => part.pricePerPiece,
          align: "right",
        },
        {
          column: "TC (€):",
          value: part => part.totalPrice,
          align: "right",
        },
        {
          column: "Part State:",
          value: part => part.isRejected,
          align: "center",
        },
        {
          column: "Note:",
          value: part => part.note,
          align: "left",
        },
      ]

      if(adjustment !== 0) {
        const adjustValue = (adjustment * 100).toFixed(2)
        schema.splice(2, 1)
        schema.splice(30, 0, {
          column: "Adjustment (%):",
          value: () => adjustValue,
          align: "right",
        }, {
          column: "Adjusted suggested PPU (€):",
          value: part => part.adjustedPricePerPiece,
          align: "right",
        })
      }

      const widths = getWidths(schema, objects)
      const schemaWithWidth = schema.map((item, index) => {
        return {
          ...item,
          width: widths[index]
        }
      })

      // console.log(preppedParts)
      writeXlsxFile(objects, {
        schema: schemaWithWidth,
        fileName: fileName + ".xlsx",
        headerStyle: headerStyle,
        fontSize: 8,
      }).then(()=>{})
    }
  }

  return (
    <Button
      type="primary"
      className={`exportExcelButton ${squared && "downloadButton"} ${isEditing && "editingDisabled"}`}
      disabled={isEditing}
      onClick={() => {
        exportWithWEF();
      }}
    >
      {squared ?
        <div>
          <FileExcelOutlined style={{fontSize: 20}}/>
          <div style={{ fontSize: 16, margin: 0  }}>Excel Sheet</div>
        </div>
        :
        <div>
          <FileExcelOutlined style={{fontSize: 14, marginRight: 8}}/>{collapsed ? "Excel" : "Export Excel"}
        </div>
      }
    </Button>
  )
}