import React from "react";


export const TableHeader = (props) => {

  const { columns } = props;

  return (
    <div className={"tableHeader"}>
      {columns.map((column, index) => {
        return (
          <div key={index} style={{width: column.width, display: "flex", alignItems: "center"}}>
            <div style={{marginLeft: 10, fontSize: 13, fontWeight: 600}}>
              {column.title}
            </div>
          </div>
        )
      })}
    </div>
  )
}