import {Col, Row, Tooltip} from "antd";
import React, { useEffect, useState } from "react";
import { S3_BUCKET_URL } from "../../../../../../../constants";
import { DropIcon } from "../../../../../../../assets/icons/drop";
import {canHaveCoating, getPlaceholderImage} from "../../../../../../../utils/utility";
import coldGalvanizingIconDark from "../../../../../../../assets/icons/cold-galvanizing-icon-dark.svg";
import nickelPlatingIconDark from "../../../../../../../assets/icons/nickel-plating-icon-dark.svg";
import hotGalvanizingIconDark from "../../../../../../../assets/icons/hot-galvanizing-icon-dark.svg";
import anodizingIconDark from "../../../../../../../assets/icons/anodizing-icon-dark.svg";
import galvanicZincPlatingIconDark from "../../../../../../../assets/icons/galvanic-zinc-plating-icon-dark.svg";
import sandBlastingDarkIcon from "../../../../../../../assets/icons/sand-blasting-icon-dark.svg"
import ceramicBeadBlastingDarkIcon from "../../../../../../../assets/icons/ceramic-bead-blasting-icon-dark.svg"
import glassBeadBlastingDarkIcon from "../../../../../../../assets/icons/glass-bead-blasting-icon-dark.svg"
import hardeningDarkIcon from "../../../../../../../assets/icons/hardening-icon-dark.svg"
import caseHardeningDarkIcon from "../../../../../../../assets/icons/case-hardening-icon-dark.svg"
import temperingDarkIcon from "../../../../../../../assets/icons/tempering-icon-dark.svg"
import annealingDarkIcon from "../../../../../../../assets/icons/annealing-icon-dark.svg"
import {EyeFilled} from "@ant-design/icons";

export const PartImageArea = (props) => {
  const {
    item, showItem, assemblyName
  } = props;

  const [loaded, setLoaded] = useState(false)

  const [showPlaceholder, setShowPlaceholder] = useState(false)

  const getCoatingIcon = () => {
    if (item.coating.type === "painting") {
      return <DropIcon style={{color: item.coating?.data?.ralHex, width: 26, height: 20}}/>
    } else if (item.coating.type === "anodizing") {
      return <img src={anodizingIconDark} alt={"A"} width={28} style={{marginTop: -5, marginRight: -3}}/>
    } else if (item.coating.type === "hotGalvanizing") {
      return <img src={hotGalvanizingIconDark} alt={"HG"} width={28} style={{marginTop: -5, marginRight: -3}}/>
    } else if (item.coating.type === "coldGalvanizing") {
      return <img src={coldGalvanizingIconDark} alt={"CG"} width={28} style={{marginTop: -5, marginRight: -3}}/>
    } else if (item.coating.type === "nickelPlating") {
      return <img src={nickelPlatingIconDark} alt={"NP"} width={28} style={{marginTop: -5, marginRight: -3}}/>
    } else if (item.coating.type === "galvanicZincPlating") {
      return <img src={galvanicZincPlatingIconDark} alt={"Z"} width={28} style={{marginTop: -5, marginRight: -3}}/>
    } else if (item.coating.type === "hardening") {
      return <img src={hardeningDarkIcon} alt={"H"} width={28} style={{marginTop: -5, marginRight: -3}}/>
    } else if (item.coating.type === "caseHardening") {
      return <img src={temperingDarkIcon} alt={"CH"} width={28} style={{marginTop: -5, marginRight: -3}}/>
    } else if (item.coating.type === "annealing") {
      return <img src={annealingDarkIcon} alt={"AN"} width={28} style={{marginTop: -5, marginRight: -3}}/>
    } else if (item.coating.type === "glassBeadBlasting") {
      return <img src={glassBeadBlastingDarkIcon} alt={"GB"} width={28} style={{marginTop: -5, marginRight: -3}}/>
    } else if (item.coating.type === "sandBlasting") {
      return <img src={ceramicBeadBlastingDarkIcon} alt={"SB"} width={28} style={{marginTop: -5, marginRight: -3}}/>
    } else {
      return <div/>
    }
  }

  useEffect(()=>{
    if(item.isAnalysed === true && item.fileType === "step" && !item.stepData?.filePaths?.pngFilePath) {
      setLoaded(true)
      setShowPlaceholder(true)
    }
  }, [item.isAnalysed])

  return (
    <div>
      {(item.fileType === "step" && !showPlaceholder) &&
      (<Row style={{height: 180}}>
        <Col style={{height: 180}}>
          <div className={item.stepData?.filePaths?.foldedStlFilePath && "container"}>
            {item.stepData?.filePaths?.pngFilePath ?
              <img
                className={"productImage"}
                style={{width: 180, height: 180, padding: 10, position: "relative", borderRadius: 4, zIndex: 3}}
                src={S3_BUCKET_URL + item.stepData.filePaths.pngFilePath}
                alt={""}
                onLoad={() => {
                  setLoaded(true)
                }}
              />
              :
              <div className={"productImage"} style={{ width: 180, height: 180 }}/>
            }
            {item.stepData?.filePaths?.foldedStlFilePath && (
              <div>
                {canHaveCoating(item) &&
                <div className={"dropOnOverlay"}>
                  {getCoatingIcon()}
                </div>
                }
                <div className={"overlay"} onClick={() => showItem(item)}>
                  <div className={"overlay-text"}>
                    <div className={"overlay-text-3D"}>3D</div>
                    <div className={"overlay-text-view"}>View</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>)
      }
      {(item.fileType === "dxf") &&
      (<Row style={{height: 180}}>
        <Col>
          <div
            className={item.s3FilePath && "container"}
            style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center", width: 180}}
          >
            <div
              style={{
                width: 180,
                height: 180,
                display: "flex",
                borderRadius: 4,
                background: "#fff",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {item.dxfData?.pngFilePath ?
                <img
                  className={"productImage"}
                  style={{
                    maxWidth: "99%",
                    maxHeight: "99%",
                    padding: 10,
                    position: "relative",
                    zIndex: 3
                  }}
                  src={S3_BUCKET_URL + item.dxfData.pngFilePath}
                  alt={""}
                  onLoad={() => {
                    setLoaded(true)
                  }}
                />
                :
                <div className={"productImage"} style={{ width: 180, height: 180 }}/>
              }
            </div>
            {item.s3FilePath && (
              <div>
                {canHaveCoating(item) &&
                <div className={"dropOnOverlay"}>
                  {getCoatingIcon()}
                </div>
                }
                <div className={"overlay"} onClick={() => showItem(item)}>
                  <div className={"overlay-text"}>
                    <div className={"overlay-text-3D"}>2D</div>
                    <div className={"overlay-text-view"}>View</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>)
      }
      {(item.fileType === "other" || showPlaceholder) &&
      (<Row style={{height: 180}}>
        <Col>
          <div
            className={item.s3FilePath && "container"}
            style={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center", width: 180}}
          >
            <div
              style={{
                width: 180,
                height: 180,
                display: "flex",
                background: "#fff",
                borderRadius: 4,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {canHaveCoating(item) &&
              <div className={"dropOnOverlay"}>
                {getCoatingIcon()}
              </div>
              }
              <img
                className={"productImage"}
                style={{width: 150, height: 150, padding: 8, opacity: 0.8}}
                src={getPlaceholderImage(item.partType)}
                alt={""}
                onLoad={() => {
                  setLoaded(true)
                }}
              />
            </div>
          </div>
        </Col>
      </Row>)
      }
      {!loaded &&
      <div className={"imgLoading"}>
        <div className={"dotsContainer"}>
          <div className={"dotsFlashing"}/>
        </div>
      </div>
      }
      {item.assemblyId !== null &&
      <div
        align={"center"}
        className={"assemblyPartIndicator"}
      >
        <Tooltip
          getPopupContainer={trigger => trigger.parentNode}
          overlayClassName={"multi-edit-thickness-tooltip"}
          color={"rgba(82,82,82,0.98)"}
          placement={"top"}
          title={
            <div align={"center"}>
              <span>This parts belongs to assembly <b>{assemblyName}</b></span>
            </div>
          }
        >
          <div style={{width: 120, cursor: "pointer"}}>
            <EyeFilled style={{marginRight: 5, color: "#ff5b2d", position: "relative"}}/>Assembly Part
          </div>
        </Tooltip>
      </div>
      }
    </div>
  )
}