import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Row, Col, Button, Layout, Modal, Card, Form, Input, Select, Divider, Grid} from 'antd'

import {AlignRight} from '../../../styles'
import {logoutUser} from '../../../actions/authActions'
import {
  editAccountDetails,
  changePassword,
  deleteAccount
} from '../../../actions/userSettingsActions'
import {Sidebar} from '../../../components'
import {
  CaretDownOutlined,
  GlobalOutlined,
  HomeOutlined,
  LockOutlined,
  PhoneOutlined,
  SearchOutlined,
  UserOutlined
} from "@ant-design/icons";
import {countries} from "../../../data/countries";
import {useWindowSize} from "../../../utils/utility";
import "./style.less";

export const RegularCustomerSettingsPage = () => {
  const [deleteVisible, setDeleteVisible] = useState(false);

  
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user);
  const editingSettings = useSelector(state => state.customerSettings.editingCustomerSettings)
  const isResettingPassword = useSelector(state => state.customerSettings.resettingPassword)

  const dispatch = useDispatch();

  const windowSize = useWindowSize();
  const screens = Grid.useBreakpoint();

  const handleClick = (values) => {
    dispatch(editAccountDetails({
      data: {...values, region: values.city},
      type: "regular-customer",
      logout_user: logoutUser
    }))
  }

  const handlePasswordClick = (values) => {
    dispatch(changePassword({
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    }))
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <Sidebar onLogout={()=>{dispatch(logoutUser())}}
               user={user}
      />
      <Layout.Content style={{marginRight: 16, marginLeft: isCollapsed ? 60 : 200, paddingLeft: 25, paddingTop: 15, marginBottom: 15, transition: "all 0.25s"}}>
        <div style={{ height: 32, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div className={"pageTitle"}>Account Settings</div>
        </div>
        <Divider style={{marginTop: 6, marginBottom: 11}}/>
        <Card className={"customerSettingsCard"}
              bodyStyle={{ height: "100%", padding: 0 }}
        >
          <Row style={{ paddingLeft: '2rem', paddingRight: '1rem'}} gutter={4} type="flex">
            <React.Fragment>
              <Col flex={1} style={{ maxWidth: 1000, paddingBottom: 30}}>
                <h3 style={{ paddingTop: '1rem'}}>Edit account information</h3>
                <Form
                  className={"accountInfoForm"}
                  name={'settings-customer'}
                  onFinish={handleClick}
                >
                  <Row gutter={25}>
                    <Col span={screens['sm'] ? 12 : 24} style={{ maxWidth: 500 }}>
                      <Form.Item
                        label={"Name"}
                        name={'name'}
                        rules={[
                          {required: true, message: 'This field is required'},
                          {type: 'string', min: 2 ,message: 'First name must be 2 characters or longer'},
                          {type: 'string', max: 60 ,message: 'First name too long!'},
                        ]}
                        initialValue={user.name}
                        validateTrigger={'onSubmit'}
                      >
                        <Input
                          placeholder="Enter your first name"
                          prefix={<UserOutlined />}
                        />
                      </Form.Item>
                      <Form.Item
                        label={"Country"}
                        name={'country'}
                        rules={[
                          {required: true, message: 'This field is required'},
                        ]}
                        initialValue={user.country}
                        validateTrigger={'onSubmit'}
                      >
                        <Select
                          showSearch
                          style={{width: '100%'}}
                          prefix={<GlobalOutlined />}
                          placeholder="Select your country"
                          suffixIcon={(e) => {
                            return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                          }}
                          optionFilterProp="children"
                          notFoundContent={"Country not found"}
                          filterOption={(input, option) =>
                            option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          autoComplete="new-password"
                        >
                          {
                            countries.map(item => {
                              return (
                                <Select.Option key={item.name} value={item.name} className={"countrySelect"}>
                                  <img src={item.flag} alt="" style={{position: "relative", top: -2, width: 18}}/> {item.name}
                                </Select.Option>
                              )
                            })
                          }
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={"City"}
                        name={'city'}
                        rules={[
                          {required: true, message: 'This field is required'},
                          {type: 'string', min: 2 ,message: 'City name must be 2 characters or longer'},
                          {type: 'string', max: 60 ,message: 'City name too long!'},
                        ]}
                        initialValue={user.city}
                        validateTrigger={'onSubmit'}
                      >
                        <Input
                          placeholder="Enter your city"
                          prefix={<GlobalOutlined />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={screens['sm'] ? 12 : 24} style={{ maxWidth: 500 }}>
                      <Form.Item
                        label={"Postal Code"}
                        name={'postalCode'}
                        rules={[
                          {required: true, message: 'This field is required'},
                          {type: 'string', min: 2 ,message: 'Postal code must be 2 digits or longer'},
                        ]}
                        initialValue={user.postalCode}
                        validateTrigger={'onSubmit'}
                      >
                        <Input
                          prefix={<GlobalOutlined />}
                          placeholder="Enter your postal code"
                        />
                      </Form.Item>

                      <Form.Item
                        label={"Address"}
                        name={'address'}
                        rules={[
                          {required: true, message: 'This field is required'},
                          {type: 'string', min: 2 ,message: 'Address must be 2 characters or longer'},
                          {type: 'string', max: 60 ,message: 'Address too long!'},
                        ]}
                        initialValue={user.address}
                        validateTrigger={'onSubmit'}
                      >
                        <Input
                          placeholder="Enter your address"
                          prefix={<HomeOutlined />}
                        />
                      </Form.Item>

                      <Form.Item
                        label={"Phone"}
                        name={'phone'}
                        rules={[
                          {required: true, message: 'This field is required'},
                          {type: 'string', min: 6 ,message: 'Contact number must be 6 digits or longer'},
                          {type: 'string', max: 60 ,message: 'Contact number too long!'},
                          {
                            type: 'string',
                            pattern: new RegExp(/^[+]*[(]?\d{1,4}[)]?[-\s./\d]*$/g),
                            message: 'Please specify a valid phone number'
                          },
                        ]}
                        initialValue={user.phone}
                        validateTrigger={'onSubmit'}
                      >
                        <Input
                          placeholder="Enter your phone number"
                          prefix={<PhoneOutlined />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <div align={'right'}>
                        <Divider style={{width: "100%", minWidth: "100%", margin: "0 0 8px 0"}}/>
                        <Button
                          style={{ marginTop: 17, width: 180 }}
                          loading={editingSettings}
                          type="primary"
                          htmlType={'submit'}
                        >
                          <span style={{ fontSize: 13, fontWeight: 600}}>Save settings</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
              {windowSize.width > 1013 ?
                <Divider type="vertical"
                        style={{height: "auto", margin: "25px 12.5px 0 12.5px"}}/>
                : (windowSize.width >= 975 ?
                    <div style={{ width: 25}}/>
                    :
                    <div  />
                )
              }
              <Col flex={2} style={{ maxWidth: 500, paddingBottom: 30}}>
                <h3 style={{ paddingTop: '1rem'}}>Change Password</h3>
                <Form
                  className={"passwordForm"}
                  name={"password-change-customer"}
                  onFinish={handlePasswordClick}
                >
                  <Form.Item
                    label={"Old password"}
                    name={'oldPassword'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 6 ,message: 'Password must be 6 characters or longer'},
                      {type: 'string', max: 60 ,message: 'Password must be 60 characters or shorter'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      placeholder="Enter your old password"
                    />
                  </Form.Item>

                  <Form.Item
                    label={"New password"}
                    name={'newPassword'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 6 ,message: 'Password must be 6 characters or longer'},
                      {type: 'string', max: 60 ,message: 'Password must be 60 characters or shorter'},
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      placeholder="Enter your new password"
                    />
                  </Form.Item>

                  <Form.Item
                    label={"Repeat new password"}
                    name={'repeatPassword'}
                    rules={[
                      {required: true, message: 'This field is required'},
                      {type: 'string', min: 6 ,message: 'Password must be 6 characters or longer'},
                      {type: 'string', max: 60 ,message: 'Password must be 60 characters or shorter'},
                      ({getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Passwords must match!');
                        },
                      }),
                    ]}
                    validateTrigger={'onSubmit'}
                  >
                    <Input.Password
                      prefix={<LockOutlined />}
                      placeholder="Enter your new password again"
                    />
                  </Form.Item>

                  <Divider style={{width: "100%", minWidth: "100%", margin: "0 0 8px 0"}}/>
                  <AlignRight>
                    <Button
                      style={{ marginTop: 17 }}
                      type="primary"
                      loading={isResettingPassword}
                      htmlType={"submit"}
                    >
                      <span style={{ fontSize: 13, fontWeight: 600}}>Change password</span>
                    </Button>
                  </AlignRight>
                </Form>
                {/*<h1 style={{paddingTop: '2.5rem'}}>Delete Account</h1>*/}
                <Modal
                  title="Delete"
                  centered={true}
                  visible={deleteVisible}
                  onOk={() => {
                    deleteAccount({
                      logout_user: logoutUser,
                      customer_id: user.id,
                    })
                  }
                  }
                  onCancel={() => setDeleteVisible(false)}
                  okText={'Delete'}
                  okType="danger"
                >
                  <h3>Are you sure you want to DELETE your account ? </h3>
                </Modal>
              </Col>
            </React.Fragment>
          </Row>
        </Card>
      </Layout.Content>
    </Layout>
  )
}