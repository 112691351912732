import styled from 'styled-components'

const LinkWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`

const CardTitle = styled.h2`
  text-align: center;
  margin-bottom: 30px;
`

const Logo = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
`

const Picture = styled.img`
  border: 1px solid black;
  margin-top: 10px;
  margin-bottom: 10px;
`

export { LinkWrapper, CardTitle, Logo, Picture }
