import React from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import {Accordion, AccordionDetails, AccordionSummary, Divider as MiDivider} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Input, Radio, Spin} from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {ArchivedBulkOrdersList} from "../../../ArchivedBulkOrdersList";

export const BulkOrderArchiveAccordion = (props) => {

  const {
    selectedCalc, setSelectedCalc,
    searchArchivedCalculations,
    internalArchivedCalculations,
    isFetchingInternalArchived,
    searchingArchived
  } = props;

  return (
    <Spin
      spinning={isFetchingInternalArchived}
      indicator={
        <div align={"center"}>
          <LoadingOutlined style={{ fontSize: 30, marginLeft: -5, marginTop: -5 }} spin />
        </div>
      }
      wrapperClassName={"borderRadiusSpin"}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1c-content"
          id="panel1c-header2"
          style={{pointerEvents: 'auto', maxHeight: 48}}
        >
          <div className={"text14-500"} style={{fontSize: 20, marginLeft: 5}}>
            Stored Calculations
          </div>
        </AccordionSummary>
        <AccordionDetails style={{
          alignItems: 'center',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          height: "400px",
          padding: "0 0 5px 7px",
        }}>
          <div style={{ width: "100%", height: "100%"}}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <div style={{ marginLeft: 8, width: 135, minWidth: 135 }} >
                <Radio.Group
                  value={selectedCalc}
                  onChange={(e) => {setSelectedCalc(e.target.value)}}
                  className={"loginText"}
                >
                  <Radio value="self">Mine</Radio>
                  <Radio value="all">All</Radio>
                </Radio.Group>
              </div>
              <Input
                suffix={<SearchOutlined style={{fontSize: 16, color: '#ff5b2d', margin: 0}}/>}
                placeholder={"Type to search"}
                className={"archiveSearch"}
                onChange={(e)=>searchArchivedCalculations(e.target.value)}
              />
            </div>
            <MiDivider style={{ margin: "0px 0px 0px -8px" }}/>
            <div style={{
              height: "351px",
              width: "100%",
              overflow: "auto",
              marginRight: 5,
              overflowX: "hidden",
            }}>
              <ArchivedBulkOrdersList items={internalArchivedCalculations} loading={isFetchingInternalArchived} searching={searchingArchived}/>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Spin>
  )
}