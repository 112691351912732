import {
  CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINES,
  CUTTING_MACHINES_IS_FETCHING_MACHINE_TYPES,
  CUTTING_MACHINES_SET_CUTTING_MACHINES,
  CUTTING_MACHINES_SET_MACHINE_TYPES,
  CUTTING_MACHINES_IS_FETCHING_MACHINE_PRIORITIES,
  CUTTING_MACHINES_SET_MACHINE_PRIORITIES,
  CUTTING_MACHINES_SET_MACHINE_DATA,
  CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINE_DATA,
  CUTTING_MACHINES_SET_CUTTING_SPEED,
  CUTTING_MACHINES_SET_MACHINE_PARAMETER,
  CUTTING_MACHINES_IS_UPDATING_MACHINE_PRIORITIES,
  CUTTING_MACHINES_IS_CREATING_CUTTING_MACHINE,
  CUTTING_MACHINES_IS_EDITING_CUTTING_MACHINE,
  CUTTING_MACHINES_IS_DELETING_CUTTING_MACHINE,
  CUTTING_MACHINES_SET_TOLERANCE
} from './actionTypes'

import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";

const fetchCuttingMachines = () => dispatch => {

  dispatch({
    type: CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINES,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/cutting-machine/sorted-by-type`)
    .then(response => {
      dispatch({
        type: CUTTING_MACHINES_SET_CUTTING_MACHINES,
        payload: response.data.machines
      })

      dispatch({
        type: CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINES,
        payload: false
      })

      return response.data.machines
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINES,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const createCuttingMachine = payload => dispatch => {

  dispatch({
    type: CUTTING_MACHINES_IS_CREATING_CUTTING_MACHINE,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/cutting-machine`, payload)
    .then(response => {

      dispatch({
        type: CUTTING_MACHINES_SET_CUTTING_MACHINES,
        payload: response.data.machines
      })

      dispatch({
        type: CUTTING_MACHINES_IS_CREATING_CUTTING_MACHINE,
        payload: false
      })

      return response.data.machineId
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: CUTTING_MACHINES_IS_CREATING_CUTTING_MACHINE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchCuttingMachineParameters = payload => dispatch => {

  dispatch({
    type: CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINE_DATA,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/cutting-machine/${payload.cuttingMachineId}/properties`)
    .then(response => {
      let data = response.data.machine.groupNames.map( name => {
        let item = response.data.machine.tables.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })

      let machine = {...response.data.machine, tables: data}

      dispatch({
        type: CUTTING_MACHINES_SET_MACHINE_DATA,
        payload: machine
      })
      dispatch({
        type: CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINE_DATA,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: CUTTING_MACHINES_IS_FETCHING_CUTTING_MACHINE_DATA,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchCuttingMachineTypes = () => dispatch => {
  dispatch({
    type: CUTTING_MACHINES_IS_FETCHING_MACHINE_TYPES,
    payload: true
  })
  return http
    .get(`${API_BASE_URL}/cutting-machine/types`)
    .then(response => {
      dispatch({
        type: CUTTING_MACHINES_SET_MACHINE_TYPES,
        payload: response.data.types
      })
      dispatch({
        type: CUTTING_MACHINES_IS_FETCHING_MACHINE_TYPES,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: CUTTING_MACHINES_IS_FETCHING_MACHINE_TYPES,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchCuttingMachinePriorities = () => dispatch => {
  dispatch({
    type: CUTTING_MACHINES_IS_FETCHING_MACHINE_PRIORITIES,
    payload: true
  })
  return http
    .get(`${API_BASE_URL}/cutting-machine/sorted-by-priority`)
    .then(response => {
      dispatch({
        type: CUTTING_MACHINES_SET_MACHINE_PRIORITIES,
        payload: response.data.machines
      })
      dispatch({
        type: CUTTING_MACHINES_IS_FETCHING_MACHINE_PRIORITIES,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      }
      else {
        dispatch({
          type: CUTTING_MACHINES_IS_FETCHING_MACHINE_PRIORITIES,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const updateCuttingMachinePriorities = payload => dispatch => {
  dispatch({
    type: CUTTING_MACHINES_IS_UPDATING_MACHINE_PRIORITIES,
    payload: true
  })
  return http
    .put(`${API_BASE_URL}/cutting-machine/priorities`, [...payload])
    .then(response => {
      dispatch({
        type: CUTTING_MACHINES_SET_MACHINE_PRIORITIES,
        payload: response.data.machines.data
      })
      dispatch({
        type: CUTTING_MACHINES_IS_UPDATING_MACHINE_PRIORITIES,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: CUTTING_MACHINES_IS_UPDATING_MACHINE_PRIORITIES,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const updateCuttingMachineSpeed = payload => dispatch => {
  dispatch({
    type: CUTTING_MACHINES_SET_CUTTING_SPEED,
    payload: payload
  })
}

const setMachineParameter = payload => dispatch => {
  dispatch({
    type: CUTTING_MACHINES_SET_MACHINE_PARAMETER,
    payload: payload
  })
}

const editCuttingMachine = payload => dispatch => {
  dispatch({
    type: CUTTING_MACHINES_IS_EDITING_CUTTING_MACHINE,
    payload: true
  })
  return http
    .put(`${API_BASE_URL}/cutting-machine/${payload.cuttingMachineId}`, {name: payload.name, typeId: payload.typeId})
    .then(response => {
      dispatch({
        type: CUTTING_MACHINES_SET_CUTTING_MACHINES,
        payload: response.data.machines.data
      })

      dispatch({
        type: CUTTING_MACHINES_IS_EDITING_CUTTING_MACHINE,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: CUTTING_MACHINES_IS_EDITING_CUTTING_MACHINE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const deleteCuttingMachine = payload => dispatch => {
  dispatch({
    type: CUTTING_MACHINES_IS_DELETING_CUTTING_MACHINE,
    payload: true
  })
  return http
    .delete(`${API_BASE_URL}/cutting-machine/${payload.cuttingMachineId}`)
    .then(response => {
      dispatch({
        type: CUTTING_MACHINES_SET_CUTTING_MACHINES,
        payload: response.data.machines
      })

      dispatch({
        type: CUTTING_MACHINES_IS_DELETING_CUTTING_MACHINE,
        payload: false
      })

      return response.data.machines
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: CUTTING_MACHINES_IS_DELETING_CUTTING_MACHINE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setEmptyCuttingMachineData = () => dispatch => {
  dispatch({
    type: CUTTING_MACHINES_SET_MACHINE_DATA,
    payload: {}
  })
}

const setCuttingMachineTolerance = payload => dispatch => {
  dispatch({
    type: CUTTING_MACHINES_SET_TOLERANCE,
    payload: payload
  })
}



export {
  fetchCuttingMachines,
  fetchCuttingMachineParameters,
  fetchCuttingMachineTypes,
  fetchCuttingMachinePriorities,
  updateCuttingMachineSpeed,
  setMachineParameter,
  updateCuttingMachinePriorities,
  createCuttingMachine,
  editCuttingMachine,
  deleteCuttingMachine,
  setEmptyCuttingMachineData,
  setCuttingMachineTolerance
}