import React from "react";
import coldGalvanizingIcon from "../../../assets/icons/cold-galvanizing-icon.svg";
import hotGalvanizingIcon from "../../../assets/icons/hot-galvanizing-icon.svg";
import anodizingIcon from "../../../assets/icons/anodizing-icon.svg";
import nickelPlatingIcon from "../../../assets/icons/nickel-plating-icon.svg";
import galvanicZincPlatingIcon from "../../../assets/icons/galvanic-zinc-plating-icon.svg";
import sandBlastingIcon from "../../../assets/icons/sand-blasting-icon.svg"
import ceramicBeadBlastingIcon from "../../../assets/icons/ceramic-bead-blasting-icon.svg"
import glassBeadBlastingIcon from "../../../assets/icons/glass-bead-blasting-icon.svg"
import hardeningIcon from "../../../assets/icons/hardening-icon.svg"
import caseHardeningIcon from "../../../assets/icons/case-hardening-icon.svg"
import temperingIcon from "../../../assets/icons/tempering-icon.svg"
import annealingIcon from "../../../assets/icons/annealing-icon.svg"
import {DropIcon} from "../../../assets/icons/drop";
import {getColorName} from "../../../utils/utility";

export const CoatingSection = (props) => {

  const { item } = props;

  return (
    <div style={{marginRight: 30, display: "flex"}}>
      <div style={{marginTop: 10, marginLeft: 2, marginRight: 5}}>
        {item.coating.type === "painting" &&
        <DropIcon style={{color: item.coating?.data?.ralHex, width: 26, height: 20}}/>
        }
        {item.coating.type === "coldGalvanizing" &&
        <img src={coldGalvanizingIcon} alt={"CG"} width={28}/>
        }
        {item.coating.type === "hotGalvanizing" &&
        <img src={hotGalvanizingIcon} alt={"HG"} width={28}/>
        }
        {item.coating.type === "anodizing" &&
        <img src={anodizingIcon} alt={"A"} width={28}/>
        }
        {item.coating.type === "nickelPlating" &&
        <img src={nickelPlatingIcon} alt={"NP"} width={28}/>
        }
        {item.coating.type === "galvanicZincPlating" &&
        <img src={galvanicZincPlatingIcon} alt={"Z"} width={28}/>
        }
        {item.coating.type === "sandBlasting" &&
        <img src={ceramicBeadBlastingIcon} alt={"SB"} width={28}/>
        }
        {item.coating.type === "glassBeadBlasting" &&
        <img src={glassBeadBlastingIcon} alt={"GB"} width={28}/>
        }
        {item.coating.type === "annealing" &&
        <img src={annealingIcon} alt={"AN"} width={28}/>
        }
        {item.coating.type === "hardening" &&
        <img src={hardeningIcon} alt={"H"} width={28}/>
        }
        {item.coating.type === "caseHardening" &&
        <img src={temperingIcon} alt={"CH"} width={28}/>
        }
      </div>
      <div>
        <div className={"orderItemDetailsLabel"}>Surface finish:</div>
        <div className={"orderItemDetailsValue"}>
          {item.coating.type === "coldGalvanizing" &&
          <div>Cold-Galvanizing</div>
          }
          {item.coating.type === "hotGalvanizing" &&
          <div>Hot-Galvanizing</div>
          }
          {item.coating.type === "anodizing" &&
          <div>Anodizing</div>
          }
          {item.coating.type === "nickelPlating" &&
          <div>Chemical Nickel Plating</div>
          }
          {item.coating.type === "galvanicZincPlating" &&
          <div>Galvanic Zinc Plating</div>
          }
          {item.coating.type === "sandBlasting" &&
          <div>Ceramic Bead Blasting</div>
          }
          {item.coating.type === "glassBeadBlasting" &&
          <div>Glass Bead Blasting</div>
          }
          {item.coating.type === "annealing" &&
          <div>Annealing</div>
          }
          {item.coating.type === "hardening" &&
          <div>Hardening</div>
          }
          {item.coating.type === "caseHardening" &&
          <div>Tempering</div>
          }
          {item.coating.type === "painting" &&
          <div>
            {getColorName({color: item.coating.data.colorRAL})},
            {item.coating.data.colorGlossId === "1" ? " Painting" : " Powder Painting"}
          </div>
          }
        </div>
      </div>
    </div>
  )
}