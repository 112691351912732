import React from "react";
import {Button, Card, Modal, Tooltip} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {imageSection} from "../../../../../utils/partCardUtils/sections";
import {calcAdjustment} from "../../../../../utils/utility";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";

export const DraftOrderAdjustmentModal = (props) => {

  const {
    showAdjustmentChangeModal,
    handleAdjustmentConfirm,
    order
  } = props;

  return (
    <Modal
      visible={showAdjustmentChangeModal}
      maskClosable={false}
      centered={true}
      closable={false}
      footer={
        <div>
          <Button
            type={"primary"}
            style={{fontSize: 13, fontWeight: 500}}
            onClick={()=>{handleAdjustmentConfirm(order.id)}}
          >
            Confirm
          </Button>
        </div>
      }
      width={520}
    >
      <div style={{fontSize: 13, fontWeight: 500, marginBottom: 10, color: "white"}}>
        <ExclamationCircleFilled style={{color: "#ffc739", fontSize: 16, marginRight: 5, position: "relative", top: 1}} /> The prices of some of your parts have changed. Confirm changes to proceed.
      </div>
      {order.id &&
      <div
        className={"quotedItemsList"}
        style={{
          maxHeight: 480,
          padding: "0 6px 10px 6px",
          overflowX: "hidden"
        }}
      >
        {order.parts.map(part => {
          if(part.processingType === "automatic" && part.state === "processed") {
            return (
              <div key={part.id || part.fileId}>
                <div style={{display: "flex"}}>
                  <Card
                    className={"bendCard"}
                    style={{height: 75, marginTop: 8, marginBottom: 0}}
                    bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                  >
                    <div style={{display: "flex", width: "100%"}}>
                      {imageSection(part)}
                      <div style={{
                        width: "100%",
                        height: 55,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                      }}>
                        <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                          <div
                            className={"partInfo"}
                            style={{maxWidth: 330}}
                          >
                            <Tooltip
                              overlayClassName={"delete-tooltip"}
                              color={"rgba(82,82,82,0.9)"}
                              title={<span className={"text12-500"} style={{color: "white"}}>{part.fileName}</span>}
                            >
                              {part.fileName}
                            </Tooltip>
                          </div>
                          <div>
                            <div style={{height: 24}}/>
                          </div>
                        </div>
                        <div>
                          <div align={"right"} style={{display: "flex", justifyContent: "flex-end"}}>
                            <div style={{fontSize: 12, fontWeight: 500}}>
                              1pc: <span style={{fontWeight: 600, color: "white"}}>
                                    {((parseFloat(part.pricePerPiece) || 0) * calcAdjustment(order.oldAdjustment)).toFixed(2)} €
                                  </span>
                            </div>
                            <div align={"center"} style={{display: "flex"}}>
                              <TrendingFlatIcon style={{width: 35, fontSize: 18, alignSelf: "center"}}/>
                            </div>
                            <div
                              style={{fontSize: 12, fontWeight: 500}}
                            >
                                  <span style={{
                                    fontWeight: 600,
                                    color: "#52c41a"
                                  }}>{((parseFloat(part.pricePerPiece) || 0) * calcAdjustment(order.adjustment)).toFixed(2)} €</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            )
          }
          else {
            return <div key={part.id || part.fileId}/>
          }
        })}
      </div>
      }
    </Modal>
  )
}