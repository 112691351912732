import React from "react";
import { PartItemCard } from "../OrderItemCards/ProcessedItemCard/PartItemCard";

export const AssemblyPartProcessedCard = (props) => {
  const {
    orderId, item, sheetMetalMaterials, machiningMaterials, tubeCuttingMaterials,
    changeSheetMetalMaterial, changePartThickness, changeMachiningMaterial,
    changeTubeCuttingMaterial, changePartQuantity, changePartNote, changePartColor,
    changePartTolerance, changePartRoughness, changeManualPartTolerance,
    changeManualPartRoughness,
    changePartStandard, changePartNumberOfUniqueParts, handleColorRemove,
    handleRemoveItem, showItem, selectedItems, onCheck,
    handleChangeItemType, isOrderDetails,
    bulkItem, changePrice, adjustment, openDocumentsView,
    handleOpenPricesModal, assemblyCount, assemblyName,
    changeMaterialCertificate
  } = props;

  return (
    <div key={item.id}>
      <PartItemCard
        orderId={orderId}
        item={item}
        assemblyName={assemblyName}
        selectedItems={selectedItems}
        onCheck={onCheck}
        handleRemoveItem={handleRemoveItem}
        sheetMetalMaterials={sheetMetalMaterials}
        machiningMaterials={machiningMaterials}
        tubeCuttingMaterials={tubeCuttingMaterials}
        changeSheetMetalMaterial={changeSheetMetalMaterial}
        changeMachiningMaterial={changeMachiningMaterial}
        changeTubeCuttingMaterial={changeTubeCuttingMaterial}
        changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
        changePartThickness={changePartThickness}
        changePartQuantity={changePartQuantity}
        changePartNote={changePartNote}
        changePartColor={changePartColor}
        changePartTolerance={changePartTolerance}
        changePartRoughness={changePartRoughness}
        changeManualPartTolerance={changeManualPartTolerance}
        changeManualPartRoughness={changeManualPartRoughness}
        changeMaterialCertificate={changeMaterialCertificate}
        handleColorRemove={handleColorRemove}
        handleOpenPricesModal={handleOpenPricesModal}
        showItem={showItem}
        isOrderDetails={isOrderDetails}
        bulkItem={bulkItem}
        changePrice={changePrice}
        adjustment={adjustment}
        openDocumentsView={openDocumentsView}
        isAssemblyPart={true}
        handleChangeItemType={handleChangeItemType}
        changePartStandard={changePartStandard}
        assemblyCount={assemblyCount}
      />
    </div>
  )
}