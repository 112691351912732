import React from "react";
import {Button, Modal} from "antd";
import {S3_BUCKET_URL} from "../../../../../constants";
import {FileTextOutlined} from "@ant-design/icons";

export const OrderDownloadFilesModal = (props) => {

  const {
    isVisible, onCancel,
    filesItem
  } = props

  return (
    <Modal
      title={"Download Files"}
      centered={true}
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
      width={520}
    >
      <div>
        {filesItem &&
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          {filesItem.s3FilePath &&
          <div style={{marginBottom: 8}}>
            <Button
              className={"downloadButton"}
              type={"primary"}
              href={`${S3_BUCKET_URL + filesItem.s3FilePath}`}
            >
              <FileTextOutlined style={{fontSize: 14}} />
              <span style={{fontSize: 13, fontWeight: 500}}>
                    Original File
                  </span>
            </Button>
          </div>
          }
          {filesItem.fileType === "step" &&
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            {filesItem.stepData?.filePaths?.unfoldedStepFilePath &&
            <div style={{marginBottom: 8}}>
              <Button
                className={"downloadButton"}
                style={{ marginLeft: 6 }}
                type={"primary"}
                href={`${S3_BUCKET_URL + filesItem.stepData.filePaths.unfoldedStepFilePath}`}
              >
                <FileTextOutlined style={{fontSize: 14}} />
                <span style={{fontSize: 13, fontWeight: 500}}>
                      Unfolded STEP
                    </span>
              </Button>
            </div>
            }
            {filesItem.stepData?.filePaths?.unfoldedDxfFilePath &&
            <div style={{marginBottom: 8}}>
              <Button
                className={"downloadButton"}
                style={{ marginLeft: 6 }}
                type={"primary"}
                href={`${S3_BUCKET_URL + filesItem.stepData.filePaths.unfoldedDxfFilePath}`}
              >
                <FileTextOutlined style={{fontSize: 14}} />
                <span style={{fontSize: 13, fontWeight: 500}}>
                      Generated DXF
                    </span>
              </Button>
            </div>
            }
          </div>
          }
        </div>
        }
      </div>
    </Modal>
  )
}
