import React, {useEffect, Fragment, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Table, Layout, Row, Col, Button, Tag, Divider} from 'antd'

import { CompanySidebar } from '../../../components/company'
import { logoutUser } from '../../../actions/authActions'
import {
  fetchProducerPendingOrders
} from '../../../actions/ordersActions'
import {InboxOutlined, LoadingOutlined} from "@ant-design/icons";
import {formatTableDate, getColumnSearchProps} from "../../../utils/utility";

import "./style.less"
import history from "../../../utils/history";
import {EmptyTable} from "../../../components/EmptyTable";


export const PendingPage = () => {
  let searchInput = null;

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user)
  const isFetchingPendingOrders = useSelector(state => state.orders.isFetchingProducerPendingOrders)
  const pendingOrders = useSelector(state => state.orders.producerPendingOrders)
  const pagination = useSelector(state => state.orders.producerPendingOrdersPagination)
  const dispatch = useDispatch()

  const [sortedInfo, setSortedInfo] = useState({})
  const [filteredInfo, setFilteredInfo] = useState({})

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  useEffect(() => {
    dispatch(fetchProducerPendingOrders({
      sortField: "orderDate",
      sortDirection: "desc",
      orderName: "",
      customName: "",
      customerName: "",
      page: pagination.current !== undefined ? pagination.current : 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
  }, []);

  const initializeData = () => {
    setSortedInfo({})
    setFilteredInfo({})
    dispatch(fetchProducerPendingOrders({
      sortField: "orderDate",
      sortDirection: "desc",
      orderName: "",
      customName: "",
      customerName: "",
      page: 1,
      pagination: {...pagination, current: 1, pageSize: pageSize}
    }))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const sortField = sorter.order ? sorter.columnKey : "orderDate"

    const sortDirection = sorter.order ? (sorter.order === "ascend" ? "asc" : "desc") : "desc"
    const orderName = filters.orderName ? filters.orderName[0] : ""
    const customName = filters.customName ? filters.customName[0] : ""
    const customerName = filters.customerName ? filters.customerName[0] : ""

    setFilteredInfo(filters)
    setSortedInfo(sorter)

    dispatch(fetchProducerPendingOrders({
      sortField: sortField,
      sortDirection: sortDirection,
      orderName: orderName,
      customName: customName,
      customerName: customerName,
      page: pagination.current,
      pagination: pager
    }))
  }

  return (
    <Layout
        style={{
          overflow: 'auto',
          minHeight: '100vh',
        }}
    >
      <CompanySidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200}}>
        <div style={{overflowX: "hidden"}}>
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            <Row type="flex">
              <Col span={24}>
                <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                  <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
                    <div className={"pageTitle"}>Pending quotes:</div>
                  </div>
                  <div align={"right"} style={{alignSelf: "flex-end"}}>
                    <Button
                      loading={isFetchingPendingOrders}
                      style={{fontWeight: 500}}
                      onClick={() => {
                        initializeData()
                      }}
                      type="primary">
                      Check for new quotes
                    </Button>
                  </div>
                </div>
                <Divider style={{marginTop: 6, marginBottom: 11}}/>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={24}>
                <Fragment>
                  <Table
                    columns={[
                      {
                        title: 'Quote Code',
                        key: 'orderName',
                        width: "10%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.orderName}
                          </div>
                        ),
                        filteredValue: filteredInfo.orderName || null,
                        ...getColumnSearchProps('orderName', searchInput, "code"),
                      },
                      {
                        title: 'Custom Name',
                        key: 'customName',
                        className: "darkerColumn",
                        ellipsis: {showTitle: false},
                        width: "18%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.customName || "/"}
                          </div>
                        ),
                        filteredValue: filteredInfo.customName || null,
                        ...getColumnSearchProps('customName', searchInput, "orders"),
                      },
                      {
                        title: 'Quote date',
                        key: 'orderDate',
                        width: "10%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {formatTableDate(row.orderDate)}
                          </div>
                        ),
                        sorter: true,
                        sortOrder: sortedInfo.columnKey === 'orderDate' && sortedInfo.order
                      },
                      {
                        title: 'Customer',
                        key: 'customerName',
                        className: "darkerColumn",
                        width: "22%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.customerName}
                          </div>
                        ),
                        filteredValue: filteredInfo.customerName || null,
                        ...getColumnSearchProps('customerName', searchInput,"customer"),
                      },
                      {
                        title: 'Delivery date',
                        key: 'deliveryDate',
                        width: "10%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {formatTableDate(row.deliveryDate)}
                          </div>
                        ),
                        sorter: true,
                        sortOrder: sortedInfo.columnKey === 'deliveryDate' && sortedInfo.order
                      },
                      {
                        title: 'Total price',
                        key: 'totalWithVat',
                        className: "darkerColumn",
                        width: "12%",
                        align: "right",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.state === "quoted" ?
                              `${(row.totalPrice * (1 + row.vatValue) || row.totalPrice || 0).toFixed(2)}€`
                              :
                              row.totalPrice ? `${(row.totalPrice * (1 + row.vatValue) || row.totalPrice || 0).toFixed(2)}€ + RFQ` : "RFQ"
                            }
                          </div>
                        ),
                      },
                      {
                        title: 'Status',
                        key: 'stateName',
                        className: "column200",
                        width: "200px",
                        align: "center",
                        render: row => (
                          <div>
                            <React.Fragment>
                              <Tag color="#f0bd27" style={{fontWeight: 600, color: "black", textAlign: "center"}}>Pending</Tag>
                              {row.state === "quoted" ?
                                <Tag color="#808080" style={{fontWeight: 600, textAlign: "center", width: 78, marginRight: 0}}>Quoted</Tag>
                                :
                                <Tag color="#e03838" style={{fontWeight: 600, color: "white", textAlign: "center", width: 78, marginRight: 0}}>Unquoted</Tag>
                              }
                            </React.Fragment>
                          </div>
                        ),
                      },
                      {
                        title: '',
                        key: 'more',
                        className: "darkerColumn",
                        width: "90px",
                        align: "right",
                        render: row => (
                          <Button
                            onMouseDown={(e) => {
                              if (e.button === 1) {
                                const win = window.open(`/pending-order?order=${row.id}`, "_blank");
                                win.focus();
                              } else if (e.button === 0) {
                                history.push(`/pending-order?order=${row.id}`)
                              }
                            }}
                            type="primary"
                            style={{
                              fontSize: 13,
                              fontWeight: 600
                            }}
                          >
                            Details
                          </Button>
                        ),
                      },
                    ]}
                    className={"antTable"}
                    dataSource={pendingOrders}
                    rowKey="id"
                    pagination={{...pagination, pageSize: pageSize}}
                    loading={{spinning: isFetchingPendingOrders, indicator: <LoadingOutlined style={{ fontSize: 50,marginLeft: "-25px" }} spin />}}
                    onChange={handleTableChange}
                    size={"small"}
                    scroll={{x: '70%'}}
                    locale={{ emptyText: <EmptyTable text={"No Data"} />
                    }}
                  />
                </Fragment>
              </Col>
            </Row>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}
