import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, Tabs, Form, Spin, Row, Col, Divider, InputNumber, Typography, Modal} from 'antd';

import MaterialTableContainer from "../../../CompanyMaterials/MaterialTableContainer";
import {ValueTable} from "../../../CompanyMaterials/MaterialTables/ValueTable";
import {LoadingOutlined} from "@ant-design/icons";
import {
  fetchProducerMaterialPrices,
  fetchProducerMachiningMaterials,
  fetchProducerTubeMaterialPrices,
  fetchProducerStandardTubeMaterialPrices, fetchProducerMaterialCertificates
} from "../../../../../actions/producerParamsActions";
import { MachiningMaterialList } from "../../../CompanyMaterials/MachiningMaterialList";
import MaterialTableContainerNested from "../../../CompanyMaterials/MaterialTableContainerNested";
import {ModalTable} from "../../../CompanyMaterials/MaterialTables/ModalTable";
import {Trans} from "react-i18next";
import {ProducerMaterialInput} from "../../NonEditableFields";
import {EmptyTable} from "../../../CompanyMaterials/MaterialTables/EmptyTable";
import {TableHeader} from "../../../CompanyMaterials/MaterialTables/TableHeader";

const { TabPane } = Tabs;

export const MaterialsTab = (props) => {
  const {
    selectedTab, producerId,
  } = props

  const [shouldUpdatePrices, setShouldUpdatePrices] = useState(false)
  const [tubeModalVisible, setTubeModalVisible] = useState(false);
  const [selectedTubeData, setSelectedTubeData] = useState({});

  const materialPrices = useSelector(state => state.producerParams.materialPrices)
  const isFetchingMaterialPrices = useSelector(state => state.producerParams.isFetchingMaterialPrices)
  const isFetchingMachiningMaterials = useSelector(state => state.producerParams.isFetchingMachiningMaterials)
  const isFetchingMaterialCertPrices = useSelector(state => state.producerParams.isFetchingMaterialCertPrices)
  const baseMaterialCertFee = useSelector(state => state.producerParams.baseMaterialCertFee)
  const assemblyMaterialCertFee = useSelector(state => state.producerParams.assemblyMaterialCertFee)
  const machiningMaterials = useSelector(state => state.producerParams.machiningMaterials)
  const hasMachining = useSelector(state => state.producerParams.hasMachining)

  const isFetchingTubeMaterialPrices = useSelector(state => state.producerParams.isFetchingTubeMaterialPrices)
  const tubeCuttingMaterials = useSelector(state => state.producerParams.tubeCuttingMaterials)
  const hasTubeCutting = useSelector(state => state.producerParams.hasTubeCutting)
  const isFetchingStandardTubeMaterialPrices = useSelector(state => state.producerParams.isFetchingStandardTubeMaterialPrices)
  const standardTubeMaterialPrices = useSelector(state => state.producerParams.standardTubeMaterialPrices)

  const certificateForm = React.createRef();
  const dispatch = useDispatch()

  useEffect(()=>{
    if(selectedTab === "7") {
      dispatch(fetchProducerMaterialPrices({producerId})).then(()=>{
        setShouldUpdatePrices(true)
      })
      dispatch(fetchProducerMachiningMaterials({producerId}))
      dispatch(fetchProducerTubeMaterialPrices({producerId})).then(() => {
        setShouldUpdatePrices(true)
      })
      dispatch(fetchProducerStandardTubeMaterialPrices({producerId}))
      dispatch(fetchProducerMaterialCertificates({producerId}))
    }
  }, [selectedTab])

  useEffect(() => {
    certificateForm.current.setFieldsValue({
      "baseMaterialCertFee": baseMaterialCertFee
    })
  }, [baseMaterialCertFee])

  useEffect(() => {
    certificateForm.current.setFieldsValue({
      "assemblyMaterialCertFee": assemblyMaterialCertFee
    })
  }, [assemblyMaterialCertFee])

  const handleTubeMaterialModalOpen = (params) => {
    setSelectedTubeData({
      gradeName: params.gradeName,
      materialId: params.materialId,
      groupName: params.groupName,
      x: params.materialThickness,
      y: params.materialGrade,
      tab: params.tab,
      grade: params.gradeTabIndex,
      data: params.tubeData,
      col: params.col,
      row: params.row
    })
    setTubeModalVisible(true)
  }

  const handleTubeMaterialModalClose = () => {
    setSelectedTubeData({});
    setTubeModalVisible(false)
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard tabMaterialCardMargin"} bodyStyle={{paddingRight: 0, paddingBottom: 10, paddingTop: 8}}>
        <div style={{height: 'calc(100vh - 158px)', overflowY: materialPrices.length === 0 ? "hidden" : "auto", overflowX: "hidden", paddingRight: 15}}>
          <Row>
            <Col span={24}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2 }}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Material Certificates:</span>
                </div>
                <Form ref={certificateForm}
                      style={{ width: "100%", marginLeft: 12}}
                >
                  <Spin
                    spinning={isFetchingMaterialCertPrices}
                    style={{zIndex: 1}}
                    indicator={<LoadingOutlined style={{ fontSize: 32, position: "absolute", left: 237, top: 17 }} spin />}
                  >
                    <Card
                      style={{ width: "100%" }}
                      bodyStyle={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 2 }}
                    >
                      <div style={{display: "flex", alignItems: "center", marginLeft: 12}}>
                        <div style={{display: "flex", alignItems: "center", marginTop: 5, marginBottom: 5}}>
                          <ProducerMaterialInput
                            label={"Base Part Cost"}
                            value={baseMaterialCertFee}
                            suffix={<span>€</span>}
                            disabled={false}
                          />
                        </div>
                      </div>
                      <Divider type={"vertical"} style={{ height: "24px", margin: "0 12px", borderColor: "#838f94" }} />
                      <div style={{display: "flex", alignItems: "center", marginTop: 5, marginBottom: 5}}>
                        <ProducerMaterialInput
                          label={"Assembly Part Cost"}
                          value={assemblyMaterialCertFee}
                          suffix={<span>€</span>}
                          disabled={false}
                        />
                      </div>
                    </Card>
                  </Spin>
                </Form>
              </div>
            </Col>
            <Col flex={"285px"}>
              <div style={{display: "flex", alignItems: "center", marginBottom: 2}}>
                <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Machining:</span>
                <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
              </div>
              <Spin
                spinning={isFetchingMachiningMaterials}
                style={{zIndex: 1}}
                indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-12px, -8px)" }} spin />}
              >
                {(machiningMaterials.availableMaterials.length === 0 || !hasMachining) ?
                  <Card
                    bordered={false}
                    style={{borderRadius: 5}}
                    bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: "calc(100vh - 244px)", minHeight: 670}}
                  >
                    {(!hasMachining && !isFetchingMachiningMaterials)  &&
                    <div style={{width: "100%", height: "60%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <div style={{fontSize: 16, fontWeight: 600, color: "#aaaaaa", textAlign: "center"}}>
                        You do not have machining enabled
                      </div>
                    </div>
                    }
                  </Card>
                  :
                  <div>
                    <TableHeader columns={[
                      {title: "Name", width: 130},
                      {title: "Price (€/kg)", width: 140}
                    ]}/>
                    <Card
                      className={"tableCard"}
                      style={{width: 285}}
                      bodyStyle={{height: "calc(100vh - 285px)", maxWidth: 300, minHeight: 670}}
                    >
                      <MachiningMaterialList
                        rows={machiningMaterials.availableMaterials}
                        allAvailable={machiningMaterials.allAvailable}
                        onChange={() => {}}
                        updateTable={()=>{}}
                        setUpdateTable={()=>{}}
                        isEditable={false}
                      />
                    </Card>
                  </div>
                }
              </Spin>
            </Col>
            <Divider type="vertical" style={{height: 'calc(100vh - 230px)', margin: "13px 15px 0 15px", borderColor: "#1e1e1e"}}/>
            <Col flex={"auto"}>
              <div style={{display: "flex", alignItems: "center", marginBottom: 2}}>
                <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Sheet Metal:</span>
                <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
              </div>
              <Form layout={'horizontal'}>
                <div className={"newLine"}>
                  <div className={"customColumn"}>
                    <div style={{maxWidth: "100%"}}>
                      <Spin spinning={isFetchingMaterialPrices}
                            style={{zIndex: 1}}
                            indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
                      >
                        {materialPrices.length === 0 ?
                          <Card bordered={false}
                                bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: 336}}
                                style={{marginBottom: 0, borderRadius: 5}}
                          >

                          </Card>
                          :
                          <Tabs style={{marginLeft: -10, marginBottom: 0}}
                                type="card"
                                className={"laserSpeedTabs"}
                          >
                            {materialPrices.map((item, index) => {
                              return (
                                <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                                  <MaterialTableContainer
                                    tableText={"Material Price (€/kg)"}
                                    xAxisText={"Available Thickness (mm)"}
                                    yAxisText={"Material Grade"}
                                    xAxisStyle={{marginTop: 12, marginBottom: -12}}
                                  >
                                    <ValueTable
                                      columns={item.columns}
                                      rows={item.rows}
                                      allAvailable={item.allAvailable}
                                      tabIndex={index}
                                      onChange={()=>{}}
                                      updateTable={shouldUpdatePrices}
                                      setUpdateTable={setShouldUpdatePrices}
                                      leftColumnWidth={140}
                                      referenceHeight={248}
                                      isEditable={false}
                                    />
                                  </MaterialTableContainer>
                                </TabPane>
                              )
                            })}
                          </Tabs>
                        }
                      </Spin>
                    </div>
                  </div>
                </div>
              </Form>
              <div style={{display: "flex", alignItems: "center", marginBottom: 2, marginTop: 4}}>
                <span className={"mainFromLabel"} style={{color: "white", fontSize: 18, fontWeight: 600}}>Tube Cutting:</span>
                <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 10px"}}/>
              </div>
              <Form layout={'horizontal'}>
                <div className={"newLine"}>
                  <div className={"customColumn"}>
                    <div style={{maxWidth: "100%"}}>
                      <Row gutter={15} style={{width: "100%", marginRight: 0, marginLeft: 0}}>
                        <Col flex="285px" style={{paddingLeft: 0}}>
                          <Spin
                            spinning={isFetchingTubeMaterialPrices}
                            style={{zIndex: 1}}
                            indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-25%, -50%)" }} spin />}
                          >
                            {(tubeCuttingMaterials.availableMaterials.length === 0 || !hasTubeCutting) ?
                              <Card
                                className={"tableCard"}
                                style={{width: 285}}
                                bodyStyle={{maxWidth: 300, minHeight: 298, height: "calc(100vh - 615px)"}}
                              >
                                {(!hasTubeCutting && !isFetchingTubeMaterialPrices)  &&
                                <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                  <div style={{fontSize: 16, fontWeight: 600, color: "#aaaaaa", textAlign: "center"}}>
                                    You do not have Tube Cutting enabled
                                  </div>
                                </div>
                                }
                              </Card>
                              :
                              <div>
                                <TableHeader columns={[
                                  {title: "Name", width: 180},
                                  {title: "Price (€/kg)", width: 90}
                                ]}/>
                                <Card
                                  className={"tableCard"}
                                  style={{width: 285}}
                                  bodyStyle={{maxWidth: 300, minHeight: 298, height: "calc(100vh - 655px)"}}
                                >
                                  <MachiningMaterialList
                                    rows={tubeCuttingMaterials.availableMaterials}
                                    allAvailable={tubeCuttingMaterials.allAvailable}
                                    onChange={() => {}}
                                    updateTable={() => {}}
                                    setUpdateTable={() => {}}
                                    isEditable={false}
                                    leftWidth={190}
                                    rightWidth={70}
                                    gradeAlignLeft={true}
                                  />
                                </Card>
                              </div>
                            }
                          </Spin>
                        </Col>
                        <Col flex="auto" style={{paddingRight: 0}}>
                          <Spin
                            spinning={isFetchingStandardTubeMaterialPrices}
                            style={{zIndex: 1}}
                            wrapperClassName={"largeTubeTable"}
                            indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-50%, -50%)" }} spin />}
                          >
                            {(standardTubeMaterialPrices.length === 0 || !hasTubeCutting) ?
                              <Card
                                bordered={false}
                                bodyStyle={{border: "1px solid #f0f0f0", borderRadius: 5, height: "calc(100vh - 615px)", minHeight: 310}}
                                style={{marginBottom: 0, borderRadius: 5, width: "100%"}}
                              >
                                {(!hasTubeCutting && !isFetchingStandardTubeMaterialPrices)  &&
                                <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                  <div style={{fontSize: 18, fontWeight: 600, color: "#aaaaaa", textAlign: "center"}}>
                                    You do not have Tube Cutting enabled
                                  </div>
                                </div>
                                }
                              </Card>
                              :
                              <Tabs
                                style={{marginLeft: -10, marginBottom: 0}}
                                type="card"
                                className={"laserSpeedTabs"}
                              >
                                {standardTubeMaterialPrices.map((group, index) => {
                                  return (
                                    <TabPane className={"materialsTabPane tubesTabPane"} tab={group.groupName} key={index}>
                                      <Card
                                        className={'laserSpeedCard'}
                                        bodyStyle={{ padding: "8px 0 0 0", height: "calc(100vh - 645px)", minHeight: 308}}
                                      >
                                        {group.grades.length === 0 ?
                                          <div
                                            className={"emptyTableContainer"}
                                            style={{marginLeft: 10, marginRight: 15, marginBottom: 10}}
                                          >
                                            <EmptyTable height={"calc(100% - 12px)"} text={"No General Grade Price Set"}/>
                                          </div>
                                          :
                                          <Tabs
                                            style={{marginBottom: 0}}
                                            type="card"
                                            tabPosition="left"
                                            className={"laserSpeedTabs gradeSideTabs"}
                                          >
                                            {group.grades.map((item, subIndex) => {
                                              return (
                                                <TabPane style={{paddingLeft: 0}} tab={item.gradeName} key={subIndex}>
                                                  {item.tubeType === 'tube' ?
                                                    <MaterialTableContainerNested
                                                      tableText={`Tube Price (€/kg)`}
                                                      xAxisText={"X dimension (mm)"}
                                                      yAxisText={"Y dimension (mm)"}
                                                      isProducer={true}
                                                    >
                                                      <ModalTable
                                                        columns={item.columns}
                                                        rows={item.rows}
                                                        allAvailable={item.allAvailable}
                                                        tabIndex={index}
                                                        gradeTabIndex={subIndex}
                                                        onClick={handleTubeMaterialModalOpen}
                                                        groupName={group.groupName}
                                                        gradeName={item.gradeName}
                                                        updateTable={false}
                                                        setUpdateTable={()=>{}}
                                                        activeLoadingTabs={{}}
                                                        nestedTable={true}
                                                        referenceHeight={244}
                                                        isEditable={false}
                                                      />
                                                    </MaterialTableContainerNested>
                                                    :
                                                    <MaterialTableContainerNested
                                                      tableText={`Tube Price (€/kg)`}
                                                      xAxisText={"Diameter (mm)"}
                                                      yAxisText={"Thickness (mm)"}
                                                      isProducer={true}
                                                    >
                                                      <ValueTable
                                                        columns={item.columns}
                                                        rows={item.rows}
                                                        allAvailable={item.allAvailable}
                                                        tabIndex={index}
                                                        gradeTabIndex={subIndex}
                                                        onChange={() => {}}
                                                        updateTable={false}
                                                        setUpdateTable={()=>{}}
                                                        nestedTable={true}
                                                        leftColumnWidth={45}
                                                        referenceHeight={244}
                                                        isEditable={false}
                                                      />
                                                    </MaterialTableContainerNested>
                                                  }
                                                </TabPane>
                                              )
                                            })}
                                          </Tabs>
                                        }
                                      </Card>
                                    </TabPane>
                                  )
                                })}
                              </Tabs>
                            }
                          </Spin>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Card>
      <Modal
        visible={tubeModalVisible}
        onCancel={handleTubeMaterialModalClose}
        title={"Tube Prices"}
        footer={<div style={{ height: 20 }}/>}
        centered={true}
        bodyStyle={{ height: 660 }}
        destroyOnClose={true}
        zIndex={1}
        width={550}
      >
        <React.Fragment>
          <div style={{ fontWeight: 500 }}>
            <div style={{color: "white"}}>
              <div>
                <div>Tube Grade: <Trans>{selectedTubeData.gradeName}</Trans></div>
                <div>Tube Dimensions: {`${selectedTubeData.x}x${selectedTubeData.y}`}mm</div>
              </div>
              <div className={"modalDataContainer"}>
                {selectedTubeData?.data?.map((item) => (
                  <div className={"checkboxStyle"} key={item.id}>
                    <Card
                      className={"bendCard"}
                      style={{ marginTop: 3, marginBottom: 3}}
                      bodyStyle={{paddingTop: 8, paddingBottom: 8}}
                    >
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>Thickness: {item.thickness} mm</div>
                        <div style={{display: "flex", alignItems: "center"}}>
                          <ProducerMaterialInput
                            style={{ marginTop: 10, marginBottom: 10 }}
                            label={"Price"}
                            value={item.price || 0}
                            suffix={"€/kg"}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  )
}