import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchCustomerArchivedOrders, fetchCustomerProcessedOrders} from "../../../actions/ordersActions";
import {Sidebar} from "../../../components";
import {logoutUser} from "../../../actions/authActions";
import {Col, Divider, Row, Layout, Table, Tag, Button} from "antd";
import {formatTableDate, getColumnSearchProps} from "../../../utils/utility";
import history from "../../../utils/history";
import {LoadingOutlined} from "@ant-design/icons";
import {EmptyTable} from "../../../components/EmptyTable";

export const CustomerProcessedPage = (props) => {
  let searchInput = null;

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user);
  const isFetchingProcessedOrders = useSelector(state => state.orders.isFetchingCustomerProcessedOrders)
  const processedOrders = useSelector(state => state.orders.customerProcessedOrders)
  const pagination = useSelector(state => state.orders.customerProcessedOrdersPagination)
  const dispatch = useDispatch()

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  useEffect(() => {
    dispatch(fetchCustomerProcessedOrders({
      sortField: "lastStateChangeDate",
      sortDirection: "desc",
      orderName: "",
      customName: "",
      state: "",
      page: pagination.current !== undefined ? pagination.current : 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const sortField = sorter.order ? sorter.columnKey : "lastStateChangeDate"
    const sortDirection = sorter.order ? (sorter.order === "ascend" ? "asc" : "desc") : "desc"
    const orderName = filters.orderName ? filters.orderName[0] : ""
    const customName = filters.customName ? filters.customName[0] : ""
    const state = filters.state && filters.state.length === 1 ? filters.state[0] : ""

    dispatch(fetchCustomerProcessedOrders({
      sortField: sortField,
      sortDirection: sortDirection,
      orderName: orderName,
      customName: customName,
      page: pagination.current,
      pagination: pager,
      state: state
    }))
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <Sidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
          <Row>
            <Col span={24}>
              <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                <div className={"pageTitle"}>
                  Processed quotes:
                </div>
              </div>
              <Divider style={{marginTop: 6, marginBottom: 11}}/>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <React.Fragment>
                <Table
                  columns={[
                    {
                      title: 'Quote Code',
                      key: 'orderName',
                      className: "darkerColumn",
                      width: "10%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {row.orderName}
                        </div>
                      ),
                      ...getColumnSearchProps('code', searchInput),
                    },
                    {
                      title: "Quote Name",
                      key: "customName",
                      ellipsis: {showTitle: false},
                      width: "20%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {row.customName || "/"}
                        </div>
                      ),
                      ...getColumnSearchProps('customName', searchInput,"orders"),
                    },
                    {
                      title: 'Quote Date',
                      key: 'orderDate',
                      className: "darkerColumn",
                      width: "12%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {formatTableDate(row.orderDate)}
                        </div>
                      ),
                      sorter: true,
                    },
                    {
                      title: 'Delivery Date',
                      key: 'deliveryDate',
                      width: "12%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {formatTableDate(row.deliveryDate)}
                        </div>
                      ),
                      sorter: true,
                    },
                    {
                      title: 'Total Price',
                      key: 'totalWithVat',
                      className: "darkerColumn",
                      align: "right",
                      width: "13%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {(row.totalPrice * (1 + row.vatValue) || row.totalPrice || 0).toFixed(2)}€
                        </div>
                      ),
                    },
                    {
                      title: 'Processed Date',
                      key: 'completionDate',
                      align: "right",
                      width: "12%",
                      render: row => (
                        <div style={{fontWeight: 500, fontSize: 13}}>
                          {formatTableDate(row.lastStateChangeDate)}
                        </div>
                      ),
                      sorter: true,
                    },
                    {
                      title: 'Status',
                      key: 'state',
                      className: "darkerColumn",
                      align: "center",
                      width: "200px",
                      render: row => (
                        <div>
                          <React.Fragment>
                            {row.state === "accepted" ?
                              <Tag color="#6bce34" style={{fontWeight: 600, color: "black", textAlign: "center", width: 89}}>Accepted</Tag>
                              :
                              <Tag color="#cc332c" style={{fontWeight: 600, textAlign: "center", width: 89}}>Declined</Tag>
                            }
                          </React.Fragment>
                        </div>
                      ),
                      filters: [{
                        text: 'Declined',
                        value: 'declined',
                      }, {
                        text: 'Completed',
                        value: 'completed',
                      }],
                    },
                    {
                      title: '',
                      key: 'more',
                      align: "right",
                      render: row => (
                        <Button
                          onMouseDown={(e) => {
                            if (e.button === 1) {
                              const win = window.open(`/completed-order?order=${row.id}`, "_blank");
                              win.focus();
                            } else if (e.button === 0) {
                              history.push(`/completed-order?order=${row.id}`)
                            }
                          }}
                          type="primary"
                          style={{
                            fontSize: 13,
                            fontWeight: 600
                          }}
                        >
                          Details
                        </Button>
                      ),
                    },
                  ]}
                  className={"antTable"}
                  dataSource={processedOrders}
                  rowKey="id"
                  pagination={{...pagination, pageSize: pageSize}}
                  loading={{spinning: isFetchingProcessedOrders, indicator: <LoadingOutlined style={{ fontSize: 50,marginLeft: "-25px" }} spin />}}
                  onChange={handleTableChange}
                  size={"small"}
                  scroll={{x: '70%'}}
                  locale={{ emptyText: <EmptyTable text={"No Data"} /> }}
                />
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  )
}