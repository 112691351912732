import {
  MATERIALS_FETCHING_MATERIAL_CERTIFICATE_PRICES,
  MATERIALS_FETCHING_MATERIAL_PRICES,
  MATERIALS_SET_MATERIAL_CERTIFICATE_PRICES,
  MATERIALS_SET_MATERIAL_PRICES,
  MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE,
  MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE,
  MATERIALS_UPDATE_MATERIAL_PRICE
} from '../actions/actionTypes'
import initialState from './initialState'

const materialsReducer = (state = initialState.materials, action) => {
  switch (action.type) {

    case MATERIALS_FETCHING_MATERIAL_PRICES:
      return {
        ...state,
        isFetchingMaterialPrices: action.payload,
      }

    case MATERIALS_SET_MATERIAL_PRICES:
      return {
        ...state,
        materialPrices: action.payload,
      }

    case MATERIALS_UPDATE_MATERIAL_PRICE: {
      let tmp = [...state.materialPrices]
      tmp[action.payload.tab].rows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        materialPrices: [...tmp]
      }
    }

    case MATERIALS_FETCHING_MATERIAL_CERTIFICATE_PRICES:
      return {
        ...state,
        isFetchingMaterialCertPrices: action.payload,
      }

    case MATERIALS_SET_MATERIAL_CERTIFICATE_PRICES:
      return {
        ...state,
        ...action.payload,
      }

    case MATERIALS_UPDATE_BASE_MATERIAL_CERTIFICATE_PRICE:
      return {
        ...state,
        baseMaterialCertFee: action.payload,
      }

    case MATERIALS_UPDATE_ASSEMBLY_MATERIAL_CERTIFICATE_PRICE:
      return {
        ...state,
        assemblyMaterialCertFee: action.payload,
      }

    default:
      return state
  }
}

export default materialsReducer
