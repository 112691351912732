import React, {useEffect, useState} from 'react';
import {
  Card, Tabs,
  Divider, Modal,
  Input, Spin, Button
} from 'antd';

import "./style.less"
import {
  LoadingOutlined,
} from "@ant-design/icons";
import MaterialTableContainer from "../../../CompanyMaterials/MaterialTableContainer";
import {ValueTable} from "../../../CompanyMaterials/MaterialTables/ValueTable";
import {WaterJetIcon} from "../../../../../assets/icons/waterJetIcon";
import {getToleranceValue, useWindowSize} from "../../../../../utils/utility";
import {lineBreakLimit} from "../../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchProducerCuttingMachineParameters, fetchProducerCuttingMachinePriorities,
  fetchProducerCuttingMachines
} from "../../../../../actions/producerParamsActions";
import {ProducerMaterialInput} from "../../NonEditableFields";
import {MachineSelect, MachineTolerancesModal} from "../../../MachineSelect";

const {TabPane} = Tabs;

/*
    laser types:
    CO₂ Laser
    Fiber Laser
    Nd:YAG Laser
    CNC Gas
    CNC Plasma
    Water Jet
*/

export const LaserCuttingTab = (props) => {

  const {selectedTab, producerId} = props;
  const [machinePriorityModalVisible, setMachinePriorityModalVisible] = useState(false)
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [shouldUpdateSpeeds, setShouldUpdateSpeeds] = useState(false)
  const [machineTolerancesModalVisible, setMachineTolerancesModalVisible] = useState(false);

  const cuttingMachines = useSelector(state => state.producerParams.cuttingMachines)
  const isFetchingMachines = useSelector(state => state.producerParams.isFetchingMachines)

  const isFetchingMachineTypes = useSelector(state => state.producerParams.isFetchingMachineTypes)

  const machinePriorities = useSelector(state => state.producerParams.machinePriorities)
  const isFetchingMachinePriorities = useSelector(state => state.producerParams.isFetchingMachinePriorities)

  const machineData = useSelector(state => state.producerParams.selectedMachine)
  const isFetchingSelectedMachine = useSelector(state => state.producerParams.isFetchingSelectedMachine)

  const [machinePriority, setMachinePriority] = useState(machinePriorities)
  const [fetchingAll, setFetchingAll] = useState(false)

  const [rowFlex, setRowFlex] = useState(false);
  const dispatch = useDispatch();

  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width >= 1153 && windowSize.width <= 1426) {
      setRowFlex(true)
    } else {
      setRowFlex(false);
    }
  }, [windowSize.width])

  useEffect(() => {
    if (selectedTab === "1") {
      setFetchingAll(true)
      dispatch(fetchProducerCuttingMachines({producerId})).then((machines) => {
        if (machines.length !== 0) {
          if (selectedMachine) {
            let doesExist = machines.find(m => {
              return m?.machines.find(item => item.id === selectedMachine)
            })
            if (doesExist) {
              dispatch(fetchProducerCuttingMachineParameters({
                cuttingMachineId: selectedMachine,
                producerId: producerId
              })).then(() => {
                setFetchingAll(false)
              })
            } else {
              setSelectedMachine(machines[0].machines[0].id)
              dispatch(fetchProducerCuttingMachineParameters({
                cuttingMachineId: machines[0].machines[0].id,
                producerId: producerId
              })).then(() => {
                setFetchingAll(false)
              })
            }
          } else {
            setSelectedMachine(machines[0].machines[0].id)
            dispatch(fetchProducerCuttingMachineParameters({
              cuttingMachineId: machines[0].machines[0].id,
              producerId: producerId
            })).then(() => {
              setFetchingAll(false)
            })
          }
        } else {
          setFetchingAll(false)
        }
      }).catch(() => {
      })
      dispatch(fetchProducerCuttingMachinePriorities({producerId}))
    }
  }, [selectedTab])

  const changeSelectedMachine = (id) => {
    setSelectedMachine(id)
    dispatch(fetchProducerCuttingMachineParameters({cuttingMachineId: id, producerId: producerId})).then(() => {
      setShouldUpdateSpeeds(true)
    })
  }

  const handleMachinePriorityOpen = () => {
    setMachinePriority(machinePriorities)
    setMachinePriorityModalVisible(true)
  }

  const handleMachinePriorityClose = () => {
    setMachinePriorityModalVisible(false)
  }

  const handleMachineTolerancesOpen = () => {
    setMachineTolerancesModalVisible(true)
  }

  const handleMachineTolerancesClose = () => {
    setMachineTolerancesModalVisible(false)
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard tabMaterialCardMargin"} bodyStyle={{paddingRight: 0}}>
        <div style={{height: 'calc(100vh - 172px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          <div className={windowSize.width > lineBreakLimit ? "inLine" : "newLIne"}>
            <div className={"customColumn2"} style={{width: "100%", maxWidth: 1605}}>
              <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'flex-end'}}>
                <MachineSelect
                  type={"laserCutting"}
                  isFetchingMachines={isFetchingMachines}
                  selectedMachine={selectedMachine}
                  changeSelectedMachine={changeSelectedMachine}
                  machines={cuttingMachines}
                  isFetchingMachineTypes={isFetchingMachineTypes}
                  handleEditMachine={() => {
                  }}
                  handleRemoveMachine={() => {
                  }}
                  handleAddMachineOpen={() => {
                  }}
                  isFetchingMachinePriorities={isFetchingMachinePriorities}
                  handleMachinePriorityOpen={handleMachinePriorityOpen}
                  machinePriorities={machinePriorities}
                  allowPriorityChange={true}
                  allowAddMachine={false}
                  hasTolerances={selectedMachine || false}
                  isFetchingMachineTolerances={isFetchingSelectedMachine}
                  handleMachineTolerancesOpen={handleMachineTolerancesOpen}
                  showButtons={false}
                />
              </div>
            </div>
          </div>
          <Divider style={{width: "75%", minWidth: "0%", margin: "0 0 16px 0"}}/>
          <Spin spinning={fetchingAll || isFetchingMachines || isFetchingSelectedMachine}
                indicator={<LoadingOutlined style={{fontSize: 72}} spin/>}
                style={{
                  position: "absolute",
                  width: "100%",
                  top: machineData.id ? "41px" : "36%",
                  left: machineData.id ? "-40px" : "-10px",
                }}
          >
            {machineData.id &&
            <div className={windowSize.width > lineBreakLimit ? "inLine" : "newLIne"}>
              <div className={"customColumn3"}>
                <div className={"column3CardContainer"}>
                  <Card style={{marginBottom: 16}} bodyStyle={{padding: "8px 16px"}}>
                    <ProducerMaterialInput
                      style={{marginBottom: 12}}
                      label={"Maximum cutting length"}
                      _labelWidth={150}
                      value={machineData.maxCuttingLength}
                      suffix={"mm"}
                    />
                    <ProducerMaterialInput
                      label={"Maximum cutting width"}
                      _labelWidth={150}
                      value={machineData.maxCuttingWidth}
                      suffix={"mm"}
                    />
                  </Card>
                </div>
                <Tabs style={{marginLeft: -10, marginBottom: 20}}
                      type="card"
                      className={"laserSpeedTabs"}
                >
                  {machineData.tables.map((item, index) => (
                    <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                      <MaterialTableContainer
                        tableText={"Cutting speed by material Grade and material Thickness (m/min)"}
                        xAxisText={"Available Thickness (mm)"}
                        yAxisText={"Material Grade"}
                        xAxisStyle={{marginTop: 12, marginBottom: -12}}
                      >
                        <ValueTable
                          columns={item.columns}
                          rows={item.rows}
                          allAvailable={item.allAvailable}
                          tabIndex={index}
                          onChange={() => {}}
                          updateTable={shouldUpdateSpeeds}
                          setUpdateTable={setShouldUpdateSpeeds}
                          leftColumnWidth={140}
                          referenceHeight={248}
                          isEditable={false}
                        />
                      </MaterialTableContainer>
                    </TabPane>
                  ))}
                </Tabs>
              </div>
              <div className={`${rowFlex ? "customColumn5" : "customColumn4"}`}>
                <Card bodyStyle={{padding: "16px"}}>
                  <div style={{
                    display: "flex",
                    flexDirection: rowFlex ? "row" : "column",
                    height: "100%",
                    width: "100%",
                    alignItems: "flex-start"
                  }}>
                    <div style={{width: "100%", maxWidth: 430}}>
                      <div className={"producerDetailEntry"}>
                        <span className={"producerMaterialLabel"}>
                          Cutting price:
                        </span>
                        <Input
                          style={{maxWidth: 100}}
                          value={machineData.cuttingPrice}
                          disabled
                        />
                        <span style={{marginLeft: 12}}>€/h</span>
                      </div>
                      <div align={'right'}>
                        <div className={"producerDetailEntry"} style={{justifyContent: "flex-end"}}>
                          <span className={"producerMaterialLabel"}>
                            Average positioning speed:
                          </span>
                          <Input
                            style={{maxWidth: 100}}
                            value={machineData.averagePositioningSpeed}
                            disabled
                          />
                          <span style={{marginLeft: 12}}>m/min</span>
                        </div>
                      </div>
                      <Divider className={"laserDivider"} style={{marginBottom: 18}}/>
                      <div className={"producerDetailEntry"}>
                        <span className={"producerMaterialLabel"}>
                          Preparation price:
                        </span>
                        <Input
                          style={{maxWidth: 100}}
                          value={machineData.preparationPrice}
                          disabled
                        />
                        <span style={{marginLeft: 12}}>€/h</span>
                      </div>
                      <div align={'right'}>
                        <div className={"producerDetailEntry"} style={{justifyContent: "flex-end"}}>
                          <span className={"producerMaterialLabel"}>
                            Average preparation time:
                          </span>
                          <Input
                            style={{maxWidth: 100}}
                            value={machineData.averagePreparationTime}
                            disabled
                          />
                          <span style={{marginLeft: 12}}>min</span>
                        </div>
                      </div>
                      <Divider className={"laserDivider"} style={{marginTop: 15, marginBottom: 15}}/>
                      {rowFlex &&
                        <div style={{ width: "100%", minWidth: 0}}>
                        <div className={"producerDetailEntry"}>
                          <ProducerMaterialInput
                            style={{
                              display: "flex",
                              width: "100%",
                              paddingRight: 0
                            }}
                            inputWidth={200}
                            label={"Maximum Tolerance"}
                            value={getToleranceValue(machineData.maxTolerance, "other")}
                            suffix={""}
                          />
                        </div>
                        <div style={{ marginTop: 8, display: "flex", width: "100%", justifyContent: "center"}}>
                          <Button
                            className={"toleranceButton d6ButtonDark"}
                            style={{fontSize: 13}}
                            onClick={() => handleMachineTolerancesOpen()}
                            disabled={false}
                          >
                            <span style={{fontWeight: 600}}>View Tolerance Markup</span>
                          </Button>
                        </div>
                      </div>
                      }
                    </div>
                    {rowFlex && <Divider type={"vertical"} style={{height: "256px", margin: "0 10px"}}/>}
                    <div>
                      <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <div className={"producerDetailEntry"}>
                          <span className={"producerMaterialLabel"} style={{width: 160}}>
                            Average Piercing time:
                          </span>
                          <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: 74,
                            justifyContent: "space-between",
                            paddingTop: 4
                          }}>
                            <div>
                              <Input
                                style={{maxWidth: 60}}
                                value={machineData.pierceTimeLow}
                                disabled
                              />
                              <span style={{marginLeft: 8}}>sec</span>
                              <span style={{marginLeft: 17}}>for thickness</span>
                              <span style={{marginLeft: 14}}>≤</span>
                            </div>
                            <div>
                              <Input
                                style={{maxWidth: 60}}
                                value={machineData.pierceTimeHigh}
                                disabled
                              />
                              <span style={{marginLeft: 8}}>sec</span>
                              <span style={{marginLeft: 17}}>for thickness</span>
                              <span style={{marginLeft: 14}}>></span>
                            </div>
                          </div>
                          <div style={{marginTop: 2}}>
                            <Input
                              style={{maxWidth: 60, marginLeft: 14}}
                              value={machineData.pierceTimeThreshold}
                              disabled
                            />
                            <span style={{marginLeft: 8}}>mm</span>
                          </div>
                        </div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 17}}>
                        <div className={"producerDetailEntry"}>
                          <span className={"producerMaterialLabel"} style={{width: 160}}>
                            Average approach length:
                          </span>
                          <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: 74,
                            justifyContent: "space-between",
                            paddingTop: 4
                          }}>
                            <div>
                              <Input
                                style={{maxWidth: 60}}
                                value={machineData.approachLengthLow}
                                disabled
                              />
                              <span style={{marginLeft: 8}}>mm</span>
                              <span style={{marginLeft: 16}}>for thickness</span>
                              <span style={{marginLeft: 14}}>≤</span>
                            </div>
                            <div>
                              <Input
                                style={{maxWidth: 60}}
                                value={machineData.approachLengthHigh}
                                disabled
                              />
                              <span style={{marginLeft: 8}}>mm</span>
                              <span style={{marginLeft: 16}}>for thickness</span>
                              <span style={{marginLeft: 14}}>></span>
                            </div>
                          </div>
                          <div style={{marginTop: 2}}>
                            <Input
                              style={{maxWidth: 60, marginLeft: 14}}
                              value={machineData.approachLengthThreshold}
                              disabled
                            />
                            <span style={{marginLeft: 8}}>mm</span>
                          </div>
                        </div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 17}}>
                        <div className={"producerDetailEntry"}>
                        <span className={"producerMaterialLabel"} style={{width: 160}}>
                          Average exit length:
                        </span>
                          <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: 74,
                            justifyContent: "space-between",
                            paddingTop: 4
                          }}>
                            <div>
                              <Input
                                style={{maxWidth: 60}}
                                value={machineData.exitLengthLow}
                                disabled
                              />
                              <span style={{marginLeft: 8}}>mm</span>
                              <span style={{marginLeft: 16}}>for thickness</span>
                              <span style={{marginLeft: 14}}>≤</span>
                            </div>
                            <div>
                              <Input
                                style={{maxWidth: 60}}
                                value={machineData.exitLengthHigh}
                                disabled
                              />
                              <span style={{marginLeft: 8}}>mm</span>
                              <span style={{marginLeft: 16}}>for thickness</span>
                              <span style={{marginLeft: 14}}>></span>
                            </div>
                          </div>
                          <div style={{marginTop: 2}}>
                            <Input
                              style={{maxWidth: 60, marginLeft: 14}}
                              value={machineData.exitLengthThreshold}
                              disabled
                            />
                            <span style={{marginLeft: 8}}>mm</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!rowFlex &&
                      <div style={{ width: "100%", minWidth: 0}}>
                        <Divider className={"laserDivider"} style={{marginBottom: 18}}/>
                        <div className={"producerDetailEntry"}>
                          <ProducerMaterialInput
                            style={{
                              display: "flex",
                              width: "100%",
                              paddingRight: 0
                            }}
                            inputWidth={200}
                            label={"Maximum Tolerance"}
                            value={getToleranceValue(machineData.maxTolerance, "other")}
                            suffix={""}
                          />
                        </div>
                        <div style={{ marginTop: 8, display: "flex", width: "100%", justifyContent: "center"}}>
                          <Button
                            className={"toleranceButton d6ButtonDark"}
                            style={{fontSize: 13}}
                            onClick={() => handleMachineTolerancesOpen()}
                            disabled={false}
                          >
                            <span style={{fontWeight: 600}}>View Tolerance Markup</span>
                          </Button>
                        </div>
                      </div>
                    }
                  </div>
                </Card>
              </div>
            </div>
            }
          </Spin>
        </div>
      </Card>

      <Modal
        visible={machinePriorityModalVisible}
        title={"Machine Priority"}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => {
          handleMachinePriorityClose()
        }}
        footer={null}
      >
        <div className={"machinePriorityContainer"} align={"center"}>
          <Card className={"machineCardContainer"}>
            {machinePriority.map((item, index) => (
              <div key={index}
                   className={"machineCard"}
              >
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{display: "flex"}}>
                    <WaterJetIcon/>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 500,
                        marginLeft: 10,
                        marginTop: 8,
                        color: "white"
                      }}>{item.name}</div>
                  </div>

                  <div style={{marginTop: 24, marginBottom: 5}}>
                    <div style={{fontSize: 10, fontWeight: 500, color: "#ccc"}}>Priority: {index + 1}</div>
                  </div>
                </div>
              </div>
            ))}
          </Card>
        </div>
      </Modal>

      <MachineTolerancesModal
        machineType={"cutting"}
        title={"Cutting Machine Tolerance Markup"}
        modalVisible={machineTolerancesModalVisible}
        handleCloseModal={handleMachineTolerancesClose}
        isLoading={isFetchingSelectedMachine}
        markupData={machineData?.markup}
        allowTolerancesChange={false}
      />

    </React.Fragment>
  )
}
