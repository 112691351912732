import React, {createRef, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import history from '../../utils/history'
import {
  Button, Card, Col, Layout, Row, Modal,
  InputNumber, Form, Spin, Divider, Tag,
  Tooltip, Input, Grid, Drawer
} from 'antd';

import "./style.less"
import {
  CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined,
  FileAddOutlined, FileDoneOutlined,
  FileZipOutlined, InfoCircleOutlined, ProfileOutlined,
  RedoOutlined, WarningOutlined, ExportOutlined,
} from "@ant-design/icons";
import {logoutUser} from "../../actions/authActions";
import DatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-GB";
registerLocale("en", en);
import "react-datepicker/dist/react-datepicker.css";

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

import {OrderDetailsManualQuote} from "../../components/Order/OrderDetailsManualQuote";
import {OrderDetailsAssemblyQuote} from "../../components/Order/OrderDetailsAssemblyQuote";
import {Sidebar} from "../../components";
import {
  addNewPart, fetchPendingOrder, fetchPendingOrderParts,
  quoteManualPart, editPendingOrder, resolveOrder,
  revertOrder, downloadOrderZip, setGeneratingZip, fetchProducersForOrder, quoteAssemblyProductionPrice, setOdooLink
} from "../../actions/ordersActions";
import {IconButton} from "@mui/material";
import {formatTableDate} from "../../utils/utility";
import {OrderDetailsPartProcessedPartsList} from "../../components/Order/OrderDetailsPartProcessedPartsList";
import ItemVisualisation from "../../components/Order/ItemVisualisation";
import {CompanySidebar} from "../../components/company";
import {OrderChanges} from "../../components/Order/OrderChanges";
import {OrderExcelExportButton} from "../../components/Order/OrderExcelExportButton";
import {OrderPdfExportButton} from "../../components/Order/OrderPdfExportButton";
import {changePartProducedQuantity} from "../../actions/ordersActions";
import {FetchingOrderIndicator} from "../../components/Order/FetchingOrderIndicator";
import {ProducersPricesModal} from "../../components/company/ProducersPricesModal";
import {DocumentsModal} from "../../components/Order/DocumentsModal";
import { imageSection, materialInfoSection } from "../../utils/partCardUtils/sections";
import {OrderDetailsPartUnprocessedPartsGrid} from "../../components/Order/OrderDetailsPartUnprocessedPartsGrid";
import {OrderViewOtherModal} from "../../components/Order/OrderCalculationComponents/SharedComponents/OrderViewOtherModal";
import {OrderDownloadFilesModal} from "../../components/Order/OrderCalculationComponents/SharedComponents/OrderDownloadFilesModal";
import UndoIcon from '@mui/icons-material/Undo';
import { add, getDay } from "date-fns";

const { useBreakpoint } = Grid;

export const OrderDetailsPage = () => {
  const form = createRef()

  const [isEditing, setIsEditing] = useState(false)
  const [isEditFinalisationVisible, setIsEditFinalisationVisible] = useState(false)
  const [viewVisible, setViewVisible] = useState(false)
  const [itemView, setItemView] = useState(null)
  const [modalWidth, setModalWidth] = useState(window.innerWidth-100)
  const [modalHeight, setModalHeight] = useState(window.innerHeight-135)
  const [editItem, setEditItem] = useState(null)
  const [isEditItemVisible, setIsEditItemVisible] = useState(false)
  const [hasBeenEdited, setHasBeenEdited] = useState(false)
  const [isDeletingItem, setIsDeletingItem] = useState(false)
  const [isManualQuoteModalVisible, setIsManualQuoteModalVisible] = useState(false)
  const [manualQuoteItem, setManualQuoteItem] = useState(null)
  const [isAssemblyQuoteModalVisible, setIsAssemblyQuoteModalVisible] = useState(null)
  const [assemblyQuoteItem, setAssemblyQuoteItem] = useState(null)
  const [isDetailsView, setIsDetailsView] = useState(false)
  const [otherItemView, setOtherItemView] = useState(null)
  const [isAddNewPartVisible, setIsAddNewPartVisible] = useState(false)

  const [additionalDocs, setAdditionalDocs] = useState([])
  const [additionalDocsVisible, setAdditionalDocsVisible] = useState(false)
  const [declineVisible, setDeclineVisible] = useState(false)
  const [acceptVisible, setAcceptVisible] = useState(false)
  const [sendQuoteVisible ,setSendQuoteVisible] = useState(false)
  const [completeVisible, setCompleteVisible] = useState(false)
  const [changesVisible, setChangesVisible]  = useState(false)
  const [odooVisible, setOdooVisible] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const [editMap, setEditMap] = useState({
    oldDeliveryDate: "",
    newDeliveryDate: "",
    oldTotalPrice: "",
    newTotalPrice: "",
    items : {}
  })

  const [selectedDate, setSelectedDate] = useState(null)

  const [filesItem, setFilesItem] = useState(null);
  const [downloadFilesVisible, setDownloadFilesVisible] = useState(false);

  const [allPricesModalVisible, setAllPricesModalVisible] = useState(false);
  const [pricesModalItem, setPricesModalItem] = useState({});

  const [quantityChangeVisible, setQuantityChangeVisible] = useState(false);
  const [selectedPartQuantityData, setSelectedPartQuantityData] = useState(null);
  const [assemblyData, setAssemblyData] = useState({});
  const [allPartsRejected, setAllPartsRejected] = useState(false);

  const user = useSelector(state => state.auth.user)
  const isFetchingPendingOrder = useSelector(state => state.orders.isFetchingPendingOrder)
  const orderDetails = useSelector(state => state.orders.pendingOrder)
  const isAddingNewPart = useSelector(state => state.orders.isAddingNewPart)
  const isQuotingAssembly = useSelector(state => state.orders.isQuotingAssembly)

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const isFetchingPendingOrderParts = useSelector(state => state.orders.isFetchingPendingOrderParts)
  const isEditingPendingOrder = useSelector(state => state.orders.isEditingPendingOrder)
  const isResolvingPendingOrder = useSelector(state => state.orders.isResolvingPendingOrder)
  const isRevertingOrder = useSelector(state => state.orders.isRevertingOrder)
  const isGeneratingZip = useSelector(state => state.orders.isGeneratingZip)
  const isFetchingProducersForOrder = useSelector(state => state.orders.isFetchingProducersForOrder)
  const isUpdatingOdooLink = useSelector(state => state.orders.isUpdatingOdooLink)
  const role = user?.role

  const firstCollapseThreshold = (role === "regularCustomer" || role === "corporateCustomer") ? 650 : 1000
  const secondCollapseThreshold = firstCollapseThreshold - 120
  const thirdCollapseThreshold = 730;
  const [collapseExportFirst, setCollapseExportFirst] = useState(window.innerWidth <= firstCollapseThreshold);
  const [collapseExportSecond, setCollapseExportSecond] = useState(window.innerWidth <= secondCollapseThreshold);
  const [collapseEdit, setCollapseEdit] = useState(window.innerWidth <= thirdCollapseThreshold);

  const [linkForm] = Form.useForm();
  const dispatch = useDispatch();
  const screens = useBreakpoint();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  const handleOrderReturn = () => {
    if(role === "gelsoAdmin" || role === "gelsoSubAdmin" ) {
      switch (history.location.pathname) {
        case('/pending-order'):
          history.push('/pending')
          break;
        case('/awaiting-order'):
          history.push('/awaiting')
          break;
        case('/completed-order'):
          history.push('/processed')
          break;
      }
    }
    else {
      switch (history.location.pathname) {
        case('/pending-order'):
          history.push('/pending-orders')
          break;
        case('/awaiting-order'):
          history.push('/')
          break;
        case('/completed-order'):
          history.push('/competed-orders')
          break;
      }
    }
  }

  const fetchOrderType = () => {
    switch (history.location.pathname) {
      case('/pending-order'):
        return "pending"
      case('/awaiting-order'):
        return "awaiting-confirmation"
      case('/completed-order'):
        return "completed"
    }
  }

  useEffect(()=>{
    if (role === "gelsoAdmin" || role === "gelsoSubAdmin" ) {
      dispatch(fetchProducersForOrder())
    }

    const id = new URLSearchParams(history.location.search).get('order')
    const type = fetchOrderType()
    if(id) {
      dispatch(fetchPendingOrder({id: id, type: type}))
        .then((order)=>{
          if(type === "pending") {
            let tmpAssemblies = {}
            for(let part of order.processedParts) {
              if(part.partType === "assembly") {
                tmpAssemblies[part.id] = {quantity: part.quantity, name: part.fileName}
              }
            }
            setAssemblyData(tmpAssemblies)
          }
          setSelectedDate(new Date(order.deliveryDate))
        })
        .catch(() => {
          if(type === "accepted") {
            history.push(`/completed-order?order=${id}`)
          } else {
            handleOrderReturn()
          }
        })
    } else {
      handleOrderReturn()
    }
  }, [])

  useEffect(()=>{
    if(orderDetails?.processedParts) {
      let rejected = orderDetails.processedParts.every(part => part.state === "declined");
      setAllPartsRejected(rejected)
    }
  }, [orderDetails?.processedParts])

  const handleResize = () => {
    const width = window.innerWidth
    if (width <= thirdCollapseThreshold) {
      setCollapseEdit(true)
    } else {
      setCollapseEdit(false)
    }
    if (width <= firstCollapseThreshold) {
      if (width <= secondCollapseThreshold) {
        setCollapseExportSecond(true)
      } else {
        setCollapseExportSecond(false)
        setCollapseExportFirst(true)
      }
    } else {
      setCollapseExportFirst(false)
      setCollapseExportSecond(false)
    }

    setModalWidth(width-100);
    setModalHeight(window.innerHeight-135);
  };

  const showItem = (item) => {
    setItemView({...item, viewType: "details", orderState: orderDetails.state})
    setViewVisible(true)
  }

  const cancelShowItem = ()  => {
    setItemView(null)
    setViewVisible(false)
  }

  const startEdit = () => {
    setSelectedDate(new Date(orderDetails.deliveryDate))
    setIsEditing(true)
    setEditMap({
      oldDeliveryDate: orderDetails.deliveryDate,
      newDeliveryDate: "",
      oldTotalPrice: orderDetails.totalPrice,
      newTotalPrice: "",
      items : {}
    })
  }

  const cancelEdit = () => {
    setIsEditing(false)
    setEditMap({
      oldDeliveryDate: "",
      newDeliveryDate: "",
      oldTotalPrice: "",
      newTotalPrice: "",
      items : {}
    })
    setHasBeenEdited(false)
  }

  const handleChange = date => {
    if(date) {
      setEditMap({...editMap, newDeliveryDate: date})
      setSelectedDate(date)
      setHasBeenEdited(true)
    }
  };

  const confirmChanges = () => {
    setIsEditFinalisationVisible(true)
  }

  const editItemFunc = (item) => {
    setIsEditItemVisible(true)
    setEditItem(item)
  }

  const handleEditModalClose = () => {
    setIsEditItemVisible(false)
    setEditItem(null)
  }

  const getOrderPrice = (items) => {
    const reducer = (accumulator, currentValue) => {
      if (currentValue.partType === "assembly" && currentValue.processingType !== "manual") {
        if((items[currentValue.id] && items[currentValue.id].state === "declined") || currentValue.state === "declined") {
          return parseFloat(accumulator)
        }
        return parseFloat(accumulator) + currentValue.productionPrice * currentValue.quantity
      }
      if(currentValue.state === "declined") {
        return accumulator
      }
      return parseFloat(accumulator) + parseFloat((items[currentValue.id] ?
        (items[currentValue.id].state === "edited" ? items[currentValue.id].newPrice * currentValue.quantity * (assemblyData[currentValue.assemblyId]?.quantity || 1) : 0)
        :
        currentValue.totalPrice
      ))
    }
    const price = orderDetails.processedParts.reduce(reducer, 0)
    return price.toFixed(2)
  }

  const getAssemblyPrice = ({assemblyId, items}) => {
    let oldPrice = 0
    let operationPrices = 0
    let declinedCount = 0
    let totalCount = 0
    const reducer = (accumulator, currentValue) => {
      if(currentValue.id === assemblyId) {
        oldPrice = currentValue.pricePerPiece
        totalCount = currentValue.numberOfUniqueParts
        operationPrices = currentValue.productionPrice * currentValue.quantity
        return accumulator
      }
      if(currentValue.state === "declined") {
        return accumulator
      }
      if(currentValue.assemblyId === assemblyId) {
        if(items[currentValue.id]) {
          if(items[currentValue.id].state === "declined") {
            declinedCount += 1
          }
          return parseFloat(accumulator) + (items[currentValue.id].state === "edited" ? parseFloat(items[currentValue.id].newPrice) * currentValue.quantity : 0)
        }
        return parseFloat(accumulator) + currentValue.totalPrice / (assemblyData[currentValue.assemblyId]?.quantity || 1)
      }

      return accumulator
    }
    const newPrice = parseFloat(orderDetails.processedParts.reduce(reducer, 0).toFixed(2)) + operationPrices
    return {oldPrice, newPrice, declinedCount, totalCount}
  }

  const handleItemApply = (values) => {
    if(editMap.items[editItem.id] || parseFloat(values.itemPrice) !== parseFloat(editItem.pricePerPiece)) {
      const items = {...editMap.items}
      items[editItem.id] = {type: "part", assemblyId: editItem.assemblyId, state: "edited", oldPrice: editItem.pricePerPiece, newPrice: parseFloat(values.itemPrice.toFixed(2))}
      if(editItem.assemblyId) {
        const prices = getAssemblyPrice({assemblyId: editItem.assemblyId, items: items})
        items[editItem.assemblyId] = {type: "assembly", state: "edited", oldPrice: prices.oldPrice, newPrice: prices.newPrice}
      }
      setEditMap({...editMap, newTotalPrice: getOrderPrice(items), items: {...items}})
    }
    setIsEditItemVisible(false)
    setEditItem(null)
    setHasBeenEdited(true)
  }

  const itemRemoveOpen = () => {
    setIsDeletingItem(true)
  }

  const itemRemoveClose = () => {
    setIsDeletingItem(false)
  }

  const handleItemRemove = (item) => {
    const items = {...editMap.items}
    items[item.id] = {type: "part", assemblyId: item.assemblyId, state: "declined", oldPrice: item.pricePerPiece, newPrice: 0}
    if(item.assemblyId) {
      const prices = getAssemblyPrice({assemblyId: item.assemblyId, items: items})

      if(prices.declinedCount === prices.totalCount) {
        items[item.assemblyId] = {type: "assembly", state: "declined", oldPrice: prices.oldPrice, newPrice: 0}
      } else {
        items[item.assemblyId] = {type: "assembly", state: "edited", oldPrice: prices.oldPrice, newPrice: prices.newPrice}
      }
    }
    setEditMap({...editMap, newTotalPrice: getOrderPrice(items), items: {...items}})
    setHasBeenEdited(true)
  }

  const handleEditFinalisationModalClose = () => {
    setIsEditFinalisationVisible(false)
  }

  const handleEditFinalisation = () => {
    const data = {}
    if(editMap.newDeliveryDate !== "") {
      data.deliveryDate = editMap.newDeliveryDate
    }
    if(editMap.newTotalPrice !== "") {
      let edits = [];
      for(let key of Object.keys(editMap.items)) {
        let item = editMap.items[key]
        if(item.type === "part") {
          let obj = {partId: key, editType: item.state === "declined" ? "delete" : "priceChange"}
          if(item.state === "edited") {
            obj.newPrice = parseFloat(item.newPrice)
          }
          edits.push(obj)
        }
      }
      data.edits = edits
    }
    dispatch(editPendingOrder({orderId: orderDetails.id, data})).then(()=>{
      dispatch(fetchPendingOrderParts({id: orderDetails.id}))
      setIsEditFinalisationVisible(false)
      setIsEditing(false)
      setHasBeenEdited(false)
      setEditMap({
        oldDeliveryDate: "",
        newDeliveryDate: "",
        oldTotalPrice: "",
        newTotalPrice: "",
        items : {}
      })
    }).catch(()=>{})
  }

  const openQuantityChangeModal = (item) => {
    setSelectedPartQuantityData({
      quantity: item.quantity,
      producedQuantity: item.producedQuantity,
      pendingProductionQuantity: item.pendingProductionQuantity,
      partId: item.id
    })
    setQuantityChangeVisible(true);
  }

  const handleQuantityChange = (values) => {
    dispatch(changePartProducedQuantity({
      orderId: orderDetails.id,
      partId: selectedPartQuantityData.partId,
      newQuantity: values.newQuantity,
      oldQuantity: selectedPartQuantityData.producedQuantity
    }))
    setQuantityChangeVisible(false);
  }


  const openManualQuoteModal = (item) => {
    setManualQuoteItem(item)
    setIsManualQuoteModalVisible(true)
  }

  const closeManualQuoteModal = () => {
    setIsManualQuoteModalVisible(false)
  }

  const openAssemblyQuoteModal = (item) => {
    setAssemblyQuoteItem(item)
    setIsAssemblyQuoteModalVisible(true)
  }

  const closeAssemblyQuoteModal = () => {
    setIsAssemblyQuoteModalVisible(false)
  }

  const openAddPartModal = () => {
    setIsAddNewPartVisible(true)
  }

  const closeAddPartModal = () => {
    setIsAddNewPartVisible(false)
  }

  const showDetailsView = (item) => {
    setIsDetailsView(true)
    setOtherItemView(item)
  }

  const closeDetailsView = () => {
    setIsDetailsView(false)
  }

  const handleRemovePartOnEdit = (item) => {
    itemRemoveOpen(item)
    Modal.confirm({
      className: "inputInfoText",
      visible: isDeletingItem,
      style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
      centered: true,
      onCancel: itemRemoveClose,
      onOk: () => {
        handleItemRemove(item)
      },
      destroyOnClose: true,
      width: 400,
      title: <span style={{ color: "white" }}>{"Reject Part"}</span>,
      content: <div style={{ color: "white" }}>
        <div>Are you sure you want to reject this part ?</div>
        <Divider
          className={"lightDivider"}
          style={{
            marginBottom: 0,
            position: 'relative',
            right: 36,
            width: 334
        }}/>
      </div>,
      okText: <span style={{fontSize: 13, fontWeight: 500}}>Reject</span>,
      okButtonProps: {type: "danger"},
      cancelText: <span
        style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
      icon: <DeleteOutlined style={{color: "#ff5b2d"}}/>,
      zIndex: 2,
    })
  }

  const formatOrderState = () => {
    switch (orderDetails.state) {
      case "quoted":
        return (
          <div>
            <Tag color="#f0bd27" style={{fontWeight: 600, textAlign: "center", color: "black", padding: "2px 10px 2px 10px"}}>Pending</Tag>
            <Tag color="#808080" style={{fontWeight: 600, textAlign: "center", width: 78, padding: "2px 10px 2px 10px", marginRight: 0}}>Quoted</Tag>
          </div>
        )
      case "awaitingQuote":
        return (
          <div>
            <Tag color="#f0bd27" style={{fontWeight: 600, textAlign: "center", color: "black", padding: "2px 10px 2px 10px"}}>Pending</Tag>
            <Tag color="#e03838" style={{fontWeight: 600, textAlign: "center", width: 98, padding: "2px 10px 2px 10px", marginRight: 0}}>Unquoted</Tag>
          </div>
        )
      case "awaitingConfirmation":
        return <Tag color="#9966cc" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Awaiting</Tag>
      case "accepted":
        return <Tag color="#6bce34" style={{fontWeight: 600, textAlign: "center", color: "black", padding: "2px 10px 2px 10px"}}>Accepted</Tag>
      case "inProgress":
        return <Tag color="#7cbbf4" style={{fontWeight: 600, textAlign: "center", color: "black", padding: "2px 10px 2px 10px"}}>In Progress</Tag>
      case "declined":
        return <Tag color="#e03838" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Declined</Tag>
      case "completed":
        return <Tag color="#1890ff" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Completed</Tag>
      default:
        return <span>Pending</span>
    }
  }

  const formatOrderStateProducer = () => {
    switch (orderDetails.state) {
      case "awaitingConfirmation":
        return <Tag color="#9966cc" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Awaiting</Tag>
      case "accepted":
        return <Tag color="#6bce34" style={{fontWeight: 600, textAlign: "center", color: "black", padding: "2px 10px 2px 10px"}}>Accepted</Tag>
      case "inProgress":
        return <Tag color="#7cbbf4" style={{fontWeight: 600, textAlign: "center", color: "black", padding: "2px 10px 2px 10px"}}>In Progress</Tag>
      case "declined":
        return <Tag color="#e03838" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Declined</Tag>
      case "completed":
        return <Tag color="#1890ff" style={{fontWeight: 600, textAlign: "center", padding: "2px 10px 2px 10px"}}>Completed</Tag>
      default:
        return <span/>
    }
  }

  const handleAddNewPart = (data) => {
    const reqData = {partType: data.partType, data: data.data}
    dispatch(addNewPart({orderId: orderDetails.id, data: reqData})).then(()=>{
      closeAddPartModal()
      dispatch(fetchPendingOrderParts({id: orderDetails.id}))
    }).catch(()=>{})
  }

  const handleQuotePart = (data) => {
    let {uploadTime, uploadIndex, ...filteredData} = data.data
    dispatch(quoteManualPart({orderId: orderDetails.id, partId: data.partId, type: data.type, data: { partType: data.partType, data: filteredData}})).then(()=>{
      closeManualQuoteModal()
      dispatch(fetchPendingOrderParts({id: orderDetails.id}))
    }).catch(()=>{})
  }

  const handleQuoteAssembly = (n) => {
    let {assemblyId, data, close} = n
    dispatch(quoteAssemblyProductionPrice({orderId: orderDetails.id, assemblyId: assemblyId, data: data})).then(()=>{
      close()
      dispatch(fetchPendingOrderParts({id: orderDetails.id}))
    }).catch(()=>{})
  }

  const handleManualPartReject = (partId, e) => {
    let data = {edits: [{partId, editType: "delete"}]}
    dispatch(editPendingOrder({orderId: orderDetails.id, data})).then(()=>{
      dispatch(fetchPendingOrderParts({id: orderDetails.id}))
      e()
    }).catch(()=>{})
  }

  const handleRejectManualPart = (partId) => {
    let modal = Modal.confirm({
      className: "inputInfoText",
      visible: true,
      style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
      centered: true,
      onOk: (e) => {
        modal.update(prevConfig => ({
          ...prevConfig,
          cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
          okButtonProps: {...prevConfig.okButtonProps, loading: true}
        }));
        handleManualPartReject(partId, e)
      },
      confirmLoading: true,
      destroyOnClose: true,
      width: 400,
      title: <span style={{ color: "white" }}>{"Reject Part"}</span>,
      content: <div style={{ color: "white" }}>
        <div>Are you sure you want to reject this part ?</div>
        <Divider
          className={"lightDivider"}
          style={{
            marginBottom: 0,
            position: 'relative',
            right: 36,
            width: 334
        }}/>
      </div>,
      okText: <span style={{fontSize: 13, fontWeight: 500}}>Reject</span>,
      okButtonProps: {type: "danger", loading: false},
      cancelText: <span
        style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
      icon: <DeleteOutlined style={{color: "#ff5b2d"}}/>,
      zIndex: 2,
    })
  }

  const handleRevertOrder = () => {
    dispatch(revertOrder({orderId: orderDetails.id})).then(()=> {
      setChangesVisible(false)
      dispatch(fetchPendingOrder({id: orderDetails.id, type: "pending"}))
        .then((order)=>{
          setSelectedDate(new Date(order.deliveryDate))
        })
    })
  }

  const getOrderState = (state) => {
    switch (state) {
      case "quoted":
        return "pending"
      case "awaitingQuote":
        return "pending"
      case "awaitingConfirmation":
        return "awaiting-confirmation"
      case "accepted":
        return "accepted"
      case "inProgress":
        return "accepted"
      default:
        return "pending"
    }
  }

  const handleShowDownloadFiles = (item) => {
    setFilesItem(item)
    setDownloadFilesVisible(true)
  }

  const handleCloseDownloadFiles = () => {
    setDownloadFilesVisible(false)
    setFilesItem(null)
  }

  const handleDownloadZip = () => {
    dispatch(downloadOrderZip({orderId: orderDetails.id})).then((zipUrl)=>{
      setTimeout(()=>{
        const link = document.createElement('a');
        link.href = zipUrl;

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        dispatch(setGeneratingZip(false))
      }, 1000)
    }).catch(()=>{
      dispatch(setGeneratingZip(false))
    })
  }

  const handleOpenPricesModal = (item) => {
    setPricesModalItem(item);
    setAllPricesModalVisible(true);
  }

  const handleClosePricesModal = () => {
    setAllPricesModalVisible(false);
    setPricesModalItem({});
  }
  
  const handleOpenDocsModal = (docs) => {
    setAdditionalDocs(docs)
    setAdditionalDocsVisible(true);
  }
  
  const handleCloseDocsModal = () => {
    setAdditionalDocsVisible(false);
    setAdditionalDocs([])
  }

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <Layout
      style={{
        overflow: "auto",
        minHeight: "100vh",
      }}
    >
      {(role === "regularCustomer" || role === "corporateCustomer") &&
        <Sidebar
          onLogout={() => {
            dispatch(logoutUser())
          }}
          user={user}
        />
      }
      {(role === "gelsoAdmin" || role === "gelsoSubAdmin") &&
        <CompanySidebar
          onLogout={() => {
            dispatch(logoutUser())
          }}
          user={user}
          collapsed={true}
        />
      }
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <Spin
          spinning={isFetchingPendingOrder || isFetchingProducersForOrder}
          wrapperClassName={"fetchingOrderSpin"}
          indicator={<FetchingOrderIndicator/>}
        >
        <div style={{overflowX: "hidden", minHeight: "100vh"}}>
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            {orderDetails.id && !isFetchingPendingOrder &&
              <Row gutter={[20, 10]}>
                <Col span={24}>
                  <Card className={"orderDetailsHeader"}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      <div
                        className={"ellipsisText"}
                        style={{display: "flex", alignItems: "center", minWidth: 0}}
                      >
                        <div style={{marginRight: 5}}>
                          <IconButton
                            color="primary"
                            aria-label="back to pending quotes"
                            size="small"
                            style={{padding: 5}}
                            onClick={()=>{
                              history.goBack()
                            }}
                          >
                            <ArrowBackIosRoundedIcon style={{ fontSize: 18 }}/>
                          </IconButton>
                        </div>
                        <div
                          className={"orderDetailsTitle"}
                          style={{ minWidth: 0}}
                        >
                          {(role === "regularCustomer" || role === "corporateCustomer") && (
                            orderDetails.customName !== "" ?
                              <div style={{ display: "flex", alignItems: "center", minWidth: 0}}>
                                <div style={{ whiteSpace: "nowrap"}}>
                                  <span style={{ color: "#ff5b2d" }}>Quote </span>{orderDetails.name}
                                </div>
                                <div className={"ellipsisText orderRef"}>
                                  {orderDetails.customName}
                                </div>
                              </div>
                            :
                              <span><span style={{ color: "#ff5b2d" }}>Quote</span> {orderDetails.name}</span>
                          )}
                          {(role === "gelsoAdmin" || role === "gelsoSubAdmin") &&
                            <div style={{ borderRight: "1px solid #777", paddingRight: 20, minWidth: 170 }}>
                              <span style={{ color: "#ff5b2d" }}>Quote</span> {orderDetails.name}
                            </div>
                          }
                        </div>
                        <div>
                          {(role === "gelsoAdmin" || role === "gelsoSubAdmin") &&
                            <div style={{marginLeft: -5}}>
                              {formatOrderStateProducer()}
                            </div>
                          }
                        </div>
                        {(role === "gelsoAdmin" || role === "gelsoSubAdmin") && (orderDetails.state === "quoted" || orderDetails.state === "awaitingQuote")  &&
                          <div>
                            <Button
                              type="primary"
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                                marginRight: 5,
                              }}
                              disabled={isEditing || isFetchingPendingOrderParts}
                              onClick={() => startEdit()}
                            >
                              <EditOutlined style={{fontSize: 14}}/>
                              {!collapseEdit && "Edit Quote"}
                            </Button>
                            <Button
                              disabled={isEditing || isFetchingPendingOrderParts}
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                                marginRight: 10
                              }}
                              onClick={() => {
                                openAddPartModal()
                              }}
                            >
                              <FileAddOutlined style={{fontSize: 14}}/>
                              {!collapseEdit && "Add Part"}
                            </Button>
                          </div>
                        }
                        {(role === "gelsoAdmin" || role === "gelsoSubAdmin") && orderDetails.state === "awaitingConfirmation" &&
                          (orderDetails.odooUrl ?
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Button
                                type="primary"
                                style={{marginRight: 6, fontWeight: 500, display: "flex", alignItems: "center", paddingInline: 11}}
                                icon={<OpenInNewOutlinedIcon style={{ fontSize: 16, marginRight: 4 }}/>}
                                onClick={() => {
                                  const win = window.open(`${orderDetails.odooUrl}`, "_blank");
                                  win.focus();
                                }}
                              >
                                <span style={{fontSize: 12}}>Official offer</span>
                              </Button>
                              <Button
                                style={{marginRight: 6, fontWeight: 500, display: "flex", alignItems: "center", paddingInline: 11}}
                                icon={<EditOutlined style={{ fontSize: 14 }}/>}
                                onClick={() => {
                                  linkForm.setFieldsValue({
                                    odooUrl: orderDetails.odooUrl
                                  })
                                  setOdooVisible(true)
                                }}
                              >
                                <span style={{fontSize: 12}}>Edit link</span>
                              </Button>
                            </div>
                          :
                            <div>
                              <Button
                                type="primary"
                                style={{marginRight: 6, fontWeight: 500, display: "flex", alignItems: "center", paddingInline: 11}}
                                icon={<OpenInNewOutlinedIcon style={{ fontSize: 16, marginRight: 4 }}/>}
                                onClick={() => {
                                  setOdooVisible(true)
                                }}
                              >
                                <span style={{fontSize: 12}}>Add <span style={{ fontWeight: 600 }}>Odoo</span> link</span>
                              </Button>
                            </div>
                          )
                        }
                        {(role === "regularCustomer" || role === "corporateCustomer") && orderDetails.state === "awaitingConfirmation" &&
                          <div>
                            <OrderChanges
                              order={orderDetails}
                              size={"default"}
                            />
                          </div>
                        }
                      </div>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                        {collapseExportSecond ?
                          <Button
                            onClick={() => setShowDrawer(true)}
                            type={"primary"}
                            style={{
                              marginRight: 5
                            }}
                          >
                            <ExportOutlined style={{fontSize: 14}}/>
                            Export Files
                          </Button>
                          :
                          <div style={{display: "flex", alignItems: "center"}}>
                            <OrderExcelExportButton
                              role={role}
                              name={orderDetails.name}
                              isEditing={isEditing}
                              parts={orderDetails.processedParts || orderDetails.parts || []}
                              adjustment={orderDetails.adjustment || 0}
                              collapsed={collapseExportFirst}
                            />
                            {!(role === "regularCustomer" || role === "corporateCustomer") &&
                              <OrderPdfExportButton
                                orderData={{
                                  name: orderDetails.name,
                                  orderDate: orderDetails.orderDate,
                                  deliveryDate: orderDetails.deliveryDate,
                                  totalPrice: orderDetails.totalPrice,
                                  customer: orderDetails.customer,
                                  shippingData: orderDetails.shippingData,
                                  producer: orderDetails.producer || user.producer,
                                }}
                                isEditing={isEditing}
                                parts={orderDetails.processedParts || orderDetails.parts || []}
                                collapsed={collapseExportFirst}
                              />
                            }
                            {!(role === "regularCustomer" || role === "corporateCustomer") &&
                            <Button
                              type="primary"
                              style={{
                                fontSize: 13,
                                fontWeight: 500
                              }}
                              onClick={handleDownloadZip}
                              loading={isGeneratingZip}
                              disabled={isEditing}
                            >
                              <FileZipOutlined style={{fontSize: 14}}/>
                              {collapseExportFirst ? "Files" : "Download Files"}
                            </Button>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col span={24}>
                  <Row gutter={[10, 10]}>
                    <Col xxl={15} xl={13} lg={12} span={24}>
                      <span className={"pageSubtitle"} style={{ marginBottom: 5}}>
                        Details
                      </span>
                      <div className={"orderDetailsInfoContainer"}>
                        <Row>
                          <Col xxl={16} xl={24} span={24}>
                            <Card className={"orderDetailsInfoCard"} bodyStyle={{ padding: "10px 15px" }}>
                              <Row>
                                <Col sm={13} span={24}>
                                  <div className={"dataLabel"}>Date of quote</div>
                                  <div className={"dataValue"}>{formatTableDate(orderDetails.orderDate)}</div>
                                  <div className={"dataLabel"}>Email</div>
                                  <div className={"dataValue"}>{orderDetails.customer.email}</div>
                                  <div className={"dataLabel"}>Customer</div>
                                  <div className={"dataValue"}>{orderDetails.customer.name}</div>
                                  <div className={"dataLabel"}>Phone</div>
                                  <div className={"dataValue"}>{orderDetails.shippingData.phone}</div>
                                </Col>
                                <Col sm={11} span={24}>
                                  <div className={"dataLabel"}>Address</div>
                                  <div className={"dataValue"}>{orderDetails.shippingData.address}</div>
                                  <div className={"dataLabel"}>City</div>
                                  <div className={"dataValue"}>{orderDetails.shippingData.city}</div>
                                  <div className={"dataLabel"}>Country</div>
                                  <div className={"dataValue"}>{orderDetails.shippingData.country}</div>
                                  <div className={"dataLabel"}>Postal Code</div>
                                  <div className={"dataValue"}>{orderDetails.shippingData.postalCode}</div>
                                </Col>
                                {orderDetails.orderNote &&
                                <Col span={24} style={{borderTop: "1px dotted #bababa", marginTop: 5}}>
                                  <div style={{display: "flex", marginTop: 10, marginBottom: -10}}>
                                    <div style={{fontSize: 14, marginRight: 5, color: "#ff5b2d"}}>Note:</div>
                                    <div className={"dataValue"}>{orderDetails.orderNote}</div>
                                  </div>
                                </Col>
                                }
                              </Row>
                            </Card>
                          </Col>
                          <Col xxl={8} xl={24} span={24}>
                            <Row style={{height: "100%"}}>
                              <Col xxl={24} xl={12} sm={12} span={24}>
                                <Card
                                  className={"orderDetailsInfoCard"}
                                  bodyStyle={{padding: "10px 15px"}}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      height: "44px"
                                    }}
                                  >
                                    <div style={{display: "block", width: "100%"}}>
                                      <div className={"dataLabel"}>Delivery Date</div>
                                      <div style={{height: 24}}>
                                        {isEditing ?
                                          <DatePicker
                                            selected={selectedDate}
                                            dateFormat={'dd.MM.yyyy'}
                                            locale="en"
                                            minDate={add(new Date(),{ days: 1 })}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            filterDate={isWeekday}
                                            placeholderText="Click to select a date"
                                            className={"orderDetailsDatePicker"}
                                          />
                                          :
                                          <div className={"dataValue2"}>{formatTableDate(orderDetails.deliveryDate)}</div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              <Col xxl={24} xl={12} sm={12} span={24}>
                                <Card
                                  className={"orderDetailsInfoCard"}
                                  bodyStyle={{padding: "10px 15px"}}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      height: "44px"
                                    }}
                                  >
                                    <div style={{display: "block", width: "100%"}}>
                                      <div className={"dataLabel"}>Total Price</div>
                                      <div style={{height: 24}}>
                                        {editMap.newTotalPrice ?
                                          <div
                                            className={"dataValue2"}
                                            style={{
                                              color: editMap.oldTotalPrice < editMap.newTotalPrice ? "#52c41a" : "#e03838",
                                              display: "flex"
                                            }}
                                          >
                                            <div>
                                              {editMap.newTotalPrice} € {orderDetails?.unprocessedParts?.length > 0 && "+ RFQ"}
                                            </div>
                                            <div style={{ color: "white" }}>
                                              {editMap.oldTotalPrice < editMap.newTotalPrice ?
                                                <ArrowRightAltIcon
                                                  style={{
                                                    transform: "rotate(-90deg)",
                                                    fontSize: 18,
                                                    marginTop: 2,
                                                    marginBottom: -4
                                                  }}
                                                />
                                                :
                                                <ArrowRightAltIcon
                                                  style={{
                                                    transform: "rotate(90deg)",
                                                    fontSize: 18,
                                                    marginTop: 2,
                                                    marginBottom: -4
                                                  }}
                                                />
                                              }
                                            </div>
                                          </div>
                                          :
                                          <div className={"dataValue2"}>{(orderDetails.totalPrice || 0).toFixed(2)} € {orderDetails?.unprocessedParts?.length > 0 && "+ RFQ"}</div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              <Col xxl={24} xl={24} lg={24} span={24}>
                                <Card
                                  className={"orderDetailsInfoCard"}
                                  bodyStyle={{padding: "10px 15px"}}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      height: "44px",
                                      alignItems: "center",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    {(orderDetails.state === "completed" || orderDetails.state === "declined") ?
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          height: "100%"
                                        }}
                                      >
                                        <div style={{display: "block"}}>
                                          <div className={"dataLabel"}>Completion Date</div>
                                          <div className={"dataValue2"}>{formatTableDate(orderDetails.completionDate)}</div>
                                        </div>
                                      </div>
                                      :
                                      ((role === "regularCustomer" || role === "corporateCustomer") ?
                                        <div style={{fontSize: 13}}>Status:</div>
                                        :
                                        <div style={{fontSize: 13}}>Action:</div>
                                      )
                                    }
                                    <div>
                                      {(role === "gelsoAdmin" || role === "gelsoSubAdmin") &&
                                      <div>
                                        {(orderDetails.state === "quoted" || orderDetails.state === "awaitingQuote") &&
                                        <div>
                                          {isEditing ?
                                            <div>
                                              <Button
                                                type="primary"
                                                disabled={!hasBeenEdited}
                                                style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                onClick={() => {
                                                  confirmChanges()
                                                }}
                                              >
                                                Save
                                              </Button>
                                              <Button
                                                style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                type={"danger"}
                                                onClick={() => {
                                                  cancelEdit()
                                                }}
                                              >
                                                Cancel
                                              </Button>
                                            </div>
                                            :
                                            <div>
                                              {(orderDetails.stage === "edited" || orderDetails.state === "awaitingQuote") ?
                                                (orderDetails.unprocessedParts.length > 0 ?
                                                  <Tooltip
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    color={"rgba(82,82,82,0.9)"}
                                                    placement={"bottom"}
                                                    title={<span className={"text12-500"} style={{color: "white"}}>All parts and operations must be quoted!</span>}
                                                  >
                                                    <Button
                                                      type="primary"
                                                      style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                      icon={<FileDoneOutlined/>}
                                                      disabled={true}
                                                    >
                                                      Send Quote
                                                    </Button>
                                                  </Tooltip>
                                                  :
                                                  (allPartsRejected ?
                                                    <Tooltip
                                                      getPopupContainer={trigger => trigger.parentNode}
                                                      color={"rgba(82,82,82,0.9)"}
                                                      placement={"bottom"}
                                                      title={<span className={"text12-500"} style={{color: "white"}}>All parts have been rejected!</span>}
                                                    >
                                                      <Button
                                                        type="primary"
                                                        style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                        icon={<FileDoneOutlined/>}
                                                        disabled={true}
                                                      >
                                                        Send Quote
                                                      </Button>
                                                    </Tooltip>
                                                    :
                                                    <Button
                                                      type="primary"
                                                      style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                      icon={<FileDoneOutlined/>}
                                                      onClick={() => setSendQuoteVisible(true)}
                                                    >
                                                      Send Quote
                                                    </Button>
                                                  )
                                                )
                                                :
                                                <Button
                                                  type="primary"
                                                  className={"placeOrderButton"}
                                                  icon={<CheckOutlined/>}
                                                  style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                  onClick={() => setAcceptVisible(true)}
                                                >
                                                  Accept
                                                </Button>
                                              }
                                              <Button
                                                type="danger"
                                                style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                                icon={<CloseOutlined/>}
                                                onClick={() => setDeclineVisible(true)}
                                              >
                                                Decline
                                              </Button>
                                            </div>
                                          }
                                        </div>
                                        }
                                        {orderDetails.state === "accepted" &&
                                          (orderDetails.odooUrl ?
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              <Button
                                                type="primary"
                                                style={{marginRight: 6, fontWeight: 500, display: "flex", alignItems: "center", paddingInline: 11}}
                                                icon={<OpenInNewOutlinedIcon style={{ fontSize: 16, marginRight: 4 }}/>}
                                                onClick={() => {
                                                  const win = window.open(`${orderDetails.odooUrl}`, "_blank");
                                                  win.focus();
                                                }}
                                              >
                                                <span style={{fontSize: 12}}>Official offer</span>
                                              </Button>
                                              <Button
                                                style={{marginRight: 6, fontWeight: 500, display: "flex", alignItems: "center", paddingInline: 11}}
                                                icon={<EditOutlined style={{ fontSize: 14 }}/>}
                                                onClick={() => {
                                                  linkForm.setFieldsValue({
                                                    odooUrl: orderDetails.odooUrl
                                                  })
                                                  setOdooVisible(true)
                                                }}
                                              >
                                                <span style={{fontSize: 12}}>Edit link</span>
                                              </Button>
                                            </div>
                                          :
                                            <div>
                                              <Button
                                                type="primary"
                                                style={{marginRight: 6, fontWeight: 500, display: "flex", alignItems: "center", paddingInline: 11}}
                                                icon={<OpenInNewOutlinedIcon style={{ fontSize: 16, marginRight: 4 }}/>}
                                                onClick={() => {
                                                  setOdooVisible(true)
                                                }}
                                              >
                                                <span style={{fontSize: 12}}>Add <span style={{ fontWeight: 600 }}>Odoo</span> link</span>
                                              </Button>
                                            </div>
                                          )
                                        }
                                        {orderDetails.state === "inProgress" &&
                                        <div>
                                          <Button
                                            type="primary"
                                            style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                            icon={<ProfileOutlined/>}
                                            onClick={()=>setCompleteVisible(true)}
                                          >
                                            Complete
                                          </Button>
                                          <Button
                                            type="danger"
                                            style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                            icon={<CloseOutlined/>}
                                            onClick={() => setDeclineVisible(true)}
                                          >
                                            Decline
                                          </Button>
                                        </div>
                                        }
                                      </div>
                                      }
                                      {((role === "regularCustomer" || role === "corporateCustomer") && (orderDetails.state === "accepted" || orderDetails.state === "awaitingConfirmation")) &&
                                        <div style={{ display: "flex", alignItems: "center", width: "100%"}}>
                                          <div>
                                            {formatOrderState()}
                                          </div>
                                          {orderDetails.odooUrl &&
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                                              <Divider type={"vertical"} style={{
                                                height: "30px",
                                                margin: "0 10px 0 2px",
                                                borderColor: "#838f94"
                                              }}/>
                                              <Button
                                                type="primary"
                                                style={{
                                                  marginRight: 6,
                                                  fontWeight: 500,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  paddingInline: 11
                                                }}
                                                icon={<OpenInNewOutlinedIcon style={{fontSize: 16, marginRight: 4}}/>}
                                                onClick={() => {
                                                  const win = window.open(`${orderDetails.odooUrl}`, "_blank");
                                                  win.focus();
                                                }}
                                              >
                                                <span style={{fontSize: 12}}>Official offer</span>
                                              </Button>
                                            </div>
                                          }
                                        </div>
                                      }
                                      {(role === "regularCustomer" ||
                                        role === "corporateCustomer" ||
                                        orderDetails.state === "declined" ||
                                        orderDetails.state === "completed") &&
                                      (orderDetails.state !== "awaitingConfirmation" && orderDetails.state !== "accepted")  &&
                                      <div>
                                        {formatOrderState()}
                                      </div>
                                      }
                                      {((role === "gelsoAdmin" || role === "gelsoSubAdmin") && orderDetails.state === "awaitingConfirmation") &&
                                      <div>
                                        <Button
                                          type="primary"
                                          className={"placeOrderButton"}
                                          style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                          icon={<CheckOutlined/>}
                                          onClick={() => setAcceptVisible(true)}
                                        >
                                          Accept
                                        </Button>
                                        <Button
                                          type="danger"
                                          style={{marginRight: 8, fontSize: 13, fontWeight: 500}}
                                          icon={<CloseOutlined/>}
                                          onClick={() => setDeclineVisible(true)}
                                        >
                                          Decline
                                        </Button>
                                      </div>
                                      }
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      {orderDetails.unprocessedParts?.length > 0 &&
                      <div style={{marginTop: 10}}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                          <span className={"pageSubtitle"}>
                            Unquoted Parts and Operations
                            <Tooltip
                              placement={"top"}
                              color={"#656565"}
                              title={
                                <div style={{ color: "white", fontSize: 12, fontWeight: 500 }}>
                                  <div>This list contains all items that need to be quoted manually.</div>
                                </div>
                              }
                            >
                              <InfoCircleOutlined className={"unquotedInfo"}/>
                            </Tooltip>
                          </span>
                        </div>
                        <div className={"quotedItemsList"}
                             style={{
                               minHeight: "223px",
                               height: screens["xxl"] ? "calc(100vh - 406px)" : "calc(100vh - 550px)",
                               padding: 0,
                               overflowY: "auto", overflowX: "hidden"
                            }}
                        >
                          <OrderDetailsPartUnprocessedPartsGrid
                            parts={orderDetails.unprocessedParts || []}
                            itemCount={(orderDetails.unprocessedParts || []).length}
                            isEditing={isEditing}
                            role={role}
                            openManualQuoteModal={openManualQuoteModal}
                            openAssemblyQuoteModal={openAssemblyQuoteModal}
                            showManualItem={showItem}
                            showOtherItem={showDetailsView}
                            isFetchingUnprocessedParts={isFetchingPendingOrderParts}
                            rejectManualPart={handleRejectManualPart}
                            handleShowDownloadFiles={handleShowDownloadFiles}
                            handleOpenDocsModal={handleOpenDocsModal}
                            assemblyData={assemblyData}
                            itemSize={380}
                            emptyText={"All Parts are Quoted"}
                          />
                        </div>
                      </div>
                      }
                    </Col>
                    <Col xxl={9} xl={11} lg={12} span={24}>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div className={"pageSubtitle"}>
                          Quoted Parts
                        </div>
                        <div>
                          {orderDetails.stage === "edited" && (role === "gelsoAdmin" || role === "gelsoSubAdmin") &&
                          <Button
                            type="primary"
                            size="small"
                            disabled={isEditing || isFetchingPendingOrderParts}
                            style={{
                              fontSize: 13,
                              fontWeight: 500
                            }}
                            onClick={() => setChangesVisible(true)}
                          >
                            {/*<RedoOutlined style={{fontSize: 14, position: "relative", top: 1}}/>*/}
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <UndoIcon style={{ fontSize: 16, marginRight: 3 }}/>
                              <span>Undo Changes</span>
                            </div>
                          </Button>
                          }
                          {orderDetails.state === "awaitingConfirmation" && (role === "gelsoAdmin" || role === "gelsoSubAdmin") &&
                          <OrderChanges
                            order={orderDetails}
                            size={'small'}
                          />
                          }
                        </div>
                      </div>
                      <OrderDetailsPartProcessedPartsList
                        parts={orderDetails.processedParts || orderDetails.parts || []}
                        showOtherItem={showDetailsView}
                        showDetailsItem={showItem}
                        unprocessedPartsLength={orderDetails.unprocessedParts?.length || 0}
                        isEditing={isEditing}
                        orderState={orderDetails.state}
                        editMap={editMap}
                        editItemFunc={editItemFunc}
                        role={role}
                        handleRemovePartOnEdit={handleRemovePartOnEdit}
                        isFetchingProcessedList={isFetchingPendingOrderParts}
                        handleShowDownloadFiles={handleShowDownloadFiles}
                        handleQuantityChange={openQuantityChangeModal}
                        handleOpenPricesModal={handleOpenPricesModal}
                        handleOpenDocsModal={handleOpenDocsModal}
                        adjustment={orderDetails.adjustment || 0}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          </div>
        </div>
        </Spin>
        <ItemVisualisation
          isModelViewVisible={viewVisible}
          itemView={itemView}
          modalWidth={modalWidth}
          modalHeight={modalHeight}
          onCloseView={cancelShowItem}
          adjustment={0}
        />
        <Modal
          visible={isEditItemVisible}
          centered={true}
          onCancel={handleEditModalClose}
          title="Edit Part Price"
          bodyStyle={{paddingBottom: 0}}
          footer={
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end"
              }}
            >
              <Button
                style={{marginRight: 5, fontWeight: 500, fontSize: 13}}
                onClick={()=>{handleEditModalClose()}}
              >
                Cancel
              </Button>
              <Button
                type={"primary"}
                htmlType="submit"
                form={"orderItemEditForm"}
                style={{fontWeight: 500, fontSize: 13}}
              >
                Apply
              </Button>
            </div>
          }
          destroyOnClose={true}
          width={600}
        >
          {editItem ?
            <div style={{display: "flex", width: "100%", color: "white"}}>
              {imageSection(editItem)}
              <div style={{width: "100%"}}>
                <div>
                  <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <div
                      className={"partInfo"}
                      style={{maxWidth: 450}}
                    >
                      {editItem.fileName || editItem.name}
                    </div>
                  </div>
                  <div>
                    {materialInfoSection(editItem)}
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      <div
                        style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                      >
                        Quantity: <span style={{fontWeight: 600}}>{editItem.quantity}</span>
                      </div>
                      <div style={{marginTop: -6}}>
                        <Form
                          ref={form}
                          onFinish={handleItemApply}
                          name={"orderItemEditForm"}
                        >
                          <div style={{display: "flex"}}>
                            <div style={{fontSize: 12, fontWeight: 500, marginRight: 5, marginTop: 7, color: "white"}}>
                              1pc: <span style={{fontWeight: 600}}>€</span>
                            </div>
                            <Form.Item
                              name={"itemPrice"}
                              initialValue={editMap.items[editItem.id] ? editMap.items[editItem.id].newPrice : (editItem.pricePerPiece || 0).toFixed(2)}
                              rules={[
                                {required: true, message: 'Price is required'},
                              ]}
                              validateTrigger={"onSubmit"}
                            >
                              <InputNumber
                                min={0}
                                max={10000000}
                                placeholder={"Enter price"}
                                type={"number"}
                                style={{width: "110px"}}
                              />
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }
        </Modal>

        <Modal
          visible={isEditFinalisationVisible}
          centered={true}
          onCancel={handleEditFinalisationModalClose}
          title="Edit Confirmation"
          footer={<div style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end"
          }}
          >
            <Button
              style={{marginRight: 5, fontWeight: 500, fontSize: 13}}
              onClick={()=>{handleEditFinalisationModalClose()}}
              disabled={isEditingPendingOrder}
            >
              Cancel
            </Button>
            <Button
              type={"primary"}
              onClick={()=>{handleEditFinalisation()}}
              style={{fontWeight: 500, fontSize: 13}}
              loading={isEditingPendingOrder}
            >
              Save Changes
            </Button>
          </div>
          }
          destroyOnClose={true}
          width={600}
        >
          {editMap.newDeliveryDate !== "" ?
            <div style={{ color: "white" }}>
              <div style={{fontWeight: 400, fontSize: 13}}>Delivery Date:</div>
              <div style={{display: "flex"}}>
                <div style={{fontWeight: 600, fontSize: 14, color: "#e03838"}}>
                  {formatTableDate(editMap.oldDeliveryDate)}
                </div>
                <div align={"center"} style={{display: "flex"}}>
                  <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
                </div>
                <div style={{fontWeight: 600, fontSize: 14, color: "#52c41a"}}>
                  {formatTableDate(editMap.newDeliveryDate)}
                </div>
              </div>
              <Divider style={{marginTop: 10, marginBottom: 10}}/>
            </div>
            :
            null
          }
          {editMap.newTotalPrice !== "" ?
            <div style={{ color: "white" }}>
              <div style={{fontWeight: 400, fontSize: 13}}>Total Price:</div>
              <div style={{display: "flex"}}>
                <div style={{fontWeight: 600, fontSize: 14, color: "#e03838"}}>
                  € {(editMap.oldTotalPrice|| 0).toFixed(2)}
                </div>
                <div align={"center"} style={{display: "flex"}}>
                  <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
                </div>
                <div style={{fontWeight: 600, fontSize: 14, color: "#52c41a"}}>
                  € {editMap.newTotalPrice}
                </div>
              </div>
              <Divider style={{marginTop: 10, marginBottom: 20}}/>
              <Card bodyStyle={{padding: "10px 5px 10px 10px", backgroundColor: "#354045"}}>
                <div style={{maxHeight: 310, paddingRight: 5, overflowY: "auto", overflowX: "hidden"}}>
                  {orderDetails.processedParts.map(item=>{
                    return (editMap.items[item.id] ?
                      <div key={item.id}>
                        {item.partType === "assembly" &&
                          <div>
                            <div style={{display: "flex", justifyContent: "space-between", background: "#48555c", padding: "5px 5px 5px 8px"}}>
                              <div style={{
                                fontWeight: 600,
                                fontSize: 13,
                                textDecoration: editMap.items[item.id].state === "declined" ? "line-through" : "none"
                              }}>{item.fileName || item.name}:</div>
                              <div style={{display: "flex", marginRight: Object.keys(editMap.items).length > 7 ? 8 : 0}}>
                                <div style={{fontWeight: 600, fontSize: 14, color: "#e03838"}}>
                                  € {(editMap.items[item.id].oldPrice|| 0).toFixed(2)}
                                </div>
                                <div align={"center"} style={{display: "flex"}}>
                                  <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
                                </div>
                                <div style={{fontWeight: 600, fontSize: 14, color: "#52c41a"}}>
                                  € {editMap.items[item.id].newPrice.toFixed(2)}
                                </div>
                              </div>
                            </div>
                            <Divider style={{marginTop: 0, marginBottom: 5}}/>
                            {orderDetails.processedParts.map(assemblyItem => {
                              return ((editMap.items[assemblyItem.id] && assemblyItem.assemblyId === item.id) &&
                                <div key={assemblyItem.id} style={{marginLeft: 15}}>
                                  <div style={{display: "flex", justifyContent: "space-between", padding: "2px 5px 2px 5px"}}>
                                    <div style={{
                                      fontWeight: 600,
                                      fontSize: 13,
                                      textDecoration: editMap.items[assemblyItem.id].state === "declined" ? "line-through" : "none"
                                    }}>{assemblyItem.fileName || assemblyItem.name}:</div>
                                    <div style={{display: "flex", marginRight: Object.keys(editMap.items).length > 7 ? 8 : 0}}>
                                      <div style={{fontWeight: 600, fontSize: 14, color: "#e03838"}}>
                                        € {(editMap.items[assemblyItem.id].oldPrice|| 0).toFixed(2)}
                                      </div>
                                      <div align={"center"} style={{display: "flex"}}>
                                        <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
                                      </div>
                                      <div style={{fontWeight: 600, fontSize: 14, color: "#52c41a"}}>
                                        € {editMap.items[assemblyItem.id].newPrice.toFixed(2)}
                                      </div>
                                    </div>
                                  </div>
                                  <Divider style={{marginTop: 5, marginBottom: 5}}/>
                                </div>
                              )
                            })}
                          </div>
                        }
                        {(item.partType !== "assembly" && !item.assemblyId) &&
                          <div>
                            <div style={{display: "flex", justifyContent: "space-between", padding: "2px 5px 2px 8px"}}>
                              <div style={{
                                fontWeight: 600,
                                fontSize: 13,
                                textDecoration: editMap.items[item.id].state === "declined" ? "line-through" : "none"
                              }}>{item.fileName || item.name}:</div>
                              <div style={{display: "flex", marginRight: Object.keys(editMap.items).length > 7 ? 8 : 0}}>
                                <div style={{fontWeight: 600, fontSize: 14, color: "#e03838"}}>
                                  € {(editMap.items[item.id].oldPrice|| 0).toFixed(2)}
                                </div>
                                <div align={"center"} style={{display: "flex"}}>
                                  <TrendingFlatIcon style={{width: 50, fontSize: 18, alignSelf: "center"}}/>
                                </div>
                                <div style={{fontWeight: 600, fontSize: 14, color: "#52c41a"}}>
                                  € {editMap.items[item.id].newPrice.toFixed(2)}
                                </div>
                              </div>
                            </div>
                            <Divider style={{marginTop: 5, marginBottom: 5}}/>
                          </div>
                        }
                      </div>
                      :
                      null
                    )
                  })}
                </div>
              </Card>
            </div>
            :
            null
          }
        </Modal>
        <OrderDetailsManualQuote
          isManualQuoteModalVisible={isManualQuoteModalVisible}
          handleClose={closeManualQuoteModal}
          item={manualQuoteItem}
          addNewPart={handleQuotePart}
          isAddingNewPart={isAddingNewPart}
          materials={orderDetails.materials}
          assemblyData={assemblyData}
        />
        <OrderDetailsManualQuote
          isManualQuoteModalVisible={isAddNewPartVisible}
          handleClose={closeAddPartModal}
          item={undefined}
          addNewPart={handleAddNewPart}
          isAddingNewPart={isAddingNewPart}
          materials={orderDetails.materials}
        />
        <OrderDetailsAssemblyQuote
          isAssemblyQuoteModalVisible={isAssemblyQuoteModalVisible}
          handleClose={closeAssemblyQuoteModal}
          item={assemblyQuoteItem}
          quoteAssembly={handleQuoteAssembly}
          isQuotingAssembly={isQuotingAssembly}
        />

        <Modal
          title="Decline"
          centered={true}
          visible={declineVisible}
          onCancel={() => setDeclineVisible(false)}
          destroyOnClose
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setDeclineVisible(false)}
              disabled={isResolvingPendingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="danger"
              htmlType="submit"
              form={"declineForm"}
              style={{fontWeight: 500}}
              loading={isResolvingPendingOrder}
            >
              Decline
            </Button>
          ]}
        >
          <h4>Are you sure you want to DECLINE this quote ?</h4>
          <Form
            name={"declineForm"}
            onFinish={(values) => {
              dispatch(resolveOrder({orderId: orderDetails.id, orderState: getOrderState(orderDetails.state), data: {orderNote: values.orderNote || ""}, type: "decline"})).then(()=>{
                setDeclineVisible(false)
                handleOrderReturn()
              }).catch(()=>{})
            }}
          >
            <h4>Optional remark:</h4>
            <Form.Item
              name={"orderNote"}
              className={"textAreaInput"}
            >
              <Input.TextArea
                rows={3}
                className={"textAreaInputText muiInput"}
                maxLength={500}
                showCount
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Accept"
          centered={true}
          visible={acceptVisible}
          onCancel={() => setAcceptVisible(false)}
          destroyOnClose
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setAcceptVisible(false)}
              disabled={isResolvingPendingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              className={"placeOrderButton"}
              form={"acceptForm"}
              style={{fontWeight: 500}}
              loading={isResolvingPendingOrder}
            >
              <CheckOutlined style={{fontSize: 14}}/>
              Accept
            </Button>
          ]}
        >
          <h4>Are you sure you want to ACCEPT this quote?</h4>
          <Form
            name={"acceptForm"}
            onFinish={(values) => {
              if(orderDetails.state === "quoted") {
                dispatch(resolveOrder({orderId: orderDetails.id, orderState: getOrderState(orderDetails.state), data: {orderNote: values.orderNote || "", odooUrl: values.odooUrl || ""}, type: "accept"})).then(()=>{
                  setAcceptVisible(false)
                  handleOrderReturn()
                }).catch(()=>{})
              } else {
                dispatch(resolveOrder({orderId: orderDetails.id, orderState: getOrderState(orderDetails.state), data: {orderNote: values.orderNote || ""}, type: "accept"})).then(()=>{
                  setAcceptVisible(false)
                  handleOrderReturn()
                }).catch(()=>{})
              }
            }}
          >
            <h4>Optional remark:</h4>
            <Form.Item
              className={"textAreaInput"}
              name={"orderNote"}
            >
              <Input.TextArea
                rows={3}
                className={"textAreaInputText muiInput"}
                maxLength={500}
                showCount
              />
            </Form.Item>
            {orderDetails.state === "quoted" &&
              <Form.Item
                name={"odooUrl"}
                label={"Odoo Link"}
                rules={[{
                  required: true, message: 'Odoo link is required'
                },{
                  type: "url",
                  message: "This field must be a valid url."
                }]}
                initialValue={""}
              >
                <Input
                  style={{ color: "white" }}
                  placeholder={"Enter the Odoo link for this quote."}
                />
              </Form.Item>
            }
          </Form>
        </Modal>

        <Modal
          title="Complete"
          centered={true}
          visible={completeVisible}
          onCancel={() => setCompleteVisible(false)}
          destroyOnClose
          width={550}
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setCompleteVisible(false)}
              disabled={isResolvingPendingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              form={"completedForm"}
              style={{fontWeight: 500}}
              loading={isResolvingPendingOrder}
            >
              <ProfileOutlined style={{fontSize: 14}}/>
              Complete
            </Button>
          ]}
        >
          <h4>Are you sure you want to mark the quote as COMPLETED ?</h4>
          <Form
            name={"completedForm"}
            onFinish={(values) => {
              dispatch(resolveOrder({orderId: orderDetails.id, orderState: getOrderState(orderDetails.state), data: {orderNote: values.orderNote || ""}, type: "complete"})).then(()=>{
                setCompleteVisible(false)
                handleOrderReturn()
              }).catch(()=>{})
            }}
          >
            <h4>Optional remark:</h4>
            <Form.Item
              className={"textAreaInput"}
              name={"orderNote"}
            >
              <Input.TextArea
                rows={3}
                className={"textAreaInputText muiInput"}
                maxLength={500}
                showCount
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Send Quote"
          centered={true}
          visible={sendQuoteVisible}
          onCancel={() => setSendQuoteVisible(false)}
          destroyOnClose
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setSendQuoteVisible(false)}
              disabled={isResolvingPendingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              form={"sendQuoteForm"}
              style={{fontWeight: 500}}
              loading={isResolvingPendingOrder}
            >
              Send Quote
            </Button>
          ]}
        >
          <h4>Are you sure you want to FORWARD QUOTE to the customer ?</h4>
          <Form
            name={"sendQuoteForm"}
            onFinish={(values) => {
              dispatch(resolveOrder({orderId: orderDetails.id, orderState: getOrderState(orderDetails.state), data: {orderNote: values.orderNote || "", odooUrl: values.odooUrl}, type: "send-quote"})).then(()=>{
                setSendQuoteVisible(false)
                handleOrderReturn()
              }).catch(()=>{})
            }}
          >
            <h4>Optional remark:</h4>
            <Form.Item
              name={"orderNote"}
              className={"textAreaInput"}
            >
              <Input.TextArea
                rows={3}
                className={"textAreaInputText muiInput"}
                maxLength={500}
                showCount
              />
            </Form.Item>
            {(orderDetails.state === "awaitingQuote" || orderDetails.state === "quoted") &&
              <Form.Item
                name={"odooUrl"}
                label={"Odoo Link"}
                rules={[{
                  required: true, message: 'Odoo link is required'
                },{
                  type: "url",
                  message: "This field must be a valid url."
                }]}
                initialValue={""}
              >
                <Input
                  style={{ color: "white" }}
                  placeholder={"Enter the Odoo link for this quote."}
                />
              </Form.Item>
            }
          </Form>
        </Modal>

        <Modal
          title="Undo Changes"
          centered={true}
          visible={changesVisible}
          onCancel={() => setChangesVisible(false)}
          destroyOnClose
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setChangesVisible(false)}
              disabled={isRevertingOrder}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{fontWeight: 500}}
              onClick={() => handleRevertOrder()}
              loading={isRevertingOrder}
            >
              <RedoOutlined style={{fontSize: 14}}/>
              Undo Changes
            </Button>
          ]}
        >
          <Row>
            <Col span={4}>
              <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", width: "100%", height: "100%"}}>
                <WarningOutlined style={{ fontSize: 54, marginRight: 18, color: "#faad14", marginTop: 8 }}/>
              </div>
            </Col>
            <Col span={20}>
              <h4>Are you sure you want to UNDO all changes made to this quote ?</h4>
              <h4>All parts will be reverted to their original state and all additional parts will be removed.</h4>
              <h4>This action CAN NOT be undone!</h4>
            </Col>
          </Row>
        </Modal>

        <Modal
          title={"Odoo Link"}
          centered={true}
          visible={odooVisible}
          onCancel={() => setOdooVisible(false)}
          destroyOnClose
          footer={[
            <Button
              key="cancel"
              style={{fontWeight: 500}}
              onClick={() => setOdooVisible(false)}
              disabled={false}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{fontWeight: 500}}
              disabled={isUpdatingOdooLink}
              onClick={() =>
                linkForm
                  .validateFields()
                  .then((values) => {
                    dispatch(setOdooLink({orderId: orderDetails.id, odooUrl: values.odooUrl}))
                      .then(setOdooVisible(false))
                  })
                  .catch(() => {})
              }
              loading={false}
            >
              Save Link
            </Button>
          ]}
        >
          <Form
            form={linkForm}
          >
            <Form.Item
              name={"odooUrl"}
              label={"Odoo Quote Link"}
              rules={[{
                required: true,
                message: "Please enter the Odoo link for this quote."
              },{
                type: "url",
                message: "This field must be a valid url."
              }]}
              initialValue={orderDetails.odooUrl || ""}
            >
              <Input
                placeholder={"Enter the Odoo link for this quote."}
                style={{ color: "white" }}
              />
            </Form.Item>
          </Form>
        </Modal>

        <OrderViewOtherModal
          viewOtherVisible={isDetailsView}
          closeOtherView={closeDetailsView}
          item={otherItemView}
          showMaterialGroup={false}
          showPrice={true}
        />

        <OrderDownloadFilesModal
          isVisible={downloadFilesVisible}
          onCancel={() => handleCloseDownloadFiles()}
          filesItem={filesItem}
        />

        <Modal
          title={"Change Part Quantity"}
          visible={quantityChangeVisible}
          footer={
            <div align={"right"}>
              <Button onClick={() => {
                setQuantityChangeVisible(false);
                setSelectedPartQuantityData(null);
              }}>
                <span style={{ fontWeight: 500 }}>Cancel</span>
              </Button>
              <Button type={"primary"}
                      form={"producedQuantityForm"}
                      key={"submit"}
                      htmlType={"submit"}
                      loading={false}
              >
                <span style={{ fontWeight: 500 }}>Save</span>
              </Button>
            </div>
          }
          bodyStyle={{ paddingBottom: 6 }}
          centered={true}
          onCancel={() => {
            setQuantityChangeVisible(false);
            setSelectedPartQuantityData(null);
          }}
          destroyOnClose={true}
          width={520}
        >
          {selectedPartQuantityData !== null &&
            <div style={{ color: "white"}} >
              <div>
                <span>Total Quantity:</span>
                <span style={{ marginLeft: 4, fontWeight: 500 }}>{selectedPartQuantityData.quantity}</span>
              </div>
              <div>
                <span >Produced:</span>
                <span style={{ marginLeft: 4, fontWeight: 500 }}>{selectedPartQuantityData.producedQuantity}</span>
              </div>
              <div>
                <span>Pending:</span>
                <span style={{ marginLeft: 4, fontWeight: 500 }}>{selectedPartQuantityData.pendingProductionQuantity}</span>
              </div>
              <Divider style={{ marginTop: 6, marginBottom: 6 }}/>
              {selectedPartQuantityData.pendingProductionQuantity !== 0 &&
                <div style={{marginBottom: 4}}>
                  <span style={{fontSize: 12}}>Scrap the plates where this part is pending to add more than the current limit.</span>
                </div>
              }
              <div className={"editQuant"}>
                <span>Edit Produced Quantity:</span>
                <Form name={"producedQuantityForm"}
                      validateTrigger={"onSubmit"}
                      onFinish={handleQuantityChange}
                >
                  <Form.Item name={"newQuantity"}
                             initialValue={selectedPartQuantityData.producedQuantity}
                             rules={[
                               {required: true, message: 'Input is required'},
                             ]}
                             validateTrigger={"onSubmit"}
                  >
                    <InputNumber style={{ width: 102, display: "inline-block" }}
                                 min={0}
                                 max={selectedPartQuantityData.quantity - selectedPartQuantityData.pendingProductionQuantity}
                                 size={"small"}
                                 disabled={selectedPartQuantityData.quantity - selectedPartQuantityData.pendingProductionQuantity === 0}
                    />
                  </Form.Item>
                </Form>
                  <Tooltip title={"Current Limit"}>
                    <span style={{ fontWeight: 500 }}>
                      <span style={{ fontSize: 12, fontWeight: 600, marginLeft: 2 }}>/</span>
                      {selectedPartQuantityData.quantity - selectedPartQuantityData.pendingProductionQuantity}
                    </span>
                  </Tooltip>
              </div>
            </div>
          }
        </Modal>

        <ProducersPricesModal
          visible={allPricesModalVisible}
          modalItem={pricesModalItem}
          onClose={handleClosePricesModal}
          applyAdjustment={false}
          compMaxWidth={514}
        />

        <DocumentsModal
          visible={additionalDocsVisible}
          handleCloseDocsModal={handleCloseDocsModal}
          additionalDocs={additionalDocs}
        />

        <Drawer
          className={"exportDrawer"}
          title={<span style={{ whiteSpace: "nowrap" }}>Export Files</span>}
          placement={"right"}
          onClose={() => setShowDrawer(false)}
          visible={showDrawer}
          destroyOnClose={true}
          closable={true}
          width={180}
        >
          <OrderExcelExportButton
            role={role}
            name={orderDetails.name}
            isEditing={isEditing}
            parts={orderDetails.processedParts || orderDetails.parts || []}
            adjustment={orderDetails.adjustment || 0}
            squared={true}
          />
          <div style={{ width: "100%", borderTop: "1px solid #777", marginTop: 10, marginBottom: 10 }} />
          {!(role === "regularCustomer" || role === "corporateCustomer") &&
            <OrderPdfExportButton
              orderData={{
                name: orderDetails.name,
                orderDate: orderDetails.orderDate,
                deliveryDate: orderDetails.deliveryDate,
                totalPrice: orderDetails.totalPrice,
                customer: orderDetails.customer,
                shippingData: orderDetails.shippingData,
                producer: orderDetails.producer || user.producer,
              }}
              isEditing={isEditing}
              parts={orderDetails.processedParts || orderDetails.parts || []}
              squared={true}
            />
          }
          {!(role === "regularCustomer" || role === "corporateCustomer") &&
            <div>
              <div style={{ width: "100%", borderTop: "1px solid #777", marginTop: 10, marginBottom: 10 }} />
              <Button
                type="primary"
                className={"downloadButton"}
                style={{
                  marginRight: 5,
                  fontSize: 13,
                  fontWeight: 500,
                  marginTop: 5
                }}
                onClick={handleDownloadZip}
                loading={isGeneratingZip}
                disabled={isEditing}
              >
                <FileZipOutlined style={{fontSize: 20}}/>
                <div style={{ fontSize: 16, margin: 0 }}>All Parts</div>
              </Button>
            </div>
          }
          <div style={{ width: "100%", borderTop: "1px solid #777", marginTop: 10, marginBottom: 7 }} />
          <div style={{ color: "#ccc", fontSize: 12, marginLeft: 5 }}>
            Click a button to download the files.
          </div>
        </Drawer>

      </Layout.Content>
    </Layout>
  )
}
