import React from "react";
import { Select } from "antd";
import { CaretDownOutlined, CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import "./style.less"

const { Option } = Select;

export const PartTypeSelect = (props) => {
  const {
    item, handleChangeType
  } = props;

  return (
    <Select
      size={"small"}
      className={"muiInput"}
      dropdownClassName={"part-type-select-dropdown"}
      suffixIcon={<CaretDownOutlined />}
      style={{marginLeft: 10, minWidth: 150}}
      onChange={value => {
        handleChangeType(item.id, item, value)
      }}
      virtual={false}
      defaultValue={item.partType}
    >
      <Option
        className={"part-type-select-option"}
        value={"sheetMetal"}
      >
        <span>
          {item.isSheetMetal ?
            <CheckOutlined style={{ color: "#57d33c", fontSize: 11, marginRight: 3 }}/> :
            <ExclamationCircleOutlined style={{color: "#ffc107", fontSize: 11, marginRight: 3}}/>
          } Sheet Metal
        </span>
      </Option>
      <Option
        className={"part-type-select-option"}
        value={"milled"}
      >
        <span>
          {item.isMilled ?
            <CheckOutlined style={{ color: "#57d33c", fontSize: 11, marginRight: 3 }}/> :
            <ExclamationCircleOutlined style={{color: "#ffc107", fontSize: 11, marginRight: 3}}/>
          } Milled
        </span>
      </Option>
      <Option
        className={"part-type-select-option"}
        value={"lathed"}
      >
        <span>
          {item.isLathed ?
            <CheckOutlined style={{ color: "#57d33c", fontSize: 11, marginRight: 3 }}/> :
            <ExclamationCircleOutlined style={{color: "#ffc107", fontSize: 11, marginRight: 3}}/>
          } Lathed
        </span>
      </Option>
      <Option
        className={"part-type-select-option"}
        value={"tubeCut"}
      >
        <span>
          {item.isTubeCut ?
            <CheckOutlined style={{ color: "#57d33c", fontSize: 11, marginRight: 3 }}/> :
            <ExclamationCircleOutlined style={{color: "#ffc107", fontSize: 11, marginRight: 3}}/>
          } Tube Cut
        </span>
      </Option>
      <Option
        className={"part-type-select-option"}
        value={"profile"}
      >
        <span>
          <ExclamationCircleOutlined style={{color: "#ffc107", fontSize: 11, marginRight: 3}}/> Standard Profile
        </span>
      </Option>
      <Option
        className={"part-type-select-option"}
        value={"assembly"}
      >
        <span>
          <ExclamationCircleOutlined style={{color: "#ffc107", fontSize: 11, marginRight: 3}}/> Assembly
        </span>
      </Option>
      <Option
        className={"part-type-select-option"}
        value={"buyPart"}
      >
        <span>
          <ExclamationCircleOutlined style={{color: "#ffc107", fontSize: 11, marginRight: 3}}/> Buy Part
        </span>
      </Option>
      <Option
        className={"part-type-select-option"}
        value={"bulkMaterial"}
      >
        <span>
          <ExclamationCircleOutlined style={{color: "#ffc107", fontSize: 11, marginRight: 3}}/> Bulk Material
        </span>
      </Option>
      <Option
        className={"part-type-select-option"}
        value={"other"}
      >
        <span>
          <ExclamationCircleOutlined style={{color: "#ffc107", fontSize: 11, marginRight: 3}}/> Other
        </span>
      </Option>
    </Select>
  )
}