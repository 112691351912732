import React from "react";
import {Button, Layout, Menu, Row} from "antd";
import history from "../../../utils/history";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {MaterialIcon} from "../../../assets/icons/material";
import {useDispatch, useSelector} from "react-redux";
import {collapseSidebar} from "../../../actions/utilActions";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import WysiwygOutlinedIcon from "@mui/icons-material/WysiwygOutlined";

let selectedField = '1';

function activeComponent(history) {
  switch (history.location.pathname)
  {
    case('/'):
      selectedField = '1';
      break;
    case('/calculation'):
      selectedField = '2';
      break;
    default:
      break;
  }
  return selectedField;
}

export const ProducerSidebar = (props) => {

  const dispatch = useDispatch();

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const onCollapse = () => {
    dispatch(collapseSidebar(!isCollapsed));
  }

  const { onLogout, user } = props

  return (
    <Layout.Sider
      style={{
        overflow: 'auto',
        minHeight: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 1000,
      }}
      collapsed={isCollapsed}
    >
      <Button className={"collapseButton"}
              style={{ left: isCollapsed ? '50px' : '190px'}}
              onClick={() => onCollapse()}
      >
        { isCollapsed ?
          <RightOutlined style={{ fontSize: 14, position: "relative", top: 1 }}/>
          :
          <LeftOutlined style={{ fontSize: 14, position: "relative", top: 1  }}/>
        }
      </Button>
      <Row style={{marginTop: '2rem', height: "100%"}}>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys = {[activeComponent(history)]}
        >
          <Menu.Item
            key="1"
            onClick={() => {
              history.push('/')
            }}
            title={<span className="nav-text">Materials</span>}
            style={{ paddingLeft: 21, display: "block" }}
          >
            <a href={"/"} onClick={(e) => e.preventDefault()}>
              <MaterialIcon style={{fontSize: 20, position: "relative", right: 2, top: 1}}/>
              <span
                className={"menuLine"}
                style={{ marginLeft: 9, opacity: isCollapsed ? 0 : 1}}
              >Materials</span>
            </a>
          </Menu.Item>

          <Menu.Item
            key="2"
            onClick={() => {
              history.push('/calculation')
            }}
            title={<span className="nav-text">Calculation</span>}
            style={{ paddingLeft: 21 }}
          >
            <a href={"/calculation"} onClick={(e) => e.preventDefault()}>
              <div align={'center'}
                   style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <WysiwygOutlinedIcon style={{fontSize: 22}}/>
                <span
                  className={"menuLine"}
                  style={{ opacity: isCollapsed ? 0 : 1}}
                >Calculation</span>
              </div>
            </a>
          </Menu.Item>

        </Menu>
        <Menu
          theme="dark"
          mode="inline"
          style={{position: "absolute", bottom: 20}}
        >
          <Menu.Item
            key="6"
            onClick={onLogout}
            title={<span className="nav-text">Sign out</span>}
            style={{ paddingLeft: 22 }}
          >
            <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
              <LogoutOutlinedIcon style={{fontSize: 22}}/>
              <span
                className={"menuLine"}
                style={{ opacity: isCollapsed ? 0 : 1 }}
              >Sign Out</span>
            </div>
          </Menu.Item>
        </Menu>
      </Row>
    </Layout.Sider>
  )
}