import React, { Fragment, useEffect, useState } from "react";
import { Sidebar } from "../../../components";
import { logoutUser } from "../../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Divider, Layout, Row, Table, Tag } from "antd";
import { formatTableDate, getColumnSearchProps } from "../../../utils/utility";
import history from "../../../utils/history";
import { LoadingOutlined } from "@ant-design/icons";
import { EmptyTable } from "../../../components/EmptyTable";
import { fetchCustomerAcceptedOrders, fetchCustomerAwaitingConfirmationOrders } from "../../../actions/ordersActions";

export const CustomerAwaitingPage = () => {
  let searchInput = null;

  const user = useSelector(state => state.auth.user)
  const isCollapsed = useSelector(state => state.util.isCollapsed)
  const pagination = useSelector(state => state.orders.customerAwaitingConfirmationPagination)
  const isFetchingCustomerAwaitingConfirmationOrders = useSelector(state => state.orders.isFetchingCustomerAwaitingConfirmationOrders)
  const customerAwaitingConfirmation = useSelector(state => state.orders.customerAwaitingConfirmation)

  const dispatch = useDispatch();

  const [sortedInfo, setSortedInfo] = useState({})
  const [filteredInfo, setFilteredInfo] = useState({})

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  useEffect(() => {
    dispatch(fetchCustomerAwaitingConfirmationOrders({
      sortField: "lastStateChangeDate",
      sortDirection: "desc",
      orderName: "",
      customName: "",
      page: pagination.current !== undefined ? pagination.current : 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
  }, [])

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const sortField = sorter.order ? sorter.columnKey : "lastStateChangeDate"
    const sortDirection = sorter.order ? (sorter.order === "ascend" ? "asc" : "desc") : "desc"
    const orderName = filters.orderName ? filters.orderName[0] : ""
    const customName = filters.customName ? filters.customName[0] : ""

    setFilteredInfo(filters)
    setSortedInfo(sorter)

    dispatch(fetchCustomerAwaitingConfirmationOrders({
      sortField: sortField,
      sortDirection: sortDirection,
      orderName: orderName,
      customName: customName,
      page: pagination.current,
      pagination: pager
    }))
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <Sidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{overflowX: "hidden"}}>
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            <Row type={"flex"}>
              <Col span={24}>
                <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                  <div className={"pageTitle"}>Awaiting quotes:</div>
                </div>
                <Divider style={{marginTop: 6, marginBottom: 11}}/>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={24}>
                <Fragment>
                  <Table
                    columns={[
                      {
                        title: 'Quote Code',
                        key: 'orderName',
                        className: "darkerColumn",
                        width: "10%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.orderName}
                          </div>
                        ),
                        filteredValue: filteredInfo.orderName || null,
                        ...getColumnSearchProps('code', searchInput),
                      },
                      {
                        title: "Quote Name",
                        key: "customName",
                        ellipsis: {showTitle: false},
                        width: "20%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.customName || "/"}
                          </div>
                        ),
                        ...getColumnSearchProps('customName', searchInput,"orders"),
                      },
                      {
                        title: 'Quote date',
                        key: 'orderDate',
                        className: "darkerColumn",
                        width: "12%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {formatTableDate(row.orderDate)}
                          </div>
                        ),
                        sorter: true,
                        sortOrder: sortedInfo.columnKey === 'orderDate' && sortedInfo.order
                      },
                      {
                        title: 'Delivery date',
                        key: 'deliveryDate',
                        width: "12%",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {formatTableDate(row.deliveryDate)}
                          </div>
                        ),
                        sorter: true,
                        sortOrder: sortedInfo.columnKey === 'deliveryDate' && sortedInfo.order
                      },
                      {
                        title: 'Total price',
                        key: 'totalWithVat',
                        className: "darkerColumn",
                        width: "13%",
                        align: "right",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {row.state === "quoted" ?
                              `${(row.totalPrice * (1 + row.vatValue) || row.totalPrice || 0).toFixed(2)}€`
                              :
                              row.totalPrice ? `${(row.totalPrice * (1 + row.vatValue) || row.totalPrice || 0).toFixed(2)}€ + RFQ` : "RFQ"
                            }
                          </div>
                        ),
                      },
                      {
                        title: 'Modified',
                        key: 'lastStateChangeDate',
                        width: "12%",
                        align: "right",
                        render: row => (
                          <div style={{fontWeight: 500, fontSize: 13}}>
                            {formatTableDate(row.lastStateChangeDate || 0)}
                          </div>
                        ),
                        sorter: true,
                        sortOrder: sortedInfo.columnKey === 'lastStateChangeDate' && sortedInfo.order
                      },
                      {
                        title: 'Status',
                        key: 'stateName',
                        className: "darkerColumn column200",
                        width: "200px",
                        align: "center",
                        render: () => (
                          <div>
                            <Tag color="#9966cc" style={{fontWeight: 600, textAlign: "center"}}>Awaiting</Tag>
                          </div>
                        ),
                      },
                      {
                        title: '',
                        key: 'more',
                        align: "right",
                        render: row => (
                          <Button
                            onMouseDown={(e) => {
                              if (e.button === 1) {
                                const win = window.open(`/awaiting-order?order=${row.id}`, "_blank");
                                win.focus();
                              } else if (e.button === 0) {
                                history.push(`/awaiting-order?order=${row.id}`)
                              }
                            }}
                            type="primary"
                            style={{
                              fontSize: 13,
                              fontWeight: 600
                            }}
                          >
                            Details
                          </Button>
                        ),
                      },
                    ]}
                    className={"antTable"}
                    dataSource={customerAwaitingConfirmation}
                    rowKey="id"
                    pagination={{...pagination, pageSize: pageSize}}
                    loading={{spinning: isFetchingCustomerAwaitingConfirmationOrders, indicator: <LoadingOutlined style={{ fontSize: 50,marginLeft: "-25px" }} spin />}}
                    onChange={handleTableChange}
                    size={"small"}
                    scroll={{x: '70%'}}
                    locale={{ emptyText: <EmptyTable text={"No Data"} /> }}
                  />
                </Fragment>
              </Col>
            </Row>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}