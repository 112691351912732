import {Button, Card, Spin, Tooltip, Grid} from "antd";
import {imageSection, materialInfoSection} from "../../../utils/partCardUtils/sections";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FilePdfOutlined,
  SearchOutlined
} from "@ant-design/icons";
import React from "react";
import {CircularProgress} from "@mui/material";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import {canHaveCertificate, getWeight, hasWeight} from "../../../utils/utility";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";

const OrderPartItem = (props) => {
  const {
    part, showOtherItem, showDetailsItem, isEditing, editMap, editItemFunc,
    handleRemovePartOnEdit, orderState, role, handleShowDownloadFiles,
    handleOpenPricesModal, assemblyCount, handleOpenDocsModal, adjustment
  } = props;

  return (
    <Card
      className={isEditing && editMap.items[part.id] ?
        (editMap.items[part.id].state === "declined" ? "declinedCard bendCard" : "changedCard bendCard")
        :
        part.state === "declined" ? "declinedCard bendCard" :
          (part.state === "edited" && (orderState === "quoted" || orderState === "awaitingQuote" || orderState === "awaitingConfirmation") ?
            "editedCard bendCard" : "bendCard")
      }
      style={{height: 80, marginTop: 6, marginBottom: 6, backgroundColor: part.assemblyId ? "#354045" : ""}}
      bodyStyle={{
        padding: "8px 12px 8px 12px",
        height: "100%"
      }}
      key={part.id}
    >
      <div style={{display: "flex", width: "100%"}}>
        {imageSection(part)}
        <div style={{width: "100%"}}>
          <div style={{width: "100%", height: 22}}>
            <table style={{width: "100%"}}>
              <tbody>
              <tr>
                <td style={{width: "calc(100% - 100px)", maxWidth: 150}}>
                  <div className={"partInfo"}>
                    {part.fileName || part.name}
                  </div>
                </td>
                <td style={{width: 100}}>
                  <div style={{display: "flex", height: 24, justifyContent: "flex-end"}}>
                    {isEditing ?
                      (part.state === "declined" || editMap.items[part.id] && editMap.items[part.id].state === "declined" ?
                        <div style={{height: 24}}/>
                        :
                        <div style={{display: "flex", height: 24}}>
                          <Tooltip
                            title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>Edit Part</span>}
                            placement={"topRight"}
                            color={"#d6d6d6"}
                          >
                            <EditOutlined
                              onClick={() => {
                                editItemFunc(part)
                              }}
                              style={{fontSize: 16, marginRight: 5, color: "#1890ff", padding: 2}}
                            />
                          </Tooltip>
                          <Tooltip
                            title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>Reject Part</span>}
                            placement={"topRight"}
                            color={"#d6d6d6"}
                          >
                            <DeleteOutlined
                              onClick={() => {
                                handleRemovePartOnEdit(part)
                              }}
                              style={{fontSize: 16, color: "#ff4d4f", padding: 2}}
                            />
                          </Tooltip>
                        </div>
                      )
                      :
                      (part.state === "declined" ?
                        <div className={"deletedItemCorner"}>
                          Rejected
                        </div>
                        :
                        <div style={{display: "flex", height: 24}}>
                          {part.documents?.length > 0 &&
                          <Tooltip
                            title={<span
                              style={{color: "black", fontSize: 12, fontWeight: 500}}>Download Documents</span>}
                            placement={"topRight"}
                            color={"#d6d6d6"}
                          >
                            <Button
                              size={"small"}
                              type={"primary"}
                              className={"exportPdfButton"}
                              onClick={() => {
                                handleOpenDocsModal(part.documents)
                              }}
                            >
                              <div>
                                <FilePdfOutlined style={{fontSize: 12}}/>
                              </div>
                            </Button>
                          </Tooltip>
                          }
                          {(part.processingType === "automatic" && (role === "gelsoAdmin" || role === "gelsoSubAdmin")) &&
                          <Tooltip
                            title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>View Different Producers Prices</span>}
                            placement={"topLeft"}
                            color={"#d6d6d6"}
                          >
                            <Button
                              size={"small"}
                              className={"d6Button"}
                              style={{marginLeft: 5}}
                              onClick={() => {
                                handleOpenPricesModal({...part, assemblyCount: assemblyCount, adjustment: adjustment})
                              }}
                            >
                              <AssignmentOutlinedIcon sx={{fontSize: "14px !important"}}/>
                            </Button>
                          </Tooltip>
                          }
                          {((role === "gelsoAdmin" || role === "gelsoSubAdmin") && part.originType !== "additional") &&
                          <Tooltip
                            title={<span
                              style={{color: "black", fontSize: 12, fontWeight: 500}}>Download Files</span>}
                            placement={"topRight"}
                            color={"#d6d6d6"}
                          >
                            <Button
                              size={"small"}
                              type={"primary"}
                              style={{marginLeft: 5}}
                              onClick={() => handleShowDownloadFiles(part)}
                            >
                              <DownloadOutlined style={{fontSize: 13}}/>
                            </Button>
                          </Tooltip>
                          }
                          <Tooltip
                            title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>View Part</span>}
                            placement={"topRight"}
                            color={"#d6d6d6"}
                          >
                            <Button
                              size={"small"}
                              type={"primary"}
                              style={{marginLeft: 5}}
                              onClick={() => {
                                if (part.fileType === "other" || (part.processingType === "manual" && !part.fileType)) {
                                  showOtherItem(part)
                                } else {
                                  showDetailsItem(part)
                                }
                              }}
                            >
                              <div>
                                <SearchOutlined style={{fontSize: 13}}/>
                              </div>
                            </Button>
                          </Tooltip>
                        </div>
                      )
                    }
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div style={{display: "flex", alignItems: "flex-end"}}>
              {part.certNeeded && canHaveCertificate(part.partType) &&
              <Tooltip
                title={<span>3.1 Material Certificate Requested for this part.</span>}
                placement={"topLeft"}
              >
                <TaskOutlinedIcon
                  className={"materialCertificateIcon"}
                  style={{ fontSize: 18, position: "relative", bottom: 2}}
                />
              </Tooltip>
              }
              {materialInfoSection(part)}
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{fontSize: 12, fontWeight: 500, marginRight: 8}}>
                Quantity:
                <span style={{fontWeight: 600, marginLeft: 4}}>{part.quantity}</span>
              </div>
              {isEditing && editMap.items[part.id] ?
                <div
                  style={{fontSize: 12, fontWeight: 500}}
                >
                  1pc: <span
                  style={{fontWeight: 600, color: "#4ec750"}}>{editMap.items[part.id].newPrice.toFixed(2)} €</span>
                </div>
                :
                <div
                  style={{fontSize: 12, fontWeight: 500}}
                >
                  1pc: <span style={{
                  fontWeight: 600,
                  color: part.state === "edited" && (orderState === "quoted" || orderState === "awaitingQuote" || orderState === "awaitingConfirmation") ? "#4ec750" : "rgb(255,255,255)"
                }}>{(part.state === "declined" ? 0 : (parseFloat(part.pricePerPiece) || 0)).toFixed(2)} €</span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

const OrderAssemblyItem = (props) => {
  const {
    part, showOtherItem, showDetailsItem, isEditing, editMap,
    orderState, role, handleShowDownloadFiles, handleOpenDocsModal,
    getAssemblyWeight
  } = props;

  return (
    <div style={{display: "flex", width: "100%"}}>
      {imageSection(part)}
      <div style={{width: "100%"}}>
        <div style={{width: "100%", height: 22}}>
          <table style={{width: "100%"}}>
            <tbody>
            <tr>
              <td style={{width: "calc(100% - 100px)", maxWidth: 150}}>
                <div className={"partInfo"}>
                  {part.fileName || part.name}
                </div>
              </td>
              <td style={{width: 100}}>
                <div style={{display: "flex", height: 24, justifyContent: "flex-end"}}>
                  {part.state === "declined" ?
                    <div className={"deletedItemCorner"} style={{fontWeight: 500}}>
                      Rejected
                    </div>
                    :
                    <div style={{display: "flex", height: 24}}>
                      {part.documents?.length > 0 &&
                      <Tooltip
                        title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>Download Documents</span>}
                        placement={"topRight"}
                        color={"#d6d6d6"}
                      >
                        <Button
                          size={"small"}
                          type={"primary"}
                          className={"exportPdfButton"}
                          disabled={isEditing}
                          onClick={() => {
                            handleOpenDocsModal(part.documents)
                          }}
                        >
                          <div>
                            <FilePdfOutlined style={{fontSize: 12}}/>
                          </div>
                        </Button>
                      </Tooltip>
                      }
                      {((role === "gelsoAdmin" || role === "gelsoSubAdmin") && part.originType !== "additional") &&
                      <Tooltip
                        title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>Download Files</span>}
                        placement={"topRight"}
                        color={"#d6d6d6"}
                      >
                        <Button
                          size={"small"}
                          type={"primary"}
                          disabled={isEditing}
                          style={{marginLeft: 5}}
                          onClick={() => handleShowDownloadFiles(part)}
                        >
                          <DownloadOutlined style={{fontSize: 13}}/>
                        </Button>
                      </Tooltip>
                      }
                      <Tooltip
                        title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>View Part</span>}
                        placement={"topRight"}
                        color={"#d6d6d6"}
                      >
                        <Button
                          size={"small"}
                          type={"primary"}
                          style={{marginLeft: 5}}
                          disabled={isEditing}
                          onClick={() => {
                            if (part.fileType === "other" || (part.processingType === "manual" && !part.fileType)) {
                              showOtherItem(part)
                            } else {
                              let assemblyWeight = getAssemblyWeight(part.id)
                              showDetailsItem({
                                ...part,
                                assemblyWeight: assemblyWeight
                              })
                            }
                          }}
                        >
                          <div>
                            <SearchOutlined style={{fontSize: 13}}/>
                          </div>
                        </Button>
                      </Tooltip>
                    </div>
                  }
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div>
            <div style={{fontSize: 12, fontWeight: 500, marginRight: 8}}>
              Number of Unique Parts:
              <span style={{fontWeight: 600, marginLeft: 4}}>{part.numberOfUniqueParts}</span>
            </div>
          </div>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{fontSize: 12, fontWeight: 500, marginRight: 8}}>
              Number of Assemblies:
              <span style={{fontWeight: 600, marginLeft: 4}}>{part.quantity}</span>
            </div>
            {isEditing && editMap.items[part.id] ?
              <div
                style={{fontSize: 12, fontWeight: 500}}
              >
                1pc: <span
                style={{fontWeight: 600, color: "#4ec750"}}>{editMap.items[part.id].newPrice.toFixed(2)} €</span>
              </div>
              :
              <div
                style={{fontSize: 12, fontWeight: 500}}
              >
                1pc: <span style={{
                fontWeight: 600,
                color: part.state === "edited" && (orderState === "quoted" || orderState === "awaitingQuote" || orderState === "awaitingConfirmation") ? "#4ec750" : "rgb(255,255,255)"
              }}>{(part.state === "declined" ? 0 : (parseFloat(part.pricePerPiece) || 0)).toFixed(2)} €</span>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export const OrderDetailsPartProcessedPartsList = (props) => {
  const {
    parts, showOtherItem, showDetailsItem, unprocessedPartsLength,
    isEditing, editMap, editItemFunc, handleRemovePartOnEdit,
    isFetchingProcessedList, orderState, role, handleShowDownloadFiles,
    handleOpenPricesModal, handleOpenDocsModal, adjustment
  } = props;

  const getAssemblyParts = (assemblyId) => {
    return parts.filter(item => item.assemblyId === assemblyId)
  }

  const getAssemblyWeight = (assemblyId) => {
    let assemblyParts = getAssemblyParts(assemblyId)
    let weight = 0;
    for (const assemblyPart of assemblyParts) {
      if (hasWeight({item: assemblyPart})) {
        weight += parseFloat(getWeight({item: assemblyPart})) * (assemblyPart.quantity || 1)
      }
    }
    return weight
  }

  return (
    <Spin
      spinning={isFetchingProcessedList}
      indicator={<CircularProgress sx={{color: "#da291c"}} size={48} thickness={2}
                                   style={{marginTop: -24, marginLeft: -24}}/>}
    >
      <div
        className={"quotedItemsList"}
        style={{
          minHeight: "550px",
          height: "calc(100vh - 132px)",
          padding: "0 6px 0 6px",
        }}
      >
        {parts.map(part => (
          part.partType === "assembly" && part.processingType !== "manual" ?
            <Card
              className={"assemblyPartsCard"}
              key={part.id}
            >
              <div
                className={isEditing && editMap.items[part.id] ?
                  (editMap.items[part.id].state === "declined" ? "declinedCard assemblyPartName" : "changedCard assemblyPartName")
                  :
                  part.state === "declined" ? "declinedCard assemblyPartName" : "assemblyPartName"
                }
              >
                <OrderAssemblyItem
                  part={part}
                  showOtherItem={showOtherItem}
                  showDetailsItem={showDetailsItem}
                  getAssemblyWeight={getAssemblyWeight}
                  isEditing={isEditing}
                  editMap={editMap}
                  editItemFunc={editItemFunc}
                  handleRemovePartOnEdit={handleRemovePartOnEdit}
                  orderState={orderState}
                  role={role}
                  handleShowDownloadFiles={handleShowDownloadFiles}
                  handleOpenDocsModal={handleOpenDocsModal}
                />
              </div>
              <div className={"assemblyParts"}>
                {getAssemblyParts(part.id).map(assemblyPart => (
                  <div key={assemblyPart.id}>
                    <OrderPartItem
                      part={assemblyPart}
                      showOtherItem={showOtherItem}
                      showDetailsItem={showDetailsItem}
                      isEditing={isEditing}
                      editMap={editMap}
                      editItemFunc={editItemFunc}
                      handleRemovePartOnEdit={handleRemovePartOnEdit}
                      orderState={orderState}
                      role={role}
                      handleShowDownloadFiles={handleShowDownloadFiles}
                      handleOpenPricesModal={handleOpenPricesModal}
                      assemblyCount={part.quantity}
                      handleOpenDocsModal={handleOpenDocsModal}
                      adjustment={adjustment}
                    />
                  </div>
                ))}
              </div>
            </Card>
            :
            (!part.assemblyId &&
              <OrderPartItem
                part={part}
                showOtherItem={showOtherItem}
                showDetailsItem={showDetailsItem}
                isEditing={isEditing}
                editMap={editMap}
                editItemFunc={editItemFunc}
                handleRemovePartOnEdit={handleRemovePartOnEdit}
                orderState={orderState}
                role={role}
                handleShowDownloadFiles={handleShowDownloadFiles}
                handleOpenPricesModal={handleOpenPricesModal}
                handleOpenDocsModal={handleOpenDocsModal}
                adjustment={adjustment}
                key={part.id}
              />
            )
        ))}
      </div>
    </Spin>
  )
}