import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {withStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {Card, Col, Divider, Row} from "antd";
import {
  calcAdjustment, getTotalLathingSuggestedPrice,
  getTotalMillingSuggestedPrice,
  getTotalSheetMetalSuggestedPrice, getTotalTubeCuttingSuggestedPrice
} from "../../../utils/utility";
import {EmptyTable} from "../../EmptyTable";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters={true} elevation={0} square {...props} />
))(({theme}) => ({
  marginTop: "6px !important",
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon fontSize={"small"} sx={{color: "white"}}/>}
    {...props}
  />
))(({theme}) => ({
  minHeight: "32px !important",
  maxHeight: "32px !important",
  backgroundColor: "#14181a !important",
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: "8px !important",
    width: '100% !important',
    justifyContent: "space-between",
    alignItems: "center"
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginLeft: "8px !important",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "12px !important"
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
    fontSize: "14px !important",
    marginTop: "1px !important"
  }
})(Typography);

const SheetMetalContent = (props) => {
  const {item, itemQuantity, certNeeded, adjustment} = props;
  return (
    <Row style={{ width: "100%" }} gutter={12}>
      <Col span={24}>
        <WhiteTextTypography>
          <b>Price per 1 Piece: {((getTotalSheetMetalSuggestedPrice(item?.operationPrices, itemQuantity) || 0) * calcAdjustment(adjustment)).toFixed(2)}€</b>
        </WhiteTextTypography>
        <Divider style={{ margin: 2, borderColor: "#838f94", marginBottom: 4 }}/>
      </Col>
      <Col span={12}>
        <div style={{ color: "white"}}>
          <div className={"tooltipLine"}>
            <div>Cutting price:</div>
            <div>{((item?.operationPrices?.cuttingPricePerPiece) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Cutting preparation price:</div>
            <div>{((item?.operationPrices?.cuttingPreparationPrice / itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Total cutting price:</div>
            <div>{(((item?.operationPrices?.cuttingPricePerPiece * itemQuantity + item?.operationPrices?.cuttingPreparationPrice)/itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
          <div className={"tooltipLine"}>
            <div>Material price:</div>
            <div>{(item?.operationPrices?.materialPricePerPiece * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          {certNeeded &&
            <div className={"tooltipLine"}>
              <span>Certificate price:</span>
              <span>{((item?.operationPrices?.materialCertificatePrice/itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</span>
            </div>
          }
          <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
          <div className={"tooltipLine"}>
            <div>Surface finish price:</div>
            <div>{(item?.operationPrices?.coatingPricePerPiece * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
        </div>
      </Col>
      <Col span={12} style={{ borderLeft: "1px solid #838f94"}}>
        <div style={{ color: "white"}}>
          <div className={"tooltipLine"}>
            <div>Bending price:</div>
            <div>{(item?.operationPrices?.bendingPricePerPiece * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Bending preparation price:</div>
            <div>{((item?.operationPrices?.bendingPreparationPrice / itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Total bending price:</div>
            <div>{(((item?.operationPrices?.bendingPricePerPiece * itemQuantity + item?.operationPrices?.bendingPreparationPrice)/itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
          <div className={"tooltipLine"}>
            <div>Machining price:</div>
            <div>{(item?.operationPrices?.machiningPricePerPiece * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Machining preparation price:</div>
            <div>{((item?.operationPrices?.machiningPreparationPrice / itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Total machining price:</div>
            <div>{(((item?.operationPrices?.machiningPricePerPiece * itemQuantity + item?.operationPrices?.machiningPreparationPrice)/itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

const MillingContent = (props) => {
  const {item, itemQuantity, certNeeded, adjustment} = props;
  return (
    <Row style={{ width: "100%" }} gutter={12}>
      <Col span={24}>
        <WhiteTextTypography>
          <b>Price per 1 Piece: {((getTotalMillingSuggestedPrice(item?.operationPrices, itemQuantity) || 0) * calcAdjustment(adjustment)).toFixed(2)}€</b>
        </WhiteTextTypography>
        <Divider style={{ margin: 2, borderColor: "#838f94", marginBottom: 4 }}/>
      </Col>
      <Col span={24}>
        <div style={{ color: "white"}}>
          <div className={"tooltipLine"}>
            <div>Milling Price:</div>
            <div>{((item?.operationPrices?.millingPricePerPiece) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Milling preparation price:</div>
            <div>{((item?.operationPrices?.millingPreparationPrice / itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Total milling price:</div>
            <div>{(((item?.operationPrices?.millingPricePerPiece * itemQuantity + item?.operationPrices?.millingPreparationPrice)/itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
          <div className={"tooltipLine"}>
            <div>Material price:</div>
            <div>{(item?.operationPrices?.materialPricePerPiece * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          {certNeeded &&
            <div className={"tooltipLine"}>
              <span>Certificate price:</span>
              <span>{((item?.operationPrices?.materialCertificatePrice/itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</span>
            </div>
          }
          <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
          <div className={"tooltipLine"}>
            <div>Surface finish price:</div>
            <div>{(item?.operationPrices?.coatingPricePerPiece * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

const LathingContent = (props) => {
  const {item, itemQuantity, certNeeded, adjustment} = props;
  return (
    <Row style={{ width: "100%" }} gutter={12}>
      <Col span={24}>
        <WhiteTextTypography>
          <b>Price per 1 Piece: {((getTotalLathingSuggestedPrice(item?.operationPrices, itemQuantity) || 0) * calcAdjustment(adjustment)).toFixed(2)}€</b>
        </WhiteTextTypography>
        <Divider style={{ margin: 2, borderColor: "#838f94", marginBottom: 4 }}/>
      </Col>
      <Col span={24}>
        <div style={{ color: "white"}}>
          <div className={"tooltipLine"}>
            <div>Lathing Price:</div>
            <div>{((item?.operationPrices?.lathingPricePerPiece) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Lathing preparation price:</div>
            <div>{((item?.operationPrices?.lathingPreparationPrice / itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Total lathing price:</div>
            <div>{(((item?.operationPrices?.lathingPricePerPiece * itemQuantity + item?.operationPrices?.lathingPreparationPrice)/itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
          <div className={"tooltipLine"}>
            <div>Material price:</div>
            <div>{(item?.operationPrices?.materialPricePerPiece * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          {certNeeded &&
            <div className={"tooltipLine"}>
              <span>Certificate price:</span>
              <span>{((item?.operationPrices?.materialCertificatePrice/itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</span>
            </div>
          }
          <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
          <div className={"tooltipLine"}>
            <div>Surface finish price:</div>
            <div>{(item?.operationPrices?.coatingPricePerPiece * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

const TubeCutContent = (props) => {
  const {item, itemQuantity, certNeeded, adjustment} = props;
  return (
    <Row style={{ width: "100%" }} gutter={12}>
      <Col span={24}>
        <WhiteTextTypography>
          <b>Price per 1 Piece: {((getTotalTubeCuttingSuggestedPrice(item?.operationPrices, itemQuantity) || 0) * calcAdjustment(adjustment)).toFixed(2)}€</b>
        </WhiteTextTypography>
        <Divider style={{ margin: 2, borderColor: "#838f94", marginBottom: 4 }}/>
      </Col>
      <Col span={24}>
        <div style={{ color: "white"}}>
          <div className={"tooltipLine"}>
            <div>Tube cutting Price:</div>
            <div>{((item?.operationPrices?.tubeCuttingPricePerPiece) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Tube cutting preparation price:</div>
            <div>{((item?.operationPrices?.tubeCuttingPreparationPrice / itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <div className={"tooltipLine"}>
            <div>Total tube cutting price:</div>
            <div>{(((item?.operationPrices?.tubeCuttingPricePerPiece * itemQuantity + item?.operationPrices?.tubeCuttingPreparationPrice)/itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
          <div className={"tooltipLine"}>
            <div>Material price:</div>
            <div>{(item?.operationPrices?.materialPricePerPiece * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
          {certNeeded &&
            <div className={"tooltipLine"}>
              <span>Certificate price:</span>
              <span>{((item?.operationPrices?.materialCertificatePrice/itemQuantity) * calcAdjustment(adjustment)).toFixed(2)}€</span>
            </div>
          }
          <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
          <div className={"tooltipLine"}>
            <div>Surface finish price:</div>
            <div>{(item?.operationPrices?.coatingPricePerPiece * calcAdjustment(adjustment)).toFixed(2)}€</div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export const ProducerPricesAccordion = (props) => {
  const {itemPrices, quantity, certNeeded, assemblyCount, adjustment, partType} = props
  const [itemQuantity, setItemQuantity] = useState(1)

  useEffect(() => {
    setItemQuantity((quantity * (assemblyCount || 1)))
  }, [quantity, assemblyCount])

  const producersForOrder = useSelector(state => state.orders.producersForOrder)

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (!itemPrices || itemPrices.length === 0) {
    return (
      <Card className={"emptyPricesCard"}>
        <EmptyTable text={"No prices available for this part."}/>
      </Card>
    )
  }

  return (
    <div style={{width: "100%"}}>
      {(itemPrices || []).map((item, index) => {
        return (
          <Accordion style={{width: "100%"}} expanded={expanded === `panel${index+1}`} onChange={handleChange(`panel${index + 1}`)} key={index}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <WhiteTextTypography>{producersForOrder[item.producerId]?.name}</WhiteTextTypography>
              <WhiteTextTypography>Total Price: {(item.pricePerPiece * quantity * calcAdjustment(adjustment)).toFixed(2)}€</WhiteTextTypography>
            </AccordionSummary>
            <AccordionDetails>
              {partType === "sheetMetal" &&
                <SheetMetalContent
                  item={item}
                  itemQuantity={itemQuantity}
                  certNeeded={certNeeded}
                  adjustment={adjustment}
                />
              }
              {partType === "milled" &&
                <MillingContent
                  item={item}
                  itemQuantity={itemQuantity}
                  certNeeded={certNeeded}
                  adjustment={adjustment}
                />
              }
              {partType === "lathed" &&
                <LathingContent
                  item={item}
                  itemQuantity={itemQuantity}
                  certNeeded={certNeeded}
                  adjustment={adjustment}
                />
              }
              {partType === "tubeCut" &&
                <TubeCutContent
                  item={item}
                  itemQuantity={itemQuantity}
                  certNeeded={certNeeded}
                  adjustment={adjustment}
                />
              }
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  );
}