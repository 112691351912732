import React from "react";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {
  PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINE_DATA,
  PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINES,
  PRODUCER_PARAMS_IS_FETCHING_MACHINE_PRIORITIES,
  PRODUCER_PARAMS_SET_CUTTING_MACHINES,
  PRODUCER_PARAMS_SET_MACHINE_DATA,
  PRODUCER_PARAMS_SET_MACHINE_PRIORITIES,
  PRODUCER_PARAMS_IS_FETCHING_BENDING_DATA,
  PRODUCER_PARAMS_IS_FETCHING_MATERIAL_PRICES,
  PRODUCER_PARAMS_IS_FETCHING_MATERIALS,
  PRODUCER_PARAMS_IS_FETCHING_SIMPLE_MACHINING_DATA,
  PRODUCER_PARAMS_IS_FETCHING_SINGLE_MATERIAL_BENDS,
  PRODUCER_PARAMS_IS_FETCHING_SURFACE_COATING,
  PRODUCER_PARAMS_SET_BENDING_DATA,
  PRODUCER_PARAMS_SET_MATERIAL_PRICES,
  PRODUCER_PARAMS_SET_MATERIALS,
  PRODUCER_PARAMS_SET_SIMPLE_MACHINING_DATA,
  PRODUCER_PARAMS_SET_SINGLE_MATERIAL_BENDS,
  PRODUCER_PARAMS_SET_SURFACE_COATING_DATA,
  PRODUCER_PARAMS_RESET_PRODUCER_PARAMS,
  PRODUCER_PARAMS_IS_FETCHING_MACHINING_DATA,
  PRODUCER_PARAMS_SET_MACHINING_DATA,
  PRODUCER_PARAMS_IS_FETCHING_MILLING_MACHINE_DATA,
  PRODUCER_PARAMS_SET_MILLING_MACHINE_DATA,
  PRODUCER_PARAMS_SET_LATHING_MACHINE_DATA,
  PRODUCER_PARAMS_IS_FETCHING_LATHING_MACHINE_DATA,
  PRODUCER_PARAMS_IS_FETCHING_MACHINING_MATERIALS,
  PRODUCER_PARAMS_SET_MACHINING_MATERIALS,
  PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_DATA,
  PRODUCER_PARAMS_SET_TUBE_CUTTING_DATA,
  PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_MACHINE_DATA,
  PRODUCER_PARAMS_SET_TUBE_CUTTING_MACHINE_DATA,
  PRODUCER_PARAMS_IS_FETCHING_TUBE_MATERIAL_PRICES,
  TUBE_CUTTING_FETCHING_STANDARD_MATERIAL_PRICES,
  PRODUCER_PARAMS_SET_TUBE_MATERIAL_PRICES,
  PRODUCER_PARAMS_IS_FETCHING_STANDARD_TUBE_MATERIAL_PRICES,
  PRODUCER_PARAMS_SET_STANDARD_TUBE_MATERIAL_PRICES,
  TUBE_CUTTING_SET_STANDARD_MATERIAL_PRICES,
  PRODUCER_PARAMS_IS_FETCHING_MATERIAL_CERTIFICATES,
  PRODUCER_PARAMS_SET_MATERIAL_CERTIFICATES,
} from "./actionTypes";
import {forceLogoutUser} from "./authActions";
import {formatTubeCuttingMachines} from "../utils/utility";

const BASE_URL = "https://f9f1833d-f470-41cf-85b5-b98fba41d111.mock.pstmn.io"

const fetchProducerCuttingMachines = payload => dispatch => {

  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINES,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/cutting-machines/sorted-by-type`)
    .then(response => {
      dispatch({
        type: PRODUCER_PARAMS_SET_CUTTING_MACHINES,
        payload: response.data.machines
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINES,
        payload: false
      })

      return response.data.machines
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINES,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
      throw error
    })
}

const fetchProducerCuttingMachineParameters = payload => dispatch => {

  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINE_DATA,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/cutting-machines/${payload.cuttingMachineId}/properties`)
    .then(response => {
      let data = response.data.machine.groupNames.map( name => {
        let item = response.data.machine.tables.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })

      let machine = {...response.data.machine, tables: data}

      dispatch({
        type: PRODUCER_PARAMS_SET_MACHINE_DATA,
        payload: machine
      })
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINE_DATA,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINE_DATA,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchProducerCuttingMachinePriorities = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_MACHINE_PRIORITIES,
    payload: true
  })
  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/cutting-machines/sorted-by-priority`)
    .then(response => {
      dispatch({
        type: PRODUCER_PARAMS_SET_MACHINE_PRIORITIES,
        payload: response.data.machines
      })
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MACHINE_PRIORITIES,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      }
      else {
        dispatch({
          type: PRODUCER_PARAMS_IS_FETCHING_MACHINE_PRIORITIES,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchProducerMaterials = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_MATERIALS,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/producer-materials`)
    .then((response) => {
      dispatch({
        type: PRODUCER_PARAMS_SET_MATERIALS,
        payload: response.data
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MATERIALS,
        payload: false
      })
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MATERIALS,
        payload: false
      })
    })
}

const fetchProducerMaterialPrices = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_MATERIAL_PRICES,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/producer-materials/prices`)
    .then((response) => {
      let data = response.data.groupNames.map( name => {
        let item = response.data.prices.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })
      dispatch({
        type: PRODUCER_PARAMS_SET_MATERIAL_PRICES,
        payload: [...data]
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MATERIAL_PRICES,
        payload: false
      })
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MATERIAL_PRICES,
        payload: false
      })
    })
}

const fetchProducerMaterialCertificates = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_MATERIAL_CERTIFICATES,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/material-cert-fees`)
    .then((response) => {
      dispatch({
        type: PRODUCER_PARAMS_SET_MATERIAL_CERTIFICATES,
        payload: response.data
      })
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MATERIAL_CERTIFICATES,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: PRODUCER_PARAMS_IS_FETCHING_MATERIAL_CERTIFICATES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchProducerMachiningMaterials = (payload) => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_MACHINING_MATERIALS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/machining/materials`)
    .then(response => {
      dispatch({
        type: PRODUCER_PARAMS_SET_MACHINING_MATERIALS,
        payload: response.data,
      })
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MACHINING_MATERIALS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: PRODUCER_PARAMS_IS_FETCHING_MACHINING_MATERIALS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchProducerBendingData = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_BENDING_DATA,
    payload: true
  })
  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/bending/bends`)
    .then(response => {
      let bends = []
      if(response.data?.bends) {
        bends = response.data.groupNames.map( name => {
          let item = response.data.bends.find(item => item.groupName === name)
          if(item) {
            return item
          }
          else {
            return {
              groupName: name,
              rows: [],
              columns: [],
              allAvailable: {}
            }
          }
        })
      }
      dispatch({
        type: PRODUCER_PARAMS_SET_BENDING_DATA,
        payload: {
          ...response.data,
          bends: bends
        },
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_BENDING_DATA,
        payload: false,
      })
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_BENDING_DATA,
        payload: false
      })
    })
}

const fetchProducerSingleMaterialBendsData = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_SINGLE_MATERIAL_BENDS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/${payload.materialId}/bends`)
    .then(response => {
      dispatch({
        type: PRODUCER_PARAMS_SET_SINGLE_MATERIAL_BENDS,
        payload: response.data,
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_SINGLE_MATERIAL_BENDS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: PRODUCER_PARAMS_IS_FETCHING_SINGLE_MATERIAL_BENDS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const resetProducerBendsData = () => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_SET_SINGLE_MATERIAL_BENDS,
    payload: null
  })
}

const fetchProducerSimpleMachiningData = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_SIMPLE_MACHINING_DATA,
    payload: true
  })
  http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/simple-machining`)
    .then((response) => {

      dispatch({
        type: PRODUCER_PARAMS_SET_SIMPLE_MACHINING_DATA,
        payload: response.data
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_SIMPLE_MACHINING_DATA,
        payload: false
      })

    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_SIMPLE_MACHINING_DATA,
        payload: false
      })
    })
}

const fetchProducerMachiningData = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_MACHINING_DATA,
    payload: !payload.alreadyFetchedMachining
  })
  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/machining`)
    .then((response) => {
      dispatch({
        type: PRODUCER_PARAMS_SET_MACHINING_DATA,
        payload: response.data
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MACHINING_DATA,
        payload: false
      })

      return response.data
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MACHINING_DATA,
        payload: false
      })
    })
}

const fetchProducerMillingMachineProperties = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_MILLING_MACHINE_DATA,
    payload: true
  })
  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/machining/milling/${payload.machineId}/properties`)
    .then(response => {
      dispatch({
        type: PRODUCER_PARAMS_SET_MILLING_MACHINE_DATA,
        payload: response.data
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MILLING_MACHINE_DATA,
        payload: false
      })
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_MILLING_MACHINE_DATA,
        payload: false
      })
    })
}

const fetchProducerLathingMachineProperties = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_LATHING_MACHINE_DATA,
    payload: true
  })
  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/machining/lathing/${payload.machineId}/properties`)
    .then(response => {
      dispatch({
        type: PRODUCER_PARAMS_SET_LATHING_MACHINE_DATA,
        payload: response.data
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_LATHING_MACHINE_DATA,
        payload: false
      })
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_LATHING_MACHINE_DATA,
        payload: false
      })
    })
}

const fetchProducerTubeCuttingData = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_DATA,
    payload: !payload.alreadyFetchedTubeCuttingService
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/tube-cutting`)
    .then(response => {
      const machines = formatTubeCuttingMachines(response.data.machines)

      dispatch({
        type: PRODUCER_PARAMS_SET_TUBE_CUTTING_DATA,
        payload: {
          hasTubeCutting: response.data.hasTubeCutting,
          tubeCuttingMachines: machines
        }
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_DATA,
        payload: false
      })

      return machines
    })
    .catch(error => {
    if(error?.response?.status === 401) {
      dispatch(forceLogoutUser())
    } else {
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_DATA,
        payload: false
      })
      message.show(message.type.error, http.getErrorMessage(error))
    }
    throw error
  })
}

const fetchProducerTubeCuttingMachineProperties = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_MACHINE_DATA,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/tube-cutting/${payload.machineId}/properties`)
    .then(response => {
      let data = response.data.groupNames.map( name => {
        let item = response.data.tables.find(item => item.groupName === name)
        if(item) {
          return item
        }
        else {
          return {
            groupName: name,
            rows: [],
            columns: [],
            allAvailable: {}
          }
        }
      })

      let machine = {...response.data, tables: data}

      dispatch({
        type: PRODUCER_PARAMS_SET_TUBE_CUTTING_MACHINE_DATA,
        payload: machine
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_MACHINE_DATA,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_MACHINE_DATA,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })

}

const fetchProducerTubeMaterialPrices = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_TUBE_MATERIAL_PRICES,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/tube-cutting/materials`)
    .then(response => {

      dispatch({
        type: PRODUCER_PARAMS_SET_TUBE_MATERIAL_PRICES,
        payload: {
          hasTubeCutting: response.data.hasTubeCutting,
          availableMaterials: response.data.rows,
          allAvailable: response.data.allAvailable
        }
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_TUBE_MATERIAL_PRICES,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: PRODUCER_PARAMS_IS_FETCHING_TUBE_MATERIAL_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchProducerStandardTubeMaterialPrices = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_STANDARD_TUBE_MATERIAL_PRICES,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/tube-cutting/standard-tube-materials`)
    .then(response => {

      dispatch({
        type: PRODUCER_PARAMS_SET_STANDARD_TUBE_MATERIAL_PRICES,
        payload: {
          hasTubeCutting: response.data.hasTubeCutting,
          standardTubeMaterialPrices: response.data.table
        }
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_STANDARD_TUBE_MATERIAL_PRICES,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: PRODUCER_PARAMS_IS_FETCHING_STANDARD_TUBE_MATERIAL_PRICES,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchProducerSurfaceCoating = payload => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_IS_FETCHING_SURFACE_COATING,
    payload: true
  })
  http
    .get(`${API_BASE_URL}/producer-params/${payload.producerId}/surface-coating`)
    .then((response) => {
      dispatch({
        type: PRODUCER_PARAMS_SET_SURFACE_COATING_DATA,
        payload: response.data
      })

      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_SURFACE_COATING,
        payload: false
      })
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      dispatch({
        type: PRODUCER_PARAMS_IS_FETCHING_SURFACE_COATING,
        payload: false
      })
    })
}

const setProducerEmptyMillingMachines = () => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_SET_MILLING_MACHINE_DATA,
    payload: []
  })
}

const setProducerEmptyLathingMachines = () => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_SET_LATHING_MACHINE_DATA,
    payload: []
  })
}

const resetProducerParams = () => dispatch => {
  dispatch({
    type: PRODUCER_PARAMS_RESET_PRODUCER_PARAMS,
    payload: null
  })
}

export {
  fetchProducerCuttingMachines, fetchProducerCuttingMachineParameters,
  fetchProducerCuttingMachinePriorities, fetchProducerMaterials, fetchProducerMaterialPrices,
  fetchProducerBendingData, fetchProducerSimpleMachiningData, resetProducerBendsData,
  fetchProducerSingleMaterialBendsData, fetchProducerSurfaceCoating, fetchProducerMaterialCertificates,
  fetchProducerTubeCuttingData, fetchProducerTubeCuttingMachineProperties, resetProducerParams,
  fetchProducerMachiningData, fetchProducerMillingMachineProperties, fetchProducerLathingMachineProperties,
  fetchProducerMachiningMaterials, setProducerEmptyMillingMachines, setProducerEmptyLathingMachines,
  fetchProducerTubeMaterialPrices, fetchProducerStandardTubeMaterialPrices
}