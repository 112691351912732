import {
  CUSTOMER_RELATIONS_SET_INTERNAL_RELATIONS,
  CUSTOMER_RELATIONS_INTERNAL_PAGINATION,
  CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS,
  CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
  CUSTOMER_FETCHING_UNAUTHORIZED_CUSTOMERS,
  CUSTOMER_SET_UNAUTHORIZED_CUSTOMERS,
} from '../actions/actionTypes'
import initialState from './initialState'

const customerRelationsReducer = (state = initialState.customerRelations, action) => {
  switch (action.type) {
    case CUSTOMER_RELATIONS_SET_INTERNAL_RELATIONS:
      return {
        ...state,
        internalCustomerRelations: action.payload,
      }

    case CUSTOMER_RELATIONS_INTERNAL_PAGINATION:
      return {
        ...state,
        internalCustomerRelationsPagination: action.payload,
      }

    case CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS:
      return {
        ...state,
        fetchingInternalCustomerRelations: action.payload,
      }

    case CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER:
      return {
        ...state,
        updatingInternalCustomer: action.payload,
      }

    case CUSTOMER_FETCHING_UNAUTHORIZED_CUSTOMERS:
      return {
        ...state,
        fetchingUnauthorizedCustomers: action.payload
      }

    case CUSTOMER_SET_UNAUTHORIZED_CUSTOMERS:
      return {
        ...state,
        unauthorizedCustomers: action.payload
      }

    default:
      return state
  }
}

export default customerRelationsReducer