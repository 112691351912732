import React from "react";
import {Button, Card, Divider, Form, Input, Modal} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";

export const BulkOrderArchiveModal = (props) => {

  const {
    isArchiveModalVisible, setIsArchiveModalVisible,
    isArchivingCalculation, archiveOrderForm,
    handleArchiveOrder, toProcess, allItemsCount,
    totalPrice, existsZeroPrice, items
  } = props;

  return (
    <Modal
      className={"archiveModal"}
      visible={isArchiveModalVisible}
      title={"Store Calculation"}
      width={600}
      closable={true}
      destroyOnClose={true}
      bodyStyle={{
        paddingTop: 8,
        paddingBottom: 12
      }}
      onCancel={() => {
        archiveOrderForm.setFieldsValue({
          customName: "",
          orderNote: ""
        })
        setIsArchiveModalVisible(false)
      }}
      footer={
        <div>
          <Button disabled={isArchivingCalculation} onClick={() => setIsArchiveModalVisible(false)}>
            <span style={{fontWeight: 500}}>Cancel</span>
          </Button>
          <Button key="submit"
                  htmlType="submit"
                  form={"archiveForm"}
                  type={"primary"}
                  loading={isArchivingCalculation}
          >
            <span style={{fontWeight: 500}}>Store</span>
          </Button>
        </div>
      }
    >
      {items &&
      <Form
        form={archiveOrderForm}
        name={"archiveForm"}
        onFinish={handleArchiveOrder}
      >
        <div style={{ marginTop: 20}}>
          <Card
            className={"summaryContainerCard"}
            style={{ marginTop: toProcess > 0 ? 12 : 0 }}
            bodyStyle={{padding: "5px 0", height: "100%"}}
          >
            <div className={"summaryContainer"}>
              <div className={"text14-500 orderSummary"} style={{fontSize: 18}}>
                Calculation Summary:
              </div>
              <div className={"summaryContent"} style={{ width: 380 }}>
                <div style={{fontSize: 13, fontWeight: 600, display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                  <span style={{width: 140, display: "inline-block"}}>Number of parts:</span>
                  <span style={{fontWeight: 600, whiteSpace: "nowrap"}}>
                          {allItemsCount}
                        </span>
                </div>
                <Divider className={"lightDivider"} style={{ margin: "2px 0 2px 0" }}/>
                <div style={{fontSize: 13, fontWeight: 600, display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                  <span style={{width: 140, display: "inline-block"}}>Total Price:</span>
                  <span style={{fontWeight: 600, whiteSpace: "nowrap"}}>
                          {(totalPrice || 0).toFixed(2)} €
                        </span>
                </div>
              </div>
            </div>
          </Card>

          {existsZeroPrice &&
          <div className={"zeroPriceWarning"}>
            <div>
              <ExclamationCircleFilled
                style={{color: "#ffc107", fontSize: 15, marginLeft: 5}}/>
            </div>
            <div className={"text12-600"} style={{marginLeft: 10, lineHeight: 1.4, fontSize: 11}}>
              The cost of one or more parts is set to 0.00€. Make sure this is intentional.
            </div>
          </div>
          }
          <Divider className={"lightDivider"} style={{ margin: "10px 0 0 0" }}/>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginTop: 11, width: "100%", marginBottom: 10}}>
            <div className={"text12-500-12 width80"}
                 style={{ whiteSpace: "nowrap", marginRight: 6, height: 33, fontWeight: 500, display: "flex", alignItems: "center", justifyContent: "flex-end"}}
            >
              Order Name:
            </div>
            <Form.Item
              className={"archiveOrderNameInput"}
              style={{ marginBottom: 0 }}
              name={"customName"}
              validateTrigger={"onSubmit"}
              rules={[
                {required: true, message: 'Name required!'},
              ]}
            >
              <Input
                className={"text12-500 orderNameFieldModal muiInput"}
                size={'large'}
                placeholder={"Enter a name for this calculation"}
              />
            </Form.Item>
          </div>

          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
            <div className={"text12-500-12 width80"}
                 align={"right"}
                 style={{ whiteSpace: "nowrap", marginRight: 6, height: 33, fontWeight: 500 }}
            >
              Note:
            </div>
            <Form.Item
              className={"textAreaInput"}
              name={"orderNote"}
            >
              <Input.TextArea
                rows={3}
                className={"textAreaInputText noteFieldModal muiInput"}
                placeholder={"Optional Note"}
                maxLength={500}
                showCount
              />
            </Form.Item>
          </div>
        </div>
      </Form>
      }
    </Modal>
  )
}