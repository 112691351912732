import React, {useCallback, useEffect, useState} from "react";
import "./style.less";
import {Card, Col, Divider, Form, Row, Switch, Checkbox, InputNumber, Typography, Spin} from "antd";
import {
  fetchMachiningData,
  setMachiningService,
  setMachiningMilling,
  setMachiningLathing,
  setMillingData,
  setLathingData,
  fetchMillingMachineData,
  setEmptyMillingMachines,
  fetchLathingMachineData,
  setEmptyLathingMachines,
  createMillingMachine,
  deleteMillingMachine,
  createLathingMachine,
  deleteLathingMachine,
  setEmptySelectedMillingMachineData,
  setEmptySelectedLathingMachineData,
  updateMillingMachine,
  updateLathingMachine,
  updateGradeMillingSpeed,
  updateGradeLathingSpeed,
  setMillingMachineTolerance,
  setLathingMachineTolerance,
  setMillingMachineRoughness,
  setLathingMachineRoughness
} from "../../../../../actions/machiningActions";
import {useDispatch, useSelector} from "react-redux";
import {debounce} from "lodash";
import {
  AddMachineModal,
  EditMachineModal,
  MachineSelect,
  MachineTolerancesModal,
  RemoveMachineModal
} from "../../../MachineSelect";
import {MachiningMaterialList} from "../../MachiningMaterialList";
import {useWindowSize} from "../../../../../utils/utility";
import {LoadingOutlined} from "@ant-design/icons";
import {EmptyTable} from "../../../../EmptyTable";
import {ToleranceSelect} from "../../../ToleranceSelect";
import {TableHeader} from "../../MaterialTables/TableHeader";

const MachiningTableContainer = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
        <TableHeader columns={[
          {title: "Name", width: 130},
          {title: <span>{props.type === "milling" ? "Milling" : "Lathing"} Speed (cm<sup>3</sup>/min)</span>, width: 173},
        ]}/>
        <Card
          className={"tableCard"}
          style={{width: 303}}
          bodyStyle={{height: "calc(100vh - 345px)", maxWidth: 508}}
        >
          {props.children}
        </Card>
      </div>
      <Divider type="vertical" style={{height: 'calc(100vh - 300px)', marginInline: 15, borderColor: "#1e1e1e"}}/>
    </div>
  )
}

export const MachiningTab = (props) => {
  const {
    selectedTab,
    isFetchingMachiningData,
    isGelso,
    changeMillingParams,
    changeLathingParams,
    changeGradeMillingSpeed,
    changeGradeLathingSpeed,
    changeMachineMaxTolerance,
    changeMachineToleranceMarkup,
    changeMachineMaxRoughness,
    changeMachineRoughnessMarkup,
    socketConnected
  } = props;

  const machiningData = useSelector(state => state.machining.machiningData)
  const alreadyFetchedMachining = useSelector(state => state.machining.alreadyFetchedMachining)

  const [disableSwitching, setDisableSwitching] = useState(false);

  {/*-------------------Milling-------------------*/}
  const selectedMillingMachine = useSelector(state => state.machining.selectedMillingMachine);
  const [millingMachines, setMillingMachines] = useState([]);
  const isCreatingMillingMachine = useSelector(state => state.machining.isCreatingMillingMachine);
  const [addMillingMachineModalVisible, setAddMillingMachineModalVisible] = useState(false);

  const [removeMillingMachine, setRemoveMillingMachine] = useState(null);
  const [removeMillingMachineModalVisible, setRemoveMillingMachineModalVisible] = useState(false);
  const [millingMachineTolerancesModalVisible, setMillingMachineTolerancesModalVisible] = useState(false);

  const [editMillingMachineModalVisible, setEditMillingMachineModalVisible] = useState(false);
  const [editMillingMachine, setEditMillingMachine] = useState(null);
  const isFetchingSelectedMillingMachine = useSelector(state => state.machining.isFetchingSelectedMillingMachine);
  const isUpdatingMillingMachine = useSelector(state => state.machining.isUpdatingMillingMachine);
  const [shouldUpdateMillingTable, setShouldUpdateMillingTable] = useState(false);
  const [noMillingMachines, setNoMillingMachines] = useState(Object.keys(selectedMillingMachine).length === 0)

  {/*-------------------Lathing-------------------*/}
  const selectedLathingMachine = useSelector(state => state.machining.selectedLathingMachine);
  const [lathingMachines, setLathingMachines] = useState([]);
  const isCreatingLathingMachine = useSelector(state => state.machining.isCreatingLathingMachine);
  const [addLathingMachineModalVisible, setAddLathingMachineModalVisible] = useState(false);

  const [removeLathingMachine, setRemoveLathingMachine] = useState(null);
  const [removeLathingMachineModalVisible, setRemoveLathingMachineModalVisible] = useState(false);
  const [lathingMachineTolerancesModalVisible, setLathingMachineTolerancesModalVisible] = useState(false);

  const [editLathingMachineModalVisible, setEditLathingMachineModalVisible] = useState(false);
  const [editLathingMachine, setEditLathingMachine] = useState(null);
  const isFetchingSelectedLathingMachine = useSelector(state => state.machining.isFetchingSelectedLathingMachine);
  const isUpdatingLathingMachine = useSelector(state => state.machining.isUpdatingLathingMachine);
  const [shouldUpdateLathingTable, setShouldUpdateLathingTable] = useState(false);
  const [noLathingMachines, setNoLathingMachines] = useState(Object.keys(selectedLathingMachine).length === 0)

  const dispatch = useDispatch()
  const windowSize = useWindowSize();
  const [shouldChangeOrder, setShouldChangeOrder] = useState((windowSize.width < 1500 && windowSize.width >= 1235) || windowSize.width < 810);
  const [shouldChangeOrder2, setShouldChangeOrder2] = useState(windowSize.width < 1235 && windowSize.width > 991);

  const millingForm = React.createRef()
  const lathingForm = React.createRef()

  useEffect(() => {
    setShouldChangeOrder((windowSize.width < 1500 && windowSize.width >= 1235) || windowSize.width < 810)
    setShouldChangeOrder2(windowSize.width < 1235 && windowSize.width > 991)
  }, [windowSize.width])

  useEffect(()=>{
    if(selectedTab === "4") {
      fetchData()
    }
  }, [selectedTab])

  const fetchData = () => {
    dispatch(fetchMachiningData({alreadyFetchedMachining}))
      .then((machiningData) => {
        if (machiningData) {
          const millingMachines = machiningData.milling.machines
          setMillingMachines([{
            id: "1",
            name: "Milling Machines",
            machines: millingMachines
          }]);

          if (millingMachines?.length > 0) {
            const millingMachineId = millingMachines[0].id
            dispatch(fetchMillingMachineData({machineId: millingMachineId})).then(() => {
              setShouldUpdateMillingTable(true)
            })
          } else {
            dispatch(setEmptyMillingMachines())
          }

          const lathingMachines = machiningData.lathing.machines
          setLathingMachines([{
            id: "1",
            name: "Lathing Machines",
            machines: lathingMachines
          }]);

          if (lathingMachines?.length > 0) {
            const lathingMachineId = lathingMachines[0].id
            dispatch(fetchLathingMachineData({machineId: lathingMachineId})).then(() => {
              setShouldUpdateLathingTable(true)
            })
          } else {
            dispatch(setEmptyLathingMachines())
          }
        }
      })
  }

  const triggerSlideToggle = (value) => {
    setDisableSwitching(true);
    dispatch(setMachiningService({value})).then(()=>{
      setDisableSwitching(false);
      if(value) {
        fetchData()
      }
    })
  }

  const setMilling = (value) => {
    dispatch(setMachiningMilling({value}))
  }

  const setLathing = (value) => {
    dispatch(setMachiningLathing({value}))
  }

  /*----------- Milling -----------*/

  const changeMillingData = (data) => {
    const machineData = {
      ...data,
      supervisedMachine: isGelso ? true : data.supervisedMachine,
    }
    dispatch(setMillingData(machineData))
    changeMillingParams(machineData)
  }

  const debouncedMilling = useCallback(
    debounce(data => changeMillingData(data), 800),
    []
  );

  const changeMilling = (machineId) => {
    const { maxTolerance, maxRoughness, ...data } = millingForm.current.getFieldsValue();
    debouncedMilling({
      ...data,
      machineId: machineId
    })
  }

  const setSupervisedMilling  = (machineId) => {
    const { maxTolerance, maxRoughness, ...data } = millingForm.current.getFieldsValue();
    changeMillingData({
      ...data,
      machineId: machineId
    })
  }

  const changeSelectedMillingMachine = (value) => {
    dispatch(fetchMillingMachineData({machineId: value})).then(() => {
      setShouldUpdateMillingTable(true)
    })
  }

  const handleEditMillingMachine = (e, machine) => {
    e.stopPropagation()
    setEditMillingMachine(machine)
    setEditMillingMachineModalVisible(true)
  }
  const handleEditMillingMachineClose = () => {
    setEditMillingMachine(null)
    setEditMillingMachineModalVisible(false)
  }

  const applyEditMillingMachine = (data) => {
    const payloadData = {
      machineId: editMillingMachine.id,
      data: {
        name: data.name,
        dimensions: {
          "x": data.x,
          "y": data.y,
          "z": data.z
        }
      }
    }
    dispatch(updateMillingMachine(payloadData)).then(() => {
      setEditMillingMachineModalVisible(false);
    })
  }

  const handleRemoveMillingMachine = (e, machine) => {
    e.stopPropagation()
    setRemoveMillingMachine(machine)
    setRemoveMillingMachineModalVisible(true)
  }

  const handleRemoveMillingMachineConfirm = () => {
    dispatch(deleteMillingMachine({machineId: removeMillingMachine.id})).then((newMachines) => {
      if (selectedMillingMachine.id === removeMillingMachine.id) {
        if (newMachines.length === 0) {
          dispatch(setEmptySelectedMillingMachineData()).then(() => {
            setShouldUpdateMillingTable(true)
          })
        } else {
          dispatch(fetchMillingMachineData({machineId: newMachines[0].id})).then(() => {
            setShouldUpdateMillingTable(true)
          })
        }
      }
    })
    setRemoveMillingMachine(null);
    setRemoveMillingMachineModalVisible(false)
  }

  const handleRemoveMillingMachineClose = () => {
    setRemoveMillingMachine(null)
    setRemoveMillingMachineModalVisible(false)
  }

  const handleAddMillingMachineOpen = () => {
    setAddMillingMachineModalVisible(true);
  }

  const handleAddMillingMachineClose = () => {
    setAddMillingMachineModalVisible(false);
  }

  const handleAddMillingMachine = (values) => {
    dispatch(createMillingMachine({
      "name": values.name,
      "dimensions": {
        "x": values.x,
        "y": values.y,
        "z": values.z
      }
    })).then((machines) => {
      if (machines?.length > 0) {
        const machineId = machines[machines.length - 1].id // Najnovija masina je uvek poslednja u nizu
        dispatch(fetchMillingMachineData({machineId: machineId})).then(() => {
          setShouldUpdateMillingTable(true);
        })
      }
      handleAddMillingMachineClose();
    })
  }

  const handleMillingMachineTolerancesOpen = () => {
    setMillingMachineTolerancesModalVisible(true);
  }

  const handleMillingMachineTolerancesClose = () => {
    setMillingMachineTolerancesModalVisible(false);
  }

  useEffect(() => {
    const machines = machiningData.milling.machines;
    if (machines?.length > 0) {
      setMillingMachines([{
        id: "1",
        name: "Milling Machines",
        machines: machines
      }]);
    } else {
      setMillingMachines([]);
    }
  }, [machiningData.milling.machines])

  const changeMillingSpeed = (params) => {
    changeGradeMillingSpeed({machineId: selectedMillingMachine.id, materialId: params.materialId, speed: params.value})
    dispatch(updateGradeMillingSpeed(params))
    return Promise.resolve(10)
  }

  const handleChangeMillingTolerance = (data) => {
    dispatch(setMillingMachineTolerance(data))
    changeMachineToleranceMarkup(data)
  }

  const handleChangeMillingRoughness = (data) => {
    dispatch(setMillingMachineRoughness(data))
    changeMachineRoughnessMarkup(data)
  }

  /*----------- Lathing -----------*/
  const changeLathingData = (data) => {
    const machineData = {
      ...data,
      supervisedMachine: isGelso ? true : data.supervisedMachine,
    }
    dispatch(setLathingData(machineData))
    changeLathingParams(machineData)
  }

  const debouncedLathing = useCallback(
    debounce(data => changeLathingData(data), 800),
    []
  );

  const changeLathing = (machineId) => {
    const { maxTolerance, maxRoughness, ...data } = lathingForm.current.getFieldsValue();
    debouncedLathing({
      ...data,
      machineId: machineId
    })
  }

  const setSupervisedLathing  = (machineId) => {
    const { maxTolerance, maxRoughness, ...data } = lathingForm.current.getFieldsValue();
    changeLathingData({
      ...data,
      machineId: machineId
    })
  }

  const changeSelectedLathingMachine = (id) => {
    dispatch(fetchLathingMachineData({machineId: id})).then(() => {
      setShouldUpdateLathingTable(true)
    })
  }

  const handleEditLathingMachine = (e, machine) => {
    e.stopPropagation()
    setEditLathingMachine(machine)
    setEditLathingMachineModalVisible(true)
  }

  const handleEditLathingMachineClose = () => {
    setEditLathingMachine(null)
    setEditLathingMachineModalVisible(false)
  }

  const applyEditLathingMachine = (data) => {
    const payloadData = {
      machineId: editLathingMachine.id,
      data: {
        name: data.name,
        dimensions: {
          "r": data.r,
          "h": data.h,
        }
      }
    }
    dispatch(updateLathingMachine(payloadData)).then(() => {
      setEditLathingMachineModalVisible(false);
    })
  }

  const handleRemoveLathingMachine = (e, machine) => {
    e.stopPropagation()
    setRemoveLathingMachine(machine)
    setRemoveLathingMachineModalVisible(true)
  }

  const handleRemoveLathingMachineConfirm = () => {
    dispatch(deleteLathingMachine({machineId: removeLathingMachine.id})).then((newMachines) => {
      if (selectedLathingMachine.id === removeLathingMachine.id) {
        if (newMachines.length === 0) {
          dispatch(setEmptySelectedLathingMachineData()).then(() => {
            setShouldUpdateLathingTable(true)
          })
        } else {
          dispatch(fetchLathingMachineData({machineId: newMachines[0].id})).then(() => {
            setShouldUpdateLathingTable(true)
          })
        }
      }
    })
    setRemoveLathingMachine(null);
    setRemoveLathingMachineModalVisible(false)
  }

  const handleRemoveLathingMachineClose = () => {
    setRemoveLathingMachine(null);
    setRemoveLathingMachineModalVisible(false)
  }

  const handleAddLathingMachineOpen = () => {
    setAddLathingMachineModalVisible(true)
  }

  const handleAddLathingMachineClose = () => {
    setAddLathingMachineModalVisible(false)
  }

  const handleAddLathingMachine = (values) => {
    dispatch(createLathingMachine({
      "name": values.name,
      "dimensions": {
        "r": values.r,
        "h": values.h,
      }
    })).then((machines) => {
      if (machines?.length > 0) {
        const machineId = machines[machines.length - 1].id // Najnovija masina je uvek poslednja u nizu
        dispatch(fetchLathingMachineData({machineId: machineId})).then(() => {
          setShouldUpdateLathingTable(true);
        })
      }
      handleAddLathingMachineClose();
    })
  }

  const handleLathingMachineTolerancesOpen = () => {
    setLathingMachineTolerancesModalVisible(true)
  }

  const handleLathingMachineTolerancesClose = () => {
    setLathingMachineTolerancesModalVisible(false)
  }

  const changeLathingSpeed = (params) => {
    changeGradeLathingSpeed({machineId: selectedLathingMachine.id, materialId: params.materialId, speed: params.value})
    dispatch(updateGradeLathingSpeed(params))
    return Promise.resolve(10)
  }

  const handleChangeLathingTolerance = (data) => {
    dispatch(setLathingMachineTolerance(data))
    changeMachineToleranceMarkup(data)
  }

  const handleChangeLathingRoughness = (data) => {
    dispatch(setLathingMachineRoughness(data))
    changeMachineRoughnessMarkup(data)
  }



  useEffect(() => {
    const machines = machiningData.lathing.machines;
    if (machines?.length > 0) {
      setLathingMachines([{
        id: "1",
        name: "Lathing Machines",
        machines: machines
      }]);
    } else {
      setLathingMachines([]);
    }
  }, [machiningData.lathing.machines])

  useEffect(() => {
    setNoMillingMachines(Object.keys(selectedMillingMachine).length === 0)
    millingForm.current?.setFieldsValue({
      pricePerHour: selectedMillingMachine.pricePerHour,
      engineerPricePerHour: selectedMillingMachine.engineerPricePerHour,
      averagePreparationTime: selectedMillingMachine.averagePreparationTime,
      supervisedMachine: selectedMillingMachine.supervisedMachine,
      supervisionPricePerHour: selectedMillingMachine.supervisionPricePerHour,
      maxTolerance: selectedMillingMachine.maxTolerance,
      maxRoughness: selectedMillingMachine.maxRoughness
    })
  }, [selectedMillingMachine])

  useEffect(() => {
    setNoLathingMachines(Object.keys(selectedLathingMachine).length === 0)
    lathingForm.current?.setFieldsValue({
      pricePerHour: selectedLathingMachine.pricePerHour,
      engineerPricePerHour: selectedLathingMachine.engineerPricePerHour,
      averagePreparationTime: selectedLathingMachine.averagePreparationTime,
      supervisedMachine: selectedLathingMachine.supervisedMachine,
      supervisionPricePerHour: selectedLathingMachine.supervisionPricePerHour,
      maxTolerance: selectedLathingMachine.maxTolerance,
      maxRoughness: selectedLathingMachine.maxRoughness
    })
  }, [selectedLathingMachine])

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"}>
        <div style={{height: 'calc(100vh - 120px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          <Row gutter={16}>
            <Col xl={14} span={24}>
              <div className={"switchContainer"}>
                {isGelso ?
                    <span>Fallback Machining parameters:</span>
                  :
                  (
                    <div>
                      <span style={{marginRight: 30, marginBottom: 6}}>Do you offer Machining service?</span>
                      <span>No</span>
                      <Switch
                        checked={machiningData.hasMachining}
                        onChange={triggerSlideToggle}
                        style={{marginLeft: 6, marginRight: 6}}
                        disabled={disableSwitching}
                      />
                      <span>Yes</span>
                    </div>
                  )
                }
              </div>
            </Col>
          </Row>
          <Divider style={{width: "75%", minWidth: "0%", margin: "14px 0 8px 0"}}/>
          {machiningData.hasMachining &&
            <div>
              <div className={"mainForm"}>
                <Row gutter={16}>
                  {/*------------------------------------------------------------------------------------------------*/}
                  <Col lg={12} span={24}>
                    <Spin
                      spinning={isFetchingMachiningData || isCreatingMillingMachine || isUpdatingMillingMachine || isFetchingSelectedMillingMachine || !socketConnected}
                      indicator={<LoadingOutlined style={{fontSize: 72, transform: "translate(-15%, 50%)"}} spin/>}
                    >
                    <div>
                      <Form>
                        <div style={{display: "inline-block"}}>
                          {isGelso ?
                            <span className={"mainFromLabel"} style={{color: "white"}}>Milling:</span>
                            :
                            <Form.Item label={<span className={"mainFromLabel"}>Milling</span>}>
                              <Checkbox
                                checked={machiningData.milling.hasMilling}
                                onChange={(e) => {
                                  setMilling(e.target.checked);
                                }}
                                disabled={false}
                              />
                            </Form.Item>
                          }
                        </div>
                        <Divider
                          className={"lightDivider"}
                          style={{width: "75%", minWidth: "0%", margin: "8px 0 5px 10px", display: "inline-block"}}
                        />
                      </Form>

                      {machiningData.milling.hasMilling &&
                        <div>
                          <MachineSelect
                            type={"milling"}
                            style={{marginTop: 8}}
                            selectedMachine={selectedMillingMachine?.id}
                            changeSelectedMachine={changeSelectedMillingMachine}
                            machines={millingMachines}
                            isFetchingMachineTypes={false}
                            handleEditMachine={handleEditMillingMachine}
                            handleRemoveMachine={handleRemoveMillingMachine}
                            handleAddMachineOpen={handleAddMillingMachineOpen}
                            allowPriorityChange={false}
                            allowAddMachine={true}
                            showButtons={true}
                          />
                          <Divider style={{width: "90%", minWidth: "0%", margin: "0 0 16px 0"}}/>
                          <Card
                            className={"inputInfoText"}
                            style={{margin: 0, minHeight: 425}}
                            bodyStyle={{padding: "8px 16px", height: "100%"}}
                          >
                            {!noMillingMachines ?
                              <div style={{ display: "flex", flexDirection: shouldChangeOrder2 ? "column-reverse" : "row" }}>
                                <div style={{width: "334px"}}>
                                  <MachiningTableContainer type={"milling"}>
                                    <MachiningMaterialList
                                      rows={selectedMillingMachine?.rows || []}
                                      allAvailable={machiningData?.allAvailable}
                                      onChange={changeMillingSpeed}
                                      updateTable={shouldUpdateMillingTable}
                                      setUpdateTable={setShouldUpdateMillingTable}
                                      isEditable={true}
                                    />
                                  </MachiningTableContainer>
                                </div>

                                <div style={{width: "100%", minWidth: 0}}>
                                  <Form ref={millingForm}>
                                    {/*<Divider style={{*/}
                                    {/*  width: "75%",*/}
                                    {/*  minWidth: "0%",*/}
                                    {/*  margin: `9px 0 ${shouldChangeOrder ? 0 : "10px"} 0`,*/}
                                    {/*  borderColor: "rgb(30, 30, 30)"*/}
                                    {/*}}/>*/}
                                    <div
                                      className={machiningData.milling.hasMilling ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        label={"Milling price per hour"}
                                        name={"pricePerHour"}
                                        className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                        initialValue={selectedMillingMachine.pricePerHour}
                                      >
                                        <InputNumber
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => changeMilling(selectedMillingMachine.id)}
                                          disabled={!machiningData.milling.hasMilling || noMillingMachines}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 36
                                        } : {marginLeft: 12}}
                                      >
                                        €/h
                                      </Typography.Text>
                                    </div>
                                    <div
                                      className={machiningData.milling.hasMilling ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        label={"Preparation price"}
                                        name={"engineerPricePerHour"}
                                        className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                        initialValue={selectedMillingMachine.engineerPricePerHour}
                                      >
                                        <InputNumber
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => changeMilling(selectedMillingMachine.id)}
                                          disabled={!machiningData.milling.hasMilling || noMillingMachines}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 16
                                        } : {marginLeft: 12}}
                                      >
                                        €/h
                                      </Typography.Text>
                                    </div>
                                    <div
                                      className={machiningData.milling.hasMilling ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      <Form.Item
                                        label={"Average preparation time"}
                                        name={"averagePreparationTime"}
                                        className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                        initialValue={selectedMillingMachine.averagePreparationTime}
                                      >
                                        <InputNumber
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => changeMilling(selectedMillingMachine.id)}
                                          disabled={!machiningData.milling.hasMilling || noMillingMachines}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 62
                                        } : {marginLeft: 12}}
                                      >
                                        min
                                      </Typography.Text>
                                    </div>
                                    <div
                                      className={(machiningData.milling.hasMilling && selectedMillingMachine.supervisedMachine) ? "" : "disabledField"}
                                      style={{display: "flex", alignItems: "center"}}
                                    >
                                      {!isGelso ?
                                        <Form.Item
                                          name={"supervisedMachine"}
                                          valuePropName={"checked"}
                                          initialValue={selectedMillingMachine.supervisedMachine}
                                        >
                                          <Checkbox
                                            style={{marginBottom: shouldChangeOrder ? 32 : 2, marginRight: 10}}
                                            onChange={() => setSupervisedMilling(selectedMillingMachine.id)}
                                            disabled={!machiningData.milling.hasMilling || noMillingMachines}
                                          />
                                        </Form.Item>
                                        :
                                        <Form.Item/>
                                      }
                                      <Form.Item
                                        label={"Additional operator cost"}
                                        name={"supervisionPricePerHour"}
                                        className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                        initialValue={selectedMillingMachine.supervisionPricePerHour}
                                      >
                                        <InputNumber
                                          style={{minWidth: 30}}
                                          min={0}
                                          step={1}
                                          formatter={value => value ? value : 0}
                                          parser={value => value ? value : 0}
                                          onChange={() => changeMilling(selectedMillingMachine.id)}
                                          disabled={!(machiningData.milling.hasMilling && selectedMillingMachine?.supervisedMachine) || noMillingMachines}
                                        />
                                      </Form.Item>
                                      <Typography.Text
                                        style={shouldChangeOrder ? {
                                          position: "relative",
                                          top: 16,
                                          right: 54
                                        } : {marginLeft: 12}}
                                      >
                                        €/h
                                      </Typography.Text>
                                    </div>
                                    <Divider style={{
                                      width: "100%",
                                      minWidth: "0%",
                                      margin: `9px 0 ${shouldChangeOrder ? 0 : "10px"} 0`,
                                      borderColor: "rgb(30, 30, 30)"
                                    }}/>
                                    <ToleranceSelect
                                      type={"maxTolerance"}
                                      machineType={"milling"}
                                      isDisabled={!machiningData.milling.hasMilling}
                                      initialValue={selectedMillingMachine.maxTolerance}
                                      machineId={selectedMillingMachine.id}
                                      handleChangeTolerance={changeMachineMaxTolerance}
                                      shouldChangeOrder={shouldChangeOrder}
                                      showButton={false}
                                      isLoading={isFetchingSelectedMillingMachine}
                                      handleMachineTolerancesOpen={handleMillingMachineTolerancesOpen}
                                    />
                                    <ToleranceSelect
                                      type={"maxRoughness"}
                                      machineType={"milling"}
                                      isDisabled={!machiningData.milling.hasMilling}
                                      initialValue={selectedMillingMachine.maxRoughness}
                                      machineId={selectedMillingMachine.id}
                                      handleChangeTolerance={changeMachineMaxRoughness}
                                      shouldChangeOrder={shouldChangeOrder}
                                      showButton={true}
                                      isLoading={isFetchingSelectedMillingMachine}
                                      handleMachineTolerancesOpen={handleMillingMachineTolerancesOpen}
                                    />
                                    {shouldChangeOrder2 &&
                                      <Divider style={{
                                        width: "75%",
                                        minWidth: "0%",
                                        margin: `9px 0 ${shouldChangeOrder ? 0 : "10px"} 0`,
                                        borderColor: "rgb(30, 30, 30)"
                                      }}/>
                                    }
                                  </Form>
                                </div>
                              </div>
                              :
                              <EmptyTable
                                text={"No Milling Machines Available"}
                                style={{
                                  position: "relative", top: "25%", height: "calc(100vh - 300px)",
                                  display: "flex", justifyContent: "center", alignItems: "center"
                                }}
                              />
                            }
                          </Card>
                        </div>
                      }
                    </div>
                  </Spin>
                  </Col>

                  <Col lg={12} span={24}>
                    <Spin
                      spinning={isFetchingMachiningData || isCreatingLathingMachine || isUpdatingLathingMachine || isFetchingSelectedLathingMachine || !socketConnected}
                      indicator={<LoadingOutlined style={{ fontSize: 72, transform: "translate(-15%, 50%)" }} spin />}
                    >
                      <div>
                        <Form>
                          <div style={{ display: "inline-block"}}>
                            {isGelso ?
                              <span className={"mainFromLabel"} style={{color: "white"}}>Lathing:</span>
                              :
                              <Form.Item label={<span className={"mainFromLabel"}>Lathing</span>}>
                                <Checkbox
                                  checked={machiningData.lathing.hasLathing}
                                  onChange={(e) => {
                                    setLathing(e.target.checked);
                                  }}
                                  disabled={false}
                                />
                              </Form.Item>
                            }
                          </div>
                          <Divider
                            className={"lightDivider"}
                            style={{width: "75%", minWidth: "0%", margin: "8px 0 5px 10px", display: "inline-block"}}
                          />
                        </Form>

                        {machiningData.lathing.hasLathing &&
                          <div>
                            <MachineSelect
                              type={"lathing"}
                              style={{ marginTop: 8 }}
                              selectedMachine={selectedLathingMachine?.id}
                              changeSelectedMachine={changeSelectedLathingMachine}
                              machines={lathingMachines}
                              isFetchingMachineTypes={false}
                              handleEditMachine={handleEditLathingMachine}
                              handleRemoveMachine={handleRemoveLathingMachine}
                              handleAddMachineOpen={handleAddLathingMachineOpen}
                              allowPriorityChange={false}
                              allowAddMachine={true}
                              showButtons={true}
                            />
                            <Divider style={{width: "90%", minWidth: "0%", margin: "0 0 16px 0"}}/>

                            <Card
                              className={"inputInfoText"}
                              style={{margin: 0, minHeight: 425}}
                              bodyStyle={{padding: "8px 16px", height: "100%"}}
                            >
                              {!noLathingMachines ?
                                <div style={{display: "flex", flexDirection: shouldChangeOrder2 ? "column-reverse" : "row"}}>
                                  <div style={{width: "334px"}}>
                                    <MachiningTableContainer type={"lathing"}>
                                      <MachiningMaterialList
                                        rows={selectedLathingMachine?.rows || []}
                                        allAvailable={machiningData?.allAvailable}
                                        onChange={changeLathingSpeed}
                                        updateTable={shouldUpdateLathingTable}
                                        setUpdateTable={setShouldUpdateLathingTable}
                                        leftWidth={130}
                                        rightWidth={150}
                                        isEditable={true}
                                      />
                                    </MachiningTableContainer>
                                  </div>

                                  <div style={{width: "100%", minWidth: 0}}>
                                    <Form ref={lathingForm}>
                                      <div
                                        className={machiningData.lathing.hasLathing ? "" : "disabledField"}
                                        style={{display: "flex", alignItems: "center"}}
                                      >
                                        <Form.Item
                                          label={"Lathing price per hour"}
                                          name={"pricePerHour"}
                                          className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                          initialValue={selectedLathingMachine.pricePerHour}
                                        >
                                          <InputNumber
                                            style={{minWidth: 30}}
                                            min={0}
                                            step={1}
                                            formatter={value => value ? value : 0}
                                            parser={value => value ? value : 0}
                                            onChange={() => changeLathing(selectedLathingMachine.id)}
                                            disabled={!machiningData.lathing.hasLathing || noLathingMachines}
                                          />
                                        </Form.Item>
                                        <Typography.Text
                                          style={shouldChangeOrder ? {
                                            position: "relative",
                                            top: 16,
                                            right: 36
                                          } : {marginLeft: 12}}
                                        >
                                          €/h
                                        </Typography.Text>
                                      </div>
                                      <div
                                        className={machiningData.lathing.hasLathing ? "" : "disabledField"}
                                        style={{display: "flex", alignItems: "center"}}
                                      >
                                        <Form.Item
                                          label={"Preparation price"}
                                          name={"engineerPricePerHour"}
                                          className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                          initialValue={selectedLathingMachine.engineerPricePerHour}
                                        >
                                          <InputNumber
                                            style={{minWidth: 30}}
                                            min={0}
                                            step={1}
                                            formatter={value => value ? value : 0}
                                            parser={value => value ? value : 0}
                                            onChange={() => changeLathing(selectedLathingMachine.id)}
                                            disabled={!machiningData.lathing.hasLathing || noLathingMachines}
                                          />
                                        </Form.Item>
                                        <Typography.Text
                                          style={shouldChangeOrder ? {
                                            position: "relative",
                                            top: 16,
                                            right: 16
                                          } : {marginLeft: 12}}
                                        >
                                          €/h
                                        </Typography.Text>
                                      </div>
                                      <div
                                        className={machiningData.lathing.hasLathing ? "" : "disabledField"}
                                        style={{display: "flex", alignItems: "center"}}
                                      >
                                        <Form.Item
                                          label={"Average preparation time"}
                                          name={"averagePreparationTime"}
                                          className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                          initialValue={selectedLathingMachine.averagePreparationTime}
                                        >
                                          <InputNumber
                                            style={{minWidth: 30}}
                                            min={0}
                                            step={1}
                                            formatter={value => value ? value : 0}
                                            parser={value => value ? value : 0}
                                            onChange={() => changeLathing(selectedLathingMachine.id)}
                                            disabled={!machiningData.lathing.hasLathing || noLathingMachines}
                                          />
                                        </Form.Item>
                                        <Typography.Text
                                          style={shouldChangeOrder ? {
                                            position: "relative",
                                            top: 16,
                                            right: 62
                                          } : {marginLeft: 12}}
                                        >
                                          min
                                        </Typography.Text>
                                      </div>
                                      <div
                                        className={(machiningData.lathing.hasLathing && selectedLathingMachine.supervisedMachine) ? "" : "disabledField"}
                                        style={{display: "flex", alignItems: "center"}}
                                      >
                                        {!isGelso ?
                                          <Form.Item
                                            name={"supervisedMachine"}
                                            valuePropName={"checked"}
                                            initialValue={selectedLathingMachine.supervisedMachine}
                                          >
                                            <Checkbox
                                              style={{marginBottom: shouldChangeOrder ? 32 : 2, marginRight: 10}}
                                              onChange={() => setSupervisedLathing(selectedLathingMachine.id)}
                                              disabled={!machiningData.lathing.hasLathing || noLathingMachines}
                                            />
                                          </Form.Item>
                                          :
                                          <Form.Item/>
                                        }
                                        <Form.Item
                                          label={"Additional operator cost"}
                                          name={"supervisionPricePerHour"}
                                          className={shouldChangeOrder ? "columnItem" : "rowItem"}
                                          initialValue={selectedLathingMachine.supervisionPricePerHour}
                                        >
                                          <InputNumber
                                            style={{minWidth: 30}}
                                            min={0}
                                            step={1}
                                            formatter={value => value ? value : 0}
                                            parser={value => value ? value : 0}
                                            onChange={() => changeLathing(selectedLathingMachine.id)}
                                            disabled={!(machiningData.lathing.hasLathing && selectedLathingMachine?.supervisedMachine) || noLathingMachines}
                                          />
                                        </Form.Item>
                                        <Typography.Text
                                          style={shouldChangeOrder ? {
                                            position: "relative",
                                            top: 16,
                                            right: 54
                                          } : {marginLeft: 12}}
                                        >
                                          €/h
                                        </Typography.Text>
                                      </div>
                                      <Divider style={{
                                        width: "100%",
                                        minWidth: "0%",
                                        margin: `9px 0 ${shouldChangeOrder ? 0 : "10px"} 0`,
                                        borderColor: "rgb(30, 30, 30)"
                                      }}/>
                                      <ToleranceSelect
                                        type={"maxTolerance"}
                                        machineType={"lathing"}
                                        isDisabled={!machiningData.lathing.hasLathing}
                                        initialValue={selectedLathingMachine.maxTolerance}
                                        machineId={selectedLathingMachine.id}
                                        handleChangeTolerance={changeMachineMaxTolerance}
                                        shouldChangeOrder={shouldChangeOrder}
                                        showButton={false}
                                        isLoading={isFetchingSelectedLathingMachine}
                                        handleMachineTolerancesOpen={handleLathingMachineTolerancesOpen}
                                      />
                                      <ToleranceSelect
                                        type={"maxRoughness"}
                                        machineType={"lathing"}
                                        isDisabled={!machiningData.lathing.hasLathing}
                                        initialValue={selectedLathingMachine.maxRoughness}
                                        machineId={selectedLathingMachine.id}
                                        handleChangeTolerance={changeMachineMaxRoughness}
                                        shouldChangeOrder={shouldChangeOrder}
                                        showButton={true}
                                        isLoading={isFetchingSelectedLathingMachine}
                                        handleMachineTolerancesOpen={handleLathingMachineTolerancesOpen}
                                      />
                                      {shouldChangeOrder2 &&
                                        <Divider style={{
                                          width: "75%",
                                          minWidth: "0%",
                                          margin: `9px 0 ${shouldChangeOrder ? 0 : "10px"} 0`,
                                          borderColor: "rgb(30, 30, 30)"
                                        }}/>
                                      }
                                    </Form>
                                  </div>
                                </div>
                                :
                                <EmptyTable
                                  text={"No Lathing Machines Available"}
                                  style={{
                                    position: "relative", top: "25%", height: "calc(100vh - 300px)",
                                    display: "flex", justifyContent: "center", alignItems: "center"
                                  }}
                                />
                              }
                            </Card>
                          </div>
                        }
                      </div>
                    </Spin>
                  </Col>

                </Row>
              </div>
            </div>
          }
        </div>
      </Card>
      {/*------------Milling Modals------------*/}
      <AddMachineModal
        type={"milling"}
        title={"Add New Milling Machine"}
        modalVisible={addMillingMachineModalVisible}
        handleCloseModal={handleAddMillingMachineClose}
        isLoading={isCreatingMillingMachine}
        handleAddMachine={handleAddMillingMachine}
        machineTypes={[]}
      />

      <EditMachineModal
        type={"milling"}
        title={"Edit Milling Machine"}
        modalVisible={editMillingMachineModalVisible}
        handleCloseModal={handleEditMillingMachineClose}
        isLoading={isUpdatingMillingMachine}
        handleEditMachine={applyEditMillingMachine}
        editMachine={editMillingMachine}
        machineTypes={[]}
      />

      <MachineTolerancesModal
        machineType={"milling"}
        title={"Set Milling Machine Adjustment"}
        modalVisible={millingMachineTolerancesModalVisible}
        handleCloseModal={handleMillingMachineTolerancesClose}
        isLoading={isFetchingSelectedMillingMachine}
        handleChangeTolerance={handleChangeMillingTolerance}
        handleChangeRoughness={handleChangeMillingRoughness}
        selectedMachineId={selectedMillingMachine.id}
        markupData={selectedMillingMachine?.markup}
        allowTolerancesChange={true}
      />

      <RemoveMachineModal
        type={"milling"}
        modalVisible={removeMillingMachineModalVisible}
        handleCloseModal={handleRemoveMillingMachineClose}
        machine={removeMillingMachine}
        handleRemoveMachine={handleRemoveMillingMachineConfirm}
      />

      {/*------------Lathing Modals------------*/}
      <AddMachineModal
        type={"lathing"}
        title={"Add New Lathing Machine"}
        modalVisible={addLathingMachineModalVisible}
        handleCloseModal={handleAddLathingMachineClose}
        isLoading={isCreatingLathingMachine}
        handleAddMachine={handleAddLathingMachine}
        machineTypes={[]}
      />

      <EditMachineModal
        type={"lathing"}
        title={"Edit Lathing Machine"}
        modalVisible={editLathingMachineModalVisible}
        handleCloseModal={handleEditLathingMachineClose}
        isLoading={isUpdatingLathingMachine}
        handleEditMachine={applyEditLathingMachine}
        editMachine={editLathingMachine}
        machineTypes={[]}
      />

      <MachineTolerancesModal
        machineType={"lathing"}
        title={"Set Lathing Machine Adjustment"}
        modalVisible={lathingMachineTolerancesModalVisible}
        handleCloseModal={handleLathingMachineTolerancesClose}
        isLoading={isFetchingSelectedLathingMachine}
        handleChangeTolerance={handleChangeLathingTolerance}
        handleChangeRoughness={handleChangeLathingRoughness}
        selectedMachineId={selectedLathingMachine.id}
        markupData={selectedLathingMachine?.markup}
        allowTolerancesChange={true}
      />

      <RemoveMachineModal
        type={"lathing"}
        modalVisible={removeLathingMachineModalVisible}
        handleCloseModal={handleRemoveLathingMachineClose}
        machine={removeLathingMachine}
        handleRemoveMachine={handleRemoveLathingMachineConfirm}
      />
      
    </React.Fragment>
  )
}