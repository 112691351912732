import React, {useEffect} from "react";
import "./style.less";
import {Card, Col, Divider, Row, Switch} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
  ProducerMaterialDoubleInput,
  ProducerMaterialInput,
  ProducerMaterialsCheckbox
} from "../../NonEditableFields";
import {fetchProducerSurfaceCoating} from "../../../../../actions/producerParamsActions";

export const SurfaceCoatingTab = (props) => {
  const {
    selectedTab,
    producerId
  } = props;

  const surfaceCoatingData = useSelector(state => state.producerParams.surfaceCoatingData)

  const dispatch = useDispatch()

  useEffect(()=>{
    if(selectedTab === "6") {
      fetchData()
    }
  }, [selectedTab])

  const fetchData = () => {
    dispatch(fetchProducerSurfaceCoating({producerId: producerId}))
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0}}>
        <div style={{height: 'calc(100vh - 172px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          <Row>
            <Col xl={14} span={24}>
              <div className={"switchContainer"}>
                <div>
                  <span style={{marginRight: 30, marginBottom: 6}}>Producer offers Surface Treatment service?</span>
                  <span>No</span>
                  <Switch
                    checked={surfaceCoatingData.hasSurfaceCoating}
                    style={{marginLeft: 6, marginRight: 6}}
                    disabled={true}
                    className={"producersUneditableSwitch"}
                  />
                  <span>Yes</span>
                </div>
              </div>
            </Col>
          </Row>

          <Divider style={{width: "50%", minWidth: "0%", margin: "14px 0 8px 0"}}/>
          {surfaceCoatingData.hasSurfaceCoating &&
          <div>
            <div className={"mainForm"}>
              {/*------------------------------------------------------------------------------------------------*/}
              <Row gutter={16}>
                <Col xl={12} span={24}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    <ProducerMaterialsCheckbox label={"Painting"} value={surfaceCoatingData.painting.hasPainting}/>
                  </div>
                  <span className={"processDescription"}>(Standard Painting with frequently-used colors)</span>
                  <Card className={"inputInfoText fieldGroupCard"}>
                    <div style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                      <ProducerMaterialInput
                        label={"Painting price"}
                        value={surfaceCoatingData.painting.paintingPrice}
                        suffix={<span>€/m<sup>2</sup></span>}
                        disabled={!surfaceCoatingData.painting.hasPainting}
                      />
                    </div>

                    <ProducerMaterialDoubleInput
                      disabled={!surfaceCoatingData.painting.hasPainting || !surfaceCoatingData.painting.hasPrimer}
                      hasTreatment={surfaceCoatingData.painting.hasPrimer}
                      price={surfaceCoatingData.painting.paintingPriceWithPrimer}
                      suffix={<span>€/m<sup>2</sup></span>}
                      labelOne={"Has Painting with Primer"}
                      labelTwo={"Price with Primer"}
                    />

                  </Card>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    <ProducerMaterialsCheckbox label={"Powder Painting"} value={surfaceCoatingData.powderPainting.hasPowderPainting}/>
                  </div>
                  <span className={"processDescription"}>(Standard Powder Painting with frequently-used colors)</span>
                  <Card className={"inputInfoText fieldGroupCard"}>
                    <div style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                      <ProducerMaterialInput
                        label={"Powder Painting price"}
                        value={surfaceCoatingData.powderPainting.powderPaintingPrice}
                        suffix={<span>€/m<sup>2</sup></span>}
                        disabled={!surfaceCoatingData.powderPainting.hasPowderPainting}
                      />
                    </div>

                    <ProducerMaterialDoubleInput
                      disabled={!surfaceCoatingData.powderPainting.hasPowderPainting || !surfaceCoatingData.powderPainting.hasPrimer}
                      hasTreatment={surfaceCoatingData.powderPainting.hasPrimer}
                      price={surfaceCoatingData.powderPainting.powderPaintingPriceWithPrimer}
                      suffix={<span>€/m<sup>2</sup></span>}
                      labelOne={"Has Powder Painting with Primer"}
                      labelTwo={"Price with Primer"}
                    />
                  </Card>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    <ProducerMaterialsCheckbox label={"Hot-Dip Galvanizing"} value={surfaceCoatingData.galvanizing.hasGalvanizing}/>
                  </div>
                  <span className={"processDescription"}>(Standard Hot-Dip Galvanizing process)</span>
                  <Card className={"inputInfoText fieldGroupCardSmall"}>
                    <div style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                      <ProducerMaterialInput
                        label={"Price for Hot-Dip Galvanizing"}
                        value={surfaceCoatingData.galvanizing.hotGalvanizingPrice}
                        suffix={<span>€/dm<sup>2</sup></span>}
                        disabled={!surfaceCoatingData.galvanizing.hasGalvanizing}
                        _labelWidth={185}
                      />
                    </div>
                  </Card>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    <ProducerMaterialsCheckbox
                      label={"Chemical Nickel Plating"}
                      value={surfaceCoatingData.nickelPlating.hasNickelPlating}
                    />
                  </div>
                  <span className={"processDescription"}>(Standard Chemical Nickel Plating process)</span>
                  <Card className={"inputInfoText fieldGroupCardSmall"}>
                    <div style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                      <ProducerMaterialInput
                        label={"Chemical Nickel Plating Price"}
                        suffix={<span>€/dm<sup>2</sup></span>}
                        value={surfaceCoatingData.nickelPlating.nickelPlatingPrice}
                        disabled={!surfaceCoatingData.nickelPlating.hasNickelPlating}
                      />
                    </div>
                  </Card>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    <ProducerMaterialsCheckbox
                      label={"Anodizing"}
                      value={surfaceCoatingData.anodizing.hasAnodizing}
                    />
                  </div>
                  <span className={"processDescription"}>(Standard Anodizing process)</span>
                  <Card className={"inputInfoText fieldGroupCardSmall"}>
                    <div style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                      <ProducerMaterialInput
                        label={"Anodizing Price"}
                        value={surfaceCoatingData.anodizing.anodizingPrice}
                        suffix={<span>€/dm<sup>2</sup></span>}
                        disabled={!surfaceCoatingData.anodizing.hasAnodizing}
                      />
                    </div>
                  </Card>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    <ProducerMaterialsCheckbox
                      label={"Galvanic Zinc Plating"}
                      value={surfaceCoatingData.galvanicZincPlating.hasGalvanicZincPlating}
                    />
                  </div>
                  <span className={"processDescription"}>(Standard Galvanic Zinc Plating process)</span>
                  <Card className={"inputInfoText fieldGroupCardSmall"}>
                    <div style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                      <ProducerMaterialInput
                        label={"Galvanic Zinc Plating Price"}
                        value={surfaceCoatingData.galvanicZincPlating.galvanicZincPlatingPrice}
                        disabled={!surfaceCoatingData.galvanicZincPlating.hasGalvanicZincPlating}
                        suffix={<span>€/dm<sup>2</sup></span>}
                      />
                    </div>
                  </Card>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    <ProducerMaterialsCheckbox
                      label={"Thermal Treatment"}
                      value={surfaceCoatingData.heatTreatment.hasHeatTreatment}
                    />
                  </div>
                  <span className={"processDescription"}>(Standard Thermal Treatment processes)</span>
                  <Card className={"inputInfoText fieldGroupCardLarger"}
                        style={{ minHeight: "unset" }}
                  >
                    <div>

                      <ProducerMaterialDoubleInput
                        disabled={!surfaceCoatingData.heatTreatment.hasHeatTreatment || !surfaceCoatingData.heatTreatment.hardening.hasHardening}
                        hasTreatment={surfaceCoatingData.heatTreatment.hardening.hasHardening}
                        price={surfaceCoatingData.heatTreatment.hardening.hardeningPrice}
                        suffix={<span>€/kg</span>}
                        labelOne={"Has Hardening"}
                        labelTwo={"Price of Hardening"}
                      />

                      <ProducerMaterialDoubleInput
                        disabled={!surfaceCoatingData.heatTreatment.hasHeatTreatment || !surfaceCoatingData.heatTreatment.caseHardening.hasCaseHardening}
                        hasTreatment={surfaceCoatingData.heatTreatment.caseHardening.hasCaseHardening}
                        price={surfaceCoatingData.heatTreatment.caseHardening.caseHardeningPrice}
                        suffix={<span>€/kg</span>}
                        labelOne={"Has Tempering"}
                        labelTwo={"Price of Tempering"}
                      />

                      <ProducerMaterialDoubleInput
                        disabled={!surfaceCoatingData.heatTreatment.hasHeatTreatment || !surfaceCoatingData.heatTreatment.annealing.hasAnnealing}
                        hasTreatment={surfaceCoatingData.heatTreatment.annealing.hasAnnealing}
                        price={surfaceCoatingData.heatTreatment.annealing.annealingPrice}
                        suffix={<span>€/kg</span>}
                        labelOne={"Has Annealing"}
                        labelTwo={"Price of Annealing"}
                      />
                    </div>
                  </Card>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
                <Col xl={12} span={24}>
                  <div style={{ display: "flex", alignItems: "center"}}>
                    <ProducerMaterialsCheckbox
                      label={"Blasting"}
                      value={surfaceCoatingData.blasting.hasBlasting}
                    />
                  </div>
                  <span className={"processDescription"}>(Standard Blasting processes)</span>
                  <Card className={"inputInfoText fieldGroupCard"}
                        style={{ minHeight: "unset" }}
                  >
                    <div>
                      <ProducerMaterialDoubleInput
                        disabled={!surfaceCoatingData.blasting.hasBlasting || !surfaceCoatingData.blasting.glassBeadBlasting.hasGlassBeadBlasting}
                        hasTreatment={surfaceCoatingData.blasting.glassBeadBlasting.hasGlassBeadBlasting}
                        price={surfaceCoatingData.blasting.glassBeadBlasting.glassBeadBlastingPrice}
                        suffix={<span>€/kg</span>}
                        labelOne={"Has Glass Bead Blasting"}
                        labelTwo={"Price of Glass Bead Blasting"}
                      />

                      <ProducerMaterialDoubleInput
                        disabled={!surfaceCoatingData.blasting.hasBlasting || !surfaceCoatingData.blasting.sandBlasting.hasSandBlasting}
                        hasTreatment={surfaceCoatingData.blasting.sandBlasting.hasSandBlasting}
                        price={surfaceCoatingData.blasting.sandBlasting.sandBlastingPrice}
                        suffix={<span>€/kg</span>}
                        labelOne={"Has Ceramic Bead Blasting"}
                        labelTwo={"Price of Ceramic Bead Blasting"}
                      />
                    </div>
                  </Card>
                </Col>
                {/*------------------------------------------------------------------------------------------------*/}
              </Row>
            </div>
          </div>
          }
        </div>
      </Card>
    </React.Fragment>
  )
}

