import React from "react";
import {Card, Col, Row, Spin} from "antd";
import {calcAdjustment, formatDate, getWeight, hasWeight} from "../../../../utils/utility";
import {CircularProgress} from "@mui/material";
import {OrderItemSummaryCard} from "../../OrderItemSummaryCard";

export const OrderConfirmationStage = (props) => {
  const {
    largeThreshold, user, order, needsManualQuote,
    totalPrice, parts, showOtherItem,
    showDetailsItem, isChangingDraftStage,
    handleOpenDocsModal
  } = props

  const getAssemblyParts = (assemblyId) => {
    return parts.filter(item => item.partType !== "assembly" && item.assemblyId === assemblyId)
  }

  const getAssemblyWeight = (assemblyId) => {
    let assemblyParts = getAssemblyParts(assemblyId)
    let weight = 0;
    for (const assemblyPart of assemblyParts) {
      if (hasWeight({item: assemblyPart})) {
        weight += parseFloat(getWeight({item: assemblyPart})) * (assemblyPart.quantity || 1)
      }
    }
    return weight
  }

  return (
    <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
      <Card
        style={{
          borderRadius: 5,
          border: '1px solid #c0c0c0',
          width: "100%",
          height: "100%",
          marginRight: largeThreshold ? 10 : 20
        }}
        bodyStyle={{padding: "10px 20px 20px 20px", width: "100%", height: "100%"}}
      >
        <div
          style={{
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 5
          }}
        >
          Summary
        </div>
        <div className={"orderDetailsInfoContainer"}>
          <Row>
            <Col lg={16} span={24}>
              <Spin
                spinning={isChangingDraftStage}
                style={{maxHeight: "100%"}}
                indicator={
                  <CircularProgress
                    sx={{color: "#1890ff"}}
                    size={42}
                    thickness={3}
                    style={{marginLeft: -21, marginTop: -21}}
                  />
                }
              >
                <Card
                  className={"orderDetailsInfoCard"}
                  bodyStyle={{ padding: "10px 0 10px 15px"}}
                >
                  <Row style={{marginTop: -7}}>
                    <Col span={12}>
                      <div className={"dataLabel"}>Date of quote</div>
                      <div className={"dataValue"}>{formatDate(new Date)}</div>
                      <div className={"dataLabel"}>Email</div>
                      <div className={"dataValue"}>{user.email}</div>
                      <div className={"dataLabel"}>Customer</div>
                      <div className={"dataValue"}>{user.name}</div>
                      <div className={"dataLabel"}>Phone</div>
                      <div className={"dataValue"}>{order.shippingData.phone}</div>
                    </Col>
                    <Col span={12}>
                      <div className={"dataLabel"}>Address</div>
                      <div className={"dataValue"}>{order.shippingData.address}</div>
                      <div className={"dataLabel"}>City</div>
                      <div className={"dataValue"}>{order.shippingData.city}</div>
                      <div className={"dataLabel"}>Country</div>
                      <div className={"dataValue"}>{order.shippingData.country}</div>
                      <div className={"dataLabel"}>Postal Code</div>
                      <div className={"dataValue"}>{order.shippingData.postalCode}</div>
                    </Col>
                  </Row>
                </Card>
              </Spin>
            </Col>
            <Col lg={8} span={24}>
              <Card
                className={"orderDetailsInfoCard"}
                bodyStyle={{ padding: "10px 0 10px 15px", display: "flex"}}
              >
                <Row style={{ alignContent: "flex-start" }}>
                  <Col span={12} lg={24}>
                    <div className={"dataLabel"}>Desired Delivery Date</div>
                    <div className={"dataValue"}>{formatDate(order.deliveryDate)}</div>
                  </Col>
                  <Col span={12} lg={24}>
                    <div className={"dataLabel"}>Total Price</div>
                    <div className={"dataValue"}>
                      {((totalPrice || 0) * calcAdjustment(order.adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            {/*<Col xxl={8} xl={8} lg={8} span={24}>*/}
            {/*  <Row style={{height: "100%"}}>*/}
            {/*    <Col xxl={24} xl={24} lg={24} md={12} span={24}>*/}
            {/*      <Card*/}
            {/*        className={"orderDetailsInfoCard"}*/}
            {/*        bodyStyle={{height: "100%", padding: "10px 15px 10px 15px"}}*/}
            {/*      >*/}
            {/*        <div*/}
            {/*          style={{*/}
            {/*            display: "flex",*/}
            {/*            alignItems: "center",*/}
            {/*            height: "100%"*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <div style={{display: "block", width: "100%"}}>*/}
            {/*            <div className={"dataLabel"}>Desired Delivery Date</div>*/}
            {/*            <div className={"dataValue2"}>{formatDate(order.deliveryDate)}</div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </Card>*/}
            {/*    </Col>*/}
            {/*    <Col xxl={24} xl={24} lg={24} md={12} span={24}>*/}
            {/*      <Card*/}
            {/*        className={"orderDetailsInfoCard"}*/}
            {/*        bodyStyle={{height: "100%", padding: "10px 15px 10px 15px"}}*/}
            {/*      >*/}
            {/*        <div*/}
            {/*          style={{*/}
            {/*            display: "flex",*/}
            {/*            alignItems: "center",*/}
            {/*            height: "100%"*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <div style={{display: "block", width: "100%"}}>*/}
            {/*            <div className={"dataLabel"}>Total Price</div>*/}
            {/*            <div*/}
            {/*              className={"dataValue2"}*/}
            {/*            >*/}
            {/*              {((totalPrice || 0) * calcAdjustment(order.adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </Card>*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</Col>*/}
          </Row>
        </div>
        <div
          className={"quotedItemsList"}
          style={{
            height: "calc(100vh - 420px)",
            minHeight: 350,
            padding: "0 6px 0 6px"
          }}
        >
          {parts.map(part => (
            part.partType === "assembly" ?
              <Card className={"assemblyPartsCard"} key={part.id}>
                <div className={"assemblyPartName"}>
                  <OrderItemSummaryCard
                    part={part}
                    order={order}
                    showOtherItem={showOtherItem}
                    showDetailsItem={showDetailsItem}
                    handleOpenDocsModal={handleOpenDocsModal}
                    getAssemblyWeight={getAssemblyWeight}
                  />
                </div>
                <div className={"assemblyParts"}>
                  {getAssemblyParts(part.id).map(assemblyPart => (
                    <Card
                      className={"bendCard"}
                      style={{height: 80, backgroundColor: "#354045"}}
                      bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                      key={assemblyPart.id}
                    >
                      <OrderItemSummaryCard
                        part={assemblyPart}
                        order={order}
                        showOtherItem={showOtherItem}
                        showDetailsItem={showDetailsItem}
                        handleOpenDocsModal={handleOpenDocsModal}
                        getAssemblyWeight={()=>{}}
                      />
                    </Card>
                  ))}
                </div>
              </Card>
              :
              ((!part.assemblyId && part.state) &&
                <Card
                  className={"bendCard"}
                  style={{height: 80}}
                  bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                  key={part.id}
                >
                  <OrderItemSummaryCard
                    part={part}
                    order={order}
                    showOtherItem={showOtherItem}
                    showDetailsItem={showDetailsItem}
                    handleOpenDocsModal={handleOpenDocsModal}
                    getAssemblyWeight={()=>{}}
                  />
                </Card>
              )
          ))}
        </div>
      </Card>
    </div>
  )
}