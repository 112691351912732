import React, { useEffect } from "react";
import { Checkbox, Cascader, Col, Form, Input, InputNumber, Row, Select, Tooltip } from "antd";
import { CaretDownOutlined, CaretRightOutlined, ExclamationCircleFilled, SearchOutlined } from "@ant-design/icons";
import { translator } from "../../../../../../../translations/translators";


const { Option } = Select;
const { OptGroup } = Select;

import { profiles } from "../../../../../../../data/profiles";
import {getTubeData} from "../../../../../../../utils/partCardUtils/sections";

export const PartInputArea = (props) => {
  const {
    item, orderId, changeSheetMetalMaterial, ptAutomatic, sheetMetalMaterials,
    machiningMaterials, tubeCuttingMaterials, changePartThickness, changePartQuantity, changePartNote,
    changePartStandard, changeMachiningMaterial, changeTubeCuttingMaterial, changePartNumberOfUniqueParts,
    selectedProfile, setSelectedProfile, changeMaterialCertificate
  } = props;

  const strForSearch = (str) => {
    return str
      ? str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
      : str;
  };

  useEffect(()=>{
    if(item.standard === "" || item.standard === null) {
      setSelectedProfile(null)
    } else {
      if(item.partType === "profile" || item.partType === "tubeCut") {
        let standard = item.standard.split("/");
        standard = standard.map(item => item.trim());
        let material = standard[1] + " / " + standard[2]
        standard.splice(1, 2, material)
        setSelectedProfile(standard)
      }
    }
  }, [item.standard])

  useEffect(()=>{
    if(item.partType === "tubeCut" && item.isTubeCut) {
      let material = getTubeData({item})
      setSelectedProfile(material)
    }
  }, [item.selectedMaterial])

  const sheetMetalFields = () => {
    return (
      <Row gutter={10} style={{marginInline: 5, marginTop: -4}}>
        {materialField()}
        {materialCertificateField()}
        {thicknessField()}
        {quantityField()}
        {noteField()}
        {warningField()}
      </Row>
    )
  }
  const millingAndLathingFields = () => {
    return (
      <Row gutter={10} style={{marginInline: 5, marginTop: -4}}>
        {materialField()}
        {materialCertificateField()}
        {quantityField()}
        {noteField()}
        {warningField()}
      </Row>
    )
  }
  const tubeAndProfileFields = () => {
    return (
      <Row gutter={10} style={{marginInline: 5, marginTop: -4}}>
        {materialField()}
        {materialCertificateField()}
        {quantityField()}
        {noteField()}
        {warningField()}
      </Row>
    )
  }
  const bulkAndBuyFields = () => {
    return (
      <Row gutter={10} style={{marginInline: 5, marginTop: -4}}>
        {OEMField()}
        {quantityField()}
        {noteField()}
        {warningField()}
      </Row>
    )
  }
  const otherFields = () => {
    return (
      <Row gutter={10} style={{marginInline: 5, marginTop: -4}}>
        {quantityField()}
        {noteField()}
        {warningField()}
      </Row>
    )
  }
  const assemblyFields = () => {
    return (
      <Row gutter={10} style={{marginInline: 5, marginTop: -4}}>
        {numOfUniquesField()}
        {quantityField()}
        {noteField()}
        {warningField()}
      </Row>
    )
  }

  const materialField = () => {

    const fieldName = () => {
      switch (item.partType) {
        case "sheetMetal":
        case "milled":
        case "lathed":
          return "Material"
        case "profile":
          return "Profile Type"
        default:
          return "Material"
      }
    }
    const tooltip = () => {
      if ((item.partType === "sheetMetal" || item.partType === "milled" || item.partType === "lathed" || (item.partType === 'tubeCut' && item.processingType === 'automatic'))
        && item.selectedMaterial.isAvailable === false && item.fileType === "step")  {
        return (
          <Tooltip
            color={"#ffc107"}
            overlayClassName={"small-tooltip"}
            size={"small"}
            title={<span className={"text12-500"} style={{ color: "#333f48" }}>Material not available.</span>}
          >
            <ExclamationCircleFilled
              style={{ color: "#ffc107", fontSize: 13, marginLeft: 6, position: "relative", top: -1 }} />
          </Tooltip>
        )
      }
    }
    const initialValue = () => {
      switch (item.partType) {
        case "sheetMetal":
          return item.selectedMaterial.grade
        case "tubeCut":
          return item.selectedMaterial.isAvailable === false ? item.selectedMaterial.grade.split(' - ')[1] : item.selectedMaterial.grade
        case "milled":
        case "lathed":
          return item.selectedMaterial.isAvailable === false ? item.selectedMaterial.grade : item.selectedMaterial.materialId
        default:
          return null
      }
    }
    const onSelect = (value) => {
      if (item.partType === "sheetMetal") {
        let data = {
          partId: item.id,
          materialName: value,
          partType: item.partType,
          type: item.fileType,
          processingType: item.processingType,
          thickness: item.selectedMaterial.thickness,
          partMaterials: item.materials.sheetMetal
        }
        changeSheetMetalMaterial(data)
      }
      else if (item.partType === "lathed" || item.partType === "milled") {
        let data = {
          id: item.id,
          partType: item.partType,
          materialId: value
        }
        changeMachiningMaterial(data)
      }
      else if (item.partType === "tubeCut") {
        setSelectedProfile((prev) => [prev[0], value.split(' - ')[1], prev[2]]);
        let data = {
          id: item.id,
          partType: item.partType,
          material: {
            grade: value,
            thickness: item.selectedMaterial.thickness,
            x: item.stepData.partData.tubeData.tubeDimensions[0],
            y: item.stepData.partData.tubeData.tubeDimensions[1],
            tubeType: item.stepData.partData.tubeData.tubeType
          }
        }
        changeTubeCuttingMaterial(data)
      }
    }
    const option = (t) => {
      return (
        <Option
          key={t.grade}
          value={t.grade}
          className={"orderItemMaterialSelectItem"}
        >
            <span className={"text12-600-colorless text-white"}>
              {translator(t.grade)}
            </span>
        </Option>
      )
    }
    const optGroup = () => {
      switch (item.partType) {
        case "sheetMetal":
          return (
            sheetMetalMaterials.map(material => {
              if (ptAutomatic) {
                return (
                  item.materials.sheetMetal[material.groupName] &&
                  <OptGroup
                    key={material.groupName}
                    label={material.groupName}
                  >
                    {material.grades.map(t => {
                      return (
                        item.materials.sheetMetal[material.groupName][t.grade] && option(t)
                      )
                    })}
                  </OptGroup>
                )
              } else {
                return (
                  <OptGroup
                    key={material.groupName}
                    label={material.groupName}
                  >
                    {material.grades.map(t => {
                      return option(t)
                    })}
                  </OptGroup>
                )
              }
            })
          )
        case "milled":
        case "lathed":
          return (
            machiningMaterials.map(group => {
              if(ptAutomatic) {
                return (
                  (
                    (item.partType === "milled" && item.materials.milling[group.group]) ||
                    (item.partType === "lathed" && item.materials.lathing[group.group])
                  ) &&
                  <OptGroup
                    key={group.group}
                    label={group.group}
                  >
                    {group.grades.map(grade => {
                      return (
                        (
                          (item.partType === "milled" && item.materials.milling[group.group][grade.grade]) ||
                          (item.partType === "lathed" && item.materials.lathing[group.group][grade.grade])
                        ) &&
                        <Option
                          key={grade.id}
                          value={grade.id}
                          className={"orderItemMaterialSelectItem"}
                        >
                        <span className={"text12-600-colorless text-white"}>
                          {translator(grade.grade)}
                        </span>
                        </Option>
                      )
                    })}
                  </OptGroup>
                )
              } else {
                return (
                  <OptGroup
                    key={group.group}
                    label={group.group}
                  >
                    {group.grades.map(grade => {
                      return (
                        <Option
                          key={grade.id}
                          value={grade.id}
                          className={"orderItemMaterialSelectItem"}
                        >
                        <span className={"text12-600-colorless text-white"}>
                          {translator(grade.grade)}
                        </span>
                        </Option>
                      )
                    })}
                  </OptGroup>
                )
              }
            })
          )
        case "profile":
          return null
        case "tubeCut":
          return (
            tubeCuttingMaterials.map(material => {
              return (
                item.materials.tubeCutting[material.group] &&
                <OptGroup
                  key={material.group}
                  label={material.group}
                >
                  {material.grades.map(t => {
                    return (
                      item.materials.tubeCutting[material.group][t.grade] &&
                      <Option
                        key={t.id}
                        value={t.grade}
                        className={"orderItemMaterialSelectItem"}
                      >
                        <span className={"text12-600-colorless text-white"}>
                          {t.grade.split(' - ')[1]}
                        </span>
                      </Option>
                    )
                  })}
                </OptGroup>
              )
            })
          )
        default:
          return null
      }
    }
    const manualPartMaterial = () => {
      switch (item.partType) {
        case "sheetMetal":
        case "milled":
        case "lathed":
          return (
            <Col span={24}>
              <span className={"text12-500-12"}>Material
                {item.selectedMaterial.isAvailable === false && item.fileType === "step" &&
                <Tooltip
                  color={"#ffc107"}
                  overlayClassName={"small-tooltip"}
                  size={"small"}
                  title={<span className={"text12-500"} style={{ color: "#333f48" }}>Material not available.</span>}
                >
                  <ExclamationCircleFilled
                    style={{ color: "#ffc107", fontSize: 13, marginLeft: 6, position: "relative", top: -1 }} />
                </Tooltip>
                }
              </span>
              <Form.Item
                name={"material"}
                style={{ marginBottom: 5 }}
                initialValue={item.partType === "sheetMetal" ? item.selectedMaterial.grade : item.selectedMaterial.materialId}
              >
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  onChange={onSelect}
                  getPopupContainer={trigger => trigger.parentNode}
                  showSearch
                  filterOption={(input, option) => {
                    if (option.value) {
                      return strForSearch(option.children.props.children).includes(strForSearch(input));
                    } else {
                      return false;
                    }
                  }}
                  className={item.selectedMaterial.isAvailable === false ? "notAvailable muiInput" : "muiInput"}
                  dropdownClassName={"orderItemMaterialSelectDropDown"}
                  virtual={false}
                >
                  {optGroup()}
                </Select>
              </Form.Item>
            </Col>
          )
        case "tubeCut":
        case "profile":
          return (
            <Col span={24}>
              <span className={"text12-500-12"}>Profile Type:</span>
              <Form.Item
                style={{marginBottom: 5}}
              >
                <Cascader
                  options={profiles}
                  onChange={(value) => {
                    setSelectedProfile(value?.length === 0 ? null : value);
                    let standard = value?.length === 0 ? "" : value.join(" / ");
                    changePartStandard({orderId: orderId, id: item.id, partType: item.partType, standard: standard});
                  }}
                  className={"text12-500 muiInput"}
                  dropdownClassName={"orderItemMaterialSelectDropDown profileTypeDropDown"}
                  style={{ width: '100%' }}
                  placeholder="Select profile type"
                  showSearch={{
                    filter: (inputValue, path) => {return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);},
                    limit: 400
                  }}
                  value={selectedProfile}
                  suffixIcon={(e) => {
                    return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                  }}
                  expandIcon={<CaretRightOutlined/>}
                />
              </Form.Item>
            </Col>
          )
      }
    }

    if (ptAutomatic) {
      return (
        <Col span={24}>
          <span className={"text12-500-12"}>{fieldName()}
            {tooltip()}
          </span>
          <Form.Item
            name={item.partType === "tubeCut" ? "tubeMaterial" : "material"}
            style={{ marginBottom: 5 }}
            initialValue={initialValue()}
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              onChange={onSelect}
              getPopupContainer={trigger => trigger.parentNode}
              showSearch
              filterOption={(input, option) => {
                if (option.value) {
                  return strForSearch(option.children.props.children).includes(strForSearch(input));
                } else {
                  return false;
                }
              }}
              className={item.selectedMaterial.isAvailable === false ? "notAvailable muiInput" : "muiInput"}
              dropdownClassName={"orderItemMaterialSelectDropDown"}
              virtual={false}
            >
              {optGroup()}
            </Select>
          </Form.Item>
        </Col>
      )
    }
    else
      return (
        manualPartMaterial()
      )
  }
  const materialCertificateField = () => {
    return (
      <Col span={24}>
        <div style={{ display: "flex", alignItems: "center", width: "100%", borderBottom: "1px solid #6a6a6a" }}>
          <Form.Item
            name={"materialCertificate"}
            initialValue={item.certNeeded || false}
            valuePropName={"checked"}
            style={{ margin: "0 8px 0 0", position: "relative", top: -2 }}
          >
            <Checkbox
              onChange={(e) => {
                changeMaterialCertificate({
                  id: item.id,
                  certNeeded: e.target.checked
                })
              }}
              checked={item.certNeeded || false}
            >
              <span
                className={"text12-500-12"}
                style={{ whiteSpace: "nowrap" }}
              >
                3.1 Material Certificate
              </span>
            </Checkbox>
          </Form.Item>
        </div>
      </Col>
    )
  }
  const OEMField = () => {
    return (
      <Col span={24}>
        <span className={"text12-500-12"}>OEM:</span>
        <Form.Item
          name={"standard"}
          initialValue={item.standard}
          style={{marginBottom: 5}}
        >
          <Input
            size={'large'}
            className={"text12-500 muiInput"}
            placeholder={"eg. Samsung"}
            onChange={(e) => {changePartStandard({orderId: orderId, id: item.id, standard: e.target.value})}}
          />
        </Form.Item>
      </Col>
    )
  }
  const thicknessField = () => {
    return (
      <Col span={12}>
        {ptAutomatic ?
          <React.Fragment>
            {item.fileType === 'step' ?
              <React.Fragment>
                <Tooltip
                  getPopupContainer={trigger => trigger.parentNode}
                  overlayClassName={"thickness-tooltip"}
                  color={"rgba(82,82,82,0.9)"}
                  title={
                    <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                        <span
                          className={"text12-500"}
                          style={{ color: "white" }}
                        >
                          The thickness is automatically detected from the file
                        </span>
                    </div>
                  }
                >
                  <span className={"text12-500-12"}>Thickness</span>
                  <Form.Item
                    style={{ marginBottom: 5 }}
                  >
                    <Select
                      className={"muiInput"}
                      disabled={true}
                      suffixIcon={<CaretDownOutlined />}
                      virtual={false}
                      value={item.selectedMaterial.thickness}
                    >
                      <Option
                        key={item.selectedMaterial.materialId}
                        value={item.selectedMaterial.thickness}
                      >
                          <span className={"text12-600"} style={{ color: "#bdbdbd" }}>
                            {item.selectedMaterial.thickness} mm
                          </span>
                      </Option>
                    </Select>
                  </Form.Item>
                </Tooltip>
              </React.Fragment>
              :
              <React.Fragment>
                <span className={"text12-500-12"}>
                  Thickness
                  {item.selectedMaterial.isAvailable === false &&
                  <Tooltip
                    color={"#ffc107"}
                    overlayClassName={"small-tooltip"}
                    size={"small"}
                    title={<span className={"text12-500"} style={{ color: "#333f48" }}>Material/Thickness not available.</span>}
                  >
                    <ExclamationCircleFilled style={{ color: "#ffc107", fontSize: 13, marginLeft: 6 }} />
                  </Tooltip>
                  }
                </span>
                <Form.Item
                  style={{ marginBottom: 5 }}
                >
                  <Select
                    suffixIcon={<CaretDownOutlined />}
                    value={item.selectedMaterial.isAvailable === false ? `${item.selectedMaterial.thickness} mm` : item.selectedMaterial.materialId}
                    onChange={(value) => changePartThickness({
                      id: item.id,
                      partType: item.partType,
                      thickness: item.availableThicknesses.find(thick => thick.id === value)
                    })}
                    className={item.selectedMaterial.isAvailable === false ? "notAvailable muiInput" : "muiInput"}
                    dropdownClassName={"orderItemMaterialSelectDropDown"}
                    getPopupContainer={trigger => trigger.parentNode}
                    virtual={false}
                  >
                    {item.availableThicknesses.map(thick => {
                      return (
                        <Option
                          key={thick.id}
                          value={thick.id}
                          className={"orderItemMaterialSelectItem"}
                        >
                            <span className={"text12-600"}>
                              {thick.thickness} mm
                            </span>
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </React.Fragment>
            }
          </React.Fragment>
          :
          <React.Fragment>
              <span className={"text12-500-12"}>
                Thickness
                {item.selectedMaterial.isAvailable === false &&
                <Tooltip
                  color={"#ffc107"}
                  overlayClassName={"small-tooltip"}
                  size={"small"}
                  title={<span className={"text12-500"} style={{color: "#333f48"}}>Material/Thickness not available.</span>}
                >
                  <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 13, marginLeft: 6}} />
                </Tooltip>
                }
              </span>
            <Form.Item
              style={{marginBottom: 5}}
            >
              <Select
                suffixIcon={<CaretDownOutlined />}
                value={(item.selectedMaterial.isAvailable === false ? `${item.selectedMaterial.thickness} mm` : item.selectedMaterial.materialId)}
                onChange={(value)=> changePartThickness({id: item.id, partType: item.partType, thickness: (item.availableThicknesses || []).find(thick => thick.id === value)})}
                getPopupContainer={trigger => trigger.parentNode}
                virtual={false}
                className={item.selectedMaterial.isAvailable === false ? "notAvailable muiInput" : "muiInput"}
                dropdownClassName={"orderItemMaterialSelectDropDown"}
                disabled={item.partType !== "sheetMetal"}
              >
                {(item.availableThicknesses || []).map(thick => {
                  return (
                    <Option
                      key={thick.id}
                      value={thick.id}
                      className={"orderItemMaterialSelectItem"}
                    >
                      <span
                        className={"text12-600"}
                      >
                        {thick.thickness} mm
                      </span>
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </React.Fragment>
        }
      </Col>
    )
  }
  const quantityField = () => {
    return (
      <Col span={item.partType === "sheetMetal" ? 12 : 24}>
        <span className={"text12-500-12"}>Quantity</span>
        <Form.Item
          name={"quantity"}
          style={{marginBottom: 5}}
          initialValue={item.quantity}
          rules={[
            {required: true, message: ""}
          ]}
          className={"quantity-field"}
        >
          <InputNumber
            className={"item-number-input muiInput"}
            placeholder={"Enter quantity"}
            type={"number"}
            style={{width: '100%'}}
            min={1}
            max={10000}
            formatter={value => value ? value : 1}
            parser={value => value ? value : 1}
            step={1}
            onChange={(q) => changePartQuantity({orderId: orderId, id: item.id, partType: item.partType, quantity: q})}
          />
        </Form.Item>
      </Col>
    )
  }
  const numOfUniquesField = () => {
    return (
      <Col span={24}>
        <span className={"text12-500-12"}>Number of Unique Parts</span>
        <Form.Item
          name={"numberOfUniqueParts"}
          style={{marginBottom: 5}}
          initialValue={item.numberOfUniqueParts || 1}
          rules={[
            {required: true, message: ""}
          ]}
          className={"quantity-field"}
        >
          <InputNumber
            className={"item-number-input muiInput"}
            placeholder={"Enter number of parts"}
            type={"number"}
            style={{width: '100%'}}
            min={1}
            max={10000}
            formatter={value => value ? value : 1}
            parser={value => value ? value : 1}
            step={1}
            onChange={(q) => changePartNumberOfUniqueParts({orderId: orderId, id: item.id, numberOfUniqueParts: q})}
          />
        </Form.Item>
      </Col>
    )
  }
  const noteField = () => {
    return (
      <Col span={24} >
        <span className={"text12-500-12"}>Note (Optional)</span>
        <Form.Item
          name={"note"}
          style={{marginBottom: ptAutomatic ? 20 : 5}}
          initialValue={item.note}
        >
          <Input
            size={'large'}
            className={"text12-500 muiInput"}
            placeholder={"Notes and additional requirements"}
            onChange={(e)=> changePartNote({orderId: orderId, id: item.id, partType: "part", note: e.target.value})}
          />
        </Form.Item>
      </Col>
    )
  }
  const warningField = () => {
    if (ptAutomatic) return null
    else return (
      <Col span={24}>
        <div className={"manualNote"}>
          <div style={{display:"flex", height: "100%", alignItems: "center", marginTop: "auto", marginBottom: "auto"}}>
            <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 12, position: "relative", marginLeft: 5}}/>
          </div>
          <div style={{display:"flex", height: "100%", alignItems: "center", marginTop: "auto", marginBottom: "auto"}}>
            <div className={"text12-600"} style={{marginLeft: 10, lineHeight: 1.4, fontSize: 10}}>
              Automatic quote for this type is not available.
            </div>
          </div>
        </div>
      </Col>
    )
  }

  const getContent = () => {
    switch (item.partType) {
      case "sheetMetal":
        return sheetMetalFields()
      case "milled":
      case "lathed":
        return millingAndLathingFields()
      case "tubeCut":
      case "profile":
        return tubeAndProfileFields()
      case "buyPart":
      case "bulkMaterial":
        return bulkAndBuyFields()
      case "assembly":
        return assemblyFields()
      case "other":
        return otherFields()
      default:
        return sheetMetalFields()
    }
  }

  return (
    <div>
      {getContent()}
    </div>
  )
}