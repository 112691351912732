import React, {useState} from "react";
import {FilePdfOutlined} from "@ant-design/icons";
import {Button, Col, Row, Table} from "antd";
import ReactToPrint from "react-to-print";
import logo from "../../../assets/Gelso.png";
import "./style.less";
import {formatDate} from "../../../utils/utility";
import {translator} from "../../../translations/translators";


const PrintComponent = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ backgroundColor: "white", minHeight: "100%"}}>
      <div className="page-header" style={{ backgroundColor: "white" }}>
      </div>
      <table>
        <thead>
        <tr>
          <td>
            <div className="page-header-space"/>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <Row>
              <Col span={24} style={{ backgroundColor: "white"}}>
                <div style={{margin: "0 30px 30px 30px"}}>
                  <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
                    <div className={"centeredCol"} style={{ width: 340 }}>
                      <img style={{objectFit: "contain", maxWidth: "100%", maxHeight: 140}}
                           src={logo}
                           alt={''}
                      />
                    </div>
                    <div style={{ width: 300 }}>
                      <Row>
                        <Col span={24}>
                          <div className={"orderNoteTitle"}>Quote Note</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className={"headerTextFields"}>Quote code:</div>
                          <div className={"headerTextFields"} style={{marginTop: 2}}>Date of Quote:</div>
                          <div className={"headerTextFields"} style={{marginTop: 2}}>Delivery date:</div>
                          <div className={"headerTextFields"} style={{marginTop: 2}}>Total price:</div>
                        </Col>
                        <Col span={12}>
                          <div className={"headerTextValues"}>{props.orderData.name}</div>
                          <div className={"headerTextValues"}>{formatDate(props.orderData.orderDate)}</div>
                          <div className={"headerTextValues"}>{formatDate(props.orderData.deliveryDate)}</div>
                          <div className={"headerTextValues"}>{props.orderData.totalPrice.toFixed(2)}€</div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className={"dataRow"}>
              <div className={"centeredCol"} style={{ alignItems: "flex-start", width: "50%", marginRight: 10, borderRight: "1px solid #cccccc"}}>
                <div className={"dataTitle"}>Delivery To:</div>
                <div className={"invoiceField"}>
                  {props.orderData.customer.name}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.customer.email}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.shippingData.address}, {props.orderData.shippingData.city}, {props.orderData.shippingData.country}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.shippingData.phone}
                </div>
              </div>

              <div className={"centeredCol"} style={{ alignItems: "flex-start", width: "50%", paddingLeft: 10}}>
                <div className={"dataTitle"}>Company Info:</div>
                <div className={"invoiceField"}>
                  {props.orderData.producer.name}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.producer.contactEmail}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.producer.address}, {props.orderData.producer.city}, {props.orderData.producer.country}
                </div>

                <div className={"invoiceField"}>
                  {props.orderData.producer.phone}
                </div>
              </div>
            </div>
            <Table columns={[
              {
                title: <span className={"orderNoteColumnTitle"}>Part</span>,
                className: "darkerNoteColumn",
                key: "fileName",
                align: "left",
                ellipsis: true,
                width: "30%",
                render: item => <span className={"orderNoteValues"} style={{marginLeft: item.assemblyId ? 25 : 0}}>{item.fileName}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>Type</span>,
                className: "noteColumn",
                key: "partType",
                align: "left",
                ellipsis: true,
                width: "11%",
                dataIndex: "partType",
                render: data => <span className={"orderNoteValues"}>{data}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>Material</span>,
                className: "darkerNoteColumn",
                key: "material",
                align: "left",
                ellipsis: true,
                width: "10%",
                dataIndex: "material",
                render: data => <span className={"orderNoteValues"}>{data}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>THK</span>,
                className: "noteColumn",
                key: "thickness",
                align: "left",
                ellipsis: true,
                width: "9%",
                dataIndex: "thickness",
                render: data => <span className={"orderNoteValues"}>{data ? data + "mm" : ""}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>WGT</span>,
                className: "darkerNoteColumn",
                key: "thickness",
                align: "left",
                ellipsis: true,
                width: "10%",
                dataIndex: "weight",
                render: data => <span className={"orderNoteValues"}>{data === '/' ? data : data + "kg"}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>QTY</span>,
                className: "noteColumn",
                key: "quantity",
                align: "left",
                ellipsis: true,
                width: "7%",
                dataIndex: "quantity",
                render: data => <span className={"orderNoteValues"}>{data}</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>PPU</span>,
                className: "darkerNoteColumn",
                key: "pricePerPiece",
                align: "left",
                ellipsis: true,
                width: "13%",
                dataIndex: "pricePerPiece",
                render: data => <span className={"orderNoteValues"}>{Number(data).toFixed(2)}€</span>
              },
              {
                title: <span className={"orderNoteColumnTitle"}>Total</span>,
                className: "noteColumn",
                key: "totalPrice",
                align: "left",
                ellipsis: true,
                width: "13%",
                dataIndex: "totalPrice",
                render: data => <span className={"orderNoteValues"}>{Number(data).toFixed(2)}€</span>
              },
              ]}
             dataSource={props.parts}
             bordered={false}
             size={"small"}
             style={{margin: 30, overflow: "hidden"}}
             pagination={false}
             rowClassName={(record, index) => {
               if(record.type === "assembly") {
                 return "pdfRowAssembly"
               } else if(record.assemblyId) {
                 return "pdfRowAssemblyItem"
               } else {
                 return ""
               }
             }}
             className={"deliveryNoteTable"}
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
});

export const OrderPdfExportButton = (props) => {
  const { isEditing, orderData, parts, collapsed, squared } = props;
  const componentRef = React.useRef();
  const [loading, setLoading] = useState(false)

  const formatPartType = (partType) => {
    switch(partType) {
      case "sheetMetal":
        return "Sheet Metal";
      case "milled":
        return "Milled";
      case "lathed":
        return "Lathed";
      case "tubeCut":
        return "Tube Cut";
      case "profile":
        return "Profile";
      case "assembly":
        return "Assembly";
      case "buyPart":
        return "Buy Part";
      case "bulkMaterial":
        return "Bulk Mat";
      case "other":
        return "Other";
      default:
        return "";
    }
  }

  const formatPart = (part, index) => {
    let name = (part.fileName || part.name)
    let volume = 0;
    let weight = '/'
    if(part.fileType === "step") {
      if(part.stepData?.partData?.volume && part.selectedMaterial?.density) {
        if(part.partType === 'tubeCut' && part.isTubeCut) {
          weight = (part.selectedMaterial?.density * part.stepData.partData.tubeData.tubeLength / 1000).toFixed(2)
        } else {
          volume = part.stepData.partData.volume / 1000000000
          weight = (part.selectedMaterial?.density * volume).toFixed(2)
        }
      }
    }
    if(part.fileType === "dxf") {
      if(part.dxfData?.partData?.area && part.selectedMaterial?.density && part.selectedMaterial?.thickness) {
        volume = (part.dxfData.partData.area * part.selectedMaterial.thickness) / 1000000000
        weight = (part.selectedMaterial?.density * volume).toFixed(2)
      }
    }

    if(weight !== '/' && Number(weight) === 0) {
      weight = ">0.01"
    }

    return {
      key: index,
      id: part.id,
      type: part.partType,
      assemblyId: part.assemblyId,
      fileName: name.substring(0, name.lastIndexOf(".")),
      partType: formatPartType(part.partType),
      material: translator(part.selectedMaterial?.grade) || "",
      thickness: part.selectedMaterial?.thickness || "",
      weight: weight,
      quantity: part.quantity,
      pricePerPiece: part.pricePerPiece.toFixed(4),
      totalPrice: (part.pricePerPiece * part.quantity).toFixed(4)
    }
  }

  const prepPartData = (parts) => {
    let tmpParts = []
    let assemblies = {}
    let index = 0
    for(let part of parts) {
      if(part.state !== "declined") {
        if(part.assemblyId) {
          if(assemblies[part.assemblyId]) {
            assemblies[part.assemblyId].push(formatPart(part, index))
          } else {
            assemblies[part.assemblyId] = [formatPart(part, index)]
          }
        } else {
          tmpParts.push(formatPart(part, index))
        }
      }
      index += 1;
    }
    let resParts = []
    for(let part of tmpParts) {
      resParts.push(part)
      if(part.type === "assembly") {
        resParts.push(...(assemblies[part.id] || []))
      }
    }
    return resParts
  }

  const handleAfterPrint = React.useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  return (
    <div style={{ display: "inline-block"}}>
      <ReactToPrint
        content={() => componentRef.current}
        documentTitle={orderData.name + formatDate(Date.now()) + ".pdf"}
        onBeforeGetContent={handleOnBeforeGetContent}
        onAfterPrint={handleAfterPrint}
        trigger={() => (
          <Button
            type="primary"
            className={`exportPdfButton ${squared && "downloadButton"} ${isEditing && "editingDisabled"}`}
            disabled={isEditing}
            loading={loading}
            style={{
              marginRight: 5
            }}
          >
            {squared ?
              <span>
                <FilePdfOutlined style={{fontSize: 20, marginRight: 0}}/>
                <div style={{ fontSize: 16, margin: 0  }}>Quote PDF</div>
              </span>
              :
              <span>
                <FilePdfOutlined style={{fontSize: 14, marginRight: 8}}/>{collapsed ? "PDF" : "Export PDF"}
              </span>
            }
          </Button>
        )}
      />
      <div style={{ display: "none", backgroundColor: "white"}}>
        <PrintComponent ref={componentRef}
                        orderData={orderData}
                        parts={prepPartData(parts)}
        />
      </div>
    </div>
  )
}