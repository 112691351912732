import React from 'react'
import {Layout, Row, Menu, Button} from 'antd'
import {
  BankOutlined,
  HomeOutlined,
  IdcardOutlined, LeftOutlined, LogoutOutlined,
  ProfileOutlined, RightOutlined,
  TeamOutlined,
  UserOutlined
} from "@ant-design/icons";
import history from "../../../utils/history";
import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {collapseSidebar} from "../../../actions/utilActions";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const SubMenu = Menu.SubMenu
const {Sider} = Layout;

let selectedField = '1';

function activeComponent(history) {
  switch (history.location.pathname) {
    case('/'):
      selectedField = '1';
      break;
    case('/customers'):
      selectedField = '2';
      break;
    case('/corporate'):
      selectedField = '3';
      break;
    case('/producers'):
      selectedField = '4';
      break;
    case('/logs'):
      selectedField = '5';
      break;
    default:
      selectedField = '1';
  }
  return selectedField;
}

export const AdminSidebar = (props) => {
  const activeSubmenuKeys = useSelector(state => state.util.activeSubmenuKeys);
  const {onLogout} = props

  const dispatch = useDispatch();

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const onCollapse = () => {
    dispatch(collapseSidebar(!isCollapsed));
  }

  return (
    <Sider
      style={{
        overflow: 'auto',
        minHeight: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 1000,
      }}
      collapsed={isCollapsed}
    >
      <Button className={"collapseButton"}
              style={{ left: isCollapsed ? '50px' : '190px'}}
              onClick={() => onCollapse()}
      >
        { isCollapsed ?
          <RightOutlined style={{ fontSize: 14, position: "relative", top: 1 }}/>
          :
          <LeftOutlined style={{ fontSize: 14, position: "relative", top: 1  }}/>
        }
      </Button>
      <Row style={{marginTop: '2rem'}}>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[activeComponent(history)]}
          defaultOpenKeys={isCollapsed ? [] : activeSubmenuKeys}
        >
          <Menu.Item
            key="1"
            onClick={() => {
              history.push('/')
            }}
            title={<span className="nav-text">Home</span>}
          >
            <div align={'center'} className={"menuItem"} style={{left: isCollapsed ? -3 : 0}}>
              <HomeOutlined style={{fontSize: 22}}/>
              {!isCollapsed && <span className={"menuLine"} >Home</span>}
            </div>
          </Menu.Item>
          <SubMenu
            key="sub1"
            title={
              <div align={'center'} className={"menuItem"} style={{left: isCollapsed ? -3 : 0}}>
                <TeamOutlined style={{fontSize: 21}}/>
                {!isCollapsed && <span className={"menuLine"} >Customers</span>}
              </div>
            }
            className={"subMenuC"}
          >
            <Menu.Item
              key="2"
              onClick={() => {
                history.push('/customers')
              }}
            >
              <div style={{display: "flex", alignItems: "center"}}>
                <div style={{display: "flex", marginLeft: -2}}>
                  <UserOutlined style={{fontSize: 20}}/>
                </div>
                <div style={{marginLeft: 7}}>
                  <span className="nav-text">Private customers</span>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => {
                history.push('/corporate')
              }}>
              <div style={{display: "flex", alignItems: "center"}}>
                <div style={{display: "flex", marginLeft: -2}}>
                  <IdcardOutlined style={{fontSize: 20}}/>
                </div>
                <div style={{marginLeft: 7}}>
                  <span className="nav-text">Corporate customers</span>
                </div>
              </div>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="4"
            onClick={() => {
              history.push('/producers')
            }}
            title={<span className="nav-text">Producers</span>}
          >
            <div align={'center'} className={"menuItem"} style={{left: isCollapsed ? -3 : 0}}>
              <BankOutlined style={{fontSize: 22}}/>
              {!isCollapsed && <span className={"menuLine"} >Producers</span>}
            </div>
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={() => {
              history.push('/logs')
            }}
            title={<span className="nav-text">Logs</span>}
          >
            <div align={'center'} className={"menuItem"} style={{left: isCollapsed ? -3 : 0}}>
              <ProfileOutlined style={{fontSize: 22}}/>
              {!isCollapsed && <span className={"menuLine"} >Logs</span>}
            </div>
          </Menu.Item>
        </Menu>
        <Menu
          theme="dark"
          mode="inline"
          style={{position: "absolute", bottom: 20 }}
        >
          <Menu.Item
            key="6"
            onClick={onLogout}
            title={<span className="nav-text">Sign out</span>}
          >
            <div align={'center'}
                 className={"menuItem"} style={{left: isCollapsed ? -3 : 0}}>
              <LogoutOutlinedIcon style={{fontSize: 22}}/>
              {!isCollapsed && <span className={"menuLine"} >Sign Out</span>}
            </div>
          </Menu.Item>
        </Menu>
      </Row>
    </Sider>
  )
}

export default AdminSidebar
