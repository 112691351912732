import React from "react";
import {Card, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

export const UploadingItemCard = (props) => {

  const { item } = props;

  return (
    <Card
      key={item.id || item.fileId}
      style={{
        borderRadius: "5px",
        border: "1px solid #07223d",
        marginBottom: 15,
        backgroundColor: "#082745"
      }}
      bodyStyle={{
        padding: 0
      }}
    >
      <div
        className={"part-card-head"}
      >
        <div className={"part-card-head-left-side"}>
          <Spin
            className={"part-card-head-spin"}
            indicator={<LoadingOutlined style={{color: "rgb(83,170,238)", fontSize: 18}}/>}
          />
          <span
            className={"part-name-white"}
          >
            {item.fileName}
          </span>
        </div>
        <div className={"part-card-head-right-side"}>
          <span className={"text12-500"} style={{color: "rgb(77,159,226)"}}>Uploading...</span>
        </div>
      </div>
    </Card>
  )
}