import React from "react";
import Sticky from "react-sticky-el";
import {Card, Form, Input, Radio, Spin} from "antd";
import {LoadingOutlined, SearchOutlined} from "@ant-design/icons";
import {Divider as MiDivider} from "@material-ui/core";
import {ArchivedBulkOrdersList} from "../../../ArchivedBulkOrdersList";

export const BulkOrderSticky = (props) => {

  const {
    isFetchingInternalArchived, selectedCalc,
    searchingArchived, setSelectedCalc,
    searchArchivedCalculations, form,
    internalArchivedCalculations,
  } = props;

  return (
    <div style={{marginLeft: 20, marginTop: -15}}>
      <Sticky
        scrollElement="window"
      >
        <div className={"archivedOrdersSticky"}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <div className={"pageTitle"} style={{whiteSpace: "nowrap", marginRight: 20}}>
              Stored Calculations
            </div>
          </div>
          <div style={{paddingTop: 17, height: "calc(100vh - 70px)"}}>
            <Spin
              spinning={isFetchingInternalArchived}
              indicator={
                <div align={"center"}>
                  <LoadingOutlined style={{ fontSize: 60, marginLeft: -20, marginTop: 30 }} spin />
                </div>
              }
              wrapperClassName={"archivedOrdersSpin borderRadiusSpin"}
            >
              <Card
                style={{borderRadius: 5, border: '1px solid #c0c0c0', height: "100%"}}
                bodyStyle={{padding: "5px 0 5px 7px", height: "100%"}}
              >
                <div style={{ width: "100%", height: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "2px 4px" }}>
                    <div style={{ marginLeft: 2, width: 135, minWidth: 135 }}>
                      <Radio.Group
                        value={selectedCalc}
                        onChange={(e) => {
                          if (!searchingArchived) {
                            setSelectedCalc(e.target.value)
                          }}
                        }
                        className={"loginText"}
                      >
                        <Radio value="self">Mine</Radio>
                        <Radio value="all">All</Radio>
                      </Radio.Group>
                    </div>
                    <div style={{ paddingRight: 3 }}>
                      <Form ref={form}>
                        <Form.Item
                          name={"search"}
                          style={{marginBottom: 0}}
                        >
                          <Input
                            suffix={<SearchOutlined style={{fontSize: 16, color: '#ff5b2d'}}/>}
                            placeholder={"Type to search"}
                            onChange={(e) =>
                              searchArchivedCalculations(e.target.value)
                            }
                          />
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                  <MiDivider style={{ margin: "4px 0px 0px -8px" }}/>
                  <div style={{width: "100%", height: "calc(100% - 37px)", overflow: "auto", overflowX: "hidden"}}>
                    <ArchivedBulkOrdersList
                      items={internalArchivedCalculations}
                      loading={isFetchingInternalArchived}
                      searching={searchingArchived}
                    />
                  </div>
                </div>
              </Card>
            </Spin>
          </div>
        </div>
      </Sticky>
    </div>
  )
}