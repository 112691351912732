import React, {useCallback, useEffect, useState} from 'react';
import {
  Card, Tabs, Form, InputNumber,
  Typography, Space, Divider,
  Modal, Spin, Col, Row
} from 'antd';

import "./style.less"
import {LoadingOutlined} from "@ant-design/icons";
import MaterialTableContainer from "../../MaterialTableContainer";
import {ValueTable} from "../../MaterialTables/ValueTable";
import {useWindowSize} from "../../../../../utils/utility";
import { lineBreakLimit } from "../../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchCuttingMachineParameters, fetchCuttingMachinePriorities,
  fetchCuttingMachines, fetchCuttingMachineTypes,
  updateCuttingMachineSpeed, setMachineParameter,
  updateCuttingMachinePriorities, createCuttingMachine,
  editCuttingMachine, deleteCuttingMachine,
  setEmptyCuttingMachineData, setCuttingMachineTolerance
} from "../../../../../actions/cuttinMachinesActions";
import {debounce} from "lodash";
import {getCuttingPricePerHour} from "../../../../../utils/calculator";
import {
  AddMachineModal,
  EditMachineModal,
  MachinePriorityModal,
  RemoveMachineModal,
  MachineSelect, MachineTolerancesModal
} from "../../../MachineSelect";
import {ToleranceSelect} from "../../../ToleranceSelect";
const {TabPane} = Tabs;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const LaserCuttingTab = (props) => {

  const {
    selectedTab, changeCuttingSpeed, 
    changeCuttingMachineParams,
    changeMachineMaxTolerance,
    changeMachineToleranceMarkup
  } = props;

  const [addMachineModalVisible, setAddMachineModalVisible] = useState(false)
  const [editMachine, setEditMachine] = useState(null)
  const [editMachineModalVisible, setEditMachineModalVisible] = useState(false)
  const [removeMachine, setRemoveMachine] = useState(null);
  const [removeMachineModalVisible, setRemoveMachineModalVisible] = useState(false)
  const [machinePriorityModalVisible, setMachinePriorityModalVisible] = useState(false)
  const [machineTolerancesModalVisible, setMachineTolerancesModalVisible] = useState(false)
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [shouldUpdateSpeeds, setShouldUpdateSpeeds] = useState(false)
  const [selectedMachineType, setSelectedMachineType] = useState("")
  const [calculationData, setCalculationData] = useState(
    {
      energyConsumption: 0,
      electricityCost: 0,

      credit: 0,
      interestRate: 0,
      investedCapital: 0,
      machineLifespan: 1, // not 0
      insurance: 0,
      requiredSpace: 0,
      rent: 0,
      maintenanceExpense: 0,
      yearlyHoursPerShift: 0,
      timeEfficiency: 0,
      numberOfShifts: 0,

      singleJetLifespan: 1, // not 0
      singleJetCost: 0,
      numOfActiveJets: 1,

      type: "laser",
      //------------------------
      workingGasPrice: 0,
      workingGasConsumption: 0,
      laserGasPrice: 0,
      laserGasConsumption: 0,

      lensLifespan: 1,  // not 0
      lensPrice: 0,
      //------------------------
      coolingGasPrice: 0,
      coolingGasConsumption: 0,
      plasmaGasPrice: 0,
      plasmaGasConsumption: 0,

      cathodeLifespan: 1, // not 0
      cathodePrice: 0,
      //------------------------
      abrasivePrice: 0,
      abrasiveConsumption: 0,
      //------------------------
      oxygenConsumption: 0,
      oxygenPrice: 0,

      cuttingGasConsumption: 0,
      cuttingGasPrice: 0,

      preheatingGasConsumption: 0,
      preheatingGasPrice : 0
    }
  )

  const [suggestedPricePerHour, setSuggestedPricePerHour] = useState(0)

  const [isCalculationModalVisible, setIsCalculationModalVisible] = useState(false)

  const cuttingMachines = useSelector(state => state.cuttingMachines.cuttingMachines)
  const isFetchingMachines = useSelector(state => state.cuttingMachines.isFetchingMachines)

  const machineTypes = useSelector(state => state.cuttingMachines.machineTypes)
  const isFetchingMachineTypes = useSelector(state => state.cuttingMachines.isFetchingMachineTypes)

  const machinePriorities = useSelector(state => state.cuttingMachines.machinePriorities)
  const isFetchingMachinePriorities = useSelector(state => state.cuttingMachines.isFetchingMachinePriorities)
  const isUpdatingMachinePriorities = useSelector(state => state.cuttingMachines.isUpdatingMachinePriorities)

  const machineData = useSelector(state => state.cuttingMachines.selectedMachine)
  const isFetchingSelectedMachine = useSelector(state => state.cuttingMachines.isFetchingSelectedMachine)

  const isCreatingCuttingMachine = useSelector(state => state.cuttingMachines.isCreatingCuttingMachine)

  const isEditingCuttingMachine = useSelector(state => state.cuttingMachines.isEditingCuttingMachine)

  const isRemovingMachine = useSelector(state => state.cuttingMachines.isRemovingMachine)

  const [machinePriority, setMachinePriority] = useState(machinePriorities)
  const [fetchingAll, setFetchingAll] = useState(false)

  const [rowFlex, setRowFlex] = useState(false);
  const dispatch = useDispatch();

  const laserCuttingRef = React.createRef();

  useEffect(()=>{
    if(selectedTab === "1") {
      setFetchingAll(true)
      dispatch(fetchCuttingMachineTypes())
      dispatch(fetchCuttingMachines()).then((machines)=>{
        if(machines.length !== 0) {
          if(selectedMachine) {
            let doesExist = machines.find(m => {
              return m?.machines.find(item => item.id === selectedMachine)
            })
            if(doesExist) {
              dispatch(fetchCuttingMachineParameters({cuttingMachineId: selectedMachine})).then(()=>{
                setShouldUpdateSpeeds(true)
                setFetchingAll(false)
              })
            }
            else {
              setSelectedMachine(machines[0].machines[0].id)
              dispatch(fetchCuttingMachineParameters({cuttingMachineId: machines[0].machines[0].id})).then(()=>{
                setShouldUpdateSpeeds(true)
                setFetchingAll(false)
              })
            }
          } else {
            setSelectedMachine(machines[0].machines[0].id)
            dispatch(fetchCuttingMachineParameters({cuttingMachineId: machines[0].machines[0].id})).then(()=>{
              setShouldUpdateSpeeds(true)
              setFetchingAll(false)
            })
          }
        } else {
          setFetchingAll(false)
        }
      }).catch(()=>{})
      dispatch(fetchCuttingMachinePriorities())
    }
  }, [selectedTab])

  const handleAddMachineOpen = () => {
    setAddMachineModalVisible(true)
  }

  const handleAddMachineClose = () => {
    setAddMachineModalVisible(false)
  }

  const handleEditMachine = (e, item) => {
    e.stopPropagation()
    setEditMachine(item)
    setEditMachineModalVisible(true)
  }

  const handleEditMachineClose = () => {
    setEditMachineModalVisible(false)
  }

  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width >= 1153 && windowSize.width <= 1426) {
      setRowFlex(true)
    } else {
      setRowFlex(false);
    }
  }, [windowSize.width])

  const handleMachinePriorityClose = () => {
    setMachinePriorityModalVisible(false)
  }

  const handleMachinePriorityOpen = () => {
    setMachinePriority(machinePriorities)
    setMachinePriorityModalVisible(true)
  }

  const handleChangeMachinePriority = () => {
    let priorities = machinePriority.map(item => item.id)
    dispatch(updateCuttingMachinePriorities(priorities)).then(()=>{
      handleMachinePriorityClose()
    })
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      machinePriority,
      result.source.index,
      result.destination.index,
    );
    setMachinePriority(items)
  }

  const handleMachineTolerancesOpen = () => {
    setMachineTolerancesModalVisible(true)
  }

  const handleMachineTolerancesClose = () => {
    setMachineTolerancesModalVisible(false)
  }

  const changeSelectedMachine = (id) => {
    setSelectedMachine(id)
    dispatch(fetchCuttingMachineParameters({cuttingMachineId: id})).then(()=>{
      setShouldUpdateSpeeds(true)
    })
  }

  const handleRemoveMachineClose = () => {
    setRemoveMachine(null)
    setRemoveMachineModalVisible(false)
  }

  const handleRemoveMachine = (e, machine) => {
    e.stopPropagation()
    setRemoveMachine(machine)
    setRemoveMachineModalVisible(true)
  }

  const handleRemoveMachineConfirm = () => {
    dispatch(deleteCuttingMachine({cuttingMachineId: removeMachine.id})).then( machines => {
      if(machines.length === 0) {
        setSelectedMachine(null) // Should be "", check
        dispatch(setEmptyCuttingMachineData())
      } else {
        if(removeMachine.id === selectedMachine) {
          setSelectedMachine(machines[0].machines[0].id)
          dispatch(fetchCuttingMachineParameters({cuttingMachineId: machines[0].machines[0].id})).then(()=>{
            setShouldUpdateSpeeds(true)
            setFetchingAll(false)
          })
        }
      }
      setRemoveMachine(null)
      setRemoveMachineModalVisible(false)
      dispatch(fetchCuttingMachinePriorities())
    })
  }

  useEffect(()=>{
    if(!isFetchingSelectedMachine) {
      laserCuttingRef.current?.setFieldsValue({
        approachLengthHigh: machineData.approachLengthHigh,
        approachLengthLow: machineData.approachLengthLow,
        approachLengthThreshold: machineData.approachLengthThreshold,
        averagePositioningSpeed: machineData.averagePositioningSpeed,
        averagePreparationTime: machineData.averagePreparationTime,
        cuttingPrice: machineData.cuttingPrice,
        exitLengthHigh: machineData.exitLengthHigh,
        exitLengthLow: machineData.exitLengthLow,
        exitLengthThreshold: machineData.exitLengthThreshold,
        maxCuttingLength: machineData.maxCuttingLength,
        maxCuttingWidth: machineData.maxCuttingWidth,
        pierceTimeHigh: machineData.pierceTimeHigh,
        pierceTimeLow: machineData.pierceTimeLow,
        pierceTimeThreshold: machineData.pierceTimeThreshold,
        preparationPrice: machineData.preparationPrice,
        maxTolerance: machineData.maxTolerance
      })
    }
  }, [isFetchingSelectedMachine])

  const changeSpeed = (params) => {
    changeCuttingSpeed({machineId: machineData.id, materialId: params.materialId, speed: params.value})
    dispatch(updateCuttingMachineSpeed(params))
    return Promise.resolve(10)
  }


  /*------- Max Width -------*/
  const changeMaxCuttingWidth = (data) => {
    dispatch(setMachineParameter({maxCuttingWidth: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedMaxCuttingWidthChange = useCallback(
    debounce(data => changeMaxCuttingWidth(data), 800),
    []
  );

  const changeCuttingMaxCuttingWidth = (value) => { // preimenuj change u apply
    debouncedMaxCuttingWidthChange({
      machineId: machineData.id,
      fieldName: "maxCuttingWidth",
      fieldValue: value
    })
  }

  /*------- Max Length -------*/
  const changeMaxCuttingLength = (data) => {
    dispatch(setMachineParameter({maxCuttingLength: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedMaxCuttingLengthChange = useCallback(
    debounce(data => changeMaxCuttingLength(data), 800),
    []
  );

  const changeCuttingMaxCuttingLength = (value) => {
    debouncedMaxCuttingLengthChange({
      machineId: machineData.id,
      fieldName: "maxCuttingLength",
      fieldValue: value
    })
  }

  /*------- Cutting Price -------*/
  const changeCuttingPrice = (data) => {
    dispatch(setMachineParameter({cuttingPrice: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedCuttingPriceChange = useCallback(
    debounce(data => changeCuttingPrice(data), 800),
    []
  );

  const changeCuttingCuttingPrice = (value) => {
    debouncedCuttingPriceChange({
      machineId: machineData.id,
      fieldName: "cuttingPrice",
      fieldValue: value
    })
  }

  /*------- Average Positioning Speed -------*/
  const changeAveragePositioningSpeed = (data) => {
    dispatch(setMachineParameter({averagePositioningSpeed: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedAveragePositioningSpeedChange = useCallback(
    debounce(data => changeAveragePositioningSpeed(data), 800),
    []
  );

  const changeCuttingAveragePositioningSpeed = (value) => {
    debouncedAveragePositioningSpeedChange({
      machineId: machineData.id,
      fieldName: "averagePositioningSpeed",
      fieldValue: value
    })
  }

  /*------- Preparation Price -------*/
  const changePreparationPrice = (data) => {
    dispatch(setMachineParameter({preparationPrice: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedPreparationPriceChange = useCallback(
    debounce(data => changePreparationPrice(data), 800),
    []
  );

  const changeCuttingPreparationPrice = (value) => {
    debouncedPreparationPriceChange({
      machineId: machineData.id,
      fieldName: "preparationPrice",
      fieldValue: value
    })
  }

  /*------- Average Preparation Time -------*/
  const changeAveragePreparationTime = (data) => {
    dispatch(setMachineParameter({averagePreparationTime: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedAveragePreparationTimeChange = useCallback(
    debounce(data => changeAveragePreparationTime(data), 800),
    []
  );

  const changeCuttingAveragePreparationTime = (value) => {
    debouncedAveragePreparationTimeChange({
      machineId: machineData.id,
      fieldName: "averagePreparationTime",
      fieldValue: value
    })
  }

  /*------- Pierce Time Low -------*/
  const changePierceTimeLow = (data) => {
    dispatch(setMachineParameter({pierceTimeLow: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedPierceTimeLowChange = useCallback(
    debounce(data => changePierceTimeLow(data), 800),
    []
  );

  const changeCuttingPierceTimeLow = (value) => {
    debouncedPierceTimeLowChange({
      machineId: machineData.id,
      fieldName: "pierceTimeLow",
      fieldValue: value
    })
  }

  /*------- Pierce Time High -------*/
  const changePierceTimeHigh = (data) => {
    dispatch(setMachineParameter({pierceTimeHigh: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedPierceTimeHighChange = useCallback(
    debounce(data => changePierceTimeHigh(data), 800),
    []
  );

  const changeCuttingPierceTimeHigh = (value) => {
    debouncedPierceTimeHighChange({
      machineId: machineData.id,
      fieldName: "pierceTimeHigh",
      fieldValue: value
    })
  }

  /*------- Pierce Time Threshold -------*/
  const changePierceTimeThreshold = (data) => {
    dispatch(setMachineParameter({pierceTimeThreshold: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedPierceTimeThresholdChange = useCallback(
    debounce(data => changePierceTimeThreshold(data), 800),
    []
  );

  const changeCuttingPierceTimeThreshold = (value) => {
    debouncedPierceTimeThresholdChange({
      machineId: machineData.id,
      fieldName: "pierceTimeThreshold",
      fieldValue: value
    })
  }

  /*------- Approach Length Low -------*/
  const changeApproachLengthLow = (data) => {
    dispatch(setMachineParameter({approachLengthLow: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedApproachLengthLowChange = useCallback(
    debounce(data => changeApproachLengthLow(data), 800),
    []
  );

  const changeCuttingApproachLengthLow = (value) => {
    debouncedApproachLengthLowChange({
      machineId: machineData.id,
      fieldName: "approachLengthLow",
      fieldValue: value
    })
  }

  /*------- Approach Length High -------*/
  const changeApproachLengthHigh = (data) => {
    dispatch(setMachineParameter({approachLengthHigh: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedApproachLengthHighChange = useCallback(
    debounce(data => changeApproachLengthHigh(data), 800),
    []
  );

  const changeCuttingApproachLengthHigh = (value) => {
    debouncedApproachLengthHighChange({
      machineId: machineData.id,
      fieldName: "approachLengthHigh",
      fieldValue: value
    })
  }

  /*------- Approach Length Threshold -------*/
  const changeApproachLengthThreshold = (data) => {
    dispatch(setMachineParameter({approachLengthThreshold: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedApproachLengthThresholdChange = useCallback(
    debounce(data => changeApproachLengthThreshold(data), 800),
    []
  );

  const changeCuttingApproachLengthThreshold = (value) => {
    debouncedApproachLengthThresholdChange({
      machineId: machineData.id,
      fieldName: "approachLengthThreshold",
      fieldValue: value
    })
  }

  /*------- Exit Length Low -------*/
  const changeExitLengthLow = (data) => {
    dispatch(setMachineParameter({exitLengthLow: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedExitLengthLowChange = useCallback(
    debounce(data => changeExitLengthLow(data), 800),
    []
  );

  const changeCuttingExitLengthLow = (value) => {
    debouncedExitLengthLowChange({
      machineId: machineData.id,
      fieldName: "exitLengthLow",
      fieldValue: value
    })
  }

  /*------- Exit Length High -------*/
  const changeExitLengthHigh = (data) => {
    dispatch(setMachineParameter({exitLengthHigh: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedExitLengthHighChange = useCallback(
    debounce(data => changeExitLengthHigh(data), 800),
    []
  );

  const changeCuttingExitLengthHigh = (value) => {
    debouncedExitLengthHighChange({
      machineId: machineData.id,
      fieldName: "exitLengthHigh",
      fieldValue: value
    })
  }

  /*------- Exit Length Threshold -------*/
  const changeExitLengthThreshold = (data) => {
    dispatch(setMachineParameter({exitLengthThreshold: data.fieldValue}))
    changeCuttingMachineParams(data)
  }

  const debouncedExitLengthThresholdChange = useCallback(
    debounce(data => changeExitLengthThreshold(data), 800),
    []
  );

  const changeCuttingExitLengthThreshold = (value) => {
    debouncedExitLengthThresholdChange({
      machineId: machineData.id,
      fieldName: "exitLengthThreshold",
      fieldValue: value
    })
  }
  
  /*------------ Tolerances ------------*/
  const handleChangeTolerance = (data) => {
    dispatch(setCuttingMachineTolerance(data))
    changeMachineToleranceMarkup(data)
  }

  const applyEditMachine = (values) => {
    if(editMachine.typeId !== values.typeId || editMachine.name !== values.name) {
      dispatch(editCuttingMachine({cuttingMachineId: editMachine.id, ...values})).then(()=>{
        handleEditMachineClose()
      })
    } else {
      handleEditMachineClose()
    }
  }

  const createNewMachine = (values) => {
    dispatch(createCuttingMachine(values)).then((id)=>{
      setSelectedMachine(id)
      dispatch(fetchCuttingMachineParameters({cuttingMachineId: id})).then(()=>{
        setShouldUpdateSpeeds(true)
      })
      dispatch(fetchCuttingMachinePriorities())
      handleAddMachineClose()
    })
  }

  const getMachineType = (typeId) => {
    let type = machineTypes.find(item => item.id === typeId)
    switch (type?.name) {
      case "CO₂":
        return "laser"
      case "Fiber Laser":
        return "laser"
      case "Nd:YAG Laser":
        return "laser"
      case "CNC Gas":
        return "gas"
      case "CNC Plasma":
        return "plasma"
      case "Water Jet":
        return "abrasive"
      default:
        return ""
    }
  }

  const showCalculationModal = () => {
    let laserType = getMachineType(machineData.typeId)
    setSelectedMachineType(laserType)
    setCalculationData(prevState => ({...prevState, type: laserType}))
    setIsCalculationModalVisible(true)
  }

  const closeCalculationModal = () => {
    setSelectedMachineType("")
    setIsCalculationModalVisible(false)
  }

  const recalculateCuttingPrice = (data) => {
    let newData = {...data.data, [data.field]: data.value}
    setCalculationData(newData)
    setSuggestedPricePerHour(getCuttingPricePerHour(newData))
  }

  const debouncedRecalculateCuttingPrice = useCallback(
    debounce(data => recalculateCuttingPrice(data), 800),
    []
  );

  const onCalculationFieldChange = (field, value) => {
    debouncedRecalculateCuttingPrice({data: calculationData, field, value})
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard tabMaterialCardMargin"}>
        <div style={{height: 'calc(100vh - 120px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          <Form
            layout={'horizontal'}
            style={{height: "100%"}}
            ref={laserCuttingRef}
          >
            <div className={windowSize.width > lineBreakLimit ? "inLine" : "newLIne"}>
              <div className={"customColumn2"} style={{width: "100%", maxWidth: 1605}}>
                <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'flex-end'}}>
                  <MachineSelect
                    type={"laserCutting"}
                    isFetchingMachines={isFetchingMachines}
                    selectedMachine={selectedMachine}
                    changeSelectedMachine={changeSelectedMachine}
                    machines={cuttingMachines}
                    isFetchingMachineTypes={isFetchingMachineTypes}
                    handleEditMachine={handleEditMachine}
                    handleRemoveMachine={handleRemoveMachine}
                    handleAddMachineOpen={handleAddMachineOpen}
                    isFetchingMachinePriorities={isFetchingMachinePriorities}
                    handleMachinePriorityOpen={handleMachinePriorityOpen}
                    machinePriorities={machinePriorities}
                    allowPriorityChange={true}
                    allowAddMachine={true}
                    showButtons={true}
                  />
                  {/*<div style={{display: "flex", marginBottom: 10, alignItems: 'flex-end'}}>*/}
                  {/*  <Button*/}
                  {/*    style={{fontSize: 13, fontWeight: 500}}*/}
                  {/*    type={"primary"}*/}
                  {/*    disabled={isFetchingMachineTypes || isFetchingMachinePriorities || machinePriorities.length === 0}*/}
                  {/*    onClick={()=>{showCalculationModal()}}*/}
                  {/*  >*/}
                  {/*    Price Help*/}
                  {/*  </Button>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
            <Divider style={{
              width: "75%",
              minWidth: "0%",
              margin: "1px 0 16px 0",
              position: "relative",
              top: "-1px"
            }}/>
            <Spin spinning={fetchingAll || isFetchingMachines || isFetchingSelectedMachine}
                  indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: machineData.id ? "41px" : "36%",
                    left: machineData.id ? "-40px" : "-10px",
                  }}
            >
            {machineData.id &&
              <div className={windowSize.width > lineBreakLimit ? "inLine" : "newLIne"}>
                <div className={"customColumn3"}>
                  <div className={"column3CardContainer"}>
                    <Card style={{marginBottom: 16}} bodyStyle={{padding: "8px 16px"}}>
                      <Form.Item className={"materialFormItem"}>
                        <Space>
                          <Form.Item
                            label="Maximum cutting length"
                            name="maxCuttingLength"
                            style={{marginBottom: 12}}
                            initialValue={machineData.maxCuttingLength}
                          >
                            <InputNumber
                              style={{minWidth: 100}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeCuttingMaxCuttingLength}
                            />
                          </Form.Item>
                          <Typography.Text className={"cuttingSpeedText"} style={{position: 'relative', top: -7}}>mm</Typography.Text>
                        </Space>
                      </Form.Item>
                      <Form.Item className={"materialFormItem"}>
                        <Space>
                          <Form.Item
                            className={"materialFormItem"}
                            label="Maximum cutting width"
                            name="maxCuttingWidth"
                            initialValue={machineData.maxCuttingWidth}
                          >
                            <InputNumber
                              style={{minWidth: 100, marginLeft: 5}}
                              min={0}
                              step={1}
                              formatter={value => value ? value : 0}
                              parser={value => value ? value : 0}
                              onChange={changeCuttingMaxCuttingWidth}
                            />
                          </Form.Item>
                          <Typography.Text className={"cuttingSpeedText"} style={{position: 'relative', top: -1}}>mm</Typography.Text>
                        </Space>
                      </Form.Item>
                    </Card>
                  </div>
                  <Tabs style={{marginLeft: -10, marginBottom: 20}}
                        type="card"
                        className={"laserSpeedTabs"}
                  >
                    {machineData.tables.map((item, index) => (
                      <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                        <MaterialTableContainer tableText={"Cutting speed by material Grade and material Thickness (m/min)"}
                                                xAxisText={"Available Thickness (mm)"}
                                                yAxisText={"Material Grade"}
                                                xAxisStyle={{marginTop: 12, marginBottom: -12}}
                        >
                          <ValueTable
                            columns={item.columns}
                            rows={item.rows}
                            allAvailable={item.allAvailable}
                            tabIndex={index}
                            onChange={changeSpeed}
                            updateTable={shouldUpdateSpeeds}
                            setUpdateTable={setShouldUpdateSpeeds}
                            leftColumnWidth={140}
                            referenceHeight={248}
                            isEditable={true}
                          />
                        </MaterialTableContainer>
                      </TabPane>
                    ))}
                  </Tabs>
                </div>

                <div className={`${rowFlex ? "customColumn5" : "customColumn4"}`}>
                  <Card bodyStyle={{ padding: 16 }}>
                    <div style={{ display: "flex", flexDirection: rowFlex ? "row" : "column", height: "100%", width : "100%", alignItems: "flex-start" }}>
                      <div style={{ width : "100%", maxWidth: 430 }}>
                        <Form.Item className={"materialFormItem"}>
                          <Space>
                            <Form.Item
                              className={"materialFormItem"}
                              label="Cutting price"
                              name="cuttingPrice"
                              initialValue={machineData.cuttingPrice}
                            >
                              <InputNumber
                                style={{minWidth: 100}}
                                min={0}
                                step={1}
                                formatter={value => value ? value : 0}
                                parser={value => value ? value : 0}
                                onChange={changeCuttingCuttingPrice}
                              />
                            </Form.Item>
                            <Typography.Text className={"cuttingSpeedText"}>€/h</Typography.Text>
                          </Space>
                        </Form.Item>
                        <div align={"right"} style={{marginRight: 21}}>
                          <Form.Item className={"materialFormItem"}>
                            <Space>
                              <Form.Item
                                className={"materialFormItem"}
                                label="Average Positioning speed"
                                name="averagePositioningSpeed"
                                initialValue={machineData.averagePositioningSpeed}
                              >
                                <InputNumber
                                  style={{minWidth: 100}}
                                  min={0}
                                  step={1}
                                  formatter={value => value ? value : 0}
                                  parser={value => value ? value : 0}
                                  onChange={changeCuttingAveragePositioningSpeed}
                                />
                              </Form.Item>
                              <Typography.Text className={"cuttingSpeedText"}>m/min</Typography.Text>
                            </Space>
                          </Form.Item>
                        </div>
                        <Divider className={"laserDivider"}/>
                        <div style={{marginTop: 16}}>
                          <Form.Item className={"materialFormItem"}>
                            <Space>
                              <Form.Item
                                className={"materialFormItem"}
                                label="Preparation price"
                                name="preparationPrice"
                                initialValue={machineData.preparationPrice}
                              >
                                <InputNumber
                                  style={{minWidth: 100}}
                                  min={0}
                                  step={1}
                                  formatter={value => value ? value : 0}
                                  parser={value => value ? value : 0}
                                  onChange={changeCuttingPreparationPrice}
                                />
                              </Form.Item>
                              <Typography.Text className={"cuttingSpeedText"}>€/h</Typography.Text>
                            </Space>
                          </Form.Item>
                          <div align={'right'} style={{marginRight: 21}}>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  className={"materialFormItem"}
                                  label={"Average Preparation time"}
                                  name="averagePreparationTime"
                                  initialValue={machineData.averagePreparationTime}
                                >
                                  <InputNumber
                                    style={{minWidth: 100}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeCuttingAveragePreparationTime}
                                  />
                                </Form.Item>
                                <Typography.Text className={"cuttingSpeedText"}>min</Typography.Text>
                              </Space>
                            </Form.Item>
                          </div>
                          {rowFlex &&
                            <div>
                              <Divider className={"laserDivider"}/>
                              <ToleranceSelect
                                type={"maxTolerance"}
                                machineType={"cutting"}
                                isDisabled={false}
                                initialValue={machineData.maxTolerance}
                                machineId={machineData.id}
                                handleChangeTolerance={changeMachineMaxTolerance}
                                shouldChangeOrder={false}
                                showButton={true}
                                isLoading={isFetchingSelectedMachine}
                                handleMachineTolerancesOpen={handleMachineTolerancesOpen}
                              />
                            </div>
                          }
                        </div>
                        {!rowFlex && <Divider className={"laserDivider"}/>}
                      </div>
                      {rowFlex && <Divider type={"vertical"} style={{ height: "256px", margin: "0 10px" }}/>}
                      <div>
                        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                          <div className={"cuttingSpeedText"}
                               style={{display: 'flex', alignItems: 'center', marginRight: 33, marginTop: -20}}>
                            Average Piercing time:
                          </div>
                          <div>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  style={{marginBottom: 5}}
                                  name="pierceTimeLow"
                                  initialValue={machineData.pierceTimeLow}
                                >
                                  <InputNumber
                                    style={{maxWidth: 70}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeCuttingPierceTimeLow}
                                  />
                                </Form.Item>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -5}}
                                >
                                  sec
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -4, marginLeft: 10}}
                                >
                                  for thickness
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -3}}
                                >
                                  ≤
                                </Typography.Text>
                              </Space>
                            </Form.Item>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  style={{marginBottom: 5}}
                                  name="pierceTimeHigh"
                                  initialValue={machineData.pierceTimeHigh}
                                >
                                  <InputNumber
                                    style={{maxWidth: 70}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeCuttingPierceTimeHigh}
                                  />
                                </Form.Item>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -5}}
                                >
                                  sec
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -4, marginLeft: 10}}
                                >
                                  for thickness
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -3}}
                                >
                                  >
                                </Typography.Text>
                              </Space>
                            </Form.Item>
                          </div>
                          <div style={{display: 'flex', alignItems: 'center', marginLeft: 15, marginTop: -5}}>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  className={"materialFormItem"}
                                  name="pierceTimeThreshold"
                                  initialValue={machineData.pierceTimeThreshold}
                                >
                                  <InputNumber
                                    style={{maxWidth: 70}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeCuttingPierceTimeThreshold}
                                  />
                                </Form.Item>
                                <Typography.Text className={"cuttingSpeedText"}>mm</Typography.Text>
                              </Space>
                            </Form.Item>
                          </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 17}}>
                          <div
                            className={"cuttingSpeedText"}
                            style={{display: 'flex', alignItems: 'center', marginRight: 14, marginTop: -20}}
                          >
                            Average approach length:
                          </div>
                          <div>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  style={{marginBottom: 5}}
                                  name="approachLengthLow"
                                  initialValue={machineData.approachLengthLow}
                                >
                                  <InputNumber
                                    style={{maxWidth: 70}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeCuttingApproachLengthLow}
                                  />
                                </Form.Item>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -5}}
                                >
                                  mm
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -4, marginLeft: 8}}
                                >
                                  for thickness
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -3}}
                                >
                                  ≤
                                </Typography.Text>
                              </Space>
                            </Form.Item>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  style={{marginBottom: 5}}
                                  name="approachLengthHigh"
                                  initialValue={machineData.approachLengthHigh}
                                >
                                  <InputNumber
                                    style={{maxWidth: 70}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeCuttingApproachLengthHigh}
                                  />
                                </Form.Item>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -5}}
                                >
                                  mm
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -4, marginLeft: 8}}
                                >
                                  for thickness
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -3}}
                                >
                                  >
                                </Typography.Text>
                              </Space>
                            </Form.Item>
                          </div>
                          <div style={{display: 'flex', alignItems: 'center', marginLeft: 15, marginTop: -5}}>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  className={"materialFormItem"}
                                  name="approachLengthThreshold"
                                  initialValue={machineData.approachLengthThreshold}
                                >
                                  <InputNumber
                                    style={{maxWidth: 70}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeCuttingApproachLengthThreshold}
                                  />
                                </Form.Item>
                                <Typography.Text className={"cuttingSpeedText"}>mm</Typography.Text>
                              </Space>
                            </Form.Item>
                          </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 17}}>
                          <div
                            className={"cuttingSpeedText"}
                            style={{display: 'flex', alignItems: 'center', marginRight: 50, marginTop: -20}}
                          >
                            Average exit length:
                          </div>
                          <div>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  style={{marginBottom: 5}}
                                  name="exitLengthLow"
                                  initialValue={machineData.exitLengthLow}
                                >
                                  <InputNumber
                                    style={{maxWidth: 70}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeCuttingExitLengthLow}
                                  />
                                </Form.Item>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -5}}
                                >
                                  mm
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -4, marginLeft: 8}}
                                >
                                  for thickness
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -3}}
                                >
                                  ≤
                                </Typography.Text>
                              </Space>
                            </Form.Item>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  style={{marginBottom: 5}}
                                  name="exitLengthHigh"
                                  initialValue={machineData.exitLengthHigh}
                                >
                                  <InputNumber
                                    style={{maxWidth: 70}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeCuttingExitLengthHigh}
                                  />
                                </Form.Item>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -5}}
                                >
                                  mm
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -4, marginLeft: 8}}
                                >
                                  for thickness
                                </Typography.Text>
                                <Typography.Text
                                  className={"cuttingSpeedText"}
                                  style={{position: 'relative', top: -3}}
                                >
                                  >
                                </Typography.Text>
                              </Space>
                            </Form.Item>
                          </div>
                          <div style={{display: 'flex', alignItems: 'center', marginLeft: 15, marginTop: -5}}>
                            <Form.Item className={"materialFormItem"}>
                              <Space>
                                <Form.Item
                                  className={"materialFormItem"}
                                  name="exitLengthThreshold"
                                  initialValue={machineData.exitLengthThreshold}
                                >
                                  <InputNumber
                                    style={{maxWidth: 70}}
                                    min={0}
                                    step={1}
                                    formatter={value => value ? value : 0}
                                    parser={value => value ? value : 0}
                                    onChange={changeCuttingExitLengthThreshold}
                                  />
                                </Form.Item>
                                <Typography.Text className={"cuttingSpeedText"}>mm</Typography.Text>
                              </Space>
                            </Form.Item>
                          </div>
                        </div>
                        {!rowFlex &&
                          <div>
                            <Divider className={"laserDivider"}/>
                            <ToleranceSelect
                              type={"maxTolerance"}
                              machineType={"cutting"}
                              isDisabled={false}
                              initialValue={machineData.maxTolerance}
                              machineId={machineData.id}
                              handleChangeTolerance={changeMachineMaxTolerance}
                              shouldChangeOrder={false}
                              showButton={true}
                              isLoading={isFetchingSelectedMachine}
                              handleMachineTolerancesOpen={handleMachineTolerancesOpen}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            }
            </Spin>
          </Form>
        </div>
      </Card>

      <AddMachineModal
        type={"laserCutting"}
        title={"Add New Laser Cutting Machine"}
        modalVisible={addMachineModalVisible}
        handleCloseModal={handleAddMachineClose}
        isLoading={isCreatingCuttingMachine}
        handleAddMachine={createNewMachine}
        machineTypes={machineTypes}
      />

      <EditMachineModal
        type={"laserCutting"}
        title={"Edit Laser Cutting Machine"}
        modalVisible={editMachineModalVisible}
        handleCloseModal={handleEditMachineClose}
        isLoading={isEditingCuttingMachine}
        handleEditMachine={applyEditMachine}
        editMachine={editMachine}
        machineTypes={machineTypes}
      />

      <MachinePriorityModal
        modalVisible={machinePriorityModalVisible}
        handleCloseModal={handleMachinePriorityClose}
        isLoading={isUpdatingMachinePriorities}
        handleEditPriorities={handleChangeMachinePriority}
        onDragEnd={onDragEnd}
        machinePriority={machinePriority}
      />

      <MachineTolerancesModal
        machineType={"cutting"}
        modalVisible={machineTolerancesModalVisible}
        handleCloseModal={handleMachineTolerancesClose}
        isLoading={isFetchingSelectedMachine}
        handleChangeTolerance={handleChangeTolerance}
        handleChangeRoughness={()=>{}}
        selectedMachineId={selectedMachine}
        markupData={machineData?.markup}
        allowTolerancesChange={true}
      />

      <RemoveMachineModal
        type={"laserCutting"}
        modalVisible={removeMachineModalVisible}
        handleCloseModal={handleRemoveMachineClose}
        machine={removeMachine}
        isLoading={isRemovingMachine}
        handleRemoveMachine={handleRemoveMachineConfirm}
      />

      <Modal
        title={"Cutting Price Calculator"}
        visible={isCalculationModalVisible}
        maskClosable={false}
        width={1100}
        footer={null}
        centered={true}
        destroyOnClose={true}
        onCancel={closeCalculationModal}
        bodyStyle={{paddingTop: 10, paddingBottom: 20}}
      >
        <div style={{fontSize: 12, fontWeight: 500}}>
          This is a quick calculator for getting the price per work-hour for your machine.
          Fill the fields that apply to your machine and get an estimated minimal price per hour.
        </div>
        <div style={{fontSize: 12, fontWeight: 500}}>
          Please note that this is only a suggested price for cutting based on parameters and is best used as a reference for getting a baseline cutting cost.
        </div>
        <Form>
          <Row gutter={15}>
            <Col span={12} style={{marginTop: 10}}>
              <Card
                className={"calculationCard"}
                style={{borderRadius: 5, border: "1px solid #bbbbbb"}}
                bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                title={<span style={{fontSize: 14, fontWeight: 600}}>Electricity</span>}
              >
                <Row gutter={15}>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Energy Consumption (kW):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("energyConsumption", value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Electricity Cost (€/kWh):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("electricityCost", value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card
                className={"calculationCard"}
                style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15}}
                bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                title={<span style={{fontSize: 14, fontWeight: 600}}>Jets</span>}
              >
                <Row gutter={15}>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Single Jet Lifespan (h):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 1}
                        parser={value => value ? value : 1}
                        onChange={(value)=>onCalculationFieldChange("singleJetLifespan", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Number of Jets:</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 1}
                        parser={value => value ? value : 1}
                        onChange={(value)=>onCalculationFieldChange("numOfActiveJets", value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Single Jet Cost (€):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("singleJetCost", value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              {selectedMachineType === "laser" &&
                <Card
                  className={"calculationCard"}
                  style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15}}
                  bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                  title={<span style={{fontSize: 14, fontWeight: 600}}>Laser</span>}
                >
                  <Row gutter={15}>
                    <Col span={10}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Working Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("workingGasPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Laser Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={1}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("laserGasPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Lens Lifespan (h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={1}
                          step={1}
                          formatter={value => value ? value : 1}
                          parser={value => value ? value : 1}
                          onChange={(value)=>onCalculationFieldChange("lensLifespan", value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Working Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("workingGasConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Laser Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("laserGasConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Lens Price (€):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("lensPrice", value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              }
              {selectedMachineType === "plasma" &&
                <Card
                  className={"calculationCard"}
                  style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15}}
                  bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                  title={<span style={{fontSize: 14, fontWeight: 600}}>Plasma</span>}
                >
                  <Row gutter={15}>
                    <Col span={10}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Cooling Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("coolingGasPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Plasma Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("plasmaGasPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Cathode Lifespan (h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 1}
                          parser={value => value ? value : 1}
                          onChange={(value)=>onCalculationFieldChange("cathodeLifespan", value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Cooling Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("coolingGasConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Plasma Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("plasmaGasConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Cathode Price (€):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("cathodePrice", value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              }
              {selectedMachineType === "gas" &&
                <Card
                  className={"calculationCard"}
                  style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15}}
                  bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                  title={<span style={{fontSize: 14, fontWeight: 600}}>Gas</span>}
                >
                  <Row gutter={15}>
                    <Col span={10}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Oxygen Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("oxygenPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Cutting Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("cuttingGasPrice", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Preheating Gas Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("preheatingGasPrice", value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Oxygen Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("oxygenConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Cutting Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("cuttingGasConsumption", value)}
                        />
                      </Form.Item>
                      <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Preheating Gas Consumption (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("preheatingGasConsumption", value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              }
              {selectedMachineType === "abrasive" &&
                <Card
                  className={"calculationCard"}
                  style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15}}
                  bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                  title={<span style={{fontSize: 14, fontWeight: 600}}>Water Jet</span>}
                >
                  <Row gutter={15}>
                    <Col span={10}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Abrasive Water Price (€/m³):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("abrasivePrice", value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Abrasive Water Price (l/h):</div>
                      <Form.Item
                        className={"cuttingCalculationItem"}
                      >
                        <InputNumber
                          style={{minWidth: 160}}
                          min={0}
                          step={1}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          onChange={(value)=>onCalculationFieldChange("abrasiveConsumption", value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              }
            </Col>
            <Col span={12} style={{marginTop: 10}}>
              <Card
                className={"calculationCard"}
                style={{borderRadius: 5, border: "1px solid #bbbbbb"}}
                bodyStyle={{padding: "15px 15px 0 15px", borderRadius: 5, background: "rgba(238,238,238,0.4)"}}
                title={<span style={{fontSize: 14, fontWeight: 600}}>Capital</span>}
              >
                <Row gutter={15}>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Credit (€):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("credit", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Invested Capital (€):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("investedCapital", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Machine Lifespan (year):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("machineLifespan", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Rent (€/m²):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("rent", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: -2, fontSize: 12, fontWeight: 500}}>Yearly Hours Per Shift (h):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("yearlyHoursPerShift", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: -2, fontSize: 12, fontWeight: 500}}>Number Of Shifts:</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("numberOfShifts", value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <div style={{marginBottom: 4, fontSize: 12, fontWeight: 500}}>Interest Rate (%/year):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={0}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("interestRate", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Maintenance Expense (%):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("maintenanceExpense", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Required Space (m²):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("requiredSpace", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 12, fontWeight: 500}}>Insurance (%):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("insurance", value)}
                      />
                    </Form.Item>
                    <div style={{marginBottom: 4, marginTop: -2, fontSize: 12, fontWeight: 500}}>Time Efficiency (%):</div>
                    <Form.Item
                      className={"cuttingCalculationItem"}
                    >
                      <InputNumber
                        style={{minWidth: 160}}
                        min={1}
                        step={1}
                        formatter={value => value ? value : 0}
                        parser={value => value ? value : 0}
                        onChange={(value)=>onCalculationFieldChange("timeEfficiency", value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              {selectedMachineType !== "abrasive" &&
                <Card
                  className={"calculationCard"}
                  style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15, height: 115}}
                  bodyStyle={{padding: 15, borderRadius: 5, background: "rgba(238,238,238,0.4)", height: "calc(100% - 31px)"}}
                  title={<span style={{fontSize: 14, fontWeight: 600}}>Cutting Price Per Hour</span>}
                >
                  <div style={{width: "100%", height: 50, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 13, fontWeight: 500}}>Suggested price based on your parameters:</div>
                    <div style={{marginBottom: 4, marginTop: 5, fontSize: 13, fontWeight: 500}}>{(suggestedPricePerHour || 0).toFixed(2)} €/h</div>
                  </div>

                </Card>
              }
            </Col>
          </Row>
          {selectedMachineType === "abrasive" &&
            <Card
              className={"calculationCard"}
              style={{borderRadius: 5, border: "1px solid #bbbbbb", marginTop: 15, height: 81}}
              bodyStyle={{padding: 15, borderRadius: 5, background: "rgba(238,238,238,0.4)", height: 50}}
              title={<span style={{fontSize: 14, fontWeight: 600}}>Cutting Price Per Hour</span>}
            >
              <div style={{width: "100%", height: 20, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <div style={{marginBottom: 4, marginTop: 5, fontSize: 13, fontWeight: 500}}>Suggested price based on your parameters:</div>
                <div style={{marginBottom: 4, marginTop: 5, fontSize: 13, fontWeight: 500}}>{(suggestedPricePerHour || 0).toFixed(2)} €/h</div>
              </div>

            </Card>
          }
        </Form>
      </Modal>
    </React.Fragment>
  )
}

/*

let data = {
  energyConsumption: (kW),
  electricityCost: (€/kWh),

  credit: (€),
  interestRate: (%/year),
  investedCapital: (€),
  machineLifespan: (years),
  insurance: (%),
  requiredSpace: (m²),
  rent: (€/m²),
  maintenanceExpense: (%),
  yearlyHoursPerShift: (h),
  timeEfficiency: (%),
  numberOfShifts: (Number),

  singleJetLifespan: (h),
  singleJetCost: (€),
  numOfActiveJets: (Number),

  type: "laser",
  laser: {
    workingGasPrice: (€/m³),
    workingGasConsumption: (l/h),
    laserGasPrice: (€/m³),
    laserGasConsumption: (l/h),

    lensLifespan: (h),
    lensPrice: (€)
  },
  plasma: {
    coolingGasPrice: (€/m³),
    coolingGasConsumption: (l/h),
    plasmaGasPrice: (€/m³),
    plasmaGasConsumption: (l/h),

    cathodeLifespan: (h),
    cathodePrice: (€)
  },
  abrasive: {
    abrasivePrice: (€/l),
    abrasiveConsumption: (l/h),
  },
  gas: {
    oxygenConsumptionWhilePreheating: (l/h),
    oxygenConsumptionWhileCutting: (l/h),
    oxygenPrice: (€/m³),
    cuttingGasConsumption: (l/h),
    preheatingGasConsumption: (l/h)
  }
}

*/