import React from "react";
import {InboxOutlined} from "@ant-design/icons";
import "./style.less";

export const EmptyTable = (props) => {
  return (
    <div
      className={"emptyTableContent"}
      style={props.style}
    >
      <InboxOutlined style={{ fontSize: 36 }}/>
      <span style={{ fontWeight: 500, fontSize: 16 }}>{props.text}</span>
    </div>
  )
}