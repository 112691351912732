import { Button, Divider } from "antd";
import {
    getTotalLathingSuggestedPrice,
    getTotalMillingSuggestedPrice,
    getTotalSheetMetalSuggestedPrice,
    getTotalTubeCuttingSuggestedPrice
} from "../../../../../../../../utils/utility";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TooltipMui from "@mui/material/Tooltip";
import {useSelector} from "react-redux";

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#14181a"
  },
  popper: {
    zIndex: 1099
  },
  arrow: {
    color: "#14181a"
  }
})(TooltipMui);

const SheetMetalContent = (props) => {
  const {item, itemQuantity} = props;
  return (
    <div>
      <div className={"tooltipLine"}>
        <span>Cutting price:</span>
        <span>{item?.operationPrices?.cuttingPricePerPiece.toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Cutting preparation price:</span>
        <span>{(item?.operationPrices?.cuttingPreparationPrice/itemQuantity).toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Total cutting price:</span>
        <span>{((item?.operationPrices?.cuttingPricePerPiece * itemQuantity + item?.operationPrices?.cuttingPreparationPrice)/itemQuantity).toFixed(2)}€</span>
      </div>
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"}>
        <span>Material price: </span>
        <span>{(item?.operationPrices?.materialPricePerPiece).toFixed(2)}€</span>
      </div>
      {item?.certNeeded &&
        <div className={"tooltipLine"}>
          <span>Certificate price:</span>
          <span>{(item?.operationPrices?.materialCertificatePrice/itemQuantity).toFixed(2)}€</span>
        </div>
      }
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"}>
        <span>Bending price:</span>
        <span>{item?.operationPrices?.bendingPricePerPiece.toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Bending preparation price:</span>
        <span>{(item?.operationPrices?.bendingPreparationPrice / itemQuantity).toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Total bending price:</span>
        <span>{((item?.operationPrices?.bendingPricePerPiece * itemQuantity + item?.operationPrices?.bendingPreparationPrice)/itemQuantity).toFixed(2)}€</span>
      </div>
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"}>
        <span>Machining price: </span>
        <span>{item?.operationPrices?.machiningPricePerPiece.toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Machining preparation price: </span>
        <span>{(item?.operationPrices?.machiningPreparationPrice / itemQuantity).toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Total machining price:</span>
        <span>{((item?.operationPrices?.machiningPricePerPiece * itemQuantity + item?.operationPrices?.machiningPreparationPrice)/itemQuantity).toFixed(2)}€</span>
      </div>
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"}>
        <span>Surface finish price:</span>
        <span>{item?.operationPrices?.coatingPricePerPiece.toFixed(2)}€</span>
      </div>
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"} style={{ fontSize: 15 }}>
        <b>Total suggested price:</b>
        <b style={{marginLeft: 5}}>{(getTotalSheetMetalSuggestedPrice(item?.operationPrices, itemQuantity) || 0).toFixed(2)}€</b>
      </div>
    </div>
  )
}

const MillingContent = (props) => {
  const {item, itemQuantity} = props;
  return (
    <div>
      <div className={"tooltipLine"}>
        <span>Milling price:</span>
        <span>{item?.operationPrices?.millingPricePerPiece.toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Milling preparation price:</span>
        <span>{(item?.operationPrices?.millingPreparationPrice/itemQuantity).toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Total milling price:</span>
        <span>{((item?.operationPrices?.millingPricePerPiece * itemQuantity + item?.operationPrices?.millingPreparationPrice)/itemQuantity).toFixed(2)}€</span>
      </div>
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"}>
        <span>Material price: </span>
        <span>{(item?.operationPrices?.materialPricePerPiece).toFixed(2)}€</span>
      </div>
      {item?.certNeeded &&
        <div className={"tooltipLine"}>
          <span>Certificate price:</span>
          <span>{(item?.operationPrices?.materialCertificatePrice/itemQuantity).toFixed(2)}€</span>
        </div>
      }
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"}>
        <span>Surface finish price:</span>
        <span>{item?.operationPrices?.coatingPricePerPiece.toFixed(2)}€</span>
      </div>
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"} style={{ fontSize: 15 }}>
        <b>Total suggested price:</b>
        <b style={{marginLeft: 5}}>{(getTotalMillingSuggestedPrice(item?.operationPrices, itemQuantity) || 0).toFixed(2)}€</b>
      </div>
    </div>
  )
}

const LathingContent = (props) => {
  const {item, itemQuantity} = props;
  return (
    <div>
      <div className={"tooltipLine"}>
        <span>Lathing price:</span>
        <span>{item?.operationPrices?.lathingPricePerPiece.toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Lathing preparation price:</span>
        <span>{(item?.operationPrices?.lathingPreparationPrice/itemQuantity).toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Total lathing price:</span>
        <span>{((item?.operationPrices?.lathingPricePerPiece * itemQuantity + item?.operationPrices?.lathingPreparationPrice)/itemQuantity).toFixed(2)}€</span>
      </div>
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"}>
        <span>Material price: </span>
        <span>{(item?.operationPrices?.materialPricePerPiece).toFixed(2)}€</span>
      </div>
      {item?.certNeeded &&
        <div className={"tooltipLine"}>
          <span>Certificate price:</span>
          <span>{(item?.operationPrices?.materialCertificatePrice/itemQuantity).toFixed(2)}€</span>
        </div>
      }
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"}>
        <span>Surface finish price:</span>
        <span>{item?.operationPrices?.coatingPricePerPiece.toFixed(2)}€</span>
      </div>
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"} style={{ fontSize: 15 }}>
        <b>Total suggested price:</b>
        <b style={{marginLeft: 5}}>{(getTotalLathingSuggestedPrice(item?.operationPrices, itemQuantity) || 0).toFixed(2)}€</b>
      </div>
    </div>
  )
}

const TubeCuttingContent = (props) => {
  const {item, itemQuantity} = props;
  return (
    <div>
      <div className={"tooltipLine"}>
        <span>Tube cutting price:</span>
        <span>{item?.operationPrices?.tubeCuttingPricePerPiece.toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Tube cutting preparation price:</span>
        <span>{(item?.operationPrices?.tubeCuttingPreparationPrice/itemQuantity).toFixed(2)}€</span>
      </div>
      <div className={"tooltipLine"}>
        <span>Total tube cutting price:</span>
        <span>{((item?.operationPrices?.tubeCuttingPricePerPiece * itemQuantity + item?.operationPrices?.tubeCuttingPreparationPrice)/itemQuantity).toFixed(2)}€</span>
      </div>
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"}>
        <span>Material price: </span>
        <span>{(item?.operationPrices?.materialPricePerPiece).toFixed(2)}€</span>
      </div>
      {item?.certNeeded &&
        <div className={"tooltipLine"}>
          <span>Certificate price:</span>
          <span>{(item?.operationPrices?.materialCertificatePrice/itemQuantity).toFixed(2)}€</span>
        </div>
      }
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"}>
        <span>Surface finish price:</span>
        <span>{item?.operationPrices?.coatingPricePerPiece.toFixed(2)}€</span>
      </div>
      <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
      <div className={"tooltipLine"} style={{ fontSize: 15 }}>
        <b>Total suggested price:</b>
        <b style={{marginLeft: 5}}>{(getTotalTubeCuttingSuggestedPrice(item?.operationPrices, itemQuantity) || 0).toFixed(2)}€</b>
      </div>
    </div>
  )
}

export const PartPriceTooltip = (props) => {

  const isProducer = useSelector(state => state.auth.user.role === "producerAdmin")

  const {producersForOrder, item, itemQuantity, handleOpenPricesModal, assemblyCount} = props;
  return (
    <CustomTooltip
      placement={"top"}
      className={"priceBubble"}
      arrow
      title={
        <div className={"priceInfoTooltip"}>
          {producersForOrder[item.selectedMaterial.producerId] &&
            <div className={"tooltipLine"} style={{ justifyContent: "center", fontSize: 14 }}>
              <b>{producersForOrder[item.selectedMaterial.producerId].name}</b>
            </div>
          }
          <div className={"tooltipLine"} style={{ justifyContent: "center", fontSize: 16 }}>
            <b>Price per 1 piece:</b>
          </div>
          {item.partType === "sheetMetal" &&
            <SheetMetalContent
              item={item}
              itemQuantity={itemQuantity}
            />
          }
          {item.partType === "milled" &&
            <MillingContent
              item={item}
              itemQuantity={itemQuantity}
            />
          }
          {item.partType === 'lathed' &&
            <LathingContent
              item={item}
              itemQuantity={itemQuantity}
            />
          }
          {item.partType === 'tubeCut' &&
            <TubeCuttingContent
              item={item}
              itemQuantity={itemQuantity}
            />
          }
          <Divider style={{ margin: 2, borderColor: "#838f94" }}/>
          {!isProducer &&
            <Button className={"d6Button"} onClick={() => {
              handleOpenPricesModal({...item, assemblyCount: assemblyCount})
            }}>
              <span style={{position: "relative", top: 1, fontWeight: 600}}>View Different Producers Prices</span>
            </Button>
          }
        </div>
      }>
      <div className={"totalPriceInfo"}
           style={{ display: "flex", alignItems: "center", marginTop: -5, justifyContent: "flex-end", color: "white" }}
      >
        <span style={{ marginRight: 5, lineHeight: 1.2, fontSize: 12 }}>1 pc</span>
        <div><InfoCircleOutlined /></div>
      </div>
    </CustomTooltip>
  )
}