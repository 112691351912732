import React, {createRef, useEffect, useState} from 'react'
import {Button, Card, Dropdown, Layout} from 'antd'
import {CalendarOutlined} from "@ant-design/icons";
import './style.less'
import {DateRangePicker} from "react-date-range";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  isSameDay,
} from 'date-fns';

const {Content, Footer} = Layout;

export const UserDateRange = (props) => {

  const { onConfirm, startDate, endDate } = props

  const [date, setDate] = useState({
    startDate,
    endDate,
    key: 'selection'
  })
  const [chosenDate, setChosenDate] = useState({
    startDate,
    endDate,
    key: 'selection'
  })
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  const dropdown = createRef();
  const dropdownButton = createRef();

  const isToday = (someDate) => {
    const today = new Date()
    return isSameDay(someDate, today)
  }

  const isYesterday = (someDate) => {
    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return isSameDay(someDate, yesterday)
  }

  const formatDate = (start_date, end_date) => {
    let format = 'en'
    if (isToday(start_date) && isToday(end_date)) {
      return 'Today'
    } else if (isYesterday(start_date) && isYesterday(end_date)) {
      return 'Yesterday'
    } else {
      const swd = (new Intl.DateTimeFormat(format, {weekday: 'short'}).format(start_date)).replace(/\.$/, "");
      const smo = new Intl.DateTimeFormat(format, {month: 'short'}).format(start_date)
      const sda = new Intl.DateTimeFormat(format, {day: '2-digit'}).format(start_date)

      const ewd = (new Intl.DateTimeFormat(format, {weekday: 'short'}).format(end_date)).replace(/\.$/, "");
      const emo = new Intl.DateTimeFormat(format, {month: 'short'}).format(end_date)
      const eda = new Intl.DateTimeFormat(format, {day: '2-digit'}).format(end_date)

      return `${swd}, ${smo} ${sda} - ${ewd}, ${emo} ${eda}`
    }
  }

  const handleResize = () => {
    setWidth(window.innerWidth)
  }


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    }
  })

  const handleClickOutside = (event) => {
    if (dropdown && dropdown.current !== null && dropdownButton && dropdownButton.current !== null) {
      if (!dropdown.current.contains(event.target) && !dropdownButton.current.contains(event.target)) {
        setDropdownVisible(false)
        setDate({...chosenDate})
      }
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        overlayClassName={"dateRangeDropdown"}
        overlay={
          <React.Fragment>
            <span ref={dropdown}>
              <Card style={{boxShadow: '5px 0 16px 0 rgba(101,101,101,.2)'}} bodyStyle={{padding: 0}}>
                <Layout>
                  <Content>
                    <DateRangePicker
                      className={"dateRangePickerStyle"}
                      onChange={item => setDate(item.selection)}
                      ranges={[date]}
                      direction={"horizontal"}
                      maxDate={new Date()}
                      showDateDisplay={false}
                      color={"#da291c"}
                      rangeColors={["#da291c"]}
                    />
                  </Content>
                  <Footer className={"dateRangePickerFooter"}>
                    <div align={'right'}>
                      <Button
                        className={'rangeButton'}
                        onClick={() => {
                          setDropdownVisible(false)
                          setDate({...chosenDate})
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        className={'rangeButton'}
                        onClick={() => {
                          setDropdownVisible(false)
                          const sd = new Date(date.startDate)
                          const ed = new Date(date.endDate)
                          setChosenDate({...date})
                          onConfirm({
                            timestampFrom: sd.getTime(),
                            timestampTo: ed.getTime()
                          })
                        }}
                        style={{marginLeft: 10}}
                        type={'primary'}
                      >
                        Apply
                      </Button>
                    </div>
                  </Footer>
                </Layout>
              </Card>
            </span>
          </React.Fragment>
        }
        placement={'bottomRight'}
        trigger={['click']}
        visible={dropdownVisible}
      >
        <Button
          type={'primary'}
          ref={dropdownButton}
          className={"dateRangeButton"}
          onClick={() => {
            setDropdownVisible(prevState => !prevState)
          }}
        >
          <CalendarOutlined style={{fontSize: 16}}/>
          {width >= 900 ?
            <span className={'rangeName'}>
              {formatDate(chosenDate.startDate, chosenDate.endDate)}
            </span>
          :
            null
          }
        </Button>

      </Dropdown>
    </React.Fragment>
  )
}
