import React, {useEffect, useState} from 'react'
import { Card, Button, Form, Input } from 'antd'

import logo from '../../assets/vectors/step2real_logo_black.svg'
import { CardTitle, Logo } from '../LoginPage/styles'


import {http, message} from "../../utils";
import {API_BASE_URL} from "../../constants";
import { LockOutlined } from "@ant-design/icons";
import {useWindowSize} from "../../utils/utility";
import history from "../../utils/history";

export const ResetPassword = () => {

  const [token, setToken] = useState(new URLSearchParams(history.location.search).get('token'));
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const windowSize = useWindowSize();

  useEffect(() => {
    const token = new URLSearchParams(history.location.search).get('token')
    if (token === null) {
      history.push('/login')
    }
    else {
      setToken(token);
    }
  }, [])

  const handleClick = (values) => {
    setIsResettingPassword(true);
    http
      .post(`${API_BASE_URL}/auth/forgot-password`, {newPassword: values.repeatPassword, token: token})
      .then(() => {
        setIsResettingPassword(false);
        message.show(message.type.success, 'Password changed successfully!')
        history.push('/login')
      })
      .catch(error => {
        setIsResettingPassword(false);
        message.show(message.type.error, http.getErrorMessage(error))
      })
  }

  return (
    <div className={"publicWrapper"}>
      <div className={windowSize.width > 800 ? "tiledBackgroundWide" : "tiledBackgroundNarrow"}>
        <Card className={"publicCard"}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center",  height: 200}}>
            <Logo src={logo} alt={"Step2Real"}/>
          </div>
          <CardTitle className={"resendText"}>Reset Password</CardTitle>
          <Form
            name={'password-reset'}
            onFinish={handleClick}
          >
            <span className={"loginLabel"}>New password</span>
            <Form.Item
              name={'password'}
              rules={[
                {required: true, message: 'This field is required'},
                {type: 'string', min: 6 ,message: 'Password must be 6 characters or longer'},
                {type: 'string', max: 60 ,message: 'Password must be 60 characters or shorter'},
              ]}
              validateTrigger={'onSubmit'}
            >
              <Input.Password
                className={"loginInputText"}
                prefix={<LockOutlined />}
                placeholder="Enter your new password"
                autoComplete="new-password"
              />
            </Form.Item>
            <span className={"loginLabel"}>Repeat new password</span>
            <Form.Item
              name={'repeatPassword'}
              rules={[
                {required: true, message: 'This field is required'},
                {type: 'string', min: 6 ,message: 'Password must be 6 characters or longer'},
                {type: 'string', max: 60 ,message: 'Password must be 60 characters or shorter'},
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The passwords that you entered do not match!');
                  },
                }),
              ]}
              validateTrigger={'onSubmit'}
            >
              <Input.Password
                className={"loginInputText"}
                prefix={<LockOutlined />}
                placeholder="Enter your new password again"
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: '100%', marginTop: 15}}
                loading={isResettingPassword}
                type="primary"
                htmlType="submit"
                className={"blackButton"}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  )
}
