import {
  CUSTOMER_RELATIONS_INTERNAL_PAGINATION,
  CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
  CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS,
  CUSTOMER_RELATIONS_SET_INTERNAL_RELATIONS,
  CUSTOMER_FETCHING_UNAUTHORIZED_CUSTOMERS,
  CUSTOMER_SET_UNAUTHORIZED_CUSTOMERS, CUSTOMER_SET_IS_AUTHORIZING_CUSTOMER
} from './actionTypes'
import { API_BASE_URL } from '../constants'
import { http, message } from '../utils'
import {forceLogoutUser} from "./authActions";

const fetchInternalRelations = payload => dispatch => {
  dispatch({
    type: CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/customer-relations?pageRowCount=${payload.pagination.pageSize}&pageIndex=${payload.page}&email=${payload.email}&name=${payload.name}`)
    .then(response => {

      const pagination = { ...payload.pagination };
      pagination.total = response.data.totalCount;

      dispatch({
        type: CUSTOMER_RELATIONS_INTERNAL_PAGINATION,
        payload: pagination,
      })

      dispatch({
        type: CUSTOMER_RELATIONS_SET_INTERNAL_RELATIONS,
        payload: response.data.customers,
      })

      dispatch({
        type: CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS,
        payload: false,
      })

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: CUSTOMER_RELATIONS_SET_FETCHING_INTERNAL_RELATIONS,
          payload: false,
        })
      }
    })
}

const changeCustomerStatus = payload => dispatch => {
  return http
    .post(`${API_BASE_URL}/customer-relations/block/${payload.customerId}`, {isBlocked: payload.isBlocked})
    .then(() => {

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })
}

const changeCustomerLimit = payload => dispatch => {
  dispatch({
    type: CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
    payload: true,
  })

  return http
    .put(`${API_BASE_URL}/customer-relations/limit/${payload.customerId}`, {limited: payload.limited, partLimit: payload.partLimit})
    .then(() => {
      dispatch({
        type: CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
          payload: false,
        })
        throw error
      }
    })
}


const changeCustomerAdjustment = payload => dispatch => {
  dispatch({
    type: CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
    payload: true,
  })

  return http
    .put(`${API_BASE_URL}/customer-relations/adjustment/${payload.customerId}`, {adjustment: payload.adjustment})
    .then(() => {
      dispatch({
        type: CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
          payload: false,
        })
        throw error
      }
    })
}

const fetchPendingCustomers = () => dispatch => {
  dispatch({
    type: CUSTOMER_FETCHING_UNAUTHORIZED_CUSTOMERS,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/customer-relations/pending`)
    .then(response => {

      dispatch({
        type: CUSTOMER_SET_UNAUTHORIZED_CUSTOMERS,
        payload: response.data.data
      })

      dispatch({
        type: CUSTOMER_FETCHING_UNAUTHORIZED_CUSTOMERS,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: CUSTOMER_FETCHING_UNAUTHORIZED_CUSTOMERS,
          payload: false
        })
      }
    })
}

const reloadPendingCustomers = () => dispatch => {
  return http
    .get(`${API_BASE_URL}/customer-relations/pending`)
    .then(response => {
      dispatch({
        type: CUSTOMER_SET_UNAUTHORIZED_CUSTOMERS,
        payload: response.data.data
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const authorizeCustomer = payload => dispatch => {
  dispatch({
    type: CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
    payload: true,
  })

  dispatch({
    type: CUSTOMER_SET_IS_AUTHORIZING_CUSTOMER,
    payload: true
  })

  return http
    .put(`${API_BASE_URL}/customer-relations/authorize-customer/${payload.customerId}`, {isAuthorized: payload.isAuthorized})
    .then(() => {

      dispatch({
        type: CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
        payload: false,
      })

      dispatch({
        type: CUSTOMER_SET_IS_AUTHORIZING_CUSTOMER,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: CUSTOMER_RELATIONS_SET_IS_UPDATING_INTERNAL_CUSTOMER,
          payload: false,
        })
        dispatch({
          type: CUSTOMER_SET_IS_AUTHORIZING_CUSTOMER,
          payload: false
        })
        throw error
      }
    })
}



export {
  changeCustomerStatus,
  changeCustomerLimit,
  changeCustomerAdjustment,
  fetchInternalRelations,
  fetchPendingCustomers,
  authorizeCustomer,
  reloadPendingCustomers
}
