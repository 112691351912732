import React from 'react'
import {Row, Layout, Menu, Button} from 'antd'

import history from "../../../utils/history";

import "./style.css"
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SnippetFolderOutlinedIcon from "@mui/icons-material/SnippetFolderOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {collapseSidebar, updateActiveCustomerSubmenuKeys} from "../../../actions/utilActions";

const SubMenu = Menu.SubMenu

let selectedField = '1';

function activeComponent(history) {
  switch (history.location.pathname)
  {
    case('/'):
      selectedField = '1';
      break;
    case('/pending-order'):
    case('/pending-orders'):
      selectedField = '2';
      break;
    case('/awaiting-order'):
    case('/awaiting-orders'):
      selectedField = '3';
      break;
    case('/processed-order'):
    case('/processed-orders'):
      selectedField = '4';
      break
    case('/customer-settings'):
    case('/corporate-settings'):
      selectedField = '5';
      break;
    case('/company-settings'):
      selectedField = '6';
      break;
    default:
      break;
  }
  return selectedField;
}

export const Sidebar = (props) => {

  const dispatch = useDispatch();

  const activeCustomerSubmenuKeys = useSelector(state => state.util.activeCustomerSubmenuKeys);

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const onCollapse = () => {
    dispatch(collapseSidebar(!isCollapsed));
  }

  const { onLogout, user } = props

  return(
    <Layout.Sider
      style={{
        overflow: 'auto',
        minHeight: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 1000,
      }}
      collapsed={isCollapsed}
    >
      <Button className={"collapseButton"}
              style={{ left: isCollapsed ? '50px' : '190px'}}
              onClick={() => onCollapse()}
      >
        { isCollapsed ?
          <RightOutlined style={{ fontSize: 14, position: "relative", top: 1 }}/>
          :
          <LeftOutlined style={{ fontSize: 14, position: "relative", top: 1  }}/>
        }
      </Button>
      <Row style={{marginTop: '2rem', height: "100%"}}>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys = {[activeComponent(history)]}
          defaultOpenKeys={isCollapsed ? [] : activeCustomerSubmenuKeys}
        >
          <Menu.Item
            key="1"
            onClick={() => {
              history.push('/')
            }}
            title={<span className="nav-text">Dashboard</span>}
            style={{ paddingLeft: 22 }}
          >
            <a href={"/"} onClick={(e) => e.preventDefault()}>
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <DashboardOutlinedIcon style={{fontSize: 22}}/>
                <span
                  className={"menuLine"}
                  style={{opacity: isCollapsed ? 0 : 1}}
                >Dashboard</span>
              </div>
            </a>
          </Menu.Item>
          <SubMenu
            key="sub1"
            className={"subMenuC"}
            title={
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <PendingActionsIcon style={{fontSize: 21}}/>
                <span
                  className={"menuLine"}
                  style={{opacity: isCollapsed ? 0 : 1}}
                >Pending Quotes</span>
              </div>
            }
            onTitleClick={() => {
              dispatch(updateActiveCustomerSubmenuKeys('sub1'))
            }}
          >
            <Menu.Item
              key="2"
              onClick={() => {
                history.push('/pending-orders')
              }}
            >
              <a href={"/pending-orders"} onClick={(e) => e.preventDefault()}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <div style={{display: "flex", marginLeft: -2}}>
                    <PendingActionsIcon style={{fontSize: 20}}/>
                  </div>
                  <div style={{marginLeft: 7}}>
                    <span className="nav-text">Pending</span>
                  </div>
                </div>
              </a>
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => {
                history.push('/awaiting-orders')
              }}
            >
              <a href={"/awaiting-orders"} onClick={(e) => e.preventDefault()}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <div style={{display: "flex", marginLeft: -2}}>
                    <SnippetFolderOutlinedIcon style={{fontSize: 20}}/>
                  </div>
                  <div style={{marginLeft: 7}}>
                    <span className="nav-text">Awaiting</span>
                  </div>
                </div>
              </a>
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            key="4"
            onClick={() => {
              if(user?.role === 'regularCustomer') {
                history.push('/processed-orders')
              }
              else {
                history.push('/processed-orders')
              }
            }}
            title={<span className="nav-text">Processed Quotes</span>}
            style={{ paddingLeft: 22 }}
          >
            <a href={"/processed-orders"} onClick={(e) => e.preventDefault()}
            >
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <InventoryOutlinedIcon style={{fontSize: 21}}/>
                <span
                  className={"menuLine"}
                  style={{opacity: isCollapsed ? 0 : 1}}
                >Processed Quotes</span>
              </div>
            </a>
          </Menu.Item>

          <Menu.Item
            key="5"
            onClick={() => {
              if(user?.role === 'regularCustomer') {
                history.push('/customer-settings')
              }
              else {
                history.push('/corporate-settings')
              }
            }}
            title={<span className="nav-text">Account Settings</span>}
            style={{ paddingLeft: 22 }}
          >
            <a href={`/${user?.role === 'regularCustomer' ? "customer-settings" : "corporate-settings"}`}
               onClick={(e) => e.preventDefault()}
            >
              <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
                <SettingsOutlinedIcon style={{fontSize: 22}}/>
                <span
                  className={"menuLine"}
                  style={{opacity: isCollapsed ? 0 : 1}}
                >Account Settings</span>
              </div>
            </a>
          </Menu.Item>
        </Menu>
        <Menu
          theme="dark"
          mode="inline"
          style={{position: "absolute", bottom: 20}}
        >
          <Menu.Item
            key="6"
            onClick={onLogout}
            title={<span className="nav-text">Sign out</span>}
            style={{ paddingLeft: 22 }}
          >
            <div align={'center'} style={{display: "flex", alignItems: "center", height: '100%', width: "100%", marginLeft: -3}}>
              <LogoutOutlinedIcon style={{fontSize: 22}}/>
              <span
                className={"menuLine"}
                style={{opacity: isCollapsed ? 0 : 1}}
              >Sign Out</span>
            </div>
          </Menu.Item>
        </Menu>
      </Row>
    </Layout.Sider>
  )
}

