import {
  SURFACE_COATING_SET_DATA,
  SURFACE_COATING_IS_FETCHING_DATA,
  SURFACE_COATING_SET_SURFACE_COATING_SERVICE,
  SURFACE_COATING_SET_PAINTING_SERVICE,
  SURFACE_COATING_SET_POWDER_PAINTING_SERVICE,
  SURFACE_COATING_SET_GALVANIZING_SERVICE,
  SURFACE_COATING_SET_NICKEL_PLATING_SERVICE,
  SURFACE_COATING_SET_PAINTING_DATA,
  SURFACE_COATING_SET_POWDER_PAINTING_DATA,
  SURFACE_COATING_SET_GALVANIZING_DATA,
  SURFACE_COATING_SET_NICKEL_PLATING_DATA,
  SURFACE_COATING_SET_ANODIZING_SERVICE,
  SURFACE_COATING_SET_ANODIZING_DATA,
  SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_SERVICE,
  SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_DATA,
  SURFACE_COATING_SET_HEAT_TREATMENT_SERVICE,
  SURFACE_COATING_SET_BLASTING_SERVICE,
  SURFACE_COATING_SET_GLASS_BEAD_BLASTING_DATA,
  SURFACE_COATING_SET_BLASTING_DATA,
  SURFACE_COATING_SET_HEAT_TREATMENT_DATA,
} from "./actionTypes";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";

const fetchSurfaceCoatingData = () => dispatch => {
  dispatch({
    type: SURFACE_COATING_IS_FETCHING_DATA,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/surface-coating`)
    .then(response => {
      dispatch({
        type: SURFACE_COATING_SET_DATA,
        payload: response.data,
      })

      dispatch({
        type: SURFACE_COATING_IS_FETCHING_DATA,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: SURFACE_COATING_IS_FETCHING_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSurfaceCoatingService = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_SURFACE_COATING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/surface-coating`, {hasSurfaceCoating: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSurfaceCoatingPainting = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_PAINTING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/surface-coating/has-painting`, {hasPainting: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSurfaceCoatingPowderPainting = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_POWDER_PAINTING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/surface-coating/has-powder-painting`, {hasPowderPainting: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSurfaceCoatingGalvanizing = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_GALVANIZING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/surface-coating/has-galvanizing`, {hasGalvanizing: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSurfaceCoatingNickelPlating = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_NICKEL_PLATING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/surface-coating/has-nickel-plating`, {hasNickelPlating: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSurfaceCoatingAnodizing = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_ANODIZING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/surface-coating/has-anodizing`, {hasAnodizing: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSurfaceCoatingGalvanicZincPlating = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/surface-coating/has-galvanic-zinc-plating`, {hasGalvanicZincPlating: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSurfaceCoatingHeatTreatment = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_HEAT_TREATMENT_SERVICE,
    payload: payload.value
  })

  return http
    .put(`${API_BASE_URL}/surface-coating/has-heat-treatment`, {hasHeatTreatment: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setSurfaceCoatingBlasting = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_BLASTING_SERVICE,
    payload: payload.value
  })

  return http
    .put(`${API_BASE_URL}/surface-coating/has-blasting`, {hasBlasting: payload.value})
    .then(() => {})
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setPaintingData = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_PAINTING_DATA,
    payload: payload,
  })
}

const setPowderPaintingData = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_POWDER_PAINTING_DATA,
    payload: payload,
  })
}

const setGalvanizingData = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_GALVANIZING_DATA,
    payload: payload,
  })
}

const setNickelPlatingData = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_NICKEL_PLATING_DATA,
    payload: payload,
  })
}

const setAnodizingData = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_ANODIZING_DATA,
    payload: payload,
  })
}

const setGalvanicZincPlatingData = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_GALVANIC_ZINC_PLATING_DATA,
    payload: payload,
  })
}

const setBlastingData = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_BLASTING_DATA,
    payload: payload
  })
}

const setHeatTreatmentData = payload => dispatch => {
  dispatch({
    type: SURFACE_COATING_SET_HEAT_TREATMENT_DATA,
    payload: payload
  })
}


export {
  fetchSurfaceCoatingData,
  setSurfaceCoatingService,
  setSurfaceCoatingPainting,
  setSurfaceCoatingPowderPainting,
  setSurfaceCoatingGalvanizing,
  setSurfaceCoatingNickelPlating,
  setSurfaceCoatingAnodizing,
  setSurfaceCoatingGalvanicZincPlating,
  setSurfaceCoatingHeatTreatment,
  setSurfaceCoatingBlasting,
  setPaintingData,
  setPowderPaintingData,
  setGalvanizingData,
  setNickelPlatingData,
  setAnodizingData,
  setGalvanicZincPlatingData,
  setBlastingData,
  setHeatTreatmentData
}
