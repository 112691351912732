import {toast} from "react-toastify";
import {CheckCircleOutlined,CloseCircleOutlined,InfoCircleOutlined,WarningOutlined} from "@ant-design/icons";
import React from "react";

const type = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
}

const getIcon = (type) => {
  if (type === "error") {
    return <CloseCircleOutlined style={{ fontSize: '18px', color: "#e33e33" }} />
  }
  if (type === "success") {
    return <CheckCircleOutlined style={{ fontSize: '18px', color: "#04b10b" }} />
  }
  if (type === "info") {
    return <InfoCircleOutlined style={{ fontSize: '18px', color: "#2c8ad4" }} />
  }
  if (type === "warning") {
    return <WarningOutlined style={{ fontSize: '18px', color: "#edb90f" }} />
  }
}

const show = (type, content) => {
  toast[type](content, {
    toastId: type,
    theme: "dark",
    position: "top-center",
    icon: getIcon(type),
    className: "toast-className"
  })
}

export default {
  show,
  type,
}
