import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./style.less";

import {
  Row, Col, Card,
  Divider, Switch,
  Tabs, Modal, Spin
} from "antd";

import MaterialTableContainer from "../../../CompanyMaterials/MaterialTableContainer";
import {ModalTable} from "../../../CompanyMaterials/MaterialTables/ModalTable";

import {ProducerMaterialInput} from "../../NonEditableFields";
import {Trans} from "react-i18next";
import {LoadingOutlined} from "@ant-design/icons";
import {EmptyTable} from "../../../../EmptyTable";
import {
  fetchProducerBendingData,
  fetchProducerSingleMaterialBendsData,
  resetProducerBendsData
} from "../../../../../actions/producerParamsActions";

const { TabPane } = Tabs;

export const MaterialBendingTab = (props) => {
  const { selectedTab, producerId } = props

  const [shouldUpdateBends, setShouldUpdateBends] = useState(false)
  const [mainModalVisible, setMainModalVisible] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState(null);
  const [selectedFieldData, setSelectedFieldData] = useState({});

  const bendingData = useSelector(state => state.producerParams.bendingData);
  const bendingBendsData = useSelector(state => state.producerParams.bendingBendsData);
  const isFetchingSingleMaterialBends = useSelector(state => state.producerParams.isFetchingSingleMaterialBends);

  const dispatch = useDispatch()

  useEffect(()=>{
    if(selectedTab === "2") {
      dispatch(fetchProducerBendingData({producerId: producerId})).then(()=>{
        setShouldUpdateBends(true)
      })
    }
  }, [selectedTab])

  const setupMainModal = (params) => {
    setSelectedFieldData({
      materialGrade: params.materialGrade,
      materialThickness: params.materialThickness,
      bendsMaterialId: params.materialId,
      bendsGroupName: params.groupName,
      tab: params.tab
    })

    dispatch(fetchProducerSingleMaterialBendsData({
      materialId: params.materialId,
      producerId: producerId
    }))
    setMainModalVisible(true);
  }

  const handleMainModalClose = () => {
    setMainModalVisible(false);
    dispatch(resetProducerBendsData())
  }

  const handleBendModalOpen = (params) => {
    setSelectedDataType("bends")
    setupMainModal(params)
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0}}>
        <div style={{height: 'calc(100vh - 172px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          {bendingData && Object.keys(bendingData).length === 0 ? <div/>
            :
            <div>
              <Row>
                <Col xxl={12} span={24}>
                  <div className={"switchContainer"}>
                    <div>
                      <span style={{marginRight: 30, marginBottom: 6}}>Producer offers Bending service?</span>
                      <span>No</span>
                      <Switch
                        checked={bendingData.hasBending}
                        style={{marginLeft: 6, marginRight: 6}}
                        className={"producersUneditableSwitch"}
                        disabled={true}
                      />
                      <span>Yes</span>
                    </div>
                  </div>
                </Col>
              </Row>

              <Divider style={{width: "75%", minWidth: "0%", margin: "14px 0 8px 0"}}/>
              {bendingData.hasBending &&
              <div>
                <Row>
                  <Col xxl={12} span={24}>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <ProducerMaterialInput
                        style={{ marginTop: 10, marginBottom: 10 }}
                        label={"Maximum bending length"}
                        value={bendingData.maxLength}
                        suffix={"mm"}
                      />
                    </div>
                  </Col>
                </Row>

                <Divider style={{width: "100%", minWidth: "0%", margin: "8px 0 30px 0"}}/>

                <div className={"newLine"}>
                  <div className={"customColumn"}>
                    <div>
                      <Tabs style={{marginLeft: -10, marginBottom: 20}}
                            type="card"
                            className={"laserSpeedTabs"}
                      >
                        {bendingData.bends.map((item, index) => (
                          <TabPane className={"materialsTabPane"} tab={item.groupName} key={index}>
                            <MaterialTableContainer tableText={"Cost of bending per punch and material group (€/punch)"}
                                                    xAxisText={"Available Thickness (mm)"}
                                                    yAxisText={"Material Grade"}
                                                    xAxisStyle={{marginTop: 12, marginBottom: -12}}
                            >
                              <ModalTable
                                columns={item.columns}
                                rows={item.rows}
                                allAvailable={item.allAvailable}
                                tabIndex={index}
                                onClick={handleBendModalOpen}
                                groupName={item.groupName}
                                updateTable={shouldUpdateBends}
                                setUpdateTable={setShouldUpdateBends}
                                activeLoadingTabs={{}}
                                referenceHeight={248}
                                isEditable={false}
                              />
                            </MaterialTableContainer>
                          </TabPane>
                        ))}
                      </Tabs>
                    </div>
                  </div>
                </div>

                <Divider className={"lightDivider"} style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 0"}}/>

                <Row gutter={16}>
                  <Col xxl={12} span={24}>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <ProducerMaterialInput
                        style={{ marginTop: 10, marginBottom: 10 }}
                        label={"Preparation price"}
                        value={bendingData.preparationPrice}
                        suffix={"€/h"}
                      />
                    </div>

                    <div style={{display: "flex", alignItems: "center"}}>
                      <ProducerMaterialInput
                        style={{ marginTop: 10, marginBottom: 10 }}
                        label={"Average preparation time"}
                        value={bendingData.averagePreparationTime}
                        suffix={"min"}
                      />
                    </div>

                    <Divider className={"lightDivider"} style={{width: "50%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
                  </Col>
                </Row>
              </div>
              }
            </div>
          }
        </div>
      </Card>

      <Modal visible={mainModalVisible}
             onCancel={handleMainModalClose}
             title={selectedDataType === "bends" ? "Available bends" : "Available radii"}
             footer={<div style={{ height: 20 }}/>}
             centered={true}
             bodyStyle={{ height: 660 }}
             destroyOnClose={true}
             zIndex={1}
             width={550}
      >
        <React.Fragment>
          <div style={{ fontWeight: 500 }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "white"}}>
              <div>
                <div>Material Grade: <Trans>{selectedFieldData.materialGrade}</Trans></div>
                <div>Material Thickness: {selectedFieldData.materialThickness} mm</div>
              </div>
            </div>
            <Spin spinning={isFetchingSingleMaterialBends}
                  indicator={<LoadingOutlined style={{ fontSize: 48, transform: "translate(-25%, -50%)" }} spin />}
            >
              <div className={"modalDataContainer"}>
                {!bendingBendsData || bendingBendsData.length === 0 ?
                  (
                    <div style={{position: "relative", top: "25%"}}>
                      <EmptyTable text={"No Bends"}/>
                    </div>
                  )
                  :
                  bendingBendsData.map(item => (
                    <div className={"checkboxStyle"} key={item.id}>
                      <Card className={"bendCard"}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <div>
                            <div>Length: {item.length} mm</div>
                            <div>Price per bend: {item.price} €</div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))
                }
              </div>
            </Spin>
          </div>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  )
}