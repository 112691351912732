import {
    AUTH_SET_USER,
    CUSTOMER_SETTINGS_SET_EDITING_CUSTOMER_SETTINGS,
    CUSTOMER_SETTINGS_SET_RESETTING_PASSWORD,
} from './actionTypes'
import { API_BASE_URL } from '../constants'
import { http, message, storage } from '../utils'
import {forceLogoutUser} from "./authActions";

const editAccountDetails = payload => dispatch => {
  dispatch({
    type: CUSTOMER_SETTINGS_SET_EDITING_CUSTOMER_SETTINGS,
    payload: true,
  })
  return http
    .put(`${API_BASE_URL}/${payload.type}`, payload.data)
    .then(response => {
      message.show(
        message.type.success,
        'Successfully updated account information.'
      )
      dispatch({
        type: AUTH_SET_USER,
        payload: response.data,
      })
      dispatch({
        type: CUSTOMER_SETTINGS_SET_EDITING_CUSTOMER_SETTINGS,
        payload: false,
      })
      storage.updateUser(payload.data)
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: CUSTOMER_SETTINGS_SET_EDITING_CUSTOMER_SETTINGS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const changePassword = payload => dispatch => {
  dispatch({
    type:CUSTOMER_SETTINGS_SET_RESETTING_PASSWORD,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/auth/change-password`, payload)
    .then(() => {
      dispatch({
        type:CUSTOMER_SETTINGS_SET_RESETTING_PASSWORD,
        payload: false
      })
      message.show(message.type.success, 'Successfully changed password.')
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type:CUSTOMER_SETTINGS_SET_RESETTING_PASSWORD,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const deleteAccount = payload => dispatch => {
    return http
        .delete(`${API_BASE_URL}/naturalPersons/${payload.customer_id}`)
        .then(() => {
            dispatch(payload.logout_user)
            message.show(message.type.success, 'Successfully deleted customer.')
        })
        .catch(error => {
            message.show(message.type.error, http.getErrorMessage(error))
        })
}

export { editAccountDetails, changePassword, deleteAccount }
