import React, { Fragment, useEffect, useState} from 'react';
import {Layout, Button, Col, Row, Table, Input, Modal, Divider, Dropdown, Menu, Checkbox, Spin} from 'antd';
import {logoutUser} from '../../../actions/authActions';
import { AdminSidebar} from '../../../components/admin';
import {useDispatch, useSelector} from 'react-redux';
import {
  toggleCustomerBlock,
  fetchCustomerDetails,
  fetchCustomers,
} from "../../../actions/customersActions";
import http from "../../../utils/http";
import {API_BASE_URL} from "../../../constants";
import {LoadingOutlined, MoreOutlined} from "@ant-design/icons";
import { getColumnSearchProps } from "../../../utils/utility";
import {EmptyTable} from "../../../components/EmptyTable";

// TODO UNUSED
export const AllCustomers = (props) => {
  const customers = useSelector(state => state.customers.customers);
  const fetchingCustomers = useSelector(state => state.customers.fetchingCustomers);
  const pagination = useSelector(state => state.customers.customersPagination)
  const fetchingCustomerDetails = useSelector(state => state.customers.fetchingCustomerDetails)
  const togglingCustomerBlock = useSelector(state => state.customers.togglingCustomerBlock)
  const isCollapsed = useSelector(state => state.util.isCollapsed);

  const [details, setDetails] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isBlockVisible, setIsBlockVisible] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isUnblockVisible, setIsUnblockVisible] = useState(false);
  const [isUnblocking, setIsUnblocking] = useState(false);
  const [actionId, setActionId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState(null);

  const dispatch = useDispatch();

  let searchInput = null;

  useEffect(() => {
    initializeData()
  }, [])


  const handleClose = () => {
    setIsVisible(false);
    setDetails(null);
  }

  const initializeData = () => {
    dispatch(fetchCustomers({
        logout_user: logoutUser,
        page: pagination.current !== undefined ? pagination.current : 1,
        pagination: pagination,
        email: "",
        name: ""
      }
    ))
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    dispatch(fetchCustomers({
      logout_user: logoutUser,
      page: pagination.current,
      pagination: pager,
      email: filters.email || "",
      name: filters.name || ""
    }))
  }
  
  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <AdminSidebar onLogout={()=>{dispatch(logoutUser())}} />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
          <Row type={"flex"}>
            <Col span={24}>
              <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                <div className={"pageTitle"}>Registered regular users:</div>
              </div>
              <Divider style={{marginTop: 6, marginBottom: 11}}/>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <Fragment>
                <Table
                  columns={[
                    {
                      title: 'Name',
                      className: "darkerColumn",
                      key: 'name',
                      render: row => (
                        <div>
                          {row.name}
                        </div>
                      ),
                      ...getColumnSearchProps('name', searchInput),
                    },
                    {
                      title: 'Email',
                      key: 'email',
                      render: row => (
                        <div>
                          {row.email}
                        </div>
                      ),
                      ...getColumnSearchProps('email', searchInput),
                    },
                    {
                      title: 'Country',
                      className: "darkerColumn",
                      key: 'country',
                      render: row => (
                        <div>
                          {row.country}
                        </div>
                      ),
                      ...getColumnSearchProps('country', searchInput),
                    },
                    {
                      title: "Blocked",
                      align: 'center',
                      key: "isBlocked",
                      render: row => (
                        <div className={row.isBlocked ? "trueDisabledCheckbox" : "falseDisabledCheckbox"}>
                          <Checkbox checked={row.isBlocked}
                                    disabled
                          />
                        </div>
                      )
                    },
                    {
                      title: 'Details',
                      key: 'details',
                      className: "darkerColumn",
                      align: 'center',
                      render: row => {
                        return (
                          <Button
                            onClick={() => {
                              dispatch(fetchCustomerDetails({id: row.id}))
                                .then(res => {setDetails(res.data)})
                                .catch(() => {setDetails(null)})
                              setIsVisible(true);
                            }}
                            type="primary">
                            <span style={{fontWeight: 600, fontSize: 13}}>Details</span>
                          </Button>)
                      },
                    },
                    {
                      title: 'Action',
                      key: 'action',
                      align: 'center',
                      render: row => {
                        return (
                          <Button
                            disabled={togglingCustomerBlock}
                            onClick={() => {dispatch(toggleCustomerBlock({id: row.id, isBlocked: !row.isBlocked}))}}
                            type="danger"
                            style={{ width: 88}}
                          >
                            <span style={{fontWeight: 600, fontSize: 13}}>{row.isBlocked ? "Unblock" : "Block"}</span>
                          </Button>
                        )
                      },
                    }
                  ]}
                  className={"antTable"}
                  dataSource={customers}
                  rowKey="id"
                  pagination={pagination}
                  loading={{spinning: fetchingCustomers, indicator: <LoadingOutlined style={{ fontSize: 50,marginLeft: "-25px" }} spin />}}
                  onChange={handleTableChange}
                  size={"small"}
                  scroll={{x: '70%'}}
                  locale={{ emptyText: <EmptyTable text={"No Data"} /> }}
                />
                <Modal
                  onCancel={handleClose}
                  title="Details"
                  visible={isVisible}
                  footer={null}
                  centered={true}
                  bodyStyle={{ height: 260 }}
                >
                  {fetchingCustomerDetails || details === null ? (
                    <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                      <Spin indicator={<LoadingOutlined style={{fontSize: 90}} spin/>}/>
                    </div>
                  ) : (
                    <Fragment>
                      <Row type={"flex"}>
                        <Col span={14}>
                          Email:
                          <h3>{details.email}</h3>
                          Name:
                          <h3>{details.name}</h3>
                          Address:
                          <h3>{details.address}</h3>
                          Phone number:
                          <h3>{details.phone}</h3>
                        </Col>
                        <Col span={10}>
                          City:
                          <h3>{details.city}</h3>
                          Postal Code:
                          <h3>{details.postalCode}</h3>
                          Region:
                          <h3>{details.region}</h3>
                          Country:
                          <h3>{details.country}</h3>
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </Modal>

                <Modal
                  title="Block"
                  centered={true}
                  visible={isBlockVisible}
                  onCancel={() => setIsBlockVisible(false)}
                  footer={[
                    <Button key="cancel" onClick={() => setIsBlockVisible(false)}>Cancel</Button>,
                    <Button
                      key="submit"
                      type="danger"
                      loading={isBlocking}
                      onClick={ () => {
                        setIsBlocking(true);
                        http
                          .post(
                            `${API_BASE_URL}/users/${actionId}/block`
                          )
                          .then(() => {
                            setActionId(null);
                            setIsBlocking(false);
                            setIsBlockVisible(false);
                            initializeData();
                          })
                      }}>
                      Block
                    </Button>,
                  ]}
                >
                  <h3>Are you sure you want to BLOCK this user ? </h3>
                </Modal>

                <Modal
                  title="Unblock"
                  centered={true}
                  visible={isUnblockVisible}
                  onCancel={() => setIsUnblockVisible(false)}
                  footer={[
                    <Button key="cancel" onClick={() => setIsUnblockVisible(false)}>Cancel</Button>,
                    <Button
                      key="submit"
                      type="danger"
                      loading={isUnblocking}
                      onClick={ () => {
                        setIsUnblocking(true);
                        http
                          .post(
                            `${API_BASE_URL}/users/${actionId}/unblock`
                          )
                          .then(() => {
                            setActionId(null);
                            setIsUnblocking(false);
                            setIsUnblockVisible(false);
                            initializeData();
                          })
                      }}>
                      Unblock
                    </Button>,
                  ]}
                >
                  <h3>Are you sure you want to UNBLOCK this user ? </h3>
                </Modal>

                <Modal
                  title="Delete"
                  centered={true}
                  visible={isDeleteVisible}
                  onCancel={() => setIsDeleteVisible(false)}
                  footer={[
                    <Button key="cancel" onClick={() => setIsDeleteVisible(false)}>Cancel</Button>,
                    <Button
                      key="submit"
                      type="danger"
                      loading={isDeleting}
                      onClick={ () => {
                        setIsDeleting(true);
                        http
                          .delete(
                            `${API_BASE_URL}/naturalPersons/${deleteId}`
                          )
                          .then(() => {
                            setDeleteId(null);
                            setIsDeleting(false);
                            setIsDeleteVisible(false);
                            initializeData();
                          })
                      }}>
                      Delete
                    </Button>,
                  ]}
                >
                  <h3>Are you sure you want to DELETE {deleteEmail} ? </h3>
                </Modal>

              </Fragment>
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  )
}