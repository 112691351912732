import initialState from "./initialState";
import {
  PRODUCER_PARAMS_IS_FETCHING_BENDING_DATA,
  PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINE_DATA,
  PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINES,
  PRODUCER_PARAMS_IS_FETCHING_MACHINE_PRIORITIES,
  PRODUCER_PARAMS_IS_FETCHING_MATERIAL_PRICES,
  PRODUCER_PARAMS_IS_FETCHING_SIMPLE_MACHINING_DATA,
  PRODUCER_PARAMS_IS_FETCHING_SINGLE_MATERIAL_BENDS,
  PRODUCER_PARAMS_IS_FETCHING_SURFACE_COATING,
  PRODUCER_PARAMS_SET_BENDING_DATA,
  PRODUCER_PARAMS_SET_CUTTING_MACHINES,
  PRODUCER_PARAMS_SET_MACHINE_DATA,
  PRODUCER_PARAMS_SET_MACHINE_PRIORITIES,
  PRODUCER_PARAMS_SET_MATERIAL_PRICES,
  PRODUCER_PARAMS_SET_SIMPLE_MACHINING_DATA,
  PRODUCER_PARAMS_SET_SINGLE_MATERIAL_BENDS,
  PRODUCER_PARAMS_SET_SURFACE_COATING_DATA,
  PRODUCER_PARAMS_RESET_PRODUCER_PARAMS,
  PRODUCER_PARAMS_IS_FETCHING_MACHINING_DATA,
  PRODUCER_PARAMS_SET_MACHINING_DATA,
  PRODUCER_PARAMS_IS_FETCHING_MILLING_MACHINE_DATA,
  PRODUCER_PARAMS_SET_MILLING_MACHINE_DATA,
  PRODUCER_PARAMS_IS_FETCHING_LATHING_MACHINE_DATA,
  PRODUCER_PARAMS_SET_LATHING_MACHINE_DATA,
  PRODUCER_PARAMS_IS_FETCHING_MACHINING_MATERIALS,
  PRODUCER_PARAMS_SET_MACHINING_MATERIALS,
  PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_DATA,
  PRODUCER_PARAMS_SET_TUBE_CUTTING_DATA,
  PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_MACHINE_DATA,
  PRODUCER_PARAMS_SET_TUBE_CUTTING_MACHINE_DATA,
  PRODUCER_PARAMS_SET_TUBE_MATERIAL_PRICES,
  PRODUCER_PARAMS_IS_FETCHING_TUBE_MATERIAL_PRICES,
  PRODUCER_PARAMS_IS_FETCHING_STANDARD_TUBE_MATERIAL_PRICES,
  PRODUCER_PARAMS_SET_STANDARD_TUBE_MATERIAL_PRICES,
  PRODUCER_PARAMS_IS_FETCHING_MATERIAL_CERTIFICATES,
  PRODUCER_PARAMS_SET_MATERIAL_CERTIFICATES,
} from "../actions/actionTypes";

const producerParamsReducer = (state = initialState.producerParams, action) => {
  switch(action.type) {
    case PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINE_DATA:
      return {
        ...state,
        isFetchingSelectedMachine: action.payload
      }

    case PRODUCER_PARAMS_IS_FETCHING_CUTTING_MACHINES:
      return {
        ...state,
        isFetchingMachines: action.payload
      }
    case PRODUCER_PARAMS_IS_FETCHING_MACHINE_PRIORITIES:
      return {
        ...state,
        isFetchingMachinePriorities: action.payload
      }

    case PRODUCER_PARAMS_SET_CUTTING_MACHINES:
      return {
        ...state,
        cuttingMachines: action.payload
      }

    case PRODUCER_PARAMS_SET_MACHINE_DATA:
      return {
        ...state,
        selectedMachine: action.payload
      }

    case PRODUCER_PARAMS_SET_MACHINE_PRIORITIES:
      return {
        ...state,
        machinePriorities: action.payload
      }

    case PRODUCER_PARAMS_IS_FETCHING_MATERIAL_PRICES:
      return {
        ...state,
        isFetchingMaterialPrices: action.payload
      }

    case PRODUCER_PARAMS_SET_MATERIAL_PRICES:
      return {
        ...state,
        materialPrices: action.payload
      }

    case PRODUCER_PARAMS_IS_FETCHING_MATERIAL_CERTIFICATES:
      return {
        ...state,
        isFetchingMaterialCertPrices: action.payload
      }

    case PRODUCER_PARAMS_SET_MATERIAL_CERTIFICATES:
      return {
        ...state,
        baseMaterialCertFee: action.payload.baseMaterialCertFee,
        assemblyMaterialCertFee: action.payload.assemblyMaterialCertFee,
      }

    case PRODUCER_PARAMS_IS_FETCHING_BENDING_DATA:
      return {
        ...state,
        isFetchingBendingData: action.payload
      }

    case PRODUCER_PARAMS_SET_BENDING_DATA:
      return {
        ...state,
        bendingData: action.payload
      }

    case PRODUCER_PARAMS_IS_FETCHING_SINGLE_MATERIAL_BENDS:
      return {
        ...state,
        isFetchingSingleMaterialBends: action.payload
      }

    case PRODUCER_PARAMS_IS_FETCHING_SURFACE_COATING:
      return {
        ...state,
        isFetchingSurfaceCoatingData: action.payload
      }

    case PRODUCER_PARAMS_SET_SURFACE_COATING_DATA:
      return {
        ...state,
        surfaceCoatingData: action.payload
      }

    case PRODUCER_PARAMS_SET_SINGLE_MATERIAL_BENDS:
      return {
        ...state,
        bendingBendsData: action.payload
      }

    case PRODUCER_PARAMS_IS_FETCHING_SIMPLE_MACHINING_DATA:
      return {
        ...state,
        isFetchingSimpleMachiningData: action.payload
      }

    case PRODUCER_PARAMS_SET_SIMPLE_MACHINING_DATA:
      return {
        ...state,
        simpleMachiningData: action.payload
      }

    case PRODUCER_PARAMS_IS_FETCHING_MACHINING_DATA:
      return {
        ...state,
        isFetchingMachiningData: action.payload
      }

    case PRODUCER_PARAMS_SET_MACHINING_DATA:
      return {
        ...state,
        machiningData: {...action.payload},
        alreadyFetchedMachining: true
      }

    case PRODUCER_PARAMS_RESET_PRODUCER_PARAMS:
      return {
        isFetchingMachines: false,
        cuttingMachines: [],
        selectedMachine: {},
        isFetchingSelectedMachine: false,
        machinePriorities: [],
        isFetchingMachinePriorities: false,
        machineTolerances: {},
        isFetchingMachineTolerances: false,

        materialPrices: [],
        isFetchingMaterialPrices: false,

        bendingData: {},
        isFetchingBendingData: false,
        bendingBendsData: [],
        isFetchingSingleMaterialBends: false,

        isFetchingTubeCuttingData: false,
        tubeCuttingData: {},
        alreadyFetchedTubeCuttingService: false,
        isFetchingSelectedTubeCuttingMachine: false,
        selectedTubeCuttingMachine: {},
        tubeCuttingTolerances: {},
        isFetchingTubeCuttingTolerances: false,

        surfaceCoatingData: {},
        isFetchingSurfaceCoatingData: false,

        machiningData: {},
        isFetchingMachiningData: false,
        millingMachine: {},
        isFetchingMillingMachine: false,
        lathingMachine: {},
        isFetchingLathingMachine: false,
        alreadyFetchedMachining: false,
        millingMachineTolerances: {},
        isFetchingMillingMachineTolerances: false,
        lathingMachineTolerances: {},
        isFetchingLathingMachineTolerances: false,

        isFetchingTubeMaterialPrices: false,
        tubeCuttingMaterials: {
          availableMaterials: [],
          allAvailable: {}
        },
        hasTubeCutting: false,
        isFetchingStandardTubeMaterialPrices: false,
        standardTubeMaterialPrices: [],

        isFetchingMachiningMaterials: false,
        hasMachining: false,
        machiningMaterials: {
          availableMaterials: [],
          allAvailable: {}
        },

        simpleMachiningData: {},
        isFetchingSimpleMachiningData: false
      }

    case PRODUCER_PARAMS_IS_FETCHING_MILLING_MACHINE_DATA:
      return {
        ...state,
        isFetchingMillingMachine: action.payload
      }

    case PRODUCER_PARAMS_SET_MILLING_MACHINE_DATA:
      return {
        ...state,
        millingMachine: action.payload
      }

    case PRODUCER_PARAMS_IS_FETCHING_LATHING_MACHINE_DATA:
      return {
        ...state,
        isFetchingLathingMachine: action.payload
      }

    case PRODUCER_PARAMS_SET_LATHING_MACHINE_DATA:
      return {
        ...state,
        lathingMachine: action.payload
      }

    case PRODUCER_PARAMS_IS_FETCHING_MACHINING_MATERIALS:
      return {
        ...state,
        isFetchingMachiningMaterials: action.payload
      }

    case PRODUCER_PARAMS_SET_MACHINING_MATERIALS:
      return {
        ...state,
        hasMachining: action.payload.hasMachining,
        machiningMaterials: {
          availableMaterials: action.payload.rows,
          allAvailable: action.payload.allAvailable
        }
      }

    case PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_DATA:
      return {
        ...state,
        isFetchingTubeCuttingData: action.payload
      }

    case PRODUCER_PARAMS_SET_TUBE_CUTTING_DATA:
      return {
        ...state,
        tubeCuttingData: {...action.payload},
        alreadyFetchedTubeCuttingService: true
      }

    case PRODUCER_PARAMS_IS_FETCHING_TUBE_CUTTING_MACHINE_DATA:
      return {
        ...state,
        isFetchingSelectedTubeCuttingMachine: action.payload
      }

    case PRODUCER_PARAMS_SET_TUBE_CUTTING_MACHINE_DATA:
      return {
        ...state,
        selectedTubeCuttingMachine: {...state.selectedTubeCuttingMachine, ...action.payload }
      }

    case PRODUCER_PARAMS_IS_FETCHING_TUBE_MATERIAL_PRICES:
      return {
        ...state,
        isFetchingTubeMaterialPrices: action.payload
      }

    case PRODUCER_PARAMS_SET_TUBE_MATERIAL_PRICES:
      return {
        ...state,
        hasTubeCutting: action.payload.hasTubeCutting,
        tubeCuttingMaterials: {
          availableMaterials: action.payload.availableMaterials,
          allAvailable: action.payload.allAvailable
        }
      }

    case PRODUCER_PARAMS_IS_FETCHING_STANDARD_TUBE_MATERIAL_PRICES:
      return {
        ...state,
        isFetchingStandardTubeMaterialPrices: action.payload
      }

    case PRODUCER_PARAMS_SET_STANDARD_TUBE_MATERIAL_PRICES:
      return {
        ...state,
        hasTubeCutting: action.payload.hasTubeCutting,
        standardTubeMaterialPrices: action.payload.standardTubeMaterialPrices
      }

    default:
      return state
  }
}

export default producerParamsReducer;