import React, {useEffect, useState} from "react";

import {
  Button, Col, Divider, Form, Input, InputNumber,
  Modal, Row, Select
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

export const OrderDetailsAssemblyQuote = (props) => {

  const {
    isAssemblyQuoteModalVisible,
    item, handleClose, quoteAssembly,
    isQuotingAssembly
  } = props

  const [form] = Form.useForm();

  const handleModalClose = () => {
    form.setFieldsValue({
      assemblingPrice: 0,
      weldingPrice: 0,
      packagingPrice: 0,
      transportationPrice: 0
    })
    handleClose()
  }

  return (
    <div>
      <Modal
        className={"manualPartModal"}
        bodyStyle={{ padding: "16px" }}
        visible={isAssemblyQuoteModalVisible}
        style={{ top: 200 }}
        onCancel={handleModalClose}
        title={"Manual Quote | Assembly Operations"}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        width={550}
      >
        <Form
          form={form}
          className={"manualQuoteForm"}
          name={"manualQuoteOther"}
          onFinish={(values) => {
            quoteAssembly({
              assemblyId: item?.id,
              data: {
                assemblingPrice: values.assemblingPrice,
                weldingPrice: values.weldingPrice,
                packagingPrice:values.packagingPrice,
                transportationPrice: values.transportationPrice
              },
              close: handleModalClose
            })
          }}
        >
          <Row>
            <Col span={24}>
              <div>
                <div style={{display: "flex"}}>
                  <div style={{fontWeight: 500, fontSize: 13}}>Name:</div>
                </div>
                <Form.Item
                  style={{marginBottom: 10}}
                >
                  <Input
                    className={"quoteModalName"}
                    style={{width: "100%", height: 32}}
                    disabled={true}
                    value={item?.fileName}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", marginBottom: 8}}>
                <Divider className={"lightDivider"} style={{ margin: "2px 6px 0 0", minWidth: "0%" }}/>
                <div style={{whiteSpace: "nowrap"}}>Operations Breakdown <b>Per Assembly</b></div>
                <Divider className={"lightDivider"} style={{ margin: "2px 0 0 6px", minWidth: "0%" }}/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{marginRight: 10}}>
                <div style={{fontWeight: 500, fontSize: 13}}>Assembling Price:</div>
                <Form.Item
                  name={"assemblingPrice"}
                  rules={[
                    {required: true, message: 'This field is required'},
                  ]}
                  initialValue={0}
                >
                  <InputNumber
                    min={0}
                    max={1000000}
                    style={{width: "100%"}}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <div style={{fontWeight: 500, fontSize: 13}}>Welding Price:</div>
                <Form.Item
                  name={"weldingPrice"}
                  rules={[
                    {required: true, message: 'This field is required'},
                  ]}
                  initialValue={0}
                >
                  <InputNumber
                    min={0}
                    max={1000000}
                    style={{width: "100%"}}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div style={{marginRight: 10}}>
                <div style={{fontWeight: 500, fontSize: 13}}>Machine Processing Price:</div>
                <Form.Item
                  name={"packagingPrice"}
                  rules={[
                    {required: true, message: 'This field is required'},
                  ]}
                  initialValue={0}
                >
                  <InputNumber
                    min={0}
                    max={1000000}
                    style={{width: "100%"}}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <div style={{fontWeight: 500, fontSize: 13}}>Others:</div>
                <Form.Item
                  name={"transportationPrice"}
                  rules={[
                    {required: true, message: 'This field is required'},
                  ]}
                  initialValue={0}
                >
                  <InputNumber
                    min={0}
                    max={1000000}
                    style={{width: "100%"}}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Divider className={"lightDivider"} style={{ margin: "-6px 0 12px 0" }}/>
          <div align={"right"}>
            <Button
              type={"primary"}
              htmlType={"submit"}
              style={{marginLeft: 5, fontWeight: 500, fontSize: 13}}
              loading={isQuotingAssembly}
            >
              Quote Operations
            </Button>
          </div>
        </Form>
      </Modal>
    </div>

  )
}