import React from 'react'
import { Redirect } from 'react-router-dom'

import {
  AdminLoginPage,

  LoginPage,
  RegisterPage,
  VerifyEmail,
  ResendEmail,
  ForgotPassword,
  ResetPassword,

  CompanyDashboard,
  AdminManagementPage,
  PendingPage,
  AwaitingConfirmationPage,
  ProcessedPage,
  MaterialsAndMachinesPage,
  BulkOrderDetailsPage,
  InternalCustomerRelations,
  CompanySettingsPage,
  ProducersPage,
  ProducerMaterialsPage,

  OrderCalculationPage,
  StepTipsPage,

  CustomerDashboard,
  CustomerPendingPage,
  CustomerAwaitingPage,
  CustomerProcessedPage,
  RegularCustomerSettingsPage,
  CorporateCustomerSettingsPage,

  AdminDashboard,
  AllProducers,
  AllCustomers,
  AllCorporateCustomers,
  AdminLogPage,

  OrderDetailsPage,

  TestPage,
} from '../containers'

import { withRedirect } from './helpers'
import {storage} from "../utils";
import {notification} from "antd";

const withMiddleware = ({ component: Component, roleNames, permission }) => () => {
  const user = store.getState().auth.user

  notification.close("pdfWantedInfo")
  notification.close("size20warning")
  notification.close("file2Large")

  if (!user) {
    storage.storeAuthData(null)
    return <Redirect to={{ pathname: '/login' }} />
  }

  const roleName = user.role

  if (!roleNames.includes(roleName)) {
    return <Redirect to={{ pathname: '/' }} />
  }

  if(roleName === "gelsoSubAdmin" && permission) {
    if(!user.permissions?.includes(permission)) {
      return <Redirect to={{ pathname: '/' }} />
    }
  }



  return <Component />
}

let store

const routes = {
  all: [
    {
      path: '/',
      exact: true,
      component: () => {
        const user = store.getState().auth.user;
        const authTime = storage.retrieveTimePassed()

        notification.close("pdfWantedInfo")
        notification.close("size20warning")
        notification.close("file2Large")

        if(user !== null && authTime !== null && authTime >= 216000) {
          storage.storeAuthData(null)
          return <Redirect to={{ pathname: '/login' }} />
        }

        const role = user.role

        switch (role) {
          case 'headAdmin':
            return <AdminDashboard />

          case 'gelsoAdmin':
          case 'gelsoSubAdmin':
            return <CompanyDashboard />

          case 'regularCustomer':
          case 'corporateCustomer':
            return <CustomerDashboard />

          case 'producerAdmin':
            return <MaterialsAndMachinesPage />

          default:
            return null
        }
      },
    },
    {
      path: '/loginAdmin',
      exact: true,
      isPublic: true,
      component: withRedirect({
        ifTrue: () => store.getState().auth.user === null,
        thenRenderComponent: AdminLoginPage,
        elseRedirectTo: '/',
      }),
    },
    {
      path: '/login',
      exact: true,
      isPublic: true,
      component: withRedirect({
        ifTrue: () => store.getState().auth.user === null,
        thenRenderComponent: LoginPage,
        elseRedirectTo: '/',
      }),
    },
    {
      path: '/register',
      exact: true,
      isPublic: true,
      component: withRedirect({
        ifTrue: () => store.getState().auth.user === null,
        thenRenderComponent: RegisterPage,
        elseRedirectTo: '/',
      }),
    },
    {
      path: '/confirm-email',
      exact: true,
      isPublic: true,
      component: withRedirect({
        ifTrue: () => store.getState().auth.user === null,
        thenRenderComponent: VerifyEmail,
        elseRedirectTo: '/',
      }),
    },
    {
      path: '/resend-email',
      exact: true,
      isPublic: true,
      component: withRedirect({
        ifTrue: () => store.getState().auth.user === null,
        thenRenderComponent: ResendEmail,
        elseRedirectTo: '/',
      }),
    },
    {
      path: '/forgot-password',
      exact: true,
      isPublic: true,
      component: withRedirect({
        ifTrue: () => store.getState().auth.user === null,
        thenRenderComponent: ForgotPassword,
        elseRedirectTo: '/',
      }),
    },
    {
      path: '/recover-password',
      exact: true,
      isPublic: true,
      component: withRedirect({
        ifTrue: () => store.getState().auth.user === null,
        thenRenderComponent: ResetPassword,
        elseRedirectTo: '/',
      }),
    },
    {
      path: '/employeeManagement',
      exact: true,
      component: withMiddleware({
        component: AdminManagementPage,
        roleNames: ['gelsoAdmin'],
      }),
    },
    {
      path: '/producersManagement',
      exact: true,
      component: withMiddleware({
        component: ProducersPage,
        roleNames: ['gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'producers'
      }),
    },
    {
      path: '/producersManagement/:producerId/materials',
      exact: true,
      component: withMiddleware({
        component: ProducerMaterialsPage,
        roleNames: ['gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'producers'
      }),
    },
    {
      path: '/pending',
      exact: true,
      component: withMiddleware({
        component: PendingPage,
        roleNames: ['gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'orders'
      }),
    },
    {
      path: '/awaiting',
      exact: true,
      component: withMiddleware({
        component: AwaitingConfirmationPage,
        roleNames: ['gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'orders'
      }),
    },
    {
      path: '/processed',
      exact: true,
      component: withMiddleware({
        component: ProcessedPage,
        roleNames: ['gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'orders'
      })
    },
    {
      path: '/materials',
      exact: true,
      component: withMiddleware({
        component: MaterialsAndMachinesPage,
        roleNames: ['gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'materials'
      }),
    },
    {
      path: '/calculation',
      exact: true,
      component: withMiddleware({
        component: OrderCalculationPage,
        roleNames: ['gelsoAdmin', 'gelsoSubAdmin', 'producerAdmin'],
        permission: 'bulk'
      }),
    },
    {
      path: '/calculation/:orderId/details',
      exact: true,
      component: withMiddleware({
        component: BulkOrderDetailsPage,
        roleNames: ['gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'bulk'
      }),
    },
    {
      path: '/internal-customer-relations',
      exact: true,
      component: withMiddleware({
        component: InternalCustomerRelations,
        roleNames: ['gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'customers'
      }),
    },
    {
      path: '/company-settings',
      exact: true,
      component: withMiddleware({
        component: CompanySettingsPage,
        roleNames: ['gelsoAdmin', 'gelsoSubAdmin']
      }),
    },

    {
      path: '/draft',
      exact: true,
      component: withMiddleware({
        component: OrderCalculationPage,
        roleNames: ['regularCustomer', 'corporateCustomer'],
      }),
    },
    {
      path: '/pending-order',
      exact: true,
      component: withMiddleware({
        component: OrderDetailsPage,
        roleNames: ['regularCustomer', 'corporateCustomer', 'gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'orders'
      }),
    },
    {
      path: '/awaiting-order',
      exact: true,
      component: withMiddleware({
        component: OrderDetailsPage,
        roleNames: ['regularCustomer', 'corporateCustomer', 'gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'orders'
      }),
    },
    // {
    //   path: '/accepted-order',
    //   exact: true,
    //   component: withMiddleware({
    //     component: OrderDetailsPage,
    //     roleNames: ['regularCustomer', 'corporateCustomer', 'gelsoAdmin', 'gelsoSubAdmin'],
    //     permission: 'orders'
    //   }),
    // },
    {
      path: '/completed-order',
      exact: true,
      component: withMiddleware({
        component: OrderDetailsPage,
        roleNames: ['regularCustomer', 'corporateCustomer', 'gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'orders'
      }),
    },
    { //#TODO Msm da ovo ne postoji
      path: '/processed-order',
      exact: true,
      component: withMiddleware({
        component: OrderDetailsPage,
        roleNames: ['regularCustomer', 'corporateCustomer', 'gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'orders'
      })
    },
    {
      path: '/step-tips',
      exact: true,
      component: withMiddleware({
        component:  StepTipsPage,
        roleNames: ['regularCustomer', 'corporateCustomer', 'gelsoAdmin', 'gelsoSubAdmin'],
      }),
    },

    {
      path: '/pending-orders',
      exact: true,
      component: withMiddleware({
        component: CustomerPendingPage,
        roleNames: ['regularCustomer', 'corporateCustomer'],
      }),
    },
    {
      path: '/awaiting-orders',
      exact: true,
      component: withMiddleware({
        component: CustomerAwaitingPage,
        roleNames: ['regularCustomer', 'corporateCustomer'],
        permission: 'orders'
      }),
    },
    {
      path: '/processed-orders',
      exact: true,
      component: withMiddleware({
        component: CustomerProcessedPage,
        roleNames: ['regularCustomer', 'corporateCustomer', 'gelsoAdmin', 'gelsoSubAdmin'],
        permission: 'orders'
      })
    },

    {
      path: '/customer-settings',
      exact: true,
      component: withMiddleware({
        component: RegularCustomerSettingsPage,
        roleNames: ['regularCustomer'],
      }),
    },
    {
      path: '/corporate-settings',
      exact: true,
      component: withMiddleware({
        component: CorporateCustomerSettingsPage,
        roleNames: ['corporateCustomer'],
      }),
    },

    {
      path: '/producers',
      exact: true,
      component: withMiddleware({
        component: AllProducers,
        roleNames: ['headAdmin'],
      }),
    },
    {
      path: '/customers',
      exact: true,
      component: withMiddleware({
        component: AllCustomers,
        roleNames: ['headAdmin'],
      }),
    },
    {
      path: '/corporate',
      exact: true,
      component: withMiddleware({
        component: AllCorporateCustomers,
        roleNames: ['headAdmin'],
      }),
    },
    {
      path: '/logs',
      exact: true,
      component: withMiddleware({
        component: AdminLogPage,
        roleNames: ['headAdmin'],
      }),
    },
    {
      path: '/test',
      exact: true,
      component: withMiddleware({
        component: TestPage,
        roleNames: ['headAdmin'],
      }),
    },
    {
      path: '/*',
      isPublic: true,
      component: () => <Redirect to={{ pathname: '/' }} />,
    },
  ],
  setStore: newStore => {
    store = newStore
  },
}

export { routes }
