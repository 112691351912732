import React from "react";
import {Button, Col, Modal, Row} from "antd";
import {S3_BUCKET_URL} from "../../../constants";
import {FileTextOutlined} from "@ant-design/icons";

export const DocumentsModal = (props) => {

  const { visible, handleCloseDocsModal, additionalDocs } = props;

  return (
    <Modal
      visible={visible}
      title={"Download Documents"}
      closable={true}
      destroyOnClose={true}
      footer={
        <div align={"right"} style={{ width: "100%" }}>
          <Button onClick={() => {
            handleCloseDocsModal()
          }}>
            Close
          </Button>
        </div>
      }
      onCancel={()=>{
        handleCloseDocsModal()
      }}
      zIndex={8}
    >
      <Row>
        <Col span={24}>
          <div className={"docsContainer"}>
            {additionalDocs.map(item => {
              return (
                <div key={item.documentId} style={{ marginBottom: 6, width: "100%" }}>
                  <Button
                    className={"downloadButton"}
                    style={{ width: "100%", height: 32 }}
                    type={"primary"}
                    href={`${S3_BUCKET_URL + item.drawingPDFFilePath}`}
                  >
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", paddingTop: 3 }}>
                      <FileTextOutlined style={{fontSize: 14, marginRight: 6}} />
                      <span style={{fontSize: 13, fontWeight: 500}}>
                            {item.drawingPDFFileName}
                          </span>
                      <div/>
                    </div>
                  </Button>
                </div>
              )
            })}
          </div>
        </Col>
      </Row>
    </Modal>
  )
}