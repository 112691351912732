import {
  ORDERS_SET_PRODUCER_PENDING_ORDERS,
  ORDERS_SET_PRODUCER_PENDING_ORDERS_PAGINATION,
  ORDERS_SET_IS_FETCHING_PRODUCER_PENDING_ORDERS,
  ORDERS_SET_PRODUCER_ACCEPTED_ORDERS,
  ORDERS_SET_PRODUCER_ACCEPTED_ORDERS_PAGINATION,
  ORDERS_SET_IS_FETCHING_PRODUCER_ACCEPTED_ORDERS,
  ORDERS_SET_PRODUCER_ARCHIVED_ORDERS,
  ORDERS_SET_PRODUCER_ARCHIVED_ORDERS_PAGINATION,
  ORDERS_SET_IS_FETCHING_PRODUCER_ARCHIVED_ORDERS,
  ORDERS_SET_CUSTOMER_PENDING_ORDERS,
  ORDERS_SET_CUSTOMER_PENDING_ORDERS_PAGINATION,
  ORDERS_SET_IS_FETCHING_CUSTOMER_PENDING_ORDERS,
  ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS,
  ORDERS_SET_CUSTOMER_ACCEPTED_ORDERS_PAGINATION,
  ORDERS_SET_IS_FETCHING_CUSTOMER_ACCEPTED_ORDERS,
  ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS,
  ORDERS_SET_CUSTOMER_ARCHIVED_ORDERS_PAGINATION,
  ORDERS_SET_IS_FETCHING_CUSTOMER_ARCHIVED_ORDERS,
  ORDERS_SET_IS_FETCHING_DRAFT_ORDERS,
  ORDERS_SET_DRAFT_ORDERS,
  ORDERS_SET_IS_REMOVING_DRAFT_ORDER,
  ORDERS_REMOVE_DRAFT_ORDER,
  ORDERS_SET_IS_CREATING_DRAFT_ORDER,
  ORDERS_SET_IS_FETCHING_DRAFT_ORDER,
  ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
  ORDERS_SET_PARTS_TO_ANALYZE,
  ORDERS_SET_IS_CHANGING_DELIVERY_DATE,
  ORDERS_SET_IS_FETCHING_PENDING_ORDER,
  ORDERS_SET_PENDING_ORDER,
  ORDERS_SET_IS_ADDING_NEW_PART,
  ORDERS_SET_PENDING_ORDER_PRICE,
  ORDERS_SET_PENDING_ORDER_PARTS,
  ORDERS_SET_IS_FETCHING_PENDING_ORDER_PARTS,
  ORDERS_SET_IS_EDITING_PENDING_ORDER,
  ORDERS_SET_PENDING_ORDER_DELIVERY_DATE,
  ORDERS_SET_PENDING_ORDER_STAGE,
  ORDERS_SET_IS_RESOLVING_PENDING_ORDER,
  ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_ORDERS,
  ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_PAGINATION,
  ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_ORDERS,
  ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_DASHBOARD_ORDERS,
  ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_DASHBOARD_ORDERS,
  ORDERS_SET_IS_FETCHING_PRODUCER_AWAITING_CONFIRMATION_ORDERS,
  ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_PAGINATION,
  ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_ORDERS,
  ORDERS_SET_IS_REVERTING_ORDER,
  ORDERS_SET_IS_CHANGING_DRAFT_NAME,
  ORDERS_SET_IS_FETCHING_AWAITING_CHANGES,
  ORDERS_SET_AWAITING_CHANGES,
  ORDERS_SET_IS_FETCHING_RECENT_CHANGES,
  ORDERS_SET_RECENT_CHANGES,
  ORDERS_SET_IS_GENERATING_ZIP,
  ORDERS_SET_ARCHIVED_BULK_ORDERS,
  ORDERS_SET_FETCHING_ARCHIVED_BULK_ORDERS,
  ORDERS_SET_IS_FETCHING_INTERNAL_DRAFT_ORDER,
  ORDERS_SET_INTERNAL_ARCHIVED_ORDERS,
  ORDERS_SET_IS_ARCHIVING_CALCULATION,
  ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION,
  ORDERS_SET_ARCHIVED_CALCULATION,
  ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION_CUSTOMERS,
  ORDERS_SET_ARCHIVED_CALCULATION_CUSTOMERS,
  ORDERS_SET_PENDING_ORDER_PART_PRODUCED_QUANTITY,
  ORDERS_SET_FILES_TO_UPLOAD,
  ORDERS_SET_IS_FETCHING_PRODUCERS_FOR_ORDER,
  ORDER_SET_PRODUCERS_FOR_ORDER,
  ORDERS_SET_IS_QUOTING_ASSEMBLY_PRODUCTION_PRICE,
  ORDERS_SET_IS_FETCHING_RECENT_PRODUCER_CHANGES,
  ORDERS_RECENT_PRODUCER_CHANGES,
  ORDERS_SET_IS_FETCHING_PRODUCER_PROCESSED_ORDERS,
  ORDERS_SET_PRODUCER_PROCESSED_ORDERS_PAGINATION,
  ORDERS_SET_PRODUCER_PROCESSED_ORDERS,
  ORDERS_SET_CUSTOMER_PROCESSED_ORDERS,
  ORDERS_SET_IS_FETCHING_CUSTOMER_PROCESSED_ORDERS,
  ORDERS_sET_CUSTOMER_PROCESSED_ORDERS_PAGINATION, ORDERS_SET_IS_UPDATING_ODOO_ORDER_LINK, ORDERS_SET_ODOO_ORDER_LINK
} from "../actions/actionTypes";
import initialState from './initialState'

const ordersReducer = (state = initialState.orders, action) => {
  switch (action.type) {
    case ORDERS_SET_PRODUCER_PENDING_ORDERS:
      return {
        ...state,
        producerPendingOrders: action.payload,
      }

    case ORDERS_SET_PRODUCER_PENDING_ORDERS_PAGINATION:
      return {
        ...state,
        producerPendingOrdersPagination: action.payload,
      }

    case ORDERS_SET_IS_FETCHING_PRODUCER_PENDING_ORDERS:
      return {
        ...state,
        isFetchingProducerPendingOrders: action.payload,
      }

    case ORDERS_SET_IS_FETCHING_PRODUCER_PROCESSED_ORDERS:
      return {
        ...state,
        isFetchingProducerProcessedOrders: action.payload
      }

    case ORDERS_SET_PRODUCER_PROCESSED_ORDERS_PAGINATION:
      return {
        ...state,
        producerProcessedOrdersPagination: action.payload
      }

    case ORDERS_SET_PRODUCER_PROCESSED_ORDERS:
      return {
        ...state,
        producerProcessedOrders: action.payload
      }

    //  -------------------------------------------------------------

    case ORDERS_SET_CUSTOMER_PENDING_ORDERS:
      return {
        ...state,
        customerPendingOrders: action.payload,
      }

    case ORDERS_SET_CUSTOMER_PENDING_ORDERS_PAGINATION:
      return {
        ...state,
        customerPendingOrdersPagination: action.payload,
      }

    case ORDERS_SET_IS_FETCHING_CUSTOMER_PENDING_ORDERS:
      return {
        ...state,
        isFetchingCustomerPendingOrders: action.payload,
      }

    case ORDERS_SET_IS_FETCHING_CUSTOMER_PROCESSED_ORDERS:
      return {
        ...state,
        isFetchingCustomerProcessedOrders: action.payload
      }

    case ORDERS_sET_CUSTOMER_PROCESSED_ORDERS_PAGINATION:
      return {
        ...state,
        customerProcessedOrdersPagination: action.payload
      }

      case ORDERS_SET_CUSTOMER_PROCESSED_ORDERS:
        return {
          ...state,
          customerProcessedOrders: action.payload
        }

    case ORDERS_SET_IS_FETCHING_DRAFT_ORDERS:
      return {
        ...state,
        isFetchingDrafts: action.payload,
      }

    case ORDERS_SET_DRAFT_ORDERS:
      return {
        ...state,
        draftOrders: action.payload,
      }

    case ORDERS_SET_IS_REMOVING_DRAFT_ORDER:
      return {
        ...state,
        draftOrders: state.draftOrders.map(item => {
          if(item.id === action.payload.id) {
            return {...item, loading: action.payload.value}
          }
          return item
        }),
      }

    case ORDERS_REMOVE_DRAFT_ORDER:
      return {
        ...state,
        draftOrders: state.draftOrders.filter(item => item.id !== action.payload)
      }

    case ORDERS_SET_IS_CREATING_DRAFT_ORDER:
      return {
        ...state,
        isCreatingDraftOrder: action.payload
      }

    case ORDERS_SET_IS_FETCHING_DRAFT_ORDER:
      return {
        ...state,
        isFetchingOrder: action.payload
      }

    case ORDERS_SET_IS_CHANGING_DRAFT_STAGE:
      return {
        ...state,
        isChangingDraftStage: action.payload
      }

    case ORDERS_SET_PARTS_TO_ANALYZE:
      return {
        ...state,
        toAnalyze: action.payload
      }

    case ORDERS_SET_IS_CHANGING_DELIVERY_DATE:
      return {
        ...state,
        isChangingDeliveryDate: action.payload
      }

    case ORDERS_SET_IS_FETCHING_PENDING_ORDER:
      return {
        ...state,
        isFetchingPendingOrder: action.payload
      }

    case ORDERS_SET_PENDING_ORDER:
      return {
        ...state,
        pendingOrder: action.payload
      }

    case ORDERS_SET_IS_ADDING_NEW_PART:
      return {
        ...state,
        isAddingNewPart: action.payload
      }

    case ORDERS_SET_IS_QUOTING_ASSEMBLY_PRODUCTION_PRICE:
        return {
          ...state,
          isQuotingAssembly: action.payload
        }

    case ORDERS_SET_PENDING_ORDER_PRICE:
      return {
        ...state,
        pendingOrder: {
          ...state.pendingOrder,
          ...action.payload
        }
      }

    case ORDERS_SET_PENDING_ORDER_PARTS:
      return {
        ...state,
        pendingOrder: {
          ...state.pendingOrder,
          processedParts: action.payload.processedParts,
          unprocessedParts: action.payload.unprocessedParts
        }
      }

    case ORDERS_SET_IS_FETCHING_PENDING_ORDER_PARTS:
      return {
        ...state,
        isFetchingPendingOrderParts: action.payload
      }

    case ORDERS_SET_IS_EDITING_PENDING_ORDER:
      return {
        ...state,
        isEditingPendingOrder: action.payload
      }

    case ORDERS_SET_PENDING_ORDER_DELIVERY_DATE:
      return {
        ...state,
        pendingOrder: {
          ...state.pendingOrder,
          deliveryDate: action.payload
        }
      }

    case ORDERS_SET_PENDING_ORDER_STAGE:
      return {
        ...state,
        pendingOrder: {
          ...state.pendingOrder,
          stage: action.payload
        }
      }

    case ORDERS_SET_IS_RESOLVING_PENDING_ORDER:
      return {
        ...state,
        isResolvingPendingOrder:  action.payload
      }

    case ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_DASHBOARD_ORDERS:
      return {
        ...state,
        customerAwaitingConfirmationDashboard: action.payload,
      }

    case ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_DASHBOARD_ORDERS:
      return {
        ...state,
        isFetchingCustomerAwaitingConfirmationOrders: action.payload,
      }

    case ORDERS_SET_IS_FETCHING_CUSTOMER_AWAITING_CONFIRMATION_ORDERS:
      return {
        ...state,
        isFetchingCustomerAwaitingConfirmationOrders: action.payload,
      }
    case ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_PAGINATION:
      return {
        ...state,
        customerAwaitingConfirmationPagination: action.payload
      }

    case ORDERS_SET_CUSTOMER_AWAITING_CONFIRMATION_ORDERS:
      return {
        ...state,
        customerAwaitingConfirmation: [...action.payload]
      }

    case ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_ORDERS:
      return {
        ...state,
        producerAwaitingConfirmation: action.payload,
      }

    case ORDERS_SET_PRODUCER_AWAITING_CONFIRMATION_PAGINATION:
      return {
        ...state,
        producerAwaitingConfirmationPagination: action.payload,
      }

    case ORDERS_SET_IS_FETCHING_PRODUCER_AWAITING_CONFIRMATION_ORDERS:
      return {
        ...state,
        isFetchingProducerAwaitingConfirmationOrders: action.payload,
      }

    case ORDERS_SET_IS_REVERTING_ORDER:
      return {
        ...state,
        isRevertingOrder: action.payload,
      }

    case ORDERS_SET_IS_CHANGING_DRAFT_NAME:
      return {
        ...state,
        isChangingDraftName: action.payload,
      }

    case ORDERS_SET_IS_FETCHING_AWAITING_CHANGES:
      return {
        ...state,
        isFetchingAwaitingChanges: action.payload,
      }

    case ORDERS_SET_AWAITING_CHANGES:
      return {
        ...state,
        awaitingChanges: action.payload,
      }

    case ORDERS_SET_IS_FETCHING_RECENT_CHANGES:
      return {
        ...state,
        isFetchingRecentChanges: action.payload,
      }

    case ORDERS_SET_RECENT_CHANGES:
      return {
        ...state,
        recentChanges: action.payload,
      }

    case ORDERS_SET_IS_GENERATING_ZIP:
      return {
        ...state,
        isGeneratingZip: action.payload,
      }

    case ORDERS_SET_ARCHIVED_BULK_ORDERS:
      return {
        ...state,
        archivedOrders: [...action.payload]
      }

    case ORDERS_SET_FETCHING_ARCHIVED_BULK_ORDERS:
      return {
        ...state,
        fetchingArchivedBulkOrders: action.payload
      }

    case ORDERS_SET_IS_FETCHING_INTERNAL_DRAFT_ORDER:
      return {
        ...state,
        isFetchingOrder: action.payload
      }

    case ORDERS_SET_INTERNAL_ARCHIVED_ORDERS:
      return {
        ...state,
        archivedInternalOrders: action.payload
      }

    case ORDERS_SET_IS_ARCHIVING_CALCULATION:
      return {
        ...state,
        isArchivingCalculation: action.payload
      }

    case ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION:
      return {
        ...state,
        isFetchingArchivedCalculation: action.payload
      }

    case ORDERS_SET_ARCHIVED_CALCULATION:
      return {
        ...state,
        archivedCalculation: action.payload
      }

    case ORDERS_SET_IS_FETCHING_ARCHIVED_CALCULATION_CUSTOMERS:
      return {
        ...state,
        isFetchingArchivedCalculationCustomers: action.payload
      }

    case ORDERS_SET_ARCHIVED_CALCULATION_CUSTOMERS:
      return {
        ...state,
        archivedCalculationCustomers: action.payload
      }

    case ORDERS_SET_PENDING_ORDER_PART_PRODUCED_QUANTITY:
      return {
        ...state,
        pendingOrder: {
          ...state.pendingOrder,
          state: "inProgress",
          parts: state.pendingOrder.parts.map(item => {
            if (item.id === action.payload.partId) {
              return {
                ...item,
                producedQuantity: action.payload.newQuantity
              }
            }
            return item
          })
        }
      }

    case ORDERS_SET_FILES_TO_UPLOAD:
      return {
        ...state,
        filesToUpload: action.payload
      }

    case ORDERS_SET_IS_FETCHING_PRODUCERS_FOR_ORDER:
      return {
        ...state,
        isFetchingProducersForOrder: action.payload
      }

    case ORDER_SET_PRODUCERS_FOR_ORDER:
      return {
        ...state,
        producersForOrder: action.payload
      }

    case ORDERS_SET_IS_FETCHING_RECENT_PRODUCER_CHANGES:
      return {
        ...state,
        isFetchingRecentOrderChanges: action.payload,
      }

    case ORDERS_RECENT_PRODUCER_CHANGES:
      return {
        ...state,
        recentOrderChanges: [...action.payload]
      }

    case ORDERS_SET_IS_UPDATING_ODOO_ORDER_LINK:
      return {
        ...state,
        isUpdatingOdooLink: action.payload
      }

    case ORDERS_SET_ODOO_ORDER_LINK:
      return {
        ...state,
        pendingOrder: {
          ...state.pendingOrder,
          odooUrl: action.payload
        }
      }

    default:
      return state
  }
}

export default ordersReducer
