import {
  MACHINING_SET_DATA,
  MACHINING_IS_FETCHING_DATA,
  MACHINING_SET_MACHINING_SERVICE,
  MACHINING_SET_MILLING_SERVICE,
  MACHINING_SET_LATHING_SERVICE,
  MACHINING_SET_MILLING_DATA,
  MACHINING_SET_LATHING_DATA,
  MACHINING_IS_FETCHING_MATERIALS,
  MACHINING_SET_MATERIALS,
  MACHINING_SET_MACHINING_MATERIAL_PRICE,
  MACHINING_IS_FETCHING_SELECTED_MILLING_MACHINE_DATA,
  MACHINING_IS_FETCHING_SELECTED_LATHING_MACHINE_DATA,
  MACHINING_SET_SELECTED_MILLING_MACHINE,
  MACHINING_SET_SELECTED_LATHING_MACHINE,
  MACHINING_SET_MILLING_MACHINES,
  MACHINING_SET_LATHING_MACHINES,
  MACHINING_IS_REMOVING_LATHING_MACHINE,
  MACHINING_IS_REMOVING_MILLING_MACHINE,
  MACHINING_IS_UPDATING_MILLING_MACHINE,
  MACHINING_IS_UPDATING_LATHING_MACHINE,
  MACHINING_SET_MILLING_GRADE_SPEED,
  MACHINING_SET_LATHING_GRADE_SPEED,
  MACHINING_SET_MILLING_MACHINE_TOLERANCE,
  MACHINING_SET_LATHING_MACHINE_TOLERANCE,
  MACHINING_SET_MILLING_MACHINE_ROUGHNESS,
  MACHINING_SET_LATHING_MACHINE_ROUGHNESS
} from "../actions/actionTypes";

import initialState from './initialState'


const machiningReducer = (state = initialState.machining, action) => {
  switch (action.type) {
    case MACHINING_SET_DATA:
      return {
        ...state,
        machiningData: {...state.machiningData, ...action.payload},
        alreadyFetchedMachining: true
      }

    case MACHINING_IS_FETCHING_DATA:
      return {
        ...state,
        isFetchingMachiningData: action.payload,
      }

    case MACHINING_SET_MATERIALS:
      return {
        ...state,
        hasMachining: action.payload.hasMachining,
        materials: {
          availableMaterials: action.payload.rows,
          allAvailable: action.payload.allAvailable
        },
      }

    case MACHINING_IS_FETCHING_MATERIALS:
      return {
        ...state,
        isFetchingMachiningMaterials: action.payload,
      }

    case MACHINING_SET_MACHINING_SERVICE:
      return {
        ...state,
        machiningData: {...state.machiningData, hasMachining: action.payload},
      }

    case MACHINING_SET_MILLING_SERVICE:
      return {
        ...state,
        machiningData: {...state.machiningData, milling: {...state.machiningData.milling, hasMilling: action.payload}},
      }

    case MACHINING_SET_LATHING_SERVICE:
      return {
        ...state,
        machiningData: {...state.machiningData, lathing: {...state.machiningData.lathing, hasLathing: action.payload}},
      }

    case MACHINING_SET_MILLING_DATA:
      return {
        ...state,
        selectedMillingMachine: {
          ...state.selectedMillingMachine,
          supervisedMachine: action.payload.supervisedMachine,
          supervisionPricePerHour: action.payload.supervisionPricePerHour,
          pricePerHour: action.payload.pricePerHour,
          engineerPricePerHour: action.payload.engineerPricePerHour,
          averagePreparationTime: action.payload.averagePreparationTime,
        }
      }

    case MACHINING_SET_LATHING_DATA:
      return {
        ...state,
        selectedLathingMachine: {
          ...state.selectedLathingMachine,
          supervisedMachine: action.payload.supervisedMachine,
          supervisionPricePerHour: action.payload.supervisionPricePerHour,
          pricePerHour: action.payload.pricePerHour,
          engineerPricePerHour: action.payload.engineerPricePerHour,
          averagePreparationTime: action.payload.averagePreparationTime,
        }
      }

    case MACHINING_SET_MACHINING_MATERIAL_PRICE:
      let temp = [...state.materials.availableMaterials]
      temp[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        materials: {...state.materials, availableMaterials: temp},
      }

    case MACHINING_IS_FETCHING_SELECTED_MILLING_MACHINE_DATA:
      return {
        ...state,
        isFetchingSelectedMillingMachine: action.payload
      }

    case MACHINING_SET_SELECTED_MILLING_MACHINE:
      return {
        ...state,
        selectedMillingMachine: {...action.payload}
      }

    case MACHINING_IS_FETCHING_SELECTED_LATHING_MACHINE_DATA:
      return {
        ...state,
        isFetchingSelectedLathingMachine: action.payload
      }

    case MACHINING_SET_SELECTED_LATHING_MACHINE:
      return {
        ...state,
        selectedLathingMachine: {...action.payload}
      }

    case MACHINING_SET_MILLING_MACHINES:
      return {
        ...state,
        machiningData: {
          ...state.machiningData,
          milling: {
            ...state.machiningData.milling,
            machines: [...action.payload]
          }
        }
      }

    case MACHINING_SET_LATHING_MACHINES:
      return {
        ...state,
        machiningData: {
          ...state.machiningData,
          lathing: {
            ...state.machiningData.lathing,
            machines: [...action.payload]
          }
        }
      }

    case MACHINING_IS_REMOVING_MILLING_MACHINE:
      return {
        ...state,
        isRemovingMillingMachine: action.payload
      }

    case MACHINING_IS_REMOVING_LATHING_MACHINE:
      return {
        ...state,
        isRemovingLathingMachine: action.payload
      }

    case MACHINING_IS_UPDATING_MILLING_MACHINE:
      return {
        ...state,
        isUpdatingMillingMachine: action.payload
      }

    case MACHINING_IS_UPDATING_LATHING_MACHINE:
      return {
        ...state,
        isUpdatingLathingMachine: action.payload
      }

    case MACHINING_SET_MILLING_GRADE_SPEED:
      let tmpMillRows = [...state.selectedMillingMachine.rows]
      tmpMillRows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        selectedMillingMachine: {...state.selectedMillingMachine, rows: tmpMillRows}
      }

    case MACHINING_SET_LATHING_GRADE_SPEED:
      let tmpLathRows = [...state.selectedLathingMachine.rows]
      tmpLathRows[action.payload.row][action.payload.col] = action.payload.value
      return {
        ...state,
        selectedLathingMachine: {...state.selectedLathingMachine, rows: tmpLathRows}
      }

    case MACHINING_SET_MILLING_MACHINE_TOLERANCE:
      return {
        ...state,
        selectedMillingMachine: {
          ...state.selectedMillingMachine,
          markup: {
            ...state.selectedMillingMachine.markup,
            tolerance: {
              ...state.selectedMillingMachine.markup.tolerance,
              [action.payload.toleranceClass]: action.payload.toleranceMarkup
            }
          }
        }
      }

    case MACHINING_SET_LATHING_MACHINE_TOLERANCE:
      return {
        ...state,
        selectedLathingMachine: {
          ...state.selectedLathingMachine,
          markup: {
            ...state.selectedLathingMachine.markup,
            tolerance: {
              ...state.selectedLathingMachine.markup.tolerance,
              [action.payload.toleranceClass]: action.payload.toleranceMarkup
            }
          }
        }
      }

    case MACHINING_SET_MILLING_MACHINE_ROUGHNESS:
      return {
        ...state,
        selectedMillingMachine: {
          ...state.selectedMillingMachine,
          markup: {
            ...state.selectedMillingMachine.markup,
            roughness: {
              ...state.selectedMillingMachine.markup.roughness,
              [action.payload.roughnessClass]: action.payload.roughnessMarkup
            }
          }
        }
      }

    case MACHINING_SET_LATHING_MACHINE_ROUGHNESS:
      return {
        ...state,
        selectedLathingMachine: {
          ...state.selectedLathingMachine,
          markup: {
            ...state.selectedLathingMachine.markup,
            roughness: {
              ...state.selectedLathingMachine.markup.roughness,
              [action.payload.roughnessClass]: action.payload.roughnessMarkup
            }
          }
        }
      }

    default:
      return state
  }
}

export default machiningReducer
