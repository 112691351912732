import React, {useCallback, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {CompanySidebar} from "../../../components/company";
import {logoutUser} from "../../../actions/authActions";
import {Button, Card, Col, Divider, Form, Grid, Input, Layout, Modal, Row, Select, Spin, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {IconButton} from "@mui/material";
import "./style.less";
import history from "../../../utils/history";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import {
  CaretDownOutlined, DeleteOutlined,
  FileZipOutlined, GlobalOutlined,
  HomeOutlined, LoadingOutlined, MailOutlined,
  PhoneOutlined, SearchOutlined, UserOutlined
} from "@ant-design/icons";
import {OrderExcelExportButton} from "../../../components/Order/OrderExcelExportButton";
import {ArchivedBulkOrderPartList} from "../../../components/Order/ArchivedBulkOrderPartList";
import {
  calculationToOrder, downloadOrderZip,
  fetchArchivedCalculation,
  fetchArchivedCalculationCustomerById,
  fetchArchivedCalculationCustomers, setGeneratingZip,
  deleteCalculation, fetchProducersForOrder
} from "../../../actions/ordersActions";
import {calcAdjustment} from "../../../utils/utility";
import ItemVisualisation from "../../../components/Order/ItemVisualisation";
import {CustomerGrid} from "../../../components/Order/CustomerGrid";
import DatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-GB";
registerLocale("en", en);
import {countries} from "../../../data/countries";
import PublicIcon from "@mui/icons-material/Public";
import {debounce} from "lodash";
import {ProducersPricesModal} from "../../../components/company/ProducersPricesModal";
import {DocumentsModal} from "../../../components/Order/DocumentsModal";
import {OrderViewOtherModal} from "../../../components/Order/OrderCalculationComponents/SharedComponents/OrderViewOtherModal";
import {OrderDownloadFilesModal} from "../../../components/Order/OrderCalculationComponents/SharedComponents/OrderDownloadFilesModal";
import { add, getDay } from "date-fns";

export const BulkOrderDetailsPage = () => {

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user);
  const archivedCalculation = useSelector(state => state.orders.archivedCalculation);
  const isFetchingArchivedCalculation = useSelector(state => state.orders.isFetchingArchivedCalculation);
  const archivedCalculationCustomers = useSelector(state => state.orders.archivedCalculationCustomers);
  const isFetchingArchivedCalculationCustomers = useSelector(state => state.orders.isFetchingArchivedCalculationCustomers);
  const isGeneratingZip = useSelector(state => state.orders.isGeneratingZip)

  const [filteredCustomers, setFilteredCustomers] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [isDetailsViewVisible, setIsDetailsViewVisible] = useState(false)
  const [detailsItem, setDetailsItem] = useState(null)

  const [viewVisible, setViewVisible] = useState(false)
  const [itemView, setItemView] = useState(null)
  const [modalWidth, setModalWidth] = useState(window.innerWidth-100)
  const [modalHeight, setModalHeight] = useState(window.innerHeight-135)

  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [selectedCustomerId, setSelectedCustomerId] = useState(null)

  const [selectedCustomerData, setSelectedCustomerData] = useState(null)
  const [fetchingCustomerData, setFetchingCustomerData] = useState(false)

  const [selectedDate, setSelectedDate] = useState(null)

  const [filesItem, setFilesItem] = useState(null)
  const [downloadFilesVisible, setDownloadFilesVisible] = useState(false)

  const [isCreatingOrder, setIsCreatingOrder] = useState(false)

  const [adjustment, setAdjustment] = useState(0)

  const [pricesModalItem, setPricesModalItem] = useState({});
  const [allPricesModalVisible, setAllPricesModalVisible] = useState(false);
  const [additionalDocsVisible, setAdditionalDocsVisible] = useState(false);
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const [collapsedExport, setCollapsedExport] = useState(window.innerWidth <= 670);

  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const { orderId } = useParams()

  const handleResize = () => {
    const width = window.innerWidth;

    if (width <= 670) {
      setCollapsedExport(true)
    } else {
      setCollapsedExport(false)
    }
    setModalWidth(width - 100);
    setModalHeight(window.innerHeight - 135);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  useEffect(()=>{
    if (user.role === "gelsoAdmin" || user.role === "gelsoSubAdmin" ) {
      dispatch(fetchProducersForOrder())
    }

    if(orderId) {
      dispatch(fetchArchivedCalculation({orderId}))
        .then(()=>{})
        .catch(() => {
          history.push('/calculation')
        })
      dispatch(fetchArchivedCalculationCustomers())
        .then((customers)=>{
          setFilteredCustomers(customers)
        })
        .catch(() => {
          history.push('/calculation')
        })
    } else {
      history.push('/calculation')
    }
  }, [])

  const handleDownloadZip = () => {
    dispatch(downloadOrderZip({orderId: archivedCalculation.id})).then((zipUrl)=>{
      setTimeout(()=>{
        const link = document.createElement('a');
        link.href = zipUrl;

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        dispatch(setGeneratingZip(false))
      }, 1000)
    }).catch(()=>{
      dispatch(setGeneratingZip(false))
    })
  }

  const showDetailsView = (item) => {
    setIsDetailsViewVisible(true)
    setDetailsItem(item)
  }

  const closeDetailsView = () => {
    setIsDetailsViewVisible(false)
  }

  const showItem = (item) => {
    setItemView({...item, viewType: "details"})
    setViewVisible(true)
  }

  const cancelShowItem = () => {
    setItemView(null)
    setViewVisible(false)
  }

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer)
    setSelectedCustomerId(customer.id)
  }

  useEffect(()=>{
    if(selectedCustomer && selectedCustomer.id) {
      setFetchingCustomerData(true)
      dispatch(fetchArchivedCalculationCustomerById({customerId: selectedCustomer.id})).then((customer)=>{
        setSelectedCustomerData(customer)
        form.setFieldsValue({
          name: customer?.name,
          email: customer?.email,
          phone: customer?.phone,
          country: customer?.country,
          city: customer?.city,
          postalCode: customer?.postalCode,
          address: customer?.address
        })
        setAdjustment(customer.adjustment)
        setFetchingCustomerData(false)
      }).catch(()=>{})
    }
  }, [selectedCustomer])

  const handleDateChange = date => {
    let datum = new Date(date)
    if(datum < new Date()) {
      datum = null
    }
    setSelectedDate(datum)
  };

  const escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const filterCustomers = (data) => {
    if(!data.text) {
      setFilteredCustomers(data.archivedCalculationCustomers)
    } else {
      const customers = data.archivedCalculationCustomers.filter(item => item.name.toLowerCase().search(escapeRegExp(data.text.toLowerCase())) !== -1)
      setFilteredCustomers(customers)
    }
    setIsSearching(false)
  }

  const debounceSearchCustomers = useCallback(
    debounce(data => filterCustomers(data), 800),
    []
  );

  const searchCustomers = (text) => {
    setIsSearching(true)
    debounceSearchCustomers({archivedCalculationCustomers,text})
  }

  const handleCreateOrder = (values) => {
    setIsCreatingOrder(true)
    let data = {
      deliveryDate: values.deliveryDate,
      customerId: selectedCustomerId,
      orderId: archivedCalculation.id,
      customerType: selectedCustomerData.type,
      shippingData: {
        country: values.country,
        city: values.city,
        postalCode: values.postalCode,
        address: values.address,
        phone: values.phone
      }
    }

    dispatch(calculationToOrder(data)).then(()=>{
      setIsCreatingOrder(false)
    }).catch(()=>{
      setIsCreatingOrder(false)
    })
  }

  const handleShowDownloadFiles = (item) => {
    setFilesItem(item)
    setDownloadFilesVisible(true)
  }

  const handleCloseDownloadFiles = () => {
    setDownloadFilesVisible(false)
    setFilesItem(null)
  }

  const handleDeleteCalculation = (calculationId, e) => {
    dispatch(deleteCalculation({calculationId})).then(()=>{
      e()
      history.push('/calculation')
    }).catch(()=>{
      e()
    })
  }

  const onDeleteCalculation = (calculationId) => {
    let modal = Modal.confirm({
      className: "inputInfoText",
      visible: true,
      style: {minWidth: '500px', maxHeight: '90vh', marginTop: -50},
      centered: true,
      onOk: (e) => {
        modal.update(prevConfig => ({
          ...prevConfig,
          cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
          okButtonProps: {...prevConfig.okButtonProps, loading: true}
        }));
        handleDeleteCalculation(calculationId, e)
      },
      confirmLoading: true,
      destroyOnClose: true,
      width: 500,
      title: <span style={{ color: "white" }}>{"Delete Calculation"}</span>,
      content: <div style={{ color: "white" }}>
        <div>Are you sure you want to DELETE this Calculation?</div>
        <div>This action cannot be undone.</div>
        <Divider
          className={"lightDivider"}
          style={{
            marginBottom: 0,
            position: 'relative',
            right: 36,
            width: 436
        }}/>
      </div>,
      okText: <span style={{fontSize: 13, fontWeight: 500}}>Delete</span>,
      okButtonProps: {type: "danger", loading: false},
      cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
      icon: <DeleteOutlined style={{color: "#ff5b2d"}}/>,
      zIndex: 2,
    })
  }

  const handleOpenDocsModal = (docs) => {
    setAdditionalDocs(docs)
    setAdditionalDocsVisible(true);
  }

  const handleCloseDocsModal = () => {
    setAdditionalDocsVisible(false);
    setAdditionalDocs([])
  }

  const handleOpenPricesModal = (item) => {
    setPricesModalItem(item);
    setAllPricesModalVisible(true);
  }

  const handleClosePricesModal = () => {
    setAllPricesModalVisible(false);
    setPricesModalItem({});
  }

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={() => dispatch(logoutUser({}))}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <Spin
          spinning={isFetchingArchivedCalculation || isFetchingArchivedCalculationCustomers}
          wrapperClassName={"fetchingOrderSpin"}
          indicator={
            <div style={{position: "absolute", top: "35%", left: "50%", width: 400, transform: "translateX(-50%)", color: "#ff5b2d"}}>
              <LoadingOutlined style={{ fontSize: 72 }} spin />
              <div style={{fontSize: 27, fontWeight: 500, marginTop: 10}}>Fetching Your Calculation</div>
            </div>
          }
        >
          <div style={{overflowX: 'hidden', minHeight: "100vh"}}>
            <div style={{marginRight: 15, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
              {archivedCalculation.id && !isFetchingArchivedCalculation && !isFetchingArchivedCalculationCustomers &&
                <Row gutter={[15, 10]}>
                  <Col span={24} order={1}>
                    <Card className={"orderDetailsHeader"}>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                          <div style={{marginRight: 5}}>
                            <IconButton
                              color="primary"
                              aria-label="back to pending quotes"
                              size="small"
                              style={{padding: 5}}
                              onClick={()=>{
                                history.push('/calculation')
                              }}
                            >
                              <ArrowBackIosRoundedIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                          <div className={"orderDetailsTitle"}>
                            {archivedCalculation.customName || archivedCalculation.name}
                          </div>
                        </div>
                        <div align={"right"}>
                          <Button
                            type="danger"
                            style={{
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                            onClick={()=>{onDeleteCalculation(archivedCalculation.id)}}
                            loading={false}
                          >
                            <DeleteOutlined  style={{fontSize: 14}}/>
                            Delete
                          </Button>
                          <Divider type={"vertical"} style={{marginRight: 10, marginLeft: 10, borderLeft: "1px solid #cccccc", height: 27}}/>
                          <OrderExcelExportButton
                            role={user?.role}
                            name={archivedCalculation.customName || archivedCalculation.name}
                            isEditing={false}
                            parts={archivedCalculation.parts || []}
                            adjustment={adjustment}
                            collapsed={collapsedExport}
                          />
                          <Button
                            type="primary"
                            style={{
                              marginRight: 5,
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                            onClick={handleDownloadZip}
                            loading={isGeneratingZip}
                          >
                            <FileZipOutlined style={{fontSize: 14}}/>
                            {collapsedExport ? "Files" : "Download Files"}
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xxl={9} xl={10} lg={{span: 12, order: 2}} span={24} order={3}>
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      Parts in Calculation
                    </div>
                    <ArchivedBulkOrderPartList
                      parts={archivedCalculation.parts || []}
                      showOtherItem={showDetailsView}
                      showDetailsItem={showItem}
                      handleShowDownloadFiles={handleShowDownloadFiles}
                      handleOpenDocsModal={handleOpenDocsModal}
                      handleOpenPricesModal={handleOpenPricesModal}
                      userRole={user.role}
                      adjustment={adjustment}
                    />
                  </Col>
                  <Col xxl={15} xl={14} lg={{span: 12, order: 3}} span={24} order={2}>
                    <div
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: "white"
                      }}
                    >
                      Summary
                    </div>
                    <div className={"summaryCard"}>
                      <Row>
                        <Col span={24}>
                          <Card
                            className={"summaryCardInfo"}
                            bodyStyle={{height: "100%", width: "100%", padding: "0 12px 0 12px", display: "flex", alignItems: "center"}}
                          >
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start  ", width: "100%", flexDirection: "column", whiteSpace: "nowrap", paddingRight: 10}}>
                                <div style={{fontSize: 13, fontWeight: 600, display: "flex", flexDirection: "column"}}>
                                  <span style={{width: 130, display: "inline-block"}}>Total Price:</span>
                                  {adjustment !== 0 ?
                                    <span style={{fontWeight: 600, color: "#04cc04"}}>{Number(archivedCalculation.totalPrice * calcAdjustment(adjustment)).toFixed(2)} €</span>
                                    :
                                    <span style={{fontWeight: 600}}>{Number(archivedCalculation.totalPrice).toFixed(2)} €</span>
                                  }
                                </div>
                              </div>
                              <div style={{ maxWidth: 350, width: "100%", justifyContent: "flex-end", alignItems: "flex-end", marginTop: -4}}>
                                <div style={{fontSize: 13, fontWeight: 500, marginRight: 10, whiteSpace: "nowrap", marginBottom: 2}}>
                                  Search Customers:
                                </div>
                                <div>
                                  <Input
                                    allowClear
                                    placeholder={"Type to search"}
                                    onChange={(e)=>searchCustomers(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                        <Col span={24}>
                          <Card
                            style={{borderRadius: 5, width: "100%", border: "none"}}
                            bodyStyle={{width: "100%", padding: "6px 6px 6px 6px"}}
                          >
                            <div style={{ paddingBottom: 4, paddingLeft: 6, fontWeight: 500 }}>Select a customer to create a quote:</div>
                            <div style={{height: 'calc(100vh - 677px)', overflowY: "auto", overflowX: "hidden", minHeight: 150}}>
                              <CustomerGrid
                                customers={(filteredCustomers || [])}
                                itemCount={(filteredCustomers || []).length}
                                itemSize={300}
                                selectCustomer={handleSelectCustomer}
                                selectedId={selectedCustomerId}
                                searching={isSearching}
                                emptyText={"No Customers"}
                              />
                            </div>
                            <Divider style={{marginTop: 5, marginBottom: 5}}/>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                    <div style={{marginTop: 15}}>
                      {!selectedCustomerData && fetchingCustomerData &&
                        <Spin
                          spinning={true}
                          indicator={<LoadingOutlined style={{ fontSize: 48, marginLeft: -24, marginTop: -24 }} spin />}
                          wrapperClassName={"borderRadiusSpin"}
                        >
                          <div style={{height: 300}}>
                          </div>
                        </Spin>
                      }
                      {selectedCustomerData &&
                        <Spin
                          spinning={fetchingCustomerData}
                          indicator={<LoadingOutlined style={{ fontSize: 48, marginLeft: -24, marginTop: -24 }} spin />}
                          wrapperClassName={fetchingCustomerData ? "borderRadiusSpin" : "borderRadiusSpin visibleOverflow"}
                        >
                        <Card
                          style={{
                            boxShadow: "0 0 24px 0 rgba(0, 0, 0, 0.13)",
                            border: '1px solid #e0e0e0',
                            borderRadius: 5
                          }}
                          bodyStyle={{
                            padding: "10px 15px 10px 15px"
                          }}
                        >
                          <Form
                            name={"shippingForm"}
                            form={form}
                            onFinish={handleCreateOrder}
                          >
                            <Row gutter={30}>
                              <Col span={24} style={{borderBottom: "1px dashed rgba(0, 0, 0, 0.5)"}}>
                                <div style={{display: "flex", justifyContent: "space-between", marginBottom: 8}}>
                                  <div style={{display: "flex"}}>
                                    <div
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "white"
                                      }}
                                    >
                                      Delivery date
                                    </div>
                                    <div style={{marginLeft: 20, marginTop: -3}}>
                                      <Form.Item
                                        name={"deliveryDate"}
                                        rules={[
                                          {required: true, message: ""}
                                        ]}
                                        style={{marginBottom: 0, height: 32}}
                                        autoComplete="off"
                                        initialValue={null}
                                      >
                                        <DatePicker
                                          selected={selectedDate}
                                          onChange={handleDateChange}
                                          dateFormat={'dd.MM.yyyy'}
                                          locale="en"
                                          minDate={add(new Date(),{ days: 1 })}
                                          isClearable={true}
                                          autoComplete="off"
                                          filterDate={isWeekday}
                                          placeholderText="Click to select a date"
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                  <div>
                                  </div>
                                </div>
                              </Col>
                              {(screens['xl'] || !screens['lg']) &&
                                <Col
                                  xl={12}
                                  xs={24}
                                  style={{
                                    borderRight: "1px dashed rgba(0, 0, 0, 0.5)",
                                    borderBottom: "1px dashed rgba(0, 0, 0, 0.5)"
                                  }}
                                >
                                  <Row gutter={10} style={{marginTop: 8}}>
                                    <Col xxl={12} xl={24} xs={12}>
                                      <div className={"orderFormLabel"}>Full Name:</div>
                                      <Form.Item
                                        name={"name"}
                                        rules={[
                                          {required: true, message: ""}
                                        ]}
                                        style={{marginBottom: 8, height: 32}}
                                        initialValue={selectedCustomerData.name}
                                      >
                                        <Input
                                          prefix={<UserOutlined/>}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xxl={12} xl={24} xs={12}>
                                      <div className={"orderFormLabel"}>Email:</div>
                                      <Form.Item
                                        name={"email"}
                                        rules={[
                                          {required: true, message: ""}
                                        ]}
                                        style={{marginBottom: 8, height: 32}}
                                        initialValue={selectedCustomerData.email}
                                      >
                                        <Input
                                          prefix={<MailOutlined/>}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                      <div className={"orderFormLabel"}>Phone:</div>
                                      <Form.Item
                                        name={"phone"}
                                        rules={[
                                          {required: true, message: ""}
                                        ]}
                                        style={{marginBottom: 8, height: 32}}
                                        initialValue={selectedCustomerData.phone}
                                      >
                                        <Input
                                          placeholder={"Enter contact phone"}
                                          prefix={<PhoneOutlined/>}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>
                              }
                              <Col
                                xl={12}
                                xs={24}
                                style={{borderBottom: "1px dashed rgba(0, 0, 0, 0.5)", paddingBottom: 4}}
                              >
                                <div className={"orderFormLabel"} style={{ marginTop: 8 }}>Country:</div>
                                <Form.Item
                                  name={"country"}
                                  rules={[
                                    {required: true, message: ""}
                                  ]}
                                  className={"shippingArchivedFormItem"}
                                  initialValue={selectedCustomerData.country}
                                >
                                  <Select
                                    showSearch
                                    style={{width: '100%'}}
                                    prefix={<GlobalOutlined/>}
                                    suffixIcon={(e) => {
                                      return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                                    }}
                                    placeholder="Select your country"
                                    optionFilterProp="children"
                                    notFoundContent={"Country not found"}
                                    filterOption={(input, option) =>
                                      option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    autoComplete="new-password"
                                  >
                                    {
                                      countries.map(item => {
                                        return (
                                          <Select.Option
                                            key={item.name}
                                            value={item.name}
                                            className={"countrySelect"}
                                          >
                                            <img
                                              src={item.flag}
                                              alt=""
                                              style={{
                                                position: "relative",
                                                top: -2,
                                                width: 18
                                              }}
                                            /> {item.name}
                                          </Select.Option>
                                        )
                                      })
                                    }
                                  </Select>
                                </Form.Item>
                                <Row gutter={10}>
                                  <Col span={13}>
                                    <div className={"orderFormLabel"}>City:</div>
                                    <Form.Item
                                      name={"city"}
                                      rules={[
                                        {required: true, message: ""}
                                      ]}
                                      className={"shippingArchivedFormItem"}
                                      initialValue={selectedCustomerData.city}
                                    >
                                      <Input
                                        placeholder={"Enter country"}
                                        prefix={<PublicIcon style={{fontSize: 17}}/>}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={11}>
                                    <div className={"orderFormLabel"}>Postal Code:</div>
                                    <Form.Item
                                      name={"postalCode"}
                                      className={"shippingArchivedFormItem"}
                                      rules={[
                                        {required: true, message: ""}
                                      ]}
                                      initialValue={selectedCustomerData.postalCode}
                                    >
                                      <Input
                                        placeholder={"Enter postal code"}
                                        prefix={<PublicIcon style={{fontSize: 17}}/>}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <div className={"orderFormLabel"}>Address:</div>
                                <Form.Item
                                  name={"address"}
                                  rules={[
                                    {required: true, message: ""}
                                  ]}
                                  className={"shippingArchivedFormItem"}
                                  initialValue={selectedCustomerData.address}
                                >
                                  <Input
                                    placeholder={"Enter delivery address"}
                                    prefix={<HomeOutlined/>}
                                  />
                                </Form.Item>
                                {!screens['xl'] && screens['lg'] &&
                                  <div>
                                    <div className={"orderFormLabel"}>Phone:</div>
                                    <Form.Item
                                      name={"phone"}
                                      rules={[
                                        {required: true, message: ""}
                                      ]}
                                      style={{marginBottom: 8, height: 32}}
                                      initialValue={selectedCustomerData.phone}
                                    >
                                      <Input
                                        placeholder={"Enter contact phone"}
                                        prefix={<PhoneOutlined/>}
                                      />
                                    </Form.Item>
                                  </div>
                                }
                              </Col>
                            </Row>
                            <div align={"right"} style={{marginTop: 10}}>
                              <Tooltip
                                color={"rgba(89,89,89,0.88)"}
                                overlayClassName={!selectedDate ? "small-tooltip" : "small-tooltip dont-show-wrapper"}
                                size={"small"}
                                title={<span className={"text12-500"} style={{color: "#ffffff"}}>Pick delivery date!</span>}
                              >
                                <Button
                                  type={"primary"}
                                  key={"submit"}
                                  htmlType={"submit"}
                                  disabled={!selectedDate}
                                  loading={isCreatingOrder}
                                >
                                  <span
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500
                                    }}
                                  >
                                    Create Quote
                                  </span>
                                </Button>
                              </Tooltip>
                            </div>
                          </Form>
                        </Card>
                        </Spin>
                      }
                    </div>
                  </Col>
                </Row>
              }
            </div>
          </div>
        </Spin>
        <ItemVisualisation
          isModelViewVisible={viewVisible}
          itemView={itemView}
          modalWidth={modalWidth}
          modalHeight={modalHeight}
          onCloseView={cancelShowItem}
          adjustment={adjustment}
        />

        <OrderViewOtherModal
          viewOtherVisible={isDetailsViewVisible}
          closeOtherView={closeDetailsView}
          item={detailsItem}
          showMaterialGroup={false}
          showPrice={true}
        />

        <OrderDownloadFilesModal
          isVisible={downloadFilesVisible}
          onCancel={() => handleCloseDownloadFiles()}
          filesItem={filesItem}
        />

        <DocumentsModal
          visible={additionalDocsVisible}
          handleCloseDocsModal={handleCloseDocsModal}
          additionalDocs={additionalDocs}
        />

        <ProducersPricesModal
          visible={allPricesModalVisible}
          modalItem={pricesModalItem}
          onClose={handleClosePricesModal}
          applyAdjustment={true}
          compMaxWidth={514}
        />
      </Layout.Content>
    </Layout>
  )
}