import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  Table, Layout, Row, Col, Button,
  Modal, Form, InputNumber, Divider, List,
  Checkbox, Input, Dropdown, Menu, Tag, Card, Spin,
} from 'antd'

import {CompanySidebar} from '../../../components/company'
import {logoutUser} from '../../../actions/authActions'
import {
  fetchInternalRelations,
  changeCustomerStatus,
  changeCustomerLimit,
  changeCustomerAdjustment,
  fetchPendingCustomers,
  authorizeCustomer, reloadPendingCustomers
} from '../../../actions/customerRelations'
import {LoadingOutlined, MoreOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {getColumnSearchProps, useWindowSize} from "../../../utils/utility";
import "./style.less";
import {EmptyTable} from "../../../components/EmptyTable";
import {PendingCustomerCard} from "../../../components/company/CustomerRelations/PendingCustomerCard";
import {PendingCustomerGrid} from "../../../components/company/CustomerRelations/PendingCustomerGrid";

export const InternalCustomerRelations = () => {
  let searchInput = null;
  const windowSize = useWindowSize();
  const breakThreshold = 1340;

  const [isLimitVisible, setIsLimitVisible] = useState(false);
  const [isAdjustmentVisible, setIsAdjustmentVisible] = useState(false);
  const [details, setDetails] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [shouldBreak, setShouldBreak] = useState(windowSize.width < breakThreshold);

  const [filteredInfo, setFilteredInfo] = useState({})

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user);
  const relations = useSelector(state => state.customerRelations.internalCustomerRelations);
  const isFetchingCustomerRelations = useSelector(state => state.customerRelations.fetchingInternalCustomerRelations);
  const isUpdatingCustomer = useSelector(state => state.customerRelations.updatingInternalCustomer);
  const pagination = useSelector(state => state.customerRelations.internalCustomerRelationsPagination);
  const unverifiedCustomers = useSelector(state => state.customerRelations.unauthorizedCustomers)
  const fetchingUnauthorizedCustomers = useSelector(state => state.customerRelations.fetchingUnauthorizedCustomers);

  const dispatch = useDispatch();

  useEffect(() => {
    setShouldBreak(windowSize.width < breakThreshold);
  }, [windowSize.width])

  const getPageSize = () => {
    const count = Math.floor((window.innerHeight - 150) / 50)
    return count > 10 ? count : 10;
  }

  const pageSize = getPageSize();

  const initializeData = () => {
    const email = filteredInfo.email ? filteredInfo.email[0] : ""
    const name = filteredInfo.name ? filteredInfo.name[0] : ""

    dispatch(fetchInternalRelations({
      email: email,
      name: name,
      page: pagination.current,
      pagination: pagination
    }))
    dispatch(fetchPendingCustomers({}))
  }

  const reloadData = () => {
    const email = filteredInfo.email ? filteredInfo.email[0] : ""
    const name = filteredInfo.name ? filteredInfo.name[0] : ""

    dispatch(fetchInternalRelations({
      email: email,
      name: name,
      page: pagination.current,
      pagination: pagination
    }))
    dispatch(reloadPendingCustomers({}))
  }

  useEffect(() => {
    dispatch(fetchInternalRelations({
      email: "",
      name: "",
      page: 1,
      pagination: {...pagination, pageSize: pageSize}
    }))
  }, [])

  useEffect(() => {
    dispatch(fetchPendingCustomers({}))
  }, [])

  const handleLimitClose = () => {
    setIsLimitVisible(false);
    setDetails(null);
    setHasChanges(false);
  }
  const handleAdjustmentClose = () => {
    setIsAdjustmentVisible(false);
    setDetails(null);
    setHasChanges(false);
  }

  const handleTableChange = (pagination, filters) => {
    const pager = { ...pagination };
    pager.current = pagination.current

    const email = filters.email ? filters.email[0] : ""
    const name = filters.name ? filters.name[0] : ""

    setFilteredInfo(filters)

    dispatch(fetchInternalRelations({
      email: email,
      name: name,
      page: pagination.current,
      pagination: pager
    }))
  }

  const handleChangeCustomerState = (customerId, isBlocked, e) => {
    dispatch(changeCustomerStatus({customerId, isBlocked})).then(()=>{
      e()
      initializeData()
    }).catch(()=>{
      e()
    })
  }

  const onToggleCustomerStatus = (isBlocked, customerId) => {
    if (isBlocked) {
      let modal = Modal.confirm({
        className: "inputInfoText",
        visible: true,
        style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
        centered: true,
        onOk: (e) => {
          modal.update(prevConfig => ({
            ...prevConfig,
            cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
            okButtonProps: {...prevConfig.okButtonProps, loading: true}
          }));
          handleChangeCustomerState(customerId, !isBlocked, e)
        },
        confirmLoading: true,
        destroyOnClose: true,
        width: 400,
        title: <span style={{ color: "white"}}>{"Activate Customer"}</span>,
        content: <div>
          <div style={{ color: "white"}}>Are you sure you want to ACTIVATE this Customer?</div>
        </div>,
        okText: <span style={{fontSize: 13, fontWeight: 500}}>Activate</span>,
        okButtonProps: {type: "primary", loading: false},
        cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
        icon: <QuestionCircleOutlined style={{color: "#ffc739"}}/>,
        zIndex: 2,
      })
    } else {
      let modal = Modal.confirm({
        className: "inputInfoText",
        visible: true,
        style: {minWidth: '400px', maxHeight: '90vh', marginTop: -50},
        centered: true,
        onOk: (e) => {
          modal.update(prevConfig => ({
            ...prevConfig,
            cancelButtonProps: {...prevConfig.cancelButtonProps, disabled: true},
            okButtonProps: {...prevConfig.okButtonProps, loading: true}
          }));
          handleChangeCustomerState(customerId, !isBlocked, e)
        },
        confirmLoading: true,
        destroyOnClose: true,
        width: 400,
        title: <span style={{color: "white"}}>Block Customer</span>,
        content: <div>
          <div style={{color: "white"}}>Are you sure you want to BLOCK this Customer?</div>
        </div>,
        okText: <span style={{fontSize: 13, fontWeight: 500}}>Block</span>,
        okButtonProps: {type: "danger", loading: false},
        cancelText: <span style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
        icon: <QuestionCircleOutlined style={{color: "#ffc739"}}/>,
        zIndex: 2,
      })
    }
  }

  const handleLimitUpdate = (values) => {
    dispatch(changeCustomerLimit({customerId: details.id, partLimit: values.partLimit, limited: !values.unlimited})).then(()=>{
      handleLimitClose()
      initializeData()
    }).catch(()=>{})
  }

  const handleAdjustmentUpdate = (values) => {
    dispatch(changeCustomerAdjustment({customerId: details.id, adjustment: values.adjustment / 100})).then(()=>{
      handleAdjustmentClose()
      initializeData()
    }).catch(()=>{})
  }

  const handleAuthorizeCustomer = (values) => {
    dispatch(authorizeCustomer({customerId: values.customerId, isAuthorized: values.isAuthorized})).then(() => {
      reloadData()
    }).catch(() => {})
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      <CompanySidebar
        onLogout={() => {dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, paddingLeft: 25, paddingRight: 25, paddingTop: 15, transition: "all 0.25s"}}>
        <Row type="flex">
          <Col span={24}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={"pageTitle"}>Customer relations:</div>
            </div>
            <Divider style={{marginTop: 6, marginBottom: 11}}/>
          </Col>
        </Row>
        <Row type={"flex"} gutter={15}>
          <Col span={shouldBreak ? 24 : 18} order={shouldBreak ? 2 : 1}>
            <div className={"pageSubtitle"}>
              Customers
            </div>
            <Table
              columns={[
                {
                  title: 'Name',
                  className: "darkerColumn",
                  key: 'name',
                  width: "29%",
                  render: row => (
                    <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#999": "inherit"}}>
                      {row.name}
                    </div>
                  ),
                  ...getColumnSearchProps('name', searchInput, "name"),
                },
                {
                  title: 'Email',
                  key: 'email',
                  width: "29%",
                  render: row => (
                    <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#999": "inherit" }}>
                      {row.email}
                    </div>
                  ),
                  ...getColumnSearchProps('email', searchInput, "email"),
                },
                {
                  title: 'Part Limit',
                  className: "darkerColumn",
                  key: 'limit',
                  align: "right",
                  width: "15%",
                  render: row => (
                    <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#999": "inherit" }}>
                      {row.limited ? row.partLimit : "Unlimited"}
                    </div>
                  ),
                },
                {
                  title: 'Adjustment',
                  key: 'discount',
                  align: "right",
                  width: "15%",
                  render: row => (
                    <div style={{fontWeight: 500, fontSize: 13, color: row.isBlocked ? "#999": "inherit" }}>
                      {Number(row.adjustment * 100).toFixed(2)}%
                    </div>
                  ),
                },
                {
                  title: 'Customer Type',
                  className: "darkerColumn column190",
                  key: 'status',
                  align: "center",
                  width: "14%",
                  render: row => (
                    <div>
                      {row.role === "regularCustomer" ?
                        <Tag color="#1c84e0" style={{fontWeight: 600, textAlign: "center", width: 150, marginRight: 0}}>Private Customer</Tag>
                        :
                        <Tag color="#9966cc" style={{fontWeight: 600, textAlign: "center", width: 150, marginRight: 0}}>Corporate Customer</Tag>
                      }
                    </div>
                  ),
                },
                {
                  title: 'Status',
                  key: 'status',
                  className: 'column190',
                  align: "center",
                  width: "12%",
                  render: row => (
                    <div>
                      {!row.isBlocked ?
                        <Tag color="#6bce34" style={{fontWeight: 600, textAlign: "center", width: 104, marginRight: 0}}>Active</Tag>
                        :
                        <Tag color="#e03838" style={{fontWeight: 600, textAlign: "center", width: 104, marginRight: 0}}>Blocked</Tag>
                      }
                    </div>
                  ),
                },
                {
                  title: '',
                  key: 'edit',
                  width: "20px",
                  align: "right",
                  render: row => (
                    <Dropdown
                      overlay={
                        <Menu className={'employeeEditMenu'}>
                          {!row.isBlocked &&
                            <Menu.Item
                              className={"employeeEditMenuItem"}
                              key='1'
                              disabled={row.isBlocked}
                              onClick={()=>{
                                setIsLimitVisible(true);
                                setDetails(row);
                                setHasChanges(false);
                              }}
                            >
                              <span>
                                Edit Limit
                              </span>
                            </Menu.Item>
                          }
                          {!row.isBlocked &&
                            <Menu.Item
                              className={"employeeEditMenuItem"}
                              key='2'
                              disabled={row.isBlocked}
                              onClick={()=>{
                                setIsAdjustmentVisible(true);
                                setDetails(row);
                                setHasChanges(false);
                              }}
                            >
                              <span>
                              Edit Adjustment
                              </span>
                            </Menu.Item>
                          }
                          <Menu.Item
                            key='3'
                            onClick={()=>{onToggleCustomerStatus(row.isBlocked, row.id)}}
                            className={"toggleBlockOption employeeEditMenuItem"}
                          >
                            <span className={row.isBlocked ? "blockedOption" : "activeOption"}>
                              {!row.isBlocked ? "Block" : "Activate"}
                            </span>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <MoreOutlined style={{fontSize: 20, fontWeight: 'bold'}} />
                    </Dropdown>
                  ),
                },
              ]}
              className={"antTable"}
              dataSource={relations}
              rowKey="id"
              pagination={pagination}
              loading={{
                spinning: isFetchingCustomerRelations,
                indicator: <LoadingOutlined style={{fontSize: 50, marginLeft: "-25px"}} spin/>
              }}
              onChange={handleTableChange}
              size={"small"}
              scroll={{x: '70%'}}
              locale={{ emptyText: <EmptyTable text={"No Data"} /> }}
            />
          </Col>
          <Col span={shouldBreak ? 24 : 6} order={shouldBreak ? 1 : 2}>
            <div className={"pageSubtitle"}>
              Pending for Authorization
            </div>
            <div className={"summaryCard"}>
              <Row>
                <Col span={24}>
                  <Spin
                    spinning={fetchingUnauthorizedCustomers}
                    indicator={
                      <div align={"center"}>
                        <LoadingOutlined style={{ fontSize: 50, marginLeft: -20 }} spin />
                      </div>
                    }
                    wrapperClassName={"borderRadiusSpin"}
                  >
                  <Card
                    style={{borderRadius: 5, width: "100%", border: "none"}}
                    bodyStyle={{width: "100%", padding: shouldBreak ? "7px 0 3px 3px" : "0 0 6px 6px"}}
                  >
                    <div className={"pendingContainer"}
                         style={{height: shouldBreak ? 210 : 'calc(100vh - 165px)'}}
                    >
                      {shouldBreak ? (
                        <PendingCustomerGrid
                          onCustomerAuth={handleAuthorizeCustomer}
                          customers={unverifiedCustomers}
                          itemCount={unverifiedCustomers.length}
                          itemSize={320}
                          searching={false}
                          isFetchingCustomers={false}
                          emptyText={"No Pending Customers"}
                        />
                      ) : (
                        unverifiedCustomers.length > 0 ?
                          <List
                            className={"pendingCustomersList"}
                            dataSource={unverifiedCustomers}
                            renderItem={(item) => (
                              <PendingCustomerCard
                                onCustomerAuth={handleAuthorizeCustomer}
                                item={item}
                                key={item.id}
                                count={0}
                                itemCount={unverifiedCustomers.length}
                              />
                            )}
                          />
                          :
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
                            <EmptyTable text={"No Pending Customers"}/>
                          </div>
                      )}
                    </div>
                  </Card>
                  </Spin>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Modal
          onCancel={handleLimitClose}
          title="Update Customer Limit"
          visible={isLimitVisible}
          footer={null}
          centered
          bodyStyle={{
            padding: 12,
          }}
          maskClosable={false}
          destroyOnClose
        >
          {details && (
            <React.Fragment>
              <Form
                className={"customerUpdateForm"}
                onFinish={handleLimitUpdate}
              >
                <div className={"infoBox"}>
                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 195, whiteSpace: "nowrap" }}
                    >
                      Customer Name:
                    </div>
                    <Input
                      defaultValue={details.name}
                      disabled
                    />
                  </div>

                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 195, whiteSpace: "nowrap" }}
                    >
                      Email:
                    </div>
                    <Input
                      defaultValue={details.email}
                      disabled
                    />
                  </div>

                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 130, whiteSpace: "nowrap" }}
                    >
                      Unlimited:
                    </div>
                    <Form.Item
                      initialValue={!details.limited}
                      valuePropName={"checked"}
                      name={"unlimited"}
                    >
                      <Checkbox
                        style={{ marginLeft: 2 }}
                        onChange={(e) => {
                          setDetails(prev => ({...prev, limited: !e.target.checked}))
                          setHasChanges(true);
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className={"inlineField"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 13, width: 130, whiteSpace: "nowrap" }}
                    >
                      Part Limit:
                    </div>
                    <div className={"customerUpdateFormItemContainer"}>
                      <Form.Item name={"partLimit"} initialValue={details.partLimit}>
                        <InputNumber
                          disabled={!details.limited}
                          formatter={value => value ? value : 0}
                          parser={value => value ? value : 0}
                          precision={0}
                          onChange={() => {
                            setHasChanges(true);
                          }}
                          style={{width: 120}}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <Divider style={{ borderColor: "black", margin: "24px 0 12px 0"}}/>
                  <div className={"updateButton"} align={"right"} style={{ width: "100%" }}>
                    <Form.Item>
                      <Button type={"primary"}
                              htmlType={"submit"}
                              style={{ fontWeight: 600, fontSize: 13, marginTop: 12 }}
                              disabled={!hasChanges}
                              loading={isUpdatingCustomer}
                      >
                        Update Customer
                      </Button>
                    </Form.Item>
                  </div>

                </div>
              </Form>
            </React.Fragment>
          )}
        </Modal>
        <Modal
          onCancel={handleAdjustmentClose}
          title="Update Customer Adjustment"
          visible={isAdjustmentVisible}
          footer={null}
          centered
          bodyStyle={{
            padding: 12,
          }}
          maskClosable={false}
          destroyOnClose
        >
          {details && (
            <React.Fragment>
              <Form
                className={"customerUpdateForm"}
                onFinish={handleAdjustmentUpdate}
              >
                <div className={"infoBox"}>
                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 195, whiteSpace: "nowrap" }}
                    >
                      Customer Name:
                    </div>
                    <Input
                      defaultValue={details.name}
                      disabled
                    />
                  </div>

                  <div className={"detailEntry"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 12, width: 195, whiteSpace: "nowrap" }}
                    >
                      Email:
                    </div>
                    <Input
                      defaultValue={details.email}
                      disabled
                    />
                  </div>

                  <div className={"inlineField"}>
                    <div align={"right"} className={"inputInfoText"}
                         style={{ marginRight: 13, width: 130, whiteSpace: "nowrap" }}
                    >
                      Adjustment:
                    </div>
                    <div className={"customerUpdateFormItemContainer"}>
                      <Form.Item
                        name={"adjustment"}
                        initialValue={details.adjustment * 100}
                      >
                        <InputNumber
                          formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)}%`}
                          parser={value => value ? value.replace('%', '') : 0}
                          onChange={() => {
                            setHasChanges(true);
                          }}
                          min={-100}
                          max={100000}
                          style={{width: 120}}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <Divider style={{ borderColor: "black", margin: "24px 0 12px 0"}}/>
                  <div className={"updateButton"} align={"right"} style={{ width: "100%" }}>
                    <Form.Item>
                      <Button type={"primary"}
                              htmlType={"submit"}
                              style={{ fontWeight: 600, fontSize: 13, marginTop: 12 }}
                              disabled={!hasChanges}
                              loading={isUpdatingCustomer}
                      >
                        Update Customer
                      </Button>
                    </Form.Item>
                  </div>

                </div>
              </Form>
            </React.Fragment>
          )}
        </Modal>
      </Layout.Content>
    </Layout>
  )
}
