
import {
  UTIL_COLLAPSE, UTIL_IS_FETCHING_STATS, UTIL_UPDATE_ACTIVE_SUBMENU_KEYS, UTIL_UPDATE_CUSTOMER_ACTIVE_SUBMENU_KEYS
} from "./actionTypes";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";

const collapseSidebar = (collapseState) => dispatch => {
  dispatch({
    type: UTIL_COLLAPSE,
    payload: collapseState
  })
}

const updateActiveSubmenuKeys = (submenuKey) => dispatch => {
  dispatch({
    type: UTIL_UPDATE_ACTIVE_SUBMENU_KEYS,
    payload: submenuKey
  })
}

const updateActiveCustomerSubmenuKeys = (submenuKey) => dispatch => {
  dispatch({
    type: UTIL_UPDATE_CUSTOMER_ACTIVE_SUBMENU_KEYS,
    payload: submenuKey
  })
}

const fetchStats = (payload) => dispatch => {
  dispatch({
    type: UTIL_IS_FETCHING_STATS,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/stats/draft-order`, payload)
    .then((response) => {
      dispatch({
        type: UTIL_IS_FETCHING_STATS,
        payload: false
      })
      return response.data
    })
    .catch((error) => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: UTIL_IS_FETCHING_STATS,
          payload: false
        })
        throw error
      }
    })
}

export {
  collapseSidebar, updateActiveSubmenuKeys,
  updateActiveCustomerSubmenuKeys, fetchStats
};