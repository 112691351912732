import React from 'react'

const Link = ({ to, history, children, className="" }) => (
  <a
    className={className}
    href={to}
    style={{fontWeight: 400, fontSize: 12, color: "black"}}
    onClick={event => {
      event.preventDefault()
      history.push(to)
    }}>
    {children}
  </a>
)

export default Link
