import {
  BENDING_IS_FETCHING_BENDING_DATA, BENDING_SET_BENDING_DATA,
  BENDING_SET_BENDING_SERVICE, BENDING_SET_BENDING_MAX_LENGTH,
  BENDING_SET_BENDING_PREPARATION_PRICE, BENDING_SET_BENDING_AVERAGE_PREPARATION_TIME,
  BENDING_FETCHING_SINGLE_MATERIAL_BENDS, BENDING_SET_MATERIAL_BENDING_DATA,
  BENDING_RESET_BENDS_DATA, BENDING_IS_ADDING_NEW_BEND,
  BENDING_SET_SINGLE_MATERIAL_BENDS, BENDING_IS_EDITING_BEND,
  BENDING_IS_DELETING_BENDS
} from "./actionTypes";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";

const fetchBendingData = () => dispatch => {
  dispatch({
    type: BENDING_IS_FETCHING_BENDING_DATA,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/params/bending/bends`)
    .then(response => {
      let bends = []
      if(response.data?.bends) {
        bends = response.data.groupNames.map( name => {
          let item = response.data.bends.find(item => item.groupName === name)
          if(item) {
            return item
          }
          else {
            return {
              groupName: name,
              rows: [],
              columns: [],
              allAvailable: {}
            }
          }
        })
      }
      dispatch({
        type: BENDING_SET_BENDING_DATA,
        payload: {...response.data, bends: bends},
      })

      dispatch({
        type: BENDING_IS_FETCHING_BENDING_DATA,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: BENDING_IS_FETCHING_BENDING_DATA,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setBendingService = payload => dispatch => {
  dispatch({
    type: BENDING_SET_BENDING_SERVICE,
    payload: payload.value,
  })

  return http
    .put(`${API_BASE_URL}/params/bending/has-bending`, {hasBending: payload.value})
    .then(response => {

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const setBendingMaxLength = payload => dispatch => {
  dispatch({
    type: BENDING_SET_BENDING_MAX_LENGTH,
    payload: payload.value,
  })
}

const setPreparationPrice = payload => dispatch => {
  dispatch({
    type: BENDING_SET_BENDING_PREPARATION_PRICE,
    payload: payload.value,
  })
}

const setAveragePreparationTime = payload => dispatch => {
  dispatch({
    type: BENDING_SET_BENDING_AVERAGE_PREPARATION_TIME,
    payload: payload.value,
  })
}

const fetchSingleMaterialBendsData = payload => dispatch => {
  dispatch({
    type: BENDING_FETCHING_SINGLE_MATERIAL_BENDS,
    payload: true,
  })
  
  return http
    .get(`${API_BASE_URL}/params/${payload.materialId}/bends`)
    .then(response => {
      dispatch({
        type: BENDING_SET_MATERIAL_BENDING_DATA,
        payload: response.data,
      })

      dispatch({
        type: BENDING_FETCHING_SINGLE_MATERIAL_BENDS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: BENDING_FETCHING_SINGLE_MATERIAL_BENDS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const fetchSingleMaterialBends = payload => dispatch => {
  return http
    .post(`${API_BASE_URL}/params/bending/bends`, {groupName: payload.groupName})
    .then(response => {
      if(response.data) {
        dispatch({
          type: BENDING_SET_SINGLE_MATERIAL_BENDS,
          payload: response.data,
        })
      }
      else {
        dispatch({
          type: BENDING_SET_SINGLE_MATERIAL_BENDS,
          payload: {
            groupName: payload.groupName,
            rows: [],
            columns: [],
            allAvailable: {}
          },
        })
      }
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const resetMaterialBendsData = () => dispatch => {
  dispatch({
    type: BENDING_RESET_BENDS_DATA,
    payload: null,
  })
}

const addNewBend = payload => dispatch => {
  dispatch({
    type: BENDING_IS_ADDING_NEW_BEND,
    payload: true,
  })
  return http
    .post(`${API_BASE_URL}/params/${payload.materialId}/bends`, {length: payload.length, price: payload.price})
    .then(response => {
      dispatch({
        type: BENDING_IS_ADDING_NEW_BEND,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: BENDING_IS_ADDING_NEW_BEND,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}

const editBend = payload => dispatch => {
  dispatch({
    type: BENDING_IS_EDITING_BEND,
    payload: true,
  })
  return http
    .put(`${API_BASE_URL}/params/${payload.materialId}/bends/${payload.bendId}`, {length: payload.length, price: payload.price})
    .then(response => {
      dispatch({
        type: BENDING_IS_EDITING_BEND,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: BENDING_IS_EDITING_BEND,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}

const deleteBends = payload => dispatch => {

  dispatch({
    type: BENDING_IS_DELETING_BENDS,
    payload: true,
  })

  return http
    .deleteData(`${API_BASE_URL}/params/${payload.materialId}/bends`, {bends: [...payload.bends]})
    .then(response => {
      dispatch({
        type: BENDING_SET_MATERIAL_BENDING_DATA,
        payload: response.data,
      })

      dispatch({
        type: BENDING_IS_DELETING_BENDS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: BENDING_IS_DELETING_BENDS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })

}

export {
  fetchBendingData,
  setBendingService,
  setBendingMaxLength,
  setPreparationPrice,
  setAveragePreparationTime,
  fetchSingleMaterialBendsData,
  resetMaterialBendsData,
  fetchSingleMaterialBends,
  addNewBend,
  editBend,
  deleteBends
}