import React from "react";
import {OrderUploadButton} from "../../OrderUploadButton";
import {OrderUploadProcessProgress} from "../../OrderUploadProcessProgress";
import {OrderPartsList} from "../../OrderPartsList";
import {OrderMultiEdit} from "../../OrderMultiEdit";
import {Card} from "antd";

export const OrderProcessingStage = (props) => {

  const {
    isProducer, orderId, largeThreshold, allItemsCount, screens, openFileSizeNotification, triggerUpload,
    smallThreshold, toProcess, currentlyUploaded, currentlyProcessed, selectableItemsCount,
    handleRemoveMultipleItems, selectedAllItems, setSelectedAllItems, handleSelectAll,
    selectedItemsCount, selectedParameters, items, selectedItems, handleRemoveItem,
    changeSheetMetalMaterial, changePartThickness, changePartQuantity, changePartNote, changePartColor,
    changePartTolerance, changePartRoughness, changeManualPartTolerance, changeManualPartRoughness,
    changeMaterialCertificate,
    handleColorRemove, showItem, handleChangeItemType, selectableThickness, onUploadClick,
    adjustment, handleSelectAssembly, partiallySelectedAssemblies, openDocumentsView,
    handleRemoveEntireAssembly, assemblyProgressMap, changeAssemblyQuantity,
    changeAssemblyWeldingPrice, changeAssemblyAssemblingPrice,
    changeAssemblyPackagingPrice, changeAssemblyTransportationPrice,
    onCheck, sheetMetalMaterials, machiningMaterials, tubeCuttingMaterials,
    changeMachiningMaterial, changeTubeCuttingMaterial,
    changePartStandard, changePartNumberOfUniqueParts,
    bulkItem, isOrderDetails, style, handleOpenPricesModal, changePrice, totalPrice,

    selectedSheetMetalPartsCount,
    selectedMillingPartsCount,
    selectedLathingPartsCount,
    selectedAutomaticTubeCuttingPartsCount,
    selectedManualTubeCuttingPartsCount,
    selectedProfilePartsCount,
    selectedOtherPartsCount,
    selectedPartsMapByType,
    selectableSheetMetalMaterials,
    selectableMachiningMaterials,

    multiEditFields,
    addMultiEditField,
    removeMultiEditField,
    setMultiEditField,
    currentActiveMultiEditFields,

    handleMultiQuantityChange,
    handleMultiCertificateChange,
    handleMultiCoatingChange,
    handleMultiCoatingClear,
    handleMultiSheetMetalMaterialChange,
    handleMultiSheetMetalMaterialClear,
    handleMultiThicknessChange,
    handleMultiThicknessClear,
    handleMultiMachiningMaterialChange,
    handleMultiMachiningMaterialClear,
    handleMultiStandardChange,
    handleMultiStandardClear,
    handleMultiClear,
    handleMultiApply,
  } = props


  return (
    <div>
      <div style={{marginRight: largeThreshold ? 10 : 20, ...style}}>
        {/*----------------Upload Button-----------------*/}
        <OrderUploadButton
          allItemsCount={allItemsCount}
          screens={screens}
          openFileSizeNotification={openFileSizeNotification}
          triggerUpload={triggerUpload}
          onOpen={onUploadClick}
        />
        {/*--------------------------------------------*/}

        {/*----------------------Order Summary---------------------*/}
        {(bulkItem && allItemsCount > 0 && !isProducer) &&
        <Card
          style={{
            marginTop: 10,
            borderRadius: 4,
            border: '1px solid rgb(224, 224, 224)',
            height: "48px",
            overflow: "auto"
          }}
          bodyStyle={{padding: "5px 0", height: "100%"}}
        >
          <div className={"summaryContainer"}>
            <div className={"text14-500 orderSummary"}>
              Calculation Summary:
            </div>
            <div className={"summaryContent"}>
              <div style={{fontSize: 14, fontWeight: 500}}>
                <span style={{width: 80, display: "inline-block"}}>Total Price:</span> <span
                style={{fontWeight: 600}}>{(totalPrice || 0).toFixed(2)} €</span>
              </div>
            </div>
          </div>
        </Card>
        }
        {/*--------------------------------------------*/}

        {/*----------------Upload/Process Progress-----------------*/}
        {toProcess > 0 &&
        <OrderUploadProcessProgress
          toProcess={toProcess}
          currentlyUploaded={currentlyUploaded}
          currentlyProcessed={currentlyProcessed}
        />
        }
        {/*--------------------------------------------*/}

        {/*----------------Multi Edit-----------------*/}
        {selectableItemsCount > 0 &&
        <OrderMultiEdit
          selectableItemsCount={selectableItemsCount}
          selectedAllItems={selectedAllItems}
          setSelectedAllItems={setSelectedAllItems}
          handleSelectAll={handleSelectAll}

          selectedItemsCount={selectedItemsCount}
          selectedSheetMetalPartsCount={selectedSheetMetalPartsCount}
          selectedMillingPartsCount={selectedMillingPartsCount}
          selectedLathingPartsCount={selectedLathingPartsCount}
          selectedAutomaticTubeCuttingPartsCount={selectedAutomaticTubeCuttingPartsCount}
          selectedManualTubeCuttingPartsCount={selectedManualTubeCuttingPartsCount}
          selectedProfilePartsCount={selectedProfilePartsCount}
          selectedOtherPartsCount={selectedOtherPartsCount}
          selectedPartsMapByType={selectedPartsMapByType}

          handleRemoveMultipleItems={handleRemoveMultipleItems}

          multiEditFields={multiEditFields}
          addMultiEditField={addMultiEditField}
          removeMultiEditField={removeMultiEditField}
          setMultiEditField={setMultiEditField}
          currentActiveMultiEditFields={currentActiveMultiEditFields}

          selectedParameters={selectedParameters}
          sheetMetalMaterials={sheetMetalMaterials}
          selectableSheetMetalMaterials={selectableSheetMetalMaterials}
          selectableThickness={selectableThickness}
          machiningMaterials={machiningMaterials}
          selectableMachiningMaterials={selectableMachiningMaterials}

          handleMultiQuantityChange={handleMultiQuantityChange}
          handleMultiCertificateChange={handleMultiCertificateChange}
          handleMultiCoatingChange={handleMultiCoatingChange}
          handleMultiCoatingClear={handleMultiCoatingClear}
          handleMultiSheetMetalMaterialChange={handleMultiSheetMetalMaterialChange}
          handleMultiSheetMetalMaterialClear={handleMultiSheetMetalMaterialClear}
          handleMultiThicknessChange={handleMultiThicknessChange}
          handleMultiThicknessClear={handleMultiThicknessClear}
          handleMultiMachiningMaterialChange={handleMultiMachiningMaterialChange}
          handleMultiMachiningMaterialClear={handleMultiMachiningMaterialClear}
          handleMultiStandardChange={handleMultiStandardChange}
          handleMultiStandardClear={handleMultiStandardClear}

          handleMultiClear={handleMultiClear}
          handleMultiApply={handleMultiApply}
        />
        }
        {/*--------------------------------------------*/}
        {allItemsCount > 0 &&
        <OrderPartsList
          orderId={orderId}
          items={items}
          selectableItemsCount={selectableItemsCount}
          selectedItems={selectedItems}
          handleRemoveItem={handleRemoveItem}
          sheetMetalMaterials={sheetMetalMaterials}
          machiningMaterials={machiningMaterials}
          tubeCuttingMaterials={tubeCuttingMaterials}
          changeSheetMetalMaterial={changeSheetMetalMaterial}
          changeMachiningMaterial={changeMachiningMaterial}
          changeTubeCuttingMaterial={changeTubeCuttingMaterial}
          changePartThickness={changePartThickness}
          changePartQuantity={changePartQuantity}
          changePartNote={changePartNote}
          changePartColor={changePartColor}
          changePartTolerance={changePartTolerance}
          changePartRoughness={changePartRoughness}
          changeManualPartTolerance={changeManualPartTolerance}
          changeManualPartRoughness={changeManualPartRoughness}
          changeMaterialCertificate={changeMaterialCertificate}
          changePartStandard={changePartStandard}
          changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
          handleColorRemove={handleColorRemove}
          showItem={showItem}
          handleChangeItemType={handleChangeItemType}
          bulkItem={bulkItem}
          adjustment={adjustment}
          isOrderDetails={isOrderDetails}
          handleSelectAssembly={handleSelectAssembly}
          partiallySelectedAssemblies={partiallySelectedAssemblies}
          handleRemoveEntireAssembly={handleRemoveEntireAssembly}
          changeAssemblyQuantity={changeAssemblyQuantity}
          changeAssemblyWeldingPrice={changeAssemblyWeldingPrice}
          changeAssemblyAssemblingPrice={changeAssemblyAssemblingPrice}
          changeAssemblyPackagingPrice={changeAssemblyPackagingPrice}
          changeAssemblyTransportationPrice={changeAssemblyTransportationPrice}
          assemblyProgressMap={assemblyProgressMap}
          openDocumentsView={openDocumentsView}
          onCheck={onCheck}
          handleOpenPricesModal={handleOpenPricesModal}
          changePrice={changePrice}
        />
        }
      </div>
    </div>
  )
}