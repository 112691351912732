import React from "react";
import {Button, Card, Spin, Tooltip} from "antd";
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  LoadingOutlined, QuestionCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {EmptyTable} from "../../EmptyTable";
import {AutoSizer, List} from "react-virtualized";
import {imageSection, materialInfoSection} from "../../../utils/partCardUtils/sections";
import {CircularProgress} from "@mui/material";


const UnprocessedCard = (props) => {

  const {
    openManualQuoteModal, openAssemblyQuoteModal,
    showManualItem, isEditing, role, showOtherItem,
    rejectManualPart, handleShowDownloadFiles,
    handleOpenDocsModal, assemblyData, part, count,
    itemCount
  } = props;

  const isAssembly = () => {
    return part.partType === "assembly" && part.processingType === null;
  }

  return (
    <div style={{ width: "100%", marginRight: 6 }}>
      {count < itemCount &&
      <Card
        className={"bendCard"}
        style={{height: 80}}
        bodyStyle={{padding: "8px 12px 8px 12px", height: "100%", background: isAssembly() ? "#48555c" : "#293135"}}
        key={part.id}
      >
        <div style={{display: "flex", width: "100%"}}>
          {part.assemblyId &&
          <Tooltip
            placement={"top"}
            color={"#656565"}
            title={
              <div style={{ color: "white", fontSize: 12, fontWeight: 500 }}>
                <div>This part belongs to the assembly:</div>
                <div>{assemblyData[part.assemblyId]?.name}</div>
              </div>
            }
          >
            <div style={{ position: "absolute", top: 6, left: 48 }}>
              <ExclamationCircleOutlined style={{ color: "#0a7300", marginLeft: 5, marginTop: 2 }} />
            </div>
          </Tooltip>
          }
          {imageSection(part)}
          <div style={{width: "100%"}}>
            <div style={{width: "100%", height: 22}}>
              <table style={{width: "100%"}}>
                <tbody>
                <tr>
                  <td style={{width: "calc(100% - 100px)", maxWidth: 150}}>
                    <div className={"partInfo"}>
                      {part.fileName || part.name}
                    </div>
                  </td>
                  <td style={{width: 100, position: "relative", bottom: 2}}>
                    <div style={{display: "flex", height: 24, justifyContent: "flex-end"}}>
                      {part.documents?.length > 0 &&
                      <Tooltip
                        placement={"topRight"}
                        color={"#d6d6d6"}
                        title={<span style={{ color: "black", fontSize: 12, fontWeight: 500}}>Download Documents</span>}
                      >
                        <Button
                          size={"small"}
                          type={"primary"}
                          className={"exportPdfButton"}
                          onClick={() => {handleOpenDocsModal(part.documents)}}
                        >
                          <div>
                            <FilePdfOutlined style={{fontSize: 12}}/>
                          </div>
                        </Button>
                      </Tooltip>
                      }
                      {(role === "gelsoAdmin" || role === "gelsoSubAdmin") &&
                      <Tooltip
                        title={<span style={{ color: "black", fontSize: 12, fontWeight: 500}}>Download Files</span>}
                        placement={"topRight"}
                        color={"#d6d6d6"}
                      >
                        <Button
                          size={"small"}
                          type={"primary"}
                          style={{marginLeft: 5}}
                          onClick={() => handleShowDownloadFiles(part)}
                        >
                          <DownloadOutlined style={{fontSize: 13}}/>
                        </Button>
                      </Tooltip>
                      }
                      <Tooltip
                        title={<span style={{ color: "black", fontSize: 12, fontWeight: 500}}>View Part</span>}
                        placement={"topRight"}
                        color={"#d6d6d6"}
                      >
                        <Button
                          size={"small"}
                          type={"primary"}
                          style={{marginLeft: 5}}
                          onClick={() => {
                            if(part.fileType === "other") {
                              showOtherItem(part)
                            } else {
                              showManualItem(part)
                            }
                          }}
                        >
                          <SearchOutlined style={{fontSize: 13}}/>
                        </Button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
              {materialInfoSection(part)}
              <div style={{display:"flex", justifyContent: "space-between"}}>
                <div
                  style={{fontSize: 12, fontWeight: 500, marginRight: 8}}
                >
                  {part.assemblyId ?
                    <div style={{display: "flex"}}>
                      <div>
                        Quantity: <span style={{ fontWeight: 600 }}>{assemblyData[part.assemblyId]?.quantity}×{part.quantity}</span>
                      </div>
                      <Tooltip
                        placement={"top"}
                        color={"#656565"}
                        title={
                          <div style={{ color: "white", fontSize: 12, fontWeight: 500}}>
                            <div>This part belongs to an assembly.</div>
                            <div>Number of assemblies: {assemblyData[part.assemblyId]?.quantity}</div>
                            <div>Quantity per assembly: {part.quantity}</div>
                          </div>
                        }
                      >
                        <QuestionCircleOutlined style={{color: "#ffffff", marginLeft: 5, marginTop: 2}}/>
                      </Tooltip>
                    </div>
                    :
                    <div>
                      Quantity: <span style={{ fontWeight: 600 }}>{part.quantity}</span>
                    </div>
                  }
                </div>
                {(role === "gelsoAdmin" || role === "gelsoSubAdmin") &&
                <div
                  style={{fontSize: 12, fontWeight: 500, marginTop: -3}}
                >
                  {!isAssembly() &&
                    <Button
                      type="danger"
                      size={"small"}
                      style={{fontSize: 12, fontWeight: 500}}
                      disabled={isEditing}
                      onClick={() => {
                        rejectManualPart(part.id)
                      }}
                    >
                      Reject
                    </Button>
                  }
                  <Button
                    type={"primary"}
                    size={"small"}
                    style={{fontSize: 12, fontWeight: 500, marginLeft: 8, width: 64}}
                    disabled={isEditing}
                    onClick={() => {
                      if(isAssembly()) {
                        openAssemblyQuoteModal(part)
                      } else {
                        openManualQuoteModal(part)
                      }
                    }}
                  >
                    Quote
                  </Button>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Card>
      }
    </div>
  )
}


export const OrderDetailsPartUnprocessedPartsGrid = (props) => {

  const {
    parts, openManualQuoteModal, openAssemblyQuoteModal,
    showManualItem, isEditing, role, showOtherItem,
    isFetchingUnprocessedParts, rejectManualPart,
    handleShowDownloadFiles, handleOpenDocsModal,
    assemblyData, emptyText, itemSize, itemCount
  } = props;
 
  if (itemCount === 0) {
    return (
      <React.Fragment>
        <Spin
          wrapperClassName={"customerGridSpinner"}
          spinning={isFetchingUnprocessedParts}
          indicator={
            <div align={"center"}>
              <LoadingOutlined style={{ fontSize: 50, marginLeft: -20, marginTop: -20 }} spin />
            </div>
          }
        >
          <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
            <EmptyTable text={emptyText}/>
          </div>
        </Spin>
      </React.Fragment>
    )
  }
  
  return (
    <AutoSizer style={{ width: "100%" }}>
    {({ height, width }) => {
      const itemsPerRow = Math.floor(width / itemSize) || 1;
      const rowCount = Math.ceil(itemCount / itemsPerRow);

      return (
        <Spin
          spinning={isFetchingUnprocessedParts}
          style={{width, height}}
          indicator={<CircularProgress sx={{color: "#da291c"}} size={48} thickness={2} style={{marginTop: -24, marginLeft: -24}}/>}
        >
          <List
            className={'customerList'}
            style={{ paddingRight: 10, paddingLeft: 6 }}
            width={width}
            height={height-2}
            rowCount={rowCount}
            rowHeight={86}
            overscanRowCount={2}
            rowRenderer={
              ({ index, key, style }) => {
                const items = [];
                const fromIndex = index * itemsPerRow;
                const toIndex = fromIndex + itemsPerRow;

                for (let i = fromIndex; i < toIndex; i++) {
                  items.push(
                    <UnprocessedCard
                      key={i}
                      openManualQuoteModal={openManualQuoteModal}
                      openAssemblyQuoteModal={openAssemblyQuoteModal}
                      showManualItem={showManualItem}
                      isEditing={isEditing}
                      role={role}
                      showOtherItem={showOtherItem}
                      rejectManualPart={rejectManualPart}
                      handleShowDownloadFiles={handleShowDownloadFiles}
                      handleOpenDocsModal={handleOpenDocsModal}
                      assemblyData={assemblyData}
                      part={parts[i]}
                      count={i}
                      itemCount={itemCount}
                    />
                  )
                }

                return (
                  <div
                    className={'customerRow'}
                    key={key}
                    style={style}
                  >
                    {items}
                  </div>
                )
              }
            }
          />
        </Spin>
      )
    }}
  </AutoSizer>
  )
}
