import {makeStyles, withStyles} from "@material-ui/core/styles";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import clsx from "clsx";
import PropTypes from "prop-types";
import React, {useEffect} from "react";
import StepConnector from "@material-ui/core/StepConnector";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#4e5c64',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, #ff5b2d 0%, #da291c 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    cursor: "pointer",
    backgroundImage:
      'linear-gradient( 136deg, #ff5b2d 0%, #da291c 100%)',
  },
});

function StepIcon(props) {
  const classes = useStepIconStyles();
  const { active, completed, changeStep } = props;

  const icons = {
    1: <CloudUploadOutlinedIcon fontSize={'small'} />,
    2: <LocalShippingOutlinedIcon fontSize={'small'} />,
    3: <FactCheckOutlinedIcon fontSize={'small'} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
      onClick={()=>{
        if(completed) {
          changeStep(String(props.icon))
        }
      }}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const Connector = withStyles({
  alternativeLabel: {
    top: 14,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 136deg, #ff5b2d 0%, #da291c 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 136deg, #ff5b2d 0%, #da291c 100%)',
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CustomStepper = withStyles({
  root: {
    padding: 0,
    backgroundColor: "#293135",
  }
})(Stepper);

const CustomStepLabel = withStyles({
  alternativeLabel: { fontWeight: "500 !important" },
  active: { fontWeight: "600 !important" },
  // completed: { color: "white !important" }
})(StepLabel);

export const OrderProgress = (props) => {
  const {stage, goToStage} = props
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const steps = ['Calculation', 'Delivery Info', 'Quote'];

  useEffect(()=>{
    switch (stage) {
      case "processing":
        setActiveStep(0)
        break;
      case "shipping":
        setActiveStep(1)
        break;
      case "confirmation":
        setActiveStep(2)
        break;
      default:
        setActiveStep(0)
    }

  }, [stage])

  const changeStep = (step) => {
    switch (step) {
      case "1":
        goToStage("processing")
        break;
      case "2":
        goToStage("shipping")
        break;
      default:
        break;
    }
  }

  return (
    <div className={classes.root}>
      <CustomStepper alternativeLabel activeStep={activeStep} connector={<Connector />}>
        {steps.map((label) => (
          <Step key={label}>
            <CustomStepLabel StepIconComponent={(props) => StepIcon({...props, changeStep})}>
              <div style={{marginTop: -8, fontFamily: "Montserrat", fontSize: 13, color: "white"}}>{label}</div>
            </CustomStepLabel>
          </Step>
        ))}
      </CustomStepper>
    </div>
  );
}