import React from "react";
import {RenderPropSticky} from "react-sticky-el";
import { Form, Card, Button, Modal, Divider } from "antd";
import {
  Accordion, AccordionActions,
  AccordionDetails, AccordionSummary,
  Divider as MiDivider
} from "@material-ui/core";

import { DeleteOutlined, EyeFilled } from "@ant-design/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import TooltipMui from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import { MultiEditListOfFields } from "./MultiEditListOfFields";
import "./style.less";


const PartsTooltip = withStyles({
  tooltip: {
    backgroundColor: "#14181a",
    border: "1px solid #a6a6a6",
    marginTop: 10
  },
  arrow: {
    '&::before': {
      backgroundColor: "#14181a",
      border: "1px solid #a6a6a6"
    }
  },
})(TooltipMui);

export const OrderMultiEdit = (props) => {
  const {
    selectableItemsCount, selectedAllItems, setSelectedAllItems,
    handleSelectAll,

    selectedItemsCount, selectedSheetMetalPartsCount,
    selectedMillingPartsCount, selectedLathingPartsCount,
    selectedAutomaticTubeCuttingPartsCount, selectedManualTubeCuttingPartsCount,
    selectedProfilePartsCount, selectedOtherPartsCount, selectedPartsMapByType,

    handleRemoveMultipleItems,

    multiEditFields,

    addMultiEditField, removeMultiEditField, setMultiEditField,
    currentActiveMultiEditFields,

    selectedParameters, sheetMetalMaterials, selectableSheetMetalMaterials,
    selectableThickness, machiningMaterials, selectableMachiningMaterials,

    handleMultiQuantityChange, handleMultiCertificateChange,
    handleMultiCoatingChange, handleMultiCoatingClear,
    handleMultiSheetMetalMaterialChange, handleMultiSheetMetalMaterialClear,
    handleMultiThicknessChange, handleMultiThicknessClear,
    handleMultiMachiningMaterialChange, handleMultiMachiningMaterialClear,
    handleMultiStandardChange, handleMultiStandardClear,

    handleMultiClear, handleMultiApply
  } = props

  return (
    <RenderPropSticky
      scrollElement={"window"}
      positionRecheckInterval={1}
    >
      {({isFixed, wrapperStyles, wrapperRef, holderStyles, holderRef}) => (
        <div ref={holderRef} style={holderStyles}>
          <div
            className={'mulSticky'}
            style={isFixed ?
              {zIndex: 1100, position: 'relative', ...wrapperStyles}
              :
              {zIndex: 5, position: 'relative', ...wrapperStyles}
            }
            ref={wrapperRef}
          >
            <Card
              style={ isFixed ?
                {
                  marginLeft: -10,
                  marginRight: -10,
                  borderRadius: "0 0 4px 4px",
                  borderLeft: '1px solid #fafafa',
                  borderRight: '1px solid #fafafa',
                  borderBottom: '1px solid #fafafa',
                  boxShadow: "0 1px 4px 1px rgba(0, 0, 0, 0.3)",
                }
                :
                {
                  background: "rgba(0,0,0,0)",
                  marginLeft: -10,
                  marginRight: -10,
                  borderRadius: "4px",
                  borderLeft: 0,
                  borderRight: 0,
                  borderBottom: 0,
                  borderTop: 0,
                  boxShadow: "none"
                }
              }
              className={"lightBorder"}
              bodyStyle={{padding: "10px 10px 10px 10px"}}
            >
              <div style={{width: "100%"}}>
                <Accordion
                  style={
                    selectedItemsCount > 0 ?
                      {borderRadius: "4px", border: '1px solid #1890ff', boxShadow: 'none'}
                      :
                      {borderRadius: "4px", border: '1px solid #e0e0e0', boxShadow: 'none'}
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    style={{pointerEvents: 'auto', maxHeight: 48}}
                  >
                    <div style={{flexBasis: '50%'}}>
                      <div style={{display: "flex"}}>
                        <div>
                        <span
                          className={"text14-600"}
                          style={{position: 'relative', top: 2, marginRight: 5}}
                        >
                          <EditOutlinedIcon/>
                        </span>
                          <span
                            className={"text14-600"}
                            style={{position: 'relative', top: -4}}
                          >
                          Multi edit parts
                        </span>
                        </div>
                        <div style={{marginLeft: 20, display: "flex"}}>
                          <Button
                            size={"small"}
                            type={"primary"}
                            style={{marginTop: 4, height: 24}}
                            onClick={event => {
                              event.stopPropagation()
                              if(!selectedAllItems) {
                                setSelectedAllItems(true)
                                handleSelectAll(true)
                              }
                            }}
                          >
                            <span style={{ fontSize: 12, fontWeight: 600, fontFamily: "Montserrat,sans-serif" }}>Select all</span>
                          </Button>
                          {selectedItemsCount > 0 && (
                            <Button
                              size={"small"}
                              type={"default"}
                              style={{marginTop: 3, marginLeft: 5, height: 26}}
                              onClick={event => {
                                event.stopPropagation()
                                setSelectedAllItems(false)
                                handleSelectAll(false)
                              }}
                            >
                              <div style={{ fontSize: 12, fontWeight: 600, paddingTop: 1, fontFamily: "Montserrat,sans-serif" }}>Deselect all</div>
                            </Button>
                          )}
                        </div>
                      </div>

                    </div>
                    <div style={{flexBasis: '50%'}}>
                      <div align={'right'}>
                        {selectedItemsCount > 0 ?
                          <div>
                            <PartsTooltip
                              arrow
                              placement={"bottom"}
                              title={
                                <div className={"partsCountTooltip"}>
                                  <div className={`tooltipLine ${!selectedSheetMetalPartsCount && "zeroColor"}`} style={{padding: "4px 0px"}}>
                                    <span>{selectedSheetMetalPartsCount} ×</span>
                                    <span>Sheet Metal</span>
                                  </div>
                                  <div className={`tooltipLine ${!selectedMillingPartsCount && "zeroColor"}`} style={{borderTop: "1px dotted #5e5e5e", padding: "3px 0px"}}>
                                    <span>{selectedMillingPartsCount} ×</span>
                                    <span>Milling</span>
                                  </div>
                                  <div className={`tooltipLine ${!selectedLathingPartsCount && "zeroColor"}`} style={{borderTop: "1px dotted #5e5e5e", padding: "3px 0px"}}>
                                    <span>{selectedLathingPartsCount} ×</span>
                                    <span>Lathing</span>
                                  </div>
                                  <div className={`tooltipLine ${!(selectedAutomaticTubeCuttingPartsCount + selectedManualTubeCuttingPartsCount) && "zeroColor"}`} style={{borderTop: "1px dotted #5e5e5e", padding: "3px 0px"}}>
                                    <span>{selectedAutomaticTubeCuttingPartsCount + selectedManualTubeCuttingPartsCount} ×</span>
                                    <span>Tube Cutting</span>
                                  </div>
                                  <div className={`tooltipLine ${!selectedProfilePartsCount && "zeroColor"}`} style={{borderTop: "1px dotted #5e5e5e", padding: "3px 0px"}}>
                                    <span>{selectedProfilePartsCount} ×</span>
                                    <span>Profile</span>
                                  </div>
                                  <div className={`tooltipLine ${!selectedOtherPartsCount && "zeroColor"}`} style={{borderTop: "1px dotted #5e5e5e", padding: "3px 0px"}}>
                                    <span>{selectedOtherPartsCount} ×</span>
                                    <span>Other</span>
                                  </div>
                                </div>
                              }
                            >
                            <span
                              className={"text14-600"}
                              style={{
                                position: 'relative',
                                marginRight: 15
                              }}
                            >
                              {selectedItemsCount === selectableItemsCount ?
                                "All parts selected"
                                :
                                `${selectedItemsCount} ${selectedItemsCount === 1 ? "part" : "parts"} selected`
                              }
                              <EyeFilled style={{marginLeft: 5, color: "#ff5b2d"}}/>
                            </span>
                            </PartsTooltip>
                            <Button
                              type={"danger"}
                              onClick={(e) => {
                                e.stopPropagation()
                                  Modal.confirm({
                                    className: "deleteConfirmationModal",
                                    visible: true,
                                    style: {minWidth: '400px', maxHeight: '90vh'},
                                    centered: true,
                                    onOk: () => {
                                      handleRemoveMultipleItems()
                                    },
                                    destroyOnClose: true,
                                    width: 440,
                                    title: <span style={{ color: "white" }}>{"Deleting Parts"}</span>,
                                    content: <div style={{ color: "white" }}>
                                      <div>
                                        Are you sure you want to delete all selected parts?.<br/>
                                        This action cannot be undone.
                                      </div>
                                      <Divider
                                        className={"lightDivider"}
                                        style={{
                                          marginBottom: 0,
                                          position: 'relative',
                                          right: 36,
                                          width: 374,
                                        }}/>
                                    </div>,
                                    okText: <span style={{fontSize: 13, fontWeight: 500}}>Delete</span>,
                                    okButtonProps: {type: "danger"},
                                    cancelText: <span
                                      style={{fontSize: 13, fontWeight: 500}}>Cancel</span>,
                                    icon: <DeleteOutlined style={{color: "#ff5b2d"}}/>,
                                    zIndex: 6,
                                  })
                              }}>
                              <span style={{ fontSize: 13, fontWeight: 600, fontFamily: "Montserrat,sans-serif" }}>Delete selected</span>
                            </Button>
                          </div>
                          :
                          <div style={{height: 32}}/>
                        }
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      alignItems: 'center',
                      borderTop: '1px solid #3A3E40',
                      padding: "8px 16px"
                    }}
                  >
                    <span style={{width: '100%'}}>
                      <Form
                        name={"multiEditOrder"}
                      >
                        <MultiEditListOfFields
                          multiEditFields={multiEditFields}

                          selectedItemsCount={selectedItemsCount}
                          selectedSheetMetalPartsCount={selectedSheetMetalPartsCount}
                          selectedMillingPartsCount={selectedMillingPartsCount}
                          selectedLathingPartsCount={selectedLathingPartsCount}
                          selectedAutomaticTubeCuttingPartsCount={selectedAutomaticTubeCuttingPartsCount}
                          selectedManualTubeCuttingPartsCount={selectedManualTubeCuttingPartsCount}
                          selectedProfilePartsCount={selectedProfilePartsCount}
                          selectedPartsMapByType={selectedPartsMapByType}

                          addMultiEditField={addMultiEditField}
                          removeMultiEditField={removeMultiEditField}
                          setMultiEditField={setMultiEditField}
                          currentActiveMultiEditFields={currentActiveMultiEditFields}

                          selectedParameters={selectedParameters}
                          sheetMetalMaterials={sheetMetalMaterials}
                          selectableSheetMetalMaterials={selectableSheetMetalMaterials}
                          selectableThickness={selectableThickness}
                          machiningMaterials={machiningMaterials}
                          selectableMachiningMaterials={selectableMachiningMaterials}

                          handleMultiQuantityChange={handleMultiQuantityChange}
                          handleMultiCertificateChange={handleMultiCertificateChange}
                          handleMultiCoatingChange={handleMultiCoatingChange}
                          handleMultiCoatingClear={handleMultiCoatingClear}

                          handleMultiSheetMetalMaterialChange={handleMultiSheetMetalMaterialChange}
                          handleMultiSheetMetalMaterialClear={handleMultiSheetMetalMaterialClear}

                          handleMultiThicknessChange={handleMultiThicknessChange}
                          handleMultiThicknessClear={handleMultiThicknessClear}

                          handleMultiMachiningMaterialChange={handleMultiMachiningMaterialChange}
                          handleMultiMachiningMaterialClear={handleMultiMachiningMaterialClear}

                          handleMultiStandardChange={handleMultiStandardChange}
                          handleMultiStandardClear={handleMultiStandardClear}
                        />
                      </Form>
                    </span>
                  </AccordionDetails>
                  <MiDivider style={{ background: "#3A3E40"}}/>
                  <AccordionActions style={{justifyContent: 'space-between'}}>
                    <div align={'left'} style={{marginLeft: 10}}>
                    </div>
                    <div>
                      <Button
                        onClick={handleMultiClear}
                        style={{marginRight: 8}}
                      >
                        <span style={{ fontSize: 13, fontWeight: 500, textAlign: "center" }}>Clear</span>
                      </Button>
                        <Button
                          disabled={selectedItemsCount === 0}
                          onClick={handleMultiApply}
                          style={{marginRight: 8, height: 30}}
                          type={"primary"}
                        >
                          <span style={{ fontSize: 13, fontWeight: 500, textAlign: "center" }}>Apply</span>
                        </Button>
                    </div>
                  </AccordionActions>
                </Accordion>
              </div>
            </Card>
          </div>
        </div>
      )}
    </RenderPropSticky>
  )
}