import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {io} from "socket.io-client";
import {Layout, Spin, Tabs} from "antd";
import {forceLogoutUser, logoutUser} from "../../../actions/authActions";
import {LaserCuttingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/LaserCuttingTab";
import {MaterialBendingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/MaterialBendingTab";
import {SimpleMachiningTab} from "../../../components/company/CompanyMaterials/MaterialTabs/SimpleMachiningTab";
import {SurfaceCoatingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/SurfaceCoatingTab";
import {MaterialsTab} from "../../../components/company/CompanyMaterials/MaterialTabs/MaterialsTab";
import "./style.less"
import {CompanySidebar} from "../../../components/company";
import {LoadingOutlined} from "@ant-design/icons";
import storage from "../../../utils/storage";
import {SOCKET_URL, SOCKET_PATH} from "../../../constants";
import {ProducerSidebar} from "../../../components/producer/ProducerSidebar";
import { MachiningTab } from "../../../components/company/CompanyMaterials/MaterialTabs/MachiningTab";
import {TubeCuttingTab} from "../../../components/company/CompanyMaterials/MaterialTabs/TubeCuttingTab";

const { TabPane } = Tabs;

var socket;

export const MaterialsAndMachinesPage = () => {
  const dispatch = useDispatch()

  const getInitialTab = () => {
    if(history.state?.state?.tab) {
      return history.state.state.tab
    }
    const query = new URLSearchParams(window.location.search);
    const tab = query.get("tab")

    if(tab) {
      switch(tab) {
        case "material":
          return "7"
        case "cutting":
          return "1"
        case "bending":
          return "2"
        case "simple-machining":
          return "3"
        case "machining":
          return "4"
        case "tubes":
          return "5"
        case "coating":
          return "6"
        default:
          return "1"
      }
    }

    return "1"
  }

  const [selectedTab, setSelectedTab] = useState(getInitialTab())
  const [socketConnected, setSocketConnected] = useState(false)

  const isFetchingBendingData = useSelector(state => state.bending.isFetchingBendingData)
  const isFetchingSimpleMachiningData = useSelector(state => state.simpleMachining.isFetchingSimpleMachiningData)
  const isFetchingMachiningData = useSelector(state => state.machining.isFetchingMachiningData)
  const isFetchingSurfaceCoatingData = useSelector(state => state.surfaceCoating.isFetchingSurfaceCoatingData)
  const isFetchingTubeCuttingData = useSelector(state => state.tubeCutting.isFetchingTubeCuttingData)
  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user)

  const isGelso = user?.role === "gelsoAdmin" || user?.role === "gelsoSubAdmin"

  useEffect(() => {
    const accessToken = storage.retrieveAccessToken();

    socket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      auth: {
        token: accessToken.substring(7)
      },
    });

    socket.on("connect", () => {
      setSocketConnected(true)
    });

    socket.on("connect_error", (err) => {
      if (err.message === "invalid credentials") {
        storage.refreshAccessToken().then(() => {
          const accessToken = storage.retrieveAccessToken();
          if(accessToken) {
            socket.auth.token = accessToken.substring(7);
            socket.connect();
          }
        })
      }
    });

    socket.on("server-error", (err) => {
      console.log(err.msg)
      if (err.msg === "invalid credentials") {
        dispatch(forceLogoutUser())
      }
    });

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      socket.disconnect();
    };
  }, []);

  const changeMaterialPrice = ({materialId, price}) => {
    if(socket) {
      socket.emit("put-producer-material-price", { materialId, price })
    }
  }

  const changeBaseMaterialCertificateParams = (data) => {
    if (socket) {
      socket.emit("put-base-material-cert-fee", data)
    }
  }

  const changeAssemblyMaterialCertificateParams = (data) => {
    if (socket) {
      socket.emit("put-assembly-material-cert-fee", data)
    }
  }

  const changeBendingParams = (data) => {
    if(socket) {
      socket.emit("put-producer-bending-params", { ...data })
    }
  }

  const changeDeburringParams = (data) => {
    if(socket) {
      socket.emit("put-deburring-params", { ...data })
    }
  }

  const changeChamferingParams = (data) => {
    if(socket) {
      socket.emit("put-chamfering-params", { ...data })
    }
  }

  const changeDrillingParams = (data) => {
    if(socket) {
      socket.emit("put-drilling-params", { ...data })
    }
  }

  const changeSimpleMachiningParams = (data) => {
    if(socket) {
      socket.emit("put-simple-machining-params", { ...data })
    }
  }

  const changePaintingParams = (data) => {
    if(socket) {
      socket.emit("put-painting-params", { ...data })
    }
  }

  const changePowderPaintingParams = (data) => {
    if(socket) {
      socket.emit("put-powder-painting-params", { ...data })
    }
  }

  const changeGalvanizingParams = (data) => {
    if(socket) {
      socket.emit("put-galvanizing-params", { ...data })
    }
  }

  const changeNickelPlatingParams = (data) => {
    if(socket) {
      socket.emit("put-nickel-plating-params", { ...data })
    }
  }

  const changeAnodizingParams = (data) => {
    if(socket) {
      socket.emit("put-anodizing-params", { ...data })
    }
  }

  const changeGalvanicZincPlatingParams = (data) => {
    if(socket) {
      socket.emit("put-galvanic-zinc-plating-params", { ...data })
    }
  }

  const changeHardeningParams = (data) => {
    if(socket) {
      socket.emit("put-hardening-params", {...data})
    }
  }

  const changeCaseHardeningParams = (data) => {
    if(socket) {
      socket.emit("put-case-hardening-params", {...data})
    }
  }

  const changeAnnealingParams = (data) => {
    if(socket) {
      socket.emit("put-annealing-params", {...data})
    }
  }

  const changeGlassBeadBlastingParams = (data) => {
    if(socket) {
      socket.emit("put-glass-bead-blasting-params", {...data})
    }
  }

  const changeSandBlastingParams = (data) => {
    if(socket) {
      socket.emit("put-sand-blasting-params", {...data})
    }
  }

  const changeCuttingSpeed = (data) => {
    if(socket) {
      socket.emit("put-cutting-machine-cutting-speed", { ...data })
    }
  }

  const changeCuttingMachineParams = (data) => {
    if(socket) {
      socket.emit("put-cutting-machine-one-field", { ...data })
    }
  }

  const changeMillingParams = (data) => {
    if(socket) {
      socket.emit("put-milling-params", { ...data })
    }
  }

  const changeLathingParams = (data) => {
    if(socket) {
      socket.emit("put-lathing-params", { ...data })
    }
  }

  const changeGradeMillingSpeed = (data) => {
    if(socket) {
      socket.emit("put-milling-speed", {...data})
    }
  }

  const changeGradeLathingSpeed = (data) => {
    if(socket) {
      socket.emit("put-lathing-speed", {...data})
    }
  }

  const changeMachiningMaterialPrice = (data) => {
    if(socket) {
      socket.emit("put-material-price", { ...data })
    }
  }

  const changeTubeCuttingMachineParams = (data) => {
    if (socket) {
      socket.emit("put-tube-cutting-machine-params", {...data})
    }
  }
  const changeTubeCuttingMachineSpeed = (data) => {
    if (socket) {
      socket.emit("put-tube-cutting-speed", {...data})
    }
  }
  const changeTubeMaterialPrice = (data) => {
    if(socket) {
      socket.emit("put-tube-cutting-material-price", { ...data })
    }
  }

  const changeStandardTubeMaterialPrice = (data) => {
    if(socket) {
      socket.emit("put-tube-cutting-standard-material-price", { ...data })
    }
  }

  const changeMachineMaxTolerance = (data) => {
    if (socket) {
      socket.emit("put-machine-max-tolerance", { ...data })
    }
  }

  const changeMachineToleranceMarkup = (data) => {
    if (socket) {
      socket.emit("put-machine-tolerance-markup", { ...data })
    }
  }

  const changeMachineMaxRoughness = (data) => {
    if (socket) {
      socket.emit("put-machine-max-roughness", { ...data })
    }
  }

  const changeMachineRoughnessMarkup = (data) => {
    if (socket) {
      socket.emit("put-machine-roughness-markup", { ...data })
    }
  }

  return (
    <Layout
      style={{
        overflow: 'auto',
        minHeight: '100vh',
      }}
    >
      {user?.role === "producerAdmin" ?
        <ProducerSidebar
          onLogout={()=>{dispatch(logoutUser())}}
          user={user}
        />
        :
        <CompanySidebar
          onLogout={()=>{dispatch(logoutUser())}}
          user={user}
        />
      }
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}} type="flex">
        <div style={{paddingLeft: 15, paddingTop: 15, paddingRight: 15}}>
          {user?.role === "producerAdmin" &&
          <div>
            <div style={{position: "absolute", right: 25, color: "white", fontSize: 16, fontWeight: 600, top: 8}}>
              {user.producer?.name}
            </div>
            <div style={{position: "absolute", color: "white", fontSize: 11, fontWeight: 500, right: 25, top: 30}}>
              {user.producer?.address}, {user.producer?.postalCode} {user.producer?.city}, {user.producer?.country}
            </div>
          </div>
          }
          <Tabs size={"small"} activeKey={selectedTab} onChange={(e)=>{setSelectedTab(e)}} type="card" className={"materialsTabs noSelect"}>
            <TabPane className={"materialsTabPane noSelect"} tab="Cutting Machines" key="1">
              <LaserCuttingTab
                selectedTab={selectedTab}
                changeCuttingSpeed={changeCuttingSpeed}
                changeCuttingMachineParams={changeCuttingMachineParams}
                changeMachineMaxTolerance={changeMachineMaxTolerance}
                changeMachineToleranceMarkup={changeMachineToleranceMarkup}
                socketConnected={socketConnected}
              />
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Bending" key="2">
              <Spin spinning={isFetchingBendingData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px",
                    }}
              >
                <MaterialBendingTab
                  changeBendingParams={changeBendingParams}
                  isGelso={isGelso}
                  selectedTab={selectedTab}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Simple Machining" key="3">
              <Spin spinning={isFetchingSimpleMachiningData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <SimpleMachiningTab
                  isFetchingSimpleMachiningData={isFetchingSimpleMachiningData || !socketConnected}
                  selectedTab={selectedTab}
                  isGelso={isGelso}
                  changeDeburringParams={changeDeburringParams}
                  changeChamferingParams={changeChamferingParams}
                  changeDrillingParams={changeDrillingParams}
                  changeSimpleMachiningParams={changeSimpleMachiningParams}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Machining" key="4">
              <Spin spinning={isFetchingMachiningData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <MachiningTab
                  isFetchingMachiningData={isFetchingMachiningData || !socketConnected}
                  selectedTab={selectedTab}
                  isGelso={isGelso}
                  changeMillingParams={changeMillingParams}
                  changeLathingParams={changeLathingParams}
                  changeGradeMillingSpeed={changeGradeMillingSpeed}
                  changeGradeLathingSpeed={changeGradeLathingSpeed}
                  changeMachineMaxTolerance={changeMachineMaxTolerance}
                  changeMachineToleranceMarkup={changeMachineToleranceMarkup}
                  changeMachineMaxRoughness={changeMachineMaxRoughness}
                  changeMachineRoughnessMarkup={changeMachineRoughnessMarkup}
                  socketConnected={socketConnected}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Tube Cutting" key="5">
              <Spin spinning={isFetchingTubeCuttingData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <TubeCuttingTab
                  changeTubeCuttingMachineParams={changeTubeCuttingMachineParams}
                  changeTubeCuttingMachineSpeed={changeTubeCuttingMachineSpeed}
                  changeMachineMaxTolerance={changeMachineMaxTolerance}
                  changeMachineToleranceMarkup={changeMachineToleranceMarkup}
                  selectedTab={selectedTab}
                  socketConnected={socketConnected}
                  isGelso={isGelso}
                />
              </Spin>
            </TabPane>
            <TabPane className={"materialsTabPane noSelect"} tab="Surface Treatments" key="6">
              <Spin spinning={isFetchingSurfaceCoatingData || !socketConnected}
                    wrapperClassName={"materialsSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
                    style={{
                      position: "absolute",
                      top: "15%",
                      left: "-36px"
                    }}
              >
                <SurfaceCoatingTab
                  isFetchingSurfaceCoatingData={isFetchingSurfaceCoatingData}
                  isGelso={isGelso}
                  changePaintingParams={changePaintingParams}
                  changePowderPaintingParams={changePowderPaintingParams}
                  changeGalvanizingParams={changeGalvanizingParams}
                  changeNickelPlatingParams={changeNickelPlatingParams}
                  changeAnodizingParams={changeAnodizingParams}
                  changeGalvanicZincPlatingParams={changeGalvanicZincPlatingParams}
                  changeHardeningParams={changeHardeningParams}
                  changeCaseHardeningParams={changeCaseHardeningParams}
                  changeAnnealingParams={changeAnnealingParams}
                  changeGlassBeadBlastingParams={changeGlassBeadBlastingParams}
                  changeSandBlastingParams={changeSandBlastingParams}
                  selectedTab={selectedTab}
                />
              </Spin>
            </TabPane>

            <TabPane className={"materialsTabPane noSelect"} tab="Materials" key="7">
              <MaterialsTab
                changeMaterialPrice={changeMaterialPrice}
                changeBaseMaterialCertificateParams={changeBaseMaterialCertificateParams}
                changeAssemblyMaterialCertificateParams={changeAssemblyMaterialCertificateParams}
                changeMachiningMaterialPrice={changeMachiningMaterialPrice}
                changeTubeMaterialPrice={changeTubeMaterialPrice}
                changeStandardTubeMaterialPrice={changeStandardTubeMaterialPrice}
                selectedTab={selectedTab}
                socketConnected={socketConnected}
              />
            </TabPane>
          </Tabs>
        </div>
      </Layout.Content>
    </Layout>
  )
}