import React, {useEffect} from "react";
import "./style.less";
import {Card, Upload} from "antd";
import dragAndDropWhite from "../../../../../assets/icons/dragndropwhite.png";

const { Dragger } = Upload;

let dragging = 0;

export const OrderDragger = (props) => {

  const {
    dragEnterCondition,
    handleFocus, beforeUpload,
  } = props;

  useEffect(() => {
    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("drop", handleDrop, false);
    handleFocus && window.addEventListener("focus", handleFocus)
    return () => {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("drop", handleDrop, false);
      handleFocus && window.removeEventListener("focus", handleFocus)
    }
  }, [])

  const handleDragEnter = e => {
    dragging++
    e.stopPropagation();
    e.preventDefault();

    let dt = e.dataTransfer;
    if (dt.types != null && ((dt.types.length && (dt.types[0] === 'Files' || dt.types[0] === 'application/x-moz-file')))) {
      let dropper = document.getElementsByClassName("fileDropZone")[0]
      if (
        (dropper.style.display === "none" || dropper.style.display === "")
        && dragEnterCondition
      ) {
        dropper.style.display = "block"
      }
    }
    return false
  };

  const handleDragLeave = e => {
    dragging--;
    if (dragging === 0) {
      document.getElementsByClassName("fileDropZone")[0].style.display = "none"
    }

    e.stopPropagation();
    e.preventDefault();
    return false
  };

  const handleDrop = e => {
    e.preventDefault();
    dragging=0
    document.getElementsByClassName("fileDropZone")[0].style.display = "none"
  };

  return (
    <Dragger
      className={"fileDropZone"}
      style={{
        display: "none",
        position: "fixed",
        margin: 5,
        top: 0,
        left: 0,
        width: "calc(100% - 12px)",
        maxHeight: "calc(100vh - 12px)",
        backgroundColor: "rgba(0.5,0.5,0.5,0.2)",
        border: "2px dashed #066fc1",
        zIndex: 2000,
      }}
      name = {'file'}
      beforeUpload={beforeUpload}
      multiple={true}
      showUploadList={false}
    >
      <div align={'center'}>
        <Card className={"draggerCard"}>
          <img src={dragAndDropWhite} className={"drop-file-icon"} alt={""} />
          <span
            className={"text14-600"}
            style={{color: "white", marginLeft: 15, fontSize: 16}}
          >
            Drop your files here
          </span>
        </Card>
      </div>
    </Dragger>
  )
}