import React, {useState} from 'react';
import { Card, Radio } from 'antd';
import { CustomerForm } from './Components/CustomerRegistration';
import { CompanyForm } from "./Components/CompanyRegistration";
import logo from '../../assets/vectors/step2real_logo_black.svg';
import { LinkWrapper, Logo } from './styles';
import { Link } from '../../components';
import {useWindowSize} from "../../utils/utility";
import history from "../../utils/history";
import "./style.less";
import { useDispatch, useSelector } from "react-redux";
import { registerCompany, registerCustomer } from "../../actions/authActions";

const RegisterWrapper = (props) => {
  const {selected, isRegistering, handleClick} = props;
  return (
    <div style={{transition: "width 0.1s linear", width: selected === 'customer' ? 680 : 750}}>
      {selected === 'customer' ?
        (
          <CustomerForm
            handleClick={handleClick}
            isRegistering={isRegistering}
          />
        )
        :
        (
          <CompanyForm
            handleClick={handleClick}
            isRegistering={isRegistering}
          />
        )
      }
    </div>
  );
}

export const RegisterPage = () => {

  const [selected, setSelected] = useState("customer");
  const isRegistering = useSelector(state => state.auth.registering);
  const isRegisteringCompany = useSelector(state => state.auth.registeringCompany);

  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  const handleClick = (form) => {
    return dispatch(registerCustomer({ ...form }))
  }

  const handleCompanyClick = (form) => {
    return dispatch(registerCompany({ ...form }))
  }

  const handleSizeChange = e => {
    setSelected(e.target.value);
  }

  return (
    <div className={"publicWrapper"}>
      <div className={windowSize.width > 800 ? "tiledBackgroundWide" : "tiledBackgroundNarrow"}>
        <div style={{height: "100%", display: "flex", minHeight: 750}}>
          <Card className={"registerCard"}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center",  height: 140}}>
              <Logo src={logo} alt={"Step2Real"}/>
            </div>
            <div align={'center'} style={{marginBottom: 10}}>
              <Radio.Group
                value={selected}
                buttonStyle="solid"
                onChange={handleSizeChange}
                style={{fontWeight: 600, background: "transparent", border: "none", boxShadow: "none" }}
              >
                <Radio.Button value="customer">Private Customer</Radio.Button>
                <Radio.Button value="company">Corporate Customer</Radio.Button>
              </Radio.Group>
            </div>
            <RegisterWrapper
              selected={selected}
              isRegistering={selected === "customer" ? isRegistering : isRegisteringCompany}
              handleClick={selected === "customer" ? handleClick : handleCompanyClick}
            />
            <LinkWrapper>
              <Link to="/login" history={history}>
                Already have an account?
              </Link>
            </LinkWrapper>
          </Card>
        </div>
      </div>
    </div>
  )
}