import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from "react";

export function Icon3D(props) {
    return (
        <SvgIcon {...props}>
          <g stroke="currentColor" fill="none">
            <path d="M4.903 11.468a1.707 1.707 0 101.706-1.706M6.61 9.558h0a1.706 1.706 0 10-1.707-1.706M11.277 13.176h-.441V6.144h.44a3.516 3.516 0 010 7.032z"/>
            <path d="M14.199 18.43H4.918a4.23 4.23 0 01-4.23-4.232v-9.28a4.23 4.23 0 014.23-4.23h9.28a4.232 4.232 0 014.232 4.23v9.28a4.23 4.23 0 01-4.231 4.231z"/>
            <path d="M17.043 1.794s2.527 2.494 2.963 2.95a4.211 4.211 0 011.174 2.924v9.28a4.232 4.232 0 01-4.23 4.232H7.667A4.213 4.213 0 014.9 20.148c-.588-.508-2.835-2.827-2.835-2.827"/></g>
        </SvgIcon>
    );
}