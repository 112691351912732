import React, { useEffect, useState } from "react";
import { Button, Cascader, Col, Divider, Form, Input, InputNumber, Row, Tooltip } from "antd";
import { CaretDownOutlined, CaretRightOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { CoatingPicker } from "../../../CoatingPicker";
import { profiles } from "../../../../../data/profiles";

export const StandardProfileAndTubeCutting = (props) => {
  const {item, addNewPart, isAddingNewPart, assemblyData, partType} = props;
  const [form] = Form.useForm();

  const [coating, setCoating] = useState({
    hasCoating: false,
    type: "painting",
    data: {
      colorRAL: "",
      colorGlossId: "",
      ralRow: 0,
      ralHex: ""
    }
  });

  useEffect(() => {
    if(item && item.coating) {
      setCoating(item.coating);
    }
  }, [item])

  useEffect(()=>{
    if(item && item.standard) {
      if(item.standard === "" || item.standard === null) {
        form.setFieldsValue({
          standard: null
        })
      } else {
        let standard = item.standard.split("/");
        standard = standard.map(item => item.trim());
        let material = standard[1] + " / " + standard[2]
        standard.splice(1, 2, material)
        form.setFieldsValue({
          standard: standard
        })
      }
    }

  }, [item?.standard])

  const clearStandard = () => {
    form.setFieldsValue({
      standard: null
    })
  }

  const changePartColor = (data) => {
    let coating;
    if (data.coatingType === "painting") {
      coating = {
        hasCoating: true,
        type: data.coatingType,
        data: {
          colorRAL: data.colorRAL,
          colorGlossId: data.colorGlossId.toString(),
          ralRow: data.ralRow,
          ralHex: data.ralHex
        }
      }
    } else {
      coating = {
        hasCoating: true,
        type: data.coatingType
      }
    }
    setCoating({
      ...coating
    })
  }

  const clearPartColor = () => {
    setCoating({
      hasCoating: false,
      type: "painting",
      data: {
        colorRAL: "",
        colorGlossId: "",
        ralRow: 0,
        ralHex: ""
      }
    })
  }

  return (
    <div>
      <Form
        className={"manualQuoteForm"}
        name={"manualQuoteProfile"}
        form={form}
        onFinish={(values)=>{
          let tmpCoating = coating.hasCoating ? { ...coating } : { hasCoating: false, type: "painting", data: { colorRAL: "", colorGlossId: "", ralRow: 0, ralHex: "" } };
          addNewPart({
            partType: partType,
            partId: item?.id,
            data: {
              ...values,
              standard: values.standard.join(" / "),
              coating: tmpCoating,
              uploadTime: Date.now(),
              uploadIndex: 1,
            }
          })
        }}
      >
        <Row>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              <div style={{display: "flex"}}>
                <div style={{fontWeight: 500, fontSize: 13}}>Name:</div>
                {item?.assemblyId &&
                <Tooltip
                  placement={"top"}
                  color={"#656565"}
                  title={
                    <div style={{ color: "white", fontSize: 12, fontWeight: 500 }}>
                      <div>This part belongs to the assembly:</div>
                      <div>{assemblyData[item?.assemblyId]?.name}</div>
                    </div>
                  }
                >
                  <QuestionCircleOutlined style={{ color: "#ffffff", marginLeft: 5, marginTop: 3 }} />
                </Tooltip>
                }
              </div>
              <Form.Item
                name={"name"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item?.fileName || null}
              >
                <Input
                  className={"quoteModalName"}
                  style={{width: "100%", height: 32}}
                  placeholder={'eg. Flat Bar'}
                  disabled={!!item}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              {item?.assemblyId ?
                <div style={{display: "flex"}}>
                  <div style={{fontWeight: 500, fontSize: 13}}>Quantity per Assembly:</div>
                  <Tooltip
                    placement={"top"}
                    color={"#656565"}
                    title={
                      <div style={{ color: "white", fontSize: 12, fontWeight: 500 }}>
                        <div>This part belongs to an assembly.</div>
                        <div>Number of assemblies: {assemblyData[item?.assemblyId]?.quantity}</div>
                        <div>Quantity per assembly: {item?.quantity}</div>
                      </div>
                    }
                  >
                    <QuestionCircleOutlined style={{ color: "#ffffff", marginLeft: 5, marginTop: 3 }} />
                  </Tooltip>
                </div>
                :
                <div style={{fontWeight: 500, fontSize: 13}}>Quantity:</div>
              }
              <Form.Item
                name={"quantity"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item ? item?.quantity : 1}
              >
                <InputNumber
                  min={1}
                  max={1000000}
                  style={{width: "100%"}}
                  placeholder={'Part quantity'}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <div style={{fontWeight: 500, fontSize: 13}}>Price 1pc (€):</div>
              <Form.Item
                name={"pricePerPiece"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={0}
              >
                <InputNumber
                  min={0}
                  max={1000000}
                  style={{width: "100%"}}
                  placeholder={'Price per piece'}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              <div style={{fontWeight: 500, fontSize: 13}}>Type of Profile:</div>
              <Form.Item
                name={"standard"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={null}
              >
                <Cascader
                  options={profiles}
                  className={"text12-500 muiInput"}
                  dropdownClassName={"orderItemMaterialSelectDropDown profileTypeDropDown"}
                  style={{ width: '100%' }}
                  placeholder="Select profile type"
                  onClear={clearStandard}
                  showSearch={{
                    filter: (inputValue, path) => {return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);},
                    limit: 400
                  }}
                  suffixIcon={(e) => {
                    return e.focused ? <SearchOutlined/> : <CaretDownOutlined/>
                  }}
                  expandIcon={<CaretRightOutlined/>}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <div style={{fontWeight: 500, fontSize: 13}}>Coating:</div>
              <div style={{display: 'flex'}}>
                <CoatingPicker
                  partId={null}
                  partType={partType}
                  type={coating.type}
                  changeColor={changePartColor}
                  hasCoating={coating.hasCoating}
                  colorRAL={coating.data?.colorRAL}
                  colorGlossId={coating.data?.colorGlossId}
                  ralRow={coating.data?.ralRow}
                  ralHex={coating.data?.ralHex}
                  handleColorRemove={clearPartColor}
                  isMultiEdit={true}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Divider className={"lightDivider"} style={{ margin: "-6px 0 12px 0" }}/>
        <div align={"right"} style={{marginTop: 10}}>
          <Button
            type={"default"}
            onClick={()=>{props.setManualQuoteStep(1)}}
            style={{fontWeight: 500, fontSize: 13}}
            disabled={isAddingNewPart}
          >
            Back
          </Button>
          <Button
            type={"primary"}
            htmlType={"submit"}
            style={{marginLeft: 5, fontWeight: 500, fontSize: 13}}
            loading={isAddingNewPart}
          >
            {!!item ? "Quote Part" : "Add Part"}
          </Button>
        </div>
      </Form>
    </div>
  )
}