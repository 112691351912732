import React from "react";
import {Form, Row, Col, Tooltip, Input, InputNumber, Divider, Button} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

export const BulkAndBuy = (props) => {
  const {item, addNewPart, isAddingNewPart, assemblyData, partType} = props;

  return (
    <div>
      <Form
        className={"manualQuoteForm"}
        name={"manualQuoteOther"}
        onFinish={(values)=>{
          addNewPart({
            partType: partType,
            partId: item?.id,
            data: {
              ...values,
              uploadTime: Date.now(),
              uploadIndex: 1,
            }
          })
        }}
      >
        <Row>
          <Col span={12}>
            <div style={{marginRight: 10}}>
              <div style={{display: "flex"}}>
                <div style={{fontWeight: 500, fontSize: 13}}>Name:</div>
                {item?.assemblyId &&
                <Tooltip
                  placement={"top"}
                  color={"#656565"}
                  title={
                    <div style={{ color: "white", fontSize: 12, fontWeight: 500 }}>
                      <div>This part belongs to the assembly:</div>
                      <div>{assemblyData[item?.assemblyId]?.name}</div>
                    </div>
                  }
                >
                  <QuestionCircleOutlined style={{ color: "#ffffff", marginLeft: 5, marginTop: 3 }} />
                </Tooltip>
                }
              </div>
              <Form.Item
                name={"name"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item?.fileName || null}
              >
                <Input
                  className={"quoteModalName"}
                  style={{height: 32, width: "100%"}}
                  placeholder={'eg. Screw M1.6'}
                  disabled={!!item}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div>
              {item?.assemblyId ?
                <div style={{display: "flex"}}>
                  <div style={{fontWeight: 500, fontSize: 13}}>Quantity per Assembly:</div>
                  <Tooltip
                    placement={"top"}
                    color={"#656565"}
                    title={
                      <div style={{ color: "white", fontSize: 12, fontWeight: 500 }}>
                        <div>This part belongs to an assembly.</div>
                        <div>Number of assemblies: {assemblyData[item?.assemblyId]?.quantity}</div>
                        <div>Quantity per assembly: {item?.quantity}</div>
                      </div>
                    }
                  >
                    <QuestionCircleOutlined style={{ color: "#ffffff", marginLeft: 5, marginTop: 3 }} />
                  </Tooltip>
                </div>
                :
                <div style={{fontWeight: 500, fontSize: 13}}>Quantity:</div>
              }
              <Form.Item
                name={"quantity"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item ? item?.quantity : 1}
              >
                <InputNumber
                  min={1}
                  max={1000000}
                  style={{width: "100%"}}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{marginRight: 10}}>
              <div style={{fontWeight: 500, fontSize: 13}}>OEM:</div>
              <Form.Item
                name={"standard"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item?.standard || null}
              >
                <Input
                  className={"quoteModalName"}
                  style={{width: "100%", height: 32}}
                  placeholder={'eg. ISO 4762'}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div>
              <div style={{fontWeight: 500, fontSize: 13}}>Price 1pc (€):</div>
              <Form.Item
                name={"pricePerPiece"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={0}
              >
                <InputNumber
                  min={0}
                  max={1000000}
                  style={{width: "100%"}}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider className={"lightDivider"} style={{ margin: "-6px 0 12px 0" }}/>
        <div align={"right"} style={{marginTop: 10}}>
          <Button
            type={"default"}
            onClick={()=>{props.setManualQuoteStep(1)}}
            style={{fontWeight: 500, fontSize: 13}}
            disabled={isAddingNewPart}
          >
            Back
          </Button>
          <Button
            type={"primary"}
            htmlType={"submit"}
            style={{marginLeft: 5, fontWeight: 500, fontSize: 13}}
            loading={isAddingNewPart}
          >
            {!!item ? "Quote Part" : "Add Part"}
          </Button>
        </div>
      </Form>
    </div>
  )
}