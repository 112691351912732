import React, {useEffect} from "react";
import "./style.less";
import {Card, Col, Divider, Row, Switch} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {ProducerMaterialInput, ProducerMaterialsCheckbox} from "../../NonEditableFields";
import {fetchProducerSimpleMachiningData} from "../../../../../actions/producerParamsActions";

export const SimpleMachiningTab = (props) => {
  const {
    selectedTab,
    producerId
  } = props;

  const simpleMachiningData = useSelector(state => state.producerParams.simpleMachiningData)

  const dispatch = useDispatch()

  useEffect(()=>{
    if(selectedTab === "3") {
      fetchData()
    }
  }, [selectedTab])

  const fetchData = () => {
    dispatch(fetchProducerSimpleMachiningData({producerId: producerId}))
  }

  return (
    <React.Fragment>
      <Card className={"tabMaterialCard"} bodyStyle={{paddingRight: 0, paddingBottom: 15}}>
        <div style={{height: 'calc(100vh - 172px)', overflowY: "auto", overflowX: "hidden", paddingRight: 15}}>
          <Row>
            <Col xl={14} span={24}>
              <div className={"switchContainer"}>
                <span style={{marginRight: 30, marginBottom: 6}}>Producer offers Simple Machining service?</span>
                <span>No</span>
                <Switch
                  checked={simpleMachiningData.hasSimpleMachining}
                  style={{marginLeft: 6, marginRight: 6}}
                  className={"producersUneditableSwitch"}
                  disabled={true}
                />
                <span>Yes</span>
              </div>
            </Col>
          </Row>
          <Divider style={{width: "75%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
          {simpleMachiningData.hasSimpleMachining &&
            <div>
              <div className={"mainForm"}>
                <Row gutter={16}>
                  {/*------------------------------------------------------------------------------------------------*/}
                  <Col xl={12} span={24}>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <ProducerMaterialsCheckbox label={"Deburring"} value={simpleMachiningData.deburring.hasDeburring}/>
                    </div>
                    <span className={"processDescription"}>(Post Sheet metal processing)</span>
                    <Card className={"inputInfoText"} style={{margin: "12px 0px 12px 0px"}} bodyStyle={{padding: "8px 16px"}}>
                      <div className={simpleMachiningData.deburring.hasDeburring ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Maximum width of Sheet metal part"}
                          value={simpleMachiningData.deburring.maxWidth}
                          suffix={"mm"}
                        />
                      </div>
                      <div className={simpleMachiningData.deburring.hasDeburring ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Maximum thickness of Sheet metal part"}
                          value={simpleMachiningData.deburring.maxThickness}
                          suffix={"mm"}
                        />
                      </div>
                      <div className={simpleMachiningData.deburring.hasDeburring ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Deburring price"}
                          value={simpleMachiningData.deburring.deburringPrice}
                          suffix={<span>€/m<sup>2</sup></span>}
                        />
                      </div>
                      <div className={simpleMachiningData.deburring.hasDeburring ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Average preparation time"}
                          value={simpleMachiningData.deburring.averagePreparationTime}
                          suffix={"min"}
                        />
                      </div>
                    </Card>
                  </Col>

                  {/*</Row>*/}
                  {/*------------------------------------------------------------------------------------------------*/}
                  {/*<Row>*/}
                  <Col xl={12} span={24}>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <ProducerMaterialsCheckbox label={"Chamfering"} value={simpleMachiningData.chamfering.hasChamfering}/>
                    </div>
                    <span className={"processDescription"}>(Sheet metal edges processing - fillet, beveling, chamfer...)</span>
                    <Card className={"inputInfoText"} style={{margin: "12px 0px 12px 0px"}}  bodyStyle={{padding: "8px 16px"}}>
                      <div className={simpleMachiningData.deburring.hasChamfering ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Maximum thickness of Sheet metal part"}
                          value={simpleMachiningData.chamfering.maxThickness}
                          suffix={"mm"}
                        />
                      </div>

                      <div className={simpleMachiningData.deburring.hasChamfering ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Chamfering price"}
                          value={simpleMachiningData.chamfering.chamferingPrice}
                          suffix={"€/m"}
                        />
                      </div>

                      <div className={simpleMachiningData.deburring.hasChamfering ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Average preparation time"}
                          value={simpleMachiningData.chamfering.averagePreparationTime}
                          suffix={"min"}
                        />
                      </div>

                      <div style={{height: 42, width: "100%"}}/>
                    </Card>
                  </Col>
                  {/*</Row>*/}
                  {/*------------------------------------------------------------------------------------------------*/}
                  {/*<Row>*/}
                  <Col xl={12} span={24}>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <ProducerMaterialsCheckbox label={"Drilling"} value={simpleMachiningData.drilling.hasDrilling}/>
                      </div>
                    <span className={"processDescription"}>(Sheet metal hole processing - simple threading, countersink hole, ...)</span>
                    <Card className={"inputInfoText"} style={{margin: "12px 0px 12px 0px"}} bodyStyle={{padding: "8px 16px"}}>
                      <div className={simpleMachiningData.drilling.hasDrilling ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Maximum thickness of Sheet metal part"}
                          value={simpleMachiningData.drilling.maxThickness}
                          suffix={"mm"}
                        />
                      </div>

                      <div className={simpleMachiningData.drilling.hasDrilling ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Maximum hole diameter"}
                          value={simpleMachiningData.drilling.maxDiameter}
                          suffix={"mm"}
                        />
                      </div>

                      <div className={simpleMachiningData.drilling.hasDrilling ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Drilling price"}
                          value={simpleMachiningData.drilling.drillingPrice}
                          suffix={"€/h"}
                        />
                      </div>

                      <div className={simpleMachiningData.drilling.hasDrilling ? "" : "disabledField"}
                           style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                        <ProducerMaterialInput
                          label={"Average preparation time"}
                          value={simpleMachiningData.drilling.averagePreparationTime}
                          suffix={"min"}
                        />
                      </div>

                    </Card>
                  </Col>
                  {/*------------------------------------------------------------------------------------------------*/}
                </Row>
              </div>

              <Row gutter={16}>
                <Col xl={14} span={24}>
                  <div style={{display: "flex", alignItems: "center", marginTop: 10, marginBottom: 10}}>
                    <ProducerMaterialInput
                      label={"Preparation price for all processes"}
                      value={simpleMachiningData.preparationPrice}
                      suffix={"€/h"}
                    />
                  </div>
                  <Divider className={"lightDivider"} style={{width: "50%", minWidth: "0%", margin: "8px 0 8px 0"}}/>
                </Col>
              </Row>
            </div>
          }
        </div>
      </Card>
    </React.Fragment>
  )
}