import {
  PRODUCERS_FETCHING_PRODUCER_DETAILS,
  PRODUCERS_FETCHING_PRODUCERS,
  PRODUCERS_PRODUCERS,
  PRODUCERS_UPDATE_PRODUCER_BLOCKED,
  PRODUCERS_SET_IS_CREATING_PRODUCER,
  PRODUCERS_SET_IS_UPDATING_PRODUCER_INFO,
  PRODUCERS_SET_IS_UPDATING_PRODUCER_LOGO,
  PRODUCERS_SET_PRODUCER_LOGO
} from "./actionTypes";
import { API_BASE_URL } from '../constants'
import { http, message } from '../utils'
import { forceLogoutUser } from "./authActions";

const fetchProducers = () => dispatch => {
  dispatch({
    type: PRODUCERS_FETCHING_PRODUCERS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/producer`)
    .then(response => {
      dispatch({
        type: PRODUCERS_PRODUCERS,
        payload: response.data.producers,
      })
      dispatch({
        type: PRODUCERS_FETCHING_PRODUCERS,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      }
      else {
        dispatch({
          type: PRODUCERS_FETCHING_PRODUCERS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const createNewProducer = payload => dispatch => {
  dispatch({
    type: PRODUCERS_SET_IS_CREATING_PRODUCER,
    payload: true,
  })

  const formData = new FormData()
  formData.set('data', JSON.stringify(payload.data))
  formData.set('file', payload.file)

  return http
    .formPost(`${API_BASE_URL}/producer`, formData)
    .then(() => {
      dispatch({
        type: PRODUCERS_SET_IS_CREATING_PRODUCER,
        payload: false,
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      }
      else {
        dispatch({
          type: PRODUCERS_SET_IS_CREATING_PRODUCER,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })
}

const fetchProducer = payload => dispatch => {
  dispatch({
    type: PRODUCERS_FETCHING_PRODUCER_DETAILS,
    payload: true,
  })

  return http
    .get(`${API_BASE_URL}/producer/${payload.producerId}`)
    .then(response => {
      dispatch({
        type: PRODUCERS_FETCHING_PRODUCER_DETAILS,
        payload: false,
      })

      return response.data
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      }
      else {
        dispatch({
          type: PRODUCERS_FETCHING_PRODUCER_DETAILS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })
}

const changeProducerOrders = payload => dispatch => {
  return http
    .put(`${API_BASE_URL}/producer/order/${payload.producerId}`, { "hasOrdering": payload.hasOrdering })
    .then((response) => {
      return payload.producerId
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const changeProducerBulk = payload => dispatch => {
  return http
    .put(`${API_BASE_URL}/producer/bulk/${payload.producerId}`, { "hasBulk": payload.hasBulk })
    .then((response) => {
      return payload.producerId
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const changeProducerNesting = payload => dispatch => {
  return http
    .put(`${API_BASE_URL}/producer/nest/${payload.producerId}`, { "hasNesting": payload.hasNesting })
    .then((response) => {
      return payload.producerId
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const toggleBlockProducer = payload => dispatch => {
  return http
    .post(`${API_BASE_URL}/producer/block/${payload.producerId}`, {"isBlocked": payload.isBlocked})
    .then((response) => {
      dispatch({
        type: PRODUCERS_UPDATE_PRODUCER_BLOCKED,
        payload: payload.producerId
      })
    })
    .catch((error) => {
      if(error.response.status === 403 && error.response.data.message === "Unauthorized action. User not logged in.") {
        console.log("Unauthorized action. User not logged in.")
      }
      else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}

const updateProducerInfo = payload => dispatch => {
  dispatch({
    type: PRODUCERS_SET_IS_UPDATING_PRODUCER_INFO,
    payload: true,
  })
  return http
    .put(`${API_BASE_URL}/producer/update/${payload.producerId}`, payload.data)
    .then(() => {
      dispatch({
        type: PRODUCERS_SET_IS_UPDATING_PRODUCER_INFO,
        payload: false,
      })
    })
    .catch((error) => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      }
      else {
        dispatch({
          type: PRODUCERS_FETCHING_PRODUCER_DETAILS,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })
}

const updateProducerLogo = payload => dispatch => {
  dispatch({
    type: PRODUCERS_SET_IS_UPDATING_PRODUCER_LOGO,
    payload: true,
  })

  const formData = new FormData()
  formData.set('file', payload.file)

  return http
    .formPut(`${API_BASE_URL}/producer/logo/${payload.producerId}`, formData)
    .then((res) => {
      dispatch({
        type: PRODUCERS_SET_PRODUCER_LOGO,
        payload: {
          logo: res.data.logoUrl,
          producerId: payload.producerId
        }
      })

      dispatch({
        type: PRODUCERS_SET_IS_UPDATING_PRODUCER_LOGO,
        payload: false,
      })
      return res.data.logoUrl
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      }
      else {
        dispatch({
          type: PRODUCERS_SET_IS_UPDATING_PRODUCER_LOGO,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })
}

export {
  fetchProducers, createNewProducer, fetchProducer,
  changeProducerOrders, changeProducerNesting,
  changeProducerBulk, toggleBlockProducer,
  updateProducerInfo, updateProducerLogo
}
