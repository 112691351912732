import {
  AUTH_SET_USER,
  AUTH_SET_LOGGING_IN,
  AUTH_SET_REGISTERING,
  AUTH_SET_REDIRECT,
  AUTH_SET_REGISTERING_COMPANY,
} from './actionTypes'
import { push } from 'connected-react-router'

import { API_BASE_URL } from '../constants'

import { http, storage, message } from '../utils'

const setRedirect = payload => dispatch => {
  dispatch({
    type: AUTH_SET_REDIRECT,
    payload,
  })
}

const registerCustomer = payload => dispatch => {
  dispatch({
    type: AUTH_SET_REGISTERING,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/auth/register-regular-customer`, payload)
    .then(() => {
      dispatch({ type: AUTH_SET_REGISTERING, payload: false })
      dispatch(push('/login'))
      message.show(message.type.success, 'A confirmation email has been sent to your email address.')
    })
    .catch(error => {
      message.show(message.type.error, http.getErrorMessage(error))
      dispatch({ type: AUTH_SET_REGISTERING, payload: false })
    })
}

const registerCompany = payload => dispatch => {
  dispatch({
    type: AUTH_SET_REGISTERING_COMPANY,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/auth/register-corporate-customer`, payload)
    .then(() => {
      dispatch({type: AUTH_SET_REGISTERING_COMPANY, payload: false})
      dispatch(push('/login'))
      message.show(
        message.type.success,
          'A confirmation email has been sent to your email address.'
      )
    })
    .catch(error => {
      message.show(message.type.error, http.getErrorMessage(error))
      dispatch({
        type: AUTH_SET_REGISTERING_COMPANY,
        payload: false,
      })
    })
}

const adminRegisterCompany = payload => dispatch => {
  dispatch({
    type: AUTH_SET_REGISTERING_COMPANY,
    payload: true,
  })

  const formData = new FormData()
  formData.set('data', JSON.stringify(payload.data))
  formData.set('image', payload.image)

  return http
    .formPost(`${API_BASE_URL}/producer`, formData)
    .then(() => {
      dispatch({
        type: AUTH_SET_REGISTERING_COMPANY,
        payload: false,
      })
      message.show(message.type.success, "Created new Producer")
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        dispatch({
          type: AUTH_SET_REGISTERING_COMPANY,
          payload: false,
        })
      }
    })
}

const loginUser = payload => dispatch => {
  dispatch({
    type: AUTH_SET_LOGGING_IN,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/auth/login`, {...payload.form})
    .then(response => {
      const authData = response.data

      storage.storeAuthData(authData)
      dispatch({
        type: AUTH_SET_USER,
        payload: authData.user,
      })

      dispatch({
        type: AUTH_SET_LOGGING_IN,
        payload: false,
      })

      dispatch(push(payload.redirectTo || '/'))
    })
    .catch(error => {
      if(error.response) {
        message.show(message.type.error, http.getErrorMessage(error))
      } else {
        message.show(message.type.error, "Server could not be reached")
      }

      dispatch({
        type: AUTH_SET_LOGGING_IN,
        payload: false,
      })
    })
}

const loginAdmin = payload => dispatch => {
  dispatch({
    type: AUTH_SET_LOGGING_IN,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/auth/login-admin`, {...payload.form})
    .then(response => {
      const authData = response.data

      storage.storeAuthData(authData)
      dispatch({
        type: AUTH_SET_USER,
        payload: authData.user,
      })

      dispatch({
        type: AUTH_SET_LOGGING_IN,
        payload: false,
      })

      dispatch(push(payload.redirectTo || '/'))
    })
    .catch(error => {
      if(error.response) {
        message.show(message.type.error, http.getErrorMessage(error))
      }
      else {
        message.show(message.type.error, "Server could not be reached")
      }

      dispatch({
        type: AUTH_SET_LOGGING_IN,
        payload: false,
      })
    })
}

const fetchSelf = () => dispatch => {
  return http
    .get(`${API_BASE_URL}/auth/self`)
    .then(response => {
      if(response.data.user) {
        dispatch({
          type: AUTH_SET_USER,
          payload: response.data.user
        })
        storage.updateUser(response.data.user)
      }
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
        throw error
      }
    })
}

const logoutUser = () => dispatch => {
    storage.storeAuthData(null)
    dispatch({
        type: AUTH_SET_USER,
        payload: null,
    })
    message.show(message.type.info, 'You have been successfully logged out.')
    dispatch(push('/login'))
}

const forceLogoutUser = () => dispatch => {
  storage.storeAuthData(null)
  dispatch({
    type: AUTH_SET_USER,
    payload: null,
  })
  message.show(message.type.info, 'You have logged in on a different device.')
  dispatch(push('/login'))
}

export {
  loginUser,
  registerCustomer,
  registerCompany,
  logoutUser,
  setRedirect,
  adminRegisterCompany,
  loginAdmin,
  forceLogoutUser,
  fetchSelf
}
