import {fetchStats} from "../../../actions/utilActions";
import React, {useEffect, useState} from "react";
import {
  formatDate,
  formatTime,
  getCoatingType,
  getManualType,
  getRoughnessValue,
  getSevenDaysAgoDate,
  getTodaysDate,
  getToleranceValue,
  getWeight,
  getWidths,
  hasWeight
} from "../../../utils/utility";
import {useDispatch, useSelector} from "react-redux";
import {Button, Divider, Modal, Radio, Select, Space, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import PersonIcon from "@mui/icons-material/Person";
import {UserDateRange} from "../../UserDateRange";
import writeXlsxFile from 'write-excel-file'
import "./style.less";

export const CustomerDraftStatisticsModal = (props) => {

  const { visible, setVisible, customers } = props

  const isFetchingProducersForOrder = useSelector(state => state.orders.isFetchingProducersForOrder)
  const producersForOrder = useSelector(state => state.orders.producersForOrder)
  const isFetchingCustomers = useSelector(state => state.orders.isFetchingArchivedCalculationCustomers);

  const [selectedRadio, setSelectedRadio] = useState(1)
  const [allCustomersSelected, setAllCustomersSelected] = useState(true)
  const [selectedDateRange, setSelectedDateRange] = useState({
    timestampFrom: getSevenDaysAgoDate().getTime(),
    timestampTo: getTodaysDate().getTime()
  })
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [preppingData, setPreppingData] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if(selectedCustomers.length === 0) {
      setSelectedRadio(1)
      setAllCustomersSelected(true)
    }
    if(customers.length !== selectedCustomers.length) {
      setAllCustomersSelected(false)
    }
  }, [selectedCustomers]);

  const getStats = () => {
    setPreppingData(true)
    let customerData = {};
    if (allCustomersSelected || selectedCustomers.length === 0) {
      for (const customer of customers) {
        customerData[customer.id] = {
          customerEmail: customer.email
        }
      }
    } else {
      let customersSet = new Set(selectedCustomers)
      for (const customer of customers) {
        if(customersSet.has(customer.id)) {
          customerData[customer.id] = {
            customerEmail: customer.email
          }
        }
      }
    }
    dispatch(fetchStats({
      customerIds: Object.keys(customerData),
      ...selectedDateRange
    })).then((stats) => {
      exportStats(stats, customerData)
    }).catch((error)=>{
      console.log(error)
      setPreppingData(false)
    })
  }

  const exportStats = (stats, customerData) => {
    const fileName = `Not_Requested_${formatDate(selectedDateRange.timestampFrom)}_${formatDate(selectedDateRange.timestampTo)}.xlsx`;
    const headerStyle = {
      backgroundColor: "#dee7e5",
      borderColor: "#000000",
      borderStyle: "thin",
      fontWeight: 600,
      fontSize: 9,
      color: "#000000",
      align: "left"
    }

    const numOfQuotes = stats.length
    let parts = []
    let assemblies = []
    const data = []
    const assemblyOrderMap = {}
    const assemblyQuantityMap = {}
    let assemblyCounter = 0
    
    for (let j=0; j<numOfQuotes; j+=1) {
      let numOfParts = stats[j].parts.length
      let numOfAssemblies = stats[j].assemblies.length
      if(numOfParts === 0) {
        continue
      }
      for (let i=0; i<numOfAssemblies; i+=1) {
        let assemblyPart = stats[j].assemblies[i]
        assemblyCounter += 1
        assemblyOrderMap[assemblyPart.id] = assemblyCounter
        assemblyQuantityMap[assemblyPart.id] = assemblyPart.quantity
        let assemblyParts = stats[j].parts.filter(item => item.assemblyId === assemblyPart.id)
        let assemblyWeight = 0;
        
        for (const assemblyPart of assemblyParts) {
          if (hasWeight({item: assemblyPart})) {
            assemblyWeight += parseFloat(getWeight({item: assemblyPart})) * (assemblyPart.quantity || 1)
          }
        }

        let size;
        if(assemblyPart.stepData?.partData?.boundingBox) {
          size = `${Math.round((assemblyPart.stepData.partData.boundingBox.x + Number.EPSILON) * 10) / 10}×${Math.round((assemblyPart.stepData.partData.boundingBox.y + Number.EPSILON) * 10) / 10}×${Math.round((assemblyPart.stepData.partData.boundingBox.z + Number.EPSILON) * 10) / 10}`
        }

        assemblies.push({
          creationDate: formatDate(assemblyPart.createdAt),
          creationTime: formatTime(assemblyPart.createdAt),
          quoteName: stats[j].name,
          customQuoteName: stats[j].customName || "/",
          customerEmail: customerData[stats[j].customerId].customerEmail,
          fileName: assemblyPart.fileName,
          size: size || "/",
          weight: assemblyWeight.toFixed(3),
          thickness: "/",
          area: "/",
          volume: "/",
          assemblyNumber: `Assembly ${assemblyCounter}`,
          assemblyParent: "/",
          numOfUniqueParts: assemblyPart.numberOfUniqueParts,
          numOfAutomaticParts: assemblyPart.numberOfAutomaticParts,
          numOfManualParts: assemblyPart.numberOfManualParts,
          quantity: assemblyPart.quantity,
          pricePerPiece: assemblyPart.pricePerPiece.toFixed(3),
          bendingPricePerPiece: "/",
          machiningPricePerPiece: "/",
          cuttingPricePerPiece: "/",
          millingPricePerPiece: "/",
          lathingPricePerPiece: "/",
          tubeCuttingPricePerPiece: "/",
          totalPrice: (assemblyPart.pricePerPiece * assemblyPart.quantity).toFixed(3),
          producerName: "/",
          needsRfq: assemblyParts.some(part => part.processingType === "manual") ? "Yes" : "No",
          type: getManualType(assemblyPart.partType),
          material: "/",
          surfaceFinish: getCoatingType(assemblyPart.coating),
          maxTolerance: "/",
          maxRoughness: "/",
          certNeeded: "/",
          deletedAt: assemblyPart.deletedAt ? (formatDate(assemblyPart.deletedAt) + " " + formatTime(assemblyPart.deletedAt)) : "/",
          draftDeletedAt: stats[j].deletedAt ? (formatDate(stats[j].deletedAt) + " " + formatTime(stats[j].deletedAt)) : "/"
        })
      }
      for (let i=0; i<numOfParts; i+=1) {
        const part = stats[j].parts[i]
        let name = (part.fileName || part.name);
        let quantity = part.quantity * (assemblyQuantityMap[part.assemblyId] || 1)
        
        let area = "/";
        let volume = "/";
        let weight = "/";

        let bendingPricePerPiece;
        let machiningPricePerPiece;
        let cuttingPricePerPiece;
        let millingPricePerPiece;
        let lathingPricePerPiece;
        let tubeCuttingPricePerPiece;
        
        if(part.fileType === "step") {
          area = part.stepData?.partData?.area ? (part.stepData.partData.area / 1000000).toFixed(3) : "/"
          if(part.stepData?.partData?.volume) {
            volume = part.stepData.partData.volume / 1000000000
            if(part.partType === 'tubeCut' && part.isTubeCut) {
              weight = ((part.selectedMaterial?.density || 0) * part.stepData.partData.tubeData.tubeLength / 1000).toFixed(3) || "/"
            } else {
              weight = ((part.selectedMaterial?.density || 0) * volume).toFixed(3) || "/"
            }
            volume = (volume * 1000).toFixed(3)
          } else {
            volume = "/"
            weight = "/"
          }
        }
        
        if(part.fileType === "dxf") {
          area = part.dxfData?.partData?.area ? (part.dxfData.partData.area / 1000000).toFixed(3) : "/"
          if(part.dxfData?.partData?.area) {
            volume = (part.dxfData.partData.area * part.selectedMaterial?.thickness) / 1000000000
            weight = ((part.selectedMaterial?.density || 0) * volume).toFixed(3) || "/"
            volume = (volume * 1000).toFixed(3)
          } else {
            volume = "/"
            weight = "/"
          }
        }

        if(part.partType === "sheetMetal" && part.operationPrices) {
          bendingPricePerPiece = ((part.operationPrices.bendingPricePerPiece * quantity + part.operationPrices.bendingPreparationPrice) / part.quantity || 0).toFixed(3)
          machiningPricePerPiece = ((part.operationPrices.machiningPricePerPiece * quantity + part.operationPrices.machiningPreparationPrice) / part.quantity || 0).toFixed(3)
          cuttingPricePerPiece = ((part.operationPrices.cuttingPricePerPiece * quantity + part.operationPrices.cuttingPreparationPrice) / part.quantity || 0).toFixed(3)
        } else {
          bendingPricePerPiece = "/"
          machiningPricePerPiece = "/"
          cuttingPricePerPiece = "/"
        }
        if(part.partType === "milled" && part.operationPrices) {
          millingPricePerPiece = ((part.operationPrices?.millingPricePerPiece * quantity + part.operationPrices.millingPreparationPrice) / quantity || 0).toFixed(3)
        } else {
          millingPricePerPiece = "/"
        }

        if(part.partType === "lathed" && part.operationPrices) {
          lathingPricePerPiece = ((part.operationPrices.lathingPricePerPiece * quantity + part.operationPrices.lathingPreparationPrice) / quantity || 0).toFixed(3)
        } else {
          lathingPricePerPiece = "/"
        }
        if(part.partType === 'tubeCut' && part.operationPrices) {
          tubeCuttingPricePerPiece = ((part.operationPrices.tubeCuttingPricePerPiece * quantity + part.operationPrices.tubeCuttingPreparationPrice) / quantity || 0).toFixed(3)
        } else {
          tubeCuttingPricePerPiece = "/"
        }
        
        let maxTolerance = (part.processingType === 'automatic' ? part.selectedTolerance?.maxTolerance : part.manualTolerance) || null
        let maxRoughness = (part.processingType === 'automatic' ? part.selectedRoughness?.maxRoughness : part.manualRoughness) || null

        let size;
        if(part.fileType === "step") {
          if(part.stepData?.partData?.boundingBox) {
            size = `${Math.round((part.stepData.partData.boundingBox.x + Number.EPSILON) * 10) / 10}×${Math.round((part.stepData.partData.boundingBox.y + Number.EPSILON) * 10) / 10}×${Math.round((part.stepData.partData.boundingBox.z + Number.EPSILON) * 10) / 10}`
          }
        }

        if(part.fileType === "dxf") {
          if(part.dxfData?.partData?.minRectangle) {
            size = `${Math.round(((part.dxfData.partData.minRectangle[0] || 0) + Number.EPSILON) * 100) / 100}×${Math.round(((part.dxfData.partData.minRectangle[1] || 0) + Number.EPSILON) * 100) / 100}`
          }
        }
        
        parts.push({
          creationDate: formatDate(part.createdAt),
          creationTime: formatTime(part.createdAt),
          quoteName: stats[j].name,
          customQuoteName: stats[j].customName || "/",
          customerEmail: customerData[stats[j].customerId].customerEmail,
          fileName: (part.assemblyId ? "-    " : "") + name.substring(0, name.lastIndexOf(".")),
          size: size || "/",
          weight: weight,
          thickness: part.selectedMaterial?.thickness || "/",
          area: area,
          volume: volume,
          assemblyNumber: "/",
          assemblyParent: part.assemblyId !== null ? `Assembly ${assemblyOrderMap[part.assemblyId]}` : "/",
          numOfUniqueParts: "/",
          numOfAutomaticParts: "/",
          numOfManualParts: "/",
          quantity: part.quantity,
          pricePerPiece: part.pricePerPiece.toFixed(3),
          bendingPricePerPiece: bendingPricePerPiece,
          machiningPricePerPiece: machiningPricePerPiece,
          cuttingPricePerPiece: cuttingPricePerPiece,
          millingPricePerPiece: millingPricePerPiece,
          lathingPricePerPiece: lathingPricePerPiece,
          tubeCuttingPricePerPiece: tubeCuttingPricePerPiece,
          totalPrice: (part.pricePerPiece * part.quantity).toFixed(3),
          producer: producersForOrder[part.selectedMaterial?.producerId]?.name || 'Unknown',
          needsRfq: part.processingType === "manual" ? "Yes" : "No",
          type: getManualType(part.partType),
          material: part.selectedMaterial?.grade || "/",
          surfaceFinish: getCoatingType(part.coating),
          maxTolerance: maxTolerance ? getToleranceValue(maxTolerance, (part.partType === 'milled' || part.partType === 'lathed') ? "machined" : "other") : "/",
          maxRoughness: maxRoughness ? getRoughnessValue(maxRoughness) : "/",
          certNeeded: part.certNeeded ? "Yes" : "No",
          deletedAt: part.deletedAt ? (formatDate(part.deletedAt) + " " + formatTime(part.deletedAt)) : "/",
          draftDeletedAt: stats[j].deletedAt ? (formatDate(stats[j].deletedAt) + " " + formatTime(stats[j].deletedAt)) : "/"
        })
      }

      data.push(...parts.filter(part => part.assemblyParent === "/"))
      for (let i=0; i<assemblies.length; i+=1) {
        data.push(assemblies[i])
        data.push(...parts.filter(part => part.assemblyParent !== "/" && part.assemblyParent === assemblies[i].assemblyNumber))
      }
      data.push({
        creationDate: "",
        creationTime: "",
        quoteName: "",
        customQuoteName: "",
        customerEmail: "",
        fileName: "",
        size: "",
        weight: "",
        thickness: "",
        area: "",
        volume: "",
        assemblyNumber: "",
        assemblyParent: "",
        numOfUniqueParts: "",
        numOfAutomaticParts: "",
        numOfManualParts: "",
        quantity: "",
        pricePerPiece: "",
        bendingPricePerPiece: "",
        machiningPricePerPiece: "",
        cuttingPricePerPiece: "",
        millingPricePerPiece: "",
        lathingPricePerPiece: "",
        tubeCuttingPricePerPiece: "",
        totalPrice: "",
        producerName: "",
        needsRfq: "",
        type: "",
        material: "",
        surfaceFinish: "",
        maxTolerance: "",
        maxRoughness: "",
        certNeeded: "",
        deletedAt: "",
        draftDeletedAt: ""
      })

      parts = []
      assemblies = []
    }

    let schema = [
      {
        column: "Date:",
        value: part => (part.creationDate),
        align: "left"
      },
      {
        column: "Time:",
        value: part => (part.creationTime),
        align: "left"
      },
      {
        column: "Quote:",
        value: part => (part.quoteName),
        align: "left"
      },
      {
        column: "Custom Name:",
        value: part => (part.customQuoteName),
        align: "left"
      },
      {
        column: "Email:",
        value: part => (part.customerEmail),
        align: "left"
      },
      {
        column: "File Name:",
        value: part => (part.fileName),
        align: "left"
      },
      {
        column: "Size [mm×mm×mm]:",
        value: part => (part.size),
        align: "left"
      },
      {
        column: "Material:",
        value: part => (part.material),
        align: "left"
      },
      {
        column: "Weight [kg]:",
        value: part => (part.weight),
        align: "left"
      },
      {
        column: "Thickness [mm]:",
        value: part => (part.thickness),
        align: "left"
      },
      {
        column: "Area [m²]:",
        value: part => (part.area),
        align: "left"
      },
      {
        column: "Volume [dm³]:",
        value: part => (part.volume),
        align: "left"
      },
      {
        column: "Assembly:",
        value: part => (part.assemblyNumber),
        align: "left"
      },
      {
        column: "Part of Assembly:",
        value: part => (part.assemblyParent),
        align: "left"
      },
      {
        column: "Unique Parts:",
        value: part => (part.numOfUniqueParts),
        align: "left"
      },
      {
        column: "Automatic Parts:",
        value: part => (part.numOfAutomaticParts),
        align: "left"
      },
      {
        column: "Manual Parts:",
        value: part => (part.numOfManualParts),
        align: "left"
      },
      {
        column: "QTY:",
        value: part => (part.quantity),
        align: "left"
      },
      {
        column: "PPC [€]:",
        value: part => (part.pricePerPiece),
        align: "left"
      },
      {
        column: "Bending [€]:",
        value: part => (part.bendingPricePerPiece),
        align: "left"
      },
      {
        column: "Machining [€]:",
        value: part => (part.machiningPricePerPiece),
        align: "left"
      },
      {
        column: "Sheet Cutting [€]:",
        value: part => (part.cuttingPricePerPiece),
        align: "left"
      },
      {
        column: "Milling [€]:",
        value: part => (part.millingPricePerPiece),
        align: "left"
      },
      {
        column: "Lathing [€]:",
        value: part => (part.lathingPricePerPiece),
        align: "left"
      },
      {
        column: "Tube Cutting [€]:",
        value: part => (part.tubeCuttingPricePerPiece),
        align: "left"
      },
      {
        column: "Total [€]:",
        value: part => (part.totalPrice),
        align: "left"
      },
      {
        column: "Producer:",
        value: part => (part.producer),
        align: "left"
      },
      {
        column: "+ RFQ:",
        value: part => (part.needsRfq),
        align: "left"
      },
      {
        column: "Type:",
        value: part => (part.type),
        align: "left"
      },
      {
        column: "MTR/MTC 3.1:",
        value: part => (part.certNeeded),
        align: "left"
      },
      {
        column: "Surface Finish:",
        value: part => (part.surfaceFinish),
        align: "left"
      },
      {
        column: "Max. Tolerance:",
        value: part => (part.maxTolerance),
        align: "left"
      },
      {
        column: "Max. Roughness:",
        value: part => (part.maxTolerance),
        align: "left"
      },
      {
        column: "Part Deleted At:",
        value: part => (part.deletedAt),
        align: "left"
      },
      {
        column: "Draft Deleted At:",
        value: part => (part.draftDeletedAt),
        align: "left"
      },
    ]

    const widths = getWidths(schema, data)
    const schemaWithWidth = schema.map((item, index) => {
      return {
        ...item,
        width: widths[index]
      }
    })

    writeXlsxFile(data, {
      schema: schemaWithWidth,
      fileName: fileName + ".xlsx",
      headerStyle: headerStyle,
      fontSize: 8,
    }).then(()=>{})
    setPreppingData(false)
  }

  const closeStatsModal = () => {
    setVisible(false)
  }

  return (
    <Modal
      title={"Customer Draft Statistics"}
      visible={visible}
      destroyOnClose={true}
      width={500}
      onCancel={closeStatsModal}
      footer={<div>
        <Button onClick={closeStatsModal}>
          Cancel
        </Button>
        <Button
          type={"primary"}
          onClick={getStats}
          loading={preppingData}
        >
          Export Excel
        </Button>
      </div>}
      bodyStyle={{ color: "white" }}
    >
      <React.Fragment>
        <Spin
          spinning={isFetchingCustomers || isFetchingProducersForOrder}
          indicator={
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <LoadingOutlined spin />
            </div>
          }
        >
          <div>
            <div style={{fontSize: 13}}>Get Draft Statistics for:</div>
            <Divider className={"lightDivider"} style={{ margin: "6px 0 4px 0", width: "75%", minWidth: "0%" }}/>
            <div>
              <Radio.Group
                value={selectedRadio}
                className={"customersRadio"}
                onChange={(e) => {
                  if (e.target.value === 1) {
                    setSelectedRadio(1)
                    setAllCustomersSelected(true)
                    setSelectedCustomers([])
                  }
                }}
              >
                <Space direction="vertical">
                  <Radio value={1}>
                    <div style={{color: "white", fontSize: 13, marginTop: 2}}>All Customers</div>
                  </Radio>
                </Space>
              </Radio.Group>
              <div style={{color: "white", marginTop: 10}}>
                <Select
                  value={selectedCustomers}
                  style={{width: 450}}
                  className={"muiInput customerSelect"}
                  placeholder={"Select Specific Customers"}
                  dropdownClassName={"orderItemMaterialSelectDropDown"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(value) => {
                    if(value) {
                      setSelectedRadio(2)
                    }
                    setSelectedCustomers(value)
                  }}
                  onClear={() => {
                    setSelectedRadio(1)
                    setSelectedCustomers([])
                  }}
                  mode={"multiple"}
                  allowClear={true}
                >
                  {customers.map(customer => {
                    return (
                      <Select.Option key={customer.id} value={customer.id}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                          <PersonIcon style={{fontSize: 15, color: "#da291c", marginRight: 6}}/>
                          {customer.name}
                        </div>
                      </Select.Option>
                    )
                  })}
                </Select>
              </div>
            </div>
            <Divider className={"lightDivider"} style={{margin: "8px 0 10px 0", width: "100%", minWidth: "0%"}}/>
            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 10}}>
              <div style={{fontSize: 13}}>Time Period:</div>
              <UserDateRange
                startDate={getSevenDaysAgoDate()}
                endDate={getTodaysDate()}
                onConfirm={setSelectedDateRange}
              />
            </div>
          </div>
        </Spin>
      </React.Fragment>
    </Modal>
  )
}