import React, { useEffect, useState } from "react";
import { Button, Checkbox, Collapse, Spin, Tooltip } from "antd";
import { DeleteFilled, LoadingOutlined } from "@ant-design/icons";
import LazyLoad, { forceCheck } from "react-lazyload";
import "./style.less"
import { PartTypeSelect } from "./PartTypeSelect";
import { PartItemBody } from "./PartItemBody";

const { Panel } = Collapse;

export const PartItemCard = (props) => {
  const {
    orderId, item, selectedItems, onCheck, handleRemoveItem,
    sheetMetalMaterials, machiningMaterials, tubeCuttingMaterials,
    changeSheetMetalMaterial, changePartThickness, changePartQuantity,
    changePartNote, changePartColor, handleColorRemove,
    changePartTolerance, changePartRoughness, changeManualPartTolerance,
    changeManualPartRoughness, handleOpenPricesModal, showItem, isOrderDetails,
    bulkItem, changePrice, adjustment, openDocumentsView, isAssemblyPart,
    assemblyCount, handleChangeItemType, changePartStandard,
    changeMachiningMaterial, changeTubeCuttingMaterial, changePartNumberOfUniqueParts, assemblyName,
    changeMaterialCertificate
  } = props;

  const ptAutomatic = item.processingType === "automatic"

  const [changingType, setChangingType] = useState(false)

  useEffect(() => {
    setChangingType(false)
  }, [item.partType])

  const handleChangeType = (id, item, value) => {
    setChangingType(true)
    handleChangeItemType({id, item, newType: value})
  }

  const handleCollapse = () => {
    forceCheck()
  }

  return (
    <Spin
      spinning={changingType}
      indicator={<LoadingOutlined style={{fontSize: 32, marginTop: -16, marginLeft: -13}} spin/>}
      wrapperClassName={"part-updating-spin"}
    >
      <Collapse
        id={item.id}
        defaultActiveKey={item.id}
        className={ptAutomatic ? "part-card-collapse" : "part-card-collapse manual-part-card-collapse"}
        onChange={handleCollapse}
      >
        <Panel
          className={ptAutomatic ? "part-card-collapse-head" : "part-card-collapse-head manual-part-card-collapse-head"}
          key={item.id}
          header={
            <div
              className={
                isAssemblyPart ?
                  (ptAutomatic ? "assembly-part-card-head" : "assembly-part-card-head assembly-manual-part-card-head")
                  :
                  (ptAutomatic ? "part-card-head" : "part-card-head manual-part-card-head")
              }
            >
              <div className={"part-card-head-left-side"} style={{paddingBottom: 1}}>
                <div
                  onClick={event => {
                    event.stopPropagation();
                    let checked = !selectedItems[item.id]
                    onCheck({item, checked});
                  }}
                >
                  <Checkbox
                    className={ptAutomatic ? "part-card-head-checkbox" : "part-card-head-checkbox manual-part-card-head-checkbox"}
                    style={{marginTop: -2}}
                    onClick={event => {
                      event.stopPropagation();
                      let checked = event.target.checked
                      onCheck({item, checked});
                    }}
                    checked={selectedItems[item.id] || false}
                  />
                </div>
                <div
                  style={{
                    overflow: "hidden",
                    display: "inline-flex",
                    alignItems: "center",
                    marginTop: 1
                  }}
                >
                  <div
                    className={ptAutomatic ? "part-name-white" : "part-name-white part-name-dark"}
                  >
                    {item.fileName}
                  </div>
                  <span
                    className={ptAutomatic ? "part-dimensions-white" : "part-dimensions-white part-dimensions-dark"}
                  >
                    {item.fileType === "step" &&
                      (<div>
                        {item.stepData?.partData?.boundingBox?.x && item.stepData?.partData?.boundingBox?.y && item.stepData?.partData?.boundingBox?.z ?
                          (
                            Math.round((item.stepData?.partData?.boundingBox?.x + Number.EPSILON) * 10) / 10 + "×" +
                            Math.round((item.stepData?.partData?.boundingBox?.y + Number.EPSILON) * 10) / 10 + "×" +
                            Math.round((item.stepData?.partData?.boundingBox?.z + Number.EPSILON) * 10) / 10 + "mm"
                          )
                          :
                          ""
                        }
                      </div>)
                    }
                    {item.fileType === "dxf" &&
                      (<div>
                        {Array.isArray(item?.dxfData?.partData?.minRectangle) && item?.dxfData?.partData?.minRectangle.length && item?.dxfData?.partData?.minRectangle[0] && item?.dxfData?.partData?.minRectangle[1] ?
                          (
                            Math.round(((item?.dxfData?.partData?.minRectangle[0] || 0) + Number.EPSILON) * 100) / 100
                            + "×" +
                            Math.round(((item?.dxfData?.partData?.minRectangle[1] || 0) + Number.EPSILON) * 100) / 100
                            + "mm"
                          )
                          :
                          ""
                        }
                      </div>)
                    }
                  </span>
                </div>
              </div>
              <div
                className={"part-card-head-right-side"}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <div
                  style={{backgroundColor: ptAutomatic ? "rgba(255,255,255,0.07)" : "rgba(0,0,0,0.15)", borderRadius: "5px", padding: "2px 8px"}}
                >
                  <span className={ptAutomatic ? "product-type-white" : "product-type-white product-type-dark"}>
                    Production Type:
                  </span>
                  <PartTypeSelect
                    item={item}
                    handleChangeType={handleChangeType}
                  />
                </div>
                <Tooltip
                  overlayClassName={"delete-tooltip"}
                  color={"rgba(82,82,82,0.9)"}
                  title={<span className={"text12-500"} style={{color: "white"}}>Delete</span>}
                >
                  <Button
                    type="circle"
                    style={{boxShadow: "5px 0 16px 0 rgba(101,101,101,.1)", marginLeft: 11}}
                    onClick={event => {
                      event.stopPropagation();
                      handleRemoveItem(item)
                    }}
                  >
                    <DeleteFilled style={{color: "#ff5b2d"}}/>
                  </Button>
                </Tooltip>
              </div>
            </div>
          }
          showArrow={false}
        >
          <div style={{minHeight: 221}}>
            <LazyLoad
              resize={true}
              offset={600}
              once
            >
              <div
                style={{backgroundColor: "#293135", borderRadius: 2, padding: 10}}
              >
                <PartItemBody
                  orderId={orderId}
                  item={item}
                  assemblyName={assemblyName}
                  sheetMetalMaterials={sheetMetalMaterials}
                  machiningMaterials={machiningMaterials}
                  tubeCuttingMaterials={tubeCuttingMaterials}
                  changeSheetMetalMaterial={changeSheetMetalMaterial}
                  changeMachiningMaterial={changeMachiningMaterial}
                  changeTubeCuttingMaterial={changeTubeCuttingMaterial}
                  changePartThickness={changePartThickness}
                  changePartQuantity={changePartQuantity}
                  changePartNote={changePartNote}
                  changePartColor={changePartColor}
                  changePartTolerance={changePartTolerance}
                  changePartRoughness={changePartRoughness}
                  changeManualPartTolerance={changeManualPartTolerance}
                  changeManualPartRoughness={changeManualPartRoughness}
                  changeMaterialCertificate={changeMaterialCertificate}
                  handleColorRemove={handleColorRemove}
                  handleOpenPricesModal={handleOpenPricesModal}
                  showItem={showItem}
                  isOrderDetails={isOrderDetails}
                  bulkItem={bulkItem}
                  changePrice={changePrice}
                  adjustment={adjustment}
                  openDocumentsView={openDocumentsView}
                  changePartStandard={changePartStandard}
                  changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
                  assemblyCount={assemblyCount}
                  ptAutomatic={ptAutomatic}
                />
              </div>
            </LazyLoad>
          </div>
        </Panel>
      </Collapse>
    </Spin>
  )
}