import * as React from 'react';
import {AutoSizer, Grid} from "react-virtualized";
import {
  Button as AntButton, Row, Col,
  Card, Input, Select, Form,
  Divider, Empty, Tooltip
} from "antd";
import {
  ClickAwayListener, Popper,
  Fade, Button, IconButton
} from "@material-ui/core";
import {CaretDownOutlined, ExclamationCircleOutlined, SearchOutlined} from "@ant-design/icons";
import CloseIcon from '@material-ui/icons/Close';
import { ral } from '../../../assets/ral';
import {DropIcon} from "../../../assets/icons/drop";
import './style.less';
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getColorName} from "../../../utils/utility";

const { Option } = Select;

export const CoatingPicker = (props) => {
  const [selectedRAL, setSelectedRAL] = useState("");
  const [listLength, setListLength] = useState(ral.length);
  const [rowCount, setRowCount] = useState(Math.ceil(ral.length/3))
  const [scrollToRow, setScrollToRow] = useState(undefined);
  const [list, setList] = useState([...ral]);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedGloss, setSelectedGloss] = useState(props.colorGlossId || "1");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCoatingType, setSelectedCoatingType] = useState(props.hasCoating ? props.type : undefined);

  const [selectedHeatTreatmentType, setSelectedHeatTreatmentType] = useState(undefined);
  const [selectedBlastingType, setSelectedBlastingType] = useState(undefined);

  const surfaceCoatingData = useSelector(state => state.surfaceCoating.surfaceCoatingData)
  const isProducer = useSelector(state => state.auth.user.role === "producerAdmin")

  const colorFormRef = React.createRef();

  const heatTreatmentsSet = new Set(["hardening", "caseHardening", "annealing", "heatTreatment"])
  const blastingSet = new Set(["sandBlasting", "glassBeadBlasting", "blasting"])

  const {
    partId,
    partType,
    changeColor,
    hasCoating,
    type,
    colorRAL,
    colorGlossId,
    ralRow,
    ralHex,
    isMultiEdit,
    handleColorRemove,
    disabled
  } = props;

  const _cellRenderer = ({columnIndex, key, rowIndex, style}) => {
    const datum = _getDatum(columnIndex, rowIndex);
    return (
      <div key={key} style={style}>
        {datum &&
        <Button
          variant={selectedRAL === datum.code ? "contained" : "outlined"}
          className={selectedRAL !== datum.code && datum.scope === 'special' ? "colorPickerButton anyColorButton" : "colorPickerButton"}
          startIcon={<DropIcon style={{color: datum.color.hex, fontSize: 16, marginLeft: 5}}/>}
          onClick={()=>{
            changeColor({
              id: partId,
              coatingType: "painting",
              partType: partType,
              colorRAL: datum.code,
              colorGlossId: colorFormRef.current.getFieldValue("gloss"),
              ralRow: Math.floor(datum.id / 3),
              ralHex: datum.color.hex
            })
            handleClose()
          }}
        >
          <span className={datum.code !== 'Any Color' ? "text12-600" : "text10-600"}>{getColorName({color: datum.code})}</span>
        </Button>
        }
      </div>
    );
  }

  const _getDatum = (columnIndex, rowIndex) => {
    const elem_id = (columnIndex+1)+3*(rowIndex)
    return elem_id <= listLength ? list[elem_id-1] : null;
  }

  const _noContentRenderer = () => {
    return <div align={'center'} style={{marginRight: 18}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>;
  }

  const _onScrollToRowChange = (value) => {
    let scrollToRow = Math.min(rowCount - 1, value+4);

    if(value < 6) {
      scrollToRow = value;
    }

    if (isNaN(scrollToRow)) {
      scrollToRow = undefined;
    }
    setScrollToRow(scrollToRow)
  }

  const escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const _onRowCountChange = (event) => {
    const value = event.target.value
    let list;
    if(value === '' ) {
      list = ral
    }
    else {
      list = ral.filter(item => (("RAL ").concat(item.code)).toLowerCase().search(escapeRegExp(value.toLowerCase())) !== -1)
    }

    const rowCount = Math.ceil((list.length)/3);
    const listLength = list.length

    setRowCount(rowCount);
    setListLength(listLength);
    setList(list);
  }

  const handleClose = () => {
    setPickerOpen(false);
    setScrollToRow(undefined);
    setList([...ral]);
    setListLength(ral.length);
    setRowCount(Math.ceil(ral.length/3));
  }

  const handleClick = (event) => {
    if(pickerOpen) {
      handleClose()
    } else {
      setAnchorEl(event.currentTarget);
      setPickerOpen(true);
      setSelectedRAL(colorRAL || "");
      setSelectedGloss(colorGlossId || "1");
      _onScrollToRowChange(ralRow || 0)
    }
  };

  const handleClickAway = () => {
    handleClose()
    if(!hasCoating) {
      if ((selectedCoatingType === "painting" && !colorRAL) || selectedCoatingType === "heatTreatment" || selectedCoatingType === "blasting") {
        setSelectedCoatingType(undefined)
      }
    } else {
      setSelectedCoatingType(type)
    }
  }

  const hasHeatTreatment = (value) => {
    return heatTreatmentsSet.has(value)
  }

  const hasBlasting = (value) => {
    return blastingSet.has(value)
  }

  const getPlaceholderText = () => {
    if (selectedCoatingType !== undefined) {
      switch(selectedCoatingType) {
        case "painting":
          if (!colorRAL) {
            return "Select a RAL Color"
          } else {
            if (selectedGloss === "1") {
              return getColorName({color: colorRAL}) + " Painting"
            } else {
              return getColorName({color: colorRAL}) + " Powder"
            }
          }
        case "anodizing":
          return "Anodizing"
        case "nickelPlating":
          return "Chemical Nickel Plating"
        case "hotGalvanizing":
          return "Hot-Dip Galvanizing"
        case "coldGalvanizing":
          return "Cold Galvanizing"
        case "galvanicZincPlating":
          return "Galvanic Zinc Plating"
        case "heatTreatment":
          return "Select a Thermal Treatment"
        case "hardening":
          return "Hardening"
        case "caseHardening":
          return "Tempering"
        case "annealing":
          return "Annealing"
        case "blasting":
          return "Select a Type of Blasting"
        case "glassBeadBlasting":
          return "Glass Bead Blasting"
        case "sandBlasting":
          return "Ceramic Bead Blasting"
      }
    } else {
      return "Pick a Surface Treatment"
    }
  }

  const deduceTreatmentType = (value) => {
    switch (value) {
      case "hardening":
      case "caseHardening":
      case "annealing":
        return "heatTreatment"
      case "glassBeadBlasting":
      case "sandBlasting":
        return "blasting"
      default:
        return value
    }
  }

  useEffect(() => {
    if(type && hasCoating) {
      setSelectedCoatingType(type)
    }
  }, [type, hasCoating])

  useEffect(() => {
    if(colorGlossId) {
      setSelectedGloss(colorGlossId)
    }
  }, [colorGlossId])

  const showSurfaceCoatingTooltip = () => {
    return surfaceCoatingData.hasSurfaceCoating === false ||
      (
        surfaceCoatingData.painting?.hasPainting === false ||
        surfaceCoatingData.powderPainting?.hasPowderPainting === false ||
        surfaceCoatingData.galvanizing?.hasGalvanizing === false ||
        surfaceCoatingData.anodizing?.hasAnodizing === false ||
        surfaceCoatingData.nickelPlating?.hasNickelPlating === false ||
        surfaceCoatingData.galvanicZincPlating?.hasGalvanicZincPlating === false ||
        surfaceCoatingData.heatTreatment?.hasHeatTreatment === false ||
        (surfaceCoatingData.heatTreatment?.hasHeatTreatment && (
          surfaceCoatingData.heatTreatment?.hardening?.hasHardening ||
          surfaceCoatingData.heatTreatment?.caseHardening?.hasCaseHardening ||
          surfaceCoatingData.heatTreatment?.annealing?.hasAnnealing) === false) ||
        surfaceCoatingData.blasting?.hasBlasting === false ||
        (surfaceCoatingData.blasting?.hasBlasting && (
          surfaceCoatingData.blasting?.sandBlasting?.hasSandBlasting ||
          surfaceCoatingData.blasting?.glassBeadBlasting?.hasGlassBeadBlasting) === false)
      );
  }

  useEffect(() => {
    if (heatTreatmentsSet.has(selectedCoatingType)) {
      setSelectedHeatTreatmentType(selectedCoatingType)
      setSelectedBlastingType(undefined)
    } else if (blastingSet.has(selectedCoatingType)) {
      setSelectedHeatTreatmentType(undefined)
      setSelectedBlastingType(selectedCoatingType)
    } else {
      setSelectedHeatTreatmentType(undefined)
      setSelectedBlastingType(undefined)
    }
  }, [selectedCoatingType])

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
          {isProducer && showSurfaceCoatingTooltip() &&
          <Tooltip
            overlayClassName={"coatingTooltip"}
            title={<div style={{color: "black", fontSize: 12, fontWeight: 500}}>
              {surfaceCoatingData.hasSurfaceCoating === false
                ? "Surface Treatments are not enabled. Cost of Surface Treatments applied to parts will be considered as 0 €."
                : "Some Surface Treatments are not enabled. If one of those treatments gets applied to a part, the cost of the treatment will be considered as 0 €."
              }
            </div>}
            placement={"top"}
            color={"#d6d6d6"}
          >
            <ExclamationCircleOutlined style={{color: "#ffc107", fontSize: 16, marginRight: 8, position: "relative"}}/>
          </Tooltip>
          }
          <AntButton
            className={"colorSelectButton muiInput"}
            style={{height: isMultiEdit ? 31 : 28}}
            onClick={handleClick}
            disabled={disabled}
          >
            {(hasCoating && type === "painting") && <DropIcon style={{color: ralHex, fontSize: 14, position: "relative", marginRight: 5}}/>}
            <span className={"colorSelectButtonText"} style={{color: disabled ? "#bdbdbd" : "#bfbfbf", fontSize: hasCoating ? 11 : 12}}>
              {getPlaceholderText()}
            </span>
            <CaretDownOutlined style={{position: "relative", top: 1}}/>
          </AntButton>
          <Popper
            open={pickerOpen}
            anchorEl={anchorEl}
            placement={isMultiEdit ? 'bottom-start' : 'bottom-end'}
            style={isMultiEdit ? {zIndex: 1101} : {zIndex: 10}}
            popperOptions={{positionFixed: true}}
            transition
            modifiers={{
              preventOverflow: {
                enabled: false,
                boundariesElement: 'scrollParent',
              },
              hide: {
                enabled: false
              },
              offset: {
                enabled: true,
                offset: '0,5',
              }
            }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div style={{width: selectedCoatingType === "painting" ? 400 : 300}}>
                  <Card className={"colorCard"} bodyStyle={{padding: "8px 12px 12px 12px"}}>
                    <div>
                      <div style={{width: '100%'}}>
                        <div style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
                          <div>
                            <span className={"text14-600"}>Surface Treatment Options</span>
                          </div>
                          <div>
                            <IconButton
                              size={'small'}
                              aria-label="close"
                              onClick={()=>{
                                handleClickAway()
                              }}
                            >
                              <CloseIcon style={{ color: "whitesmoke"}}/>
                            </IconButton>
                          </div>
                        </div>
                        <Form
                          name={'colorForm'}
                          ref={colorFormRef}
                        >
                          <Row gutter={15}>
                            <Col span={24} style={{ marginTop: 8 }}>
                              <Form.Item
                                style={{ marginBottom: 6 }}
                                name={"surfaceTreatmentType"}
                                initialValue={deduceTreatmentType(selectedCoatingType)}
                              >
                                <Select
                                  className={"muiInput"}
                                  dropdownClassName={"orderItemMaterialSelectDropDown"}
                                  placeholder={"Select a Surface Treatment Option"}
                                  getPopupContainer={trigger => trigger.parentNode}
                                  suffixIcon={<CaretDownOutlined />}
                                  onChange={(value) => {
                                    if (value === "painting" || value === "heatTreatment" || value === "blasting") {
                                      setSelectedCoatingType(value)
                                    } else {
                                      changeColor({
                                        id: partId,
                                        coatingType: value,
                                        partType: partType
                                      })
                                      setSelectedCoatingType(value)
                                      handleClose()
                                    }
                                  }}
                                >
                                  <Option
                                    value={"painting"}
                                    key={"1"}>
                                    <span>Varnish</span>
                                  </Option>
                                  <Option
                                    value={"anodizing"}
                                    key={"2"}>
                                    <span>Anodizing</span>
                                  </Option>
                                  <Option
                                    value={"nickelPlating"}
                                    key={"3"}>
                                    <span>Chemical Nickel Plating</span>
                                  </Option>
                                  <Option
                                    value={"hotGalvanizing"}
                                    key={"4"}>
                                    <span>Hot-Dip Galvanizing</span>
                                  </Option>
                                  <Option
                                    value={"galvanicZincPlating"}
                                    key={"6"}>
                                    <span>Galvanic Zinc Plating</span>
                                  </Option>
                                  <Option
                                    value={"heatTreatment"}
                                    key={"7"}>
                                    <span>Thermal Treatment</span>
                                  </Option>
                                  <Option
                                    value={"blasting"}
                                    key={"8"}>
                                    <span>Blasting</span>
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            {selectedCoatingType === "painting" &&
                            <Row gutter={15} style={{ marginLeft: 0, marginRight: 2 }}>
                              <Col span={13}>
                                <span className={"text12-400"}>Varnish Type</span>
                                <Form.Item
                                  name={"gloss"}
                                  initialValue={selectedGloss}
                                >
                                  <Select
                                    className={"muiInput"}
                                    dropdownClassName={"orderItemMaterialSelectDropDown"}
                                    suffixIcon={<CaretDownOutlined />}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    style={{ width: 195 }}
                                    onChange={(value) => {
                                      setSelectedGloss(value)
                                      if (hasCoating && colorGlossId && colorRAL && ralHex) {
                                        changeColor({
                                          id: partId,
                                          coatingType: "painting",
                                          partType: partType,
                                          colorRAL: colorRAL,
                                          colorGlossId: value,
                                          ralRow: ralRow,
                                          ralHex: ralHex
                                        })
                                        handleClose()
                                      }
                                    }}
                                  >
                                    <Option
                                      key={"1"}
                                      value={"1"}>
                                      {/*<span className={"text12-600"}>Glossy (70Â±5) (default)</span>*/}
                                      <span className={"text12-600"}>Painting</span>
                                    </Option>
                                    <Option
                                      key={"2"}
                                      value={"2"}>
                                      {/*<span className={"text12-600"}>Matte (30Â±5)</span>*/}
                                      <span className={"text12-600"}>Powder Painting</span>
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={11}>
                                <div style={{marginTop: 22}}>
                                  <Input
                                    prefix={<SearchOutlined />}
                                    className={"searchInput muiInput"}
                                    placeholder={"Find RAL.."}
                                    onChange={_onRowCountChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                            }
                            {hasHeatTreatment(selectedCoatingType) &&
                            <Row gutter={15} style={{ marginLeft: 0, marginRight: 2 }}>
                              <Col span={24}>
                                <span className={"text12-400"}>Thermal Treatment Type</span>
                                <Form.Item
                                  name={"heatTreatmentType"}
                                  initialValue={selectedHeatTreatmentType}
                                >
                                  <Select
                                    className={"muiInput"}
                                    placeholder={"Select a Thermal Treatment Type"}
                                    dropdownClassName={"orderItemMaterialSelectDropDown"}
                                    suffixIcon={<CaretDownOutlined />}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    style={{ width: 274 }}
                                    onChange={(value) => {
                                      changeColor({
                                        id: partId,
                                        coatingType: value,
                                        partType: partType
                                      })
                                      handleClose()
                                    }}
                                  >
                                    <Option
                                      value={"hardening"}
                                      key={"1"}
                                    >
                                      <span className={"text12-600"}>Hardening</span>
                                    </Option>
                                    <Option
                                      value={"caseHardening"}
                                      key={"2"}
                                    >
                                      <span className={"text12-600"}>Tempering</span>
                                    </Option>
                                    <Option
                                      value={"annealing"}
                                      key={"3"}
                                    >
                                      <span className={"text12-600"}>Annealing</span>
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            }
                            {hasBlasting(selectedCoatingType) &&
                            <Row gutter={15} style={{ marginLeft: 0, marginRight: 2 }}>
                              <Col span={24}>
                                <span className={"text12-400"}>Type of Blasting Treatment</span>
                                <Form.Item
                                  name={"blastingType"}
                                  initialValue={selectedBlastingType}
                                >
                                  <Select
                                    className={"muiInput"}
                                    placeholder={"Select a Type of Blasting Treatment"}
                                    dropdownClassName={"orderItemMaterialSelectDropDown"}
                                    suffixIcon={<CaretDownOutlined />}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    style={{ width: 274 }}
                                    onChange={(value) => {
                                      setSelectedBlastingType(value)
                                      setSelectedHeatTreatmentType(undefined)
                                      changeColor({
                                        id: partId,
                                        coatingType: value,
                                        partType: partType
                                      })
                                      handleClose()
                                    }}
                                  >
                                    <Option
                                      value={"glassBeadBlasting"}
                                      key={"1"}
                                    >
                                      <span className={"text12-600"}>Glass Bead Blasting</span>
                                    </Option>
                                    <Option
                                      value={"sandBlasting"}
                                      key={"2"}
                                    >
                                      <span className={"text12-600"}>Ceramic Bead Blasting</span>
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            }
                          </Row>
                        </Form>
                      </div>
                    </div>
                    {selectedCoatingType === "painting" &&
                    <div>
                      <Divider className={'colorDivider'}/>
                      <AutoSizer disableHeight>
                        {({width}) => (
                          <Grid
                            cellRenderer={_cellRenderer}
                            columnWidth={(width-15)/3}
                            columnCount={3}
                            height={220}
                            noContentRenderer={_noContentRenderer}
                            overscanRowCount={3}
                            rowHeight={40}
                            rowCount={rowCount}
                            scrollToRow={scrollToRow}
                            width={width}
                          />
                        )}
                      </AutoSizer>
                    </div>
                    }
                  </Card>
                </div>
              </Fade>
            )}
          </Popper>
        </div>
      </ClickAwayListener>
      {hasCoating &&
      <IconButton
        size={'small'}
        aria-label="clear"
        style={{marginLeft: 5, width: 25, height: 25, marginRight: -8, marginTop: 2}}
        onClick={()=>{
          if (selectedCoatingType !== undefined) {
            setSelectedCoatingType(undefined)
          }
          handleColorRemove({id: partId, partType: partType})
        }}
      >
        <CloseIcon style={{ fontSize: 14, color: '#fc505a'}}/>
      </IconButton>
      }
    </div>
  );
}