//local
// const API_BASE_URL = `http://localhost:5000/api`;
// const SOCKET_URL = `http://localhost:5000`;

const API_BASE_URL = `https://${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REST_API_BASE_PATH}`;
const SOCKET_URL = `https://${process.env.REACT_APP_BASE_URL}`;
const SOCKET_PATH = process.env.REACT_APP_SOCKET_API_BASE_PATH;
const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_SITE_KEY
const lineBreakLimit = 1426;

export { API_BASE_URL, lineBreakLimit, SOCKET_URL, SOCKET_PATH, S3_BUCKET_URL, RECAPTCHA_SITE_KEY }


