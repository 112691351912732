import React from "react";
import {Button, Tooltip} from "antd";
import {calcAdjustment} from "../../../utils/utility";
import {FilePdfOutlined, SearchOutlined} from "@ant-design/icons";
import { imageSection, materialInfoSection } from "../../../utils/partCardUtils/sections";

export const OrderItemSummaryCard = (props) => {
  const {
    part, showOtherItem, showDetailsItem,
    order, handleOpenDocsModal, getAssemblyWeight
  } = props
  return (
    <div style={{display: "flex", width: "100%"}}>
      {imageSection(part)}
      <div style={{width: "100%"}}>
        <div>
          <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <div className={"partInfo"}>
              {part.fileName}
            </div>
            <div className={"summaryCardButtons"}>
              {part.documents?.length > 0 &&
                <Tooltip
                  title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>Download Documents</span>}
                  placement={"topRight"}
                  color={"#d6d6d6"}
                >
                  <Button
                    size={"small"}
                    type={"primary"}
                    className={"exportPdfButton"}
                    onClick={() => {
                      handleOpenDocsModal(part.documents)
                    }}
                  >
                    <div>
                      <FilePdfOutlined style={{fontSize: 12}}/>
                    </div>
                  </Button>
                </Tooltip>
              }
              <Tooltip
                title={<span style={{color: "black", fontSize: 12, fontWeight: 500}}>View Part</span>}
                placement={"topRight"}
                color={"#d6d6d6"}
              >
                <Button
                  size={"small"}
                  type={"primary"}
                  style={{marginLeft: 5}}
                  onClick={()=>{
                    if(part.fileType === "other") {
                      showOtherItem(part)
                    } else {
                      if(part.partType === "assembly" && part.processingType !== "manual") {
                        let assemblyWeight = getAssemblyWeight(part.id)
                        showDetailsItem({
                          ...part,
                          assemblyWeight: assemblyWeight
                        })
                      } else {
                        showDetailsItem(part)
                      }
                    }
                  }}
                >
                  <SearchOutlined style={{fontSize: 13}}/>
                </Button>
              </Tooltip>
            </div>
          </div>
          <div>
            {materialInfoSection(part)}
            <div style={{display:"flex", justifyContent: "space-between"}}>
              <div style={{fontSize: 12, fontWeight: 500, marginRight: 8}}>
                {part.partType === "assembly" ? "Number of Assemblies" : "Quantity"}: <span style={{fontWeight: 600}}>{part.quantity}</span>
              </div>
              {part.processingType === "automatic" &&
              <div style={{fontSize: 12, fontWeight: 500}}>
                1pc: <span style={{fontWeight: 600}}>{((part.pricePerPiece || 0) * calcAdjustment(order.adjustment)).toFixed(2)} €</span>
              </div>
              }
              {part.processingType === "manual" &&
              <div style={{fontSize: 12, fontWeight: 500}}>
                1pc: <span style={{fontWeight: 600}}>RFQ</span>
              </div>
              }
              {part.partType === "assembly" &&
              <div style={{fontSize: 12, fontWeight: 500}}>
                1pc: <span style={{fontWeight: 600}}>
                  {part.pricePerPiece === 0 ?
                    <span>RFQ</span>
                    :
                    <span>{`${((part.pricePerPiece || 0) * calcAdjustment(order.adjustment)).toFixed(2)} € + RFQ`}</span>
                  }
                </span>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
  </div>
  )
}