import React from "react";
import {Button, Col, Divider, Modal, Row} from "antd";
import {S3_BUCKET_URL} from "../../../constants";
import {ProducerPricesAccordion} from "../ProducerPricesAccordion";
import "./style.less";
import { calcAdjustment } from "../../../utils/utility";

export const ProducersPricesModal = (props) => {

  const {
    modalItem, visible, onClose,
    compMaxWidth, applyAdjustment
  } = props

  const getPriceList = (partType) => {
    let priceList = []
    switch (partType) {
      case "sheetMetal":
        priceList = [...modalItem.sheetMetalPriceByProducer]
        break
      case "milled":
        priceList = [...modalItem.millingPriceByProducer]
        break
      case "lathed":
        priceList = [...modalItem.lathingPriceByProducer]
        break
      case "tubeCut":
        priceList = [...modalItem.tubeCuttingPriceByProducer]
        break
      default:
        priceList = []
    }

    if(!(priceList.length === 1 && priceList[0].isGelso)) {
      switch (partType) {
        case "sheetMetal":
          if (modalItem.gelsoPrices?.sheetMetal?.producerId) {
            priceList.push(modalItem.gelsoPrices?.sheetMetal)
          }
          break
        case "milled":
          if (modalItem.gelsoPrices?.milling?.producerId) {
            priceList.push(modalItem.gelsoPrices?.milling)
          }
          break
        case "lathed":
          if (modalItem.gelsoPrices?.lathing?.producerId) {
            priceList.push(modalItem.gelsoPrices?.lathing)
          }
          break
        case "tubeCut":
          if (modalItem.gelsoPrices?.tubeCutting?.producerId) {
            priceList.push(modalItem.gelsoPrices?.tubeCutting)
          }
          break
      }
    }

    return priceList
  }

  return (
    <Modal
      visible={visible}
      title={"Prices for All Producers"}
      destroyOnClose={true}
      footer={
        <div align={"right"} style={{ width: "100%" }}>
          <Button onClick={() => {
            onClose()
          }}>
            Close
          </Button>
        </div>
      }
      onCancel={()=>{
        onClose()
      }}
      zIndex={1900}
      width={700}
    >
      {Object.keys(modalItem).length !== 0 &&
        <div>
          <Row>
            <Col flex={"136px"}>
              <div className={"priceModalPic"}>
                {modalItem.fileType === "step" &&
                <img
                  src={S3_BUCKET_URL + modalItem.stepData?.filePaths?.pngFilePath}
                  alt={""}
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: 126,
                    maxHeight: 126,
                    padding: 10,
                    position: "relative",
                    background: "#fff",
                    zIndex: 3
                  }}
                />
                }
                {modalItem.fileType === "dxf" &&
                <img
                  src={S3_BUCKET_URL + modalItem.dxfData?.pngFilePath}
                  alt={""}
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: 126,
                    maxHeight: 126,
                    padding: 10,
                    position: "relative",
                    background: "#fff",
                    zIndex: 3
                  }}
                />
                }
              </div>
            </Col>
            <Col flex={"auto"} style={{borderLeft: "1px solid #838f94"}}>
              <div
                className={"text14-500"}
                style={{padding: 8, maxWidth: compMaxWidth}}
              >
                File: <span className={"text14-600"}>{modalItem.fileName}</span>
              </div>
              <Divider style={{margin: 2, borderColor: "#838f94"}}/>
              <div
                className={"text14-500"}
                style={{padding: 8, maxWidth: 700}}
              >
                Quantity: <span className={"text14-600"}>{modalItem.quantity}</span>
              </div>
              <Divider style={{margin: 2, borderColor: "#838f94"}}/>
              <div className={"text14-500"}
                   style={{padding: 8, maxWidth: 700, display: "flex", justifyContent: "space-between", width: "100%"}}
              >
                <div>
                  Price per piece: <span className={"text14-600"}>{((modalItem.pricePerPiece || 0) * (applyAdjustment ? calcAdjustment(modalItem.adjustment) : 1)).toFixed(2)} €</span>
                </div>
                <div>
                  Total price: <span className={"text14-600"}>{((modalItem.pricePerPiece || 0) * modalItem.quantity * (applyAdjustment ? calcAdjustment(modalItem.adjustment) : 1)).toFixed(2)} €</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider style={{margin: "12px 0 0 0", borderColor: "#838f94"}}/>
              <div className={"producersAccoContainer"}>
                <ProducerPricesAccordion
                  itemPrices={getPriceList(modalItem.partType)}
                  quantity={modalItem.quantity}
                  certNeeded={modalItem.certNeeded}
                  assemblyCount={modalItem.assemblyCount || 1}
                  adjustment={modalItem.adjustment}
                  partType={modalItem.partType}
                />
              </div>
            </Col>
          </Row>
        </div>
      }
    </Modal>
  )
}