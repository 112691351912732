import React from "react";
import "./style.less";
import {ProcessingItemCard, UploadingItemCard} from "../OrderItemCards";
import {Divider, List} from "antd";
import {AssemblyPartProcessedCard} from "../AssemblyPartProcessedCard";
import {EmptyTable} from "../../EmptyTable";


export const AssemblyPartsList = (props) => {

  const {
    orderId, assemblyItems, selectedItems, handleRemoveItem, sheetMetalMaterials,
    machiningMaterials, tubeCuttingMaterials, changeSheetMetalMaterial, changePartThickness,
    changePartTolerance, changePartRoughness, changeManualPartTolerance, changeManualPartRoughness,
    changeMachiningMaterial, changeTubeCuttingMaterial, changePartQuantity, changePartNote,
    changePartColor, changePartStandard, handleColorRemove, changePartNumberOfUniqueParts,
    showItem, handleChangeItemType, bulkItem, adjustment,
    assemblyId, onCheck, openDocumentsView, handleOpenPricesModal,
    changePrice, assemblyCount, assemblyName, changeMaterialCertificate
  } = props


  return (
    <List
      dataSource={assemblyItems}
      key={"id"}
      rowKey={(item) => item.fileId || item.id}
      locale={{ emptyText: <EmptyTable text={"No Data"} /> }}
      renderItem={item => {
        if (item.assemblyId === assemblyId && (item.partType !== "assembly" || item.processingType === "manual")) {
          switch (item.state) {
            case "processed":
              return (
                <div>
                  <AssemblyPartProcessedCard
                    orderId={orderId}
                    assemblyId={assemblyId}
                    item={item}
                    assemblyName={assemblyName}
                    sheetMetalMaterials={sheetMetalMaterials}
                    machiningMaterials={machiningMaterials}
                    tubeCuttingMaterials={tubeCuttingMaterials}
                    changeSheetMetalMaterial={changeSheetMetalMaterial}
                    changeMachiningMaterial={changeMachiningMaterial}
                    changeTubeCuttingMaterial={changeTubeCuttingMaterial}
                    changePartThickness={changePartThickness}
                    changePartQuantity={changePartQuantity}
                    changePartNote={changePartNote}
                    changePartColor={changePartColor}
                    changePartTolerance={changePartTolerance}
                    changePartRoughness={changePartRoughness}
                    changeManualPartTolerance={changeManualPartTolerance}
                    changeManualPartRoughness={changeManualPartRoughness}
                    changeMaterialCertificate={changeMaterialCertificate}
                    handleColorRemove={handleColorRemove}
                    handleRemoveItem={handleRemoveItem}
                    changePartStandard={changePartStandard}
                    changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
                    showItem={showItem}
                    selectedItems={selectedItems}
                    onCheck={onCheck}
                    handleChangeItemType={handleChangeItemType}
                    bulkItem={bulkItem}
                    isOrderDetails={false}
                    adjustment={adjustment}
                    openDocumentsView={openDocumentsView}
                    handleOpenPricesModal={handleOpenPricesModal}
                    changePrice={changePrice}
                    assemblyCount={assemblyCount}
                  />
                  <Divider className={"lightDivider"} style={{ margin: "-5px 0" }}/>
                </div>
              )
            case "uploaded":
            case "processing":
              return (
                <ProcessingItemCard item={item} />
              )
            case "uploading":
              return (
                <UploadingItemCard item={item} />
              )
            default:
              return (<div key={item.id || item.fileId}/>)
          }
        } else {
          return null
        }
      }}
    />
  )
}