import React, { useEffect, useState } from "react";
import {Button, Col, Divider, Form, Input, InputNumber, Row, Select, Tooltip} from "antd";
import {CaretDownOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {Trans} from "react-i18next";
import {CoatingPicker} from "../../../CoatingPicker";

const { Option } = Select;
const { OptGroup } = Select;

export const SheetMetal = (props) => {
  const {item, materials, addNewPart, isAddingNewPart, assemblyData} = props;

  const findMaterial = (name) => {
    let rez;
    for(let material of materials) {
      rez = material.grades.find(type => type.grade === name)
      if(rez) {
        rez.groupName = material.groupName
        break;
      }
    }
    return rez
  }

  const getThickness = () => {
    if(item) {
      let rez = findMaterial(item?.selectedMaterial?.grade)
      return rez?.thickness || []
    } else {
      return []
    }
  }

  const [thicknesses, setThicknesses] = useState(item ? getThickness() : [])
  const [coating, setCoating] = useState({
    hasCoating: false,
    type: "painting",
    data: {
      colorRAL: "",
      colorGlossId: "",
      ralRow: 0,
      ralHex: ""
    }
  });

  useEffect(() => {
    if(item && item.coating) {
      setCoating(item.coating);
    }
  }, [item])

  const changePartColor = (data) => {
    let coating;
    if (data.coatingType === "painting") {
      coating = {
        hasCoating: true,
        type: data.coatingType,
        data: {
          colorRAL: data.colorRAL,
          colorGlossId: data.colorGlossId.toString(),
          ralRow: data.ralRow,
          ralHex: data.ralHex
        }
      }
    } else {
      coating = {
        hasCoating: true,
        type: data.coatingType
      }
    }
    setCoating({
      ...coating
    })
  }

  const clearPartColor = () => {
    setCoating({
      hasCoating: false,
      type: "painting",
      data: {
        colorRAL: "",
        colorGlossId: "",
        ralRow: 0,
        ralHex: ""
      }
    })
  }

  const changeThicknesses = (grade) => {
    let rez = findMaterial(grade)
    setThicknesses(rez?.thickness || [])
  }

  return (
    <div>
      <Form
        className={"manualQuoteForm"}
        name={"manualQuoteOther"}
        onFinish={(values)=>{
          let tmpCoating = coating.hasCoating ? { ...coating } : { hasCoating: false, type: "painting", data: { colorRAL: "", colorGlossId: "", ralRow: 0, ralHex: "" } };
          addNewPart({
            partType: "sheetMetal",
            partId: item?.id,
            data: {
              ...values,
              coating: tmpCoating,
              uploadTime: Date.now(),
              uploadIndex: 1,
            }
          })
        }}
      >
        <Row>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              <div style={{display: "flex"}}>
                <div style={{fontWeight: 500, fontSize: 13}}>Name:</div>
                {item?.assemblyId &&
                <Tooltip
                  placement={"top"}
                  color={"#656565"}
                  title={
                    <div style={{ color: "white", fontSize: 12, fontWeight: 500 }}>
                      <div>This part belongs to the assembly:</div>
                      <div>{assemblyData[item?.assemblyId]?.name}</div>
                    </div>
                  }
                >
                  <QuestionCircleOutlined style={{ color: "#ffffff", marginLeft: 5, marginTop: 3 }} />
                </Tooltip>
                }
              </div>
              <Form.Item
                name={"name"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item?.fileName || null}
              >
                <Input
                  className={"quoteModalName"}
                  style={{height: 32, width: "100%"}}
                  placeholder={'eg. Sheet Part'}
                  disabled={!!item}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              {item?.assemblyId ?
                <div style={{display: "flex"}}>
                  <div style={{fontWeight: 500, fontSize: 13}}>Quantity per Assembly:</div>
                  <Tooltip
                    placement={"top"}
                    color={"#656565"}
                    title={
                      <div style={{ color: "white", fontSize: 12, fontWeight: 500 }}>
                        <div>This part belongs to an assembly.</div>
                        <div>Number of assemblies: {assemblyData[item?.assemblyId]?.quantity}</div>
                        <div>Quantity per assembly: {item?.quantity}</div>
                      </div>
                    }
                  >
                    <QuestionCircleOutlined style={{ color: "#ffffff", marginLeft: 5, marginTop: 3 }} />
                  </Tooltip>
                </div>
                :
                <div style={{fontWeight: 500, fontSize: 13}}>Quantity:</div>
              }
              <Form.Item
                name={"quantity"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item ? item?.quantity : 1}
              >
                <InputNumber
                  min={1}
                  max={1000000}
                  style={{width: "100%"}}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <div style={{fontWeight: 500, fontSize: 13}}>Price 1pc (€):</div>
              <Form.Item
                name={"pricePerPiece"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={0}
              >
                <InputNumber
                  min={0}
                  max={1000000}
                  style={{width: "100%"}}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              <div style={{fontWeight: 500, fontSize: 13}}>Material:</div>
              <Form.Item
                name={"materialGrade"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item?.selectedMaterial?.grade || null}
              >
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  onChange={(e)=> {changeThicknesses(e)}}
                  getPopupContainer={trigger => trigger.parentNode}
                  virtual={false}
                  style={{width: "100%"}}
                  placeholder={"Select Material"}
                  dropdownClassName={"orderItemMaterialSelectDropDown"}
                >
                  {materials.map(material => {
                    return (
                      <OptGroup
                        key={material.groupName}
                        label={material.groupName}>
                        {material.grades.map(t => {
                          return (
                            <Option
                              key={t.grade}
                              value={t.grade}
                            >
                          <span className={"text12-600"}>
                            <Trans>{t.grade}</Trans>
                          </span>
                            </Option>
                          )
                        })}
                      </OptGroup>
                    )
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              <div style={{fontWeight: 500, fontSize: 13}}>Thickness:</div>
              <Form.Item
                name={"thickness"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item?.selectedMaterial?.thickness || null}
              >
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  getPopupContainer={trigger => trigger.parentNode}
                  virtual={false}
                  style={{width: "100%"}}
                  disabled={thicknesses.length === 0}
                  placeholder={"Select Thickness"}
                  dropdownClassName={"orderItemMaterialSelectDropDown"}
                >
                  {thicknesses.map(thick => {
                    return (
                      <Option
                        key={thick.id}
                        value={thick.thickness}
                      >
                      <span className={"text12-600"}>
                        {thick.thickness} mm
                      </span>
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <div style={{fontWeight: 500, fontSize: 13}}>Surface finish:</div>
              <div style={{display: 'flex'}}>
                <CoatingPicker
                  partId={null}
                  partType={"sheetMetal"}
                  type={coating.type}
                  changeColor={changePartColor}
                  hasCoating={coating.hasCoating}
                  colorRAL={coating.data?.colorRAL}
                  colorGlossId={coating.data?.colorGlossId}
                  ralRow={coating.data?.ralRow}
                  ralHex={coating.data?.ralHex}
                  handleColorRemove={clearPartColor}
                  isMultiEdit={true}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Divider className={"lightDivider"} style={{ margin: "-6px 0 12px 0" }}/>
        <div align={"right"}>
          <Button
            type={"default"}
            onClick={()=>{props.setManualQuoteStep(1)}}
            style={{fontWeight: 500, fontSize: 13}}
            disabled={isAddingNewPart}
          >
            Back
          </Button>
          <Button
            type={"primary"}
            htmlType={"submit"}
            style={{marginLeft: 5, fontWeight: 500, fontSize: 13}}
            loading={isAddingNewPart}
          >
            {!!item ? "Quote Part" : "Add Part"}
          </Button>
        </div>
      </Form>
    </div>
  )
}