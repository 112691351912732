import React from "react";
import Sticky from "react-sticky-el";
import {Button, Card, Divider} from "antd";
import {OrderProgress} from "../../../OrderProgress";
import {calcAdjustment} from "../../../../../utils/utility";
import {ManualQuote} from "../../../../../assets/icons/manualQuote";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {FileDoneOutlined} from "@ant-design/icons";

export const DraftOrderSticky = (props) => {

  const {
    stage, returnToStage, processingPrice,
    selectableItemsCount, adjustment,
    needsManualQuote, numberOfManualParts,
    working, isChangingDraftStage,
    actionMap, proceedToShipping,
    isChangingDeliveryDate, handlePlaceOrder,
    isChangingDraftName, totalPrice
  } = props;

  const formatETATime = (hours) => {
    if (hours < 96) {
      return hours + "h"
    } else {
      let days = Math.floor(hours/24);
      return days + " days"
    }
  }

  return (
    <div style={{marginLeft: 20, marginTop: -15}}>
      <Sticky
        scrollElement="window"
      >
        <div style={{paddingTop: 20}}>
          <Card
            style={{borderRadius: 5, border: '1px solid #c0c0c0'}}
            bodyStyle={{padding: "15px 5px 5px 5px"}}
          >
            <OrderProgress stage={stage} goToStage={returnToStage}/>
            <div style={{marginLeft: 15, width: "calc(100% - 30px)"}}>
              <Divider className={"orderDivider"} style={{marginBottom: 10, marginTop: 12}}/>
              {selectableItemsCount > 0 &&
              <div>
                <div className={"text14-600"} style={{fontSize: 19, lineHeight: 1.4}}>
                  Quote summary
                </div>
                <div
                  style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 20}}
                >
                  <div>
                    <span className={"text14-400"}>Calculation</span>
                  </div>
                  <div className={"text12-600"} style={{lineHeight: 1.8}}>
                    {((processingPrice || 0) * calcAdjustment(adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
                  </div>
                </div>
                <div
                  style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}
                >
                  <div>
                    <span className={"text14-400"}>Shipping</span>
                  </div>
                  {stage === "processing" &&
                  <div className={"text12-600"} style={{lineHeight: 1.8}}>
                    Calculated in next step
                  </div>
                  }
                  {(stage === "shipping" || stage === "confirmation") &&
                  <div className={"text12-600"} style={{lineHeight: 1.8}}>
                    Manually by RFQ
                  </div>
                  }
                </div>
                {numberOfManualParts > 0 &&
                <div
                  style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}
                >
                  <div>
                    <span className={"text14-400"}>Estimated quote time</span>
                  </div>
                  <div className={"text12-600"} style={{lineHeight: 1.8}}>
                    {formatETATime(48 + 3*(numberOfManualParts - 1))}
                  </div>
                </div>
                }
                <Divider className={"orderDivider"} style={{marginBottom: 10, marginTop: 10}}/>
                <div
                  style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}>
                  <div>
                    <span className={"text14-400"}>Total</span>
                  </div>
                  <div className={"text12-600"} style={{lineHeight: 1.8}}>
                    {((totalPrice  || 0) * calcAdjustment(adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
                  </div>
                </div>
                <div align={'center'} style={{marginTop: 20, marginBottom: 10, width: '100%'}}>
                  {stage === "processing" &&
                  <div>
                    {needsManualQuote ?
                      <div style={{backgroundColor: "#252C30", borderRadius: 5}}>
                        <div align={'left'} style={{display: "flex", padding: "15px 15px 10px 15px"}}>
                          <span>
                            <ManualQuote/>
                          </span>
                          <span style={{
                            display: "flex",
                            fontSize: 14,
                            fontWeight: 600,
                            marginLeft: 10,
                            alignItems: "center",
                            color: "white"
                          }}
                          >
                            Some positions need a manual calculation by our technicians.
                          </span>
                        </div>
                        <div style={{
                          boxShadow: "inset 0 1px 10px 0 rgb(155, 185, 216, 0.3)",
                          padding: "10px 15px 15px 15px",
                          borderRadius: "0 0 5px 5px"
                        }}
                        >
                          <div style={{paddingBottom: 10, fontSize: 12, fontWeight: 600, color: "white"}}>
                            Proceed, and you will receive an official offer.
                          </div>
                          <div>
                            <Button
                              type={"primary"}
                              size={'large'}
                              style={{width: '100%'}}
                              disabled={selectableItemsCount < 1 || working || isChangingDraftStage || actionMap.size > 0}
                              onClick={() => proceedToShipping()}
                            >
                              <span className={"nextStepButtonText"}>Request for Quotation</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                      :
                      <Button
                        type={"primary"}
                        size={'large'}
                        style={{width: '100%'}}
                        disabled={selectableItemsCount < 1 || working || isChangingDraftStage || actionMap.size > 0}
                        onClick={() => proceedToShipping()}
                      >
                        <span className={"nextStepButtonText"}>Request for Quotation</span>
                      </Button>
                    }
                  </div>
                  }
                  {stage === "shipping" &&
                  <div>
                    <Button
                      type={"default"}
                      size={'large'}
                      style={{width: '100%', marginBottom: 10, color: "#ff5b2d"}}
                      disabled={isChangingDeliveryDate || isChangingDraftStage}
                      onClick={()=>returnToStage("processing")}
                    >
                      <span style={{marginLeft: -25}}>
                        <ChevronLeftRoundedIcon style={{marginTop: -10, marginBottom: -6, fontSize: 22}}/> <span className={"nextStepButtonText"}>Return to Calculation</span>
                      </span>
                    </Button>
                    <Button
                      type={"primary"}
                      size={'large'}
                      style={{width: '100%'}}
                      disabled={isChangingDeliveryDate || isChangingDraftStage}
                      form={"shippingForm"}
                      key={"submit"}
                      htmlType={"submit"}
                    >
                      <span style={{marginRight: -25}}>
                        <span className={"nextStepButtonText"}>Proceed to Quote </span> <ChevronRightRoundedIcon style={{marginTop: -10, marginBottom: -7, fontSize: 22}}/>
                      </span>
                    </Button>
                  </div>
                  }
                  {stage === "confirmation" &&
                  <div>
                    <Button
                      type={"default"}
                      size={'large'}
                      style={{width: '100%', marginBottom: 10, color: "#ff5b2d"}}
                      disabled={isChangingDraftStage}
                      onClick={()=>returnToStage("shipping")}
                    >
                      <span style={{marginLeft: -25}}>
                        <ChevronLeftRoundedIcon style={{marginTop: -10, marginBottom: -6, fontSize: 22}}/> <span className={"nextStepButtonText"}>Return to Delivery</span>
                      </span>
                    </Button>
                    <Button
                      type={"primary"}
                      size={'large'}
                      className={"placeOrderButton"}
                      style={{width: '100%'}}
                      disabled={isChangingDraftStage}
                      onClick={()=>{
                        handlePlaceOrder()
                      }}
                    >
                      <span>
                        <FileDoneOutlined style={{fontSize: 18, marginTop: 3}}/>
                        <span
                          className={"nextStepButtonText"}
                          style={{marginLeft: 5}}
                        >
                          {needsManualQuote ? "Request Quotation" : "Forward Quotation"}
                        </span>
                      </span>
                    </Button>
                  </div>
                  }
                  {/*<div style={{marginTop: 10, fontWeight: 600, fontSize: 12, color: "white"}}>*/}
                  {/*  {(working || actionMap.size > 0 || isChangingDeliveryDate || isChangingDraftName)  ?*/}
                  {/*    "Saving Draft..."*/}
                  {/*    :*/}
                  {/*    "Draft saved"*/}
                  {/*  }*/}
                  {/*</div>*/}
                </div>
              </div>
              }
            </div>
          </Card>
        </div>
      </Sticky>
    </div>
  )
}