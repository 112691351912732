import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux'

import { Sidebar } from '../../../components'

import {
  Button, Card, Col, Divider,
  Layout, Modal, Row, Spin, Tag, Tooltip,
} from 'antd';

import "./style.less"
import {
  DeleteOutlined, EditOutlined, ExclamationCircleFilled,
  LoadingOutlined, WarningOutlined
} from "@ant-design/icons";
import dragAndDropRed from "../../../assets/icons/dragndropred.png";
import {DeletionModal} from "../../../components/DeletionModal";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {Icon2D} from "../../../assets/icons/2d";
import {Icon3D} from "../../../assets/icons/3d";
import {logoutUser} from "../../../actions/authActions";
import {
  createNewDraftOrder, fetchDraftOrder,
  fetchDraftOrders, removeDraftOrder,
  createNewDraftOrderWithFiles,
  fetchCustomerAwaitingConfirmationDashboardOrders,
  customerFetchRecentChanges
} from "../../../actions/ordersActions";

import history from "../../../utils/history";
import { formatTableDate, getOrderStateName, tagColor, tagTextColor } from "../../../utils/utility";
import {FetchingOrderIndicator} from "../../../components/Order/FetchingOrderIndicator";
import {EmptyTable} from "../../../components/EmptyTable";
import {OrderDragger} from "../../../components/Order/OrderCalculationComponents/SharedComponents/OrderDragger";

let uploading = false;

export const CustomerDashboard = () => {
  const [draftLimitVisible, setDraftLimitVisible] = useState(false);

  const isCollapsed = useSelector(state => state.util.isCollapsed);
  const user = useSelector(state => state.auth.user)
  const isFetchingOrder = useSelector(state => state.orders.isFetchingOrder)
  const isFetchingDrafts = useSelector(state => state.orders.isFetchingDrafts)
  const draftOrders = useSelector(state => state.orders.draftOrders)
  const isCreatingDraftOrder = useSelector(state => state.orders.isCreatingDraftOrder)

  const isFetchingCustomerAwaitingConfirmationOrdersDashboard  = useSelector(state => state.orders.isFetchingCustomerAwaitingConfirmationOrdersDashboard)
  const customerAwaitingConfirmationDashboard  = useSelector(state => state.orders.customerAwaitingConfirmationDashboard)

  const isFetchingRecentChanges  = useSelector(state => state.orders.isFetchingRecentChanges)
  const recentChanges  = useSelector(state => state.orders.recentChanges)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDraftOrders())
    dispatch(fetchCustomerAwaitingConfirmationDashboardOrders())
    dispatch(customerFetchRecentChanges())
  }, [])

  const getHeight = () => {
    const width = window.innerWidth
    if(width >= 1200) {
      return "calc(100vh - 447px)"
    } else if (width < 1200 && width >= 992) {
      return "calc(100vh - 447px)"
    } else {
      return "calc(100% - 55px)"
    }
  }

  const [confirmationHeight, setConfirmationHeight] = useState(getHeight())

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  const handleResize = () => {
    setConfirmationHeight(getHeight)
  };

  const removeDraft = (draftId) => {
    dispatch(removeDraftOrder({draftId}))
  }

  const createNewOrder = () => {
    if(!isFetchingDrafts) {
      if(draftOrders.length > 7) {
        setDraftLimitVisible(true)
      } else {
        dispatch(createNewDraftOrder())
      }
    }
  }

  const openDraftOrder = (id) => {
    dispatch(fetchDraftOrder({id: id, withRedirect: true}))
  }

  const getOrderDetailsPath = (state) => {
    switch (state) {
      case "quoted":
        return "pending-order"
      case "awaitingQuote":
        return "pending-order"
      case "awaitingConfirmation":
        return "awaiting-order"
      case "accepted":
        return "completed-order"
      case "inProgress":
        return "completed-order"
      case "declined":
        return "completed-order"
      case "completed":
        return "completed-order"
      default:
        return "pending-order"
    }
  }

  const handleCreateDraftOrder = (file, fileList) => {
    if(!uploading) {
      uploading = true
      if(draftOrders.length > 7) {
        setDraftLimitVisible(true)
      } else {
        dispatch(createNewDraftOrderWithFiles(fileList)).then(()=>{
          uploading = false
        })
      }
    }
  }

  const formatStage = (stage) => {
    switch (stage) {
      case "processing":
        return "initial"
      case "shipping":
        return "delivery"
      case "confirmation":
        return "quote"
      default:
        return stage
    }
  }

  return (
    <Layout
      style={{
        overflow: "auto",
        minHeight: "100vh",
      }}
    >
      <Sidebar
        onLogout={()=>{dispatch(logoutUser())}}
        user={user}
      />
      <Layout.Content style={{marginLeft: isCollapsed ? 60 : 200, transition: "all 0.25s"}}>
        <div style={{overflowX: "hidden"}}>
          <Spin
            spinning={isCreatingDraftOrder || isFetchingOrder}
            style={{maxHeight: "100%"}}
            indicator={<FetchingOrderIndicator/>}
          >
          <div style={{marginRight: 25, marginBottom: 15, marginLeft: 25, marginTop: 15}}>
            <Row gutter={[20, 5]}>
              <Col span={24}>
                <Card
                  className={"orderDetailsHeader"}
                  style={{ marginBottom: -12 }}
                  // bodyStyle={{ padding: "10px" }}
                >
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div
                      className={"ellipsisText"}
                      style={{display: "flex", alignItems: "center", minWidth: 0}}
                    >
                      <div
                        className={"orderDetailsTitle"}
                        style={{ minWidth: 0}}
                      >
                        <div style={{ display: "flex", alignItems: "center", minWidth: 0}}>
                          <div style={{ whiteSpace: "nowrap"}}>
                            Welcome back, {user.name}.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col
                xxl={{span: 8, order: 1}}
                xl={{span: 8, order: 1}}
                lg={{span: 9, order: 1}}
                md={{span: 24, order: 1}}
                sm={{span: 24, order: 1}}
                xs={{span: 24, order: 1}}
              >
                <div
                  className={"newOrderBanner"}
                  style={{minHeight: 295, marginTop: 18}}
                  onClick={(e) => {
                    e.stopPropagation()
                    createNewOrder()
                  }}
                >
                  <div className={"getQuoteUpper"}>
                    <div className={"getQuoteLabel"}>
                      <div className={"getQuoteLabelContent"} style={{ color: "black"}}>
                        Fast automated quoting service
                      </div>
                    </div>
                    <div className={"getQuoteText"} style={{ color: "black"}}>
                      <div style={{marginRight: 10}}>
                        <img src={dragAndDropRed} className={"quoteIcon"} alt={""} />
                      </div>
                      Upload your drawings to receive an instant quote
                    </div>
                  </div>
                  <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 15}}>
                    <div style={{display: "flex"}}>
                      <div style={{display: "flex", alignItems: "center", marginRight: 10}}>
                        <Icon2D style={{position: "relative", width: 30, height: 30, top: 1, color: "#252C30"}}/>
                        <div style={{position: "relative", top: -2, fontSize: 18, fontWeight: 600, color: "#252C30"}}>.dxf</div>
                      </div>
                      <div style={{display: "flex", alignItems: "center", marginRight: 10}}>
                        <Icon3D style={{width: 28, height: 28, color: "#252C30", marginRight: 4}}/>
                        <div style={{position: "relative", top: -2, fontSize: 18, fontWeight: 600, color: "#252C30"}}>.stp</div>
                      </div>
                    </div>
                    <div>
                      <Button disabled={isFetchingDrafts} className={"getQuoteButton"}>
                        Get a Quote
                      </Button>
                    </div>
                  </div>
                  {draftOrders.length === 8 &&
                    <div style={{ marginTop: 5}}>
                      <ExclamationCircleFilled style={{color: "#ff5b2d", fontSize: 15}} />
                      <span style={{fontSize: 12, fontWeight: 500, color: "black", marginLeft: 5}}>
                        You have reached a maximum of 8 Drafts.
                      </span>
                    </div>
                  }
                </div>

              </Col>
              <Col
                xxl={{span: 16, order: 4}}
                xl={{span: 16, order: 4}}
                lg={{span: isCollapsed ? 15 : 24, order: 4}}
                md={{span: 24, order: 4}}
                sm={{span: 24, order: 4}}
                xs={{span: 24, order: 4}}
              >
                <div className={"pageSubtitle segmentTitle"}>
                  Recent Quote Status Changes
                </div>
                <Spin
                  spinning={isFetchingRecentChanges}
                  wrapperClassName={"recentChangesSpin"}
                  indicator={<LoadingOutlined style={{ fontSize: 48, marginTop: -24, marginLeft: -24 }} spin />}
                >
                  <div
                    className={"recentConfirmationsBox"}
                    style={{height: confirmationHeight, padding: "0 6px 0 6px", minHeight: 200}}
                  >
                    {recentChanges.length === 0 ?
                      <div className={"emptyRecentAwaiting"}>
                        {
                          !isFetchingRecentChanges &&
                          <EmptyTable text={"No recent Status changes."}/>
                        }
                      </div>
                      :
                      <div style={{width: "100%"}}>
                        <Card
                          className={"recentLegend"}
                          bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                        >
                          <div style={{display: "flex", alignItems: "center", width: "100%", marginTop: -5}}>
                            <table style={{width: "100%"}}>
                              <tbody>
                              <tr>
                                <td style={{width: "15%", minWidth: 80}}>
                                  <div style={{ display: "flex", height: "100%", width: "100%"}}>
                                    <div className={"recentStatusLeft"} style={{width: "100%"}}>
                                      <div style={{fontSize: 12, fontWeight: 500}}>Quote Code</div>
                                    </div>
                                  </div>
                                </td>
                                <td style={{width: "35%", maxWidth: 50}}>
                                  <div style={{ display: "flex", height: "100%", width: "100%"}}>
                                    <div className={"recentStatusLeft"} style={{width: "100%"}}>
                                      <div style={{fontSize: 12, fontWeight: 500}}>Custom Name</div>
                                    </div>
                                  </div>
                                </td>
                                <td style={{width: 200}}>
                                  <div align={"left"} className={"recentStatusLeft"} style={{marginRight: 8, width: "100%"}}>
                                    <div style={{fontSize: 12, fontWeight: 500, whiteSpace: "nowrap"}}>Last Changed</div>
                                  </div>
                                </td>
                                <td   style={{minWidth: "260px"}}>
                                  <div style={{ display: "flex", height: "100%", width: "100%", justifyContent: "flex-end", alignItems: "center", alignSelf: "center"}}>
                                    <div style={{ width: 96 }}>
                                      <div style={{fontSize: 12, fontWeight: 500}}>Previous state</div>
                                    </div>
                                    <div>
                                      <ArrowRightAltIcon style={{position: "relative", width: 60, top: 3, fontSize: 28, color: "rgb(86, 99, 111)"}}/>
                                    </div>
                                    <div style={{ width: 96 }}>
                                      <div style={{fontSize: 12, fontWeight: 500}}>Current state</div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </Card>
                        <Divider className={""} style={{ margin: "4px 0" }}/>
                        {recentChanges.map(item => (
                          <Card
                            className={"recentCard"}
                            style={{height: 45, cursor: "pointer", marginBottom: 6}}
                            bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}}
                            key={item.id}
                            onMouseDown={(e) => {
                              if (e.button === 1) {
                                const win = window.open(`/${getOrderDetailsPath(item.state)}?order=${item.id}`, "_blank");
                                win.focus();
                              } else if (e.button === 0) {
                                history.push(`/${getOrderDetailsPath(item.state)}?order=${item.id}`)
                              }
                            }}
                          >
                            <div style={{display: "flex", alignItems: "center", width: "100%", marginTop: -5}}>
                              <table style={{width: "100%"}}>
                                <tbody>
                                <tr>
                                  <td style={{width: "15%", minWidth: 80}}>
                                    <div style={{ display: "flex", height: "100%", width: "100%"}}>
                                      <div className={"recentStatusLeft"} style={{width: "100%"}}>
                                        <div className={"ellipsisStatusChange"}>{item.name}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td style={{width: "35%", maxWidth: 50}}>
                                    <div style={{ display: "flex", height: "100%", width: "100%"}}>
                                      <div className={"recentStatusLeft"} style={{width: "100%"}}>
                                        <div className={"ellipsisStatusChange"}>{item.customName || "/"}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td style={{width: 200}}>
                                    <div align={"left"} className={"recentStatusLeft"} style={{marginRight: 8, width: "100%"}}>
                                      <div style={{fontSize: 12, fontWeight: 500}}>{formatTableDate(item.lastStateChangeDate)}</div>
                                    </div>
                                  </td>
                                  <td style={{width: "260px"}}>
                                    <div style={{ display: "flex", height: "100%", width: "100%", justifyContent: "flex-end", alignItems: "center", alignSelf: "center"}}>
                                      <div>
                                        <Tag
                                          color={tagColor(item.previousState)}
                                          style={{marginRight: 0, fontWeight: 600, width: 96, textAlign: "center", color: tagTextColor(item.previousState)}}
                                        >
                                          {getOrderStateName(item.previousState)}
                                        </Tag>
                                      </div>
                                      <div>
                                        <ArrowRightAltIcon style={{position: "relative", width: 60, top: 3, fontSize: 28, color: "rgb(86, 99, 111)"}}/>
                                      </div>
                                      <div>
                                        <Tag
                                          color={tagColor(item.state)}
                                          style={{marginRight: 0, fontWeight: 600, width: 96, textAlign: "center", color: tagTextColor(item.state)}}
                                        >
                                          {getOrderStateName(item.state)}
                                        </Tag>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </Card>
                        ))}
                      </div>
                    }
                  </div>
                </Spin>
              </Col>
              <Col
                xxl={{span: 8, order: 3}}
                xl={{span: 8, order: 3}}
                lg={{span: isCollapsed ? 9 : 24, order: 3}}
                md={{span: 24, order: 3}}
                sm={{span: 24, order: 3}}
                xs={{span: 24, order: 3}}
              >
                <div className={"segmentTitle pageSubtitle"}>
                  Awaiting Confirmation
                </div>
                <div
                  className={"awaitingConfirmationBox"}
                  style={{height: confirmationHeight, padding: "0 6px 0 6px", minHeight: 200}}
                >
                  <Spin
                    spinning={isFetchingCustomerAwaitingConfirmationOrdersDashboard}
                    wrapperClassName={"awaitingConfirmationSpin"}
                    indicator={<LoadingOutlined style={{ fontSize: 72, marginTop: -36, marginLeft: -36 }} spin />}
                  >
                    <div>
                      {customerAwaitingConfirmationDashboard.length === 0 ?
                        <div className={"emptyRecentAwaiting"}>
                          {
                            !isFetchingCustomerAwaitingConfirmationOrdersDashboard &&
                            // <div className={"emptyDraftsText"}>All orders have been confirmed.</div>
                            <EmptyTable text={"All orders have been confirmed."}/>
                          }
                        </div>
                        :
                        customerAwaitingConfirmationDashboard.map(item => (
                          <Card
                            onMouseDown={(e) => {
                              if (e.button === 1) {
                                const win = window.open(`/awaiting-order?order=${item.id}`, "_blank");
                                win.focus();
                              } else if (e.button === 0) {
                                history.push(`/awaiting-order?order=${item.id}`)
                              }
                            }}
                            type="primary"
                            className={"bendCard"}
                            style={{height: 75, cursor: "pointer"}}
                            bodyStyle={{padding: "8px 12px 8px 12px", height: "100%"}} key={item.id}
                          >
                          <div style={{ display: "flex", justifyContent: "space-between", height: "100%"}}>
                            <div style={{ display: "flex", height: "100%"}}>
                              <div className={"awaitingConfirmationLeft"}>
                                <div>{item.customName ? <span>{item.customName}</span> : <span>{item.orderName}</span>}</div>
                                <div className={"inputInfoText"}>{formatTableDate(item.lastStateChangeDate)}</div>
                              </div>
                            </div>
                            <div style={{ display: "flex", height: "100%"}}>
                              <div className={"awaitingConfirmationRight"}>
                                <div className={"inputInfoText"} align={"right"} style={{fontSize: 12, fontWeight: 500, lineHeight: 2}}>
                                  {
                                    (item.changedDelivery ? ("Date" + (item.changedPrice ? ", Price" : "")) : (item.changedPrice ? "Price" : ""))
                                    +
                                    (item.changedParts ? item.changedDelivery || item.changedPrice ? ", Parts" : "Parts" : "")
                                  }
                                  <ExclamationCircleFilled style={{color: "#ffc739", fontSize: 15, position: "relative", top: 1, marginLeft: 5}} />
                                </div>
                                <div align={"right"}>
                                  <Button size={"small"} type={"primary"}>
                                    <span className={"inputInfoText"}>Review</span>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                        ))
                      }
                    </div>
                  </Spin>
                </div>
              </Col>
              <Col
                xxl={{span: 16, order: 2}}
                xl={{span: 16, order: 2}}
                lg={{span: 15, order: 2}}
                md={{span: 24, order: 2}}
                sm={{span: 24, order: 2}}
                xs={{span: 24, order: 2}}
              >
                <div
                  className={"draftsTitle pageSubtitle segmentTitle"}
                  style={{ marginTop: 12 }}
                >
                  <div>In Progress:</div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {draftOrders.length}/8
                    {draftOrders.length === 8 &&
                    <Tooltip
                      color={"#ffc107"}
                      size={"small"}
                      placement={"bottom"}
                      title={<span className={"text12-600"} style={{color: "#333f48"}}>You have reached a maximum of 8 Drafts.</span>}
                    >
                      <ExclamationCircleFilled style={{color: "#ffc107", fontSize: 13, marginLeft: 6, position: "relative", top: 9, height: 18}} />
                    </Tooltip>
                    }
                  </div>
                </div>
                <div className={"draftsContainerBox"} style={{ overflow: "auto" }}>
                  <Spin spinning={isFetchingDrafts}
                        wrapperClassName={"draftLoading"}
                        indicator={<LoadingOutlined style={{ fontSize: 72, marginTop: -36, marginLeft: -36 }} spin />}
                  >
                    <Row gutter={[6, 6]}>
                      {draftOrders.length === 0 ?
                        <div className={"emptyRecentAwaiting"}>
                          {!isFetchingDrafts &&
                            <EmptyTable text={"You have no drafts at the moment."}/>
                          }
                        </div>
                        :
                        draftOrders.map(item => (
                          <Col key={item.id} xxl={6} xl={8} md={12} sm={24} xs={24} style={{maxHeight: 79 }}>
                            <Spin
                              spinning={item.loading === true}
                              indicator={<LoadingOutlined style={{ fontSize: 36, marginTop: -18, marginLeft: -18 }} spin />}
                            >
                              <div className={"draftCard"} onMouseDown={(e) => {
                                if (e.button === 1) {
                                  const win = window.open(`/draft?order=${item.id}`, "_blank");
                                  win.focus();
                                } else if (e.button === 0) {
                                  openDraftOrder(item.id)
                                }
                              }}>
                                <div className={"titleWrapper"}>
                                  <div className={"draftCardTitle"}>
                                    {item.customName || item.name}
                                    <EditOutlined style={{fontSize: 16, marginLeft: 6, color: "#ff5b2d"}}/>
                                  </div>
                                  <div>
                                    <Button
                                      className={"draftRemoveButton"}
                                      // shape={"circle"}
                                      onMouseDown={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation();
                                        DeletionModal(true,
                                          "Remove Draft",
                                          "Are you sure you want to remove this draft",
                                          () => {},
                                          () => {
                                            removeDraft(item.id)
                                          })
                                      }}
                                    >
                                      <DeleteOutlined style={{color: "#ff5b2d"}}/>
                                    </Button>
                                  </div>
                                </div>
                                <div>
                                  <div className={"draftInfo"}>
                                    <div className={"dashboardText"}>
                                      Draft stage: {formatStage(item.stage)}
                                    </div>
                                  </div>
                                  <div>
                                    <Row gutter={5}>
                                      <Col span={8}>
                                        <div
                                          className={"draftProgressBar"}
                                          style={{backgroundColor: "#ff5b2d"}}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <div
                                          className={"draftProgressBar"}
                                          style={{
                                            backgroundColor:
                                              item.stage === "shipping" || item.stage === "confirmation" ?
                                                "#ff5b2d" : "#e0e0e0"
                                          }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        <div
                                          className={"draftProgressBar"}
                                          style={{backgroundColor:
                                              item.stage === "confirmation" ? "#ff5b2d" : "#e0e0e0"
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </Spin>
                          </Col>
                        ))}
                    </Row>
                  </Spin>
                </div>
              </Col>
            </Row>
          </div>
          </Spin>
        </div>
        <Modal
          visible={draftLimitVisible}
          footer={null}
          centered={true}
          onCancel={()=>{setDraftLimitVisible(false)}}
          title={"Draft Limit"}
        >
          <div style={{display: "flex", marginBottom: 20, marginTop: 15}}>
            <div style={{marginLeft: 10}}>
              <WarningOutlined style={{fontSize: 42, color: "#ff5b2d"}}/>
            </div>
            <div style={{marginLeft: 20, fontSize: 15, fontWeight: 500, color: "white"}}>
              <div>
                You have reached a maximum of 8 Drafts at a time.
              </div>
              <Divider className={"draftModalDivider"}/>
              <div>
                To create a new Order please remove or finalize one or more existing Drafts.
              </div>
            </div>
          </div>
        </Modal>

        <OrderDragger
          beforeUpload={(file, fileList) => {
            handleCreateDraftOrder(file, fileList)
            return false;
          }}
          dragEnterCondition={
            !isCreatingDraftOrder
            && !isFetchingOrder
          }
        />

      </Layout.Content>
    </Layout>
  )
}
