import initialState from "./initialState";
import {
  ORDER_SET_ORDER,
  ORDER_RESET_ORDER,
  ORDER_PARTS_ADD_NEW_PART,
  ORDER_PARTS_ADD_PART_DRAWING,
  ORDER_PARTS_APPLY_MULTIPLE_ITEMS,
  ORDER_PARTS_CHANGE_ASSEMBLY_QUANTITY,
  ORDER_PARTS_CHANGE_PART_DATA,
  ORDER_PARTS_CHANGE_PART_PROCESSING_TYPE,
  ORDER_CLEAR_ORDER,
  ORDER_PARTS_DELETE_ENTIRE_ASSEMBLY,
  ORDER_INCREMENT_PROCESSED,
  ORDER_INCREMENT_TO_PROCESS,
  ORDER_INCREMENT_UPLOADED,
  ORDER_PARTS_REMOVE_ITEM,
  ORDER_PARTS_REMOVE_MULTIPLE_ITEMS,
  ORDER_PARTS_REMOVE_PART_COLOR,
  ORDER_PARTS_REMOVE_PART_DRAWING,
  ORDER_RESET_TO_PROCESS,
  ORDER_PARTS_SET_ASSEMBLY_DATA,
  ORDER_PARTS_SET_ASSEMBLY_PARTS_DATA,
  ORDER_PARTS_SET_PART_COLOR,
  ORDER_PARTS_SET_PART_MACHINING_MATERIAL,
  ORDER_PARTS_SET_PART_TUBE_CUTTING_MATERIAL,
  ORDER_PARTS_SET_PART_MATERIAL,
  ORDER_PARTS_SET_PART_NOTE,
  ORDER_PARTS_SET_PART_QUANTITY,
  ORDER_PARTS_SET_PART_STANDARD,
  ORDER_PARTS_SET_PART_THICKNESS,
  ORDER_PARTS_SET_PART_TYPE,
  ORDER_PARTS_UPDATE_ASSEMBLY_PRICE,
  ORDER_PARTS_UPDATE_MANY_ASSEMBLIES_PRICES,
  ORDER_PARTS_UPDATE_MANY_PART_DATA,
  ORDER_PARTS_UPDATE_MANY_PART_PRICES,
  ORDER_PARTS_UPDATE_ORDER_PRICE,
  ORDER_PARTS_UPDATE_PART,
  ORDER_PARTS_UPDATE_PART_ANALYSED_STATUS,
  ORDER_PARTS_UPDATE_PART_DATA,
  ORDER_PARTS_UPDATE_PART_FILE_PATH,
  ORDER_PARTS_UPDATE_PART_PRICE,
  ORDER_SET_CHANGED_ADJUSTMENT,
  ORDER_SET_DELIVERY_DATE,
  ORDER_SET_DRAFT_NAME,
  ORDER_SET_DRAFT_STAGE,
  ORDER_SET_SHIPPING_DATA,
  ORDER_PARTS_SET_NUMBER_OF_UNIQUE_PARTS,
  ORDER_PARTS_SET_ASSEMBLY_WELDING_PRICE,
  ORDER_PARTS_SET_ASSEMBLY_ASSEMBLING_PRICE,
  ORDER_PARTS_SET_ASSEMBLY_PACKAGING_PRICE,
  ORDER_PARTS_SET_ASSEMBLY_TRANSPORTATION_PRICE,
  ORDER_PARTS_CHANGE_PART_TOLERANCE_DATA,
  ORDER_PARTS_CHANGE_PART_TOLERANCE,
  ORDER_PARTS_CHANGE_PART_ROUGHNESS,
  ORDER_PARTS_CHANGE_PART_ROUGHNESS_DATA,
  ORDER_PARTS_CHANGE_MANUAL_PART_TOLERANCE,
  ORDER_PARTS_CHANGE_MANUAL_PART_ROUGHNESS,
  ORDER_PARTS_SET_PART_MATERIAL_CERTIFICATE,
  ORDER_PARTS_CHANGE_PART_MATERIAL_CERTIFICATE_DATA
} from "../actions/actionTypes";
import {getAvailableThicknesses} from "../utils/material";

const orderReducer = (state = initialState.order, action) => {
  switch(action.type) {
    // ------------ Shared Cases ------------- //
    case ORDER_SET_ORDER:
      return {
        ...state,
        ...action.payload
      }
    case ORDER_RESET_ORDER:
      return {
        toProcess: 0,
        currentlyUploaded: 0,
        currentlyProcessed: 0
      }

    case ORDER_PARTS_UPDATE_ORDER_PRICE:
      return {
        ...state,
        ...action.payload
      }
    case ORDER_PARTS_ADD_NEW_PART:
      return {
        ...state,
        parts: [...state.parts, action.payload]
      }
    case ORDER_INCREMENT_TO_PROCESS:
      return {
        ...state,
        toProcess: state.toProcess + 1
      }
    case ORDER_INCREMENT_UPLOADED:
      return {
        ...state,
        currentlyUploaded: state.currentlyUploaded + 1
      }
    case ORDER_INCREMENT_PROCESSED:
      return {
        ...state,
        currentlyProcessed: state.currentlyProcessed + 1
      }
    case ORDER_PARTS_UPDATE_PART:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.fileId === action.payload.fileId) {
            return {
              ...item,
              ...action.payload,
              availableThicknesses: item.availableThicknesses || []
            }
          }
          return item;
        })
      }

    case ORDER_PARTS_SET_ASSEMBLY_DATA:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
              availableThicknesses: item.availableThicknesses || []
            }
          }
          return item;
        }),
      }
    case ORDER_PARTS_SET_ASSEMBLY_PARTS_DATA:
      return {
        ...state,
        parts: [...state.parts, ...action.payload],
        toProcess: state.toProcess + action.payload.length,
        currentlyUploaded: state.currentlyUploaded + action.payload.length,
      }
    case ORDER_PARTS_UPDATE_ASSEMBLY_PRICE:
      return {
        ...state,
        needsManualQuote: action.payload.needsManualQuote || state.needsManualQuote,
        parts: state.parts.map(item => {
          if (item.orderId === action.payload.orderId && item.id === action.payload.assemblyId) {
            return {
              ...item,
              isAnalysed: action.payload.isAnalysed || item.isAnalysed,
              pricePerPiece: action.payload.pricePerPiece,
              totalPrice: action.payload.totalPrice,
              numberOfUniqueParts: action.payload.numberOfUniqueParts || item.numberOfUniqueParts,
              numberOfAutomaticParts: !isNaN(action.payload.numberOfAutomaticParts) ? action.payload.numberOfAutomaticParts : item.numberOfAutomaticParts,
              numberOfManualParts: !isNaN(action.payload.numberOfManualParts) ? action.payload.numberOfManualParts : item.numberOfManualParts
            }
          }
          return item
        })
      }
    case ORDER_PARTS_UPDATE_MANY_ASSEMBLIES_PRICES:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.partType === "assembly" && action.payload.assemblyPrices[item.id]) {
            return {
              ...item,
              pricePerPiece: action.payload.assemblyPrices[item.id].pricePerPiece,
              totalPrice: action.payload.assemblyPrices[item.id].totalPrice,
              numberOfUniqueParts: action.payload.assemblyPrices[item.id].numberOfUniqueParts || item.numberOfUniqueParts,
              numberOfAutomaticParts: action.payload.assemblyPrices[item.id].numberOfAutomaticParts || item.numberOfAutomaticParts,
              numberOfManualParts: action.payload.assemblyPrices[item.id].numberOfManualParts || item.numberOfManualParts
            }
          }
          return item
        })
      }

    case ORDER_PARTS_UPDATE_PART_DATA:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            const tmp = {...action.payload.data}
            if(item?.stepData?.filePaths && tmp?.stepData?.filePaths) {
              tmp.stepData.filePaths.pngFilePath = tmp.stepData.filePaths.pngFilePath || item.stepData.filePaths.pngFilePath
              tmp.stepData.filePaths.foldedStlFilePath = tmp.stepData.filePaths.foldedStlFilePath || item.stepData.filePaths.foldedStlFilePath
              tmp.stepData.filePaths.unfoldedStlFilePath = tmp.stepData.filePaths.unfoldedStlFilePath || item.stepData.filePaths.unfoldedStlFilePath
            }
            if(!(tmp.fileType === "step" && tmp.processingType === "automatic")) {
              tmp.availableThicknesses =  getAvailableThicknesses(tmp.fileType, tmp.processingType, state.materials.sheetMetal, tmp.selectedMaterial, tmp.materials.sheetMetal, state.sheetMetalMaterialMap)
            }
            return {
              ...item,
              ...tmp
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_UPDATE_PART_FILE_PATH:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            let tmp = {...item}
            if(!item?.stepData?.filePaths) {
              tmp.stepData = {filePaths: {}}
            }
            tmp.stepData.filePaths[action.payload.filePathName] = action.payload.filePathValue
            return tmp
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_PART_COLOR:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              coating: {
                hasCoating: true,
                type: action.payload.coatingType,
                data: {
                  colorRAL: action.payload.colorRAL,
                  colorGlossId: action.payload.colorGlossId,
                  ralRow: action.payload.ralRow,
                  ralHex: action.payload.ralHex,
                }
              }
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_REMOVE_PART_COLOR:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              coating: {
                hasCoating: false,
                type: "painting",
                data: {
                  colorRAL: "",
                  colorGlossId: "",
                  ralRow: 0,
                  ralHex: ""
                }
              }
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_PART_QUANTITY:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              quantity: action.payload.quantity
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_PART_NOTE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              note: action.payload.note
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_PART_MATERIAL:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              selectedMaterial: {
                grade: action.payload.material.grade,
                materialId: action.payload.material.selectedMaterial.id,
                thickness: action.payload.material.selectedMaterial.thickness
              },
              availableThicknesses: [...action.payload.material.thickness]
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_PART_MACHINING_MATERIAL:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              selectedMaterial: {
                ...item.selectedMaterial,
                grade: action.payload.grade,
                materialId: action.payload.materialId,
              }
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_PART_MATERIAL_CERTIFICATE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              certNeeded: action.payload.certNeeded
            }
          }
          return item
        })
      }
    case ORDER_PARTS_SET_PART_TUBE_CUTTING_MATERIAL:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              selectedMaterial: {
                ...item.selectedMaterial,
                grade: action.payload.grade
              }
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_PART_THICKNESS:
      return {
        ...state,
        parts: state.parts.map(item => {
          if(item.id === action.payload.id) {
            return {
              ...item,
              selectedMaterial: {
                ...item.selectedMaterial,
                materialId: action.payload.thickness.id,
                thickness: action.payload.thickness.thickness,
                isAvailable: true
              },
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_PART_STANDARD:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            return {
              ...item,
              standard: action.payload.standard
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_ASSEMBLY_WELDING_PRICE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              weldingPrice: action.payload.weldingPrice
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_ASSEMBLY_ASSEMBLING_PRICE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              assemblingPrice: action.payload.assemblingPrice
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_ASSEMBLY_PACKAGING_PRICE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              packagingPrice: action.payload.packagingPrice
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_ASSEMBLY_TRANSPORTATION_PRICE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              transportationPrice: action.payload.transportationPrice
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_NUMBER_OF_UNIQUE_PARTS:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              numberOfUniqueParts: action.payload.numberOfUniqueParts
            }
          }
          return item;
        })
      }

    case ORDER_RESET_TO_PROCESS:
      return {
        ...state,
        toProcess: 0,
        currentlyUploaded: 0,
        currentlyProcessed: 0
      }
    case ORDER_PARTS_REMOVE_ITEM:
      const remItem = action.payload.item
      return {
        ...state,
        parts: state.parts.map((item)=> {
          if ((item.partType === "assembly" && remItem.assemblyId === item.id && item.numberOfUniqueParts === 1) ||
            (item.id === remItem.id)) {
            return {id: item.id}
          }
          return item
        })
      }
    case ORDER_PARTS_REMOVE_MULTIPLE_ITEMS:
      return {
        ...state,
        parts: state.parts.map(item => {
          if(action.payload.selected[item.id]) {
            return {id: item.id}
          }
          return item
        })
      }
    case ORDER_PARTS_APPLY_MULTIPLE_ITEMS:
      return {
        ...state,
        parts: state.parts.map(item=>{
          if(action.payload.applyingChanges[item.id]) {
            let changes = action.payload.applyingChanges[item.id]
            if(item.partType === "sheetMetal") {
              return {
                ...item,
                selectedMaterial: changes.material ? changes.material : item.selectedMaterial,
                availableThicknesses: changes.material ? changes.material.availableThicknesses : item.availableThicknesses,
                quantity: changes.quantity || item.quantity,
                certNeeded: (changes.certNeeded !== null) ? changes.certNeeded : item.certNeeded,
                coating: changes.coating || item.coating
              }
            } else if (item.partType === "milled" || item.partType === "lathed") {
              return {
                ...item,
                selectedMaterial: changes.material ? {...changes.material, materialId: changes.material.id} : item.selectedMaterial,
                quantity: changes.quantity || item.quantity,
                certNeeded: (changes.certNeeded !== null) ? changes.certNeeded : item.certNeeded,
                coating: changes.coating || item.coating
              }
            } else if (item.partType === "tubeCut" || item.partType === "profile") {
              return {
                ...item,
                quantity: changes.quantity || item.quantity,
                certNeeded: (changes.certNeeded !== null) ? changes.certNeeded : item.certNeeded,
                standard: changes.standard || item.standard,
                coating: changes.coating || item.coating
              }
            } else {
              return {
                ...item,
                quantity: changes.quantity || item.quantity
              }
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_SET_PART_TYPE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              partType: action.payload.partType
            }
          }
          return item;
        })
      }

    case ORDER_PARTS_ADD_PART_DRAWING:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            let tmpDocuments = [...item.documents];
            tmpDocuments.push({...action.payload.data});
            return {
              ...item,
              documents: tmpDocuments
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_REMOVE_PART_DRAWING:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            let tmpDocuments = item.documents;
            tmpDocuments = tmpDocuments.filter(doc => doc.documentId !== action.payload.documentId);
            return {
              ...item,
              documents: tmpDocuments
            }
          }
          return item;
        })
      }

    case ORDER_PARTS_DELETE_ENTIRE_ASSEMBLY:
      return {
        ...state,
        parts: state.parts.map(item => {
          if((item.partType === "assembly" && item.id === action.payload.assemblyId) || (item.partType !== "assembly" && item.assemblyId === action.payload.assemblyId)) {
            return {id: item.id}
          }
          return item
        })
      }
    case ORDER_PARTS_UPDATE_PART_ANALYSED_STATUS:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.orderId === action.payload.orderId && item.id === action.payload.orderPartId) {
            return {
              ...item,
              isAnalysed: action.payload.isAnalysed
            }
          }
          return item
        })
      }
    case ORDER_PARTS_CHANGE_ASSEMBLY_QUANTITY:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.partType === "assembly" && item.id === action.payload.assemblyId) {
            return {
              ...item,
              quantity: action.payload.quantity,
              pricePerPiece: action.payload.pricePerPiece,
              totalPrice: action.payload.totalPrice
            }
          }
          if (item.assemblyId === action.payload.assemblyId) {
            const part = action.payload.parts[item.id]
            if (part) {
              return {
                ...item,
                operationPrices: part.operationPrices,
                pricePerPiece: part.pricePerPiece,
                totalPrice: part.totalPrice,
                gelsoPrices: part.gelsoPrices || {},
                sheetMetalPriceByProducer: part.sheetMetalPriceByProducer || [],
                millingPriceByProducer: part.millingPriceByProducer || [],
                lathingPriceByProducer: part.lathingPriceByProducer || [],
                tubeCuttingPriceByProducer: part.tubeCuttingPriceByProducer || [],
                selectedMaterial: {
                  ...item.selectedMaterial,
                  producerId: part.producerId
                }
              }
            }
            return item
          }
          return item
        })
      }

    case ORDER_PARTS_CHANGE_PART_PROCESSING_TYPE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            let availableThicknesses = null;
            if(action.payload.partType === "sheetMetal") {
              availableThicknesses = getAvailableThicknesses(item.fileType, action.payload.processingType, state.materials.sheetMetal, ( action.payload.selectedMaterial || item.selectedMaterial), item.materials.sheetMetal, state.sheetMetalMaterialMap)
            }
            return {
              ...item,

              toleranceChanged: action.payload.toleranceChanged,
              selectedTolerance: action.payload.selectedTolerance,
              selectedRoughness: action.payload.selectedRoughness,

              standard: "",
              numberOfUniqueParts: 1,
              operationPrices: action.payload.operationPrices ||  item.operationPrices,
              selectedMaterial: action.payload.selectedMaterial || item.selectedMaterial,
              availableThicknesses: availableThicknesses || item.availableThicknesses || [],
              gelsoPrices: action.payload.gelsoPrices || item.gelsoPrices || {},
              sheetMetalPriceByProducer: action.payload.sheetMetalPriceByProducer || item.sheetMetalPriceByProducer || [],
              millingPriceByProducer: action.payload.millingPriceByProducer || item.millingPriceByProducer || [],
              lathingPriceByProducer: action.payload.lathingPriceByProducer || item.lathingPriceByProducer || [],
              tubeCuttingPriceByProducer: action.payload.tubeCuttingPriceByProducer || item.tubeCuttingPriceByProducer || [],
              partType: action.payload.partType,
              processingType: action.payload.processingType,
              pricePerPiece: action.payload.pricePerPiece,
              totalPrice: action.payload.totalPrice
            }
          }
          return item
        })
      }

    case ORDER_PARTS_CHANGE_PART_TOLERANCE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              toleranceChanged: false,
              selectedTolerance: {
                ...item.selectedTolerance,
                tolerance: action.payload.tolerance
              }
            }
          }
          return item
        })
      }

    case ORDER_PARTS_CHANGE_PART_TOLERANCE_DATA:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            return action.payload.isDraft ?
              {
                ...item,
                toleranceChanged: action.payload.toleranceChanged,
                selectedTolerance: action.payload.selectedTolerance,
                selectedToleranceByType: action.payload.selectedToleranceByType,
                selectedRoughness: action.payload.selectedRoughness,
                selectedRoughnessByType: action.payload.selectedRoughnessByType,

                selectedMaterial: {
                  ...item.selectedMaterial,
                  isAvailable: action.payload.errorType === "materialNotAvailable" ? false : item.selectedMaterial?.isAvailable
                },

                pricePerPiece: action.payload.pricePerPiece || item.pricePerPiece,
                totalPrice: action.payload.totalPrice || item.totalPrice
              }
              :
              {
                ...item,

                selectedMaterial: {
                  ...item.selectedMaterial,
                  producerId: action.payload.producerId || item.selectedMaterial?.producerId || null,
                  isAvailable: action.payload.errorType === "materialNotAvailable" ? false : item.selectedMaterial?.isAvailable
                },

                toleranceChanged: action.payload.toleranceChanged,
                selectedTolerance: action.payload.selectedTolerance,
                selectedToleranceByType: action.payload.selectedToleranceByType,
                selectedRoughness: action.payload.selectedRoughness,
                selectedRoughnessByType: action.payload.selectedRoughnessByType,

                operationPrices: action.payload.operationPrices ||  item.operationPrices,
                gelsoPrices: action.payload.gelsoPrices || item.gelsoPrices || {},
                sheetMetalPriceByProducer: action.payload.sheetMetalPriceByProducer || item.sheetMetalPriceByProducer || [],
                millingPriceByProducer: action.payload.millingPriceByProducer || item.millingPriceByProducer || [],
                lathingPriceByProducer: action.payload.lathingPriceByProducer || item.lathingPriceByProducer || [],
                tubeCuttingPriceByProducer: action.payload.tubeCuttingPriceByProducer || item.tubeCuttingPriceByProducer || [],

                pricePerPiece: action.payload.pricePerPiece || item.pricePerPiece,
                totalPrice: action.payload.totalPrice || item.totalPrice
              }
          }
          return item
        })
      }

    case ORDER_PARTS_CHANGE_PART_ROUGHNESS:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              toleranceChanged: false,
              selectedRoughness: {
                ...item.selectedRoughness,
                roughness: action.payload.roughness,
              }
            }
          }
          return item
        })
      }

    case ORDER_PARTS_CHANGE_PART_ROUGHNESS_DATA:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            return action.payload.isDraft ?
              {
                ...item,
                toleranceChanged: action.payload.toleranceChanged,
                selectedTolerance: action.payload.selectedTolerance,
                selectedToleranceByType: action.payload.selectedToleranceByType,
                selectedRoughness: action.payload.selectedRoughness,
                selectedRoughnessByType: action.payload.selectedRoughnessByType,

                selectedMaterial: {
                  ...item.selectedMaterial,
                  isAvailable: action.payload.errorType === "materialNotAvailable" ? false : item.selectedMaterial?.isAvailable
                },

                pricePerPiece: action.payload.pricePerPiece || item.pricePerPiece,
                totalPrice: action.payload.totalPrice || item.totalPrice
              }
              :
              {
                ...item,

                selectedMaterial: {
                  ...item.selectedMaterial,
                  producerId: action.payload.producerId || item.selectedMaterial?.producerId || null,
                  isAvailable: action.payload.errorType === "materialNotAvailable" ? false : item.selectedMaterial?.isAvailable
                },

                toleranceChanged: action.payload.toleranceChanged,
                selectedTolerance: action.payload.selectedTolerance,
                selectedToleranceByType: action.payload.selectedToleranceByType,
                selectedRoughness: action.payload.selectedRoughness,
                selectedRoughnessByType: action.payload.selectedRoughnessByType,

                operationPrices: action.payload.operationPrices ||  item.operationPrices,
                gelsoPrices: action.payload.gelsoPrices || item.gelsoPrices || {},
                sheetMetalPriceByProducer: action.payload.sheetMetalPriceByProducer || item.sheetMetalPriceByProducer || [],
                millingPriceByProducer: action.payload.millingPriceByProducer || item.millingPriceByProducer || [],
                lathingPriceByProducer: action.payload.lathingPriceByProducer || item.lathingPriceByProducer || [],
                tubeCuttingPriceByProducer: action.payload.tubeCuttingPriceByProducer || item.tubeCuttingPriceByProducer || [],

                pricePerPiece: action.payload.pricePerPiece || item.pricePerPiece,
                totalPrice: action.payload.totalPrice || item.totalPrice
              }
          }
          return item
        })
      }

    case ORDER_PARTS_CHANGE_PART_MATERIAL_CERTIFICATE_DATA:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            return {
              ...item,
              selectedMaterial: {
                ...item.selectedMaterial,
                density: action.payload?.density || item.selectedMaterial?.density || 0,
                producerId: action.payload?.producerId || item.selectedMaterial?.producerId || null
              },
              pricePerPiece: action.payload.pricePerPiece,
              totalPrice: action.payload.totalPrice,
              operationPrices: action.payload?.operationPrices || item.operationPrices,
              gelsoPrices: action.payload?.gelsoPrices || item.gelsoPrices || {},
              sheetMetalPriceByProducer: action.payload?.sheetMetalPriceByProducer || item.sheetMetalPriceByProducer || [],
              millingPriceByProducer: action.payload?.millingPriceByProducer || item.millingPriceByProducer || [],
              lathingPriceByProducer: action.payload?.lathingPriceByProducer || item.lathingPriceByProducer || [],
              tubeCuttingPriceByProducer: action.payload?.tubeCuttingPriceByProducer || item.tubeCuttingPriceByProducer || []
            }
          }
          return item
        })
      }

    case ORDER_PARTS_CHANGE_MANUAL_PART_TOLERANCE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              manualTolerance: action.payload.tolerance
            }
          }
          return item
        })
      }

    case ORDER_PARTS_CHANGE_MANUAL_PART_ROUGHNESS:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              manualRoughness: action.payload.roughness
            }
          }
          return item
        })
      }

    // --------------------------------------- //

    // ----------- Draft Specific ------------ //
    case ORDER_SET_DRAFT_STAGE:
      return {
        ...state,
        stage: action.payload
      }
    case ORDER_SET_SHIPPING_DATA:
      return {
        ...state,
        shippingData: {...action.payload}
      }
    case ORDER_SET_DELIVERY_DATE:
      return {
        ...state,
        deliveryDate: action.payload
      }
    case ORDER_SET_DRAFT_NAME:
      return {
        ...state,
        customName: action.payload
      }
    case ORDER_SET_CHANGED_ADJUSTMENT:
      return {
        ...state,
        adjustment: action.payload.adjustment || state.adjustment,
        oldAdjustment: action.payload.oldAdjustment || state.oldAdjustment,
      }

    case ORDER_PARTS_UPDATE_MANY_PART_PRICES:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (action.payload.parts[item.id]) {
            return {
              ...item,
              toleranceChanged: action.payload.parts[item.id].toleranceChanged,
              selectedTolerance: action.payload.parts[item.id].selectedTolerance,
              selectedToleranceByType: action.payload.parts[item.id].selectedToleranceByType,
              selectedRoughness: action.payload.parts[item.id].selectedRoughness,
              selectedRoughnessByType: action.payload.parts[item.id].selectedRoughnessByType,

              pricePerPiece: action.payload.parts[item.id].pricePerPiece,
              totalPrice: action.payload.parts[item.id].totalPrice
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_UPDATE_PART_PRICE:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            return {
              ...item,
              toleranceChanged: action.payload.toleranceChanged,
              selectedTolerance: action.payload.selectedTolerance,
              selectedToleranceByType: action.payload.selectedToleranceByType,
              selectedRoughness: action.payload.selectedRoughness,
              selectedRoughnessByType: action.payload.selectedRoughnessByType,

              pricePerPiece: action.payload.pricePerPiece,
              totalPrice: action.payload.totalPrice
            }
          }
          return item;
        })
      }
    //--------------------------------------- //

    // ----------- Bulk Specific ------------ //
    case ORDER_CLEAR_ORDER:
      return {
        toProcess: 0,
        currentlyUploaded: 0,
        currentlyProcessed: 0,
        ...action.payload
      }
    case ORDER_PARTS_CHANGE_PART_DATA:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (item.id === action.payload.orderPartId) {
            return {
              ...item,
              toleranceChanged: action.payload.toleranceChanged,
              selectedTolerance: action.payload.selectedTolerance,
              selectedToleranceByType: action.payload.selectedToleranceByType,
              selectedRoughness: action.payload.selectedRoughness,
              selectedRoughnessByType: action.payload.selectedRoughnessByType,

              selectedMaterial: {
                ...item.selectedMaterial,
                density: action.payload?.density || item.selectedMaterial?.density || 0,
                producerId: action.payload?.producerId || item.selectedMaterial?.producerId || null
              },
              pricePerPiece: action.payload.pricePerPiece,
              totalPrice: action.payload.totalPrice,
              operationPrices: action.payload?.operationPrices || item.operationPrices,
              gelsoPrices: action.payload?.gelsoPrices || item.gelsoPrices || {},
              sheetMetalPriceByProducer: action.payload?.sheetMetalPriceByProducer || item.sheetMetalPriceByProducer || [],
              millingPriceByProducer: action.payload?.millingPriceByProducer || item.millingPriceByProducer || [],
              lathingPriceByProducer: action.payload?.lathingPriceByProducer || item.lathingPriceByProducer || [],
              tubeCuttingPriceByProducer: action.payload?.tubeCuttingPriceByProducer || item.tubeCuttingPriceByProducer || []
            }
          }
          return item;
        })
      }
    case ORDER_PARTS_UPDATE_MANY_PART_DATA:
      return {
        ...state,
        parts: state.parts.map(item => {
          if (action.payload.parts[item.id]) {
            return {
              ...item,
              toleranceChanged: action.payload.parts[item.id].toleranceChanged,
              selectedTolerance: action.payload.parts[item.id].selectedTolerance,
              selectedToleranceByType: action.payload.parts[item.id].selectedToleranceByType,
              selectedRoughness: action.payload.parts[item.id].selectedRoughness,
              selectedRoughnessByType: action.payload.parts[item.id].selectedRoughnessByType,

              selectedMaterial: {
                ...item.selectedMaterial,
                density: action.payload.parts[item.id]?.density || item.selectedMaterial?.density || 0,
                producerId: action.payload.parts[item.id]?.producerId || item.selectedMaterial?.producerId || null
              },
              pricePerPiece: action.payload.parts[item.id]?.pricePerPiece,
              totalPrice: action.payload.parts[item.id]?.totalPrice,
              operationPrices: action.payload.parts[item.id]?.operationPrices || item.operationPrices,
              gelsoPrices: action.payload.parts[item.id]?.gelsoPrices || item.gelsoPrices || {},
              sheetMetalPriceByProducer: action.payload.parts[item.id]?.sheetMetalPriceByProducer || item.sheetMetalPriceByProducer || [],
              millingPriceByProducer: action.payload.parts[item.id]?.millingPriceByProducer || item.millingPriceByProducer || [],
              lathingPriceByProducer: action.payload.parts[item.id]?.lathingPriceByProducer || item.lathingPriceByProducer || [],
              tubeCuttingPriceByProducer: action.payload.parts[item.id]?.tubeCuttingPriceByProducer || item.tubeCuttingPriceByProducer || []
            }
          }
          return item;
        })
      }
    //--------------------------------------- //

    default:
      return state
  }
}

export default orderReducer