import React from "react";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {EmptyTable} from "../../../EmptyTable";
import {AutoSizer, List} from "react-virtualized";
import {PendingCustomerCard} from "../PendingCustomerCard";

export const PendingCustomerGrid = (props) => {

  const {
    customers, itemCount, itemSize,
    searching, isFetchingCustomers,
    emptyText, onCustomerAuth
  } = props;

  if (itemCount === 0) {
    return (
      <React.Fragment>
        <Spin
          wrapperClassName={"customerGridSpinner"}
          spinning={searching || isFetchingCustomers}
          indicator={
            <div align={"center"}>
              <LoadingOutlined style={{ fontSize: 50, marginLeft: -20, marginTop: -20 }} spin />
            </div>
          }
        >
          <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
            <EmptyTable text={emptyText}/>
          </div>
        </Spin>
      </React.Fragment>
    )
  } else {
    return (
      <AutoSizer>
        {({ height, width }) => {
          const itemsPerRow = Math.floor(width / itemSize) || 1;
          const rowCount = Math.ceil(itemCount / itemsPerRow);

          return (
            <Spin
              spinning={searching}
              style={{width}}
              indicator={
                <div align={"center"}>
                  <LoadingOutlined style={{ fontSize: 50, marginLeft: -25, marginTop: -25 }} spin />
                </div>
              }
            >
              <List
                className={'customerList'}
                width={width}
                height={height}
                rowCount={rowCount}
                rowHeight={70}
                overscanRowCount={2}
                rowRenderer={
                  ({ index, key, style }) => {
                    const items = [];
                    const fromIndex = index * itemsPerRow;
                    const toIndex = fromIndex + itemsPerRow;

                    for (let i = fromIndex; i < toIndex; i++) {
                      items.push(
                        <PendingCustomerCard
                          onCustomerAuth={onCustomerAuth}
                          item={customers[i]}
                          key={i}
                          count={i}
                          itemCount={itemCount}
                        />
                      )
                    }

                    return (
                      <div
                        className={'pendingCustomerRow'}
                        key={key}
                        style={style}
                      >
                        {items}
                      </div>
                    )
                  }
                }
              />
            </Spin>
          )
        }}
      </AutoSizer>
    )
  }
}