import {
  ORDER_PARTS_ADD_NEW_PART,
  ORDER_PARTS_ADD_PART_DRAWING,
  ORDER_PARTS_APPLY_MULTIPLE_ITEMS,
  ORDER_PARTS_CHANGE_ASSEMBLY_QUANTITY,
  ORDER_PARTS_CHANGE_PART_DATA,
  ORDER_PARTS_CHANGE_PART_PROCESSING_TYPE,
  ORDER_CLEAR_ORDER,
  ORDER_PARTS_DELETE_ENTIRE_ASSEMBLY,
  ORDER_INCREMENT_PROCESSED,
  ORDER_INCREMENT_TO_PROCESS,
  ORDER_INCREMENT_UPLOADED,
  ORDER_PARTS_REMOVE_ITEM,
  ORDER_PARTS_REMOVE_MULTIPLE_ITEMS,
  ORDER_PARTS_REMOVE_PART_COLOR,
  ORDER_PARTS_REMOVE_PART_DRAWING,
  ORDER_RESET_TO_PROCESS,
  ORDER_PARTS_SET_ASSEMBLY_DATA,
  ORDER_PARTS_SET_ASSEMBLY_PARTS_DATA,
  ORDER_PARTS_SET_PART_COLOR,
  ORDER_PARTS_SET_PART_MACHINING_MATERIAL,
  ORDER_PARTS_SET_PART_TUBE_CUTTING_MATERIAL,
  ORDER_PARTS_SET_PART_MATERIAL,
  ORDER_PARTS_SET_PART_NOTE,
  ORDER_PARTS_SET_PART_QUANTITY,
  ORDER_PARTS_SET_PART_STANDARD,
  ORDER_PARTS_SET_PART_THICKNESS,
  ORDER_PARTS_UPDATE_ASSEMBLY_PRICE,
  ORDER_PARTS_UPDATE_MANY_ASSEMBLIES_PRICES,
  ORDER_PARTS_UPDATE_MANY_PART_DATA,
  ORDER_PARTS_UPDATE_MANY_PART_PRICES,
  ORDER_PARTS_UPDATE_ORDER_PRICE,
  ORDER_PARTS_UPDATE_PART,
  ORDER_PARTS_UPDATE_PART_ANALYSED_STATUS,
  ORDER_PARTS_UPDATE_PART_DATA,
  ORDER_PARTS_UPDATE_PART_FILE_PATH,
  ORDER_PARTS_UPDATE_PART_PRICE,
  ORDER_SET_CHANGED_ADJUSTMENT,
  ORDER_SET_DELIVERY_DATE,
  ORDER_SET_DRAFT_NAME,
  ORDER_SET_DRAFT_STAGE,
  ORDER_SET_SHIPPING_DATA,
  ORDERS_SET_IS_CHANGING_DELIVERY_DATE,
  ORDERS_SET_IS_CHANGING_DRAFT_NAME,
  ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
  ORDER_PARTS_SET_NUMBER_OF_UNIQUE_PARTS,
  ORDER_PARTS_SET_ASSEMBLY_WELDING_PRICE,
  ORDER_PARTS_SET_ASSEMBLY_ASSEMBLING_PRICE,
  ORDER_PARTS_SET_ASSEMBLY_PACKAGING_PRICE,
  ORDER_PARTS_SET_ASSEMBLY_TRANSPORTATION_PRICE,
  ORDER_PARTS_CHANGE_PART_TOLERANCE_DATA,
  ORDER_PARTS_CHANGE_PART_TOLERANCE,
  ORDER_PARTS_CHANGE_PART_ROUGHNESS_DATA,
  ORDER_PARTS_CHANGE_PART_ROUGHNESS,
  ORDER_PARTS_CHANGE_MANUAL_PART_TOLERANCE,
  ORDER_PARTS_CHANGE_MANUAL_PART_ROUGHNESS,
  ORDER_PARTS_SET_PART_MATERIAL_CERTIFICATE,
  ORDER_PARTS_CHANGE_PART_MATERIAL_CERTIFICATE_DATA
} from "./actionTypes";
import {http, message} from "../utils";
import {API_BASE_URL} from "../constants";
import {forceLogoutUser} from "./authActions";
import history from "../utils/history";


//------------------ SHARED ACTIONS ------------------//
const uploadNewPart = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_ADD_NEW_PART,
    payload: {...payload.data, state: "uploading"},
  })

  dispatch({
    type: ORDER_INCREMENT_TO_PROCESS,
    payload: null
  })

  const formData = new FormData()
  formData.set('data', JSON.stringify({fileId: payload.data.fileId, uploadTime: payload.data.uploadTime, uploadIndex: payload.data.uploadIndex}))
  formData.set('file', payload.file)

  return http.formPost(`${API_BASE_URL}/order-part/${payload.data.orderId}`, formData).then(
    response => {
      dispatch({
        type: ORDER_PARTS_UPDATE_PART,
        payload: {...response.data}
      })
      payload.callback({orderId: payload.data.orderId, orderPartId: response.data.id})

      dispatch({
        type: ORDER_INCREMENT_UPLOADED,
        payload: null
      })
    }
  ).catch((error) => {
    if(error?.response?.status === 401) {
      dispatch(forceLogoutUser())
    }
  })
}

const changeItemColor = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_SET_PART_COLOR,
    payload,
  })
}
const changeStandard = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_SET_PART_STANDARD,
    payload,
  })
}
const removeItemColor = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_REMOVE_PART_COLOR,
    payload,
  })
}
const changeItemQuantity = payload => dispatch => {
  if (payload.id && !isNaN(parseInt(payload.quantity))) {
    dispatch({
      type: ORDER_PARTS_SET_PART_QUANTITY,
      payload,
    })
  }
}
const changeItemNote = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: ORDER_PARTS_SET_PART_NOTE,
      payload,
    })
  }
}
const changeItemMaterial = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: ORDER_PARTS_SET_PART_MATERIAL,
      payload,
    })
  }
}
const changePartMachiningMaterial = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: ORDER_PARTS_SET_PART_MACHINING_MATERIAL,
      payload,
    })
  }
}
const changePartMaterialCertificate = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: ORDER_PARTS_SET_PART_MATERIAL_CERTIFICATE,
      payload,
    })
  }
}

const changePartTubeCuttingMaterial = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: ORDER_PARTS_SET_PART_TUBE_CUTTING_MATERIAL,
      payload,
    })
  }
}

const changeItemThickness = payload => dispatch => {
  if (payload.id) {
    dispatch({
      type: ORDER_PARTS_SET_PART_THICKNESS,
      payload,
    })
  }
}
const changeWeldingPrice = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_SET_ASSEMBLY_WELDING_PRICE,
    payload,
  })
}
const changeAssemblingPrice = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_SET_ASSEMBLY_ASSEMBLING_PRICE,
    payload,
  })
}
const changePackagingPrice = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_SET_ASSEMBLY_PACKAGING_PRICE,
    payload,
  })
}
const changeTransportationPrice = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_SET_ASSEMBLY_TRANSPORTATION_PRICE,
    payload,
  })
}
const changeNumberOfUniqueParts = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_SET_NUMBER_OF_UNIQUE_PARTS,
    payload,
  })
}

const removeItem = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_REMOVE_ITEM,
    payload,
  })
}
const removeMultipleItems = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_REMOVE_MULTIPLE_ITEMS,
    payload,
  })
}
const applyMultipleItems = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_APPLY_MULTIPLE_ITEMS,
    payload,
  })
}

const changeOrderPrice = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_UPDATE_ORDER_PRICE,
    payload: payload
  })
}
const updatePartData = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_UPDATE_PART_DATA,
    payload: payload
  })
  dispatch({
    type: ORDER_INCREMENT_PROCESSED,
    payload: null
  })
}
const updatePartFilePath = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_UPDATE_PART_FILE_PATH,
    payload: payload
  })
}

const uploadPartDrawing = payload => dispatch => {
  const formData = new FormData()
  formData.set('file', payload.file)

  return http
    .formPost(`${API_BASE_URL}/order-part/${payload.orderId}/add-document-to-part/${payload.orderPartId}/${payload.partType}`, formData)
    .then((response) => {
      let data = response.data
      dispatch({
        type: ORDER_PARTS_ADD_PART_DRAWING,
        payload: {
          orderPartId: payload.orderPartId,
          data: {
            documentId: data.documentId,
            drawingPDFFileName: data.drawingPDFFileName,
            drawingPDFFilePath: data.drawingPDFFilePath,
          }
        }
      })
      return {
        documentId: data.documentId,
        drawingPDFFileName: data.drawingPDFFileName,
        drawingPDFFilePath: data.drawingPDFFilePath
      };
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error
      }
    })

}
const removePartDrawing = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_REMOVE_PART_DRAWING,
    payload: {
      orderPartId: payload.orderPartId,
      documentId: payload.documentId
    }
  })
  return http
    .delete(`${API_BASE_URL}/order-part/${payload.orderId}/remove-document-from-part/${payload.orderPartId}/${payload.partType}/${payload.documentId}`)
    .then(() => {
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })

}

const setAssemblyPartData = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_SET_ASSEMBLY_PARTS_DATA,
    payload: payload.parts
  })

  dispatch({
    type: ORDER_PARTS_SET_ASSEMBLY_DATA,
    payload: payload.assemblyData
  })

  dispatch({
    type: ORDER_INCREMENT_PROCESSED,
    payload: null
  })
}
const updateAssemblyPartData = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_UPDATE_PART,
    payload: payload.data
  })
}
const updateAssemblyPrice = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_UPDATE_ASSEMBLY_PRICE,
    payload: payload
  })
}
const updateAssemblyQuantity = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_CHANGE_ASSEMBLY_QUANTITY,
    payload: payload
  })
}
const updateManyAssembliesPrices = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_UPDATE_MANY_ASSEMBLIES_PRICES,
    payload: payload
  })
}
const deleteEntireAssembly = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_DELETE_ENTIRE_ASSEMBLY,
    payload: payload
  })
}

const updatePartAnalysedStatus = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_UPDATE_PART_ANALYSED_STATUS,
    payload: payload
  })
}
const updatePartProcessingType = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_CHANGE_PART_PROCESSING_TYPE,
    payload: payload
  })
}

const resetToProcess = payload => dispatch => {
  dispatch({
    type: ORDER_RESET_TO_PROCESS,
    payload,
  })
}

const changeItemTolerance = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_CHANGE_PART_TOLERANCE,
    payload,
  })
}

const changePartToleranceData = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_CHANGE_PART_TOLERANCE_DATA,
    payload,
  })
}

const changeItemRoughness = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_CHANGE_PART_ROUGHNESS,
    payload,
  })
}

const changePartRoughnessData = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_CHANGE_PART_ROUGHNESS_DATA,
    payload,
  })
}

const changePartMaterialCertificateData = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_CHANGE_PART_MATERIAL_CERTIFICATE_DATA,
    payload,
  })
}

const changeManualItemTolerance = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_CHANGE_MANUAL_PART_TOLERANCE,
    payload,
  })
}

const changeManualItemRoughness = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_CHANGE_MANUAL_PART_ROUGHNESS,
    payload,
  })
}
//---------------------------------------------------//

//------------------ DRAFT ACTIONS ------------------//
const changeStageToShipping = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
    payload: true
  })

  dispatch({
    type: ORDER_SET_DRAFT_STAGE,
    payload: "shipping"
  })

  return http
    .post(`${API_BASE_URL}/order/draft/${payload.orderId}/stage`, {stage: "shipping"})
    .then(response => {
      dispatch({
        type: ORDER_SET_SHIPPING_DATA,
        payload: response.data
      })
      dispatch({
        type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const changeStageToConfirmation = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
    payload: true
  })

  dispatch({
    type: ORDER_SET_DRAFT_STAGE,
    payload: "confirmation"
  })

  return http
    .post(`${API_BASE_URL}/order/draft/${payload.orderId}/stage`, {stage: "confirmation", shippingData: payload.shippingData})
    .then(() => {
      dispatch({
        type: ORDER_SET_SHIPPING_DATA,
        payload: payload.shippingData
      })
      dispatch({
        type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
        payload: false
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const changeToPreviousStage = payload => dispatch => {
  dispatch({
    type: ORDER_SET_DRAFT_STAGE,
    payload: payload.stage
  })

  return http
    .post(`${API_BASE_URL}/order/draft/${payload.orderId}/stage`, {stage: payload.stage})
    .then(() => {

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const changeDeliveryDate = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_CHANGING_DELIVERY_DATE,
    payload: true,
  })

  return http
    .post(`${API_BASE_URL}/order/draft/${payload.orderId}/deliveryDate`, {deliveryDate: payload.deliveryDate})
    .then(() => {

      dispatch({
        type: ORDER_SET_DELIVERY_DATE,
        payload: payload.dateTimestamp,
      })

      dispatch({
        type: ORDERS_SET_IS_CHANGING_DELIVERY_DATE,
        payload: false,
      })

    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DELIVERY_DATE,
          payload: false,
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }

    })
}
const placeOrder = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
    payload: true
  })

  return http
    .get(`${API_BASE_URL}/order/draft/${payload.orderId}/finalize`)
    .then(() => {
      dispatch({
        type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
        payload: false
      })
      message.show(message.type.success, "Quote has been forwarded.")
      history.push(`/`)
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else if (error?.response?.status === 437) {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
          payload: false
        })
        throw error
      } else {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DRAFT_STAGE,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const changePartPrice = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_UPDATE_PART_PRICE,
    payload: payload
  })
}
const changeManyPartPrices = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_UPDATE_MANY_PART_PRICES,
    payload: payload
  })
}
const changeDraftOrderName = payload => dispatch => {
  dispatch({
    type: ORDERS_SET_IS_CHANGING_DRAFT_NAME,
    payload: true
  })

  return http
    .post(`${API_BASE_URL}/order/draft/${payload.orderId}/custom-name`, {customName: payload.name})
    .then(() => {
      dispatch({
        type: ORDERS_SET_IS_CHANGING_DRAFT_NAME,
        payload: false
      })
      dispatch({
        type: ORDER_SET_DRAFT_NAME,
        payload: payload.name
      })
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        dispatch({
          type: ORDERS_SET_IS_CHANGING_DRAFT_NAME,
          payload: false
        })
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const confirmAdjustmentChange = payload => dispatch => {
  return http
    .get(`${API_BASE_URL}/order/draft/${payload.orderId}/adjustment-seen`)
    .then(() => {
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
      }
    })
}
const changeAdjustments = payload => dispatch => {
  dispatch({
    type: ORDER_SET_CHANGED_ADJUSTMENT,
    payload: payload
  })
}
//---------------------------------------------------//

//------------------ BULK ACTIONS ------------------//
const clearBulkOrder = () => dispatch => {
  return http
    .get(`${API_BASE_URL}/internal-order/clear-draft`)
    .then(res => {
      const emptyInternalOrder = res.data.internalOrder

      const sheetMetalMaterialMap = {}
      const machiningMaterialMap = {}
      for(let material of emptyInternalOrder.materials.sheetMetal) {
        for(let grade of material.grades) {
          sheetMetalMaterialMap[grade.grade] = {
            grade: grade.grade,
            groupName: material.groupName,
            thicknesses: {},
            thickness: grade.thickness
          }
          for(let t of grade.thickness) {
            sheetMetalMaterialMap[grade.grade].thicknesses[t.thickness] = {id: t.id, thickness: t.thickness}
          }
        }
      }

      for(let material of emptyInternalOrder.materials.machining) {
        for(let grade of material.grades) {
          machiningMaterialMap[grade.id] = {
            id: grade.id,
            grade: grade.grade
          }
        }
      }

      let order = {
        ...emptyInternalOrder,
        sheetMetalMaterialMap: sheetMetalMaterialMap,
        machiningMaterialMap: machiningMaterialMap
      }

      dispatch({
        type: ORDER_CLEAR_ORDER,
        payload: emptyInternalOrder
      });

      return order
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}

const clearProducerOrder = payload => dispatch => {
  return http
    .get(`${API_BASE_URL}/producer-order/clear-draft`)
    .then((res) => {
      const emptyProducerOrder = res.data.producerOrder

      const sheetMetalMaterialMap = {}
      const machiningMaterialMap = {}
      for(let material of emptyProducerOrder.materials.sheetMetal) {
        for(let grade of material.grades) {
          sheetMetalMaterialMap[grade.grade] = {
            grade: grade.grade,
            groupName: material.groupName,
            thicknesses: {},
            thickness: grade.thickness
          }
          for(let t of grade.thickness) {
            sheetMetalMaterialMap[grade.grade].thicknesses[t.thickness] = {id: t.id, thickness: t.thickness}
          }
        }
      }

      for(let material of emptyProducerOrder.materials.machining) {
        for(let grade of material.grades) {
          machiningMaterialMap[grade.id] = {
            id: grade.id,
            grade: grade.grade
          }
        }
      }

      let order = {
        ...emptyProducerOrder,
        sheetMetalMaterialMap: sheetMetalMaterialMap,
        machiningMaterialMap: machiningMaterialMap
      }

      dispatch({
        type: ORDER_CLEAR_ORDER,
        payload: emptyProducerOrder
      });

      return order
    })
    .catch(error => {
      if(error?.response?.status === 401) {
        dispatch(forceLogoutUser())
      } else {
        message.show(message.type.error, http.getErrorMessage(error))
        throw error;
      }
    })
}

const changeManyPartData = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_UPDATE_MANY_PART_DATA,
    payload: payload
  })
}
const changePartData = payload => dispatch => {
  dispatch({
    type: ORDER_PARTS_CHANGE_PART_DATA,
    payload: payload
  })
}
//--------------------------------------------------//


export {
  uploadNewPart,
  changeItemColor, changeStandard, removeItemColor,
  changeItemQuantity, changeItemNote, changeItemMaterial,
  changePartMachiningMaterial, changePartMaterialCertificate, changePartTubeCuttingMaterial,
  changeItemThickness,
  changeWeldingPrice, changeAssemblingPrice,
  changePackagingPrice, changeTransportationPrice,
  changeNumberOfUniqueParts,
  removeItem, removeMultipleItems, applyMultipleItems,
  changeOrderPrice, updatePartData, updatePartFilePath,
  uploadPartDrawing, removePartDrawing,
  setAssemblyPartData, updateAssemblyPartData, updateAssemblyPrice,
  updateAssemblyQuantity, updateManyAssembliesPrices, deleteEntireAssembly,
  updatePartAnalysedStatus, updatePartProcessingType,
  resetToProcess, changeItemTolerance, changePartToleranceData,
  changeItemRoughness, changePartRoughnessData, changeManualItemTolerance,
  changeManualItemRoughness, changePartMaterialCertificateData,

  changeStageToShipping, changeStageToConfirmation, changeToPreviousStage,
  changeDeliveryDate,
  placeOrder,
  changePartPrice, changeManyPartPrices,
  changeDraftOrderName,
  confirmAdjustmentChange, changeAdjustments,

  clearBulkOrder, changeManyPartData, changePartData, clearProducerOrder
}