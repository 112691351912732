import React  from "react";
import {Input, Tooltip} from "antd";
import HelpIcon from '@mui/icons-material/Help';

export const DraftOrderHeader = (props) => {

  const {
    order, changeOrderName
  } = props;

  return (
    <div style={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
      <div style={{display: 'flex', alignItems: "center", minWidth: 0}}>
        <div
          className={"text14-500"}
          style={{fontWeight: 600, fontSize: 20, paddingRight: 5, whiteSpace: "nowrap"}}
        >
          <span style={{ color: "#ff5b2d"}}>Quote</span> {order.name}
        </div>
        <div
          className={"ellipsisText orderRef"}
          style={{ paddingLeft: 15, color: "white" }}
        >
          {order.customName}
        </div>
      </div>

      <div style={{marginRight: 20, display: 'flex', alignItems: "center"}}>
        <div style={{ color: "white", marginRight: 4, fontWeight: 600, whiteSpace: "nowrap", marginLeft: 40 }}>
          Custom Quote Name:
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
          <Input
            size={'large'}
            className={"text12-600"}
            placeholder={"e.g. My Quote 01"}
            defaultValue={order.customName || ""}
            onChange={(e) => {
              changeOrderName({name: e.target.value, orderId: order.id})
            }}
          />
          <Tooltip
            title={
              <span
                className={"text12-500"}
                style={{color: "black"}}
              >
                Custom quote names are used to help you keep track of your quotes more easily.
              </span>
            }
            placement={"topRight"}
            color={"#d6d6d6"}
          >
            <HelpIcon style={{fontSize: 22, marginLeft: 5, color: "#ff5b2d"}}/>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}