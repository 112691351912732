import React from "react";
import {Button, Col, Divider, Form, Input, InputNumber, Row} from "antd";

export const Assembly = (props) => {
  const {item, addNewPart, isAddingNewPart, assemblyData} = props;
  const [form] = Form.useForm();

  const setTotalPrice = () => {
    form.setFieldsValue({
      "pricePerPiece": Number((
        form.getFieldValue("assemblingPrice") + form.getFieldValue("weldingPrice") +
        form.getFieldValue("packagingPrice") + form.getFieldValue("transportationPrice") +
        form.getFieldValue("productionPrice")
      ).toFixed(2))
    })
  }

  return (
    <div>
      <Form
        form={form}
        className={"manualQuoteForm"}
        name={"manualQuoteOther"}
        onFinish={(values) => {
          const {productionPrice, ...data} = values;
          addNewPart({
            partType: "assembly",
            partId: item?.id,
            data: {
              ...data,
              uploadTime: Date.now(),
              uploadIndex: 1,
            }
          })
        }}
      >
        <Row>
          <Col span={8}>
            <div style={{ marginRight: 10 }}>
              <div style={{display: "flex"}}>
                <div style={{fontWeight: 500, fontSize: 13}}>Name:</div>
              </div>
              <Form.Item
                name={"name"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item?.fileName || null}
              >
                <Input
                  className={"quoteModalName"}
                  style={{width: "100%", height: 32}}
                  placeholder={'eg. Assembly 01'}
                  disabled={!!item}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              <div style={{fontWeight: 500, fontSize: 13}}>Number of Assemblies:</div>
              <Form.Item
                name={"quantity"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={item ? item?.quantity : 1}
              >
                <InputNumber
                  min={0}
                  max={1000000}
                  style={{width: "100%"}}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <div style={{fontWeight: 500, fontSize: 13}}>Number of Unique Parts:</div>
              <Form.Item
                name={"numberOfUniqueParts"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={1}
              >
                <InputNumber
                  min={0}
                  max={1000000}
                  style={{width: "100%"}}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between", marginBottom: 10}}>
              <Divider className={"lightDivider"} style={{ margin: "2px 6px 0 0", minWidth: "0%" }}/>
              <div style={{whiteSpace: "nowrap"}}>Price Breakdown <b>Per Assembly</b></div>
              <Divider className={"lightDivider"} style={{ margin: "2px 0 0 6px", minWidth: "0%" }}/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              <div style={{fontWeight: 500, fontSize: 13}}>Assembling Price:</div>
              <Form.Item
                name={"assemblingPrice"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={0}
              >
                <InputNumber
                  min={0}
                  max={1000000}
                  style={{width: "100%"}}
                  onChange={(value) => setTotalPrice()}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              <div style={{fontWeight: 500, fontSize: 13}}>Welding Price:</div>
              <Form.Item
                name={"weldingPrice"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={0}
              >
                <InputNumber
                  min={0}
                  max={1000000}
                  style={{width: "100%"}}
                  onChange={(value) => setTotalPrice()}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <div style={{fontWeight: 500, fontSize: 13}}>Machine Processing Price:</div>
              <Form.Item
                name={"packagingPrice"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={0}
              >
                <InputNumber
                  min={0}
                  max={1000000}
                  style={{width: "100%"}}
                  onChange={(value) => setTotalPrice()}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              <div style={{fontWeight: 500, fontSize: 13}}>Others:</div>
              <Form.Item
                name={"transportationPrice"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={0}
              >
                <InputNumber
                  min={0}
                  max={1000000}
                  style={{width: "100%"}}
                  onChange={(value) => setTotalPrice()}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div style={{marginRight: 10}}>
              <div style={{fontWeight: 500, fontSize: 13}}>Production Price:</div>
              <Form.Item
                name={"productionPrice"}
                rules={[
                  {required: true, message: 'This field is required'},
                ]}
                initialValue={0}
              >
                <InputNumber
                  min={0}
                  max={1000000}
                  style={{width: "100%"}}
                  onChange={(value) => setTotalPrice()}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <div style={{fontWeight: 500, fontSize: 13}}>Total Price (auto-fill):</div>
              <Form.Item
                name={"pricePerPiece"}
                className={"pppField"}
                initialValue={0}
              >
                <InputNumber
                  style={{width: "100%"}}
                  disabled={true}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider className={"lightDivider"} style={{ margin: "-6px 0 12px 0" }}/>
        <div align={"right"}>
          <Button
            type={"default"}
            onClick={()=>{props.setManualQuoteStep(1)}}
            style={{fontWeight: 500, fontSize: 13}}
            disabled={isAddingNewPart}
          >
            Back
          </Button>
          <Button
            type={"primary"}
            htmlType={"submit"}
            style={{marginLeft: 5, fontWeight: 500, fontSize: 13}}
            loading={isAddingNewPart}
          >
            {!!item ? "Quote Part" : "Add Part"}
          </Button>
        </div>
      </Form>
    </div>
  )
}