import React, {useEffect, useState} from "react";
import {Button, Form, InputNumber, List, Select, Spin, Tag, Tooltip, Upload} from "antd";
import {
  CaretDownOutlined,
  CheckOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, FilePdfOutlined,
  LoadingOutlined, MinusOutlined
} from "@ant-design/icons";

import {CoatingPicker} from "../../../../../CoatingPicker";
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@mui/icons-material/Add";
import {DrawingIcon2} from "../../../../../../../assets/icons/drawing2";
import {
  calcAdjustment, getApplicableRoughnesses,
  getApplicableTolerances, getRoughnesses, getRoughnessValue,
  getTolerances,
  getToleranceValue
} from "../../../../../../../utils/utility";
import {useDispatch, useSelector} from "react-redux";
import {removePartDrawing, uploadPartDrawing} from "../../../../../../../actions/orderActions";
import {PartPriceTooltip} from "./PartPriceTooltip";
import {ka} from "date-fns/locale";

const {Option} = Select;

export const PartFeaturesArea = (props) => {
  const {
    item, changePartColor, isOrderDetails, handleColorRemove,
    openDocumentsView, bulkItem, handleOpenPricesModal,
    assemblyCount, changePrice, selectedProfile, adjustment,
    ptAutomatic, orderId, changePartTolerance, changePartRoughness,
    changeManualPartTolerance, changeManualPartRoughness
  } = props;

  const producersForOrder = useSelector(state => state.orders.producersForOrder)
  const isProducer = useSelector(state => state.auth.user.role === "producerAdmin")

  const [uploadingDrawing, setUploadingDrawing] = useState(false)
  const [itemQuantity, setItemQuantity] = useState(1);

  const dispatch = useDispatch()

  useEffect(() => {
    setItemQuantity((item.quantity * (assemblyCount || 1)))
  }, [item.quantity, assemblyCount])

  const addPartDrawing = (file) => {
    setUploadingDrawing(true)
    if (bulkItem) {
      dispatch(uploadPartDrawing({
        orderId: orderId,
        orderPartId: item.id,
        partType: "part",
        file: file
      })).then(() => {
        setUploadingDrawing(false)
      }).catch(() => {
        setUploadingDrawing(false)
      })
    } else {
      dispatch(uploadPartDrawing({
        orderId: orderId,
        orderPartId: item.id,
        partType: "part",
        file: file
      })).then(() => {
        setUploadingDrawing(false)
      })
    }
  }

  const handleRemovePartDrawing = (documentId) => {
    if (bulkItem) {
      dispatch(removePartDrawing({
        orderId: orderId,
        orderPartId: item.id,
        partType: "part",
        documentId: documentId
      }))
    } else {
      dispatch(removePartDrawing({
        orderId: orderId,
        orderPartId: item.id,
        partType: "part",
        documentId: documentId
      }))
    }
  }

  const sheetMetalFeatures = () => {
    return (
      <div>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 0, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div style={{marginTop: 4}}>
              <span>
                {item.coating?.hasCoating ?
                  <CheckOutlined style={{color: '#00C853'}}/>
                  :
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                }
              </span>
              <span
                className={"text14-400"}
                style={
                  item.coating?.hasCoating ?
                    {fontWeight: 500, marginLeft: 8}
                    :
                    {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Surface Finish
              </span>
            </div>
            <div style={{marginRight: 6, marginTop: 0, marginBottom: -1}}>
              <div style={{display: 'flex', justifyContent: "space-between"}}>
                <div style={{display: "flex", minWidth: 186}}>
                  <CoatingPicker
                    partId={item.id}
                    partType={item.partType}
                    changeColor={changePartColor}
                    hasCoating={item.coating?.hasCoating}
                    type={item.coating?.type}
                    colorRAL={item.coating?.data?.colorRAL}
                    colorGlossId={item.coating?.data?.colorGlossId}
                    ralRow={item.coating?.data?.ralRow}
                    ralHex={item.coating?.data?.ralHex}
                    isMultiEdit={false}
                    handleColorRemove={handleColorRemove}
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 3, paddingBottom: 4}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
              <span>
                {item?.selectedTolerance?.tolerance || (item.processingType === "manual" && item?.manualTolerance) ?
                  <CheckOutlined style={{color: '#00C853'}}/>
                  :
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                }
              </span>
              <Tooltip
                size={"small"}
                placement={"right"}
                title={!item.selectedTolerance?.tolerance ? <span className={"text12-600"}>>= 0.5mm</span> : null}
              >
              <span
                className={"text14-400"}
                style={
                  item?.selectedTolerance?.tolerance || (item.processingType === "manual" && item?.manualTolerance) ?
                    {fontWeight: 500, marginLeft: 8}
                    :
                    {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Max Tolerance
              </span>
              </Tooltip>
            </div>
            <div style={{marginRight: 6}}>
              {ptAutomatic ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                  {item.selectedMaterial?.isAvailable === false &&
                    <Tooltip
                      color={"#ffc107"}
                      overlayClassName={"small-tooltip"}
                      size={"small"}
                      title={<span className={"text12-500"} style={{ color: "#333f48" }}>Tolerance can't be changed because the material is no longer available.</span>}
                    >
                      <ExclamationCircleFilled style={{ color: "#ffc107", fontSize: 13, marginRight: 8, position: "relative" }} />
                    </Tooltip>
                  }
                  {item.selectedMaterial?.isAvailable === true && item.toleranceChanged &&
                    <Tooltip
                      color={"#ffc107"}
                      overlayClassName={"small-tooltip"}
                      size={"small"}
                      title={<span className={"text12-500"} style={{ color: "#333f48" }}>Selected tolerance can't be chosen in combination with the material, please choose a different tolerance.</span>}
                    >
                      <ExclamationCircleFilled style={{ color: "#ffc107", fontSize: 13, marginRight: 8, position: "relative" }} />
                    </Tooltip>
                  }
                  <div>
                    <Tooltip
                      size={"small"}
                      placement={"left"}
                      title={!item.selectedTolerance?.tolerance ? <span className={"text12-600"}>>= 0.5mm</span> : null}
                    >
                    <Select
                      style={{ width: 186, height: 28 }}
                      className={"muiInput partToleranceSelect"}
                      placeholder={"No Specific Tolerance"}
                      getPopupContainer={trigger => trigger.parentNode}
                      suffixIcon={<CaretDownOutlined />}
                      disabled={!item.selectedTolerance?.maxTolerance || item.selectedMaterial?.isAvailable === false}
                      allowClear={item.selectedTolerance?.tolerance}
                      onChange={(value) => {
                        changePartTolerance({
                          id: item.id,
                          partType: item.partType,
                          materialId: item.selectedMaterial?.materialId,
                          tolerance: value || null
                        })
                      }}
                      value={item.selectedTolerance?.tolerance}
                    >
                      {getTolerances().map((tolerance, index) => {
                        return (
                          getApplicableTolerances({tolerance, maxTolerance: item.selectedTolerance?.maxTolerance}) &&
                          <Option
                            key={index}
                            value={tolerance}
                            className={"toleranceOption"}
                          >
                            {item.selectedMaterial?.isAvailable === false ?
                              <span style={{color: "#ffc107"}}>{getToleranceValue(tolerance, "other")}</span>
                              :
                              <span>{getToleranceValue(tolerance, "other")}</span>
                            }
                          </Option>
                        )
                      })}
                    </Select>
                    </Tooltip>
                    </div>
                </div>
                :
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <div>
                    <Tooltip
                      size={"small"}
                      placement={"left"}
                      title={!item.selectedTolerance?.tolerance ? <span className={"text12-600"}>>= 0.5mm</span> : null}
                    >
                    <Select
                      style={{ width: 186, height: 28 }}
                      className={"muiInput partToleranceSelect"}
                      placeholder={"No Specific Tolerance"}
                      getPopupContainer={trigger => trigger.parentNode}
                      suffixIcon={<CaretDownOutlined />}
                      allowClear={item.manualTolerance}
                      onChange={(value) => {
                        changeManualPartTolerance({
                          id: item.id,
                          tolerance: value || null
                        })
                      }}
                      value={item.manualTolerance}
                    >
                      {getTolerances().map((tolerance, index) => {
                        return (
                          <Option
                            key={index}
                            value={tolerance}
                            className={"toleranceOption"}
                          >
                            <span>{getToleranceValue(tolerance, "other")}</span>
                          </Option>
                        )
                      })}
                    </Select>
                    </Tooltip>
                    </div>
                </div>
              }
            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 4, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
                <span>
                  {item?.stepData?.partData?.hasBends ?
                    <CheckOutlined style={{color: '#00C853'}}/>
                    :
                    <MinusOutlined style={{color: '#bdbdbd'}}/>
                  }
                </span>
              <span
                className={"text14-400"}
                style={item?.stepData?.partData?.hasBends ?
                  {fontWeight: 500, marginLeft: 8}
                  :
                  {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Bending
              </span>
            </div>
            <div style={{marginRight: 10}}>
              {item?.stepData?.partData?.hasBends &&
              <span className={"text12-500"}>Count: {item?.stepData?.partData?.numberOfBends}</span>
              }
            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 4, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
              <span>
                {item?.stepData?.partData?.numOfMachinedHoles ?
                  <CheckOutlined style={{color: '#00C853'}}/>
                  :
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                }
              </span>
              <span
                className={"text14-400"}
                style={
                  item?.stepData?.partData?.numOfMachinedHoles ?
                    {fontWeight: 500, marginLeft: 8}
                    :
                    {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Countersinking
              </span>
            </div>
            <div style={{marginRight: 10}}>
              {item?.stepData?.partData?.numOfMachinedHoles ?
                <span className={"text12-500"}>Count: {item.stepData.partData.numOfMachinedHoles}</span> : ""
              }
            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 4, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
              <span>
                {item?.stepData?.partData?.numOfSlopedEdges ?
                  <CheckOutlined style={{color: '#00C853'}}/>
                  :
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                }
              </span>
              <span
                className={"text14-400"}
                style={
                  item?.stepData?.partData?.numOfSlopedEdges ?
                    {fontWeight: 500, marginLeft: 8}
                    :
                    {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Sloped Edges
              </span>
            </div>
            <div style={{marginRight: 10}}>
              {item?.stepData?.partData?.numOfSlopedEdges ?
                <span className={"text12-500"}>Count: {item.stepData.partData.numOfSlopedEdges}</span> : ""
              }
            </div>
          </div>
        </List.Item>
      </div>
    )
  }

  const millingAndLathingFeatures = () => {
    return (
      <div>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 0, paddingBottom: 5}}  >
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div style={{marginTop: 4}}>
              <span>
                {item.coating?.hasCoating ?
                  <CheckOutlined style={{color: '#00C853'}}/>
                  :
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                }
              </span>
              <span
                className={"text14-400"}
                style={
                  item.coating?.hasCoating ?
                    {fontWeight: 500, marginLeft: 8}
                    :
                    {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Surface Finish
              </span>
            </div>
            <div style={{marginRight: 6, marginTop: 0, marginBottom: -1}}>
              <div style={{display: 'flex', justifyContent: "space-between"}}>
                <div style={{display: "flex", minWidth: 186}}>
                  <CoatingPicker
                    partId={item.id}
                    partType={item.partType}
                    changeColor={changePartColor}
                    hasCoating={item.coating?.hasCoating}
                    type={item.coating?.type}
                    colorRAL={item.coating?.data?.colorRAL}
                    colorGlossId={item.coating?.data?.colorGlossId}
                    ralRow={item.coating?.data?.ralRow}
                    ralHex={item.coating?.data?.ralHex}
                    isMultiEdit={false}
                    handleColorRemove={handleColorRemove}
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 3, paddingBottom: 4}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
              <span>
                {item?.selectedTolerance?.tolerance || (item.processingType === "manual" && item?.manualTolerance) ?
                  <CheckOutlined style={{color: '#00C853'}}/>
                  :
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                }
              </span>
              <Tooltip
                size={"small"}
                placement={"right"}
                title={!item.selectedTolerance?.tolerance ? <span className={"text12-600"}>>= 0.1mm</span> : null}
              >
              <span
                className={"text14-400"}
                style={
                  item?.selectedTolerance?.tolerance || (item.processingType === "manual" && item?.manualTolerance) ?
                    {fontWeight: 500, marginLeft: 8}
                    :
                    {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Max Tolerance
              </span>
              </Tooltip>
            </div>
            <div style={{marginRight: 6}}>
              {ptAutomatic ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                  {item.selectedMaterial?.isAvailable === false &&
                    <Tooltip
                      color={"#ffc107"}
                      overlayClassName={"small-tooltip"}
                      size={"small"}
                      title={<span className={"text12-500"} style={{ color: "#333f48" }}>Tolerance can't be changed because the material is no longer available.</span>}
                    >
                      <ExclamationCircleFilled style={{ color: "#ffc107", fontSize: 13, marginRight: 8, position: "relative" }} />
                    </Tooltip>
                  }
                  {item.selectedMaterial?.isAvailable === true && item.toleranceChanged &&
                    <Tooltip
                      color={"#ffc107"}
                      overlayClassName={"small-tooltip"}
                      size={"small"}
                      title={<span className={"text12-500"} style={{ color: "#333f48" }}>Selected tolerance can't be chosen in combination with the material, please choose a different tolerance.</span>}
                    >
                      <ExclamationCircleFilled style={{ color: "#ffc107", fontSize: 13, marginRight: 8, position: "relative" }} />
                    </Tooltip>
                  }
                  <div>
                    <Tooltip
                      size={"small"}
                      placement={"left"}
                      title={!item.selectedTolerance?.tolerance ? <span className={"text12-600"}>>= 0.1mm</span> : null}
                    >
                    <Select
                      style={{ width: 186, height: 28 }}
                      className={"muiInput partToleranceSelect"}
                      placeholder={"No Specific Tolerance"}
                      getPopupContainer={trigger => trigger.parentNode}
                      suffixIcon={<CaretDownOutlined />}
                      disabled={!item.selectedTolerance?.maxTolerance || item.selectedMaterial?.isAvailable === false}
                      allowClear={item.selectedTolerance?.tolerance}
                      onChange={(value) => {
                        changePartTolerance({
                          id: item.id,
                          partType: item.partType,
                          materialId: item.selectedMaterial?.materialId,
                          tolerance: value || null
                        })
                      }}
                      value={item.selectedTolerance?.tolerance}
                    >
                      {getTolerances().map((tolerance, index) => {
                        return (
                          getApplicableTolerances({tolerance, maxTolerance: item.selectedTolerance?.maxTolerance}) &&
                          <Option
                            key={index}
                            value={tolerance}
                            className={"toleranceOption"}
                          >
                            {item.selectedMaterial?.isAvailable === false ?
                              <span style={{color: "#ffc107"}}>{getToleranceValue(tolerance, "machined")}</span>
                              :
                              <span>{getToleranceValue(tolerance, "machined")}</span>
                            }
                          </Option>
                        )
                      })}
                    </Select>
                    </Tooltip>
                  </div>
                </div>
                :
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <div>
                    <Tooltip
                      size={"small"}
                      placement={"left"}
                      title={!item.selectedTolerance?.tolerance ? <span className={"text12-600"}>>= 0.1mm</span> : null}
                    >
                    <Select
                      style={{ width: 186, height: 28 }}
                      className={"muiInput partToleranceSelect"}
                      placeholder={"No Specific Tolerance"}
                      getPopupContainer={trigger => trigger.parentNode}
                      suffixIcon={<CaretDownOutlined />}
                      allowClear={item.manualTolerance}
                      onChange={(value) => {
                        changeManualPartTolerance({
                          id: item.id,
                          tolerance: value || null
                        })
                      }}
                      value={item.manualTolerance}
                    >
                      {getTolerances().map((tolerance, index) => {
                        return (
                          <Option
                            key={index}
                            value={tolerance}
                            className={"toleranceOption"}
                          >
                            <span>{getToleranceValue(tolerance, "machined")}</span>
                          </Option>
                        )
                      })}
                    </Select>
                    </Tooltip>
                    </div>
                </div>
              }
            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 4, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
              <span>
                {item?.selectedRoughness?.roughness || (item.processingType === "manual" && item?.manualRoughness) ?
                  <CheckOutlined style={{color: '#00C853'}}/>
                  :
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                }
              </span>
              <span
                className={"text14-400"}
                style={
                  item?.selectedRoughness?.roughness || (item.processingType === "manual" && item?.manualRoughness) ?
                    {fontWeight: 500, marginLeft: 8}
                    :
                    {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Surface Roughness
              </span>
            </div>
            <div style={{marginRight: 6}}>
              {ptAutomatic ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                  {item.selectedMaterial?.isAvailable === false &&
                    <Tooltip
                      color={"#ffc107"}
                      overlayClassName={"small-tooltip"}
                      size={"small"}
                      title={<span className={"text12-500"} style={{ color: "#333f48" }}>Roughness can't be changed because the material is no longer available.</span>}
                    >
                      <ExclamationCircleFilled style={{ color: "#ffc107", fontSize: 13, marginRight: 8, position: "relative" }} />
                    </Tooltip>
                  }
                  {item.selectedMaterial?.isAvailable === true && item.toleranceChanged &&
                    <Tooltip
                      color={"#ffc107"}
                      overlayClassName={"small-tooltip"}
                      size={"small"}
                      title={<span className={"text12-500"} style={{ color: "#333f48" }}>Selected roughness can't be chosen in combination with the material, please choose a different roughness.</span>}
                    >
                      <ExclamationCircleFilled style={{ color: "#ffc107", fontSize: 13, marginRight: 8, position: "relative" }} />
                    </Tooltip>
                  }
                  <div>
                    <Select
                      style={{ width: 186, height: 28 }}
                      className={"muiInput partToleranceSelect"}
                      placeholder={"No Specific Roughness"}
                      getPopupContainer={trigger => trigger.parentNode}
                      suffixIcon={<CaretDownOutlined />}
                      disabled={!item.selectedRoughness?.maxRoughness || item.selectedMaterial?.isAvailable === false}
                      allowClear={item.selectedRoughness?.roughness}
                      onChange={(value) => {
                        changePartRoughness({
                          id: item.id,
                          partType: item.partType,
                          materialId: item.selectedMaterial?.materialId,
                          roughness: value || null
                        })
                      }}
                      value={item.selectedRoughness?.roughness}
                    >
                      {getRoughnesses().map((roughness, index) => {
                        return (
                          getApplicableRoughnesses({roughness, maxRoughness: item.selectedRoughness?.maxRoughness}) &&
                          <Option
                            key={index}
                            value={roughness}
                            className={"toleranceOption"}
                          >
                            {item.selectedMaterial?.isAvailable === false ?
                              <span style={{color: "#ffc107"}}>{getRoughnessValue(roughness)}</span>
                              :
                              <span>{getRoughnessValue(roughness)}</span>
                            }
                          </Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
                :
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <div>
                    <Select
                      style={{ width: 186, height: 28 }}
                      className={"muiInput partToleranceSelect"}
                      placeholder={"No Specific Roughness"}
                      getPopupContainer={trigger => trigger.parentNode}
                      suffixIcon={<CaretDownOutlined />}
                      allowClear={item.manualRoughness}
                      onChange={(value) => {
                        changeManualPartRoughness({
                          id: item.id,
                          roughness: value || null
                        })
                      }}
                      value={item.manualRoughness}
                    >
                      {getRoughnesses().map((roughness, index) => {
                        return (
                          <Option
                            key={index}
                            value={roughness}
                            className={"toleranceOption"}
                          >
                            <span>{getRoughnessValue(roughness)}</span>
                          </Option>
                        )
                      })}
                    </Select>
                    </div>
                </div>
              }
            </div>
          </div>
        </List.Item>
      </div>
    )
  }

  const tubeAndProfileFeatures = () => {
    return (
      <div>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 0, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div style={{marginTop: 4}}>
              <span>
                {item.coating?.hasCoating ?
                  <CheckOutlined style={{color: '#00C853'}}/>
                  :
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                }
              </span>
              <span
                className={"text14-400"}
                style={
                  item.coating?.hasCoating ?
                    {fontWeight: 500, marginLeft: 8}
                    :
                    {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Surface
              </span>
            </div>
            <div style={{marginRight: 6, marginTop: 0, marginBottom: -1}}>
              <div style={{display: 'flex', justifyContent: "space-between"}}>
                <div style={{display: "flex", minWidth: 186}}>
                  <CoatingPicker
                    partId={item.id}
                    partType={item.partType}
                    changeColor={changePartColor}
                    hasCoating={item.coating?.hasCoating}
                    type={item.coating?.type}
                    colorRAL={item.coating?.data?.colorRAL}
                    colorGlossId={item.coating?.data?.colorGlossId}
                    ralRow={item.coating?.data?.ralRow}
                    ralHex={item.coating?.data?.ralHex}
                    isMultiEdit={false}
                    handleColorRemove={handleColorRemove}
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 3, paddingBottom: 4}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
              <span>
                {item?.selectedTolerance?.tolerance || (item.processingType === "manual" && item?.manualTolerance) ?
                  <CheckOutlined style={{color: '#00C853'}}/>
                  :
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                }
              </span>
              <Tooltip
                size={"small"}
                placement={"right"}
                title={!item.selectedTolerance?.tolerance ? <span className={"text12-600"}>>= 0.5mm</span> : null}
              >
              <span
                className={"text14-400"}
                style={
                  item?.selectedTolerance?.tolerance || (item.processingType === "manual" && item?.manualTolerance) ?
                    {fontWeight: 500, marginLeft: 8}
                    :
                    {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Max Tolerance
              </span>
              </Tooltip>
            </div>
            <div style={{marginRight: 6}}>
              {ptAutomatic ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                  {item.selectedMaterial?.isAvailable === false &&
                    <Tooltip
                      color={"#ffc107"}
                      overlayClassName={"small-tooltip"}
                      size={"small"}
                      title={<span className={"text12-500"} style={{ color: "#333f48" }}>Tolerance can't be changed because the material is no longer available.</span>}
                    >
                      <ExclamationCircleFilled style={{ color: "#ffc107", fontSize: 13, marginRight: 8, position: "relative" }} />
                    </Tooltip>
                  }
                  {item.selectedMaterial?.isAvailable === true && item.toleranceChanged &&
                    <Tooltip
                      color={"#ffc107"}
                      overlayClassName={"small-tooltip"}
                      size={"small"}
                      title={<span className={"text12-500"} style={{ color: "#333f48" }}>Selected tolerance can't be chosen in combination with the material, please choose a different tolerance.</span>}
                    >
                      <ExclamationCircleFilled style={{ color: "#ffc107", fontSize: 13, marginRight: 8, position: "relative" }} />
                    </Tooltip>
                  }
                  <div>
                    <Tooltip
                      size={"small"}
                      placement={"left"}
                      title={!item.selectedTolerance?.tolerance ? <span className={"text12-600"}>>= 0.5mm</span> : null}
                    >
                    <Select
                      style={{ width: 186, height: 28 }}
                      className={"muiInput partToleranceSelect"}
                      placeholder={"No Specific Tolerance"}
                      getPopupContainer={trigger => trigger.parentNode}
                      suffixIcon={<CaretDownOutlined />}
                      disabled={!item.selectedTolerance?.maxTolerance || item.selectedMaterial?.isAvailable === false}
                      allowClear={item.selectedTolerance?.tolerance}
                      onChange={(value) => {
                        changePartTolerance({
                          id: item.id,
                          partType: item.partType,
                          material: {
                            grade: item.selectedMaterial?.grade,
                            thickness: item.selectedMaterial?.thickness,
                            x: item.stepData.partData.tubeData.tubeDimensions[0],
                            y: item.stepData.partData.tubeData.tubeDimensions[1],
                            tubeType: item.stepData.partData.tubeData.tubeType
                          },
                          tolerance: value || null
                        })
                      }}
                      value={item.selectedTolerance?.tolerance}
                    >
                      {getTolerances().map((tolerance, index) => {
                        return (
                          getApplicableTolerances({tolerance, maxTolerance: item.selectedTolerance?.maxTolerance}) &&
                          <Option
                            key={index}
                            value={tolerance}
                            className={"toleranceOption"}
                          >
                            {item.selectedMaterial?.isAvailable === false ?
                              <span style={{color: "#ffc107"}}>{getToleranceValue(tolerance, "other")}</span>
                              :
                              <span>{getToleranceValue(tolerance, "other")}</span>
                            }
                          </Option>
                        )
                      })}
                    </Select>
                    </Tooltip>
                  </div>
                </div>
                :
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <div>
                    <Tooltip
                      size={"small"}
                      placement={"left"}
                      title={!item.selectedTolerance?.tolerance ? <span className={"text12-600"}>>= 0.5mm</span> : null}
                    >
                    <Select
                      style={{ width: 186, height: 28 }}
                      className={"muiInput partToleranceSelect"}
                      placeholder={"No Specific Tolerance"}
                      getPopupContainer={trigger => trigger.parentNode}
                      suffixIcon={<CaretDownOutlined />}
                      allowClear={item.manualTolerance}
                      onChange={(value) => {
                        changeManualPartTolerance({
                          id: item.id,
                          tolerance: value || null
                        })
                      }}
                      value={item.manualTolerance}
                    >
                      {getTolerances().map((tolerance, index) => {
                        return (
                          <Option
                            key={index}
                            value={tolerance}
                            className={"toleranceOption"}
                          >
                            <span>{getToleranceValue(tolerance, "other")}</span>
                          </Option>
                        )
                      })}
                    </Select>
                    </Tooltip>
                    </div>
                </div>
              }
            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 4, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
              <span>
                {selectedProfile ?
                  <CheckOutlined style={{color: '#00C853'}}/>
                  :
                  <MinusOutlined style={{color: '#bdbdbd'}}/>
                }
              </span>
              <span
                className={"text14-400"}
                style={selectedProfile ?
                  {fontWeight: 500, marginLeft: 8}
                  :
                  {color: '#bdbdbd', marginLeft: 8}
                }
              >
                Profile Base
              </span>
            </div>
            <div style={{ marginRight: -2, height: 22 }}>
              {selectedProfile ?
                <Tag color="rgb(29,34,37)"
                     style={{fontWeight: 600, textAlign: "center", minWidth: 90, paddingInline: 20}}
                >
                  {selectedProfile[0]} - {selectedProfile[2]}
                </Tag> : ""
              }
            </div>
          </div>
        </List.Item>
      </div>
    )
  }

  const bulkAndBuyFeatures = () => {
    return (
      <div>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 0, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div style={{marginTop: 4}}>
              <span>
                <MinusOutlined style={{color: '#bdbdbd'}}/>
              </span>
              <span
                className={"text14-400"}
                style={{color: '#bdbdbd', marginLeft: 8}}
              >
                No Applicable Features
              </span>
            </div>
            <div style={{marginRight: 10}}>
            </div>
          </div>
        </List.Item>
      </div>
    )
  }

  const assemblyFeatures = () => {
    return (
      <div>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 0, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div style={{marginTop: 4}}>
              <span>
                <MinusOutlined style={{color: '#bdbdbd'}}/>
              </span>
              <span
                className={"text14-400"}
                style={{color: '#bdbdbd', marginLeft: 8}}
              >
                Welding
              </span>
            </div>
            <div style={{marginRight: 10}}>

            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 4, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
              <span>
                <MinusOutlined style={{color: '#bdbdbd'}}/>
              </span>
              <span
                className={"text14-400"}
                style={{color: '#bdbdbd', marginLeft: 8}}
              >
                Machine Processing
              </span>
            </div>
            <div style={{marginRight: 10}}>

            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 4, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
              <span>
                <MinusOutlined style={{color: '#bdbdbd'}}/>
              </span>
              <span
                className={"text14-400"}
                style={{color: '#bdbdbd', marginLeft: 8}}
              >
                Assembling
              </span>
            </div>
            <div style={{marginRight: 10}}>

            </div>
          </div>
        </List.Item>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 4, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div>
              <span>
                <MinusOutlined style={{color: '#bdbdbd'}}/>
              </span>
              <span
                className={"text14-400"}
                style={{color: '#bdbdbd', marginLeft: 8}}
              >
                Other
              </span>
            </div>
            <div style={{marginRight: 10}}>

            </div>
          </div>
        </List.Item>
      </div>
    )
  }

  const otherFeatures = () => {
    return (
      <div>
        <List.Item className={"orderItemListItemBorder"} style={{paddingTop: 0, paddingBottom: 5}}>
          <div
            style={{display: 'flex', justifyContent: "space-between", width: '100%', alignItems: "center"}}
          >
            <div style={{marginTop: 4}}>
              <span>
                <MinusOutlined style={{color: '#bdbdbd'}}/>
              </span>
              <span
                className={"text14-400"}
                style={{color: '#bdbdbd', marginLeft: 8}}
              >
                No Applicable Features
              </span>
            </div>
            <div style={{marginRight: 10}}>
            </div>
          </div>
        </List.Item>
      </div>
    )
  }

  const getContent = () => {
    switch (item.partType) {
      case "sheetMetal":
        return sheetMetalFeatures()
      case "milled":
      case "lathed":
        return millingAndLathingFeatures()
      case "tubeCut":
      case "profile":
        return tubeAndProfileFeatures()
      case "buyPart":
      case "bulkMaterial":
        return bulkAndBuyFeatures()
      case "assembly":
        return assemblyFeatures()
      case "other":
        return otherFeatures()
      default:
        return sheetMetalFeatures()
    }
  }

  return (
    <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <List
        style={{marginLeft: 10, marginTop: -5, minWidth: 300, minHeight: 145}}
      >
        {getContent()}
      </List>
      <div style={{display: "flex", alignItems: "end", height: "100%"}}>
        <div style={{width: '100%', marginLeft: 8, paddingTop: 10}}>
          <table style={{width: "100%", height: "100%"}}>
            <tbody style={{height: "100%"}}>
            <tr style={{height: 49}}>
              <td style={{width: "67%", maxWidth: 150, height: "100%"}}>
                {!(isOrderDetails || isProducer) &&
                <div style={{display: "flex", height: '100%', alignItems: "center"}}>
                  {item.documents?.length > 0 ?
                    <div style={{width: "100%"}}>
                      {item.documents.length > 1 ?
                        <div style={{display: "flex", width: "100%"}}>
                          <Spin
                            spinning={uploadingDrawing}
                            wrapperClassName={"overlayDocumentUploadSpin"}
                            indicator={<LoadingOutlined style={{fontSize: 30, marginLeft: -15, marginTop: -15}} spin/>}
                          >
                            <Button
                              className={"addMoreDocumentsButton"}
                              style={{width: "100%", borderRadius: 5, marginBottom: -10, paddingRight: 0}}
                              type="dashed"
                              onClick={() => {
                                openDocumentsView(item)
                              }}
                              ghost
                            >
                              <div style={{display: "flex", width: "100%"}}>
                                <div
                                  style={{display: "flex", justifyContent: "flex-start", width: "calc(100% - 20px)"}}>
                                  <div>
                                    <FilePdfOutlined style={{
                                      fontSize: 14,
                                      color: "#1890ff",
                                      marginRight: 4,
                                      marginTop: 4,
                                      marginLeft: -5
                                    }}/>
                                  </div>
                                  <div
                                    className={"addDrawingText ellipsisText"}
                                    style={{color: "#1890ff", marginTop: 5}}
                                  >
                                    {item.documents.length} Drawing Files
                                  </div>
                                </div>
                              </div>
                            </Button>
                          </Spin>
                          <div style={{marginLeft: 5}}>
                            <Spin
                              spinning={uploadingDrawing}
                              wrapperClassName={"documentUploadSpin"}
                              indicator={<LoadingOutlined style={{fontSize: 24, marginTop: -13, marginLeft: -11}}
                                                          spin/>}
                            >
                              <Upload
                                showUploadList={false}
                                accept=".pdf"
                                multiple
                                beforeUpload={(file) => {
                                  addPartDrawing(file)
                                  return false
                                }}
                              >
                                <Button
                                  className={"addMoreDocumentsButton"}
                                  style={{width: 40, minWidth: 40, borderRadius: 5}}
                                  type="dashed"
                                  ghost
                                >
                                  <AddIcon
                                    style={{fontSize: 20, marginLeft: -6, minWidth: 14, minHeight: 14, marginTop: 5}}/>
                                </Button>
                              </Upload>
                            </Spin>
                          </div>
                        </div>
                        :
                        <div style={{display: "flex", width: "100%"}}>
                          <Spin
                            spinning={uploadingDrawing}
                            wrapperClassName={"overlayDocumentUploadSpin"}
                            indicator={<LoadingOutlined style={{fontSize: 30, marginLeft: -15, marginTop: -15}} spin/>}
                          >
                            <div className={"documentSpinContainer"}>
                              <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                <div style={{display: "flex", width: "calc(100% - 20px)"}}>
                                  <div>
                                    <FilePdfOutlined style={{color: "#1890ff", marginRight: 4}}/>
                                  </div>
                                  <div
                                    className={"addDrawingText ellipsisText"}
                                    style={{color: "#1890ff", marginTop: 4, cursor: "default"}}
                                  >
                                    {item.documents[0].drawingPDFFileName}
                                  </div>
                                </div>
                                <div style={{marginTop: 0, justifyContent: "flex-end", alignItems: "center"}}>
                                  <IconButton
                                    size={'small'}
                                    aria-label="clear"
                                    style={{width: 18, height: 18, marginRight: -3, marginTop: -1}}
                                    onClick={() => handleRemovePartDrawing(item.documents[0].documentId)}
                                  >
                                    <CloseIcon style={{fontSize: 14, color: '#fc505a'}}/>
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                          </Spin>
                          <div style={{marginLeft: 5}}>
                            <Spin
                              spinning={uploadingDrawing}
                              wrapperClassName={"documentUploadSpin"}
                              indicator={<LoadingOutlined style={{fontSize: 24, marginTop: -13, marginLeft: -11}}
                                                          spin/>}
                            >
                              <Upload
                                showUploadList={false}
                                accept=".pdf"
                                multiple
                                beforeUpload={(file) => {
                                  addPartDrawing(file)
                                  return false
                                }}
                              >
                                <Button
                                  className={"addMoreDocumentsButton"}
                                  style={{width: 40, minWidth: 40, borderRadius: 5}}
                                  type="dashed"
                                  ghost
                                >
                                  <AddIcon
                                    style={{fontSize: 20, marginLeft: -6, minWidth: 14, minHeight: 14, marginTop: 5}}/>
                                </Button>
                              </Upload>
                            </Spin>
                          </div>
                        </div>
                      }
                    </div>
                    :
                    <Spin
                      spinning={uploadingDrawing}
                      wrapperClassName={"documentUploadSpin"}
                      indicator={<LoadingOutlined style={{fontSize: 30, marginLeft: -67, marginTop: -15}} spin/>}
                    >
                      <Upload
                        showUploadList={false}
                        accept=".pdf"
                        multiple
                        beforeUpload={(file) => {
                          addPartDrawing(file)
                          return false
                        }}
                      >
                        <Button
                          className={"drawingsButton"}
                          icon={<DrawingIcon2 className={"drawingIcon"}/>}
                        >
                          <span className={"addDrawingText"}>
                            Add part drawings
                          </span>
                        </Button>
                      </Upload>
                    </Spin>
                  }
                </div>
                }
              </td>
              <td style={{width: "33%", paddingLeft: 10}}>
                {bulkItem ? (
                  <div align={"right"}>
                    <div style={{minWidth: 180, display: "flex", justifyContent: "flex-end"}}>
                      <div
                        style={{marginRight: 10, display: 'flex', justifyContent: "space-between", textAlign: 'right'}}>
                        <div style={{marginRight: 30}}>
                          {ptAutomatic ?
                            <PartPriceTooltip
                              producersForOrder={producersForOrder}
                              item={item}
                              itemQuantity={itemQuantity}
                              handleOpenPricesModal={handleOpenPricesModal}
                              assemblyCount={assemblyCount}
                            />
                            :
                            <div className={"text12-400"}>1 pc</div>
                          }
                          {isProducer ?
                            <div className={"priceText"} style={{paddingTop: 8}}>
                              {(item.pricePerPiece || 0).toFixed(2)} €
                            </div>
                            :
                            <div className={"priceText"} style={{paddingTop: 5.2}}>
                              <Form.Item
                                className={"priceInputField"}
                                name={"price"}
                                initialValue={(item.pricePerPiece || 0).toFixed(2)}
                              >
                                <InputNumber
                                  className={"muiInput"}
                                  formatter={(value, info) => info.userTyping ? `${value}` : `${Number(value).toFixed(2)} €`}
                                  parser={value => value ? value.replace('€', '') : 0}
                                  min={0}
                                  onChange={(p) => changePrice({
                                    orderId: orderId,
                                    id: item.id,
                                    assemblyId: item.assemblyId,
                                    quantity: item.quantity,
                                    price: p
                                  })}
                                  size={"small"}
                                  style={{width: 80}} // 1.25 123e
                                />
                              </Form.Item>
                            </div>
                          }

                        </div>
                        <div>
                          <div className={"priceText"}>
                            Total
                          </div>
                          <div className={"priceText"} style={{paddingTop: 6, whiteSpace: "nowrap"}}>
                            {((item.totalPrice || 0) / (assemblyCount || 1)).toFixed(2)} €
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{minWidth: 180, display: "flex", justifyContent: "flex-end"}}>
                    <div
                      style={{marginRight: 10, display: 'flex', justifyContent: "space-between", textAlign: 'right'}}>
                      <div style={{marginRight: 30}}>
                        <div className={"text12-400"}>1 pc</div>
                        <div className={"priceText"} style={{paddingTop: 10}}>
                          {((item.pricePerPiece || 0) * calcAdjustment(adjustment)).toFixed(2)} €
                        </div>
                      </div>
                      <div>
                        <div className={"priceText"}>Total</div>
                        <div
                          className={"priceText"}
                          style={{paddingTop: 6}}
                        >
                          {(((item.totalPrice || 0) / (assemblyCount || 1)) * calcAdjustment(adjustment)).toFixed(2)} €
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}