import React from "react";
import {Button, Card, Divider} from "antd";
import {OrderProgress} from "../../../OrderProgress";
import {calcAdjustment} from "../../../../../utils/utility";
import {ManualQuote} from "../../../../../assets/icons/manualQuote";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {FileDoneOutlined} from "@ant-design/icons";

export const DraftOrderStageCard = (props) => {

  const {
    stage, returnToStage,
    selectableItemsCount,
    needsManualQuote, working, adjustment,
    proceedToShipping, isChangingDraftStage,
    isChangingDeliveryDate, isChangingDraftName,
    handlePlaceOrder, actionMap, totalPrice
  } = props;

  return (
    <Card
      style={{borderRadius: 5, border: '1px solid #3A3E40'}}
      bodyStyle={{padding: "12px 0 10px 0"}}
    >
      <OrderProgress stage={stage} goToStage={returnToStage}/>
      <div style={{marginLeft: 15, width: "calc(100% - 30px)"}}>
        <Divider className={"orderDivider"} style={{ marginTop: 10, marginBottom: 10 }}/>
        {selectableItemsCount > 0 &&
        <div>
          <div className={"text14-600"} style={{fontSize: 19, lineHeight: 1.4}}>
            Quote summary
          </div>
          {stage === "shipping" &&
          <div>
            <div
              style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}
            >
              <div>
                <span className={"text14-400"}>Shipping</span>
              </div>

              <div className={"text12-600"} style={{lineHeight: 1.8}}>
                Manually by RFQ
              </div>

            </div>
            <Divider className={"orderDivider"} style={{marginBottom: 10, marginTop: 10}}/>
          </div>
          }
          <div style={{display: 'flex', justifyContent: "space-between", width: '100%', marginTop: 10}}>
            <div>
              <span className={"text14-400"}>Total</span>
            </div>
            <div className={"text14-600"} style={{lineHeight: 1.5}}>
              {((totalPrice  || 0) * calcAdjustment(adjustment)).toFixed(2)} € {needsManualQuote && "+ RFQ"}
            </div>
          </div>
          <div align={"center"} style={{marginTop: 10, width: "100%"}}>
            {stage === "processing" &&
            <div>
              {needsManualQuote ?
                <div style={{backgroundColor: "#252C30", borderRadius: 5}}>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px 15px 10px 15px"
                  }}>
                    <span>
                      <ManualQuote/>
                    </span>
                    <span style={{
                      display: "flex",
                      fontSize: 14,
                      fontWeight: 600,
                      marginLeft: 10,
                      alignItems: "center",
                      color: "white"
                    }}
                    >
                      Some positions need a manual calculation by our technicians.
                    </span>
                  </div>
                  <div style={{
                    boxShadow: "inset 0 1px 50px 0 rgb(155, 185, 216, 0.2)",
                    padding: "10px 15px 15px 15px",
                    borderRadius: "0 0 5px 5px"
                  }}
                  >
                    <div
                      style={{paddingBottom: 10, fontSize: 12, fontWeight: 600, color: "#a8b4b9"}}>
                      Proceed, and you will receive an official offer.
                    </div>
                    <div>
                      <Button
                        type={"primary"}
                        size={'large'}
                        style={{width: '100%'}}
                        disabled={selectableItemsCount < 1 || working || isChangingDraftStage || actionMap.size > 0}
                        onClick={() => proceedToShipping()}
                      >
                        <span className={"nextStepButtonText"}>Request for Quotation</span>
                      </Button>
                    </div>
                  </div>
                </div>
                :
                <Button
                  type={"primary"}
                  size={'large'}
                  style={{width: '100%'}}
                  disabled={selectableItemsCount < 1 || working || isChangingDraftStage || actionMap.size > 0}
                  onClick={() => proceedToShipping()}
                >
                  <span className={"nextStepButtonText"}>Request for Quotation</span>
                </Button>
              }
            </div>
            }
            {stage === "shipping" &&
            <div style={{display: "flex"}}>
              <Button
                type={"default"}
                size={'large'}
                style={{width: '100%', marginBottom: 5, marginRight: 8, color: "#ff5b2d"}}
                disabled={isChangingDraftStage || isChangingDeliveryDate}
                onClick={()=>returnToStage("processing")}
              >
                <span>
                  <ChevronLeftRoundedIcon style={{marginTop: -10, marginBottom: -6, fontSize: 22}}/>
                  <span className={"nextStepButtonText"}>Return to Calculation</span>
                </span>
              </Button>
              <Button
                type={"primary"}
                size={'large'}
                style={{width: '100%', marginLeft: 8}}
                disabled={isChangingDraftStage || isChangingDeliveryDate}
                form={"shippingForm"}
                key={"submit"}
                htmlType={"submit"}
              >
                <span>
                  <span className={"nextStepButtonText"}>Proceed to Quote </span>
                  <ChevronRightRoundedIcon style={{marginTop: -10, marginBottom: -7, fontSize: 22}}/>
                </span>
              </Button>
            </div>
            }
            {stage === "confirmation" &&
            <div style={{display: "flex"}}>
              <Button
                type={"default"}
                size={'large'}
                style={{width: '100%', marginBottom: 5, marginRight: 8, color: "#ff5b2d"}}
                disabled={isChangingDraftStage}
                onClick={()=>returnToStage("shipping")}
              >
                <span style={{marginLeft: -25}}>
                  <ChevronLeftRoundedIcon style={{marginTop: -10, marginBottom: -6, fontSize: 22}}/>
                  <span className={"nextStepButtonText"}>Return to Delivery</span>
                </span>
              </Button>
              <Button
                type={"primary"}
                size={'large'}
                className={"placeOrderButton"}
                style={{width: '100%', marginLeft: 8}}
                disabled={isChangingDraftStage}
                onClick={()=>{
                  handlePlaceOrder()
                }}
              >
                <span>
                  <FileDoneOutlined style={{fontSize: 18, marginTop: 3}}/>
                  <span className={"nextStepButtonText"}>{needsManualQuote ? "Request Quotation" : "Forward Quotation"}</span>
                </span>
              </Button>
            </div>
            }
            {/*<div style={{marginTop: 10, fontWeight: 600, fontSize: 12, color: "white"}}>*/}
            {/*  {(working || actionMap.size > 0 || isChangingDeliveryDate || isChangingDraftName) ?*/}
            {/*    "Saving Draft..."*/}
            {/*    :*/}
            {/*    "Draft saved"*/}
            {/*  }*/}
            {/*</div>*/}
          </div>
        </div>
        }
      </div>
    </Card>
  )
}