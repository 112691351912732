import React, {useState} from "react";
import {useSelector} from "react-redux";
import { AssemblyItemCard } from "./AssemblyItemCard";
import { PartItemCard } from "./PartItemCard";

export const ProcessedItemCard = (props) => {
  const {
    orderId, item, sheetMetalMaterials, machiningMaterials, tubeCuttingMaterials,
    changeSheetMetalMaterial, changePartThickness, changePartQuantity,
    changePartTolerance, changePartRoughness, changeManualPartTolerance,
    changeManualPartRoughness, changePartNote, changePartColor, changePartStandard,
    handleColorRemove, handleRemoveItem, showItem, selectedItems, onCheck,
    handleChangeItemType, isOrderDetails, bulkItem, changePrice, adjustment,
    handleSelectAssembly, partiallySelectedAssemblies, handleOpenPricesModal,
    openDocumentsView, handleRemoveEntireAssembly, assemblyProgressMap,
    changeAssemblyQuantity, changeMachiningMaterial, changeTubeCuttingMaterial,
    changePartNumberOfUniqueParts, changeAssemblyWeldingPrice, changeAssemblyAssemblingPrice,
    changeAssemblyPackagingPrice, changeAssemblyTransportationPrice,
    changeMaterialCertificate
  } = props;

  const items = bulkItem ? useSelector(state => state.order.parts) : useSelector(state => state.order.parts);

  const countAssemblyParts = (assemblyId) => {
    return items.filter(item => item.partType !== "assembly" && item.assemblyId === assemblyId).length
  }

  const [expanded, setExpanded] = useState(item.isAnalysed ? 0 : 1)

  if (!item.id) {
    return null
  }

  return (
    <div key={item.id}>
      {item.processingType !== "manual" && item.partType === "assembly" ?
        <AssemblyItemCard
          orderId={orderId}
          item={item}
          selectedItems={selectedItems}
          onCheck={onCheck}
          handleRemoveItem={handleRemoveItem}
          sheetMetalMaterials={sheetMetalMaterials}
          machiningMaterials={machiningMaterials}
          tubeCuttingMaterials={tubeCuttingMaterials}
          changeSheetMetalMaterial={changeSheetMetalMaterial}
          changeMachiningMaterial={changeMachiningMaterial}
          changeTubeCuttingMaterial={changeTubeCuttingMaterial}
          changePartThickness={changePartThickness}
          changePartQuantity={changePartQuantity}
          changePartNote={changePartNote}
          changePartColor={changePartColor}
          handleColorRemove={handleColorRemove}
          changePartTolerance={changePartTolerance}
          changePartRoughness={changePartRoughness}
          changeManualPartTolerance={changeManualPartTolerance}
          changeManualPartRoughness={changeManualPartRoughness}
          changeMaterialCertificate={changeMaterialCertificate}
          handleChangeItemType={handleChangeItemType}
          showItem={showItem}
          changePartStandard={changePartStandard}
          changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
          bulkItem={bulkItem}
          changePrice={changePrice}
          openDocumentsView={openDocumentsView}
          partiallySelectedAssemblies={partiallySelectedAssemblies}
          handleSelectAssembly={handleSelectAssembly}
          countAssemblyParts={countAssemblyParts}
          handleRemoveEntireAssembly={handleRemoveEntireAssembly}
          expanded={expanded}
          setExpanded={setExpanded}
          isOrderDetails={isOrderDetails}
          adjustment={adjustment}
          handleOpenPricesModal={handleOpenPricesModal}
          assemblyProgressMap={assemblyProgressMap}
          changeAssemblyQuantity={changeAssemblyQuantity}
          changeAssemblyWeldingPrice={changeAssemblyWeldingPrice}
          changeAssemblyAssemblingPrice={changeAssemblyAssemblingPrice}
          changeAssemblyPackagingPrice={changeAssemblyPackagingPrice}
          changeAssemblyTransportationPrice={changeAssemblyTransportationPrice}
        />
        :
        (item.assemblyId ?
          null
          :
          <PartItemCard
            orderId={orderId}
            item={item}
            selectedItems={selectedItems}
            onCheck={onCheck}
            handleRemoveItem={handleRemoveItem}
            sheetMetalMaterials={sheetMetalMaterials}
            machiningMaterials={machiningMaterials}
            tubeCuttingMaterials={tubeCuttingMaterials}
            changeSheetMetalMaterial={changeSheetMetalMaterial}
            changeMachiningMaterial={changeMachiningMaterial}
            changeTubeCuttingMaterial={changeTubeCuttingMaterial}
            changePartThickness={changePartThickness}
            changePartQuantity={changePartQuantity}
            changePartNote={changePartNote}
            changePartColor={changePartColor}
            handleColorRemove={handleColorRemove}
            changePartTolerance={changePartTolerance}
            changePartRoughness={changePartRoughness}
            changeManualPartTolerance={changeManualPartTolerance}
            changeManualPartRoughness={changeManualPartRoughness}
            changeMaterialCertificate={changeMaterialCertificate}
            handleOpenPricesModal={handleOpenPricesModal}
            showItem={showItem}
            isOrderDetails={isOrderDetails}
            bulkItem={bulkItem}
            changePrice={changePrice}
            adjustment={adjustment}
            openDocumentsView={openDocumentsView}
            isAssemblyPart={false}
            handleChangeItemType={handleChangeItemType}
            changePartStandard={changePartStandard}
            changePartNumberOfUniqueParts={changePartNumberOfUniqueParts}
          />
        )
      }
    </div>
  )
}